import { Component, Injector, Input, OnInit } from '@angular/core';
import { DocumentSigningOrder } from 'src/app/modules/loan-docs/models/document-signing-order.model';
import { NotificationService } from 'src/app/services/notification.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { DocuSignEnvelope, DocuSignRecipient } from '../../models/docusign-envelope.model';
import { ESignatureService } from '../../services/esignature.service';
import { FilePackageUpload } from '../../models/file-package-upload.model';
import { LoanDocService } from 'src/app/services/loan-doc.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DocFile } from 'src/app/models/loan/doc-file.model';
import { IFile } from 'src/app/models/file.interface';
import * as _ from 'lodash';
import { DateTime } from 'luxon';
import { Constants } from 'src/app/services/constants';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { finalize, Subscription } from 'rxjs';
import { EsignatureCancelOrderDialogComponent } from 'src/app/shared/components/esignature-cancel-order-dialog/esignature-cancel-order-dialog.component';
@Component({
  selector: 'esignature-history',
  templateUrl: 'esignature-history.component.html',
  styleUrls: ['esignature-history.component.scss'],
})
export class ESignatureHistoryComponent extends ApplicationContextBoundComponent implements OnInit {

  @Input() adminFunctionSectionEnabled: boolean = true;
  @Input() originalDocumentsSectionEnabled: boolean = true;
  @Input() docuSignDataSectionEnabled: boolean = true;

  orders: DocumentSigningOrder[] = null;

  private _loanId: number;

  selectedOrder: DocumentSigningOrder = null;

  allowCancelEsign: boolean = false;
  isLoadingDocuSignData: boolean = false;
  isLoadingOriginalDocuments: boolean = false;

  isCancelLoading: boolean = false;
  isRefreshLoading: boolean = false;

  docuSignDataByEnvelopeId: Map<string, DocuSignEnvelope> = new Map<
    string,
    DocuSignEnvelope
  >();
  originalDocsByEnvelopeId: Map<string, IFile[]> = new Map<string, IFile[]>();

  docuSignDataForSelectedOrder: DocuSignEnvelope = null;
  originalDocumentsForSelectedOrder: IFile[] = null;

  private _loanInfoChangesSubscription: Subscription;

  constructor(
    private readonly injector: Injector,
    private readonly _eSignService: ESignatureService,
    private readonly _loanDocService: LoanDocService,
    private readonly _notifService: NotificationService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _modalService: NgbModal
  ) {
    super(injector);

    this._loanId = this.applicationContext.application
      ? this.applicationContext.application.applicationId
      : null;
    this.allowCancelEsign =
      this.applicationContext.userPermissions.allowCancelEsign;

    this._loanInfoChangesSubscription = this.applicationContextService.loanInfoChanges.subscribe((context) => {
      if (context.application) {
        this._loanId = context.application.applicationId;
      }
    });
  }

  ngOnInit() {
    this.getAllOrders();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this._loanInfoChangesSubscription) {
      this._loanInfoChangesSubscription.unsubscribe();
    }
  }

  getAllOrders(isRefresh = false) {
    if (this._loanId) {
      this._eSignService.getSigningOrdersForApplication(this._loanId)
        .subscribe({
          next: (orders) => {
            this.orders = orders;
            if (isRefresh) {
              const order = this.orders && this.orders.filter(order => order.documentSigningOrderId === this.selectedOrder.documentSigningOrderId)
              if (order && order.length) {
                this.onOrderSelected(order[0]);
              }
            }
          },
          error: (err) => {
            this._notifService.showError(
              err?.message || 'Error occurred while loading e-signature orders.',
              'Error!'
            );
          }
        });
    }
  }

  onOrderSelected = (order: DocumentSigningOrder) => {
    this.selectedOrder = order;
    this.docuSignDataForSelectedOrder = this.docuSignDataByEnvelopeId.get(
      order.thirdPartyOrderId
    );
    this.originalDocumentsForSelectedOrder = this.originalDocsByEnvelopeId.get(
      order.thirdPartyOrderId
    );
    this.selectedOrder.loanDocTasks.forEach((task) => {
      const updatedByUser = this.applicationContext.globalConfig.usersAll.find(
        (u) => u.userCompanyGuid == task.updatedBy
      );
      const updatedByUserName = updatedByUser
        ? updatedByUser.firstName + ' ' + updatedByUser.lastName
        : '';
      task.updatedBy = updatedByUserName;
    });
  };

  onloadOriginalDocsClicked = () => {
    this.originalDocumentsForSelectedOrder = null;
    if (this.selectedOrder && this.selectedOrder.filePackageId > 0) {
      this.loadPackageUploads(this.selectedOrder.filePackageId);
    } else {
      this.getLoanDocFiles();
    }
  };

  onloadDocuSignDataClicked = () => {
    this.isLoadingDocuSignData = true;
    this.docuSignDataByEnvelopeId.delete(this.selectedOrder.thirdPartyOrderId);
    this._eSignService
      .loadDocuSignData(this.selectedOrder.thirdPartyOrderId)
      .subscribe({
        next: (docuSignData) => {
          this.isLoadingDocuSignData = false;
          this.docuSignDataForSelectedOrder = docuSignData;
          this.formatDocuSignRecipientDeclineReasons(docuSignData.recipients);
          this.docuSignDataByEnvelopeId.set(
            this.selectedOrder.thirdPartyOrderId,
            docuSignData
          );
        },
        error: (err) => {
          this.isLoadingDocuSignData = false;
          this._notifService.showError(
            err?.message || 'Error occurred while loading DocuSign data.',
            'Error!'
          );
        }
      });
  };

  onViewFilePackageClicked = (doc: FilePackageUpload) => {
    if (this.originalDocumentsForSelectedOrder) {
      const existingDoc: FilePackageUpload = <FilePackageUpload>(
        this.originalDocumentsForSelectedOrder.find(
          (d) =>
            (<FilePackageUpload>d).filePackageUploadId ===
            doc.filePackageUploadId
        )
      );
      if (existingDoc && existingDoc.fileData) {
        this.viewFileFromBase64(existingDoc.fileData);
      } else {
        this._eSignService
          .getFilePackageUpload(doc.filePackageUploadId)
          .subscribe({
            next: (filePackageUpload) => {
              this.viewFileFromBase64(filePackageUpload.fileData);
              this.updateFilePackageUploadCache(filePackageUpload);
            },
            error: (err) => {
              this._notifService.showError(
                err?.message || 'Error occurred while getting file upload package.',
                'Error!'
              );
            }
          });
      }
    }
  };

  onViewOriginalDocumentClicked = (file: DocFile) => {
    this._spinner.show();
    const fileGuid = file.guid;
    const mimeType = file.mimeType;
    this._loanDocService.viewFile(fileGuid).subscribe({
      next: (data) => {
        this._spinner.hide();
        const blob = new Blob([data], { type: mimeType });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      },
      error: (err) => {
        this._notifService.showError(err?.message, 'Error');
        this._spinner.hide();
      }
    });
  };

  reloadTasks = () => {
    this.applicationContextService.updateLoanTasks();
    this.getAllOrders(true);
  }

  viewDocuSignDocumentClicked(documentId: number) {
    this._spinner.show();
    this._eSignService.viewDocusignDocument(this.selectedOrder.thirdPartyOrderId, documentId)
      .pipe(finalize(() => this._spinner.hide()))
      .subscribe({
        next: (base64FileData) => {
          this.viewFileFromBase64(base64FileData);
        },
        error: (err) => {
          this._notifService.showError(
            err?.message || 'Error occurred while getting Esign Document.',
            'Error!'
          );
        }
      });
  }

  private formatDocuSignRecipientDeclineReasons = (
    recipients: DocuSignRecipient[]
  ) => {
    recipients.forEach((recipient) => {
      let declineText = '';
      if (recipient.declinedDateTime) {
        declineText = `Declined at ${DateTime.fromJSDate(
          new Date(recipient.declinedDateTime)
        ).toFormat('MMM, DD YYYY h:mm A')}`;
      }
      recipient.declinedReason = recipient.declinedReason
        ? `${declineText} : ${recipient.declinedReason}`
        : declineText;
    });
  };

  private loadPackageUploads = (filePackageId: number) => {
    this.isLoadingOriginalDocuments = true;
    this._eSignService.getFilePackageUploads(filePackageId)
      .subscribe({
        next: (filePackageUploads) => {
          this.isLoadingOriginalDocuments = false;
          this.originalDocumentsForSelectedOrder = filePackageUploads;
          this.originalDocsByEnvelopeId.set(
            this.selectedOrder.thirdPartyOrderId,
            filePackageUploads
          );
        },
        error: (err) => {
          this.isLoadingOriginalDocuments = false;
          this._notifService.showError(
            err?.message || 'Error occurred while loading original documents.',
            'Error!'
          );
        }
      });
  };

  private getLoanDocFiles = () => {
    if (
      !this.selectedOrder ||
      !this.selectedOrder.loanDocTasks ||
      !this.selectedOrder.loanDocTasks.length
    ) {
      this._notifService.showError(
        'There are no tasks associated with this order.',
        'Error!'
      );
      return;
    }
    this.originalDocsByEnvelopeId.delete(this.selectedOrder.thirdPartyOrderId);
    const loanDocFiles = [];
    const loanDocIds = _.uniq(
      this.selectedOrder.loanDocTasks.map((t) => t.loanDocId)
    );
    let expectedCalls = loanDocIds.length;
    this.isLoadingOriginalDocuments = true;
    loanDocIds.map((loanDocId) => {
      this._loanDocService.getLoanDoc(loanDocId).subscribe({
        next: (response) => {
          response.docFiles
            .filter(
              (f) =>
                f.fileName &&
                !(
                  f.fileName.includes('Combined') ||
                  f.fileName.includes('DocuSign Certificate of Completion')
                )
            )
            .forEach((file) => {
              loanDocFiles.push(file);
            });
          expectedCalls--;
          if (expectedCalls === 0) {
            this.originalDocsByEnvelopeId.set(
              this.selectedOrder.thirdPartyOrderId,
              loanDocFiles
            );
            this.isLoadingOriginalDocuments = false;
            this.originalDocumentsForSelectedOrder = loanDocFiles;
          }
        },
        error: (err) => {
          expectedCalls--;
          if (expectedCalls === 0) {
            this.isLoadingOriginalDocuments = false;
          }
          this._notifService.showError(
            err?.message || 'Error occurred while loading original documents.',
            'Error!'
          );
        }
      });
    });
  };

  private viewFileFromBase64 = (base64: string) => {
    var byteCharacters = atob(base64);
    var byteNumbers = new Array(byteCharacters.length);
    for (var i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    var byteArray = new Uint8Array(byteNumbers);
    var file = new Blob([byteArray], { type: 'application/pdf;base64' });
    var fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  };

  private updateFilePackageUploadCache = (
    filePackageUpload: FilePackageUpload
  ) => {
    this.originalDocumentsForSelectedOrder.push(filePackageUpload);
    const docs = this.originalDocsByEnvelopeId.get(
      this.selectedOrder.thirdPartyOrderId
    );
    if (docs) {
      const existingDoc: FilePackageUpload = <FilePackageUpload>(
        docs.find(
          (d) =>
            (<FilePackageUpload>d).filePackageUploadId ===
            filePackageUpload.filePackageUploadId
        )
      );
      if (existingDoc) {
        const index = docs.indexOf(existingDoc);
        if (index >= 0) {
          docs[index] = filePackageUpload;
        }
      } else {
        docs.push(filePackageUpload);
      }
    } else {
      this.originalDocsByEnvelopeId.set(this.selectedOrder.thirdPartyOrderId, [
        filePackageUpload,
      ]);
    }
  };
  getSelectedLoanDocTaskId = () => {
    if (
      !this.selectedOrder ||
      !this.selectedOrder.loanDocTasks ||
      !this.selectedOrder.loanDocTasks.length
    ) {
      this._notifService.showError(
        'There are no tasks associated with this order.',
        'Error!'
      );
      return false;
    }

    return this.selectedOrder.loanDocTasks[0].loanDocTaskId;
  };
  cancelOrder = () => {
    const loanDataTaskId = this.getSelectedLoanDocTaskId();
    if (!loanDataTaskId) {
      return;
    }
    let modalRef = this._modalService.open(EsignatureCancelOrderDialogComponent, {
      ...Constants.modalOptions.medium,
    });
    modalRef.result.then(
      (response) => {
        this.isCancelLoading = true;
        this._eSignService
          .esignCancelOrder(loanDataTaskId, response.reason)
          .subscribe({
            next: () => {
              if (response) {
                this._notifService.showSuccess('Order cancelled successfuly.', ' Refresh Order DocuSign')
                this.getAllOrders(true) // refresh the grid;
              }
            },
            error: (error) => {
              this._notifService.showError(
                error
                  ? error.message
                  : 'Error occurred while cancelling order.',
                'Error! Cancel DocuSign'
              );
            }
          }).add(() => {
            this.isCancelLoading = false;
          });
      },
      () => { }
    );
  };
  refresh = () => {
    const loanDataTaskId = this.getSelectedLoanDocTaskId();
    if (!loanDataTaskId) {
      return;
    }
    this.isRefreshLoading = true;
    this._eSignService.refresh(loanDataTaskId).subscribe({
      next: (response) => {
        if (response) {
          this._notifService.showSuccess('Refresh order from DocuSign refreshed successfuly.', ' Refresh Order DocuSign');
          this.getAllOrders(); // refresh the grid
        }
      },
      error: (error) => {
        this._notifService.showError(
          error
            ? error.message
            : 'Error occurred while refreshing the documents.',
          'Error! Refresh Order DocuSign'
        );
      }
    }).add(() => {
      this.isRefreshLoading = false;
    });;
  };
}
