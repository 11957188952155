import { Injectable } from "@angular/core";
import { Observable, of, Subject, throwError } from "rxjs";
import { catchError, switchMap, map } from "rxjs/operators";
import { DataService } from "src/app/core/services/data.service";
import { DialListRecord } from "../models/dial-list-record.model";
import { DialList } from "../models/dial-list.model";
import { DialRecordHistorySearch, DialRecordHistorySearchResponse } from "../models/dial-record-history-search.model";
import { DialerPageData, PredefinedNote } from "../models/dialer-page-data.model";
import { DialListBasic } from "../models/dial-list-basic.model";
import { DialListRecordBasic, RecordType } from "../models/dial-list-record-basic.model";
import { Lead } from "../../leads/models/lead.model";
import { BorrowerFull } from "../../app-details/models/full-borrower.model";
import { AgentFull } from "../../app-details/models/agent.model";
import { PhoneType } from "../models/phone-type.model";
import { ManualDialParams } from "../models/manual-dial-params.model";
import { DialerEventType } from "../models/dialer-event.model";
import { LeadsService } from "../../leads/services/leads.service";
import { LoanService } from "src/app/services/loan";
import { AgentService } from "src/app/services/agent.service";
import { BorrowersService } from "../../borrower/services/borrowers.service";
import { ContactList, ContactListType, LoanApplication } from "src/app/models";
import { ContactListService } from "src/app/services/contact-list.service";

export interface DialerEvent {
  eventType: DialerEventType
  data: any;
}

@Injectable({
  providedIn: 'root'
})
export class DialerService {

  private _eventTracker = new Subject<DialerEvent>();

  constructor(
    private _dataService: DataService,
    private _contactListService: ContactListService,
    private _leadsService: LeadsService,
    private _loanService: LoanService,
    private _agentService: AgentService,
    private _borrowersService: BorrowersService

  ) { }

  get events(): Subject<DialerEvent> {
    return this._eventTracker;
  }

  publish(param: DialerEvent): void {
    this._eventTracker.next(param);
  }

  addItemsToDialList(dialListId: number, itemsIds: Array<string | number>): Observable<{ added: number, removed: number }[]> {
    return this._dataService.post(`api/dialer/dial-list/${dialListId}/add-items`, itemsIds);
  }

  deleteDialList(dialListId: number): Observable<any> {
    return this._dataService.delete(`api/dialer/dial-list/${dialListId}`);
  }

  moveDialList(fromDialListId: number, toDialListId?: number): Observable<any> {
    return this._dataService.delete(`api/dialer/dial-list/${fromDialListId}${toDialListId != undefined ? '?moveRecordsToDialListId=' + toDialListId : ''}`);
  }

  getDialListRecords(dialListId: number): Observable<DialListRecord[]> {
    return this._dataService.get(`api/dialer/dial-list/${dialListId}/records`);
  }

  getDialLists(): Observable<DialList[]> {
    return this._dataService.get('api/dialer/dial-list');
  }

  getDialListsByType(type: ContactListType): Observable<DialList[]> {

    return this._contactListService.getContactList()
      .pipe(
        catchError((error) => of(error)),
        switchMap((contactLists: ContactList[]) => this.getDialLists()
          .pipe(
            catchError((error) => of(error)),
            map((dialLists: DialList[]) => {

              let contactListIds = contactLists
                .filter(contactList => contactList.contactListType === type)
                .map(contactList => contactList.contactListId);

              return dialLists
                .filter(dialList =>
                  // dialList.adminManaged === false
                  //  &&
                  contactListIds.indexOf(dialList.contactListId) > -1);

            })
          )
        )
      );
  }

  saveDialList(payload: DialList): Observable<DialList> {
    return this._dataService.post(`api/dialer/dial-list`, payload);
  }

  searchDialRecordHistory(searchItem: DialRecordHistorySearch, includeDialListRecord: boolean = true): Observable<DialRecordHistorySearchResponse[]> {
    return this._dataService.post("api/dialer/record-history/search?includeDialListRecord=" + includeDialListRecord, searchItem)
  }

  getDialerPageData = (): Observable<DialerPageData> => {
    return this._dataService.get("api/dialer/pagedata");
  }

  refreshDialListRecords = (dialListId: number): Observable<number> => {
    return this._dataService.post("api/dialer/dial-list/" + dialListId + "/refresh-records", {});
  }

  getBasicDialList = (dialListId: number): Observable<DialListBasic> => {
    return this._dataService.get("api/dialer/dial-list/basic/" + dialListId);
  }

  deleteDialListRecord(dialListId: number, dialListRecordId: number): Observable<any> {
    return this._dataService.delete(`api/dialer/dial-list/${dialListId}/records/${dialListRecordId}`);
  }

  getDialListRecord(dialListId: number, dialListRecordId: number): Observable<DialListRecordBasic> {
    return this._dataService.get(`api/dialer/dial-list/basic/${dialListId}/record/${dialListRecordId}`);
  }

  getRecordDetails = (record: DialListRecordBasic, isAdmin: boolean): Observable<LoanApplication | Lead | BorrowerFull | AgentFull> => {

    let observable: Observable<any>;

    if (record.recordType === RecordType.Lead) {
      observable = this._leadsService.getLead(record.leadId);
    } else if (record.recordType === RecordType.Borrower) {
      observable = this._borrowersService.getBorrower(record.borrowerId, isAdmin);
    } else if (record.recordType === RecordType.Agent) {
      observable = this._agentService.getAgent(record.agentId);
    } else if (record.recordType === RecordType.Application || record.applicationId > 0) {
      observable = this._loanService.getApplicationModel(record.applicationId, true);
    }

    if (observable) {
      return observable;
    }
    else {
      return throwError("Unrecognized record type.");
    }
  }

  getLeadRouteHistoryById = (leadRouteHistoryId: number) => {
    const url = "api/leadroutes/lead-route-history/" + leadRouteHistoryId;
    return this._dataService.get(url);
  }

  openCallControlPanelFromDialer = (
    selectedDialList: DialListBasic,
    selectedRecord: DialListRecordBasic,
    dialLists: DialListBasic[],
    phoneTypeToDial: PhoneType,
    predefinedNotes: PredefinedNote[],
    callImmediate: boolean,
    fetchImmediate: boolean
  ): void => {

    this.publish({
      eventType: DialerEventType.callDialListRecord,
      data: {
        callImmediate: callImmediate,
        fetchImmediate: fetchImmediate,
        selectedDialList: selectedDialList,
        selectedRecord: selectedRecord,
        dialLists: dialLists,
        phoneTypeToDial: phoneTypeToDial,
        predefinedNotes: predefinedNotes,
        manualDial: false,
        isPanelOpened: true
      }
    });
  }

  openCallControlPanel = (manualDialParams: ManualDialParams): void => {

    this.publish({
      eventType: DialerEventType.callNumber,
      data: {
        manualDial: true,
        manualDialParams: manualDialParams,
        isPanelOpened: true
      }
    });
  }

  getBasicDialListNew = (dialListId: number): Observable<DialListBasic> => {
    return this._dataService.get(`api/dialer/dial-list/basic/${dialListId}/new`);
  }
}
