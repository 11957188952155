import { Component, Injector, Input, OnInit } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { formViewProvider } from 'src/app/core/services/form-view.provider';
import { DocumentType } from 'src/app/models';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { Subscription } from 'rxjs';
import { enumLikeValueToDisplayName } from 'src/app/core/services/utils';
import { PricingChannelFlags } from 'src/app/models/pricing/pricing-channel-flags.model';

@Component({
  selector: 'document-type-details',
  templateUrl: './document-type-details.component.html',
  styleUrls: ['./document-type-details.component.scss'],
  viewProviders: [formViewProvider],
})
export class DocumentTypeDetailsComponent extends ApplicationContextBoundComponent implements OnInit {

  @Input() type: DocumentType;

  selectedRoles: EnumerationItem[] = [];
  selectedAgentTypes: EnumerationItem[] = [];

  roles: EnumerationItem[] = [];
  agentTypes: EnumerationItem[] = [];

  channelOptions: EnumerationItem<PricingChannelFlags>[] = [];

  selectedShowOnChannels: string[] = [];
  selectedAllowUploadOnChannels: string[] = [];

  multiSelectSettings: IDropdownSettings = {
    idField: 'value',
    textField: 'name',
    allowSearchFilter: true,
    itemsShowLimit: 5
  }

  private _applicationContextSubscription: Subscription;

  constructor(
    private readonly injector: Injector
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this._applicationContextSubscription = this.applicationContextService.context.subscribe(context => {
      this.roles = context.globalConfig.roles.map(r => new EnumerationItem(r.roleName, r.roleId));

      this.agentTypes = context.globalConfig.agentType.map(t => new EnumerationItem(t.agentTypeName, t.agentTypeId))

      this.getLoadedRoles();
      this.getLoadedAgentTypes();
    });

    this.channelOptions = this.applicationContext.globalConfig.enabledChannels.map((option) => {
      const { name, ...rest } = option;
      return {
        ...rest,
        name: enumLikeValueToDisplayName(name),
        value: name as PricingChannelFlags,
      };
    });

    this.selectedShowOnChannels = this.type.showOnChannels ? this.type.showOnChannels.replaceAll(' ', '').split(',') : [];
    this.selectedAllowUploadOnChannels = this.type.allowUploadOnChannels ? this.type.allowUploadOnChannels.replaceAll(' ', '').split(',') : [];
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this._applicationContextSubscription?.unsubscribe();
  }

  getLoadedRoles = () => {

    let roles = this.type.restrictedToRoles;

    if (!roles || !roles.trim()) {
      return;
    }

    let parts = roles.trim().split(",");
    parts.forEach(value => {
      value = value.trim();
      let matched = this.roles.find(s => s.value == value);
      if (matched) {
        this.selectedRoles.push(matched);
      }
    });

  }

  getLoadedAgentTypes = () => {
    let agentTypes = this.type.permittedAgentTypes;

    if (!agentTypes || !agentTypes.trim()) {
      return;
    }

    let parts = agentTypes.trim().split(",");
    parts.forEach(value => {
      value = value.trim();
      let matched = this.agentTypes.find(s => s.value == value);
      if (matched) {
        this.selectedAgentTypes.push(matched);
      }
    })

  }

  onRoleChanged = () => {
    this.type.restrictedToRoles = this.selectedRoles.length ? this.selectedRoles.map(r => r.value).join() : null;
  }

  onAgentTypeChanged = () => {
    this.type.permittedAgentTypes = this.selectedAgentTypes.length ? this.selectedAgentTypes.map(t => t.value).join() : null;
  }

  onShowOnChannelsChanged = () => {
    this.type.showOnChannels = this.selectedShowOnChannels.map(channel => channel.toString()).join(',');
  }

  onAllowUploadOnChannelsChanged = () => {
    this.type.allowUploadOnChannels = this.selectedAllowUploadOnChannels.map(channel => channel.toString()).join(',');
  }

}
