
<ng-template #pageActionBarLeft>
  <bread-crum></bread-crum>
</ng-template>

<ng-template #pageActionBarRight>
  <button type="button" class="btn btn-primary"
    (click)="openUpsertDrawer()"><i class="fas fa-plus me-2"></i>Add New
  </button>
</ng-template>

<ng-template #mainContent>
    <form #agentTypeForm="ngForm">
        <p-table #loanPurposeTable [columns]="loanPurposeColumns" columnResizeMode="fit" [value]="filteredLoanPurposes"
            [paginator]="true" [rows]="25" [resizableColumns]="false" responsiveLayout="scroll"
            name="loan-purpose-list-table-v2" [rowsPerPageOptions]="[10, 25, 50]" [autoLayout]="true" scrollable="true"
            [scrollHeight]="scrollHeight" [showCurrentPageReport]="true"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            styleClass="p-datatable-gridlines" [selectionPageOnly]="true" sortMode="single"
            [globalFilterFields]="['loanPurposeName', 'enabledChannels','mortgageLoanPurpose']">
            <ng-template pTemplate="caption">
                <grid-top-action-bar #gridHeaderActionBar [options]="actionButtonOptions"></grid-top-action-bar>
            </ng-template>
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th class="justify-content-left">
                        <span>Action</span>
                    </th>
                    <th [class]="col.cssClass" *ngFor="let col of loanPurposeColumns | filter: {visible: true}"
                        [pSortableColumn]="col.field" [pSortableColumnDisabled]="!col.sortable">
                        <div *ngIf="col.field !== 'order'">
                            {{ col.header }}
                            <p-sortIcon *ngIf="col.sortable" [field]="col.field"></p-sortIcon>
                        </div>
                        <div *ngIf="col.field === 'order'">
                            <button *ngIf="allowAddNew" class="btn link-primary p-0 m-0 sort-col-header-text"
                                (click)="openOrderDrawer();$event.stopPropagation()">Sort</button>
                            <span *ngIf="!allowAddNew">Sort</span>
                        </div>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-row>
                <tr>
                    <td class="loan-purpose-action-col">
                        <span>
                            <i class="fa fa-pencil-alt cursor text-primary" (click)="openUpsertDrawer(row)"
                                title="Edit Role"></i>
                            <span class="mx-2"> | </span>
                            <i class="fa fa-trash-alt cursor text-danger" (click)="showDeleteDialog(row)"
                                title="Delete Role"></i>
                            <span class="mx-2"> | </span>
                            <a
                                [routerLink]="['/' + applicationMode + '/loan-config/loan-purpose/lead-status-association/' + row.loanPurposeId + '/' + row.loanPurposeName]">
                                Lead Statuses</a>
                            <span class="mx-2"> | </span>
                            <a [routerLink]="['/' + applicationMode + '/loan-config/loan-purpose/lead-status-flow/' + row.loanPurposeId + '/' + row.loanPurposeName]"
                                [queryParams]="{ statusType: 'Lead' }">Lead Flow</a>
                            <span class="mx-2"> | </span>
                            <a
                                [routerLink]="['/' + applicationMode + '/loan-config/loan-purpose/loan-status-association/' + row.loanPurposeId + '/' + row.loanPurposeName]">
                                Loan Statuses</a>
                            <span class="mx-2"> | </span>
                            <a [routerLink]="['/' + applicationMode + '/loan-config/loan-purpose/loan-status-flow/' + row.loanPurposeId + '/' + row.loanPurposeName]"
                                [queryParams]="{ statusType: 'Loan' }">Loan Flow</a>
                        </span>
                    </td>
                    <td *ngIf="channelsEnabled">{{ row.enabledChannels }}</td>
                    <td>{{ row.loanPurposeName }}</td>
                    <td>{{ row.transactionType }}</td>
                    <td>{{ row.mortgageLoanPurpose }}</td>
                    <td>{{ row.order }}</td>
                </tr>
            </ng-template>
        </p-table>
    </form>
</ng-template>

<admin-page-template
  [useActionBarSections]="true"
  [pageMainContentTemplate]="mainContent"
  [pageActionBarLeftContentTemplate]="pageActionBarLeft"
  [pageActionBarRightContentTemplate]="pageActionBarRight">
</admin-page-template>


<drawer
  #loanPurposeOrderDrawer
  [title]="'Loan Purpose Order'"
  [name]="'loanPurposeOrderDrawer'"
  [templateRef]="loanPurposeOrderRef"
  [options]="orderDrawerOptions">
</drawer>
<ng-template #loanPurposeOrderRef>
    <rearrange-order
        #loanPurposeOrder
        [itemList]="itemsToOrder"
        (close)="closeOrderDrawer($event)"
        (save)="onOrderDrawerSave($event)"
    ></rearrange-order>
</ng-template>


<drawer
  #loanPurposeUpsertDrawer
  [title]="upsertDrawerTitle"
  [name]="'loanPurposeUpsertDrawer'"
  [templateRef]="loanPurposeUpsertRef"
  [options]="upsertDrawerOptions"
></drawer>
<ng-template #loanPurposeUpsertRef>
    <upsert-loan-purpose
        [loanPurpose]="loanPurposeToUpsert"
        [transactionTypes]="transactionTypes"
        [mortgageLoanPurposeTypes]="mortgageLoanPurposeTypes"
        [enabledChannels]="enabledChannels"
        [loanTypes]="loanTypes"
        (onSave)="onUpsertDrawerSave($event)"
        (onClose)="onUpsertDrawerClose($event)"
    ></upsert-loan-purpose>
</ng-template>