export class DefinedEmailImage {
    emailTemplateImageId: number
    emailTemplateImageGuid: string
    definedEmailTemplateId: number
    emailTemplateId: number
    mimeType: string
    imageData: string
    companyId: number
    insertedBy: string
    dateInserted: string
    updatedBy: string
    dateUpdated: string
}

export class DefinedEmailTemplate {
    id: number
    userId: string
    branchId: number

    denialNotificationOverride: boolean;
    denialNotificationSubject: string;
    denialNotificationEmail: string;
    denialNotificationTo: string;
    denialNotificationCC: string;
    denialNotificationBCC: string;
    denialNotificationRolesTo: string;
    denialNotificationRolesCC: string;
    denialNotificationRolesBCC: string;
    denialNotificationIncludeEmailSignature: boolean;
    denialNotificationUsingStripoEditor: boolean;

    esignCompletedOverride: boolean
    esignCompletedSubject: string
    esignCompletedEmail: string
    esignCompletedCC: string
    esignCompletedBCC: string
    esignCompletedRolesCC: string
    esignCompletedRolesBCC: string
    esignCompletedIncludeEmailSignature: boolean;
    esignCompletedUsingStripoEditor: boolean;

    lockRequestOverride: boolean
    lockRequestSubject: string
    lockRequestEmail: string
    lockRequestCC: string
    lockRequestBCC: string
    lockRequestRolesCC: string
    lockRequestRolesBCC: string
    lockRequestIncludeEmailSignature: boolean;
    lockRequestUsingStripoEditor: boolean;

    losEsignCompletedOverride: boolean
    losEsignCompletedSubject: string
    losEsignCompletedEmail: string
    losEsignCompletedCC: string
    losEsignCompletedBCC: string
    losEsignCompletedRolesCC: string
    losEsignCompletedRolesBCC: string
    losEsignCompletedIncludeEmailSignature: boolean;
    losEsignCompletedUsingStripoEditor: boolean;

    onlineAppRegistrationOverride: boolean
    onlineAppRegistrationSubject: string
    onlineAppRegistrationEmail: string = ""
    onlineAppRegistrationCC: string
    onlineAppRegistrationBCC: string
    onlineAppRegistrationIncludeEmailSignature: boolean;
    onlineAppRegistrationUsingStripoEditor: boolean

    onlineAppStartedOverride: boolean
    onlineAppStartedSubject: string
    onlineAppStartedEmail: string = ""
    onlineAppStartedCC: string
    onlineAppStartedBCC: string
    onlineAppStartedRolesCC: string
    onlineAppStartedRolesBCC: string
    onlineAppStartedIncludeEmailSignature: boolean;
    onlineAppStartedUsingStripoEditor: boolean;

    onlineAppSubmissionOverride: boolean
    onlineAppSubmissionSubject: string
    onlineAppSubmissionEmail: string = ""
    onlineAppSubmissionCC: string
    onlineAppSubmissionBCC: string
    onlineAppSubmissionRolesCC: string
    onlineAppSubmissionRolesBCC: string
    onlineAppSubmissionIncludeEmailSignature: boolean;
    onlineAppSubmissionUsingStripoEditor: boolean;

    inviteOverride: boolean
    inviteSubject: string
    inviteEmail: string = ""
    inviteCC: string
    inviteBCC: string
    inviteIncludeEmailSignature: boolean;
    inviteUsingStripoEditor: boolean;

    inviteAgentOverride: boolean
    inviteAgentSubject: string
    inviteAgentEmail: string = ""
    inviteAgentCC: string
    inviteAgentBCC: string
    inviteAgentIncludeEmailSignature: boolean;
    inviteAgentUsingStripoEditor: boolean;

    requestOverride: boolean
    requestSubject: string
    requestEmail: string = ""
    requestCC: string
    requestBCC: string
    requestRolesCC: string
    requestRolesBCC: string
    requestIncludeEmailSignature: boolean;
    requestUsingStripoEditor: boolean;

    reminderOverride: boolean
    reminderSubject: string
    reminderEmail: string = ""
    reminderEmailEnabled: boolean;
    reminderEmailInterval: number;
    reminderCC: string
    reminderBCC: string
    reminderRolesCC: string
    reminderRolesBCC: string
    reminderIncludeEmailSignature: boolean;
    reminderUsingStripoEditor: boolean;

    agentEsignOverride: boolean
    agentEsignSubject: string
    agentEsignEmail: string
    agentEsignCC: string
    agentEsignBCC: string
    agentEsignIncludeEmailSignature: boolean;
    agentEsignUsingStripoEditor: boolean;

    borrowerEsignOverride: boolean
    borrowerEsignSubject: string
    borrowerEsignEmail: string
    borrowerEsignCC: string
    borrowerEsignBCC: string
    borrowerEsignIncludeEmailSignature: boolean;
    borrowerEsignUsingStripoEditor: boolean;

    corrNonDelBorrowerEsignOverride: boolean
    corrNonDelBorrowerEsignSubject: string
    corrNonDelBorrowerEsignEmail: string
    corrNonDelBorrowerEsignCC: string
    corrNonDelBorrowerEsignBCC: string
    corrNonDelBorrowerEsignIncludeEmailSignature: boolean;
    corrNonDelBorrowerEsignUsingStripoEditor: boolean;

    internalEsignOverride: boolean
    internalEsignSubject: string
    internalEsignEmail: string
    internalEsignCC: string
    internalEsignBCC: string
    internalEsignIncludeEmailSignature: boolean;
    internalEsignUsingStripoEditor: boolean;

    qcuLeadReferralOverride: boolean;
    qcuLeadReferralSubject: string;
    qcuLeadReferralEmail: string = "";
    qcuLeadReferralCC: string;
    qcuLeadReferralBCC: string;
    qcuLeadReferralRolesCC: string;
    qcuLeadReferralRolesBCC: string;
    qcuLeadReferralIncludeEmailSignature: boolean;
    qcuLeadReferralUsingStripoEditor: boolean;

    qcuPreApprovedOverride: boolean;
    qcuPreApprovedSubject: string;
    qcuPreApprovedEmail: string = "";
    qcuPreApprovedCC: string;
    qcuPreApprovedBCC: string;
    qcuPreApprovedRolesCC: string;
    qcuPreApprovedRolesBCC: string;
    qcuPreApprovedIncludeEmailSignature: boolean;
    qcuPreApprovedUsingStripoEditor: boolean;

    qcuInProcessOverride: boolean;
    qcuInProcessSubject: string;
    qcuInProcessEmail: string = "";
    qcuInProcessCC: string;
    qcuInProcessBCC: string;
    qcuInProcessRolesCC: string;
    qcuInProcessRolesBCC: string;
    qcuInProcessIncludeEmailSignature: boolean;
    qcuInProcessUsingStripoEditor: boolean;

    videoMessageOverride: boolean
    videoMessageSubject: string
    videoMessageEmail: string
    videoMessageCC: string
    videoMessageBCC: string
    videoMessageRolesCC: string;
    videoMessageRolesBCC: string;
    videoMessageIncludeEmailSignature: boolean;
    videoMessageUsingStripoEditor: boolean;

    borrowerVerificationCompletedOverride: boolean
    borrowerVerificationCompletedSubject: string
    borrowerVerificationCompletedEmail: string = ""
    borrowerVerificationCompletedCC: string
    borrowerVerificationCompletedBCC: string
    borrowerVerificationCompletedRolesCC: string;
    borrowerVerificationCompletedRolesBCC: string;
    borrowerVerificationCompletedIncludeEmailSignature: boolean;
    borrowerVerificationCompletedUsingStripoEditor: boolean;

    images: DefinedEmailImage[]
    companyId: number
    insertedBy: string
    dateInserted: string
    updatedBy: string
    dateUpdated: string


    constructor(data?: any) {
        if (data) {
          Object.keys(data).forEach(key => this[key] = data[key])
        }
    }
}
