import { Component, EventEmitter, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ProcessSubmissionModel } from '../../models/process-submission.model';
import * as _ from 'lodash';
import Swal from 'sweetalert2';
import { ApplicationContextBoundComponent } from '../../../../shared/components';
import { User } from "../../../../models/user/user.model";
import { LoanType } from "../../../../models/config/loan-type.model";
import { LoanPurpose } from "../../../../models/config/loan-purpose.model";
import { EnumerationItem } from "../../../../models/simple-enum-item.model";
import { ApplicationContext, ExternalCompany } from "../../../../models";
import { ChannelService } from "../../../../services/channel.service";
import { BranchService } from "../../../admin/company/services/branch.service";
import { NotificationService } from "../../../../services/notification.service";
import { NgForm } from '@angular/forms';

@Component({
  selector: 'review-data',
  templateUrl: './review-data.component.html',
  styleUrls: ['./review-data.component.scss']
})
export class ReviewDataComponent extends ApplicationContextBoundComponent implements OnInit {

  @ViewChild('reviewDataForm') reviewDataForm: NgForm | undefined;

  @ViewChild('reviewDataBorrowers') reviewDataBorrowers: any;

  @Input()
  context: ApplicationContext;

  @Input()
  set processSubmissionData(value: ProcessSubmissionModel) {
    this._processSubmissionData = value;
    this.calculateLtvAndCltv();

    if (this.context) {
      this.processSubmissionData.mortgageViewModel.borrowers.forEach(borrower => {
        if (this.context.isCompanyDeepHaven) {
          borrower.mortgagePartyType = null;
        }
        this.detailsVisible.push(false);
        this.subBorrowers.push('');
      });

      this.enabledChannels = this.context.globalConfig.enabledChannels;
      this.populateLoanOptions();
    }
  }

  get processSubmissionData(): ProcessSubmissionModel {
    return this._processSubmissionData;
  }

  @Input()
  allowedLoanCharacterstics;

  @Input()
  allowedBorrowerCharacterstics;

  @Input()
  showMissingInfo: boolean;

  @Input()
  showKeyDates: boolean;

  @Input()
  isPrmgAndCorrespondent: boolean;

  @Output()
  backToUploadFile: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  nextStep: EventEmitter<{ processSubmissionData: ProcessSubmissionModel, step?: string }> = new EventEmitter<any>();

  @Output()
  previousStep: EventEmitter<any> = new EventEmitter<any>();

  subBorrowers: string[] = [];
  ltv = 0;
  cltv = 0;
  isTpoUser: boolean;
  primaryRoleUserId: string;
  availableLoanTypes: Array<LoanType> = [];
  availableLoanPurpose: Array<LoanPurpose> = [];
  enabledChannels: Array<EnumerationItem>;
  externalCompanies: ExternalCompany[];
  availableUsers: User[];
  detailsVisible: Array<boolean> = [];

  protected isCompanyDeepHaven: boolean = false;
  protected isCompanyGenway: boolean = false;
  protected isCompanySmartfi: boolean = false;

  protected isLoanTypeReadonly: boolean = false;

  private _processSubmissionData: ProcessSubmissionModel;

  constructor(
    private readonly injector: Injector,
    private readonly _channelService: ChannelService,
    private readonly _branchService: BranchService,
    private readonly _notifyService: NotificationService
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.enabledChannels = [];
    this.primaryRoleUserId = this.context.currentlyLoggedInUser.userCompanyGuid;
    this.isTpoUser = this.context.isTpo;
    this.isCompanyDeepHaven = this.context.isCompanyDeepHaven;
    this.isCompanySmartfi = this.context.isCompanySmartfi;
    this.isCompanyGenway = this.context.isCompanyGenway;

    if (this.isTpoUser) {
      let externalCompanyId: number;
      const user = this.context.globalConfig.tpoUsers.find(u => u.userCompanyGuid === this.context.currentlyLoggedInUser.userCompanyGuid);
      if (user) {
        const branch = this.context.globalConfig.branches.find(b => b.branchId === user.branchId);
        externalCompanyId = branch?.externalCompanyId || user.externalCompanyId;
      }

      this.externalCompanies = this.context.globalConfig.externalCompanies.filter(x => x.externalCompanyId === externalCompanyId);
      if (this.externalCompanies.length > 0) {
        const availableChannelNames = this._channelService
          .getChannelsFromCommaDelimitedString(this.externalCompanies[0]?.enabledChannels)
          .map(c => c.name);
        this.enabledChannels = this.context.globalConfig.enabledChannels.filter(c => availableChannelNames.includes(c.name));
      }
      this.availableUsers = this.context.globalConfig.tpoUsers.filter(x => x.externalCompanyId == externalCompanyId);
    } else {
      this.enabledChannels = this.context.globalConfig.enabledChannels;
      this.availableUsers = this.context.globalConfig.users;
    }

    this.populateLoanOptions();
  }

  loanPurposeChanged = () => {
    if (this.isCompanyGenway && this.getLoanPurposeNameById(Number(this.processSubmissionData.loanPurposeId))?.includes("DreamBuilder")) {
      const fhaLoanType = this.availableLoanTypes.find(lt => lt.loanTypeName == "FHA");
      if (fhaLoanType) {
        this.processSubmissionData.loanTypeId = fhaLoanType.loanTypeId.toString();
        this.isLoanTypeReadonly = true;
      }
    }
    else {
      this.isLoanTypeReadonly = false;
    }
  }

  formatMailingState = (state: string) => {
    if (state == null || state == '') {
      return '—';
    } else {
      return state.toUpperCase();
    }
  };

  getLoanTypeNameById = (loanTypeId) => {
    if (_.isEmpty(this.context.globalConfig) || _.isEmpty(this.context.globalConfig.loanType) || !loanTypeId) {
      return '—';
    }
    const lookupItem = this.context.globalConfig.loanType.filter(loanType => loanType.loanTypeId == loanTypeId);
    return _.isNil(lookupItem) ? '—' : lookupItem[0].loanTypeName;
  };

  getLoanPurposeNameById = (loanPurposeId) => {
    if (_.isEmpty(this.context.globalConfig) || _.isEmpty(this.context.globalConfig.loanPurpose) || !loanPurposeId) {
      return '—';
    }
    const loanPurpose = this.context.globalConfig.loanPurpose.filter(el => el.loanPurposeId == loanPurposeId);
    return _.isNil(loanPurpose) ? '—' : loanPurpose[0].loanPurposeName;
  };

  updateLinkedBorrowers = (index: number, borrowerId: string) => {
    this.processSubmissionData.linkedBorrowers = this.processSubmissionData.linkedBorrowers.filter(linkBorrower => linkBorrower.index !== index);

    let borrower;
    if (borrowerId === '') {
      borrower = undefined;
    } else if (borrowerId == '0') {
      borrower = 0;
    } else {
      borrower = this.processSubmissionData.suggestedBorr[index]?.find(sb => sb.borrowerId == borrowerId);
    }

    this.processSubmissionData.linkedBorrowers.push({ index, borrower });
  };

  hasSuggestedBorrowers = () => {
    var suggestedBorrowerList = Object.values(this.processSubmissionData.suggestedBorr);
    return _.chain(suggestedBorrowerList)
      .flatMap(suggestion => suggestion)
      .some()
      .value();
  };

  back = () => {
    this.backToUploadFile.emit();
    this.previousStep.emit();
  };

  proceed = () => {
    this.reviewDataForm.form.markAllAsTouched();
    if (this.reviewDataForm.form.valid) {
      const borrowers = this.reviewDataBorrowers.getBorrowers();
      this.processSubmissionData.mortgageViewModel.borrowers = borrowers;
      if (this.processSubmissionData.loanData?.mortgage) {
        this.processSubmissionData.loanData.mortgage.borrowers = borrowers;
      }

      if (this.hasSuggestedBorrowers() && this.processSubmissionData.linkedBorrowers && this.processSubmissionData.linkedBorrowers.some(a => a.borrower == '0')) {
        this.showDuplicationWarningDialog();
        return;
      }
      this.nextStep.emit({ processSubmissionData: this.processSubmissionData, step: "reviewData", });
    }
  };

  showDuplicationWarningDialog = () => {
    let self = this;
    Swal.fire({
      title: 'Duplicate Contact',
      text: 'Going forward will result in 2 contact records with the exact same information. Do you want to continue?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes, continue!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true
    }).then((result: any) => {
      if (result.value) {
        this.nextStep.emit({ processSubmissionData: self.processSubmissionData, step: "reviewData", });
      }
    });
  }

  copyFromPresentAddress = () => {
    this.processSubmissionData.mortgageViewModel.subjectProperty.address1 = this.processSubmissionData.mortgageViewModel.borrowers[0].residencyAddresses[0].address.address1;
    this.processSubmissionData.mortgageViewModel.subjectProperty.address2 = this.processSubmissionData.mortgageViewModel.borrowers[0].residencyAddresses[0].address.address2;
    this.processSubmissionData.mortgageViewModel.subjectProperty.city = this.processSubmissionData.mortgageViewModel.borrowers[0].residencyAddresses[0].address.city;
    this.processSubmissionData.mortgageViewModel.subjectProperty.state = this.processSubmissionData.mortgageViewModel.borrowers[0].residencyAddresses[0].address.state;
    this.processSubmissionData.mortgageViewModel.subjectProperty.zipCode = this.processSubmissionData.mortgageViewModel.borrowers[0].residencyAddresses[0].address.zipCode;
  }

  maskSsnNumber = (ssnNumber: string) => {
    if (ssnNumber) {
      let arr = ssnNumber.split('');
      for (let i = 0; i < arr.length - 4; i++) {
        if (arr[i] != '-') {
          arr[i] = '#';
        }
      }
      return arr.join('');
    }
  }

  calculateLtvAndCltv = () => {
    if (!this.processSubmissionData) {
      return;
    }
    let mortgageViewModel = this.processSubmissionData.mortgageViewModel;
    let loanAmount = mortgageViewModel.mortgageTerm.amount;
    let presentValue = mortgageViewModel.subjectProperty.presentValue || 0;
    let sub = mortgageViewModel.transactionDetail.subordinateLienAmount || 0;
    let purchasePriceAmount = mortgageViewModel.transactionDetail.purchasePriceAmount;

    if (!purchasePriceAmount) { // for Refinance
      if (presentValue == 0) {
        this.ltv = 0;
        this.cltv = 0;
      } else {
        this.ltv = loanAmount / presentValue;
        this.cltv = (loanAmount + sub) / presentValue;
      }
    } else { // for Purchase
      if (
        purchasePriceAmount &&
        purchasePriceAmount > 0 &&
        !(presentValue && presentValue != 0 && purchasePriceAmount > presentValue)
      ) {
        presentValue = purchasePriceAmount;
      }

      if (presentValue == 0) {
        this.ltv = 0;
        this.cltv = 0;
      } else {
        this.ltv = loanAmount / presentValue;
        this.cltv = (loanAmount + sub) / presentValue;
      }
    }
  };

  populateLoanOptions = () => {
    if ((this.enabledChannels.length > 0 && !this.processSubmissionData.channel)) {
      this.availableLoanTypes = [];
      this.availableLoanPurpose = [];
      this.processSubmissionData.loanPurposeId = '';
      this.processSubmissionData.loanTypeId = '';
      return;
    }

    if (this.enabledChannels.length === 0 || (this.processSubmissionData.channel !== 'Wholesale' && this.processSubmissionData.channel !== 'Correspondent')) {
      this.availableLoanTypes = this.context.globalConfig.loanType;
      this.availableLoanPurpose = this.context.globalConfig.loanPurpose
        .filter(lt => this.enabledChannels.length === 0 || (lt.enabledChannels && lt.enabledChannels.includes(this.processSubmissionData.channel)));

      if (!_.isNil(this.processSubmissionData.loanPurposeId)) {
        this.loanPurposeChanged();
      }
      return;
    }

    const matchingUser = this.availableUsers?.find((user: User) => user.userCompanyGuid == this.primaryRoleUserId);
    if (!matchingUser || !(matchingUser.branchId && matchingUser.externalCompanyId)) {
      this.availableLoanTypes = this.context.globalConfig.loanType;
      this.availableLoanPurpose = this.context.globalConfig.loanPurpose
        .filter(lt => this.enabledChannels.length === 0 || (lt.enabledChannels && lt.enabledChannels.includes(this.processSubmissionData.channel)));

      if (!_.isNil(this.processSubmissionData.loanPurposeId)) {
        this.loanPurposeChanged();
      }

      return;
    }

    this._branchService.getExternalBranchLoanOptions(matchingUser.branchId).subscribe({
      next: (res) => {
        const availableLoanTypeIds = res?.allowedLoanTypes ? res.allowedLoanTypes.split(',') : [];
        const allLoanTypes = this.context.globalConfig.loanType;
        this.availableLoanTypes = availableLoanTypeIds.length ? allLoanTypes
          .filter((loanType: LoanType) => availableLoanTypeIds.includes(loanType.loanTypeId.toString())) : this.processSubmissionData.availableLoanTypes;

        const availableLoanPurposeIds = res?.allowedLoanPurposes ? res.allowedLoanPurposes.split(',') : [];
        const allLoanPurposes = this.context.globalConfig.loanPurpose;
        this.availableLoanPurpose = availableLoanPurposeIds.length ? allLoanPurposes
          .filter((loanPurpose: LoanPurpose) => availableLoanPurposeIds.includes(loanPurpose.loanPurposeId.toString())) : this.processSubmissionData.availableLoanPurposes;

        if (!_.isNil(this.processSubmissionData.loanPurposeId)) {
          this.loanPurposeChanged();
        }
      },
      error: (err) => {
        this._notifyService.showError(err?.message || 'Unable to get branch loan options', 'Error!');
      }
    });
  }
}
