import { IDateRange } from "src/app/shared/components/date-range-filter/date-range-filter.component";

export class LeadFilters {
  dateRange: IDateRange;
  campaignId: number[] = null;
  branchId: number = null;
  showUserId: string = null;
  state: string[] = null;
  leadListId: number = null; // TODO: Do we still want this? Is this not the same as tags?
  leadStatusIds: number[] = null;
  leadTagIds: number[] = null;
  showArchived: boolean = false;
  firstName: string = null;
  lastName: string = null;

  excludeLeadTagIds: boolean = false;
}

export class LeadFiltersText {
  dateRange: string = null;
  campaignId: string = null;
  branchId: string = null;
  showUserId: string = null;
  state: string = null;
  leadListId: string = null;
}
