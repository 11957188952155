import { Component, Injector, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { cloneDeep } from 'lodash';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { Subscription } from 'rxjs';
import { combineLatestWith, finalize } from 'rxjs/operators';
import { ApplicationContext, Company, CustomDataConfig, SystemLevel } from 'src/app/models';
import { Configuration } from 'src/app/models/configuration.model';
import { CompanyService } from 'src/app/modules/global-admin/company/services/company.service';
import { DrawerOptions, DrawerService, DrawerSize } from 'src/app/shared/services/drawer.service';
import { DiffChecker } from 'src/utils/diff-checker';
import Swal, { SweetAlertResult } from 'sweetalert2';
import { Utils } from '../../../../core/services/utils';
import { EnumerationItem } from '../../../../models/simple-enum-item.model';
import { EnumerationService } from '../../../../services/enumeration-service';
import { NotificationService } from '../../../../services/notification.service';
import { SystemLevelService } from '../../../../services/system-level.service';
import { ApplicationContextBoundComponent } from '../../../../shared/components';
import { CustomDataSettingComponent } from './custom-data-setting/custom-data-setting.component';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'application-settings',
  templateUrl: './application-settings.component.html',
})
export class ApplicationSettingsComponent extends ApplicationContextBoundComponent implements OnInit {
  @ViewChildren(CustomDataSettingComponent) customDataSettingComponents: QueryList<CustomDataSettingComponent> | undefined;

  restrictCreateNewOption: Configuration;
  newUserProfileWizardDisabled: Configuration;
  sessionTimeout: Configuration;
  autoConvertToPdf: Configuration;
  useScribanForHtmlDocGenRendering: Configuration;
  useDynamicCompression: Configuration;
  taskUpdateExcludeAccepted: Configuration;
  disableBorrowerInviteChannelsConfiguration: Configuration;
  loanHiddenFields: Configuration;
  broadcastManagerRolesWithAccessConfiguration: Configuration;
  broadcastManagerUsersWithAccessConfiguration: Configuration;
  twoFactorAuthenticationRequiredForUserTypesConfiguration: Configuration;
  customDataFieldConfigModels: CustomDataConfig[];
  originalCustomDataFieldConfigModels: CustomDataConfig[];
  creditReportingScript: Configuration;
  documentSigningNonLosLoanOfficerSender: Configuration;
  documentSigningLosLoanOfficerSender: Configuration;

  loadingSystemData: boolean;
  dropdownSettings: IDropdownSettings = {};
  hiddenFields = [
    { name: 'Subject Property', value: 'Subject Property' },
    { name: 'Social Security Number', value: 'Social Security Number' },
    { name: 'Appraised Value', value: 'Appraised Value' },
    { name: 'Estimated Value', value: 'Estimated Value' },
  ];
  hiddenFieldsModel = [];
  fieldTypeOptions: EnumerationItem[] = [];
  editorTypeOptions: EnumerationItem[] = [];
  listTypes: EnumerationItem[] = [];
  isSaving: boolean;
  isDataLoaded: boolean = false;
  isCustomDataFieldsSaving: boolean = false;

  protected accessSelectionSettings: IDropdownSettings = Object.freeze({
    idField: 'value',
    textField: 'name',
    itemsShowLimit: 3,
    allowSearchFilter: true,
  });

  customDataFieldsLayoutDrawerOptions: DrawerOptions = {
    size: DrawerSize.XXLarge,
    containerWrapperId: null
  }

  protected usersWithAccessToBroadcastManager: EnumerationItem[] = [];
  protected usersWithAccessOptions: EnumerationItem[] = [];

  protected companyEnabledChannels: EnumerationItem[] = [];
  protected disabledBorrowerInviteChannels: EnumerationItem[] = [];

  protected rolesWithAccessToBroadcastManager: EnumerationItem[] = [];
  protected rolesWithAccessOptions: EnumerationItem[] = [];
  protected userTypesForWhichMfaSetupIsRequired: EnumerationItem[] = [];

  protected userTypeOptions: EnumerationItem[] = [
    new EnumerationItem('Admin', 1),
    new EnumerationItem('Borrower', 2),
    new EnumerationItem('Agent', 3),
    new EnumerationItem('TPO', 4)
  ];

  protected company: Company;

  private _applicationContextSubscription: Subscription | null = null;

  constructor(
    injector: Injector,
    private readonly _notificationService: NotificationService,
    private readonly _enumsService: EnumerationService,
    private readonly _systemLevelService: SystemLevelService,
    private readonly _companyService: CompanyService,
    private readonly _drawerService: DrawerService
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.loadingSystemData = true;
    this._systemLevelService.getSystemLevel().subscribe((data: SystemLevel) => {
      const systemLevel = JSON.parse(JSON.stringify(data));
      this.restrictCreateNewOption = systemLevel.restrictCreateNewOption || {};
      this.newUserProfileWizardDisabled = systemLevel.newUserProfileWizardDisabled || new Configuration('NewUserProfileWizardDisabled');
      this.disableBorrowerInviteChannelsConfiguration = systemLevel.disableBorrowerInviteChannelsConfiguration
        || new Configuration('BorrowerInviteDisabledChannels');
      this.broadcastManagerUsersWithAccessConfiguration = systemLevel.broadcastManagerUsersWithAccessConfiguration
        || new Configuration('BroadcastManagerUsersWithAccess');
      this.broadcastManagerRolesWithAccessConfiguration = systemLevel.broadcastManagerRolesWithAccessConfiguration
        || new Configuration('BroadcastManagerRolesWithAccess');
      this.twoFactorAuthenticationRequiredForUserTypesConfiguration = systemLevel.twoFactorAuthenticationRequiredForRolesConfiguration
        || new Configuration('TwoFactorAuthenticationRequiredForRoles');
      this.sessionTimeout = systemLevel.sessionTimeout || {};
      this.autoConvertToPdf = systemLevel.autoConvertToPdf || {};
      this.useScribanForHtmlDocGenRendering = systemLevel.useScribanForHtmlDocGenRendering
        || new Configuration('UseScribanForHtmlDocGenRendering');
      this.useScribanForHtmlDocGenRendering.companyId = this.applicationContext.userPermissions.companyId;
      this.useDynamicCompression = systemLevel.useDynamicCompression || {};
      this.taskUpdateExcludeAccepted = systemLevel.taskUpdateExcludeAccepted || {};
      this.loanHiddenFields = systemLevel.loanHiddenFields || {};
      this.customDataFieldConfigModels = systemLevel.customDataFieldConfigModels || [];
      this.originalCustomDataFieldConfigModels = cloneDeep(this.customDataFieldConfigModels);
      this.creditReportingScript = systemLevel.creditReportingScript || {};
      this.documentSigningNonLosLoanOfficerSender = systemLevel.documentSigningNonLosLoanOfficerSender || new Configuration('DocumentSigningNonLosLoanOfficerSender');
      this.documentSigningLosLoanOfficerSender = systemLevel.documentSigningLosLoanOfficerSender || new Configuration('DocumentSigningLosLoanOfficerSender')

      this.dropdownSettings = {
        idField: 'value',
        textField: 'name',
        itemsShowLimit: 2,
      };

      if (this.loanHiddenFields?.valueStr) {
        this.hiddenFieldsModel = this.loanHiddenFields.valueStr.split(',').map(el => ({ name: el, value: el }))
      } else {
        this.hiddenFieldsModel = [];
      }

      if (!this.creditReportingScript.valueStr) {
        this.creditReportingScript.valueStr = "";
      }

      this._enumsService.getCustomDataEnumerations().subscribe((enums) => {
        this.fieldTypeOptions = enums.CustomDataFieldType;
        this.editorTypeOptions = enums.CustomDataEditorType;
      });
      this._enumsService.geSharedEnumerations().subscribe((enums) => {
          this.listTypes = enums.CustomDataMultiLookupType;
          this.listTypes.sort((a, b) => a.name.localeCompare(b.name));
      });
      this.subscribeToApplicationContext(systemLevel);
      this.isDataLoaded = true;

    }).add(() => this.loadingSystemData = false);
  }

  ngOnDestroy() {
    this._applicationContextSubscription?.unsubscribe();

    super.ngOnDestroy();
  }

  addCustomField = () => {
    this.customDataFieldConfigModels.push(new CustomDataConfig());
  }

  customDataConfigRemoved(customDataFieldConfigId): void {
    const index = this.customDataFieldConfigModels.findIndex(c => c.customDataFieldConfigId === customDataFieldConfigId);
    if (index > -1) {
      this.customDataFieldConfigModels.splice(index, 1);
      this.customDataFieldConfigModels = [... this.customDataFieldConfigModels];
      this.originalCustomDataFieldConfigModels = cloneDeep(this.customDataFieldConfigModels);
    }
  }

  customDataConfigSaved(customDataFieldConfig, index: number): void {
    this.customDataFieldConfigModels[index] = customDataFieldConfig;
    this.customDataFieldConfigModels = [... this.customDataFieldConfigModels];
    this.originalCustomDataFieldConfigModels = cloneDeep(this.customDataFieldConfigModels);
  }

  customFieldLayoutOpen = async () => {
    const diffChecker = new DiffChecker(
      this.customDataFieldConfigModels,
      this.originalCustomDataFieldConfigModels,
      'customDataFieldConfigModels',
    );
    if (diffChecker.calculateDiff()) {
      await Swal.fire({
        title: 'You have unsaved changes.',
        text: 'Would you like to save your changes?',
        icon: 'question',
        showCancelButton: true,
        showDenyButton: true,
        confirmButtonText: 'Save & Continue!',
        cancelButtonText: 'Cancel',
        cancelButtonColor: "#DD6B55",
        denyButtonText: `Discard & Continue!`,
      }).then((result: SweetAlertResult) => {
        if (result.isDenied) {
          this._drawerService.show("customDataFieldsLayoutDrawer", 100);
        }
        else if (result.isConfirmed) {
          this.saveCustomDataFields(true);
        }
        else {
          return;
        }
      });
    } else {
      this._drawerService.show("customDataFieldsLayoutDrawer", 100);
    }

  }

  onCustomDataFieldConfigModelsUpdated = (customDataFieldConfigModels: CustomDataConfig[]) => {
    customDataFieldConfigModels.forEach(customDataFieldConfig => {
      const index = this.customDataFieldConfigModels.findIndex(c => c.customDataFieldConfigId === customDataFieldConfig.customDataFieldConfigId);
      if (index > -1) {
        this.customDataFieldConfigModels[index].layouts = customDataFieldConfig.layouts;
      }
    });

    this.customDataFieldConfigModels = [... this.customDataFieldConfigModels];
    this.originalCustomDataFieldConfigModels = cloneDeep(this.customDataFieldConfigModels);
  }

  closeLayoutDrawer = () => {
    this._drawerService.hide("customDataFieldsLayoutDrawer", 100);
  }

  saveCustomDataFields = (openDrawer?:boolean) => {
    this.isCustomDataFieldsSaving = true;
    return this._systemLevelService.saveCustomDataConfig(this.customDataFieldConfigModels)
    .subscribe({
      next: res => {
        this.customDataFieldConfigModels = [... res];
        this.originalCustomDataFieldConfigModels = cloneDeep(this.customDataFieldConfigModels);
        this._notificationService.showSuccess('Custom data fields saved successfully.', 'System Level');
        if (openDrawer) {
          this._drawerService.show("customDataFieldsLayoutDrawer", 100);
        }
      },
      error: error => {
        this._notificationService.showError(`${error ? error.message : 'Unable to save.'}`, 'System Level');
      }
    })
    .add(()=> {
      this.isCustomDataFieldsSaving = false;
    });
  }

  private subscribeToApplicationContext(systemLevel: SystemLevel): void {
    this._applicationContextSubscription?.unsubscribe();

    this._applicationContextSubscription = this.applicationContextService
      .context.subscribe({
        next: (context: ApplicationContext) => {
          const companyId = context.userPermissions.companyId;
          this.company = context.globalConfig.company.find(c => c.companyId == companyId);
          this.initApplicationContext(context, systemLevel);
        },
        complete: () => {
          this._applicationContextSubscription = null;
        },
      });
  }

  private initApplicationContext = (context: ApplicationContext, systemLevel: SystemLevel): void => {
    const companyId = context.userPermissions.companyId;

    this.companyEnabledChannels = context.globalConfig.enabledChannels;

    const getUsersWithAccessOptions = (): EnumerationItem[] => {
      const companyUsers = context.globalConfig.usersAll.filter(
        u => u.companyId === companyId && (u.firstName || u.lastName) && u.active,
      );
      return companyUsers.map(u => new EnumerationItem(
        Utils.getPersonsDisplayName(u),
        u.userCompanyGuid,
      ));
    };
    this.usersWithAccessOptions = getUsersWithAccessOptions();

    const getRolesWithAccessOptions = (): EnumerationItem[] => {
      const companyRoles = context.globalConfig.roles.filter(
        r => r.companyId === companyId,
      );
      return companyRoles.map(r => new EnumerationItem(
        r.roleName,
        r.roleId,
      ));
    };
    this.rolesWithAccessOptions = getRolesWithAccessOptions();

    this.populateDisabledBorrowerInviteChannels(context, systemLevel);
    this.populateRolesWithAccessToBroadcastManager(context, systemLevel);
    this.populateUsersWithAccessToBroadcastManager(context, systemLevel);
    this.populateUserTypesForWhichMfaSetupIsRequired(context, systemLevel);
  }

  private populateDisabledBorrowerInviteChannels = (context: ApplicationContext, systemLevel: SystemLevel) => {
    if (systemLevel.borrowerInviteDisabledChannels) {
      const channels = systemLevel.borrowerInviteDisabledChannels.valueStr?.split(',');
      if (!channels) {
        return;
      }
      channels.forEach(disabledChannel => {
        const companyChannels = context.globalConfig.enabledChannels;

        const channel = companyChannels.find(c => c.name == disabledChannel);
        if (channel) {
          this.disabledBorrowerInviteChannels.push(channel);
        }
      });
    }
  }

  private populateRolesWithAccessToBroadcastManager = (context: ApplicationContext, systemLevel: SystemLevel) => {
    if (systemLevel.broadcastManagerRolesWithAccess) {
      const roleIds = systemLevel.broadcastManagerRolesWithAccess.valueStr?.split(',').filter(rid => !!rid);
      if (!roleIds) {
        return;
      }
      roleIds.forEach(rid => {
        const companyRoles = context.globalConfig.roles.filter(
          r => r.companyId === context.userPermissions.companyId,
        );
        const role = companyRoles.find(r => r.roleId == Number(rid.trim()));
        if (role) {
          const selectedRole = new EnumerationItem(role.roleName, role.roleId);
          this.rolesWithAccessToBroadcastManager.push(selectedRole);
        }
      });
    }
  }

  private populateUserTypesForWhichMfaSetupIsRequired = (context: ApplicationContext, systemLevel: SystemLevel) => {
    if (systemLevel.twoFactorAuthenticationRequiredForRoles) {
      const roleIds = systemLevel.twoFactorAuthenticationRequiredForRoles.valueStr?.split(',').filter(rid => !!rid);
      if (!roleIds) {
        return;
      }
      roleIds.forEach(rid => {
        const userType = this.userTypeOptions.find(r => r.value == Number(rid.trim()));
        if (userType) {
          const selectedUserType = new EnumerationItem(userType.name, userType.value);
          this.userTypesForWhichMfaSetupIsRequired.push(selectedUserType);
        }
      });
    }
  }

  private populateUsersWithAccessToBroadcastManager = (context: ApplicationContext, systemLevel: SystemLevel) => {
    if (systemLevel.broadcastManagerUsersWithAccess) {
      const userIds = systemLevel.broadcastManagerUsersWithAccess.valueStr?.split(',').filter(uid => !!uid);
      if (!userIds) {
        return;
      }
      userIds.forEach(uid => {
        const user = context.globalConfig.usersAll.find(u => u.userCompanyGuid == uid.trim());
        if (user) {
          const selectedUser = new EnumerationItem(Utils.getPersonsDisplayName(user), user.userCompanyGuid);
          this.usersWithAccessToBroadcastManager.push(selectedUser);
        }
      });
    }
  }

  save(): void {

    this.customDataSettingComponents.toArray().forEach(c => {
      const form = c.customDataSettingForm as NgForm;
      form.form.markAllAsTouched();
    });

    const haveErrors = this.customDataSettingComponents.toArray().some(c => {
      const form = c.customDataSettingForm as NgForm;
      return !form.form.valid;
    });

    if(haveErrors){
      return;
    }

    this.isSaving = true;
    this.loanHiddenFields.valueStr = this.hiddenFieldsModel.map(el => el.value).join();

    this.disableBorrowerInviteChannelsConfiguration.valueStr = this.disabledBorrowerInviteChannels.map(c => c.name).join(",");
    this.broadcastManagerRolesWithAccessConfiguration.valueStr = this.rolesWithAccessToBroadcastManager.map(r => r.value).join(",");
    this.broadcastManagerUsersWithAccessConfiguration.valueStr = this.usersWithAccessToBroadcastManager.map(r => r.value).join(",");
    this.twoFactorAuthenticationRequiredForUserTypesConfiguration.valueStr = this.userTypesForWhichMfaSetupIsRequired.map(r => r.value).join(",");

    this._companyService.updateCompanyBasic(this.company)
      .pipe(
        combineLatestWith([
          this._systemLevelService.saveConfiguration(this.restrictCreateNewOption),
          this._systemLevelService.saveConfiguration(this.newUserProfileWizardDisabled),
          this._systemLevelService.saveConfiguration(this.disableBorrowerInviteChannelsConfiguration),
          this._systemLevelService.saveConfiguration(this.broadcastManagerRolesWithAccessConfiguration),
          this._systemLevelService.saveConfiguration(this.broadcastManagerUsersWithAccessConfiguration),
          this._systemLevelService.saveConfiguration(this.twoFactorAuthenticationRequiredForUserTypesConfiguration),
          this._systemLevelService.saveConfiguration(this.sessionTimeout),
          this._systemLevelService.saveConfiguration(this.autoConvertToPdf),
          this._systemLevelService.saveConfiguration(this.useScribanForHtmlDocGenRendering),
          this._systemLevelService.saveConfiguration(this.useDynamicCompression),
          this._systemLevelService.saveConfiguration(this.taskUpdateExcludeAccepted),
          this._systemLevelService.saveConfiguration(this.loanHiddenFields),
          this._systemLevelService.saveConfiguration(this.creditReportingScript),
          this._systemLevelService.saveConfiguration(this.documentSigningNonLosLoanOfficerSender),
          this._systemLevelService.saveConfiguration(this.documentSigningLosLoanOfficerSender),
          this._systemLevelService.saveCustomDataConfig(this.customDataFieldConfigModels),
        ]),
        finalize(() => {
          this.isSaving = false;
          this.applicationContextService.reloadCompanies().subscribe();
        })
      )
      .subscribe({
        next: res => {
          this.applicationContextService.updateUserPermission('restrictCreateNewOption', this.restrictCreateNewOption.value === 1);
          this.applicationContextService.updateUserPermission('autoConvertToPdf', this.autoConvertToPdf.value === 1);
          this.applicationContextService.updateUserPermission('useDynamicCompression', this.useDynamicCompression.value === 1);
          this._notificationService.showSuccess(`Settings saved successfully.`, 'System Level');
          const results: any[] = res;
          this.customDataFieldConfigModels = [... results[14]];
          this.originalCustomDataFieldConfigModels = cloneDeep(this.customDataFieldConfigModels);
        },
        error: error => {
          this._notificationService.showError(`${error ? error.message : 'Unable to save.'}`, 'System Level');
        }
      });
  }
}
