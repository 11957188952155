import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import * as _ from 'lodash';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ExpressionBuilderDialogService} from './services/expression-builder-dialog.service';
import {finalize} from 'rxjs/operators';
import {ContactListColumnDefinition} from 'src/app/models';
import {ExpressionBuilderData} from './expression-builder.model';
import {ExpressionGroup} from 'src/app/models/expressions/expression-group.model';
import {
  ConditionalOperator,
  Expression,
  OperatorType,
} from 'src/app/models/expressions/expression.model';
import {CustomField} from 'src/app/models/expressions/custom-field.model';
import {ExpressionTerm} from 'src/app/models/expressions/expression-term.model';
import {CustomDataUtils} from './custom-data-utils';

@Component({
  templateUrl: 'expression-builder-dialog.component.html',
  styleUrls: ['./expression-builder-dialog.component.scss'],
})
export class ExpressionBuilderDialog implements OnInit {
  @Input('expressionBuilderData')
  expressionBuilderData: ExpressionBuilderData;

  @Input('companyId')
  companyId: number;

  @Input()
  saving: boolean;

  @Input()
  showDeleteBtn: boolean = false;

  @Input()
  deleting: boolean = false;

  @Input()
  isLOSConfig: boolean = false;

  @Output('saveExpression')
  saveExpression = new EventEmitter<any>();

  @Output('deleteExpression')
  deleteExpression = new EventEmitter<any>();

  expressionGroup: ExpressionGroup;
  fields: ContactListColumnDefinition[];
  expressionGroupType;
  isCustomContactList: boolean;
  isFromDirective: boolean;
  disableChecker: boolean = false;

  readonly selectFieldTypes: Set<string> = CustomDataUtils.getSelectFieldTypes();

  protected readonly CustomDataUtils = CustomDataUtils;

  constructor(
    public activeModal: NgbActiveModal,
    private readonly _expressionBuilderDialogService: ExpressionBuilderDialogService
  ) {}

  ngOnInit() {
    if (!this.expressionBuilderData.fields) {
      this.expressionBuilderData.fields = [];
    }
    this.expressionGroup = _.cloneDeep(this.expressionBuilderData.expressionGroup);
    this.fields = this.expressionBuilderData.fields.filter(field => field.isTriggerField);
    this.expressionGroupType = _.cloneDeep(this.expressionBuilderData.expressionGroupType);
    this.isCustomContactList = _.cloneDeep(this.expressionBuilderData.isCustomContactList);
    this.isFromDirective = _.cloneDeep(this.expressionBuilderData.isFromDirective);
    this.disableChecker = !!this.expressionBuilderData.disableChecker;
    this.init();
  }

  addExpression() {
    const expressionTerm = this.createExpressionTerm();
    this.expressionGroup.expressions.push({
      conditionalOperator: 'And',
      expressionTerms: [expressionTerm],
      companyId: this.companyId,
    } as Expression);

    this.initEpressionTerm(expressionTerm);
  }

  addExpressionTerm(expression: Expression) {
    const expressionTerm = this.createExpressionTerm();
    expression.expressionTerms.push(expressionTerm);

    this.setExpressionTermField(expressionTerm);
  }

  removeExpression(expressions: Array<Expression>, index: number) {
    expressions.splice(index, 1);
  }

  removeExpressionTerm(expression: Expression, index: number) {
    expression.expressionTerms.splice(index, 1);
  }

  loadValuesAsTags(values, tags) {
    if (!tags) {
      tags = [];
    }
    values.forEach(value => {
      if (tags && !tags.find(ele => ele.text === value.value)) {
        tags.push({value: value.value, display: value.value});
      }
    });
  }

  operatorChanged(expressionTerm: ExpressionTerm): void {
    const isMultiSelectOperatorType = CustomDataUtils.isMultiSelectOperatorType(
      expressionTerm.operatorType
    );

    if (this.selectFieldTypes.has(expressionTerm.field.fieldType) && isMultiSelectOperatorType) {
      expressionTerm.values = [];
      expressionTerm.tags = [];
    } else {
      if (expressionTerm.operatorType === 'Between') {
        expressionTerm.values = ['', ''];
        expressionTerm.tags = [];
      } else {
        if (isMultiSelectOperatorType) {
          if (!expressionTerm.tags) {
            expressionTerm.tags = [];
            expressionTerm.values = [];
          }
          return;
        }
        expressionTerm.tags = [];
        expressionTerm.values = [''];
      }
    }
  }

  addTag(newTag, expressionTerm: ExpressionTerm) {
    expressionTerm.tags.push(newTag.value);
    expressionTerm.values.push(newTag.value);
  }

  removeTag(tagToRemove, expressionTerm: ExpressionTerm) {
    let index = expressionTerm.values.findIndex(value => value === tagToRemove);
    expressionTerm.values.splice(index, 1);
    index = expressionTerm.tags.indexOf(tagToRemove);
    expressionTerm.tags.splice(index, 1);
  }

  handleExpressionTermChange(expressionTerm: ExpressionTerm) {
    expressionTerm.operatorType = null;
    this.setExpressionTermField(expressionTerm);
    this.operatorChanged(expressionTerm);
  }

  getFieldOperators(expressionTerm: ExpressionTerm) {
    if (!expressionTerm?.field?.fieldPath) {
      return;
    }
    expressionTerm['loadingOperators'] = true;
    this._expressionBuilderDialogService
      .getFieldOperators(expressionTerm)
      .pipe(finalize(() => (expressionTerm['loadingOperators'] = false)))
      .subscribe(res => {
        expressionTerm.operators = [];
        res.forEach(operator => {
          expressionTerm.operators.push({
            name: operator.replace(/([A-Z])/g, ' $1').trim(),
            value: operator,
          });
        });
      });
  }

  save() {
    // when inserting expression is empty just null the expressionGroupId and expressionGroup in a parent component to ensure that parent element wont have empty expression saved
    // remember to do it in every place where you enable checker, otherwise it will cause errors
    if (!this.disableChecker) {
      let hasExpression = false;
      if (this.expressionGroup.expressions) {
        hasExpression = this.checkExpresions();
      }
      if (!hasExpression || !this.expressionGroup.expressions) {
        if (this.expressionGroup.expressionGroupId) {
          return this.deleteExpression.emit();
        } else {
          return this.saveExpression.emit(null);
        }
      }
    }

    // remove tags which are ONLY UI fields
    this.expressionGroup.expressions.forEach(expression => {
      expression.expressionTerms.forEach(term => {
        delete term.tags;
        if(term.operatorType == "In"){
          term.values = term.values.filter(d => !!d);
        }
      });
    });

    this.saveExpression.emit(this.expressionGroup);
  }

  delete() {
    this.deleteExpression.emit();
  }

  private init() {
    if (!this.expressionGroup) {
      this.expressionGroup = {
        contactListId: this.expressionBuilderData.contactListId,
        conditionalOperator: ConditionalOperator.And,
        expressions: [
          {
            conditionalOperator: ConditionalOperator.And,
            expressionTerms: [
              {
                operatorType: '',
                field: {
                  fieldPath: null,
                  dataType: '',
                  fieldType: '',
                  name: '',
                  companyId: this.companyId,
                },
                values: [''],
                companyId: this.companyId,
              },
            ],
            companyId: this.companyId,
          },
        ],
        companyId: this.companyId,
      } as unknown as ExpressionGroup;
    }
    if (!this.expressionGroup.contactListId) {
      this.expressionGroup.contactListId = this.expressionBuilderData.contactListId;
    }
    this.setExpressionGroupField();
  }

  private createExpressionTerm(): ExpressionTerm {
    return {
      operatorType: '',
      field: {
        fieldPath: null,
        dataType: '',
        fieldType: '',
        name: '',
        companyId: this.companyId,
      },
      values: [''],
      companyId: this.companyId,
    } as unknown as ExpressionTerm;
  }

  private initEpressionTerm(expressionTerm: ExpressionTerm) {
    expressionTerm.operators = [];
    expressionTerm.tags = [];
    this.loadValuesAsTags(expressionTerm.values, expressionTerm.tags);
  }

  private setExpressionTermField(expressionTerm) {
    const fieldSearch = expressionTerm.field.fieldPath || expressionTerm.field.name;
    const field = this.fields.find(ele => ele.columnDefinition.name === fieldSearch);
    expressionTerm.field.dataType = field ? field.columnDefinition.dataType : '';
    expressionTerm.field.fieldType = field ? field.columnDefinition.fieldType : '';
    expressionTerm.field.name = field ? field.columnDefinition.name : '';
    expressionTerm.field.lodasoftFullName = field && this.isLOSConfig && field.columnDefinition.fieldType == 'Enum' && field.columnDefinition.dataType == 'Enum'? field.columnDefinition.lodasoftFullName : '';
    if (this.isCustomContactList) {
      expressionTerm.virtualFieldName = null;
    } else {
      expressionTerm.virtualFieldName = field ? field.columnDefinition.name : '';
      expressionTerm.customFieldId = null;
    }
    if (!expressionTerm.values.length) {
      expressionTerm.values = [''];
    }
    if (expressionTerm.field.dataType === 'Boolean') {
      expressionTerm.values[0] = expressionTerm.values[0]
        ? !!expressionTerm.values[0]
          ? '1'
          : '0'
        : '0';
    }

    this.getFieldOperators(expressionTerm);
  }

  private setExpressionGroupField() {
    this.expressionGroup.expressions.forEach(expression => {
      expression.expressionTerms.forEach(expressionTerm => {
        expressionTerm.tags = [...expressionTerm.values.filter(v => !!v)];
        if (expressionTerm.virtualFieldName || expressionTerm.field) {
          let field = null;
          if (expressionTerm.virtualFieldName) {
            let found = this.fields.find(
              x => x.columnDefinition.name == expressionTerm.virtualFieldName
            );
            field = found ? found.columnDefinition : null;
          } else {
            field = expressionTerm.field;
          }
          if (field) {
            expressionTerm.field = {
              name: field.name,
              fieldPath: field.fieldPath || field.name,
              fieldType: field.fieldType,
              dataType: field.dataType,
              lodasoftFullName: this.isLOSConfig && field.fieldType == 'Enum' && field.dataType == 'Enum' ? field.lodasoftFullName : ''
            } as CustomField;
          } else {
            expressionTerm.field = new CustomField();
          }
        }
        this.getFieldOperators(expressionTerm);
      });
    });
  }

  private checkExpresions(): boolean {
    this.expressionGroup.expressions.forEach(expression => {
      if (!expression.expressionTerms) {
        return;
      }
      if (!expression.expressionTerms.length) {
        return;
      }

      expression.expressionTerms.forEach((term, index) => {
        const hasValue =
          term.field &&
          term.field.fieldPath &&
          term.operatorType &&
          (term.operatorType === OperatorType.IsEmpty ||
            term.operatorType === OperatorType.Today ||
            term.operatorType === OperatorType.IsAnniversary ||
            term.operatorType === OperatorType.NotEmpty ||
            (term.operatorType === OperatorType.Between &&
              term.values &&
              term.values[0] &&
              term.values[1]) ||
            (term.operatorType === OperatorType.In && term.values?.some(v => v)) ||
            (term.operatorType === OperatorType.NotIn && term.values?.some(v => v)) ||
            (term.operatorType !== OperatorType.Between &&
              term.operatorType !== OperatorType.In &&
              term.operatorType !== OperatorType.NotIn &&
              term.values &&
              term.values[0]));

        if (!hasValue) {
          expression.expressionTerms.splice(index, 1);
        }
      });
    });
    this.expressionGroup.expressions = this.expressionGroup.expressions.filter(
      expression => expression.expressionTerms && expression.expressionTerms.length > 0
    );
    return this.expressionGroup.expressions.some(expression =>
      expression.expressionTerms.some(term => term)
    );
  }
}
