import { AfterViewInit, Component, Injector, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApplicationContext, LoanApplication, Mortgage, UserPermissions } from 'src/app/models';
import { NotificationService } from 'src/app/services/notification.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { PricingScenario } from './models/pricing/pricing-scenario.model';
import { PricingService } from './services/pricing.service';
import { firstValueFrom, Observable, Subscription } from 'rxjs';
import { PricingUtils } from './pricing-utils';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Constants } from 'src/app/services/constants';
import { PricingScenarioChangesDialogComponent } from './components/pricing-scenario-changes-dialog/pricing-scenario-changes-dialog.component';
import { ScenarioComparisonSelectDialogComponent } from './components/scenario-comparison-select-dialog/scenario-comparison-select-dialog.component';
import { ScenarioComparisonWorksheetDialogComponent } from './components/scenario-comparison-worksheet-dialog/scenario-comparison-worksheet-dialog.component';
import { PricingRateCreditApplicationMethod } from './models/pricing/rate.model';
import { SafeResourceUrl } from '@angular/platform-browser';
import { PricingVendor } from 'src/app/models/pricing/pricing-vendor';
import { Utils } from 'src/app/core/services/utils';
import { LosService } from 'src/app/services/los.service';
import { PollyPricerComponent } from './components/polly-pricer/polly-pricer.component';
import { PricingIFrameComponentCanDeactivate } from 'src/app/core/route-guards/pricing-iframe-changes.guard';
import { SpinnerComponent } from 'src/app/shared/components/spinner/spinner.component';

@Component({
  selector: 'pricing',
  templateUrl: 'pricing.component.html',
  styleUrls: ['./pricing.component.scss']
})
export class PricingComponent extends ApplicationContextBoundComponent implements OnInit, AfterViewInit, PricingIFrameComponentCanDeactivate {

  @ViewChild('pollyPricer', { static: false }) pollyPricer!: PollyPricerComponent;

  @ViewChild("spinner")
  spinner: SpinnerComponent;

  mortgage: Mortgage;
  application: LoanApplication;

  isTpo: boolean = false;

  isAmortizationVisible: boolean = false;

  amortizationType: string = '';

  applicationId: number;

  pricingScenarios: PricingScenario[] = [];

  tab: 'pricingDetails' | 'pricingHistory' | 'pricingScenarios' | 'amortization' = 'pricingDetails';

  protected testLenderpriceUrl: SafeResourceUrl;

  protected userPermissions: UserPermissions;

  protected isTpoUser: boolean = false;
  protected isPRMG: boolean = false;

  protected showIframePricer: boolean | null = null;

  protected showIframeForLenderPrice: boolean = false;
  protected showIframeForPolly: boolean = false;

  protected isLoading: boolean = false;
  protected isReloadingFromLosPull: boolean = false;

  protected enabledChannels: any[] = [];

  private _loanInfoChangesSubscription: Subscription;

  constructor(private readonly injector: Injector,
    private readonly _pricingService: PricingService,
    private readonly _modalService: NgbModal,
    private readonly _losService: LosService,
    private readonly _notifyService: NotificationService,
    private readonly _spinnerService: NgxSpinnerService) {
    super(injector);
    
  }

  canDeactivate(): boolean | Observable<boolean> {
    return true;
  }

  async ngOnInit() {
    
  }

  async ngAfterViewInit() {
    this.userPermissions = this.applicationContext.userPermissions;
    if (this.applicationContext.application) {
      await this.initialize(this.applicationContext);
    }
    this._loanInfoChangesSubscription = this.applicationContextService.loanInfoChanges.subscribe(async context => {
      if (context.application && !this.isReloadingFromLosPull) {
        await this.initialize(context);
      }
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this._loanInfoChangesSubscription) {
      this._loanInfoChangesSubscription.unsubscribe();
    }
  }

  onScenariosChanged = (e: any) => {
    this.getScenarios();
  }

  onPricingCompleted = async (e: any) => {
    try {
      await this._spinnerService.show();
      await this.autoSyncLosLoan();
    } catch (e) {
    } finally {
      await this._spinnerService.hide();
    }
  }

  private autoSyncLosLoan = async () => {
    try {
      this.isReloadingFromLosPull = true;
      const losAppOpResult = await firstValueFrom(
        this._losService.pullFromLos(this.application.applicationId),
      );
      this.applicationContextService.updateMortgageAndApplication(losAppOpResult.application?.mortgageLoan,
        losAppOpResult.application, losAppOpResult.customData, null, true);
    } catch (e) {
      console.error(e);
    }
  }

  onApplyScenarioToLoanClicked = (scenario: PricingScenario) => {
    this._pricingService.getMortgagePricingRequest(this.mortgage.mortgageId).subscribe(productSearchRequest => {

      if (this.isTpoUser && this.isPRMG && this.application?.channel == "NonDelegatedCorrespondent") {

        this._spinnerService.show();
        this._pricingService.applyScenarioToLoan(this.applicationId, scenario.pricingScenarioId, PricingRateCreditApplicationMethod.DoNotApplyCredit)
          .subscribe({
            next: (result) => {
              this.applicationContextService.reloadApplicationAndMortgagePostAction(this.applicationId).subscribe();
            }, error: err => {
              this._notifyService.showError(err.error || "An error occurred while applying scenario to loan.", "Error!");
            }
          }).add(() => this._spinnerService.hide());

        return;
      }

      const changes = PricingUtils.findMortgageModelChanges(scenario.pricingRequestPayload, productSearchRequest.request);

      const modalRef = this._modalService.open(
        PricingScenarioChangesDialogComponent,
        Constants.modalOptions.large
      );
      modalRef.componentInstance.changes = changes;
      modalRef.componentInstance.hasLenderCredit = scenario.adjustedPrice > 100;
      modalRef.result.then(
        (selectedCreditMethod: PricingRateCreditApplicationMethod) => {
          this._spinnerService.show();
          this._pricingService.applyScenarioToLoan(this.applicationId, scenario.pricingScenarioId,
            selectedCreditMethod || PricingRateCreditApplicationMethod.DoNotApplyCredit)
            .subscribe({
              next: (result) => {
                this.applicationContextService.reloadApplicationAndMortgagePostAction(this.applicationId).subscribe();
              }, error: err => {
                this._notifyService.showError(err.error || "An error occurred while applying scenario to loan.", "Error!");
              }
            }).add(() => this._spinnerService.hide());
        },
        () => { }
      );
    })
  }

  onLoanComparisonClicked = () => {
    const modalRef = this._modalService.open(ScenarioComparisonSelectDialogComponent, { ...Constants.modalOptions.large, scrollable: false });
    modalRef.componentInstance.scenarios = this.pricingScenarios;
    modalRef.result.then((selections: PricingScenario[]) => {
      const modalRef = this._modalService.open(ScenarioComparisonWorksheetDialogComponent, Constants.modalOptions.xlarge);
      modalRef.componentInstance.scenarios = selections;
      modalRef.componentInstance.appId = this.applicationId;
      modalRef.result.then(() => {
      }, err => { });
    },
      () => { }
    );
  }

  private initialize = async (context: ApplicationContext) => {
    this.mortgage = context.currentMortgage;
    this.application = context.application;
    this.isTpo = context.isTpo;
    this.isTpoUser = context.isTpo;
    this.isPRMG = context.isCompanyPRMG;

    this.applicationId = context.application?.applicationId;
    if (this.applicationId) {
      try {
        this.spinner.show("Validating Loan and Pricing...");
        this.isLoading = true;
        await this.checkPricingVendor(this.applicationId, context);
        await this.getScenarios();
      } catch (error) {
        this._notifyService.showError(
          error ? error.message : 'An error occurred while initializing pricing view.',
          'Error!'
        );
      } finally {
        setTimeout(() => {this.spinner.hide();}, 500);
        
        this.isLoading = false;
      }
    }
  }

  private checkPricingVendor = async (applicationId: number, context: ApplicationContext) => {
    try {
      const pricingCreds = await firstValueFrom(this._pricingService.getPricingCredentials(applicationId));
      var enabledPricingVendors = Object.keys(pricingCreds).map(x => x);
      if (enabledPricingVendors.length === 1) {
        if (enabledPricingVendors[0].toLowerCase() === PricingVendor.LenderPriceIframe.toLowerCase()) {
          this.showIframeForLenderPrice = true;
        } else if (enabledPricingVendors[0].toLowerCase() === PricingVendor.PollyIframe.toLowerCase()) {
          this.showIframeForPolly = true;
          this.enabledChannels = pricingCreds[Utils.lowerCaseFirstLetter(enabledPricingVendors[0])];
        }
        this.showIframePricer = this.showIframeForPolly || this.showIframeForLenderPrice;
      } else {
        this.showIframePricer = false;
      }
      // Hack for Deephaven and LenderPrice
      if (this.showIframePricer) {
        if (context.isCompanyDeepHaven && this.showIframeForLenderPrice) {
          this.showIframePricer = false;
        }
      }
    } catch (error) {
      this._notifyService.showError(
        error ? error.message : 'Unable to get default vendor',
        'Error!'
      );
    } finally {

    }
  }

  setAmortizationTabVisibility(isVisible: boolean) {
    this.isAmortizationVisible = isVisible;
  }

  setAmortizationType(amortizationType: string) {
    this.amortizationType = amortizationType;
  }

  private getScenarios = async () => {
    var scenarios = firstValueFrom(this._pricingService.getPricingScenarios(this.applicationContext.application.applicationId))
    this.pricingScenarios = await scenarios;
  }
}
