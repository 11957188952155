import { PriceCell } from "./price-cell.model";
import { Price, PricedProductRow } from "./priced-product-row.model";
import { Adjustment } from "./pricing-quote.model";
import { ProductSearchRequest } from "./product-search-request-info.model";
import { ProductCalculatedValues } from "./product-search-result.model";

export class ProductPricingDetails {
  prices: PriceCell[] = [];
  notesAndAdvisories: string[] = [];
  adjustments: Adjustment[] = [];
  calculatedValues: ProductCalculatedValues;

  constructor(prices: PriceCell[], notesAndAdvisories: string[], adjustments: Adjustment[], calculatedValues?: ProductCalculatedValues) {
    this.prices = prices;
    this.notesAndAdvisories = notesAndAdvisories;
    this.adjustments = adjustments;
    this.calculatedValues = calculatedValues;
  }
}

export class Scenario {
  requestInfo: ProductSearchRequest;
  pinnedPrices: number[];
  productName: string;
  pinnedPrice: Price;
  isEligible: boolean;
  productId: string;
}

export class PricedScenario {
  pricingScenario: ProductSearchRequest;
  pinnedProducts: PinnedProduct[] = [];
  displayText: string;
  scenarioKey: string;
  searchId: string;

  constructor(pricingScenario: ProductSearchRequest) {
    this.pricingScenario = pricingScenario;
  }
}

export class PinnedProduct {
  product: PricedProductRow;
  pinnedPrices: Price[] = [];

  constructor(product: PricedProductRow) {
    this.product = product;
  }
}

export class UpfrontCosts {
  totalLoanAmount: number;
  pmiMipFfGfAmount: number;
  pmiMipFfGfFinancedAmount: number;
  pmiMipFfGfPaidinCash: number;
  pmiMipFfGfPercent: number;
}
