import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ManualDialParams } from '../dialer/models/manual-dial-params.model';
import { DialerService } from '../dialer/services/dialer.service';
import { ConversationService } from '../conversations/services/conversation.service';

@Component({
  selector: 'dial-number-and-call-history',
  templateUrl: 'dial-number-and-call-history.component.html',
  styleUrls: ['dial-number-and-call-history.component.scss'],
})
export class DialNumberAndCallHistoryComponent implements OnInit {

  @Output()
  closeDialClicked = new EventEmitter<any>();

  protected numberToCall: string;

  constructor(private readonly _dialerService: DialerService,
    private readonly _conversationService: ConversationService) { }

  ngOnInit() { }

  onCallClicked = () => {
    if (!(this.numberToCall && this.numberToCall.length === 10)) {
      return
    }
    let data = {
      phoneNumber: this.numberToCall,
      phoneType: 'mobile',
    } as ManualDialParams;

    this._dialerService.openCallControlPanel(data)
  }

  onSmsClicked = () => {
    this._conversationService.openSmsChat({
      userPhone: this.numberToCall,
      userName: "",
      openDrawer: true,
      openChat: true,
      conversationTargetPersonContext: {
        applicationId: null,
        borrowerId: null,
        leadId: null,
        agentId: null
      }
    })
  }

  onDialpadNumberClicked = (number: string) => {
    if (this.numberToCall && this.numberToCall.length === 10) {
      return;
    }
    this.numberToCall = this.numberToCall ? this.numberToCall + number : number;
  }

  onClearNumberClicked = () => {
    this.numberToCall = '';
  }

  onDialpadBackspaceClicked = () => {
    this.numberToCall = this.numberToCall.slice(0, -1);
  }

  onCloseCallHistoryClicked = () => {
    this.numberToCall = '';
    this.closeDialClicked.emit();
  }
}
