import { Component, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import * as _ from 'lodash';
import { DateTime } from 'luxon';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Observer, of, throwError } from 'rxjs';
import { catchError, finalize, map, mergeMap } from 'rxjs/operators';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { Utils } from 'src/app/core/services/utils';
import { ActivityLog, Borrower, LeadStatus, LoanApplication, LoanStatus, SendSms } from 'src/app/models';
import { Message } from 'src/app/models/message.model';
import { Message as InternalMessage } from 'src/app/models/message/message.model';
import { User } from 'src/app/models/user/user.model';
import { UpsertAgentComponent } from 'src/app/modules/agents/components/upsert-agent/upsert-agent.component';
import { AgentNote } from 'src/app/modules/agents/models/agent-note.model';
import { AgentsService } from 'src/app/modules/agents/services/agents.service';
import { LoanSummaryMortgageComponent } from 'src/app/modules/app-details/components/admin-loan-summary/loan-summary-mortgage/loan-summary-mortgage.component';
import { Agent } from 'src/app/modules/app-details/models/agent.model';
import { BorrowerFull } from 'src/app/modules/app-details/models/full-borrower.model';
import { BorrowerEditorComponent } from 'src/app/modules/borrower/components/borrower-editor/borrower-editor.component';
import { ConversationService } from 'src/app/modules/conversations/services/conversation.service';
import { ViewLeadDrawerComponent } from 'src/app/modules/leads/components/dialogs/view-lead-drawer/view-lead-drawer.component';
import { LeadEvent } from 'src/app/modules/leads/models/lead-event.model';
import { Lead } from 'src/app/modules/leads/models/lead.model';
import { LeadsService } from 'src/app/modules/leads/services/leads.service';
import { LoanActivityService } from 'src/app/modules/loan-activity/services/loan-activity.service';
import { ChatService } from 'src/app/services/chat.service';
import { LoanService } from 'src/app/services/loan';
import { MessageService } from 'src/app/services/message.service';
import { NotificationService } from 'src/app/services/notification.service';
import { VoiceService } from 'src/app/services/voice.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { DrawerOptions, DrawerService, DrawerSize, DynamicComponentInfo } from 'src/app/shared/services/drawer.service';
import { BorrowerNote } from '../../../borrower/models/borrower-note.model';
import { BorrowersService } from '../../../borrower/services/borrowers.service';
import { ConferenceLine, ConferenceStatus } from '../../models/conference-line.model';
import { CallStatus, ConferenceParticipant, ConferenceParticipantEvent } from '../../models/conference-participant.model';
import { DialListBasic } from '../../models/dial-list-basic.model';
import { DialListRecordBasic, RecordType } from '../../models/dial-list-record-basic.model';
import { DialerEventType } from '../../models/dialer-event.model';
import { PredefinedNote } from '../../models/dialer-page-data.model';
import { ManualCallRequest } from '../../models/manual-call-request.model';
import { ManualDialParams } from '../../models/manual-dial-params.model';
import { PhoneType } from '../../models/phone-type.model';
import { DialerDisposition, VoiceHistory } from '../../models/voice-history.model';
import { StartConferenceResponse, VoiceResponse } from '../../models/voice-response.model';
import { WarmTransferStatus } from '../../models/warm-transfer-status.model';
import { DialerEvent, DialerService } from '../../services/dialer.service';
import { logWithStack } from 'src/utils/debugHelpers';

export class PhoneNumberToCall {
  phoneNumber: string = null;
  phoneType: PhoneType = null
}
@Component({
  selector: 'call-control-panel-action-bar',
  templateUrl: './call-control-panel-action-bar.component.html',
  styleUrls: ['./call-control-panel-action-bar.component.scss']
})
export class CallControlPanelActionBarComponent extends ApplicationContextBoundComponent implements OnInit, OnDestroy {
  @ViewChild("dialerCallMessageForm") dialerCallMessageForm: NgForm | undefined;

  dialLists: DialListBasic[] = [];
  selectedDialList: DialListBasic;
  predefinedNotes: PredefinedNote[];
  callImmediate: boolean;
  fetchImmediate: boolean;
  manualDial: boolean = false;
  manualDialParams: ManualDialParams;
  pendingWarmTransferComplete: boolean = false;

  isPanelOpened: boolean = false;

  completedStatuses: CallStatus[] = [
    CallStatus.Completed, 
    CallStatus.Failed, 
    CallStatus.Busy,
    CallStatus.NoAnswer, 
    CallStatus.Disconnected,
    CallStatus.Canceled
  ];

  // THESE SHOULD ALL BE A this.dialerAgentParticipant: ConferenceParticipant PROPERTY, NOT THIS HODEPODGE BS.
  isDialerAgentMuted: boolean = false;
  isDialerAgentSpeaking: boolean = false;
  currentUserPhoneNumber: string;
  currentUserFullName: string;
  isCurrentlyDialing: boolean = false;
  fetchingLead: boolean = false;
  acceptingWarmTransfer: boolean = false;
  phoneNumberToCall: PhoneNumberToCall = null;

  latestNote: string = "";
  latestNoteTime: string = "";
  latestNoteAuthor: string;
  note: string;

  warmTransferStatus: WarmTransferStatus = null;

  conferenceEnded: boolean = false;
  sendSmsClicked: boolean = false;
  numberToSendSmsTo: string = null;
  possibleNumbersToSendSmsTo = {
    borrowerName: ""
  };
  textMessage: string = "";
  quickNoteHeader: string = "Quick Note";

  isAdmin: boolean = false;
  participantTimer: number;
  confTimer: number;
  participants: ConferenceParticipant[] = [];
  activeParticipantId: number;
  participantIdOfAgent: number;
  isDialInProgress: boolean;

  conferenceStartTime: string;
  recordsDialed: DialListRecordBasic[] = [];
  confTimerCounter: number = 0;
  conferenceTimer: string = "00:00";
  leadRouteHistoryId: number;
  users: User[] = [];
  sending: boolean = false;
  selectedRecordIndex: number = 0;

  activeConference: ConferenceLine;
  conferenceStatus: CallStatus;
  currentUserId: string;
  dialUsingTelephonyPoolId: number = null;

  leadStatuses: LeadStatus[] = [];
  loanStatuses: LoanStatus[] = [];

  set selectedRecordForCall(selectedRecordForCall: DialListRecordBasic) {
    selectedRecordForCall.selectedPhoneType = this.getPhoneTypeFromRecord(selectedRecordForCall, null);
    this._phoneTypeToDial = selectedRecordForCall.selectedPhoneType;
    this._selectedRecordForCall = selectedRecordForCall;
    this.setIdAndTypeOfEntityBeingDialedFromRecord(selectedRecordForCall);
  }

  get selectedRecordForCall(): DialListRecordBasic {
    return this._selectedRecordForCall;
  }

  protected fullNameOfPersonBeingDialed: string;

  protected drawerOptions: DrawerOptions = {
    size: DrawerSize.XXXLarge,
    containerWrapperId: null
  };

  private _dialerServiceSubscriber;
  private _isManualDialApiCallInProgress = false;

  private _phoneTypeToDial: PhoneType;

  // private _leadBeingDialed: Lead | null = null;
  // private _agentBeingDialed: AgentFull | null = null;
  // private _borrowerBeingDialed: BorrowerFull | null = null;
  // private _applicationBeingDialed: LoanApplication | null = null;
  private _typeOfEntityBeingDialed: RecordType | null = null;
  private _idOfEntityBeingDialed: number | null = null;

  private _selectedRecordForCall: DialListRecordBasic;

  constructor(
    private readonly _dialerService: DialerService,
    private readonly _voiceService: VoiceService,
    private readonly _leadsService: LeadsService,
    private readonly _conversationService: ConversationService,
    private readonly _borrowersService: BorrowersService,
    private readonly _agentsService: AgentsService,
    private readonly _messageService: MessageService,
    private readonly _notifyService: NotificationService,
    private readonly _localStorageService: LocalStorageService,
    private readonly _chatService: ChatService,
    private readonly _spinnerService: NgxSpinnerService,
    private readonly _loanService: LoanService,
    private readonly _loanActivityService: LoanActivityService,
    private readonly _drawerService: DrawerService,
    private readonly injector: Injector
  ) {

    super(injector);
    this.currentUserId = this.applicationContext.userPermissions.userId;
    this._dialerServiceSubscriber = this._dialerService.events.subscribe((e: DialerEvent) => {
      if (!e) {
        return;
      }

      switch (e.eventType) {
        case DialerEventType.callNumber:
          this.listener_loadCallPanel(e.data);
          break;
        case DialerEventType.callDialListRecord:
          this.listener_loadCallPanel(e.data);
          break;
        case DialerEventType.CallStatusChanged:
          this.listener_callStatusChanged(e.data);
          break;
        case DialerEventType.ConferenceParticipantStatusChanged:
          this.listener_conferenceParticipantStatusChanged(e.data);
          break;
        case DialerEventType.ConferenceEnded:
          this.listener_conferenceEnded(e.data);
          break;
        case DialerEventType.endCurrentCall:
          this.listener_currentCallEnded(e.data);
          break;
        case DialerEventType.WarmTransferConnected:
          this.listener_warmTransferConnected(e.data);
          break;
        case DialerEventType.WarmTransferCompleted:
          this.listener_warmTransferCompleted(e.data);
          break;
        case DialerEventType.WarmTransferAccepted:
          this.listener_warmTransferAccepted(e.data);
          break;
        case DialerEventType.WarmTransferCanceled:
          this.listener_warmTransferCanceled(e.data);
          break;
        case DialerEventType.WarmTransferReceived:
          this.listener_warmTransferReceived(e.data);
          break;
        case DialerEventType.DialUsingChanged:
          this.listener_dialUsingChanged(e.data);
          break;
        case DialerEventType.ScreenPopRecord:
          this.listener_screenPopRecord(e.data);
          break;
        case DialerEventType.ReloadActiveConferenceForInbound:
          const forceReload = true;
          this.checkActiveConference(forceReload).subscribe();
          break;
        default:
          console.log(`Unknown event type ${e.eventType} in dialer events...? Unhandled.`);
          break;
      }
    })
  }

  ngOnDestroy(): void {
    if (this._dialerServiceSubscriber) {
      this._dialerServiceSubscriber.unsubscribe();
    }
  }

  ngOnInit() {
    this.initialize();
  }

  initialize = () => {
    // conditional check to prevent backend conference id error when an api call is in progress and second api call is made.
    if (this._isManualDialApiCallInProgress) { return; }

    this._isManualDialApiCallInProgress = true;

    this.manualDial = this.manualDial || !this.areWeOnDialerPage();

    this.applicationContextService.context.subscribe(context => {

      this.isAdmin = context.userPermissions.admin;
      this.users = context.globalConfig.users;
      this.leadStatuses = context.globalConfig.leadStatus;
      this.loanStatuses = context.globalConfig.loanStatus;

      this.currentUserPhoneNumber = Utils.getActiveTelephonyService(context.currentlyLoggedInUserProfile.telephonyServices)?.fromPhoneNumber;
      this.currentUserFullName = context.currentlyLoggedInUserProfile.userProfile.firstName + " " + context.currentlyLoggedInUserProfile.userProfile.lastName;

      if (this.manualDial && this.manualDialParams) {
        this.numberToSendSmsTo = this.manualDialParams.phoneNumber;
      }
      this.checkActiveConference(false)
        .pipe(mergeMap(() => this.manualDialControl()))
        .pipe(finalize(() => {
          this._isManualDialApiCallInProgress = false;
        }))
        .subscribe({
          next: () => {
            this.initializeFields();

            if (this.callImmediate === true) {
              const isFromDialNext = true;
              const disconnectCurrentCall = false;
              this.removeActiveParticipantFromConference(isFromDialNext, this.dialNumber, disconnectCurrentCall);
            } else if (this.fetchImmediate === true) {
              this.fetch();
            }
          },
          error: (error) => {
            this._notifyService.showError(
              error
                ? error
                : 'Error occurred while dialing the number.',
              'Error!'
            );
          }
        });
    });
  }

  initializeFields = () => {
    if (this.selectedRecordForCall && this.selectedRecordForCall.recordType) {
      this._typeOfEntityBeingDialed = this.selectedRecordForCall.recordType;
      this.populatePhoneNumberOptionsForSms(this.selectedRecordForCall);
    } else if (this.manualDial && this.manualDialParams) {
      this.numberToSendSmsTo = this.manualDialParams.phoneNumber;
    }

    if (this.activeConferenceExists()) {
      if (!this.participantTimer) {
        this.participantTimer = window.setTimeout(this.onTimeout, 1000);
      }
      if (this.confTimer) {
        clearTimeout(this.confTimer);
      }
      this.confTimer = window.setTimeout(this.onConfTimeout, 1000);

      this.conferenceEnded = false;
      this.isCurrentlyDialing = false;
    }

    if (this.selectedRecordForCall) {
      this.quickNoteHeader = "Quick Note";
      if (this.selectedRecordForCall.recordType == RecordType.Lead && this.selectedRecordForCall.leadId) {
        this.loadLeadLatestNote(this.selectedRecordForCall.leadId);
      } else if (this.selectedRecordForCall.recordType == RecordType.Borrower && this.selectedRecordForCall.borrowerId) {
        this.loadBorrowerLatestNote(this.selectedRecordForCall.borrowerId);
      }
      else if (this.selectedRecordForCall.recordType == RecordType.Agent && this.selectedRecordForCall.agentId) {
        this.loadAgentLatestNote(this.selectedRecordForCall.agentId);
      } else if (this.selectedRecordForCall.recordType == RecordType.Application && this.selectedRecordForCall.applicationId) {
        this.loadApplicationLatestNote(this.selectedRecordForCall.applicationId);
      }
    } else {
      this.resetNoteFields();
    }
  }

  addParticipantToList = (participant: ConferenceParticipant) => {
    if (participant.agentUserId === this.currentUserId) {
      logWithStack(`tried to add ${participant.conferenceParticipantId} to the participant list in addParticipantToList, but they are the current agent. Skipping`, participant);
      return;
    }

    const updated = _.clone(participant);
    updated.totalSeconds = this.getDateDiffInMinutes(new Date(), new Date(updated.dateUpdated));
    updated.timer = '00:00';
    updated.phoneNumber = this.formatPhoneNumber(updated.phoneNumber);
    updated.conferenceLineId = this.activeConference.conferenceLineId;

    if (!!updated.voiceHistory)
    {
      if (!updated.participantName || updated.participantName == "undefined undefined")
        updated.participantName = this.getParticipantName(updated.voiceHistory);
      const participantName = this.getParticipantName(updated.voiceHistory);
      updated.participantName = participantName;
      const participantPhoneNumber = this.formatPhoneNumber(updated.voiceHistory.to);
      this.numberToSendSmsTo = participantPhoneNumber;

      if (!updated.isAgent)
        this.setIdAndTypeOfEntityBeingDialed(updated.voiceHistory);
    }
    
    let participantExists = this.participants.find(i => i.conferenceParticipantId == updated.conferenceParticipantId);

    if (!participantExists) {
      this.participants = [...this.participants, {
        conferenceParticipantId: updated.conferenceParticipantId,
        voiceHistoryId: updated.voiceHistoryId,
        voiceHistory: updated.voiceHistory,
        thirdPartyCallId: updated.thirdPartyCallId,
        conferenceLineId: updated.conferenceLineId,
        callStatus: updated.callStatus || CallStatus.Initiated,
        phoneNumber: updated.phoneNumber,
        phoneType: updated.phoneType,
        muted: updated.muted || false,
        onHold: updated.onHold,
        totalSeconds: updated.totalSeconds || 0,
        timer: updated.timer || '00:00',
        participantName: updated.participantName,
        participantFirstLetters: this.getWordsFirstLetters(updated.participantName),
        agentUserId: updated.agentUserId,
        isAgent: updated.isAgent
      } as ConferenceParticipant];

      this.publishParticipantsChange();
    }
  }

  populateParticipantsForManualDial = (participantName: string, voiceHistory: VoiceHistory) => {
    // When it's manual dial, we do not need to remove the active participant and re-dial.
    // In this case, the target person is already dialed and on the call, just need to add that to the participants list.
    this.participants = [];
    this.addParticipantToList({
      conferenceLineId: this.activeConference.conferenceLineId,
      conferenceParticipantId: this.activeParticipantId,
      voiceHistory: voiceHistory,
      participantName: participantName ?? voiceHistory.recordFirstName + " " + voiceHistory.recordLastName
    } as ConferenceParticipant);
  }

  onTimeout = () => {
    this.participantTimer = window.setTimeout(this.onTimeout, 1000);

    this.participants.forEach(participant => {
      if (!this.completedStatuses.includes(participant.callStatus)) {
        participant.totalSeconds++;
        const minutes = parseInt((participant.totalSeconds / 60).toString());
        const seconds = participant.totalSeconds % 60;
        participant.timer = this.padNumberTwoDigit(minutes) + ':' + this.padNumberTwoDigit(seconds);
      }
    });
  }

  activeConferenceExists = (): boolean => {
    return !!this.activeConference?.conferenceLineId;
  }

  activeCallExists = (): boolean => {
    return !!this.activeParticipantId;
  }

  getWordsFirstLetters = (text: string): string => {
    if (!text) {
      return;
    }
    let matches = text.match(/\b(\w)/g);
    if (!matches) {
      return;
    }

    return matches.join('');
  }

  removeActiveParticipantFromConference = (isFromDialNext: boolean, callback?: Function, disconnectCurrentCall: boolean = true) => {
    if (this.pendingWarmTransferComplete) {
      console.log(`Cant hang up, mid warm transfer...`);
      return;
    }

    // Capture the active participant Id before it is cleared in the middle of the flow below.
    let priorActiveParticipantId = this.activeParticipantId;
    if (this.activeConference && priorActiveParticipantId) {
      this._voiceService.removeActiveParticipantFromConference(this.activeConference.conferenceLineId, priorActiveParticipantId)
        .subscribe(() => {
          // Shouldn't we just get the event instead...?
          console.log(`onAfterParticipantRemovedFromConference ${priorActiveParticipantId} active participant from conference, but relying on event instead.`);
          this.onAfterParticipantRemovedFromConference(priorActiveParticipantId, isFromDialNext, callback, disconnectCurrentCall);
        }, (err) => {
          // Just swallow this error - no need to worry about not being to delete someone that is not there.
          console.log(`WOULD HAVE removed ${priorActiveParticipantId} active participant from conference, but relying on event instead.`);
          this.onAfterParticipantRemovedFromConference(priorActiveParticipantId, isFromDialNext, callback, disconnectCurrentCall);
          //this._notifyService.showError(err.message || err, 'Error');
        });
    } else {
      console.log(`Couldn't trigger removeActiveParticipantFromConference because activeConf null ${!this.activeConference} or no active participantId: ${priorActiveParticipantId}`);
      if (isFromDialNext) {
        callback();
      }
    }
  }

  removeParticipantFromConference = (participantId: number) => {
    if (this.activeConference && participantId) {
      this._voiceService.removeActiveParticipantFromConference(this.activeConference.conferenceLineId, participantId)
        .subscribe((result) => {
          // this.activeConference = result.conferenceLine;
        }, (err) => {
          // Just swallow this error - no need to worry about not being to delete someone that is not there.
          console.log(`WOULD HAVE removed ${participantId} active participant from conference, but relying on event instead.`);
        
        });
    }
  }

  populatePhoneNumberOptionsForSms = (record: DialListRecordBasic) => {
    if (this.manualDial) {
      this.numberToSendSmsTo = this.getPhoneNumberToCall(this.selectedRecordForCall, PhoneType.mobile).phoneNumber;
      return;
    }
    this._dialerService.getRecordDetails(record, this.isAdmin)
      .subscribe(response => {
        let recordDetails = response as any;
        if (record.recordType == RecordType.Borrower) {
          recordDetails = response["borrower"] as Borrower;
        }
        else if (record.recordType == RecordType.Agent) {
          recordDetails = response["agent"] as Agent;
        } else if (record.recordType == RecordType.Application) {
          this._loanService.getBorrowers(record.applicationId).subscribe(borrowers => {
            if (borrowers.length) {
              let borrower = borrowers.find(b => b.isPrimary);
              if (!borrower) {
                borrower = borrowers[0];
              }
              this.doPopulatePhoneNumberOptionsForSms(record, borrower)
              return;
            }
          }, error => {
            this._notifyService.showError(error.message || error, 'Error');
          })
        }
        this.doPopulatePhoneNumberOptionsForSms(record, recordDetails);
      }, error => {
        this._notifyService.showError(error.message || error, 'Error');
        if (record.mobilePhone) {
          this.numberToSendSmsTo = record.mobilePhone.trim();
        }
      });
  }

  getPhoneNumberToCall = (dialListRecord: DialListRecordBasic, phoneType?: PhoneType): PhoneNumberToCall => {

    let phoneNumberToCall = new PhoneNumberToCall();

    if (dialListRecord.phoneNumberToCall) {
      if (phoneType && phoneType === dialListRecord.selectedPhoneType) {
        return { phoneNumber: dialListRecord.phoneNumberToCall, phoneType: dialListRecord.selectedPhoneType };
      }
    }

    if (!phoneType) {
      if (dialListRecord.mobilePhone && dialListRecord.mobilePhone.trim().length > 0) {
        phoneNumberToCall.phoneType = PhoneType.mobile;
        phoneNumberToCall.phoneNumber = this.formatPhoneNumber(dialListRecord.mobilePhone.trim());
        return phoneNumberToCall;
      }
      if (dialListRecord.homePhone && dialListRecord.homePhone.trim().length > 0) {
        phoneNumberToCall.phoneType = PhoneType.home;
        phoneNumberToCall.phoneNumber = this.formatPhoneNumber(dialListRecord.homePhone.trim());
        return phoneNumberToCall;
      }
      if (dialListRecord.workPhone && dialListRecord.workPhone.trim().length > 0) {
        phoneNumberToCall.phoneType = PhoneType.work;
        phoneNumberToCall.phoneNumber = this.formatPhoneNumber(dialListRecord.workPhone.trim());
        return phoneNumberToCall;
      }
    } else {
      if (phoneType === PhoneType.mobile && dialListRecord.mobilePhone && dialListRecord.mobilePhone.trim().length > 0) {
        phoneNumberToCall.phoneType = PhoneType.mobile;
        phoneNumberToCall.phoneNumber = this.formatPhoneNumber(dialListRecord.mobilePhone.trim());
        return phoneNumberToCall;
      }
      if (phoneType === PhoneType.home && dialListRecord.homePhone && dialListRecord.homePhone.trim().length > 0) {
        phoneNumberToCall.phoneType = PhoneType.home;
        phoneNumberToCall.phoneNumber = this.formatPhoneNumber(dialListRecord.homePhone.trim());
        return phoneNumberToCall;
      }
      if (phoneType === PhoneType.work && dialListRecord.workPhone && dialListRecord.workPhone.trim().length > 0) {
        phoneNumberToCall.phoneType = PhoneType.work;
        phoneNumberToCall.phoneNumber = this.formatPhoneNumber(dialListRecord.workPhone.trim());
        return phoneNumberToCall;
      }
    }

    return phoneNumberToCall;
  }


  formatPhoneNumber = (phoneNum: string): string => {
    if (!phoneNum) {
      return null;
    }
    return phoneNum.replace("+1", "").replace("(", "").replace(")", "").replace("-", "").replace(" ", "");
  }

  removeParticipant = (participantId: number): void => {
    let participant = this.participants.find(p => p.conferenceParticipantId == participantId);
    if (participant) {
      let index = this.participants.indexOf(participant);
      if (index >= 0) {
        this.participants.splice(index, 1);
      }
    }
  }

  setDialInProgress = (dialInProgress: boolean) => {
    this.isDialInProgress = dialInProgress;
  }

  dialNumber = () => {
    if (!this.selectedRecordForCall) {
      this._notifyService.showInfo("Could not find an active record to dial.", "Active Record Missing");
      this.isCurrentlyDialing = false;
      return;
    }

    this.isCurrentlyDialing = true;

    this.phoneNumberToCall = this.getPhoneNumberToCall(this.selectedRecordForCall, this._phoneTypeToDial);

    this.selectedRecordIndex = this.selectedDialList.records.findIndex(x => x.dialListRecordId == this.selectedRecordForCall.dialListRecordId);

    if (!this.phoneNumberToCall.phoneNumber) {
      this._notifyService.showInfo("Could not find valid phone number to dial.", "Record Missing Data");
      this.isCurrentlyDialing = false;
      return;
    }

    // It'd be a lot simpler if the whole status bar state including participants was encapsulated into a class. 
    // That class would be what getActiveConference returns.
    // SignalR events would simply patch that class while writing the same update to the DB so that the next
    // getActiveConference is never a different state than the UI.
    if (this.activeConferenceExists()) {
      let selectedRecord = this.selectedRecordForCall;
      this._voiceService.addParticipantToConference(this.activeConference.conferenceLineId, this.phoneNumberToCall.phoneNumber, selectedRecord ? selectedRecord.dialListRecordId : -1, this.dialUsingTelephonyPoolId)
        .subscribe(
          (response) => {
            this.activeConference.conferenceLineId = Number(response.conferenceLine.conferenceLineId);
            this.activeParticipantId = Number(response.participantId);

            this.setDialInProgress(true);
            this.isCurrentlyDialing = false;

            selectedRecord.callStatus = CallStatus.Initiated;

            let participantExists = this.participants.find(i => i.conferenceParticipantId == this.activeParticipantId);
            if (!participantExists) {
              let newParticipant = response.conferenceLine.participants.find(p => p.conferenceParticipantId === response.participantId);
              let participantName = selectedRecord.firstName + " " + selectedRecord.lastName;
              if (!this.participantTimer) {
                this.participantTimer = window.setTimeout(this.onTimeout, 1000);
              }
              newParticipant.phoneType = this._phoneTypeToDial;
              newParticipant.participantName = participantName;
              
              console.log(`dialNumber -> activeConferenceExists -> addParticipantToConference -> Adding actual participant after add of ${response.participantId}`, newParticipant);
              this.addParticipantToList(newParticipant);
              // console.log(`AddParticipantToConference including jank object for confPart ${this.activeParticipantId}`);
              // // AddParticipantToConference should just return the participant, wtf.
              // this.addParticipantToList({
              //   conferenceParticipantId: this.activeParticipantId,
              //   // voiceHistory: {
              //   //   dialListRecordId: selectedRecord.dialListRecordId,
              //   // },
              //   voiceHistory: response.voiceHistory,
              //   participantName: participantName,
              //   phoneNumber: this.getPhoneNumberToCall(selectedRecord, this._phoneTypeToDial).phoneNumber,
              //   phoneType: this._phoneTypeToDial
              // } as ConferenceParticipant);
            }

            this.addToRecordsDialed(
              {
                dialListId: selectedRecord.dialListId,
                dialListRecordId: selectedRecord.dialListRecordId,
                conferenceId: this.activeConference.conferenceLineId.toString()
              } as DialListRecordBasic
            );
            this.isCurrentlyDialing = false;
          },
          (err) => {
            this.setDialInProgress(false);
            this.isCurrentlyDialing = false;

            let isNonExistentConferenceError = this.checkIfErrorIsForNonExistentConference(err.error.message);
            if (!isNonExistentConferenceError) {
              this._notifyService.showError(err.error.message || 'An error occurred adding participant to conference.', "Error!");
            }
            else {
              this.activeConference = undefined;
              this.activeParticipantId = undefined;
              this.stopConferenceTimer();
              this.clearConferenceStartTime();

              this.conferenceStartTime = this.setConferenceStartTime(new Date());
              this.dialNumber();
            }
          });
    } else {
      this._voiceService.startConference().subscribe(response => {
        console.log("start conference response:", response);
        this.handleNewConferenceResponse(response);

        this.conferenceStartTime = this.setConferenceStartTime(new Date());
        this.dialNumber();
      }, (err) => {
        this._notifyService.showError(err?.error?.message || err?.error || err, "Error");
        this.isCurrentlyDialing = false;
      });
    }
  }

  addToRecordsDialed = (recordsDialed: DialListRecordBasic): void => {
    this.recordsDialed.push(recordsDialed);
    this._localStorageService.setItem('dialerv2-status', {
      recordsDialed: this.recordsDialed
    });
  }

  stopConferenceTimer = () => {
    if (this.confTimer) {
      clearTimeout(this.confTimer);
    }

    this.isDialerAgentSpeaking = false;
    this.isDialerAgentMuted = false;
    this.confTimerCounter = 0;
    this.conferenceTimer = this.padNumberTwoDigit(parseInt((this.confTimerCounter / 60).toString())).toString() + ':' +
      this.padNumberTwoDigit(this.confTimerCounter % 60);
  }

  startConferenceTimer = (): void => {
    this.stopConferenceTimer();
    this.confTimer = window.setTimeout(this.onConfTimeout, 1000);
  }

  onConfTimeout = (): void => {
    this.confTimerCounter++;
    this.confTimer = window.setTimeout(this.onConfTimeout, 1000);
    this.conferenceTimer = this.padNumberTwoDigit(parseInt((this.confTimerCounter / 60).toString())).toString() + ':' +
      this.padNumberTwoDigit(this.confTimerCounter % 60);
  }

  padNumberTwoDigit = (val: number): string => {
    let valString = val + "";
    if (valString.length < 2) {
      return "0" + valString;
    } else {
      return valString;
    }
  }

  clearConferenceStartTime = (): void => {
    this.conferenceStartTime = '-';
  }

  checkIfErrorIsForNonExistentConference = (errorMessage: string): boolean => {
    return errorMessage === "Unable to find conference line that this conference belongs to." ||
      errorMessage === "Unable to add call to conference, conference is not active";
  }

  setConferenceStartTime = (date: Date): string => {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? 'PM' : 'AM';

    let lhours = hours % 12;
    lhours = lhours ? lhours : 12; // the hour '0' should be '12'
    let lminutes = minutes < 10 ? '0' + minutes : minutes;

    return lhours + ':' + lminutes + ' ' + ampm;
  }

  fetch = (): void => {
    if (this.warmTransferStatus === WarmTransferStatus.Published || this.warmTransferStatus === WarmTransferStatus.Started) {
      const silent = true;
      this.cancelWarmTransfer(silent);
    }

    this.resetNoteFields();
    if (this.activeConferenceExists()) {
      if (this.activeParticipantId && this.isDialInProgress) {
        //force clientside hangup
        this.hangUpByParticipantId(this.activeParticipantId);
        this.setCallStatus(CallStatus.Completed);
      }
      this.fetchLead();
    } else {
      this._voiceService.startConference()
        .subscribe(response => {
          this.handleNewConferenceResponse(response);

          this.fetch();
        }, (err) => {
          this._notifyService.showError(err.error.message || err.error || '', "Error");
        });
    }
  }

  cancelWarmTransfer = (silent: boolean): void => {
    let participant = this.participants.find(p => p.callStatus === CallStatus.InProgress && !p.isAgent);
    if (!participant) {
      this.warmTransferStatus = null;
      if (!silent) {
        this._notifyService.showError('Unable to find participant for the warm transfer cancellation.', 'Error');
      }
      return;
    }

    this._voiceService.cancelWarmTransfer(this.activeConference.conferenceLineId, participant.conferenceParticipantId)
      .subscribe(result => {
        if (this.warmTransferStatus === WarmTransferStatus.Started) {
          let index = this.participants.findIndex(p => p.isAgent && p.agentUserId != this.currentUserId);
          this.participants.splice(index, 1);
        }

        this.warmTransferStatus = null;
        this.leadRouteHistoryId = undefined;
        if (!silent) {
          this._notifyService.showSuccess('Warm transfer canceled successfully.', 'Success');
        }
      }, err => {
        if (!silent) {
          this._notifyService.showError(err.error.message || err.error || '', 'Error');
        }
      });
  }

  resetNoteFields = (): void => {
    this.latestNote = "";
    this.latestNoteTime = "";
    this.latestNoteAuthor = "";
    this.note = "";
  }

  setCallStatus = (callStatus: CallStatus): void => {
    if (this.selectedRecordForCall) {
      this.selectedRecordForCall.callStatus = callStatus;
      this.publishCallStatusChange(this.selectedRecordForCall.dialListRecordId, callStatus);

      this.participants.forEach(participant => {
        if (participant.voiceHistory.dialListRecordId === this.selectedRecordForCall.dialListRecordId) {
          participant.callStatus = callStatus;
        }
      });
    }
  }

  fetchLead = (): void => {
    this.participants = [];
    this.fetchImmediate = false;
    this.fetchLeadStarted();

    this._voiceService.fetchConferenceLead(this.activeConference.conferenceLineId, this.dialUsingTelephonyPoolId)
      .subscribe(response => {
        this._dialerService.getDialListRecord(response.dialRecord.dialListId, response.dialRecord.dialListRecordId)
          .subscribe(dialRecord => {

            let lead = dialRecord;
            let voiceResponse = response.voiceResponse;

            if (voiceResponse.errorMessage) {
              this._notifyService.showError(voiceResponse.errorMessage, "Error");
              this.fetchLeadEnded();
              this.hangUpConference();
              return;
            }

            lead.callStatus = CallStatus.Initiated;
            this.phoneNumberToCall = this.getPhoneNumberToCall(lead);
            this.numberToSendSmsTo = this.getPhoneNumberToCall(lead, PhoneType.mobile).phoneNumber;

            lead.phoneNumberToCall = this.phoneNumberToCall.phoneNumber;
            this._phoneTypeToDial = this.phoneNumberToCall.phoneType;
            this.publishCallStatusChange(lead.dialListRecordId, lead.callStatus);

            this.activeParticipantId = voiceResponse.participantId;

            let participantExists = this.participants.find(i => i.conferenceParticipantId == voiceResponse.participantId);
            if (!participantExists) {
              let pName = dialRecord.firstName + ' ' + dialRecord.lastName;

              this.addParticipantToList({
                conferenceParticipantId: voiceResponse.participantId,
                voiceHistory: response.voiceResponse.voiceHistory,
                participantName: pName
              } as ConferenceParticipant)
            }

            this.initializeFields();
            // this.loadLeadLatestNote(lead.leadId);

            this.onLeadFetched(lead, this.selectedDialList);
            this.populatePhoneNumberOptionsForSms(lead);

            this.setDialInProgress(true);
          }, err => {
            const errorMessage = err ? err.error : "An error occurred while fetching lead.";
            this._notifyService.showError(errorMessage, "Error!");
          });
      }, err => {
        const errorMessage = err ? err.error : "An error occurred while fetching lead.";
        this._notifyService.showError(errorMessage, "Error!");
      }).add(() => {
        this.fetchLeadEnded();
      });
  }

  fetchLeadStarted = () => {
    this.fetchingLead = true;
    let fetchNextButtonElement = document.getElementById('fetchNextButton');
    if (fetchNextButtonElement) {
      fetchNextButtonElement.classList.add("fa-spin");
    }
    this._spinnerService.show();
  }

  fetchLeadEnded = () => {
    this.fetchingLead = false;
    let fetchNextButtonElement = document.getElementById('fetchNextButton');
    if (fetchNextButtonElement) {
      fetchNextButtonElement.classList.remove("fa-spin");
    }
    this._spinnerService.hide();
  }

  onLeadFetched = (lead: DialListRecordBasic, dialListToInsertFetcedLeadInto: DialListBasic): void => {
    //todo: set record data using switch, as lead isnt only fetch type
    lead.recordType = RecordType.Lead;
    lead.dialListId = dialListToInsertFetcedLeadInto.dialListId;
    lead.isFetched = true;
    //end todo

    let index = this.getDialListRecordIndex(dialListToInsertFetcedLeadInto);
    this._dialerService.publish({
      eventType: DialerEventType.pushDialListRecord,
      data: {
        index: index + 1,
        record: lead
      }
    });

    this.selectedRecordForCall = lead;

    this._dialerService.publish({
      eventType: DialerEventType.dialerv2_recordCalled,
      data: {
        dialListId: lead.dialListId,
        dialListRecordId: lead.dialListRecordId,
        conferenceId: this.activeConference.conferenceLineId
      }
    });
  }

  getDialListRecordIndex = (selectedDialList: DialListBasic): number => {
    if (!this.selectedRecordForCall || !this.selectedRecordForCall.dialListRecordId) {
      return -1;
    }

    return selectedDialList.records.findIndex(x => x.dialListRecordId == this.selectedRecordForCall.dialListRecordId);
  }

  loadLeadLatestNote = (leadId: number): void => {
    this.resetNoteFields();
    this._leadsService.getLeadEvents(leadId)
      .subscribe((events: LeadEvent[]) => {

        let result = [];
        let notes = events.filter(r => r.type === "note");
        notes.forEach(note => {
          let time = new Date(note.dateUpdated || note.dateInserted);
          result.push({ note: note.note, time: time, author: note.insertedBy });
        });
        result = result.sort((n1, n2) => {
          return n2.time - n1.time;
        });

        if (result && result.length > 0) {
          let author = this.users.find(u => u.userCompanyGuid === result[0].author);
          if (author) {
            this.latestNoteAuthor = author.firstName + " " + author.lastName;
          }
          this.latestNoteTime = DateTime.fromJSDate(new Date(result[0].time)).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS);
          this.latestNote = result[0].note;
        } else {
          this.resetNoteFields();
        }
      });

  }

  loadBorrowerLatestNote = (borrowerId: number) => {
    this.resetNoteFields();
    this._borrowersService.getBorrowerNotes(borrowerId)
      .subscribe((notes: BorrowerNote[]) => {

        let result = [];
        notes.forEach(note => {
          let time = new Date(note.dateUpdated || note.dateInserted);
          result.push({ note: note, time: time, author: note.insertedBy });
        });
        result = result.sort((n1, n2) => {
          return n2.time - n1.time;
        });

        if (result && result.length > 0) {
          let author = this.users.find(u => u.userCompanyGuid === result[0].author);
          if (author) {
            this.latestNoteAuthor = author.firstName + " " + author.lastName;
          }
          this.latestNoteTime = DateTime.fromJSDate(new Date(result[0].time)).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS);
          this.latestNote = result[0].note.content;
        } else {
          this.resetNoteFields();
        }
      });
  }

  loadAgentLatestNote = (agentId: number) => {
    this.resetNoteFields();
    this._agentsService.getAgentNotes(agentId)
      .subscribe((notes: AgentNote[]) => {

        let result = [];
        notes.forEach(note => {
          let time = new Date(note.dateUpdated || note.dateInserted);
          result.push({ note: note, time: time, author: note.insertedBy });
        });
        result = result.sort((n1, n2) => {
          return n2.time - n1.time;
        });

        if (result && result.length > 0) {
          let author = this.users.find(u => u.userCompanyGuid === result[0].author);
          if (author) {
            this.latestNoteAuthor = author.firstName + " " + author.lastName;
          }
          this.latestNoteTime = DateTime.fromJSDate(new Date(result[0].time)).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS);
          this.latestNote = result[0].note.content;
        } else {
          this.resetNoteFields();
        }
      });
  }

  loadApplicationLatestNote = (applicationId: number) => {
    this.resetNoteFields();
    this._loanActivityService.getActivityLogs(applicationId, false)
      .subscribe((notes: ActivityLog[]) => {
        notes = notes.filter(n => n.activityType === 'InternalMessage');
        let result = [];
        notes.forEach(note => {
          let time = new Date(note.dateCreated);
          result.push({ note: note, time: time, author: note.userId });
        });
        result = result.sort((n1, n2) => {
          return n2.time - n1.time;
        });

        if (result && result.length > 0) {
          let author = this.users.find(u => u.userCompanyGuid === result[0].author);
          if (author) {
            this.latestNoteAuthor = author.firstName + " " + author.lastName;
          }
          this.latestNoteTime = DateTime.fromJSDate(new Date(result[0].time)).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS);
          this.latestNote = result[0].note.displayText;
        } else {
          this.resetNoteFields();
        }
      });
  }

  hangUpConference = (): void => {
    if (this.activeConference && !this.pendingWarmTransferComplete) {
      this._voiceService.endConference(this.activeConference.conferenceLineId)
        .subscribe(() => {
          this.cleanupAfterConferenceEnds();
        }, (err) => {
          this.cleanupAfterConferenceEnds();
          this._notifyService.showError(err.error.message || err.error || '', "Error");
        });
    }
  }

  cleanupAfterConferenceEnds = (): void => {
    this.activeConference = undefined;
    this.participantIdOfAgent = undefined;
    const isDialInProgress = false;
    this.setDialInProgress(isDialInProgress);

    this.participants = [];
    if (this.selectedRecordForCall) {
      this.selectedRecordForCall.callStatus = CallStatus.Disconnected;
      this.publishCallStatusChange(this.selectedRecordForCall.dialListRecordId, CallStatus.Disconnected);
    }
    this.warmTransferStatus = null;

    this.stopConferenceTimer();

    this.clearConferenceStartTime();
  }

  dialNext = (): void => {
    const isFromDialNext = true;
    this.removeActiveParticipantFromConference(isFromDialNext, this.dialNextAfterHangUpCurrent);
  }

  dialNextAfterHangUpCurrent = (): void => {
    // Add a safety delay to give time for Loda and twilio to have correct updated state.
    setTimeout(() => {
      this.selectedRecordForCall.callStatus = CallStatus.Completed;
      this.publishCallStatusChange(this.selectedRecordForCall.dialListRecordId, CallStatus.Completed);

      let currentRecordIndexOnDialList = this.selectedDialList.records.findIndex(x => x.dialListRecordId == this.selectedRecordForCall.dialListRecordId);

      if (this.selectedDialList.records.length === (currentRecordIndexOnDialList + 1)) {
        this.publish_ToggleDialListCall(this.selectedDialList);


        //move to next dial list
        var index = this.dialLists.findIndex(x => x.dialListId == this.selectedDialList.dialListId);
        if (index + 1 < this.dialLists.length) {

          this.selectedDialList = this.dialLists[++index];
          while (this.selectedDialList.records.length == 0 && index + 1 < this.dialLists.length)
            this.selectedDialList = this.dialLists[++index]

          if (this.selectedDialList.records.length == 0) {
            this._notifyService.showInfo("You've reached the end of the dial list. Please select another to begin again.", "Hey There!");
            return;
          }
          else {
            currentRecordIndexOnDialList = -1;
            this.publish_ToggleDialListCall(this.selectedDialList);
          }
        }
        else {
          this._notifyService.showInfo("You've reached the end of the dial list. Please select another to begin again.", "Hey There!");
          return;
        }
      }

      this.setSelectedRecordByIndex(++currentRecordIndexOnDialList);
      this.initializeFields();
      this.dialNumber();
    }, 100);
  }

  setSelectedRecordByIndex = (selectedRecordIndex: number) => {
    this.selectedRecordIndex = selectedRecordIndex;
    let selectedRecord = this.selectedDialList.records[selectedRecordIndex];
    this.selectedRecordForCall = selectedRecord;
    this.publish_selectedRecordForCall(selectedRecord);
    this.populatePhoneNumberOptionsForSms(this._selectedRecordForCall);
  }

  publish_ToggleDialListCall = (dialList: DialListBasic) => {
    this._dialerService.publish({
      eventType: DialerEventType.dialerv2_toggleList,
      data: dialList
    });
  }

  publish_selectedRecordForCall = (selectedRecord: DialListRecordBasic) => {
    this._dialerService.publish({
      eventType: DialerEventType.changedSelectedRecordForCall,
      data: selectedRecord
    });
  }

  numberToSmsChanged = (value: string): void => {
    this.numberToSendSmsTo = value;
  }

  hold = (participantId: number): void => {
    if (this.activeConference && participantId) {
      this._voiceService.changeConferenceStatus(this.activeConference.conferenceLineId, participantId, 'hold')
        .subscribe(() => {
          this.setParticipantHold(participantId, true);
        }, (err) => {
          this._notifyService.showError(err.error.message || err.error || '', "Error");
        });
    }
  }

  unHold = (participantId: number): void => {
    if (this.activeConference && participantId) {
      this._voiceService.changeConferenceStatus(this.activeConference.conferenceLineId, participantId, 'unhold')
        .subscribe(() => {
          this.setParticipantHold(participantId, false);
        }, (err) => {
          this._notifyService.showError(err.error.message || err.error || '', "Error");
        });
    }
  }

  setParticipantHold = (participantId: number, isOnHold: boolean): void => {
    if (this.participants) {
      let item = this.participants.find(x => x.conferenceParticipantId === participantId);
      if (item) {
        item.onHold = isOnHold;
      }
    }
  }
  muteDialer = (): void => {
    if (this.activeConference && this.participantIdOfAgent) {
      this._voiceService.changeConferenceStatus(this.activeConference.conferenceLineId, this.participantIdOfAgent, 'mute')
        .subscribe(() => {
          this.setParticipantMute(this.participantIdOfAgent, true, true);
        }, (err) => {
          this._notifyService.showError(err.error.message || err.error || '', "Error");
        });
    }
  }

  mute = (participantId: number): void => {
    if (this.activeConference && participantId) {
      this._voiceService.changeConferenceStatus(this.activeConference.conferenceLineId, participantId, 'mute')
        .subscribe(() => {
          this.setParticipantMute(participantId, true, false);
        }, (err) => {
          this._notifyService.showError(err.error.message || err.error || '', "Error");
        });
    }
  }

  unMuteDialer = (): void => {
    if (this.activeConference && this.participantIdOfAgent) {
      this._voiceService.changeConferenceStatus(this.activeConference.conferenceLineId, this.participantIdOfAgent, 'unmute')
        .subscribe(() => {
          this.setParticipantMute(this.participantIdOfAgent, false, true);
        }, (err) => {
          this._notifyService.showError(err.error.message || err.error || '', "Error");
        });
    }
  }

  unMute = (participantId: number): void => {
    if (this.activeConference && participantId) {
      this._voiceService.changeConferenceStatus(this.activeConference.conferenceLineId, participantId, 'unmute')
        .subscribe(() => {
          this.setParticipantMute(participantId, false, false);
        }, (err) => {
          this._notifyService.showError(err.error.message || err.error || '', "Error");
        });
    }
  }

  setParticipantMute = (participantId: number, isMuted: boolean, isDialerMute: boolean): void => {
    if (isDialerMute) {
      this.isDialerAgentMuted = isMuted;
    } else {
      if (this.participants) {
        let item = this.participants.find(x => x.conferenceParticipantId === participantId);
        if (item) {
          item.muted = isMuted;
        }
      }
    }
  }

  findOnDialListClicked = (): void => {
    if (this.selectedRecordForCall) {
      let message = {
        dialListId: this.selectedRecordForCall.dialListId,
        dialListRecordId: this.selectedRecordForCall.dialListRecordId
      }
      this._dialerService.publish({ eventType: DialerEventType.findOnDialList, data: message });
    }
  }

  warmTransferAccepted = (conferenceId, participantId, transferingAgentParticipantId, transferingAgentUserId, dialListId, dialListRecordId, leadRouteHistoryId) => {
    this.participants = [];
    this.activeParticipantId = participantId;
    let pName = this.fetchAgentName(transferingAgentUserId);
    let participantExists = this.participants.find(i => i.conferenceParticipantId == transferingAgentParticipantId);
    if (!participantExists) {
      this.addParticipantToList({
        conferenceParticipantId: transferingAgentParticipantId,
        callStatus: CallStatus.InProgress,
        participantName: pName
      } as ConferenceParticipant);

      this.addToRecordsDialed(
        {
          dialListId: dialListId,
          dialListRecordId: dialListRecordId,
          conferenceId: conferenceId
        } as DialListRecordBasic
      );
    }

    this.onWarmTransferAccepted({
      dialListId: dialListId,
      dialListRecordId: dialListRecordId,
      leadRouteHistoryId: leadRouteHistoryId
    });
  }

  onWarmTransferAccepted = (lead) => {
    console.log("onWarmTransferAccepted", _.clone(lead));
    if (lead === undefined)
      return;

    this.selectedDialList = undefined;
    setTimeout(() => {
      this.checkActiveConference(true).subscribe();
    }, 1000)
  }

  warmTransferCanceled = () => {
    console.log("onWarmTransferCanceled");
    this.activeConference = undefined;
    this.warmTransferStatus = undefined;
    this.pendingWarmTransferComplete = false;

    setTimeout(() => {
      this.checkActiveConference(true).subscribe();
    }, 1000)
  }

  warmTransferReceived = () => {
    console.log("warmTransferReceived");

  }

  warmTransferCompleted = (participantId: number, conferenceId: number) => {
    // participantId will be themselves + their new conferenceId for the agent who moved back to their own bridge.
    // 
    this.warmTransferStatus = undefined;
    this.resetToBridge();

    setTimeout(() => {
      this.checkActiveConference(true).subscribe();
    }, 2000);
  }

  resetToBridge() {
    this.participants = [];
  }

  private fetchAgentName = (leadUserId: string) => {
    if (!this.users) return '';
    let found = this.users.find(ele => ele.userCompanyGuid == leadUserId);
    if (found) {
      return found.firstName + ' ' + found.lastName;
    }
    return '';
  }

  addNote = () => {
    if (this._typeOfEntityBeingDialed == RecordType.Lead) {
      this.addLeadNote();
    } else if (this._typeOfEntityBeingDialed == RecordType.Borrower) {
      this.addBorrowerNote();
    } else if (this._typeOfEntityBeingDialed == RecordType.Agent) {
      this.addAgentNote();
    } else if (this._typeOfEntityBeingDialed == RecordType.Application) {
      this.addApplicationNote();
    }
  }

  publishingWarmXfer = false;
  publishWarmTransfer = () => {
    this.publishingWarmXfer = true;
    let participant = this.participants.find(p => (p.callStatus == CallStatus.Initiated || p.callStatus == CallStatus.InProgress) && !p.isAgent);
    if (!participant) {
      this._notifyService.showError('Unable to find an active customer call to warm transfer.', 'Error');
      this.publishingWarmXfer = false;
      return;
    }

    this._voiceService.publishWarmTransfer(this.activeConference.conferenceLineId)
      .subscribe(result => {
        if (this.warmTransferStatus !== WarmTransferStatus.Started) {
          this.warmTransferStatus = WarmTransferStatus.Published;
        }
        participant.onHold = true;
        // this.activeConference.participants = true;
        this.publishingWarmXfer = false;
        this._notifyService.showSuccess('Warm transfer published successfully.', 'Success');
      }, err => {
        this._notifyService.showError(err.error.message || err.error || '', 'Error');
        this.publishingWarmXfer = false;
      });
  }
  completingWarmTransfer = false;

  completeWarmTransfer = () => {
    if (!this.participants.find(p => p.callStatus == CallStatus.Initiated || p.callStatus == CallStatus.InProgress && p.isAgent && p.agentUserId != this.currentUserId)) {
      this._notifyService.showError('There is no agent on the call to complete the warm transfer with.', 'Error');
      return;
    }
    this.completingWarmTransfer = true;
    this._voiceService.completeWarmTransfer(this.activeConference.conferenceLineId)
      .subscribe(result => {
        this.completingWarmTransfer = false;
        this._voiceService.getActiveConference().subscribe(conference => {
          this.activeConference = conference;
          this.participantIdOfAgent = result.participantId;
          this.participants = [];
          this.warmTransferStatus = null;
          this.leadRouteHistoryId = undefined;
          this.setDialInProgress(false);
          if (this.selectedRecordForCall) {
            this.publishCallStatusChange(this.selectedRecordForCall?.dialListRecordId, CallStatus.Completed);
          }
          this.publishParticipantsChange();
        })

        this._notifyService.showSuccess('Warm transfer completed successfully.', 'Success');
      }, err => {
        this.completingWarmTransfer = false;
        this._notifyService.showError(err?.error?.message || err?.error || err?.message || '', 'Error');
      });
  }

  private addAgentAsParticipantForWarmTransfer = (model: any) => {
    let participantExists = this.participants.find(i => i.conferenceParticipantId == model.newAgentParticipantId);
    if (!participantExists) {
      let agentName = this.fetchAgentName(model.newAgentUserId);

      this.addParticipantToList({
        conferenceLineId: model.conferenceId,
        conferenceParticipantId: model.newAgentParticipantId,
        callStatus: CallStatus.InProgress,
        participantName: agentName,
        isAgent: true
      } as ConferenceParticipant);
    }
    else {
      participantExists.isAgent = true;
    }
    this.leadRouteHistoryId = model.leadRouteHistoryId;
    this.warmTransferStatus = WarmTransferStatus.Started;
  }

  onSendSmsClicked = () => {
    this.sendSmsClicked = true;

    if (this.dialerCallMessageForm) {

      this.dialerCallMessageForm.form.markAllAsTouched();

      if (this.dialerCallMessageForm.form.valid) {
        this.sending = true;
        this.sendSms();
      }
    }
  }

  createSmsEvent = (leadId: number) => {
    if (leadId) {
      let params = {
        leadId: leadId,
        type: "sms",
        note: 'Sent SMS To ' + this.numberToSendSmsTo + '. Message: ' + this.textMessage,
        leadStatusId: this.selectedRecordForCall.statusId
      } as LeadEvent;

      this._leadsService.addLeadEvent(params)
        .subscribe();
    }
  }

  checkActiveConference = (forceRun: boolean): Observable<any> => {

    if (!this.isPanelOpened || forceRun) {
      return this._voiceService.getActiveConference()
        .pipe(map(response => {
          if (response) {
            if (response.currentStatus === "NotInUse" && !response.participants.length) {
              this.closeCallPanel();
              return of(null);
            }

            this.activeConference = response;

            this.isPanelOpened = true;
            this.applicationContextService.toggleCallControlPanel(true);

            this.initializeConferenceTimer(this.activeConference.dateUpdated);


            let dialerParticipant = this.activeConference.participants.find(ele => ele.isAgent === true);
            if (!dialerParticipant) {
              console.log(`NO DIALER PARTICIPANT?`, this.activeConference);
              return;
            }

            this.participantIdOfAgent = dialerParticipant.conferenceParticipantId;
            this.conferenceStatus = dialerParticipant.callStatus;
            this.isDialerAgentMuted = dialerParticipant.muted;

            this.participants = [];

            if (this.activeConference.currentStatus === ConferenceStatus.CallActive) {
              if (this.activeConference.participants.length > 0) {//meaning active call exists
                console.log("Active Call Found. Reloading Call Data", _.clone(this.activeConference));

                this._dialerService.getDialerPageData().subscribe(pageData => {

                  this.dialLists = pageData.dialLists;
                  this.predefinedNotes = pageData.predefinedNotes;

                  this.isDialInProgress = true;

                  let dialListRecordId = null;
                  let activeParticipant = null;

                  if (!this.warmTransferStatus
                    && this.activeConference.participants.filter(x => x.isAgent && x.agentUserId != this.currentUserId).length > 0) {
                    this.warmTransferStatus = this.activeConference.agentUserId == this.currentUserId ? WarmTransferStatus.Started : WarmTransferStatus.Receiving;
                  }

                  this.activeConference.participants.forEach(participant => {
                    let participantExists = this.participants.find(i => i.conferenceParticipantId == participant.conferenceParticipantId);
                    if (!participantExists && participant.agentUserId != this.currentUserId) {
                      console.log(`Wtf, !participantExists ${!participantExists} && participant.agentUserId ${participant.agentUserId} != this.currentUserId ${this.currentUserId}`);
                       
                      this.addParticipantToList(participant);
                      if (!participant.agentUserId) {
                        this.activeParticipantId = participant.conferenceParticipantId;
                        activeParticipant = participant;
                      }
                      
                      this.callImmediate = false;
                      // this.fullNameOfPersonBeingDialed = participantName;

                      if (participant.voiceHistory?.dialListRecordId) {
                        dialListRecordId = participant.voiceHistory.dialListRecordId;
                        this.selectedDialList = this.dialLists.find(x => x.records.map(r => r.dialListRecordId).includes(dialListRecordId));
                        this._typeOfEntityBeingDialed = this.getRecordTypeByContactListId(participant.voiceHistory.contactListId);
                      }
                    }
                  });

                  if (this.manualDial) return;

                  var foundVoiceHistory = this.participants.find(x => !x.isAgent)?.voiceHistory;
                  if (!foundVoiceHistory || foundVoiceHistory.disposition != DialerDisposition.WarmTransferred) {
                    this.initialize();
                    return;
                  }

                  //hack to stop screen pop from happening for AFL on WT complete
                  if (this.applicationContext.userPermissions.companyId == 155) {
                    this.initializeFields();
                    return;
                  }

                  if (foundVoiceHistory.leadId) {
                    this.openEditorForLead(foundVoiceHistory.leadId);
                    return;
                  } else if (foundVoiceHistory.borrowerId) {
                    this.openEditorForBorrower(foundVoiceHistory.borrowerId);
                    return;
                  } else if (foundVoiceHistory.applicationId) {
                    this.openEditorForApplication(foundVoiceHistory.applicationId);
                    return;
                  } else if (foundVoiceHistory.agentId) {
                    this.openEditorForAgent(foundVoiceHistory.agentId);
                    return;
                  }
                  else if (foundVoiceHistory.creditMonitoringDataId) { }
                  else if (foundVoiceHistory.externalCompanyId) { }
                  else if (foundVoiceHistory.userCompanyGuid) { }
                  else if (foundVoiceHistory.contactListId) { }
                  else if (foundVoiceHistory.voiceHistoryId) { }
                  else if (foundVoiceHistory.conversationId) { }

                  this.initializeFields();
                })
              }
            }

          } else {
            this.closeCallPanel();
          }
          return response;
        }))
    } else {
      return of(null);
    }
  }

  closeCallPanel = () => {
    this.applicationContextService.toggleCallControlPanel(false);
    this._dialerService.publish({
      eventType: DialerEventType.closeCallPanel,
      data: {}
    });
  }

  getPhoneType = (number: string, record: DialListRecordBasic): PhoneType => {
    let numberToMatch = this.formatPhoneNumber(number);
    let mobilePhone = this.formatPhoneNumber(record.mobilePhone.trim());
    if (mobilePhone === numberToMatch) {
      return PhoneType.mobile;
    }
    let homePhone = this.formatPhoneNumber(record.homePhone.trim());
    if (homePhone === numberToMatch) {
      return PhoneType.home;
    }
    let workPhone = this.formatPhoneNumber(record.workPhone.trim());
    if (workPhone === numberToMatch) {
      return PhoneType.work;
    }
    return null;
  }

  getRecordTypeByContactListId = (contactListId: number): RecordType => {
    switch (contactListId) {
      case 1:
        return RecordType.Application;
      case 2:
        return RecordType.Agent;
      case 3:
        return RecordType.Borrower;
      case 4:
        return RecordType.Lead;
      default:
        console.log("Unable to determine record type from contactListId", contactListId);
        return undefined;
    }
  }

  private getAppropriateEntityIdFieldForSmsMessage = (): string => {
    if (this._typeOfEntityBeingDialed === RecordType.Lead) {
      return "leadId";
    } else if (this._typeOfEntityBeingDialed === RecordType.Borrower) {
      return "borrowerId";
    } else if (this._typeOfEntityBeingDialed === RecordType.Agent) {
      return "agentId";
    } else if (this._typeOfEntityBeingDialed === RecordType.Application) {
      return "applicationId";
    }
  }

  relatedEntities = {};
  loadingRelatedEntities = false;
  openRelatedEntities = (participant: ConferenceParticipant) => {
    this.relatedEntities = {};
    this.loadingRelatedEntities = true;
    this._conversationService.getRelatedEntities(participant.phoneNumber).subscribe(related => {
      this.relatedEntities = related;
      this.loadingRelatedEntities = false;
    });
  }

  getDateDiffInMinutes = (dt2: Date, dt1: Date): number => {
    let diff = (dt2.getTime() - dt1.getTime()) / 1000;
    //diff /= 60;
    return Math.abs(Math.round(diff));
  }

  private handleNewConferenceResponse(response: StartConferenceResponse) {
    this.activeConference = response.conferenceLine;

    this.startConferenceTimer();
    for (let participant of response.conferenceLine.participants.filter(p => p.agentUserId !== this.currentUserId)) {
      this.addParticipantToList(participant);
    }

    this.conferenceEnded = false;
    this.publishParticipantsChange();
  }

  initializeConferenceTimer(conferenceStartDate: Date): void {
    this.conferenceStartTime = this.setConferenceStartTime(new Date(conferenceStartDate));
    this.confTimerCounter = this.getDateDiffInMinutes(new Date(), new Date(conferenceStartDate));
    this.conferenceTimer = this.padNumberTwoDigit(parseInt((this.confTimerCounter / 60).toString())).toString() + ':' + this.padNumberTwoDigit(this.confTimerCounter % 60);
  }

  getParticipantName = (voiceHistory: VoiceHistory): string => {
    if (!voiceHistory) {
      return "";
    }
    const participantName = Utils.getFullName(voiceHistory.recordFirstName, voiceHistory.recordLastName);
    return participantName;
  }

  manualDialControl = (): Observable<VoiceResponse> => {
    if (this.manualDial === true && this.manualDialParams) {
      return this.manualDialNumber();
    } else {
      return of(null);
    }
  }

  manualDialNumber = (): Observable<VoiceResponse> => {

    let request = {
      phoneNumber: this.formatPhoneNumber(this.manualDialParams.phoneNumber),
    } as ManualCallRequest

    this._typeOfEntityBeingDialed = this.manualDialParams.recordType;
    this._idOfEntityBeingDialed = this.manualDialParams.recordId;
    let idFieldName = Utils.getRecordIdFieldName(this._typeOfEntityBeingDialed);

    if (this.dialUsingTelephonyPoolId > 0)
      request.telephonyPoolId = this.dialUsingTelephonyPoolId;

    request[idFieldName] = this._idOfEntityBeingDialed;
    if (this._typeOfEntityBeingDialed != RecordType.Application && this.manualDialParams.applicationId) {
      request.applicationId = this.manualDialParams.applicationId;
    }

    return this._voiceService.manualCallNumber(request)
      .pipe(
        map(voiceResponse => {
          this.activeConference = voiceResponse.conferenceLine;
          this.onAfterNumberDialed(voiceResponse);
          console.log(`after manual call number`, this.activeConference, this.participants.map(p => _.clone(p)));
          return voiceResponse;
        }),
        catchError(err => {
          if (err.error == "Unable to find conference line that this conference belongs to.") {
            this.activeConference = undefined;
          }
          throw err.error;
        }));
  }

  onAfterNumberDialed = (voiceResponse: VoiceResponse) => {

    this.activeParticipantId = voiceResponse.participantId;

    this.populateParticipantsForManualDial(this.fullNameOfPersonBeingDialed, voiceResponse.voiceHistory);
    this.setIdAndTypeOfEntityBeingDialed(voiceResponse.voiceHistory);

    this._phoneTypeToDial = this.manualDialParams.phoneType;

    let selectedRecord = null;
    if (voiceResponse.voiceHistory.dialListRecordId) {
      this.dialLists.every(dialList => {
        selectedRecord = dialList.records.find(record => record.dialListRecordId === voiceResponse.voiceHistory.dialListRecordId);
        if (selectedRecord) {
          selectedRecord.firstName = this.manualDialParams.firstName;
          selectedRecord.lastName = this.manualDialParams.lastName;
          selectedRecord.recordType = this.manualDialParams.recordType;
          selectedRecord.phoneNumberToCall = this.manualDialParams.phoneNumber;
          selectedRecord.dialListRecordId = voiceResponse.voiceHistory.dialListRecordId;
          let idFieldName = Utils.getRecordIdFieldName(this.manualDialParams.recordType);
          selectedRecord[idFieldName] = this.manualDialParams.recordId;
          this.selectedDialList = dialList;
          this.selectedRecordForCall = selectedRecord;
          return false;
        }
        return true;
      });
    }


  }

  openPredefinedNotes = () => {

    // new add or (select and save)

    // const modalRef = this._modalService.open(PredefinedNoteDialogComponent, Constants.modalOptions.medium);
    // modalRef.componentInstance.predefinedNotes = this.predefinedNotesList;

    // modalRef.result.then((data) => {
    //   this.internalMessage.content = data ? data.text : '';
    // });

  }

  moveParticipantToEnd = (participantId) => {
    let fromIndex = this.participants.findIndex(ele => ele.conferenceParticipantId === participantId);
    if (fromIndex > -1) {
      let toIndex = this.participants.length - 1;
      let participant = this.participants[fromIndex];
      this.participants.splice(fromIndex, 1);
      this.participants.splice(toIndex, 0, participant);
    }
  }

  participantCallCompleted = (participant) => {
    participant.callStatus = CallStatus.Completed;
    participant.phoneType = participant.phoneType || this.getPhoneTypeFromRecord(this.selectedRecordForCall, this._phoneTypeToDial);

    this.publishParticipantsChange();

    this.moveParticipantToEnd(participant.participantId);
    this.removeParticipant(participant.participantId);
    this._dialerService.publish({
      eventType: DialerEventType.CallCompleted,
      data: participant
    });
  }

  updateConferenceParticipantStatus = (conferenceId: number, participantId: number, conferenceParticipantEvent: ConferenceParticipantEvent, conferenceParticipant: ConferenceParticipant) => {
    console.log(`updateConferenceParticipantStatus - ${(!!this.participants ? this.participants.length : 'no')} participants: `, this.participants);

    if (!this.activeConference) {
      if (conferenceParticipantEvent === ConferenceParticipantEvent.Join) {
        console.log(`Queueing an active conference re-check in 500ms because we got a notification event that a conference user joined.`, conferenceParticipant);
        setTimeout(() => {
          const forceRun = true;
          this.checkActiveConference(forceRun).subscribe();
        }, 500);
        return;
      }

      console.log(`received an event ${conferenceParticipantEvent} and dont have an active conference, ignoring...`);
      return;
    }
    if (conferenceId != this.activeConference.conferenceLineId) {
      console.log(`received an event ${conferenceParticipantEvent} for not active conference, ignoring...`);
      return;
    }

    let participant = this.participants.find(x => x.conferenceLineId === conferenceId && x.conferenceParticipantId === participantId);
    if (!participant) {
      // console.log(`No participant found for conf ${conferenceId}, part ${participantId}. ActiveConf:`);
      // console.log(this.activeConference);
      // console.log("this.participants:");
      // console.log(this.participants);
      // if (!!conferenceParticipant) {
      //   if (!!this.participants)
      //     this.participants = [];

      if (this.participants 
        && conferenceParticipant.agentUserId !== this.currentUserId
        && conferenceParticipantEvent !== ConferenceParticipantEvent.Leave 
        && conferenceParticipantEvent !== ConferenceParticipantEvent.SpeechStop
      ) {
        console.log(`received an event ${conferenceParticipantEvent} that is resulting in us adding a participant to our list because no participant found with conf ${conferenceId} and partId ${participantId}`, conferenceParticipant);
        this.addParticipantToList(conferenceParticipant);
        participant = this.participants.find(x => x.conferenceLineId === conferenceId && x.conferenceParticipantId === participantId)
      }
      else if (conferenceParticipant.agentUserId === this.currentUserId) {
        this.handleDialerAgentParticipantStatusUpdates(conferenceParticipant, conferenceParticipantEvent);
        // everything else in here is for other participants, not us.
        return;
      }
    }

    if (!participant)
      return;

    if (conferenceParticipantEvent == ConferenceParticipantEvent.SpeechStart || conferenceParticipantEvent == ConferenceParticipantEvent.SpeechStop) {
      participant.isSpeaking = conferenceParticipantEvent == ConferenceParticipantEvent.SpeechStart;
      console.log(`participant ${participant.agentUserId ?? participant.conferenceParticipantId ?? participant.thirdPartyCallId} is speaking? ${participant.isSpeaking} (based on ${conferenceParticipantEvent})`, participant);
    }
    else if (conferenceParticipantEvent == ConferenceParticipantEvent.OnHold || conferenceParticipantEvent == ConferenceParticipantEvent.UnHold)
      participant.onHold = conferenceParticipantEvent == ConferenceParticipantEvent.OnHold;
    else if (conferenceParticipantEvent == ConferenceParticipantEvent.OnMute || conferenceParticipantEvent == ConferenceParticipantEvent.UnMute)
      participant.muted = conferenceParticipantEvent == ConferenceParticipantEvent.OnMute;
    else if (conferenceParticipantEvent == ConferenceParticipantEvent.Leave)
      this.removeParticipant(participantId);
    else {
      console.log(`...? Unhandled event ${conferenceParticipantEvent}..? For participant ${participant.agentUserId ?? participant.thirdPartyCallId}, `, conferenceParticipantEvent);
    }

    // TODO deal with other participants timers... Join?

    this.publishParticipantsChange();
  }

  handleDialerAgentParticipantStatusUpdates(conferenceParticipant: ConferenceParticipant, conferenceParticipantEvent: ConferenceParticipantEvent) {
    if (conferenceParticipantEvent == ConferenceParticipantEvent.Join)
      this.startConferenceTimer();
    else if (conferenceParticipantEvent == ConferenceParticipantEvent.Leave)
      this.stopConferenceTimer();
    else if (conferenceParticipantEvent == ConferenceParticipantEvent.OnMute) {
      console.log(`Muting dialer agent...`);
      this.isDialerAgentMuted = true;
    }
    else if (conferenceParticipantEvent == ConferenceParticipantEvent.UnMute) {
      console.log(`Unmuting dialer agent...`);
      this.isDialerAgentMuted = false;
    }
    else if (conferenceParticipantEvent == ConferenceParticipantEvent.SpeechStart)
      this.isDialerAgentSpeaking = true;
    else if (conferenceParticipantEvent == ConferenceParticipantEvent.SpeechStop)
      this.isDialerAgentSpeaking = false;
    else {
      // Hold unhold is weird.
      console.log(`...? Unhandled event ${conferenceParticipantEvent}..? For the DIALER participant.`, conferenceParticipantEvent, conferenceParticipant);
    }
    
    // Do we need this to trigger UI update? Prob not..?
    // this.publishParticipantsChange();
  }

  updateCallStatus = (conferenceId: number, participantId: number, callStatus: CallStatus, conferenceParticipant: ConferenceParticipant) => {
    if (!this.activeConference) {
      console.log("received an event and dont have an active conference, ignoring...");
      return;
    }

    if (conferenceId != this.activeConference.conferenceLineId) {
      console.log("received an event for not active conference, ignoring...");
      return;
    }

    // Note the call status could be for someone we intended to have not be in this conference anymore, though.

    const isCompletedStatus = this.completedStatuses.includes(callStatus);

    let participant = this.participants.find(x => x.conferenceLineId === conferenceId && x.conferenceParticipantId === participantId);
    if (!participant) {
      if (!!conferenceParticipant && this.currentUserId !== conferenceParticipant.agentUserId && !isCompletedStatus) {
        this.addParticipantToList(conferenceParticipant);
      }
    }
    else {
      if (participantId === this.participantIdOfAgent) {
        this.conferenceStatus = callStatus;
      }

      participant.callStatus = callStatus;

      if (isCompletedStatus) {
        // Dont instantly clear them off the bar, lets highlight them red or something for 4 seconds instead so users can see the call failed, was busy, or they hung up, etc.
        setTimeout(() => {
          this.removeParticipant(participantId);
        }, 3000);
      }

      participant.phoneType = this.manualDialParams?.phoneType || participant.phoneType || this.getPhoneTypeFromRecord(this.selectedRecordForCall, null);

      this.publishParticipantsChange()
    }
  }

  hangUpByParticipantId = (participantId: number, silentFail: boolean = false) => {
    let participant = this.participants.find(x => x.conferenceParticipantId === participantId);
    if (!participant && !silentFail) {
      return throwError("Can not find an active call for participantId " + participantId + ".");
    }
    else {
      return this._voiceService.removeActiveParticipantFromConference(this.activeConference.conferenceLineId, participantId)
        .pipe(
          finalize(() => {
            this.participantCallCompleted(participant);
          })
        );
    }
  }

  listener_loadCallPanel = (data) => {

    this.isPanelOpened = data.isPanelOpened;
    this.applicationContextService.toggleCallControlPanel(this.isPanelOpened);

    this.manualDial = data.manualDial;
    this.manualDialParams = data.manualDialParams;

    this.callImmediate = data.callImmediate;
    this.fetchImmediate = data.fetchImmediate;

    if (data.selectedDialList) {
      this.selectedDialList = data.selectedDialList;
    }

    if (data.predefinedNotes) {
      this.predefinedNotes = data.predefinedNotes;
    }

    if (data.dialLists) {
      this.dialLists = data.dialLists;
    }

    if (data.selectedRecord && !this.fetchImmediate) {
      this.selectedRecordForCall = data.selectedRecord;
      this.selectedRecordForCall.selectedPhoneType = data.phoneTypeToDial;
    }

    if (data.phoneTypeToDial) {
      this._phoneTypeToDial = data.phoneTypeToDial;
    }

    this.initialize();
  }

  publishCallStatusChange = (dialListRecordId: number, callStatus: CallStatus) => {
    const phoneTypeToDial = this.selectedRecordForCall?.selectedPhoneType || this._phoneTypeToDial;
    this._dialerService.publish({
      eventType: DialerEventType.changeRecordCallStatus,
      data: {
        dialListRecordId: dialListRecordId,
        callStatus: callStatus,
        phoneType: phoneTypeToDial
      }
    });
  }

  publishParticipantsChange = () => {
    this._dialerService.publish({
      eventType: DialerEventType.changeParticipants,
      data: this.participants
    });
  }

  listener_conferenceEnded = (data) => {
    if (this.activeConference && this.activeConference.conferenceLineId && this.activeConference.conferenceLineId !== data.conferenceId) {
      console.log("Received Conference Event for non-current conference. Ignoring!!");
      return;
    } 

    this.conferenceStatus = CallStatus.Completed;
    this.activeConference = undefined;
    this.participants.forEach(participant => {
      if (participant.conferenceParticipantId !== this.participantIdOfAgent) {
        this.participantCallCompleted(participant);
      }
    });
    let confLineParticipant = this.participants.find(x => x.conferenceParticipantId == this.participantIdOfAgent);
    this.participantIdOfAgent = undefined;
    if (confLineParticipant) {
      this.participantCallCompleted(confLineParticipant);
    }
    this.cleanupAfterConferenceEnds();
  }

  listener_conferenceParticipantStatusChanged = (data) => {
    console.log("listener_conferenceParticipantStatusChanged", data);
    this.updateConferenceParticipantStatus(data.conferenceId, data.participantId, data.conferenceParticipantEvent, data.conferenceParticipant);
  }

  listener_callStatusChanged = (data) => {
    console.log(`PRE callStatusChanged ${data.callStatus}: `, this.participants.map(p => _.clone(p)));
    this.updateCallStatus(data.conferenceId, data.participantId, data.callStatus, data.conferenceParticipant);
    console.log(`POST callStatusChanged ${data.callStatus}: `, this.participants.map(p => _.clone(p)));
  }

  listener_currentCallEnded = (data?: any) => {
    console.log('listener_currentCallEnded entered', data);
    this.cleanupAfterConferenceEnds();
    this.closeCallPanel();
  }

  endCurrentCall = (data?: any) => {
    console.log('endCurrentCall entered', data);
    const isFromDialNext = false;
    this.removeActiveParticipantFromConference(isFromDialNext);
    this.listener_currentCallEnded(data);
  }

  listener_warmTransferAccepted = (data) => {//for supervisor
    console.log('listener_warmTransferAccepted entered', data);
    this.pendingWarmTransferComplete = true;
    this.leadRouteHistoryId = data.leadRouteHistoryId;
    this._voiceService.getActiveConference().subscribe(conferenceLine => {
      this.activeConference = conferenceLine;
      this.warmTransferAccepted(
        data.conferenceId,
        data.participantId,
        data.transferingAgentParticipantId,
        data.transferingAgentUserId,
        data.dialListId,
        data.dialListRecordId,
        data.leadRouteHistoryId);

      this.isCurrentlyDialing = false;
    });
  }

  listener_warmTransferConnected = (data) => {//for supervisor
    console.log('listener_warmTransferConnected entered', data);
    this.addAgentAsParticipantForWarmTransfer(data);
  }

  listener_warmTransferCompleted = (data) => {
    console.log('listener_warmTransferCompleted entered', data);
    this.pendingWarmTransferComplete = false;
    this.warmTransferCompleted(data.participantId, data.conferenceId);
  }

  listener_warmTransferCanceled = (data) => {
    console.log('listener_warmTransferCanceled entered', data);
    this.warmTransferCanceled();
  }

  listener_warmTransferReceived = (data) => {
    console.log('listener_warmTransferReceived entered', data);
    this.warmTransferReceived();
  }

  listener_dialUsingChanged = (data) => {
    console.log('listener_dialUsingChanged entered', data);
    this.dialUsingTelephonyPoolId = data;
  }

  listener_screenPopRecord = (data) => {
    console.log('listener_screenPopRecord entered', data);
    if (data.leadId) {
      this.openEditorForLead(data.leadId);
      return;
    } else if (data.borrowerId) {
      this.openEditorForBorrower(data.borrowerId);
      return;
    } else if (data.applicationId) {
      this.openEditorForApplication(data.applicationId);
      return;
    } else if (data.agentId) {
      this.openEditorForAgent(data.agentId);
      return;
    }
    else if (data.creditMonitoringDataId) { }
    else if (data.externalCompanyId) { }
    else if (data.userCompanyGuid) { }
    else if (data.contactListId) { }
    else if (data.voiceHistoryId) { }
    else if (data.conversationId) { }

  }
  private onAfterParticipantRemovedFromConference = (activeParticipantId: number,
    isFromDialNext: boolean, callback?: Function, disconnectCurrentCall: boolean = true) => {
    this.activeParticipantId = undefined;
    this.removeParticipant(activeParticipantId);
    if (this.selectedRecordForCall && disconnectCurrentCall) {
      this.selectedRecordForCall.callStatus = CallStatus.Disconnected;
      this.publishCallStatusChange(this.selectedRecordForCall.dialListRecordId, CallStatus.Disconnected);
    }

    if (!isFromDialNext) {
      this.setDialInProgress(false);
    }
    else {
      callback();
    }
  }

  private setIdAndTypeOfEntityBeingDialed = (voiceHistory: VoiceHistory) => {
    this.fullNameOfPersonBeingDialed = voiceHistory.recordFirstName + " " + voiceHistory.recordLastName;

    if (voiceHistory.leadId) {
      this._idOfEntityBeingDialed = voiceHistory.leadId;
      this._typeOfEntityBeingDialed = RecordType.Lead;
    } else if (voiceHistory.applicationId) {
      this._idOfEntityBeingDialed = voiceHistory.applicationId;
      this._typeOfEntityBeingDialed = RecordType.Application;
    } else if (voiceHistory.borrowerId) {
      this._idOfEntityBeingDialed = voiceHistory.borrowerId;
      this._typeOfEntityBeingDialed = RecordType.Borrower;
    } else if (voiceHistory.agentId) {
      this._idOfEntityBeingDialed = voiceHistory.agentId;
      this._typeOfEntityBeingDialed = RecordType.Agent;
    }
  }

  private setIdAndTypeOfEntityBeingDialedFromRecord = (record: DialListRecordBasic): void => {
    if (record.leadId) {
      this._idOfEntityBeingDialed = record.leadId;
      this._typeOfEntityBeingDialed = RecordType.Lead;
    } else if (record.borrowerId) {
      this._idOfEntityBeingDialed = record.borrowerId;
      this._typeOfEntityBeingDialed = RecordType.Borrower;
    } else if (record.agentId) {
      this._idOfEntityBeingDialed = record.agentId;
      this._typeOfEntityBeingDialed = RecordType.Agent;
    } else if (record.applicationId) {
      this._idOfEntityBeingDialed = record.applicationId;
      this._typeOfEntityBeingDialed = RecordType.Application;
    }
  }

  private sendSms = () => {

    let smsPayload = new SendSms();

    const entityIdField = this.getAppropriateEntityIdFieldForSmsMessage()
    smsPayload[entityIdField] = this._idOfEntityBeingDialed;
    smsPayload.to = this.numberToSendSmsTo;
    smsPayload.body = this.textMessage;
    smsPayload.from = this.currentUserPhoneNumber ? Utils.cleanFormatedPhoneNumber(this.currentUserPhoneNumber) : "";

    this._spinnerService.show();

    let observable;
    if (this._typeOfEntityBeingDialed != RecordType.Lead && this._typeOfEntityBeingDialed != RecordType.Application &&
      this._typeOfEntityBeingDialed != RecordType.Borrower && this._typeOfEntityBeingDialed != RecordType.Agent) {
      smsPayload.from = this._typeOfEntityBeingDialed;
      observable = this._chatService.sendSms(smsPayload);
    } else {
      observable = this._chatService.queueSms(smsPayload);
    }

    observable.subscribe(() => {
      this._spinnerService.hide();
      this.onAfterSmsSuccessfullySent();
      this._notifyService.showSuccess("SMS has been successfully sent.", "Success!");
    }, (err) => {
      this._spinnerService.hide();
      this.sending = false;
      this.sendSmsClicked = false;
      this._notifyService.showError(err.error.message || err.message, "Error!");
    });
  }

  private cleanUpAfterOtherPartyHangsUp = () => {
    this.removeParticipant(this.activeParticipantId);
    this.activeParticipantId = undefined;

    if (this.selectedRecordForCall) {
      this.selectedRecordForCall.callStatus = CallStatus.Disconnected;
      this.publishCallStatusChange(this.selectedRecordForCall.dialListRecordId, CallStatus.Disconnected);
    }

    const isDialInProgress = false;
    this.setDialInProgress(isDialInProgress);
  }

  private onAfterSmsSuccessfullySent = (message: Message = null) => {
    if (this._typeOfEntityBeingDialed === RecordType.Lead && message) {
      this.createSmsEvent(this._idOfEntityBeingDialed);
      this._dialerService.publish({
        eventType: DialerEventType.LeadQuickSMSSended,
        data: message
      });
    }
    this._notifyService.showSuccess("Sms successfully sent!", "Success");
    this.textMessage = "";
    this.sending = false;
    this.sendSmsClicked = false;
    this.dialerCallMessageForm.form.markAsPristine();
    this.dialerCallMessageForm.form.markAsUntouched();
    this._dialerService.publish({ eventType: DialerEventType.quickSmsSent, data: {} });
  }

  private getPhoneTypeFromRecord = (record: DialListRecordBasic, phoneType: string): PhoneType => {
    if (!record) {
      return null;
    }

    if (!phoneType) {
      if (record.mobilePhone && record.mobilePhone.trim().length > 0)
        return PhoneType.mobile;
      if (record.homePhone && record.homePhone.trim().length > 0)
        return PhoneType.home;
      if (record.workPhone && record.workPhone.trim().length > 0)
        return PhoneType.work;
    }

    if (phoneType === 'mobile' && record.mobilePhone && record.mobilePhone.trim().length > 0)
      return PhoneType.mobile;
    if (phoneType === 'home' && record.homePhone && record.homePhone.trim().length > 0)
      return PhoneType.home;
    if (phoneType === 'work' && record.workPhone && record.workPhone.trim().length > 0)
      return PhoneType.work;
    if (record && record.mobilePhone && record.mobilePhone.trim().length > 0) {
      return PhoneType.mobile;
    }

    return null;
  }

  private doPopulatePhoneNumberOptionsForSms = (record: DialListRecordBasic, recordDetails: any) => {
    this.possibleNumbersToSendSmsTo.borrowerName = (recordDetails.firstName || recordDetails.lastName) ? `${recordDetails.firstName || ''} ${recordDetails.lastName || ''}` : null;
    if (recordDetails.mobilePhone) {
      this.possibleNumbersToSendSmsTo["borrowerMobileNumber"] = this.formatPhoneNumber(recordDetails.mobilePhone);
    }
    if (recordDetails.phone) {
      this.possibleNumbersToSendSmsTo["borrowerHomeNumber"] = this.formatPhoneNumber(recordDetails.phone);
    } else if (recordDetails.homePhone) {
      this.possibleNumbersToSendSmsTo["borrowerHomeNumber"] = this.formatPhoneNumber(recordDetails.homePhone);
    }
    if (recordDetails.coFirstName || recordDetails.coLastName) {
      this.possibleNumbersToSendSmsTo["coBorrowerName"] = (recordDetails.coFirstName || recordDetails.coLastName) ? `${recordDetails.coFirstName || ''} ${recordDetails.coLastName || ''}` : null;
      if (recordDetails.coMobilePhone) {
        this.possibleNumbersToSendSmsTo["coBorrowerMobileNumber"] = this.formatPhoneNumber(recordDetails.coMobilePhone);
      }
      if (recordDetails.coPhone) {
        this.possibleNumbersToSendSmsTo["coBorrowerHomeNumber"] = this.formatPhoneNumber(recordDetails.coPhone);
      }
    }
    if (record.phoneNumberToCall) {
      this.numberToSendSmsTo = record.phoneNumberToCall;
    } else {
      this.numberToSendSmsTo = this.possibleNumbersToSendSmsTo["borrowerMobileNumber"];
    }
  }

  private addApplicationNote = () => {
    this._spinnerService.show();

    const internalMessage = new InternalMessage();
    internalMessage.content = this.note;
    internalMessage.applicationId = this._idOfEntityBeingDialed;
    this._messageService.postInternalMessage(internalMessage).subscribe((response) => {
      if (response !== null && response !== undefined) {
        this.onAfterNoteSuccessfullyAdded(new Date());
      }
      this._spinnerService.hide();
    }, (err) => {
      this._notifyService.showError(err.message || err, 'Error');
      this._spinnerService.hide();
    });
  }

  private addAgentNote = () => {
    this._spinnerService.show();

    let note = new AgentNote();
    note.content = this.note;
    note.agentId = this._idOfEntityBeingDialed;

    this._agentsService.postAgentNote(this._idOfEntityBeingDialed, note)
      .subscribe(result => { // notice: result does not return any data
        this.onAfterNoteSuccessfullyAdded(new Date());
        this.note = "";
      }, (err) => {
        this._notifyService.showError(err.message || err, 'Error');
        this._spinnerService.hide();
      });
  }

  private addBorrowerNote = () => {
    this._spinnerService.show();

    let note = new BorrowerNote();
    note.content = this.note;
    note.borrowerId = this._idOfEntityBeingDialed;

    this._borrowersService.postBorrowerNote(this._idOfEntityBeingDialed, note)
      .subscribe(result => { // notice: result does not return any data

        this.onAfterNoteSuccessfullyAdded(new Date());

      }, (err) => {
        this._notifyService.showError(err.message || err, 'Error');
        this._spinnerService.hide();
      });
  }

  private addLeadNote = () => {
    let params = {
      leadId: this._idOfEntityBeingDialed,
      type: 'note',
      note: this.note,
      leadStatusId: this.selectedRecordForCall?.recordTypeStatusId
    } as LeadEvent;

    this._spinnerService.show();

    this._leadsService.addLeadEvent(params)
      .subscribe(result => {

        this._dialerService.publish({
          eventType: DialerEventType.LeadQuickNoteAdded,
          data: params
        });

        this.onAfterNoteSuccessfullyAdded(new Date(result.dateInserted));

      }, (err) => {
        this._notifyService.showError(err.message || err, 'Error');
        this._spinnerService.hide();
      });
  }

  private onAfterNoteSuccessfullyAdded = (dateAdded: Date) => {
    this._spinnerService.hide();
    this._notifyService.showSuccess("Successfully saved message.", "Success!");
    this.latestNote = this.note;
    this.latestNoteTime = DateTime.fromJSDate(dateAdded).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS);
    this.note = "";
  }

  private areWeOnDialerPage = (): boolean => {
    return location.pathname.toLocaleLowerCase().includes('dialer');
  }

  private openEditorForApplication = (applicationId: number) => {
    const observer: Observer<LoanApplication> = {
      next: (application: LoanApplication) => {
        const dynamicComponentInfo = new DynamicComponentInfo();
        dynamicComponentInfo.componentType = LoanSummaryMortgageComponent;
        dynamicComponentInfo.parameters.set('applicationOutsideOfContext', application);
        dynamicComponentInfo.parameters.set('isUsedAsChildComponent', true);
        this._drawerService.show('editorForPartyBeingCalledDrawer', 100, 'Editing Application', dynamicComponentInfo).then(() => {
        });
      },
      error: (err: any) => {
        throw new Error('Function not implemented.');
      },
      complete: () => {
      }
    }

    this._spinnerService.show();
    this._loanService.getApplicationModel(applicationId, true).subscribe(observer)
      .add(() => this._spinnerService.hide());
  }

  private openEditorForLead = (leadId: number) => {
    const observer: Observer<Lead> = {
      next: (lead: Lead) => {
        const dynamicComponentInfo = new DynamicComponentInfo();
        dynamicComponentInfo.componentType = ViewLeadDrawerComponent;
        dynamicComponentInfo.parameters.set('lead', lead);
        dynamicComponentInfo.parameters.set('isDrawer', true);
        const fullName = Utils.getPersonsFullName(lead);
        this._drawerService.show('editorForPartyBeingCalledDrawer', 100, 'Editing Lead - ' + fullName, dynamicComponentInfo).then(() => {
        });
      },
      error: (err: any) => {
        throw new Error('Function not implemented.');
      },
      complete: () => {
      }
    }

    this._spinnerService.show();
    this._leadsService.getLead(leadId).subscribe(observer)
      .add(() => this._spinnerService.hide());
  }

  private openEditorForBorrower = (borrowerId: number) => {
    const observer: Observer<BorrowerFull> = {
      next: (borrower: BorrowerFull) => {
        const dynamicComponentInfo = new DynamicComponentInfo();
        dynamicComponentInfo.componentType = BorrowerEditorComponent;
        dynamicComponentInfo.parameters.set("loanId", borrower.borrower.applicationId);
        dynamicComponentInfo.parameters.set("borrowerId", borrowerId);
        const fullName = Utils.getPersonsFullName(borrower.borrower);
        this._drawerService.show("editorForPartyBeingCalledDrawer", 100, "Editing Borrower - " + fullName, dynamicComponentInfo);
      },
      error: (err: any) => {
        throw new Error('Function not implemented.');
      },
      complete: () => {
      }
    }

    this._spinnerService.show();
    this._borrowersService.getBorrower(borrowerId, true).subscribe(observer)
      .add(() => this._spinnerService.hide());
  }

  private openEditorForAgent = (agentId: number) => {
    const dynamicComponentInfo = new DynamicComponentInfo();
    dynamicComponentInfo.componentType = UpsertAgentComponent;
    dynamicComponentInfo.parameters.set("agentId", agentId);
    dynamicComponentInfo.parameters.set("isDrawer", true);
    this._drawerService.show("editorForPartyBeingCalledDrawer", 100, "Editing Agent", dynamicComponentInfo);
  }
}
export class PersonOnCall {
  firstName: string;
  lastName: string;
}
