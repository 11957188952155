import {Component, Input, OnInit} from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {NotificationService} from 'src/app/services/notification.service';
import {FileService} from 'src/app/services/file.service';
import {DocumentService} from 'src/app/core/services/document.service';

@Component({
  selector: 'app-pdf-password-modal',
  templateUrl: './pdf-password-modal.component.html',
  styleUrls: ['./pdf-password-modal.component.scss']
})
export class PdfPasswordModalComponent implements OnInit {
  @Input() file: File;
  password: string = '';


  constructor(public activeModal: NgbActiveModal,
    private readonly _fileService: FileService,
    private readonly _documentService: DocumentService,
    private readonly _notificationService: NotificationService,) { }

  ngOnInit(): void {}

  closeModal(): void {
    this.activeModal.dismiss(); // Close the modal without returning anything
  }

  submitPassword(): void {
    this._fileService.removePdfPassword(this.file, this.password).subscribe({
      next: (updatedFile) => {
        const blob = this._documentService.convertBase64ToBlob(updatedFile.updatedPdfBytes);

        const processedFile = new File([blob], this.file.name, {
          type: 'application/pdf',
          lastModified: Date.now(),
        });

        this._notificationService.showSuccess(`Password removed successfully for file "${this.file.name}"`, "Success!");

        this.activeModal.close(processedFile);
      },
      error: (err) => {
        console.error(`Error removing password for file "${this.file.name}":`, err);
        this._notificationService.showError(err?.message || err?.error || "Unlocking PDF was unsuccessful. Please try again.", "Error!");
      },
    });
  }
}
