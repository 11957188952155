import { Component, OnInit, Input, Injector, ViewChild, Output, EventEmitter } from '@angular/core';
import { FileAttachment, FileTypes } from 'src/app/shared/components/send-mms/send-mms.component';
import * as moment from 'moment';
import { BorrowerChatMessage, NewBorrowerMessagePostedModel } from 'src/app/models';
import { ChatService } from 'src/app/services/chat.service';
import { MessageService } from 'src/app/services/message.service';
import { NotificationService } from 'src/app/services/notification.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ChatMedium, Conversation, ConversationParticipant, MessageDirection, ParticipantType } from '../../models/conversation.model';
import { ConversationService } from '../../services/conversation.service';
import { Subscription } from 'rxjs';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { urlify } from 'src/utils';
import { Mentionable } from 'src/app/shared/components/message-editor-with-mentions/mentionable.model';
import { Message } from 'src/app/models/message.model';
import { MentionsUtils } from 'src/app/shared/services/mentions.utils';
import { Utils } from 'src/app/core/services/utils';
import { SharedUtilitiesService } from 'src/app/shared/services/utility.service';
import { v4 as uuidv4 } from 'uuid';


@Component({
  selector: 'conversation-chat-window',
  templateUrl: 'conversation-chat-window.component.html',
  styleUrls: ['./conversation-chat-window.component.scss'],
})
export class ConversationChatWindowComponent extends ApplicationContextBoundComponent implements OnInit {

  @ViewChild('messageEditorWithMentions') messageEditorComponent: any;

  @ViewChild('textArea') textArea: any;

  @Output()
  conversationUpdated: EventEmitter<Conversation> = new EventEmitter<Conversation>();

  @Output()
  newMessageWasSeen: EventEmitter<Conversation> = new EventEmitter<Conversation>();

  @Input()
  set conversation(conversation: Conversation) {
    this._conversation = conversation;
    this.messageToSend = '';
    this.attachments = [];
    if (this.messageEditorComponent) {
      this.messageEditorComponent.reset();
    }
  }

  get conversation(): Conversation {
    return this._conversation;
  }

  @Input()
  set messages(messages) {
    this._messages = messages;
    if (this._messages) {
      this._messages.forEach((message: any) => {
        const number = message.from || message.senderNumber;
        const participant = this.findParticipantFromConvo(number);
        message['fromFullName'] = participant?.name ? participant.name : null;
      })
    }
    this.scrollToConversationEnd();
  }

  get messages(): any {
    return this._messages;
  }

  @Input()
  phoneNumberOfOtherParty: string;

  @Input()
  userId: string;

  @Input()
  showLoading: boolean = false;

  messageToSend: string = '';

  fakeData: string = "";

  accept: string;
  scrollAttempts: number = 0;

  @Input()
  usersThatCanBeMentioned: Mentionable[] = [];

  internalMessage: Message = new Message();
  previewAttachment: { url: string, type: string } | null = null;

  protected isMms: boolean = false;

  protected isSendingMessage: boolean = false;

  protected attachments: Array<FileAttachment> = [];
  currentUserId: string = '';

  private _conversation: Conversation;
  private _smsChatUpdatedSubscription: Subscription;
  private _borrowerMessagingChatUpdatedSubscription: Subscription;
  private _internalMessagingChatUpdatedSubscription: Subscription;
  private _messages;

  private processedMessageIds: Set<string> = new Set();
  private isProcessingMessage: boolean = false;
  private messageQueue: any[] = [];
  private _currentUserPhoneNumber: string;

  constructor(private injector: Injector,
    private readonly _chatService: ChatService,
    private readonly _messageService: MessageService,
    private readonly _conversationService: ConversationService,
    private readonly _notifyService: NotificationService,
    private readonly _utilityService: SharedUtilitiesService,
    private readonly _domSanitizer: DomSanitizer) {
    super(injector);

    this._smsChatUpdatedSubscription = this._conversationService.smsChatIncomingMessageAdded
      .subscribe(messageModel => this.processIncomingMessage(messageModel));

    this._borrowerMessagingChatUpdatedSubscription = this._conversationService.borrowerMessagingIncomingMessageAdded.subscribe((messageModel: NewBorrowerMessagePostedModel) => {
      console.log("addingMessage", messageModel)
      this._addBorrowerOrInternalMessageToChatHistory(messageModel);
      this.scrollToConversationEnd();
    });

    this._internalMessagingChatUpdatedSubscription = this._conversationService.internalMessagingIncomingMessageAdded.subscribe((messageModel: NewBorrowerMessagePostedModel) => {
      console.log("addingMessage", messageModel)
      this._addBorrowerOrInternalMessageToChatHistory(messageModel);
      this.scrollToConversationEnd();
    });
  }

  ngOnInit() {
    this.currentUserId = this.applicationContext.userPermissions.userId;

    this._currentUserPhoneNumber = Utils.getActiveTelephonyService(
      this.applicationContext.currentlyLoggedInUserProfile.telephonyServices
    )?.fromPhoneNumber;

    if (!this.conversation) {
      this.conversation = new Conversation();
      this.conversation.assignedTo = this.userId;
    }
    this.accept = this.convertToAcceptDescriptor([FileTypes.Image, FileTypes.Video]);

    const el = document.getElementById("messageEditor");
    el.addEventListener("paste", (event) => {
      this.populateAttachments(event.clipboardData.items);
    });

    el.addEventListener("drop", (event) => {
      event.preventDefault();
      this.populateAttachments(event.dataTransfer.items);
    });

    const att = document.getElementById("attachments");
    if (att) {
      att.addEventListener("drop", (event) => {
        event.preventDefault();
        this.populateAttachments(event.dataTransfer.items);
      });

      att.addEventListener("dragover", (event) => {
        event.preventDefault();
      });
    }
  }

  showPreview(file: string, type: string): void {
    this.previewAttachment = { url: file, type: type };
  }

  closePreview(): void {
    this.previewAttachment = null;
  }

  ngAfterViewInit(): void {
    this.scrollToConversationEnd();
  }

  autoResize(event: Event): void {
    const textArea = event.target as HTMLTextAreaElement;
    textArea.style.height = 'auto'; // Reset height to auto to recalculate
    textArea.style.height = textArea.scrollHeight + 'px'; // Set height based on scroll height
  }

  ngOnDestroy(): void {
    if (this._smsChatUpdatedSubscription) {
      this._smsChatUpdatedSubscription.unsubscribe();
    }
    if (this._borrowerMessagingChatUpdatedSubscription) {
      this._borrowerMessagingChatUpdatedSubscription.unsubscribe();
    }
    if (this._internalMessagingChatUpdatedSubscription) {
      this._internalMessagingChatUpdatedSubscription.unsubscribe();
    }
  }

  addRecordedVideo = (data: Blob[]) => {
    var file = new File(data, "uploaded-video.webm", { type: 'video/webm' });
    Utils.toBase64(file).subscribe((base64) => {
      let attachment = new FileAttachment();
      attachment.file = file;
      attachment.base64 = base64;
      this.attachments.push(attachment);
    });
  }

  switchMessageMode = () => {
    this.isMms = !this.isMms;
  }

  onMessageChanged = (message: string) => {
    this.messageToSend = message;
  }

  sendMessage = () => {

    this.messageToSend = this.textArea.nativeElement.value.trim();

    // Check if the message and attachments are valid
    if (!this.messageToSend && (!this.attachments || this.attachments.length === 0)) {
      return false;
    }

    switch (this.conversation.chatMedium) {

      case ChatMedium.AgentMessage:
        this._sendAgentMessage();
        break;

      case ChatMedium.BorrowerMessage:
        this._sendBorrowerMessage();
        break;

      case ChatMedium.InternalMessage:
        this._sendInternalMessage();
        break;

      default:
        this._sendSms();
        break;
    }
  }

  onImageError = (attachment) => {
    attachment.base64 = "";
  }

  onEmojiSelected = (emoji) => {
    let cursorPosition = this.textArea.nativeElement.selectionStart;
    let firstPart = this.textArea.nativeElement.value.substring(0, cursorPosition);
    let secondPart = this.textArea.nativeElement.value.substring(cursorPosition, this.textArea.nativeElement.value.length);
    this.textArea.nativeElement.value = firstPart + emoji + secondPart;
    this.textArea.nativeElement.selectionStart = cursorPosition + emoji.length;
    this.textArea.nativeElement.selectionEnd = cursorPosition + emoji.length;
  }

  private processIncomingMessage(messageModel: any): void {
    // If the message has already been processed, skip it
    if (this.processedMessageIds.has(messageModel.id)) {
      console.log("Duplicate message received. Skipping processing.");
      return;
    }

    // Add message to the queue and mark it as unique by ID
    this.messageQueue.push(messageModel);
    this.processedMessageIds.add(messageModel.id);

    // Process the queue if not currently processing
    if (!this.isProcessingMessage) {
      this.processMessageQueue();
    }
  }

  private async processMessageQueue(): Promise<void> {
    // Set the lock to indicate processing is in progress
    this.isProcessingMessage = true;

    while (this.messageQueue.length > 0) {
      // Get the next message in the queue
      const messageModel = this.messageQueue.shift();

      // Ensure the message is still relevant to the current conversation
      if (messageModel.conversationId === this.conversation.conversationId) {
        if (!this.conversation.conversationMessages.find(x => x.id === messageModel.id)) {
          // Process the message (update state, add to chat history, etc.)
          this._updateConversationStateWhenNewMessageArrived(messageModel);
          this._addSmsToChatHistory(messageModel, null);
          this.scrollToConversationEnd();

          // Mark the message as seen if in the active conversation
          setTimeout(() => {
            this.newMessageWasSeen.emit(this.conversation);
          }, 3000);
        }
      }
    }

    // Release the lock after processing all messages in the queue
    this.isProcessingMessage = false;
  }

  private _sendAgentMessage() {
    this.isSendingMessage = true;

    let newMessage = new BorrowerChatMessage();
    newMessage = {
      content: this.messageToSend,
      applicationId: this.conversation.applicationId,
      borrowerId: this.conversation.borrowerId,
      agentId: this.conversation.agentId,
      userId: this.userId
    };

    this._messageService.postAgentMessage(newMessage).subscribe((messageModel) => {
      if (messageModel !== null && messageModel !== undefined) {
        this._addBorrowerOrInternalMessageToChatHistory(messageModel);
        this.scrollToConversationEnd(500);
      }
      this.isSendingMessage = false;
      this.messageToSend = "";
      this.messageEditorComponent.reset();
    }, (err) => {
      this._notifyService.showError(err ? (err.data ? err.data.message : '') : '', 'Error');
      this.isSendingMessage = false;
    });
  }

  private _sendBorrowerMessage() {
    this.isSendingMessage = true;

    let newMessage = new BorrowerChatMessage();
    newMessage = {
      content: this.messageToSend,
      applicationId: this.conversation.applicationId,
      borrowerId: this.conversation.borrowerId,
      agentId: this.conversation.agentId,
      userId: this.userId
    };

    this._messageService.postBorrowerMessage(newMessage).subscribe((messageModel) => {
      if (messageModel !== null && messageModel !== undefined) {
        this._addBorrowerOrInternalMessageToChatHistory(messageModel);
        this.scrollToConversationEnd(500);
      }
      this.isSendingMessage = false;
      this.messageToSend = "";
      this.messageEditorComponent.reset();
    }, (err) => {
      this._notifyService.showError(err ? (err.data ? err.data.message : '') : '', 'Error');
      this.isSendingMessage = false;
    });
  }

  private _sendInternalMessage() {
    this.isSendingMessage = true;

    let newMessage = new BorrowerChatMessage();
    newMessage = {
      content: this.messageToSend,
      applicationId: this.conversation.applicationId,
      userId: this.userId
    };
    this._messageService.postInternalMessage(newMessage).subscribe((messageModel) => {
      if (messageModel !== null && messageModel !== undefined) {
        this._addBorrowerOrInternalMessageToChatHistory(messageModel);
        this.messageEditorComponent.reset();
        this.scrollToConversationEnd(500);
      }
      this.isSendingMessage = false;
      this.messageToSend = "";
    }, (err) => {
      this._notifyService.showError(err ? (err.data ? err.data.message : '') : '', 'Error');
      this.isSendingMessage = false;
    });
  }

  private _sendSms() {
    this.isSendingMessage = true;

    let internalParticipant: ConversationParticipant;

    const internalParticipants = this._conversation.participants.filter(p => p.participantType === ParticipantType.Internal);
    if (internalParticipants.length === 1) {
      internalParticipant = internalParticipants[0];
    } else {
      internalParticipant = this._conversation.participants.find(p => p.participantType === ParticipantType.Internal 
        && (Utils.cleanFormatedPhoneNumber(p.phoneNumber) === Utils.cleanFormatedPhoneNumber(this._currentUserPhoneNumber)));
    }

    if (!internalParticipant?.phoneNumber) {
      this._notifyService.showError("Couldn't send sms message because user phone number doesn't exist.", "Error");
      this.isSendingMessage = false;
      return;
    }

    if (!this._conversation.conversationId) {
      this._conversationService.saveConversation(this._conversation).subscribe({
        next: (result) => {
          this._conversation = result;
          this.sendGroupMMS();
        },
        error: (err) => {
          this._notifyService.showError(err ? (err.data ? err.data.message : '') : '', 'Error');
          this.isSendingMessage = false;
        }
      })
    } else {
      this.sendGroupMMS();
    }
  }

  private sendGroupMMS = () => {
    let mediaFiles = [];
    let tempMediaFiles = [];
    if (this.attachments) {
      this.attachments.forEach(attachment => {
        mediaFiles.push({
          fileName: attachment.file.name,
          data: attachment.base64.toString().split(',')[1],
          mimeType: attachment.file.type
        });
        tempMediaFiles.push(this._domSanitizer.bypassSecurityTrustResourceUrl(attachment.base64.toString()));
      });
    }

    const internalParticipants = this._conversation.participants.filter(p => p.participantType === ParticipantType.Internal);

    const uniqueInternalParticipants = [...new Set(internalParticipants
      .map(item => item.id))];

    const uniqueExternalParticipants = [...new Set(this._conversation.participants.filter(p => p.participantType === 'External')
      .map(item => item.id))];

    const isGroupConvo = uniqueInternalParticipants.length > 1 || uniqueExternalParticipants.length > 1;

    let internalParticipant: ConversationParticipant;
    if (internalParticipants.length === 1) {
      internalParticipant = internalParticipants[0];
    } else {
      internalParticipant = internalParticipants.find(p => (Utils.cleanFormatedPhoneNumber(p.phoneNumber) === 
      Utils.cleanFormatedPhoneNumber(this._currentUserPhoneNumber)));
    }

    const externalParticipant = !isGroupConvo && this._conversation.participants.find(p => p.participantType === ParticipantType.External);

    const data = {
      senderName: isGroupConvo ? (internalParticipant.name || this.getCurrentlyLoggedInUserName()) : undefined,
      senderNumber: isGroupConvo ? internalParticipant.phoneNumber : undefined,
      conversationId: isGroupConvo ? this._conversation.conversationId : undefined,
      to: !isGroupConvo ? externalParticipant.phoneNumber : undefined,
      from: !isGroupConvo ? internalParticipant.phoneNumber : undefined,
      body: this.messageToSend || '',
      mediaFiles: mediaFiles,
      appendSmsSignature: false,
      leadId: this._conversation.leadId,
      applicationId: this._conversation.applicationId,
      borrowerId: this._conversation.borrowerId,
      agentId: this._conversation.agentId,
      docFileIds: []
    };

    const sendFunction = isGroupConvo ? this._chatService.sendGroupMms : this._chatService.sendSms;

    sendFunction.call(this._chatService, data, this.currentUserId == this.userId ? null : this.userId).subscribe((messageModel) => {
      this._updateConversationStateWhenNewMessageArrived(messageModel || data);
      this._addSmsToChatHistory(messageModel || data, tempMediaFiles);
      this.scrollToConversationEnd(500);
      this.isSendingMessage = false;
      this.messageToSend = "";
      this.messageEditorComponent.reset();
      this._conversationService.conversationUpdated.next(this.conversation);
    }, (err) => {
      this.isSendingMessage = false;
      this._notifyService.showError(err ? (err.error ? err.error.message : '') : '', 'Error');
    });
  }

  private _addBorrowerOrInternalMessageToChatHistory(messageModel) {
    let newSendMessageDateToSend;

    if (this.conversation.conversationMessages.length > 0) {
      let latestMsgDate = this.formatGroupDate(this.conversation.conversationMessages[0].groupDate);
      let newSendMessageDate = this.formatGroupDate(moment(messageModel.dateInserted).unix());

      if (moment(latestMsgDate).isSame(newSendMessageDate)) {
        newSendMessageDateToSend = this.conversation.conversationMessages[0].groupDate;
      }
    } else {
      newSendMessageDateToSend = moment(messageModel.dateInserted).unix();
    }

    this.conversation.mostRecentMessagePreview = this.parseMessageBody(messageModel.content || messageModel.body);
    this.conversation.mostRecentMessageDate = typeof (messageModel.dateInserted) == 'string' ? new Date(messageModel.dateInserted) : messageModel.dateInserted;
    this.conversation.lastReadDate = this.conversation.mostRecentMessageDate;
    this.conversation.conversationMessages.unshift({
      groupDate: messageModel.dateInserted ? newSendMessageDateToSend : null,
      dateInserted: messageModel.dateInserted,
      to: messageModel.to,
      from: messageModel.from,
      body: this.parseMessageBody(messageModel.content || messageModel.body),
      direction: (!messageModel.senderId || messageModel.senderId == this.applicationContext.currentlyLoggedInUser.userCompanyGuid) ? MessageDirection.Outbound : MessageDirection.Inbound,
      hasMediaFiles: false,
      mediaFiles: undefined,
      id: 0,
      smsMessageSid: '',
      messageSid: '',
      accountSid: '',
      messageModelId: 0,
      senderName: '',
      messageMediaFileUrls: [],
      companyId: 0,
      insertedBy: '',
      updatedBy: '',
      dateUpdated: undefined
    });

    this.conversation.conversationMessages = [...this.conversation.conversationMessages];
  }

  private _updateConversationStateWhenNewMessageArrived(messageModel) {
    this.conversation.mostRecentMessagePreview = messageModel.body;
    this.conversation.mostRecentMessageDate = typeof (messageModel.dateInserted) == 'string' ? new Date(messageModel.dateInserted) : messageModel.dateInserted;
    this.conversation.lastReadDate = this.conversation.mostRecentMessageDate;
    this.conversation.isUnread = messageModel.direction == MessageDirection.Inbound;
  }

  private _addSmsToChatHistory(messageModel, mediaFilesModel = []) {
    this.attachments = [];
    let newSendMessageDateToSend;

    if (this.conversation.conversationMessages && this.conversation.conversationMessages.length > 0) {
      let latestMsgDate = this.formatGroupDate(this.conversation.conversationMessages[0].groupDate);
      let newSendMessageDate = this.formatGroupDate(moment(messageModel.dateInserted).unix());

      if (moment(latestMsgDate).isSame(newSendMessageDate)) {
        newSendMessageDateToSend = this.conversation.conversationMessages[0].groupDate;
      }
    } else {
      this.conversation.conversationMessages = [];
      newSendMessageDateToSend = moment(messageModel.dateInserted).unix();
    }

    if (messageModel.hasMediaFiles) {
      this.loadMediaFiles(messageModel);
    }

    const isGroupConvo = this._conversation.participants.length > 2;
    const messageBaseInfo = {
      groupDate: messageModel.dateInserted ? newSendMessageDateToSend : null,
      dateInserted: messageModel.dateInserted || new Date().toISOString(),
      body: this.parseMessageBody(messageModel.body),
      direction: messageModel.direction || MessageDirection.Outbound,
      hasMediaFiles: messageModel.hasMediaFiles || (mediaFilesModel && mediaFilesModel.length > 0),
      mediaFiles: messageModel.mediaFiles || mediaFilesModel,
      messageMediaFileUrls: messageModel.messageMediaFileUrls || [],
      id: 0,
      messageSid: '',
      accountSid: '',
      messageModelId: 0,
      companyId: 0,
      insertedBy: '',
      updatedBy: '',
      dateUpdated: undefined
    };

    const messageSpecificInfo = isGroupConvo
      ? {
        senderName: messageModel.senderName,
        senderNumber: messageModel.senderNumber
      }
      : {
        to: messageModel.to,
        from: messageModel.from,
        smsMessageSid: ''
      };

    const newMessage: any = {
      ...messageBaseInfo,
      ...messageSpecificInfo
    };

    this.conversation.conversationMessages.unshift(newMessage);
    this.conversation.conversationMessages = [...this.conversation.conversationMessages];
  }

  parseMessageBody = (text: string) => {
    if (!text) {
      return "";
    }
    if (text.includes("@")) {
      text = MentionsUtils.generateDisplayHtmlWithMentions(text);
    }

    if (this.isContainingUrl(text)) {
      return urlify(text);
    } else {
      return text.replaceAll('\n', '<br/>');
    }
  }

  onKeyDownOnMessageEditor = (e: any) => {
    if (e.keyCode === 13) {
      if (!e.ctrlKey) {
        e.preventDefault();
        this.sendMessage();
      } else {
        const messageEditor = document.getElementById("messageEditor") as any;
        messageEditor.value += '\n';
      }
    }
  }

  isContainingUrl = (text) => {
    return new RegExp("([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?(/.*)?").test(text);
  }

  formatGroupDate = (date) => {
    let retVal = '';
    if (date) {
      retVal = moment.unix(date).format('MM/DD/YYYY');
    }
    if (retVal == "Invalid Date" || retVal == "Invalid date") {
      retVal = moment().format('MM/DD/YYYY');
    }
    return retVal;
  }

  viewImage = (base64Image) => {

    const parts = base64Image.changingThisBreaksApplicationSecurity ?
      base64Image.changingThisBreaksApplicationSecurity.split(';base64,') : base64Image.split(';base64,');

    const imageType = parts[0].split(':')[1];

    const decodedData = window.atob(parts[1]);

    const uInt8Array = new Uint8Array(decodedData.length);

    for (let i = 0; i < decodedData.length; ++i) {
      uInt8Array[i] = decodedData.charCodeAt(i);
    }

    const blob = new Blob([uInt8Array], { type: imageType });

    const url = window.URL.createObjectURL(blob);

    window.open(url);

  }

  handleFileUpload = (event) => {
    event.target.files.forEach((file) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        let data = new FileAttachment();
        data.file = file;
        data.base64 = event.target.result;
        this.attachments.push(data);
      };
    });
  }

  deleteAttachment = (attachment) => {
    let index = this.attachments.indexOf(attachment);
    this.attachments.splice(index, 1);
    this.fakeData = "";
  }

  private loadMediaFiles = (chatConversation) => {
    if (!chatConversation.messageMediaFileUrls || chatConversation.messageMediaFileUrls.length == 0) return;
    chatConversation.mediaFiles = [];
    chatConversation.messageMediaFileUrls.forEach(c => {
      chatConversation.mediaFiles.push(null);
    })
    for (let i = 0; i <= chatConversation.messageMediaFileUrls.length - 1; i++) {
      this._utilityService.getImageFileContentAsBase64(chatConversation.messageMediaFileUrls[i] + "?v=" + uuidv4(), (response) => {
        chatConversation.mediaFiles[i] = response;
      });
    }
  }

  private convertToAcceptDescriptor = (allowedTypes: FileTypes[]): string => {
    let accept = "";
    if (allowedTypes.includes(FileTypes.Image)) {
      accept += "image/*"
    }
    if (allowedTypes.includes(FileTypes.Video)) {
      accept += (accept ? ", video/*" : "video/*");
    }
    if (allowedTypes.includes(FileTypes.Application)) {
      accept += (accept ? ", application/*" : "application/*");
    }
    if (allowedTypes.includes(FileTypes.Pdf)) {
      accept += (accept ? ", application/pdf" : "application/pdf");
    }
    if (allowedTypes.includes(FileTypes.Xml)) {
      accept += (accept ? ", application/xml" : "application/xml");
    }
    if (allowedTypes.includes(FileTypes.Doc)) {
      let doc = ".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document";
      accept += (accept ? ", " + doc : doc);
    }
    if (allowedTypes.includes(FileTypes.Text)) {
      accept += (accept ? ", text/plain" : "text/plain");
    }

    return accept;
  }

  private scrollToConversationEnd = (delay = 100) => {
    this.scrollAttempts = 0;
    this.scrollToConversationEndExecutor(delay);
  }

  private scrollToConversationEndExecutor = (delay = 100) => {
    setTimeout(() => {
      const element = document.getElementById("conversation-end");

      if (!element) {
        if (this.scrollAttempts++ >= 5) return; // Limit retry attempts to prevent infinite loop
        else this.scrollToConversationEndExecutor(delay * 1.5); // Increase delay for each attempt
        return;
      }

      // Scroll to the element smoothly
      element.scrollIntoView({ behavior: 'smooth', block: "end", inline: 'nearest' });

      // Secondary check to confirm scrolling reached the end after initial scroll
      setTimeout(() => {
        const bottomDistance = element.getBoundingClientRect().bottom - window.innerHeight;
        if (bottomDistance > 0 && this.scrollAttempts++ < 5) {
          // Retry scrolling if element is not fully visible
          this.scrollToConversationEndExecutor(delay * 1.5);
        }
      }, 300); // Extra delay for the secondary check

    }, delay);
  }

  private populateAttachments = (items: DataTransferItemList) => {
    for (let i = 0; i < items.length; i++) {
      let data = new FileAttachment();
      data.file = items[i].getAsFile();

      if (!data.file)
        continue;

      if (this.checkFileType(data.file.type)) {
        let fr = new FileReader;

        fr.readAsDataURL(data.file);

        fr.onloadend = (e) => {
          data.base64 = e.target.result;
          this.attachments.push(data);
        };
      }
      else {
        this._notifyService.showError('The type of file is not allowed', '');
      }
    }
  }

  private checkFileType = (type: string) => {
    if (this.accept == "") {
      return true;
    }
    if (type.startsWith('image/')) {
      return true;
    }
    return false;
  }

  private findParticipantFromConvo = (phoneNumber: string): ConversationParticipant => {
    return this._conversation.participants.find(p => p.phoneNumber === phoneNumber);
  }

  private getCurrentlyLoggedInUserName = (): string => {
    return this.applicationContext.currentlyLoggedInUserProfile.userProfile.firstName + ' ' + this.applicationContext.currentlyLoggedInUserProfile.userProfile.lastName;
  }
}
