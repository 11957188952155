<div class="call-summary-container col-md-show-height">
  <div class="container-fluid">
    <div class="row m-1">
      <div class="col-md-12 d-flex justify-content-between justify-content-sm-center">

        <div class="flex-column-mobile btn-group-left">
          <button type="button" id="buttonParticipants" class="btn btn-outline-secondary tippy-btn"
            style="min-width: 500px;" data-bs-container="body" data-bs-toggle="popover" placement="bottom"
            [popover]="templateParticipants" data-trigger="click" [outsideClick]="true"
            [containerClass]="'templateParticipants'">
            <div class="d-flex align-items-center justify-content-between margin-top-negative-8">
              <div class="d-flex me-1 gap-3">
                <div class="padding-xxs-mobile m-0 p-0 align-items-center d-flex justify-content-between me-2">
                  <div class="media d-flex">
                    <div class="participantFirstLetter participation-avatar me-2 my-0">
                      {{ getWordsFirstLetters(currentUserFullName) }}
                    </div>
                  </div>
                  <!--end media-->
                  <div class="d-flex align-items-center">

                    <h6 class="d-inline me-1">
                      <strong>
                        {{ calleeTimer }}
                      </strong>
                    </h6>

                    <ng-container *ngIf="activeParticipantCount > 0">
                      <button class="btn btn-sm btn-outline-success btn-icon-square-sm me-1" title="Mute"
                        (click)="$event.stopPropagation(); muteDialer()" *ngIf="activeCall && !activeCall.isMuted()">
                        <i class="fa fa-microphone"></i>
                      </button>
                      <button class="btn btn-sm btn-outline-danger btn-icon-square-sm me-1" title="Unmute"
                        (click)="$event.stopPropagation(); unMuteDialer()" *ngIf="activeCall && activeCall.isMuted()">
                        <i class="fa fa-microphone-slash"></i>
                      </button>
                    </ng-container>
                  </div>
                </div>
                <div class="padding-xxs-mobile p-0 m-0 align-items-center d-flex justify-content-between me-2"
                  style="min-width: 220px; border-left: 1px;" [ngClass]="{
                  'completed': activeCall?.status() == 'closed'
                }" *ngIf="activeCall">
                  <div class="media d-flex">
                    <div class="participantFirstLetter me-2 my-0" *ngIf="participantFullName">
                      {{ getWordsFirstLetters(participantFullName) }}
                    </div>
                    <img class="d-flex align-self-center me-2 my-0 rounded-circle filter-yellow-user"
                      *ngIf="!participantFullName" src="assets/images/faUser.svg" alt="" height="26" />

                    <div class="align-self-center participant-name">
                      <h6 class="mt-0 mb-1">
                        <strong *ngIf="participantFullName"> {{ participantFullName }}</strong>
                        <strong *ngIf="!participantFullName"> {{ participantPhoneNumber | phone }}</strong>
                      </h6>
                    </div>
                  </div>
                  <!--end media-->
                  <div class="d-flex align-items-center">
                    <i class="text-dark me-1"
                      *ngIf="activeCall && activeCall?.status() != 'closed'">({{activeCall?.status()}})</i>
                    <button class="btn btn-sm btn-outline-primary btn-icon-square-sm" title="Dial"
                      (click)="$event.stopPropagation(); startCall()"
                      *ngIf="device && activeCall?.status() == 'closed'">
                      <i class="fa fa-phone-alt"></i>
                    </button>
                    <button class="btn btn-sm btn-outline-danger btn-icon-square-sm" title="Hang up Call"
                      (click)="$event.stopPropagation(); endCall()" *ngIf="activeCall?.status() == 'open'">
                      <i class="fa fa-phone-slash"></i>
                    </button>
                    <button class="btn btn-sm btn-outline-success btn-icon-square-sm me-1" title="Hold"
                      (click)="$event.stopPropagation(); holdCall()" *ngIf="activeCall && !activeCallOnHold">
                      <i class="fa fa-stop"></i>
                    </button>
                    <button class="btn btn-sm btn-outline-danger btn-icon-square-sm me-1" title="Unmute"
                      (click)="$event.stopPropagation(); unHoldCall()" *ngIf="activeCall && activeCallOnHold">
                      <i class="fa fa-start"></i>
                    </button>
                    <button class="btn btn-sm btn-outline-primary btn-icon-square-sm mx-1" title="Publish Warm Transfer"
                      (click)="$event.stopPropagation(); publishWarmTransfer()"
                      *ngIf="activeCall?.status() == 'open' && !warmTransferStatus && !!voiceHistoryId">
                      <i [hidden]="publishingWarmXfer" class="fa fa-random"></i>
                      <i [hidden]="!publishingWarmXfer" class="fa fa-spin"></i>
                    </button>
                    <button class="btn btn-sm btn-outline-primary btn-icon-square-sm mx-1" title="Cancel Warm Transfer"
                      (click)="$event.stopPropagation(); cancelWarmTransfer()"
                      *ngIf="activeCall?.status() == 'open' && warmTransferStatus">
                      <i class="fa fa fa-times"></i>
                    </button>
                  </div>
                </div>
                <div
                  class="padding-xxs-mobile p-0 m-0 align-items-center d-flex justify-content-between me-1 incoming-section"
                  *ngIf="incomingCallPhone">
                  <div class="media d-flex incoming-media">
                    <div class="participantFirstLetter me-2 my-0">
                      <span><i class="fas fa-phone-volume phone-icon"></i></span>
                    </div>
                    <div class="align-self-center">
                      <h6 class="mt-0 mb-1">
                        <span class="ms-2">Incoming [{{incomingCallPhone | phone}}] <span
                            class="dot-loading">...</span></span>
                        <span class="mx-2">
                          <button class="btn btn-sm btn-outline-success btn-icon-square-sm me-1" title="Accept"
                            (click)="$event.stopPropagation(); onAcceptedIncomigCall()">
                            <i class="fa fa-check"></i>
                          </button>
                          <button class="btn btn-sm btn-outline-danger btn-icon-square-sm" title="Reject"
                            (click)="$event.stopPropagation(); onRejectedIncomigCall()">
                            <i class="fa fa-times"></i>
                          </button>
                        </span>
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </button>
          <ng-template #templateParticipants style="z-index:9999!important">
            <div class="card mb-0" style="z-index:9999!important;min-height: 295px">
              <div class="card-header">
                <div class="d-flex justify-content-between align-items-center">
                  <h5 class="p-0">Participants ({{ activeParticipantCount }})</h5>
                  <span class="p-0">Started At: <strong>{{ callingStartTime }}</strong></span>
                </div>
              </div>
              <div class="card-body">
                <ul class="list-group custom-list-group mb-2">
                  <li class="list-group-item align-items-center d-flex justify-content-between mb-2">
                    <div class="media">
                      <img class="d-flex align-self-center me-2 rounded-circle filter-yellow-user"
                        *ngIf="!currentUserAvatarUrl" src="assets/images/faUser.svg" alt="" height="36" />
                      <img class="d-flex align-self-center me-2 rounded-circle" *ngIf="!!currentUserAvatarUrl"
                        [src]="currentUserAvatarUrl" alt="" height="36" />
                      <div class="media-body align-self-center">
                        <h6 class="m-0">
                          <strong>{{ currentUserFullName }}</strong>
                        </h6>
                      </div>
                    </div>
                    <!--end media-->
                    <div class="align-self-center">
                      <h6 class="d-inline me-1">
                        <strong>
                          {{ calleeTimer }}
                        </strong>
                      </h6>

                      <ng-container *ngIf="activeParticipantCount > 0">
                        <button class="btn btn-outline-success btn-icon-square-sm me-1" title="Mute"
                          (click)="$event.stopPropagation(); muteDialer()" *ngIf="activeCall && !activeCall.isMuted()">
                          <i class="fa fa-microphone"></i>
                        </button>
                        <button class="btn btn-outline-danger btn-icon-square-sm me-1" title="Unmute"
                          (click)="$event.stopPropagation(); unMuteDialer()" *ngIf="activeCall && activeCall.isMuted()">
                          <i class="fa fa-microphone-slash"></i>
                        </button>
                      </ng-container>
                    </div>
                  </li>
                  <li class="list-group-item align-items-center d-flex justify-content-between mb-2" [ngClass]="{
                        'completed': activeCall?.status() == 'closed'
                      }">
                    <div class="media d-flex">
                      <div class="participantFirstLetter me-2" *ngIf="participantFullName">
                        {{ getWordsFirstLetters(participantFullName) }}
                      </div>
                      <img class="d-flex align-self-center me-2 rounded-circle filter-yellow-user"
                        *ngIf="!participantFullName" src="assets/images/faUser.svg" alt="" height="26" />

                      <div class="media-body align-self-center participant-name">
                        <h6 class="mt-0 mb-1">
                          <strong *ngIf="participantFullName"> {{ participantFullName || (participantPhoneNumber |
                            phone) }}</strong>
                          <strong *ngIf="!participantFullName"> {{ participantPhoneNumber | phone }}</strong>
                        </h6>
                      </div>
                    </div>
                    <!--end media-->
                    <div class="d-flex align-items-center">
                      <i class="text-dark me-1"
                        *ngIf="activeCall && activeCall?.status() != 'closed'">({{activeCall?.status() }})
                      </i>

                      <button class="btn btn-sm btn-outline-primary btn-icon-square-sm" title="Dial"
                        (click)="$event.stopPropagation(); startCall()"
                        *ngIf="device && activeCall?.status() == 'closed'">
                        <i class="fa fa-phone-alt"></i>
                      </button>

                      <button class="btn btn-outline-danger btn-icon-square-sm" title="Hang up Call"
                        (click)="$event.stopPropagation(); endCall()" *ngIf="activeCall?.status() == 'open'">
                        <i class="fa fa-phone-slash"></i>
                      </button>

                    </div>
                  </li>
                </ul>

                <div *ngIf="activeCall?.status() == 'closed'">
                  The call just ended - please, dial again.
                </div>

              </div>
              <div class="card-footer">
                <div class="row">
                  <div class="col d-grid" *ngIf="activeCall?.status() == 'closed'">
                    <button (click)="$event.stopPropagation(); startCall()" *ngIf="device"
                      class="btn button-background-color ps-3 pe-4">
                      <span><i class="fa fa-phone-alt text-success"></i>
                        Dial</span>
                    </button>
                  </div>
                  <div class="col d-grid" *ngIf="activeCall?.status() == 'open'">
                    <button (click)="$event.stopPropagation(); endCall()" class="btn button-background-color ps-3 pe-4">
                      <span>
                        <i class="fa fa-phone-slash text-danger"></i> Hang Up
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>

          <button type="button" id="buttonMessage"
            class="btn btn-outline-secondary tippy-btn m-left-1 dialer-button-mobile" data-bs-container="body"
            data-bs-toggle="popover" placement="bottom" [popover]="templateMessage" data-trigger="click"
            [outsideClick]="true" [containerClass]="'templateMessage'" *ngIf="haveRecordId">
            <i class="fas fa-sticky-note me-2" style=" font-size: 12px;"></i>
            <span class="fw-bolder button-item-text">Quick Notes</span> <i class="fas fa-angle-down ms-2"></i>
          </button>
          <ng-template #templateMessage style="z-index:9999!important">
            <div class="card mb-0" style="z-index:9999!important">
              <div class="card-header pb-1 pt-1">
                <h5 class="p-0">Quick Note</h5>
              </div>
              <div class="card-header" *ngIf="latestNote">
                <div class="row">
                  <p class="mb-0">{{ latestNote }}</p>
                </div>

                <div class="row mt-2">
                  <span style="font-size:12px"><strong>{{ latestNoteAuthor }}</strong>
                    {{ latestNoteTime }}</span>
                </div>
              </div>
              <div class="card-body">

                <div class="row" *ngIf="selectedRecordForCall && selectedRecordForCall.leadId">
                  <label class="col-md-4 text-start form-label fw-bolder">Lead Status</label>
                  <div class="col-md-8">
                    <lead-status [selectedRecord]="selectedRecordForCall" [currentDialOfRecord]="selectedDialList">
                    </lead-status>
                  </div>
                </div>

                <textarea id="textarea" class="form-control" style="height: 95px" maxlength="255" rows="2"
                  [placeholder]="'Add Note' + ' ( This note has a limit of 255 chars. )'" [(ngModel)]="note">
                </textarea>
              </div>
              <div class="card-footer">
                <div class="row">
                  <div class="col d-grid">
                    <button class="btn btn-info float-end" (click)="addNote()">
                      <i class="fas fa-plus"></i> Add Note
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>

          <button type="button" id="buttonSms" class="btn btn-outline-secondary tippy-btn m-left-1 dialer-button-mobile"
            data-bs-container="body" data-bs-toggle="popover" placement="bottom" [popover]="templateSms"
            data-trigger="click" [outsideClick]="true" [containerClass]="'templateSms'">
            <i class="fas fa-sms me-2" style="font-size:12px;"></i>
            <span class="fw-bolder button-item-text">Quick SMS</span> <i class="fas fa-angle-down ms-2"></i>
          </button>
          <ng-template #templateSms style="z-index:9999!important">
            <div class="card mb-0 card-border-remover" style="z-index:9999!important">
              <div class="card-header">
                <h5 class="p-0">Quick SMS</h5>
              </div>
              <div class="card-body">
                <form id="quickSmsForm" #quickSmsForm="ngForm" name="quickSmsForm">
                  <div *ngIf="!manualDial" class="row">
                    <label class="col-md-4 text-start font-12 col-form-label" for="numbersToSms">To (Phone
                      Number):</label>
                    <div class="col-md-8">
                      <select class="form-select" name="numbersToSms" [(ngModel)]="numberToSendSmsTo" id="numbersToSms">
                        <option [ngValue]="null">-- Select --</option>

                        <optgroup [label]="possibleNumbersToSendSmsTo.borrowerName || '(name not set)'">
                          <option id="borrowerMobileNumber" [ngValue]="
                              possibleNumbersToSendSmsTo.borrowerMobileNumber
                            ">
                            Mobile:
                            {{ possibleNumbersToSendSmsTo.borrowerMobileNumber | phone }}
                          </option>
                          <option id="borrowerHomeNumber" [ngValue]="
                              possibleNumbersToSendSmsTo.borrowerHomeNumber
                            ">
                            Home:
                            {{ possibleNumbersToSendSmsTo.borrowerHomeNumber | phone }}
                          </option>
                        </optgroup>
                        <optgroup *ngIf="possibleNumbersToSendSmsTo.coBorrowerName">
                          <option id="coBorrowerMobileNumber" [ngValue]="
                              possibleNumbersToSendSmsTo.coBorrowerMobileNumber
                            ">
                            Mobile:
                            {{
                            possibleNumbersToSendSmsTo.coBorrowerMobileNumber | phone
                            }}
                          </option>
                          <option id="coBorrowerHomeNumber" [ngValue]="
                              possibleNumbersToSendSmsTo.coBorrowerHomeNumber
                            ">
                            Home:
                            {{ possibleNumbersToSendSmsTo.coBorrowerHomeNumber | phone }}
                          </option>
                        </optgroup>
                      </select>
                    </div>
                  </div>
                  <div *ngIf="manualDial" class="row">
                    <label class="col-md-4 text-start col-form-label">To</label>
                    <div class="col-md-8">
                      <input class="form-control input-field" required name="numberToSendMessageTo" id="smsTo"
                        name="smsTo" type="text" mask="(000) 000-0000" placeHolderCharacter="9" [showMaskTyped]="true"
                        #smsToInput="ngModel" [ngClass]="{
                          'is-invalid':
                            smsToInput &&
                            (smsToInput.touched || sendSmsClicked) &&
                            smsToInput.invalid
                        }" [(ngModel)]="numberToSendSmsTo" />
                      <div class="invalid-feedback">This field is required</div>
                    </div>
                  </div>

                  <textarea id="textarea" class="form-control mt-3" style="height: 95px" maxlength="255" rows="2"
                    [placeholder]="
                      'Message' + ' ( This textarea has a limit of 255 chars. )'
                    " name="smsBody" required [(ngModel)]="textMessage" #textMessageInput="ngModel" [ngClass]="{
                      'is-invalid':
                        textMessageInput &&
                        (textMessageInput.touched || sendSmsClicked) &&
                        textMessageInput.invalid
                    }"></textarea>

                  <div class="invalid-feedback">This field is required</div>
                </form>
              </div>
              <div class="card-footer">
                <div class="row">
                  <div class="col d-grid">
                    <button class="btn btn-info" (click)="onSendSmsClicked()" [disabled]="sending">
                      <span *ngIf="!sending"><i class="fa fa-paper-plane"></i> Send</span>
                      <span *ngIf="sending" placement="bottom" ngbTooltip="Fetch V2">
                        <i class="fas fa-sync fa-spin text-primary"></i>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>

          <button type="button"
            class="btn text-danger btn-outline-secondary tippy-btn m-left-1 button-radius margin-bottom-20 dialer-button-mobile"
            (click)="closePanel()" placement="bottom" ngbTooltip="Close power dialer">
            <i class="fa fa-times" aria-hidden="true"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>