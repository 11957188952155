<ng-template #pageActionBarLeft>
  <bread-crum></bread-crum>
</ng-template>

<ng-template #pageActionBarRight>
  <button
    *ngIf="rowSelection"
    type="button"
    class="btn btn-primary mb-xs-1 ms-xs-auto"
    (click)="openNewContactDrawer()"
  >
    <i class="fas fa-plus me-2"></i> New Contact
  </button>
</ng-template>

<ng-template #mainContent>
  <div class="alert alert-danger border-0" role="alert" *ngIf="error">
    <strong>ERROR:</strong> Error encountered while loading the contacts
  </div>

  <p-table
    #dt1
    [columns]="selectedColumns"
    [value]="filteredContacts"
    [paginator]="true"
    [rows]="25"
    [resizableColumns]="true"
    scrollable="true"
    [scrollHeight]="scrollHeight"
    responsiveLayout="scroll"
    name="contact-list-table-v2"
    [rowsPerPageOptions]="[10, 25, 50]"
    [globalFilterFields]="globalFilterFields"
    [(selection)]="selectedRows"
    [autoLayout]="true"
    [showCurrentPageReport]="true"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
    styleClass="p-datatable-gridlines p-datatable-sm"
    [selectionPageOnly]="true"
    stateStorage="local"
    stateKey="contacts-table-state"
    sortMode="multiple"
    [multiSortMeta]="
      !tableState
        ? [{ field: 'dateCreateBorrower', order: -1 }]
        : tableState.multiSortMeta
    "
    (onRowSelect)="onRowSelected($event)"
  >
    <ng-template pTemplate="caption">
      <div class="row d-flex justify-content-between">
        <div
          class="col-md-4 col-12 offset-md-4 d-flex justify-content-center mb-xs-2"
        >
          <app-add-to-dial-list
            *ngIf="rowSelection"
            [selectedRows]="selectedRows"
            [contactListType]="contactListType"
            [idFieldName]="'borrowerId'"
            (onAddedToDialList)="addedToDialList($event)"
          ></app-add-to-dial-list>
        </div>

        <div
          class="col-md-4 col-12 d-flex justify-content-end justify-content-xs-between"
        >
          <!-- mobile -->
          <div class="btn-group hide-on-non-mobile">
            <button
              *ngIf="columnSelection"
              type="button"
              class="btn text-primary"
              data-bs-container="body"
              data-bs-toggle="popover"
              data-trigger="click"
              placement="bottom"
              (click)="openColumnSelectorForMobile()"
              [popover]="mobileColumnSelectorPopover"
              [outsideClick]="true"
              [containerClass]="'mobile-col-selector-popover'"
            >
              <i class="fa fa-cog"></i>
            </button>
            <ng-template #mobileColumnSelectorPopover>
              <p-multiSelect
                #mobileColumnSelector
                class="p-ml-auto d-inline-block"
                [options]="columns"
                [(ngModel)]="selectedColumns"
                optionLabel="header"
                selectedItemsLabel="{0} columns selected"
                name="selectedColumns"
                ngDefaultControl
                defaultLabel="Choose Columns"
              ></p-multiSelect>
            </ng-template>
          </div>

          <!-- desktop -->
          <p-multiSelect
            *ngIf="columnSelection"
            class="p-ml-auto hide-on-mobile"
            [options]="columns"
            [(ngModel)]="selectedColumns"
            optionLabel="header"
            selectedItemsLabel="{0} columns selected"
            name="selectedColumns"
            ngDefaultControl
            defaultLabel="Choose Columns"
          ></p-multiSelect>

          <!-- desktop and mobile -->
          <span class="p-input-icon-right ms-2 ms-xs-0">
            <i class="pi pi-search"></i>
            <input
              pInputText
              type="text"
              class="form-control h-100"
              [(ngModel)]="globalSearchString"
              (ngModelChange)="onGlobalSearchStringChanged($event); dt1.first=0"
              placeholder="Search keyword"
            />
          </span>
        </div>
      </div>
    </ng-template>

    <ng-template pTemplate="header" let-columns>
      <tr>
        <th class="justify-content-left">
          <p-tableHeaderCheckbox *ngIf="rowSelection"></p-tableHeaderCheckbox>
          <span>Action</span>
        </th>
        <th *ngFor="let col of columns" [pSortableColumn]="col.field">
          {{ col.header }}
          <p-sortIcon [field]="col.field" class="pull-right"></p-sortIcon>
        </th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-rowData let-columns="columns">
      <tr>
        <td>
          <span>
            <span *ngIf="rowSelection">
              <p-tableCheckbox [value]="rowData"> </p-tableCheckbox>
              <span class="mx-1">|</span>
            </span>
            <span>
              <a (click)="editContact(rowData.borrowerId)">
                <i class="fas fa-pencil-alt cursor text-info" title="Edit"></i>
              </a>
              <span class="mx-1">|</span>
              <i
                class="fa fa-trash-alt cursor text-danger"
                (click)="showDeleteDialog(rowData)"
                title="Delete"
              >
              </i>
            </span>
            <br />
            <span class="text-muted">
              <a (click)="onViewApplicationsClicked(rowData)"> Applications </a>
            </span>
          </span>
        </td>
        <td *ngFor="let col of columns" [ngSwitch]="col.field">
          <span *ngSwitchCase="'displayName'">
            <span class="text-cell-primary">{{ rowData.displayName }}</span>
            <br />
            <span
              class="text-muted text-ellipsis cell-mw225"
              title="{{ rowData.mailingStreet || '--' }} {{
                rowData.mailingCity
              }}{{ rowData.mailingCity ? ',' : '' }} {{
                rowData.mailingState ? rowData.mailingState.toUpperCase() : ''
              }} {{ rowData.mailingZip }}"
              >{{ rowData.mailingStreet || '--' }} {{ rowData.mailingCity
              }}{{ rowData.mailingCity ? ',' : '' }}
              {{
                rowData.mailingState ? rowData.mailingState.toUpperCase() : ''
              }}
              {{ rowData.mailingZip }}</span
            >
          </span>

          <span *ngSwitchCase="'borrowerContact'">
            <div class="d-flex" *ngIf="rowData.borrowerContact">
              <div class="img-group">
                <a class="user-avatar user-avatar-group">
                  <img
                    src="{{ baseAvatarUrl }}avatars/user-profile/{{
                      rowData.borrowerContact
                    }}"
                    onerror="this.src='assets/images/male.png'"
                    alt="user"
                    class="rounded-circle thumb-xs"
                  />
                </a>
              </div>
              <span
                style="padding-left: 10px"
                class="align-self-center text-cell-primary"
                >{{ rowData.borrowerContactName }}</span
              >
            </div>
          </span>

          <span *ngSwitchCase="'homePhone'">
            <table-cell-contact-info
              [contactInfo]="rowData"
              [recordType]="'Borrower'"
              [recordId]="rowData.borrowerId"
              [dialerEnabled]="dialerEnabled"
            ></table-cell-contact-info>
          </span>

          <span *ngSwitchCase="'isRegister'">
            <span class="text-cell-primary">
              <portal-status-indicator
                [borrower]="rowData"
              ></portal-status-indicator>
            </span>
            <br />
            <span class="text-muted"
              >Last login
              {{ rowData.lastTimeLogin ? rowData.lastLogin : 'unknown' }}</span
            >
          </span>

          <span *ngSwitchCase="'creditMonitoringStatus'">
            <span
              [ngClass]="{
                'badge badge-soft-success':
                  rowData.creditMonitoringStatus === 'Active',
                'badge badge-soft-danger':
                  rowData.creditMonitoringStatus === 'Disabled',
                'badge badge-soft-dark': !rowData.creditMonitoringStatus
              }"
            >
              <span *ngIf="rowData.creditMonitoringStatus === 'Active'">
                Live
              </span>
              <span *ngIf="rowData.creditMonitoringStatus === 'Disabled'">
                Disabled
              </span>
              <span *ngIf="!rowData.creditMonitoringStatus">
                Not Eligible
              </span>
            </span>
          </span>

          <span *ngSwitchCase="'dateCreateBorrower'">
            {{ rowData.dateCreateBorrower | date : 'short' }}
          </span>

          <span *ngSwitchDefault>
            {{ rowData[col.field] }}
          </span>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td
          [attr.colspan]="selectedColumns ? selectedColumns.length + 1 : null"
          class="text-center"
        >
          There are no contacts
        </td>
      </tr>
    </ng-template>
  </p-table>
</ng-template>

<admin-page-template
  [useActionBarSections]="true"
  [hasPageActionBar]="hasActionBar"
  [pageMainContentTemplate]="mainContent"
  [pageActionBarLeftContentTemplate]="pageActionBarLeft"
  [pageActionBarRightContentTemplate]="pageActionBarRight"
>
</admin-page-template>

<drawer
  [title]="'Add Contact'"
  #addContactEditor
  [name]="'addContactEditorDrawer'"
  [templateRef]="addContactEditorDrawerContentRef"
  [options]="addContactsDrawerOptions"
  (closed)="onAddContactEditorDrawerClosed()"
>
</drawer>
<ng-template #addContactEditorDrawerContentRef>
  <new-contact
    [isDrawer]="true"
    (closeDrawer)="onAddContactEditorDrawerClose()"
    (contactCreated)="onContactCreated($event)"
  ></new-contact>
</ng-template>

<drawer
  #editContactEditor
  [title]="'Edit Contact'"
  [name]="'editContactEditorDrawer'"
  [templateRef]="editContactEditorDrawerContentRef"
  [options]="editContactsDrawerOptions"
  (closed)="onEditContactEditorDrawerClosed()"
>
</drawer>
<ng-template #editContactEditorDrawerContentRef>
  <borrower-editor
    [borrowerId]="borrowerId"
    [tab]="editContactTab"
    (borrowerEditCancelled)="onBorrowerEditCancelled()"
    (borrowerSaved)="onBorrowerSaved()">
  </borrower-editor>
</ng-template>
