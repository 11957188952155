<div class="row form-group">
  <label class="col-sm-2 col-form-label" for="documentTemplateName"
    >Document Type</label
  >
  <div class="col-sm-4">
    <input
      id="documentTypeName"
      name="documentTypeName"
      class="form-control"
      [(ngModel)]="type.documentTypeName"
      #documentTypeNameInput="ngModel"
      [ngClass]="{
        'is-invalid':
          documentTypeNameInput &&
          documentTypeNameInput.touched &&
          documentTypeNameInput.invalid
      }"
      required
    />
    <div class="invalid-feedback">This field is required</div>
  </div>
</div>

<div class="row form-group">
  <label class="col-sm-2 col-form-label" for="showOnBorrowerPortal"
    >Show On Borrower Portal</label
  >
  <div class="col-sm-1">
    <input
      class="form-check-input"
      type="checkbox"
      name="showOnBorrowerPortal"
      id="showOnBorrowerPortal"
      [(ngModel)]="type.showOnBorrowerPortal"
    />
  </div>
</div>

<div class="row form-group">
  <label class="col-sm-2 col-form-label" for="showOnChannels"
    >Show On Channels</label
  >
  <div class="col-sm-4">
    <ng-multiselect-dropdown
      name="showOnChannels"
      [placeholder]="'-- Select --'"
      [settings]="multiSelectSettings"
      [data]="channelOptions"
      [(ngModel)]="selectedShowOnChannels"
      (ngModelChange)="onShowOnChannelsChanged()"
    >
    </ng-multiselect-dropdown>
  </div>
</div>

<div class="row form-group">
  <label class="col-sm-2 col-form-label" for="allowUploadOnChannels"
    >Allow Upload On Channels</label
  >
  <div class="col-sm-4">
    <ng-multiselect-dropdown
      name="allowUploadOnChannels"
      [placeholder]="'-- Select --'"
      [settings]="multiSelectSettings"
      [data]="channelOptions"
      [(ngModel)]="selectedAllowUploadOnChannels"
      (ngModelChange)="onAllowUploadOnChannelsChanged()"
    >
    </ng-multiselect-dropdown>
  </div>
</div>
<div class="row form-group">
  <label class="col-sm-2 col-form-label" for="permittedAgentTypes"
    >Permitted Agent Types</label
  >
  <div class="col-sm-4">
    <ng-multiselect-dropdown
      name="permittedAgentTypes"
      [placeholder]="'-- Select --'"
      [settings]="multiSelectSettings"
      [data]="agentTypes"
      [(ngModel)]="selectedAgentTypes"
      (ngModelChange)="onAgentTypeChanged()"
    >
    </ng-multiselect-dropdown>
  </div>
</div>

<div class="row form-group">
  <label class="col-sm-2 col-form-label" for="restrictedToRoles"
    >Exclusive Role Access</label
  >
  <div class="col-sm-4">
    <ng-multiselect-dropdown
      name="restrictedToRoles"
      [placeholder]="'-- Select --'"
      [settings]="multiSelectSettings"
      [data]="roles"
      [(ngModel)]="selectedRoles"
      (ngModelChange)="onRoleChanged()"
    >
    </ng-multiselect-dropdown>
  </div>
</div>
