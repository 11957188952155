<div class="modal-header">
  <h4 class="modal-title">PDF Password Required</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="closeModal()"></button>
</div>
<div class="modal-body">
  <p>The file "{{ file.name }}" is password-protected. Please enter the password below:</p>
  <div class="mb-3">
    <label for="passwordInput" class="form-label">Password</label>
    <input
      type="password"
      id="passwordInput"
      class="form-control"
      [(ngModel)]="password"
      placeholder="Enter password"
      autofocus
    />
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="closeModal()">Cancel</button>
  <button type="button" class="btn btn-primary" (click)="submitPassword()">Submit</button>
</div>
