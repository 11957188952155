import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { LoanInformation } from '../../models/pricing/product-search-request-info.model';
import * as _ from 'lodash';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'second-lien-amount-calculator-dialog',
  templateUrl: 'second-lien-amount-calculator-dialog.component.html',
  styleUrls: ['second-lien-amount-calculator-dialog.component.scss'],
})
export class SecondLientAmountCalculatorDialogComponent implements OnInit {

  @ViewChild("secondLienForm")
  secondLienForm: NgForm;

  @Input()
  set loanInformation(loanInformation: LoanInformation) {
    if (loanInformation) {
      this._loanInformation = _.cloneDeep(loanInformation);
    }
  }

  get loanInformation(): LoanInformation {
    return this._loanInformation;
  }

  @Input()
  loanType: string = null;

  private _loanInformation: LoanInformation;

  loanTypeOptions: EnumerationItem[] = [
    new EnumerationItem("Mortgage", "Mortgage"),
    new EnumerationItem("HELOC", "HELOC")
  ];

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {}

  onLoanTypeChanged = () => {
    if (this.loanType === "HELOC") {
      this.loanInformation.secondLienAmount = null;
    } else {
      this.loanInformation.helocDrawnAmount = null;
      this.loanInformation.helocLineAmount = null;
    }
  }

  onOkClicked = () => {
    this.secondLienForm.form.markAllAsTouched();
    if (this.secondLienForm.form.valid) {
      this.activeModal.close(new SecondLienAmountSettings(this._loanInformation, this.loanType));
    }
  }
}

export class SecondLienAmountSettings {
  loanInformation: LoanInformation;
  loanType: string;

  constructor(loanInformation: LoanInformation, loanType: string) {
    this.loanInformation = loanInformation;
    this.loanType = loanType;
  }
}
