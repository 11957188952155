<div class="row" *ngIf="file" [ngClass]="{'row-warning': file.losDocFileId, 'd-flex' : displaySingleRow}">
    <div class="d-flex" [ngClass]="{'justify-content-between' : displaySingleRow}"
        style="border:0 !important; padding-top:5px">
        <input *ngIf="allowMergeSelect" type="checkbox" (click)="toggleFileSelected(file)" [(ngModel)]="isMergeFile"
            name="isMergeFile" [ngModelOptions]="{standalone: true}" />
        <a *ngIf="!shouldEdit" href="javascript:void(0)" (click)="editFileName ? onEditFileName() : null"
            [ngClass]="{'single-row-width' : displaySingleRow}">
            <span class="dark-fa-color mx-1" style="word-break: break-all;">
                {{file.fileName}}.{{fileExtension}} {{file.createDate ? '(' + (file.createDate | date:'short') + ')' :
                ''}}
            </span>
        </a>
        <div class="d-flex" *ngIf="shouldEdit">
            <div class="flex-grow-1">
                <input style="width: 10rem;" [ngClass]="{'long-edit' : displaySingleRow}" type="text"
                    class="form-control ms-2" [(ngModel)]="file.fileName" />
            </div>
            <div class="ms-3 mt-1">
                <a (click)="saveLoanDoc()">
                    <span> <i class="fa fa-check-circle text-success"></i> </span>
                </a>
                <a (click)="cancelLoanDocEdit()">
                    <span class="ms-2"> <i class="fa fa-times text-danger"></i> </span>
                </a>
            </div>
        </div>
        <div *ngIf="displaySingleRow" class="" style="margin-left: 20px;border:0 !important; padding-bottom:5px">
            <a href="javascript:void(0);" (click)="viewFile()" class="mx-1" title="View Document">
                <i class="fas fa-eye"></i>
            </a>
            <a href="javascript:void(0);" (click)="downloadFile()" class="mx-1" title="Download Document">
                <i class="fas fa-download"></i>
            </a>
            <a *ngIf="showFileTracking" href="javascript:void(0);" (click)="showFileTrackingModal()" class="mx-1"
                title="Show File Tracking">
                <i class="far fa-chart-bar"></i>
            </a>
            <a href="javascript:void(0);" *ngIf="showConvertToPdf" (click)="onConvertToPdfClicked(file)" class="mx-1"
                title="Convert to PDF">
                <i class="far fa-file-pdf"></i>
            </a>
            <a *ngIf="showMergeFiles" href="javascript:void(0);" (click)="onShowMergeFilesClicked()" class="mx-1"
                title="Show Merge Files">
                <i class="fas fa-edit"></i>
            </a>
            <a href="javascript:void(0);" *ngIf="!activeDeletion && allowDelete" (click)="activeDeletion = true;"
                class="mx-1" title="Delete Document">
                <i class="fas fa-trash-alt"></i>
            </a>
            <a href="javascript:void(0);" *ngIf="activeDeletion" (click)="activeDeletion = false;" class="mx-1">
                <i class="fa fa-times hoverDanger"></i>
            </a>
            <a href="javascript:void(0);" *ngIf="activeDeletion" (click)="confirmDeletion()" class="mx-1">
                <i class="fa fa-check-circle hoverSuccess"></i>
            </a>
        </div>
    </div>
    <div *ngIf="!displaySingleRow" style="margin-left: 20px;border:0 !important; padding-bottom:5px">
        <a href="javascript:void(0);" (click)="viewFile()" class="mx-1">
            <i class="fas fa-eye"></i>
        </a>
        <a href="javascript:void(0);" (click)="downloadFile()" class="mx-1">
            <i class="fas fa-download"></i>
        </a>
        <a *ngIf="showFileTracking" href="javascript:void(0);" (click)="showFileTrackingModal()" class="mx-1">
            <i class="far fa-chart-bar"></i>
        </a>
        <a href="javascript:void(0);" *ngIf="showConvertToPdf" (click)="onConvertToPdfClicked(file)" class="mx-1">
            <i class="far fa-file-pdf"></i>
        </a>
        <a *ngIf="showMergeFiles" href="javascript:void(0);" (click)="onShowMergeFilesClicked()" class="mx-1">
            <i class="fas fa-edit"></i>
        </a>
        <a href="javascript:void(0);" *ngIf="!activeDeletion && allowDelete" (click)="activeDeletion = true;"
            class="mx-1">
            <i class="fas fa-trash-alt"></i>
        </a>
        <a href="javascript:void(0);" *ngIf="activeDeletion" (click)="activeDeletion = false;" class="mx-1">
            <i class="fa fa-times hoverDanger"></i>
        </a>
        <a href="javascript:void(0);" *ngIf="activeDeletion" (click)="confirmDeletion()" class="mx-1">
            <i class="fa fa-check-circle hoverSuccess"></i>
        </a>
    </div>
</div>