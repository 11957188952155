import { AfterViewInit, Component, ElementRef, EventEmitter, Injector, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import _, { debounce } from 'lodash';
import { Observable, Subscription, filter, firstValueFrom } from 'rxjs';
import { LoanApplication, UserType } from 'src/app/models';
import { LosService } from 'src/app/services/los.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { EnabledBusinessChannel } from '../../models/business-channel.model';
import { PricingService } from '../../services/pricing.service';
import { getErrorMessageOrDefault } from 'src/app/shared/utils/error-utils';
import { SpinnerComponent } from 'src/app/shared/components/spinner/spinner.component';
import { NavigationStart, Router } from '@angular/router';
import { PricingIFrameChangesGuard, PricingIFrameComponentCanDeactivate } from 'src/app/core/route-guards/pricing-iframe-changes.guard';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'polly-pricer',
  templateUrl: 'polly-pricer.component.html',
  styleUrls: ['./polly-pricer.component.scss']
})
export class PollyPricerComponent extends ApplicationContextBoundComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('iFrame', { static: false }) iframe!: ElementRef<HTMLIFrameElement>;

  @ViewChild("spinner")
  spinner: SpinnerComponent;

  @Input()
  isQuickPricer: boolean = false;

  @Input()
  set enabledChannels(enabledChannels: EnabledBusinessChannel[]) {
    this.error = null;
    this._enabledChannels = enabledChannels;

    if (!enabledChannels.length) {
      this.error = "There are no pricing profiles defined, please contact your system administrator.";
      return;
    }
    this.groupSelectedVendorPricingProfiles();

    if (this.enabledChannels.length == 1) {
      this.selectedProfileChannel = this.enabledChannels[0];
    } else {
      this.selectedProfileChannel = this.enabledChannels.find(x => x.channel == this.applicationContext.application.channel);
      if (!this.selectedProfileChannel) {
        this.error = "There are no pricing profiles defined for this loan channel, please contact your system administrator.";
      }
    }
  }

  get enabledChannels(): EnabledBusinessChannel[] {
    return this._enabledChannels;
  }

  @Output()
  pricingCancelled: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  pricingCompleted: EventEmitter<LoanApplication> = new EventEmitter<LoanApplication>();

  preparingForPricing: boolean = false;
  losSyncCompleted: boolean = false;
  losPostPricingSyncCompleted: boolean = false;
  loanCreateAttempts: number = 0;
  loanSyncAttempts: number = 0;

  protected error: string = null;

  protected application: LoanApplication;
  protected pollyPricerUrl: SafeResourceUrl;
  protected selectedProfileChannel: EnabledBusinessChannel = null;

  protected groupedVendorProfiles: any[] = [];

  private _loanInfoChangesSubscription: Subscription;
  private _enabledChannels: EnabledBusinessChannel[] = [];

  private messages: string[] = [
    "Preparing your loan, please wait..",
    "Preparing your loan, please wait.",
    "Preparing your loan, please wait..",
    "Preparing your loan, please wait...",
    "Good things take time.",
    "Preparing your loan, please wait...",
    "Preparing your loan, please wait..",
    "Preparing your loan, please wait.",
    "Preparing your loan, please wait..",
    "Preparing your loan, please wait...",
    "We are almost there.",
    "Preparing your loan, please wait...",
    "Preparing your loan, please wait..",
    "Preparing your loan, please wait.",
    "Preparing your loan, please wait..",
    "Preparing your loan, please wait...",
    "This is taking longer than usual, but don't worry. We are still woirking on it!",
  ];
  private currentMessageIndex = 0;
  private messageIntervalId: number | undefined;

  constructor(
    private readonly injector: Injector,
    private readonly _router: Router,
    private readonly _spinnerService: NgxSpinnerService,
    private readonly _losService: LosService,
    private readonly _pricingService: PricingService
  ) {
    super(injector);
    this._loanInfoChangesSubscription = this.applicationContextService.loanInfoChanges.subscribe((context) => {
      if (context.application) {
        this.application = context.application;
        this.initializeDebounce();
      }
    });
  }

  async ngOnInit() {
   
  }

  async ngAfterViewInit() {
    if (this.applicationContext?.application?.applicationId) {
      this.application = this.applicationContext.application;
      this.initializeDebounce();
    } else if (this.isQuickPricer) {
      this.initializeDebounce();
    }

    this._router.events
      .pipe(
        // Filter to only NavigationStart events
        filter(event => event instanceof NavigationStart)
      )
      .subscribe((event: NavigationStart) => {
        console.log('Navigation is about to start!', event);

        if (this.pollyStartDelayTimeout) {
          clearTimeout(this.pollyStartDelayTimeout);
          this.pollyStartDelayTimeout = undefined;
        }
        if (this.pollyPollingInterval) {
          clearInterval(this.pollyPollingInterval);
          this.pollyPollingInterval = undefined;
        }
      });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this._loanInfoChangesSubscription) {
      this._loanInfoChangesSubscription.unsubscribe();
    }
    if (this.pollyStartDelayTimeout) {
      clearTimeout(this.pollyStartDelayTimeout);
      this.pollyStartDelayTimeout = undefined;
    }
    if (this.pollyPollingInterval) {
      clearInterval(this.pollyPollingInterval);
      this.pollyPollingInterval = undefined;
    }
  }

  onCancelClicked() {
    this.pricingCancelled.emit();
  }

  protected onPollyPriceChannelChanged = async (selectedChannel: EnabledBusinessChannel) => {
    await this.getPollyPricerUrl();
  }

  initializeDebounce = debounce(this.initialize, 1000);

  private async initialize() {
    if (this.isQuickPricer) {
      return;
    }

    if (this.applicationContext.userPermissions.userType == UserType.Tpo) {
      if (!this.losSyncCompleted) {
        if (!this.applicationContext.application.losIdentifier) {
          this.spinner.show("Preparing your loan, please wait...");
          this.startMessageIteration();
          while (this.loanCreateAttempts < 3 && !this.losSyncCompleted) {
            this.loanCreateAttempts++;
            if (this.loanCreateAttempts > 1)
              this.spinner.updateMessage(`Retry attempt ${this.loanSyncAttempts - 1}. Preparing your loan, please wait...`)
            await this.autoCreateLosLoan();
          }
          this.stopMessageIteration();
          if (!this.losSyncCompleted)
            this.spinner.hide();
        } else {
          await this.getPollyPricerUrl();
        }
      } else {
        await this.getPollyPricerUrl();
      }
    } else {
      await this.getPollyPricerUrl();
    }
  }

  private autoCreateLosLoan = async () => {
    this.preparingForPricing = true;
    try {
      const losAppOpResult = await firstValueFrom(
        this._losService.autoCreateLosLoan(this.application.applicationId),
      );
      this.error = undefined;
      this.losSyncCompleted = true;
      this.applicationContextService.updateMortgageAndApplication(losAppOpResult.application?.mortgageLoan,
        losAppOpResult.application, losAppOpResult.customData);
    } catch (e) {
      console.error(e);
      this.error = e.message?.replace('{' + this.application.losIdentifier + '}', this.application.refNumber) || "There was an error attempting to prepare your loan for pricing. Please contact your AE.";
    } finally {
      this.preparingForPricing = false;
    }
  }

  private groupSelectedVendorPricingProfiles() {
    this.groupedVendorProfiles = [];
    if (!this.enabledChannels?.length) {
      return;
    }
    this.groupedVendorProfiles = _.chain(this.enabledChannels)
      .groupBy("externalCompanyId")
      .map((compGrp, compId) => {
        const externalCompanyName = compId === "undefined"
          ? ""
          : this.getExternalCompanyNameById(Number(compId));
        const branchGrping = !externalCompanyName ? compGrp : _.chain(compGrp)
          .groupBy("branchId")
          .map((branchGrp, branchId) => {
            const branchName = branchId === "undefined"
              ? ""
              : this.getBranchNameById(Number(branchId));
            return {
              branchName,
              branchCreds: branchGrp
            };
          }).value();
        return {
          externalCompanyName,
          externalCompanyCreds: branchGrping
        };
      })
      .orderBy("externalCompanyName")
      .value();
  }

  private getPollyPricerUrl = async () => {
    this.spinner.show("Retreiving Polly Pricing IFrame...");
    this.preparingForPricing = true;
    try {
      const suffixUrl = await firstValueFrom(this._pricingService.getPollyPricerUrl(this.selectedProfileChannel.channel,
        this.application?.applicationId, this.selectedProfileChannel.credentialId));
      this.pollyPricerUrl = this.selectedProfileChannel?.url + suffixUrl;
      if (this.application && this.application.losIdentifier) {
        this.pollyPricerUrl += `&loanId=${this.application.losIdentifier}`;
      }
      this.preparingForPricing = false;

      if (!this.isQuickPricer) {
        await this.listenForIsPricingAssignmentComplete();
      }

    } catch (error) {
      const errorMessage = getErrorMessageOrDefault(error, {
        defaultMessage:
          'An error occurred while getting Polly Pricer Iframe URL.',
      });
      this.error = errorMessage;
    } finally {
      setTimeout(() => {this.spinner.hide();}, 3000);
      this.preparingForPricing = false;
    }
  }

  private getExternalCompanyNameById(externalCompanyId: number) {
    const matchingCompany = this.applicationContext?.globalConfig?.externalCompanies.find(ec => ec.externalCompanyId === externalCompanyId);
    return matchingCompany?.name || '';
  }

  private getBranchNameById(branchId: number) {
    const matchingBranch = this.applicationContext?.globalConfig?.branches.find(b => b.branchId === branchId);
    return matchingBranch?.branchName || '';
  }

  // Declare properties to hold the timeout and interval IDs
  private pollyStartDelayTimeout: number | undefined;
  private pollyPollingInterval: number | undefined;

  private listenForIsPricingAssignmentComplete(): void {
    const startDelay = 15000;       // 15-second delay before starting polling
    const intervalTime = 3500;      // 3.5 seconds between API calls
    const limitTime = 600000;       // 5-minute limit (600,000 ms)

    // Start a timeout to wait 15 seconds before beginning polling
    this.pollyStartDelayTimeout = window.setTimeout(() => {
      const startTime = Date.now(); // Record when polling begins
      let isRequestInFlight = false; // Prevent overlapping API calls

      // Begin polling using setInterval
      this.pollyPollingInterval = window.setInterval(() => {
        const elapsedTime = Date.now() - startTime;
        if (elapsedTime >= limitTime) {
          console.log('Time limit reached.');
          clearInterval(this.pollyPollingInterval);
          this.pollyPollingInterval = undefined;
          return;
        }

        // Skip this tick if a previous API call is still running
        if (isRequestInFlight) {
          return;
        }

        isRequestInFlight = true;
        firstValueFrom(
          this._pricingService.getPricingAssignmentCompleteInfoForPolly(this.application?.applicationId)
        )
          .then(result => {
            console.log(`API call result: ${result}`);
            if (result && result.complete) {
              console.log('Success! Stopping polling.');
              clearInterval(this.pollyPollingInterval);
              this.pollyPollingInterval = undefined;
              this.pricingCompleted.emit(this.application);
              this.losPostPricingSyncCompleted = true;
            }
          })
          .catch(error => {
            console.log(error);
            // Optionally, add error handling logic here.
          })
          .finally(() => {
            isRequestInFlight = false;
          });
      }, intervalTime);
    }, startDelay);
  }

  private startMessageIteration(): void {
    this.messageIntervalId = window.setInterval(() => {
      // Check if we've processed all messages
      if (this.currentMessageIndex >= this.messages.length) {
        this.currentMessageIndex = 0;
      }
      
      // Process the current message
      const message = this.messages[this.currentMessageIndex];
      this.spinner.updateMessage(message);
      
      // Move to the next message
      this.currentMessageIndex++;
    }, 2000);
  }

  private stopMessageIteration(): void {
    window.clearInterval(this.messageIntervalId);
  }
}
