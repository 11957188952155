import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DocumentTypesComponent } from './components/document-types/document-types.component';
import { StackingOrderComponent } from './components/stacking-order/stacking-order.component';

export const routes: Routes = [
  {
    path: 'document-types',
    data: {
      breadcrumb: 'Document Types',
    },
    component: DocumentTypesComponent
  },
  {
    path: 'stacking-order',
    data: {
      breadcrumb: 'Stacking Order',
    },
    component: StackingOrderComponent
  },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DocumentConfigRoutingModule {}
