<!-- Review Fees -->
<div *ngIf='viewMode === "FeeEdit"' class='m-container'>
  <div class='m-content'>
    <div class='card flex-grow-1'>
      <div class='card-header'>
        <div class='row align-items-center'>
          <i class='fa fa-money col-auto'></i>
          <h4 class='card-title col-auto'>Fees</h4>

          <div class='col'></div>

          <div class='button-items col-auto'>
            <!-- Hide Zero Fees Checkbox -->
            <div
              class='form-check form-switch align-self-center me-2'
              title='Hide fees with zero amount'
            >
              <input
                (ngModelChange)='onAreZeroFeesHiddenChange($event)'
                [ngModel]='areZeroFeesHidden'
                class='form-check-input'
                id='hideZeroFees'
                type='checkbox'
              />
              <label
                class='form-check-label'
                for='hideZeroFees'
              >Hide Zero Fees</label>
            </div>

            <!-- Run Fee Wizard Button -->
            <button
              *ngIf='!disableFeeWizard && allowRunFeeWizard'
              type='button'
              class='btn btn-sm btn-outline-primary'
              (click)='onRunFeeWizardClicked()'>
              Run Fee Wizard
            </button>

            <!-- Generate Proforma LE Button -->
            <button
              *ngIf='showProformaLeGeneration'
              type='button'
              class='btn btn-sm btn-outline-primary'
              (click)='onGenerateProformaLeClicked()'
            >
              <span
                *ngIf='generatingLe'
                class='spinner-border spinner-border-sm'
                style='height: 0.8rem; width: 0.8rem'
                role='status'
                aria-hidden='true'
              ></span>
              {{ !generatingLe ? 'Generate Draft LE' : 'Generating...' }}
            </button>

            <!-- Add Fee Button -->
            <button
              (click)='onClickAddFee()'
              *ngIf='isFeesV2Editable'
              class='btn btn-sm btn-outline-primary'
              type='button'>
              <i class='fa fa-plus me-1'></i>

              Add Fee
            </button>

            <!-- Discard Changes Button -->
            <button
              (click)='discardFeeChanges()'
              *ngIf='isFeesV2Editable'
              [class.btn-outline-danger]='hasChangedFees'
              [class.btn-outline-secondary]='!hasChangedFees'
              [disabled]='!hasChangedFees'
              class='btn btn-sm'
              type='button'>
              Discard Changes
            </button>

            <!-- Save Fees Button -->
            <button
              *ngIf='isFeesV2Editable'
              type='button'
              class='btn btn-sm btn-primary d-flex gap-1'
              (click)='saveFees()'
              [disabled]='isSaving'>
              <span
                *ngIf='isSaving'
                class='spinner-border spinner-border-sm'
                role='status'
                aria-hidden='true'></span>
              {{ !isSaving ? 'Save Fees' : 'Saving...' }}
            </button>
          </div>
        </div>
      </div>
      <div class='card-body overflow-auto p-0'>
        <ng-container *ngIf='!isInitializing; else loading'>
          <fees-review
            (scheduleEscrowFees)='onEscrowScheduleDrawerOpen()'
            (showFeeDetails)='onFeeEditorDrawerOpened($event)'
            [editable]='isFeesV2Editable'
          ></fees-review>
        </ng-container>
      </div>
    </div>

    <div class='fees-v2-container'>
      <div class='m-content'>
        <div
          *ngIf='hasChangedFees'
          class='alert alert-warning'
          role='alert'>
          Save or discard fee changes to edit loan information.
        </div>

        <!-- Cash to Close -->
        <div class='card'>
          <div class='card-body'>
            <cash-to-close
              (save)='saveMortgage()'
              [application]='application'
              [calculationDetails]='calculationDetails'
              [editable]='isFeesV2Editable && !hasChangedFees'
              [fees]='fees'
              [mortgage]='mortgage'
            ></cash-to-close>
          </div>
        </div>

        <!-- Proposed Monthly Payment -->
        <div class='card'>
          <div class='card-body'>
            <proposed-monthly-payment
              (save)='saveMortgage()'
              [editable]='isFeesV2Editable && !hasChangedFees'
              [mortgage]='mortgage'
            ></proposed-monthly-payment>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-container *ngIf="viewMode === 'FeeWizard'" #wizardsContainer>
  <fee-wizard *ngIf="feeWizardTypeToUse === 'Loda'" 
    [feeTemplates]="templates"
    [existingLoanFees]="fees" 
    [supportedVendorNames]="supportedVendorNames"
    (wizardCancelled)="viewMode = 'FeeEdit'" 
    (wizardCompleted)="onWizardCompleted($event)" #wizard>
  </fee-wizard>

  <mortgage-cto-fee-wizard *ngIf="feeWizardTypeToUse === 'MortgageCTO'"
    (wizardCancelled)="viewMode = 'FeeEdit'"
    (wizardCompleted)="onMortgageCtoWizardCompleted($event)">
  </mortgage-cto-fee-wizard>
</ng-container>

<ng-container *ngIf="viewMode === 'LeDocView' && leDocumentUrlToView">
  <div class='card flex-grow-1'>
    <div class='card-header'>
      <div class='row align-items-center'>
        <i class='fa fa-money col-auto'></i>
        <h4 class='card-title col-auto'>LE Document</h4>

        <div class='col'></div>

        <div class='button-items col-auto'>
          <!-- Close LE Doc View -->
          <button
            type='button'
            class='btn btn-sm btn-outline-primary'
            (click)="viewMode = 'FeeEdit'"
            [disabled]='isSaving'>
            <span
              role='status'
              aria-hidden='true'></span>
            {{ 'Close' }}
          </button>
        </div>
      </div>
    </div>
    <div class='card-body embed p-0'>
      <embed [src]="leDocumentUrlToView" frameborder="0" width="100%" height="100%" scale="tofit">
    </div>
  </div>
</ng-container>

<ng-container *ngIf="viewMode === 'RefreshFromLos'">
  <div class="alert custom-alert custom-alert-info icon-custom-alert shadow-sm my-3 ms-3 mt-5" role="alert">
    <div class="media">
      <i class="la la-exclamation-triangle alert-icon text-info font-30 me-3 align-self-center"></i>
      <div class="media-body align-self-center">
        <h5 class="mb-4">
          The following step(s) must be resolved before you can view and edit Fees:
        </h5>
        <h5 class="mb-4">
          Step 3 - Pricing MUST be applied to the loan.
        </h5>
        <ng-container *ngIf="application.losIdentifier">
          <h5 class="mb-4">
            <span></span>
            If you have already requested to Float or Lock pricing, please click 'Check Pricing Status' button below to see if
            your request has already been completed.
          </h5>
          <div class="mb-4">
            <button type="button" *ngIf="!isCheckingPricingStatus" class="btn btn-primary waves-effect waves-light ms-auto"
              (click)="onCheckPricingStatusClicked()">Check Pricing Status</button>
            <button class="btn btn-primary waves-effect waves-light ms-auto" type="button" disabled=""
              *ngIf="isCheckingPricingStatus">
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              Please wait...
            </button>
          </div>
        </ng-container>
        <ng-container *ngIf="!application.losIdentifier">
          <h5 class="mb-4">
            <span></span>
            Please go back to Step-3 and make sure you assign pricing to the loan.
          </h5>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #loading>
  <loading-indicator [loadingMessage]="'Loading fees...'"></loading-indicator>
</ng-template>

<drawer #feesEditorDrawer [title]="feeEditorDrawerTitle" [name]="'feesEditorDrawer'" [templateRef]="feeDetailsDrawerRef"
  [options]="feeEditorDrawerOptions" (closed)="onFeeEditorDrawerClosed()">
</drawer>
<ng-template #feeDetailsDrawerRef>
  <fee-details-editor
    (save)='onFeeEditorDrawerSaved($event)'
    (cancel)='onFeeEditorDrawerClosed()'
    [fee]='selectedFeeForEditingDetails'
    [fees]='fees'
    [showBasicInfo]='showBasicInfoOnFeeEditor'>
  </fee-details-editor>
</ng-template>

<drawer [title]="'Escrow Schedule'" [name]="'escrowScheduleDrawer'" [templateRef]="escrowScheduleDrawerRef"
  [options]="escrowScheduleDrawerOptions" (closed)="onEscrowScheduleDrawerClosed()">
</drawer>
<ng-template #escrowScheduleDrawerRef>
  <escrow-fees-schedule *ngIf="application" #escrowSchedule [fees]="escrowFees" [appId]="application?.applicationId"
    (escrowScheduleUpdated)="onEscrowScheduleUpdated($event)">
  </escrow-fees-schedule>
</ng-template>
