<div class="modal-header">
  <h5 class="modal-title w-100">
    {{ !stackingOrderData.stakingOrderId ? 'New' : 'Edit' }}
    Stacking Order
  </h5>

  <button
    type="button"
    class="btn-close"
    (click)="activeModal.dismiss()"
    aria-label="Close"
  ></button>
</div>

<div class="modal-body">
  <form
    id="stackingOrderForm"
    #stackingOrderForm="ngForm"
    name="stackingOrderForm"
  >
    <stacking-order-details [stackingOrder]="stackingOrderData"></stacking-order-details>
  </form>
</div>

<div class="modal-footer justify-content-end">
  <button
    type="button"
    class="btn btn-secondary"
    (click)="activeModal.dismiss()"
  >
    <i class="fa fa-close"></i> Cancel
  </button>

  <button
    type="button"
    class="btn btn-primary"
    [disabled]="isSaving"
    (click)="save()"
  >
    <span *ngIf="!isSaving">Save</span>
    <span *ngIf="isSaving">
      <span
        class="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
      ></span>
      Saving
    </span>
  </button>
</div>

