import { ThirdPartyCredentialArray, UserType } from '.';
import { CustomDataEditorType, CustomDataMultiLookupType } from '../modules/app-details/models/custom-data-config.model';
import { DefinedEmailTemplate, EmailServiceModel } from '../modules/email-configuration/models';
import { Channel } from '../modules/leads/models/lead.model';
import { PrequalGenerationSettings } from '../modules/prequal-letter-settings/models';
import { DefinedSmsTemplate, TelephonyServiceModel } from '../modules/sms-configuration/models';
import { OnlineApplicationSettings } from './config/online-application-settings.model';
import { Configuration } from './configuration.model';
import { UserProfileCompany } from './user/user-profile-company.model';

export class SystemLevel {
  sessionTimeout: Configuration;
  allowEmailImpersonation: Configuration;
  allowFreeformFeeEntry: Configuration;
  allowedRolesToFinalizeDOFindings: Configuration;
  autoConvertToPdf: Configuration;
  useDynamicCompression: Configuration;
  restrictCreateNewOption: Configuration;
  reminderEmailEnabled: Configuration;
  reminderEmailInterval: Configuration;
  reminderSmsEnabled: Configuration;
  reminderSmsInterval: Configuration;
  documentExportFormat: Configuration;
  digitalAssetVerificationEnabled: Configuration;
  digitalEmploymentVerificationEnabled: Configuration;
  creditReportingEnabled: Configuration;
  creditReportingScript: Configuration;
  ausEnabled: Configuration;
  desktopOriginatorEnabled: Configuration;
  desktopUnderwriterEnabled: Configuration;
  docMagicEnabled: Configuration;
  enhancedReportingEnabled: Configuration;
  disableCallRecording: Configuration;
  lpaEnabled: Configuration;
  losEnabled: Configuration;
  ldeEnabled: Configuration;
  pricingEnabled: Configuration;
  pricingEnabledInNewApp: Configuration;
  externalPricingLockEnabled: Configuration;
  externalPricingLockEmailNotificationsEnabled: Configuration;
  defaultPricingVendor: Configuration;
  pricingScenarioRefreshTime: Configuration;
  pricingScenarioRefreshDays: Configuration;
  pricingScenarioRefreshLoanStatuses: Configuration;
  proofOfFundsDocTypeId: Configuration;
  requireSoftPullBeforeHardPull: Configuration;
  lockDeskEmailAddress: Configuration;
  lockDeskDisabledChannels: Configuration;
  loanBeamEnabled: Configuration;
  ocrEnabled: Configuration;
  requireLosLoanLinkForCreditReporting: Configuration;
  defaultCreditVendor: Configuration;
  appraisalOrderingEnabled: Configuration;
  titleOrderingEnabled: Configuration;
  homeOwnersInsuranceEnabled: Configuration;
  documentSigningEnabled: Configuration;
  losDocumentSigningEnabled: Configuration;
  loanHiddenFields: Configuration;
  ernstFeesEnabled: Configuration;
  lodestarFeesEnabled: Configuration;
  mctoFeesEnabled: Configuration;
  socialMediaIntegrationEnabled: Configuration;
  taskUpdateExcludeAccepted: Configuration;
  smsAllowedSendTimeRange: Configuration;
  transunionImportEnabled: Configuration;
  transunionImportLeadLoanPurposeId: Configuration;
  transunionImportLeadLoanTypeId: Configuration;
  transunionImportLeadCampaignId: Configuration;
  transunionImportLeadChannel: Configuration;
  defaultFeeProvider: Configuration;
  prequalGenerationSettings: PrequalGenerationSettings;
  onlineApplicationSettings: OnlineApplicationSettings;
  definedEmailTemplate: DefinedEmailTemplate;
  definedSmsTemplate: DefinedSmsTemplate;
  borrowerPortalContent: BorrowerPortalContent;
  agentPortalContent: AgentPortalContent;
  emailService: EmailServiceModel;
  telephonyService: TelephonyServiceModel;
  integrations: Integrations;
  thirdPartyCredentials: ThirdPartyCredentialArray;
  company: ConfigurationCompany;
  companyExtendedModel: CompanyExtended;
  customDataFieldConfigModels: CustomDataConfig[];
  enableSmsAlertsByDefault: Configuration;
  restrictedUserEmailTemplateOverrides: Configuration;
  broadcastManagerRolesWithAccess: Configuration;
  broadcastManagerUsersWithAccess: Configuration;
  twoFactorAuthenticationRequiredForRoles: Configuration;
  borrowerInviteDisabledChannels: Configuration;
  documentSigningNonLosLoanOfficerSender: Configuration;
  documentSigningLosLoanOfficerSender: Configuration;

  constructor() {
    this.customDataFieldConfigModels = [];
  }
}

export class CompanyExtended {
  companyExtendedId: number;
  companyLegalName: string;
  companyDBAName: string;
  federalTaxId: string;
  businessType: number;
  locationType: number;
  phoneNumber: string;
  email: string;
  faxNumber: string;
  companyType: number;
  companyTypeName: string;
  yearsOld: string;
  numberOfLoanOfficers: number;
  numberOfLoansUnderManagement: number;
  mainPOCFirstName: string;
  mainPOCLastName: string;
  mainPOCTitle: string;
  mainPOCMobilePhone: string;
  mainPOCWorkPhone: string;
  mainPOCEmail: string;
  mainPOCNmls: string;
  mainPOCAddress: string;
  mainPOCState: string;
  mainPOCCity: string;
  mainPOCZipCode: string;
  companyModel: ConfigurationCompany;
  companyId: number;
  insertedBy: string;
  dateInserted: Date;
  updatedBy: string;
  dateUpdated: Date;

  constructor() {
    this.companyModel = new ConfigurationCompany();
  }
}

export class FeaturesModel {
  docEditingEnabled: boolean;
  docGenerationEnabled: boolean;
  docMagicEnabled: boolean;
  enhancedReportingEnabled: boolean;
  full1003Enabled: boolean;
  feesEnabled: boolean;
  leadManagementEnabled: boolean;
  dialerEnabled: boolean;
  onlineApplicationEnabled: boolean;
  onlineAppV3Enabled: boolean;
  digitalAssetVerificationEnabled: boolean;
  digitalEmploymentVerificationEnabled: boolean;
  prequalGenerationEnabled: boolean;
  smsAlertsEnabled: boolean;
  rvmAlertsEnabled: boolean;
  socialMediaAlertsEnabled: boolean;
  socialMediaIntegrationEnabled: boolean;
  emailTestsEnabled: boolean;
  inviteBorrowerCheckedByDefault: boolean;
  creditMonitoringEnabled: boolean;
  creditReportingEnabled: boolean;
  ausEnabled: boolean;
  desktopOriginatorEnabled: boolean;
  desktopUnderwriterEnabled: boolean;
  lpaEnabled: boolean;
  losEnabled: boolean;
  pricingEnabled: boolean;
  appraisalOrderingEnabled: boolean;
  titleOrderingEnabled: boolean;
  campaignsEnabled: boolean;
  multipleCredentialSupportEnabled: boolean;
  leadPostingApiAuthEnabled: boolean;
  externalCompanyEnabled: boolean;
  documentSigningEnabled: boolean;
  losDocumentSigningEnabled: boolean;
  losDocumentSigningExtendedTokenLifespanEnabled: boolean;
  loanBeamEnabled: boolean;
  ocrEnabled: boolean;
}

export class ConfigurationCompany {
  companyId: number;
  companyURL: string;
  borrowerURL: string;
  onlineAppURL: string;
  onlineAppV3Url: string;
  agentURL: string;
  companyGUID: string;
  companyName: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  logo: string;
  smallLogo: string;
  signatureLogo: string;
  favicon: string;
  actionBgColor: string;
  actionTextColor: string;
  logInImage: string;
  registerImage: string;
  nmls: string;
  enabledChannels: Channel;
  isTemplate: boolean;
  leadPostingApiAuthKey: string;
  supportEmails: string;
  timeZone: string;
  f: FeaturesModel;
  cobrowseEnabled: boolean;
  docEditingEnabled: boolean;
  docGenerationEnabled: boolean;
  feesEnabled: boolean;
  full1003Enabled: boolean;
  leadManagementEnabled: boolean;
  dialerEnabled: boolean;
  onlineApplicationEnabled: boolean;
  onlineAppV3Enabled: boolean;
  digitalAssetVerificationEnabled: boolean;
  digitalEmploymentVerificationEnabled: boolean;
  prequalGenerationEnabled: boolean;
  smsAlertsEnabled: boolean;
  rvmAlertsEnabled: boolean;
  socialMediaAlertsEnabled: boolean;
  socialMediaIntegrationEnabled: boolean;
  emailTestsEnabled: boolean;
  inviteBorrowerCheckedByDefault: boolean;
  creditMonitoringEnabled: boolean;
  creditReportingEnabled: boolean;
  ausEnabled: boolean;
  desktopOriginatorEnabled: boolean;
  desktopUnderwriterEnabled: boolean;
  docMagicEnabled: boolean;
  enhancedReportingEnabled: boolean;
  ldeEnabled: boolean;
  lpaEnabled: boolean;
  losEnabled: boolean;
  mersEnabled: boolean;
  pricingEnabled: boolean;
  campaignsEnabled: boolean;
  multipleCredentialSupportEnabled: boolean;
  leadPostingApiAuthEnabled: boolean;
  appraisalOrderingEnabled: boolean;
  titleOrderingEnabled: boolean;
  externalCompanyEnabled: boolean;
  homeOwnersInsuranceEnabled: boolean;
  documentSigningEnabled: boolean;
  losDocumentSigningEnabled: boolean;
  losDocumentSigningExtendedTokenLifespanEnabled: boolean;
  loanBeamEnabled: boolean;
  ocrEnabled: boolean;
  branchesEnabled: boolean;
  fhaLenderId: string;
  fhaSponsorId: string;
  vaLenderId: string;
  vaSponsorId: string;
  insertedBy: string;
  dateInserted: Date;
  updatedBy: string;
  dateUpdated: Date;
}

export class BorrowerPortalContent {
  id: number;
  userId: string;
  aboutUsContent: string;
  referralContent: string;
  showReferralTerms: boolean;
  referralTermsContent: string;
  isGoogleAddressesIntegrationDisabled: boolean;
  showTitleInPreferenceToRoleName: boolean;
  companyId: number;
  insertedBy: string;
  dateInserted: Date;
  updatedBy: string;
  dateUpdated: Date;
}

export class AgentPortalContent {
  id: number;
  company: any; // ConfigurationCompany
  userId: string;
  user: UserProfileCompany;
  aboutUsContent: string;
  referralContent: string;
  showReferralTerms: boolean;
  showTitleInPreferenceToRoleName: boolean;
  referralTermsContent: string;
  companyId: number;
  insertedBy: string;
  dateInserted: Date;
  updatedBy: string;
  dateUpdated: Date;
}

export class CustomDataConfig {
  customDataFieldConfigId: number;
  fieldName: string;
  labelText: string;
  fieldType: string;
  editorType: CustomDataEditorType;
  multiLookupType: CustomDataMultiLookupType;
  customDropDownOptions: string;
  restrictedToRoles: string;
  layouts: CustomDataLayout[];
  companyId: number;
  insertedBy: string;
  dateInserted: string;
  updatedBy: string;
  dateUpdated: string;
}

export class CustomDataLayout {
  roleType: UserType;
  uiColumn: number;
  uiRow: number;
}

export class Integrations {
  leadPostingUrls: LeadPostingUrl[];
  onlineApplications: OnlineApplications;
}

export class LeadPostingUrl {
  leadPostUrl: string;
  vendorName: string;
}

export class OnlineApplications {
  wizardUrls: WizardUrl[];
}

export class WizardUrl {
  flowId: number;
  isActive: boolean;
  isCustom: boolean;
  isDefault: boolean;
  name: string;
  url: string;
}
