<div *ngIf="file">
    <div class="row" [ngClass]="{'row-warning': file.docFileId && file.losDocFileId, 'row-light-grey': !file.docFileId && file.losDocFileId, 'row-white': file.docFileId && !file.losDocFileId}">
        <a *ngIf="!shouldEdit" (click)="onEditFileName()" class="mt-2" title="Change File Name">
            <i class="far" [ngClass]="{'fa-file-pdf': !showConvertToPdf, 'fa-file': showConvertToPdf}"></i>
            <span class="dark-fa-color mx-1"> {{fileName}}.{{fileExtension}} </span>
        </a>
        <div *ngIf="shouldEdit">
            <input type="text" class="form-control" [(ngModel)]="fileName" />
            <div class="text-center">
                <a (click)="saveLoanDoc()">
                    <span> <i class="fa fa-check-circle text-success"></i> </span>
                </a>
                <a (click)="cancelLoanDocEdit()">
                    <span class="ms-2"> <i class="fa fa-times text-danger"></i> </span>
                </a>
            </div>
        </div>
        <span class="date-inner-wrap"> {{file.dateInserted | date:'MM/dd/yyyy h:mma'}} </span>
        <div class="mt-3">
            <input *ngIf="loanDoc.docFiles.length > 1" type="checkbox" (click)="toggleFileSelected(file)" />
            <a (click)="onViewFileClicked(file)" title="View" class="mx-2 align-text-bottom">
                <i class='far fa-eye'></i>
            </a>
            <a title="Download" class="align-text-bottom" (click)="downloadDocument(file)">
                <i class='fas fa-download'></i>
            </a>
            <a (click)="onShowTrackingModalClicked(file)" title="Show Document Tracking" class="mx-2 align-text-bottom">
                <i class='fas fa-chart-bar'></i>
            </a>
            <a [hidden]="!showConvertToPdf" (click)="onConvertToPdfClicked(file)" title="Convert to PDF"
                class="align-text-bottom">
                <i class='far fa-file-pdf'></i>
            </a>
            <!-- OCR -->
            <a *ngIf="!file.ocrProcessed && icOcrEnabled && loanDoc.documentTypeId > -1"
                (click)="onProcessAdrOcrClicked(file)" title="Process ADR/OCR" class="align-text-bottom"
                [ngClass]="{'me-2': !showConvertToPdf, 'mx-2': showConvertToPdf}">
                <i class='fas fa-redo'></i>
            </a>
            <a [hidden]="isTpoUser" (click)="onShowMergeFilesClicked(file)" title="Edit" class="align-text-bottom"
                [ngClass]="{'mx-2': showConvertToPdf && !(loanDoc.documentTypeId > -1)}">
                <i class='fas fa-edit'></i>
            </a>
            <a [hidden]="isTpoUser" href="javascript:void(0);" class="mx-2 align-text-bottom"
                *ngIf="(!(activeDeletionIndex === index) && loanDoc.documentTypeId > -1)"
                (click)="onDeleteLoanDocClicked(index)">
                <i class="far fa-trash-alt"></i>
            </a>
            <a *ngIf="activeDeletionIndex === index" class="table-icon-button mx-2" href="javascript:void(0);"
                (click)="onDeleteLoanDocCancelClicked()">
                <i class="fa fa-times hoverDanger"></i>
            </a>
            <a *ngIf="activeDeletionIndex === index" class="table-icon-button" href="javascript:void(0);"
                (click)="onDeleteLoanDocConfirmClicked(file)">
                <i class="fa fa-check-circle hoverSuccess"></i>
            </a>
            <a *ngIf="loanDoc.losLoanDocId && !file.losDocFileId" [ngClass]="{'ms-2': activeDeletionIndex === index}"
                (click)="onSyncDocFileWithLosClicked(loanDoc, file.docFileId)" class="align-text-bottom">
                <i class="fas fa-sync-alt"></i>
            </a>
        </div>
    </div>
</div>