import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { DateTime } from 'luxon';
import { Observable, Subject, catchError, of, switchMap } from 'rxjs';
import { DataService } from 'src/app/core/services/data.service';
import { CustomData, LeadStatus, RecentLead } from 'src/app/models';
import { DashboardTaskCounts } from 'src/app/models/task/dashboard-task-counts.model';
import { EmailService } from 'src/app/services/email.service';
import { LookupService } from 'src/app/services/lookup.service';
import { BulkUpdateLead } from '../models/bulk-update-lead.model';
import { LeadCredit } from '../models/lead-credit.model';
import { LeadEmployment } from '../models/lead-employment.model';
import { LeadEvent } from '../models/lead-event.model';
import { LeadFilters } from '../models/lead-filters.model';
import {
  LeadMilestonesReport,
  LeadPerformanceDetailReport,
  LeadPerformanceReport,
  LeadReportFilterData,
} from '../models/lead-reports.model';
import { LeadRouteHistory } from '../models/lead-route-history.model';
import { Lead } from '../models/lead.model';
import { cloneDeep } from 'lodash';
import { Tag } from '../../admin/tag-management/models/tag.model';

@Injectable({
  providedIn: 'root',
})
export class LeadsService {
  updateLeadsTableSubject: Subject<Lead> = new Subject<Lead>();

  updatedLead = new Subject();

  constructor(
    private _dataService: DataService,
    private _lookupService: LookupService,
    private readonly _emailService: EmailService
  ) { }

  updateLeadsTable = (lead: Lead) => {
    this.updateLeadsTableSubject.next(lead);
  }

  private generateRequestFilterString(
    filters: LeadFilters | LeadReportFilterData
  ) {
    const cloneFilters = cloneDeep(filters);
    if (filters.dateRange) {
      if (filters.dateRange.startDate) {
        cloneFilters['dateCreatedStart'] = DateTime.fromJSDate(
          filters.dateRange.startDate
        ).toLocaleString(DateTime.DATE_SHORT, { locale: 'en-US' });
      }
      if (filters.dateRange.endDate) {
        cloneFilters['dateCreatedEnd'] = DateTime.fromJSDate(
          filters.dateRange.endDate
        ).toLocaleString(DateTime.DATE_SHORT, { locale: 'en-US' });
      }
    }
    delete cloneFilters.dateRange;

    return Object.keys(cloneFilters)
      .filter((key) => cloneFilters[key] != null && cloneFilters[key] != undefined && key != 'dateRangeToString')
      .map((key: keyof (LeadFilters | LeadReportFilterData)) => {
        if ((key as any) === 'dateCreatedEnd' || (key as any) === 'dateCreatedStart') {
          return key + '=' + this.dateFormat(cloneFilters[key]);
        } else if(key === 'state' || key === 'leadStatusIds' || key === 'campaignId' || key === 'leadTagIds'){
          return (cloneFilters[key] as string[]).map(val => key + '=' + val).join('&');
        } else {
          return key + '=' + cloneFilters[key];
        }
      }).join('&');
  }

  private dateFormat(value: any) {
    const isDateValid = DateTime.fromISO(value).isValid;
    return isDateValid
      ? DateTime.fromJSDate(new Date(value))
        .setLocale('en-US')
        .toLocaleString(DateTime.DATE_SHORT)
      : value;
  }

  getLeads(filters: LeadFilters): Observable<Lead[]> {
    const filterStr = this.generateRequestFilterString(filters);
    return this._dataService.get('api/leads/all?' + filterStr);
  }

  getUnassignedLeads(filters: LeadFilters): Observable<Lead[]> {
    const filterStr = this.generateRequestFilterString(filters);
    return this._dataService.get('api/leads/unassigned?' + filterStr);
  }

  getLeadPerformanceReports(
    filters: LeadReportFilterData
  ): Observable<LeadPerformanceReport> {
    delete filters.showArchived;
    const filterStr = this.generateRequestFilterString(filters);
    return this._dataService.get('api/leads/reports/performance?' + filterStr);
  }

  getLeadPerformanceDetailReports(
    filters: LeadReportFilterData
  ): Observable<LeadPerformanceDetailReport> {
    delete filters.showArchived;
    const filterStr = this.generateRequestFilterString(filters);
    return this._dataService.get(
      'api/leads/reports/performance-detail?' + filterStr
    );
  }

  getLeadMilestonesReports(
    filters: LeadReportFilterData
  ): Observable<LeadMilestonesReport> {
    delete filters.showArchived;
    const filterStr = this.generateRequestFilterString(filters);
    return this._dataService.get('api/leads/reports/milestones?' + filterStr);
  }

  getLeadMilestonesReportSingle(
    filters: LeadReportFilterData
  ): Observable<LeadMilestonesReport> {
    delete filters.showArchived;
    const filterStr = this.generateRequestFilterString(filters);
    return this._dataService.get('api/leads/reports/milestones?' + filterStr);
  }

  getLead(leadId: number): Observable<Lead> {
    return this.openLead(leadId).pipe(
      catchError((error) => of(error)),
      switchMap(() => this._dataService.get('api/leads/' + leadId))
    )
  }

  addLead(lead: Lead): Observable<Lead> {
    return this._dataService.post('api/leads', lead);
  }

  updateLead(lead: Lead): Observable<Lead> {
    return this._dataService.post('api/leads/' + lead.leadId, lead);
  }

  duplicateLead(lead: Lead): Observable<Lead[]> {
    return this._dataService.post('api/leads/duplicate', lead);
  }

  getCustomLeadData(leadId: number): Observable<CustomData[]> {
    return this._dataService.get('api/leads/' + leadId + '/custom-data');
  }

  saveCustomLeadData(
    leadId: number,
    customData: CustomData[]
  ): Observable<CustomData[]> {

    customData.forEach(i=> i.leadId = leadId);

    return this._dataService.post(
      'api/leads/' + leadId + '/custom-data',
      customData
    );
  }

  getLeadEmployments(leadId: number): Observable<LeadEmployment[]> {
    return this._dataService.get('api/leads/' + leadId + '/employments');
  }

  addLeadEmployment(
    leadEmployment: LeadEmployment
  ): Observable<LeadEmployment> {
    return this._dataService.post(
      'api/leads/' + leadEmployment.leadId + '/employments',
      leadEmployment
    );
  }

  updateLeadEmployment(
    leadEmployment: LeadEmployment
  ): Observable<LeadEmployment> {
    return this._dataService.post(
      'api/leads/employments/' + leadEmployment.leadEmploymentId,
      leadEmployment
    );
  }

  deleteLeadEmployment(leadEmploymentId: number): Observable<any> {
    return this._dataService.delete(
      'api/leads/employments/' + leadEmploymentId
    );
  }

  getLeadEmployment(leadEmploymentId: number): Observable<LeadEmployment> {
    return this._dataService.get('api/leads/employments/' + leadEmploymentId);
  }

  getLeadCredits(leadId: number): Observable<LeadCredit[]> {
    return this._dataService.get('api/leads/' + leadId + '/credits');
  }

  addLeadCredit(leadCredit: LeadCredit): Observable<LeadCredit> {
    return this._dataService.post(
      'api/leads/' + leadCredit.leadId + '/credits',
      leadCredit
    );
  }

  updateLeadCredit(leadCredit: LeadCredit): Observable<LeadCredit> {
    return this._dataService.post(
      'api/leads/credits/' + leadCredit.leadCreditId,
      leadCredit
    );
  }

  deleteLeadCredit(leadCreditId: number): Observable<any> {
    return this._dataService.delete('api/leads/credits/' + leadCreditId);
  }

  getLeadCredit(leadCreditId: number): Observable<LeadCredit> {
    return this._dataService.get('api/leads/credits/' + leadCreditId);
  }

  uploadLoanFile(fileToUpload: File) {
    let formData = new FormData();

    if (fileToUpload != null) {
      formData.append('file', fileToUpload);
    }

    return this._dataService.postFormData('api/leads/parse-loan-file', formData);
  }

  getLeadStatusesForLoanPurpose = (
    loanPurposeId: number,
    leadStatusId: number
  ): Observable<LeadStatus[]> => {
    let loanPurposeIdArg = loanPurposeId ? loanPurposeId : 0;
    let leadStatusIdArg = leadStatusId ? leadStatusId : -1;

    return this._lookupService.getLeadStatusForLoanPurpose(
      loanPurposeIdArg,
      leadStatusIdArg
    );
  };

  bulkUpdateLead = (lead: BulkUpdateLead): Observable<Lead[]> => {
    return this._dataService.post('api/leads/bulk-update', lead);
  };

  getTaskCountByLeadId = (leadId: number, impersonateUserId: string): Observable<DashboardTaskCounts> => {
    let url = `api/leads/${leadId}/GetTaskCountByLeadId`;
    if (impersonateUserId) {
      url += `?impersonateUserId=${impersonateUserId}`;
    }
    return this._dataService.get(url);
  };

  getLeadListsById = (leadId: number): Observable<Tag[]> => {
    return this._dataService.get('api/leads/' + leadId + '/lists');
  };

  addTagToLead(leadId: number, tagId: number): Observable<Tag> {
    return this._dataService.post(
      'api/leads/tags/' + tagId + '/leads/' + leadId,
      {}
    );
  }

  deleteTagFromLead(leadId: number, tagId: number): Observable<any> {
    return this._dataService.delete(
      'api/leads/tags/' + tagId + '/leads/' + leadId
    );
  }

  getLeadLists(): Observable<Tag[]> {
    return this._dataService.get('api/leads/lists');
  }

  addLeadList(newLeadListTag: string): Observable<Tag> {
    return this._dataService.post('api/leads/lists', { name: newLeadListTag });
  }

  deleteLeadList(leadListId: number): Observable<any> {
    return this._dataService.delete('api/leads/lists/' + leadListId);
  }

  getLeadEvents(leadId: number): Observable<LeadEvent[]> {
    return this._dataService.get('api/leads/' + leadId + '/events');
  }

  addLeadEvent(leadEvent: LeadEvent): Observable<LeadEvent> {
    return this._dataService.post(
      'api/leads/' + leadEvent.leadId + '/events',
      leadEvent
    );
  }

  getLeadEvent(leadEventId: number): Observable<LeadEvent> {
    return this._dataService.get('api/leads/events/' + leadEventId);
  }

  updateLeadEvent(leadEvent: LeadEvent): Observable<LeadEvent> {
    return this._dataService.post(
      'api/leads/events/' + leadEvent.leadEventId,
      leadEvent
    );
  }

  deleteLeadEvent(leadEventId: number): Observable<any> {
    return this._dataService.delete('api/leads/events/' + leadEventId);
  }

  getLeadRouteHistories(leadId: number): Observable<LeadRouteHistory[]> {
    return this._dataService.get('api/leads/' + leadId + '/route-history');
  }

  downloadVoiceFile = (uri: string): Observable<BlobPart> => {
    return this._dataService.downloadFile(uri, false, true);
  };

  deleteLead(leadId: number): Observable<any> {
    return this._dataService.delete('api/leads/' + leadId);
  }

  getMonsterInsightsUrl = (leadId: number): Observable<any> => {
    const url = `api/monster/insights-url?leadId=${leadId}`;
    return this._dataService.get(url);
  };

  convertLeadToLoan(
    leadId: number,
    referralSourceType: number,
    secondaryReferralSourceType: number,
    borrowerId?: number,
    coBorrowerId?: number,
    userId?: string
  ): Observable<LeadEvent> {
    let url =
      'api/leads/' +
      leadId +
      '/convert' +
      (referralSourceType ? '/' + referralSourceType : '');

    if (borrowerId || coBorrowerId || userId || secondaryReferralSourceType) {
      url +=
        '?' +
        _.compact([
          borrowerId ? `existingBorrowerId=${borrowerId}` : '',
          coBorrowerId ? `existingCoBorrowerId=${coBorrowerId}` : '',
          userId ? `leadAssignedUserId=${userId}` : '',
          secondaryReferralSourceType ? `secondaryReferralSourceType=${secondaryReferralSourceType}` : '',
        ]).join('&');
    }

    return this._dataService.post(url, {});
  }

  updateLeadStatus = (
    leadId: number,
    leadStatusId: number
  ): Observable<Lead> => {
    let url = `api/leads/${leadId}/lead-status/${leadStatusId}`;
    return this._dataService.post(url, {});
  };

  getEmailDetail = (id: number) => {
    return this._emailService.getEmailDetail(id);
  };

  sendEventUpdatedLead = (leadId: number, note: string) => {
    this.updatedLead.next({ leadId: leadId, note: note });
  }

  openLead = (leadId: number): Observable<RecentLead> => {
    return this._dataService.get(`api/leads/${leadId}/open-lead`);
  };
}
