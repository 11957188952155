import { Component, Input, OnInit } from '@angular/core';
import { HighCostMortgageDetail, RegulationZDetail } from 'src/app/models';
import { splitCamelCase } from 'src/app/core/services/string-utils';

@Component({
  selector: 'points-and-fees',
  templateUrl: 'points-and-fees.component.html',
  styleUrls: ['./points-and-fees.component.scss']
})

export class PointsAndFeesComponent implements OnInit {

  @Input()
  regulationZDetail: RegulationZDetail;

  constructor() { }

  ngOnInit() { }

  addHighCostMortgageDetail = () => {
    let highCostDetail = new HighCostMortgageDetail();
    highCostDetail.isHighCostLoan = null;

    this.regulationZDetail.highCostMortgageDetail.push(highCostDetail);
  }

  deleteHighCostMortgageDetail = (index: number) => {
    this.regulationZDetail.highCostMortgageDetail.splice(index, 1);
  }

  splitCamelCase = (str: string) => {
    return splitCamelCase(str);
  }
}
