<div class="modal-header">
  <h5 class="modal-title"> {{title}} </h5>
  <button type="button" class="btn-close" (click)="activeModal.dismiss()" aria-label="Close"></button>
</div>
<div class="modal-body">
  <form #switchCompanyForm="ngForm">
    <div class="mb-3 row" *ngIf="errorMessage">
      <div class="px-3 pt-2">
        <div class="alert alert-danger border-0 mb-0" role="alert">
          <strong>
            <div>Unable to login</div>
          </strong>
          {{errorMessage}}
        </div>
      </div>
    </div>
    <div class="mb-3 row mx-2">
      <label class="form-label" for="company">Companies</label>
      <select class="form-select" name="company" id="company"
        [disabled]="isLoggingIn || needToEnterMfaCode"
        [(ngModel)]="loginInfo.userCompanyGuid"
        (ngModelChange)="onAvailableCompanyPickedForLogin()" placeholder="Select a Company"
        #userName="ngModel" [ngClass]="{'is-invalid' : userName && userName.touched && userName.invalid}" required>
        <option [ngValue]="null">--Select One--</option>
        <ng-container *ngFor="let companies of availableCompanies | groupBy: 'companyGuid'">
          <optgroup label="{{companies.value[0].companyName}}">
            <option *ngFor="let company of companies.value; let i = index" value="{{company.userCompanyGuid}}">
              {{company.userType}} {{company.externalCompanyId ? (" - " + company.externalCompanyName ) : " "}}
            </option>
          </optgroup>
        </ng-container>
      </select>
      <div class="invalid-feedback">Please enter your password.</div>
    </div>
    <div class="mb-3 row mx-2" *ngIf="loginInfo.userCompanyGuid && !needToEnterMfaCode">
      <label class="form-label" for="password">Password</label>
      <input id="password" name="password" class="form-control" type="password" [(ngModel)]="loginInfo.password"
        #password="ngModel" [ngClass]="{'is-invalid' : password && password.touched && password.invalid}"
        required />
      <div class="invalid-feedback">Please enter your password.</div>
    </div>
    <div class="mb-3 row mx-2" *ngIf="loginInfo.userCompanyGuid && needToEnterMfaCode">
      <label class="form-label" for="mfaCode">Please enter the code we sent you</label>
      <input id="mfaCode" name="mfaCode" class="form-control" type="text" [(ngModel)]="loginInfo.twoFactorCode"
        #twoFactorCode="ngModel" [ngClass]="{'is-invalid' : twoFactorCode && twoFactorCode.touched && twoFactorCode.invalid}"
        required />
      <div class="invalid-feedback">Please enter your code.</div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss()">Cancel</button>
  <button class="btn btn-primary float-end" type="button" [disabled]="!loginInfo.userCompanyGuid"
    (click)="onLoginClicked()">
    <span *ngIf="isLoggingIn" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    {{ isLoggingIn ? 'Logging in...' : 'Login'}}
  </button>
</div>
