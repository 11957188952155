import { Component, Input, OnInit } from '@angular/core';
import { formViewProvider } from 'src/app/core/services/form-view.provider';
import { StackingOrder } from 'src/app/models/config/stacking-order.model';

@Component({
  selector: 'stacking-order-details',
  templateUrl: './stacking-order-details.component.html',
  styleUrls: ['./stacking-order-details.component.scss'],
  viewProviders: [formViewProvider],
})
export class StackingOrderDetailsComponent implements OnInit {

  @Input() stackingOrder: StackingOrder;

  constructor(
  ) {
  }

  ngOnInit(): void {

  }

}

