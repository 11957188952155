<div class="activity-info shadow-sm" [ngClass]="{'active-conversation': conversation.isActive}"
    (click)="onConversationClicked(conversation)">

    <div class="row">
        <div class="d-flex">
            <div class="activity-info-icon">
                <i class="contact-icon align-self-center fw-bold"
                    [ngStyle]="{'backgroundColor': conversation.contactAvatarColor ? conversation.contactAvatarColor : defaultColor}">
                    <span class="text-white">
                        {{ conversation.contactAlias }}
                    </span>
                </i>
                <i *ngIf="conversation.conversationId && conversation.isUnread === true"
                    class="fas fa-circle unread-conversation position-absolute"></i>
            </div>
            <div class="d-block ms-3" style="padding-top: 6px;flex-grow: 2;">
                <p class="activity-info-text mb-1 font-13">
                    <span *ngIf="conversation.chatMedium == 'InternalMessage'"> Int Msg - Loan 1{{
                        conversation.applicationId | padLeft:'0': 9 }} </span>
                    <span *ngIf="conversation.chatMedium !== 'InternalMessage'">
                        {{conversation.externalName | phone}}
                        {{conversation.conversations && conversation.conversations.length > 1 ? '(' +
                        conversation.conversations.length + ')' : '' }}
                    </span>
                    <!-- <span
                        *ngIf="conversation.chatMedium !== 'InternalMessage' && !conversation.externalName && externalParticipant.phoneNumber">
                        {{ externalParticipant.phoneNumber | phone }} </span> -->
                    <i class="fas fa-archive ms-2" *ngIf="conversation.isHidden"></i>
                </p>
                <p class="text-muted message-preview">
                    <span [innerHtml]="conversation.mostRecentMessagePreview"></span>
                </p>
            </div>
            <div class="text-end" style="flex-grow: 1;margin-top: 15px;">
                <p class="text-muted me-3">
                    <span> {{ conversation.mostRecentMessageDateDisplay ? conversation.mostRecentMessageDateDisplay :
                        '--' }} </span>
                </p>
            </div>
        </div>
    </div>
</div>