import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import _, { debounce } from 'lodash';
import { Subscription, firstValueFrom } from 'rxjs';
import { EnvironmentService } from 'src/app/core/services/environment/environment.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { Branch, LoanApplication, UserType } from 'src/app/models';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { LosService } from 'src/app/services/los.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { PricingService } from '../../services/pricing.service';
import { PollHelperService } from 'src/app/shared/services/poll-helper.service';
import { User } from 'src/app/models/user/user.model';
import { Utils } from 'src/app/core/services/utils';

@Component({
  selector: 'lenderprice-pricer',
  templateUrl: 'lenderprice-pricer.component.html',
  styleUrls: ['./lenderprice-pricer.component.scss']
})
export class LenderPricePricerComponent extends ApplicationContextBoundComponent implements OnInit {

  @Input()
  isQuickPricer: boolean = false;

  @Output()
  pricingCancelled: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  pricingCompleted: EventEmitter<LoanApplication> = new EventEmitter<LoanApplication>();

  preparingForPricing: boolean = false;
  losSyncCompleted: boolean = false;
  loanSyncAttempts: number = 0;
  error: string = null;

  protected application: LoanApplication;

  protected lenderPricePricerUrl: SafeResourceUrl;
  protected channels: EnumerationItem[] = [];
  protected branches: Branch[] = [];
  protected channelBranches: Branch[] = [];

  protected selectedChannel: string = null;
  protected selectedBranch: number = null;
  protected selectedUserCompanyGuid: string = null;
  protected externalBranchUsers: User[] = [];

  protected branchName: string = "";
  protected userName: string = "";
  protected isTpo: boolean = false;

  private _loanInfoChangesSubscription: Subscription;

  constructor(
    private readonly injector: Injector,
    private readonly _losService: LosService,
    private readonly _domSanitizer: DomSanitizer,
    private readonly _pricingService: PricingService,
    private readonly _environment: EnvironmentService,
    private readonly _localStorage: LocalStorageService,
    private readonly _pollHelper: PollHelperService
  ) {
    super(injector);
    this._loanInfoChangesSubscription = this.applicationContextService.loanInfoChanges.subscribe((context) => {
      this.channels = context.globalConfig.enabledChannels || [];
      this.branches = context.globalConfig.enabledBranches || [];

      if (context.application) {
        this.application = context.application;

        if (this.channels.length == 1) {
          this.selectedChannel = this.channels[0].name;
          this.onLenderPriceChannelChanged();
        }
        else {
          this.initializeDebounce();
        }
      }
    });
  }

  async ngOnInit() {
    this.channels = this.applicationContext?.globalConfig?.enabledChannels || [];
    this.branches = this.applicationContext?.globalConfig?.enabledBranches || [];

    this.isTpo = this.applicationContext.userPermissions.userType == UserType.Tpo;

    if (this.applicationContext?.application?.applicationId) {
      this.application = this.applicationContext.application;
      this.selectedChannel = this.applicationContext.application.channel;
      this.onLenderPriceChannelChanged();
    }
    else if (this.channels.length == 1) {
      this.selectedChannel = this.channels[0].name;
      this.onLenderPriceChannelChanged();
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this._loanInfoChangesSubscription) {
      this._loanInfoChangesSubscription.unsubscribe();
    }
    if (this.lenderPricingStartDelayTimeout) {
      clearTimeout(this.lenderPricingStartDelayTimeout);
    }
    if (this.lenderPricingPollingInterval) {
      clearInterval(this.lenderPricingPollingInterval);
    }
  }

  onCancelClicked() {
    this.pricingCancelled.emit();
  }

  initializeDebounce = debounce(this.initialize, 1000);

  protected onChangePricingProfileClicked() {
    this.selectedChannel = null
    this.selectedBranch = null;
    this.selectedUserCompanyGuid = null;
  }

  protected onLenderPriceChannelChanged = () => {
    this.selectedBranch = null;
    this.selectedUserCompanyGuid = null;
    this.channelBranches = [];

    if (this.selectedChannel) {
      if (this.application?.branchId) {
        this.selectedBranch = this.application.branchId;
        this.onLenderPriceBranchChanged();
      } else {
        const filteredBranchesByChannel = this.branches.filter(b => b.enabledChannels.includes(this.selectedChannel));
        this.channelBranches = _.sortBy(filteredBranchesByChannel, ["branchName"], ["asc"]);

        if (this.channelBranches.length == 1) {
          this.selectedBranch = this.channelBranches[0].branchId;

          this.onLenderPriceBranchChanged();
        }
      }
    }
  }

  protected onLenderPriceBranchChanged = () => {
    if (this.selectedBranch) {
      //load external branch users
      if (this.application) {
        this.selectedUserCompanyGuid = this.applicationContext.currentlyLoggedInUser.userCompanyGuid;
        this.onLenderPriceUserChanged();
      } else {
        this.externalBranchUsers = this.applicationContext.globalConfig.tpoUsers.filter(x => x.branchId == this.selectedBranch);
        if (this.externalBranchUsers.length == 1) {
          this.selectedUserCompanyGuid = this.externalBranchUsers[0].userCompanyGuid;
          this.onLenderPriceUserChanged();
        }
        else if (this.externalBranchUsers.filter(x => x.userCompanyGuid == this.applicationContext.currentlyLoggedInUser.userCompanyGuid).length > 0) {
          this.selectedUserCompanyGuid = this.applicationContext.currentlyLoggedInUser.userCompanyGuid;
          this.onLenderPriceUserChanged();
        }
      }
    }
  }

  protected onLenderPriceUserChanged = () => {
    if (this.selectedUserCompanyGuid) {
      const branch = this.branches.find(b => b.branchId == this.selectedBranch);
      const user = this.externalBranchUsers.find(u => u.userCompanyGuid == this.selectedUserCompanyGuid);
      this.branchName = branch?.branchName;
      this.userName = user ? Utils.getPersonsDisplayName(user) : "";
      this.initializeDebounce();
    }
  }

  private async initialize() {
    if (this.isQuickPricer) {
      await this.getLenderPricePricerUrl();
      return;
    }

    if (this.isTpo) {
      if (!this.losSyncCompleted) {
        if (!this.applicationContext.application.losIdentifier) {
          while (this.loanSyncAttempts < 3 && !this.losSyncCompleted) {
            this.loanSyncAttempts++;
            await this.autoCreateLosLoan();
          }
        } else {
          await this.getLenderPricePricerUrl();
        }
      } else {
        await this.getLenderPricePricerUrl();
      }
    } else {
      await this.getLenderPricePricerUrl();
    }
  }

  private getLenderPricePricerUrl = async () => {
    this.preparingForPricing = true;
    try {
      const url = await firstValueFrom(
        this._pricingService.getLenderPricePricerUrl(this.application?.applicationId),
      );

      this.error = undefined;

      let decodedUrl = decodeURIComponent(url);
      const alreadyHasQueryParams = decodedUrl.includes('?');
      const queryParamsStartingChar = alreadyHasQueryParams ? '&' : '?';
      decodedUrl = decodedUrl + `${queryParamsStartingChar}baseUrl=${this._environment.apiInfo.apiBaseUrl}&token=${this._localStorage.authorizationData.token}&appId=${this.application?.applicationId || 0}`;
      if (this.selectedUserCompanyGuid) {
        decodedUrl = decodedUrl + `&userCompanyGuid=${this.selectedUserCompanyGuid}`;
      }
      this.lenderPricePricerUrl = this._domSanitizer.bypassSecurityTrustResourceUrl(decodedUrl);

      this.preparingForPricing = false;

      if (!this.isQuickPricer) {
        await this.listenForIsPricingAssignmentComplete();
      }

    } catch (e) {
      this.preparingForPricing = false;
      this.error = e.message?.replace('{' + this.application?.losIdentifier + '}', this.application?.refNumber) || "There was an error attempting to load Lender Price IFrame.";
    }
  }

  private autoCreateLosLoan = async () => {
    this.preparingForPricing = true;
    try {
      const losAppOpResult = await firstValueFrom(
        this._losService.autoCreateLosLoan(this.application.applicationId),
      );
      this.error = undefined;
      this.losSyncCompleted = true;
      this.preparingForPricing = false;
      this.applicationContextService.updateMortgageAndApplication(losAppOpResult.application?.mortgageLoan,
        losAppOpResult.application, losAppOpResult.customData);
    } catch (e) {
      console.error(e);
      this.error = e.message?.replace('{' + this.application.losIdentifier + '}', this.application.refNumber) || "There was an error attempting to prepare your loan for pricing. Please contact your AE.";
      this.preparingForPricing = false;
    }
  }
  // Declare properties to hold the timeout and interval IDs
  private lenderPricingStartDelayTimeout: number | undefined;
  private lenderPricingPollingInterval: number | undefined;

  private listenForIsPricingAssignmentComplete(): void {
    const startDelay = 15000;       // 15-second delay before starting polling
    const intervalTime = 2500;      // 2.5 seconds between API calls
    const limitTime = 600000;       // 5-minute limit (600,000 ms)

    // Start a timeout that waits for 15 seconds before beginning polling
    this.lenderPricingStartDelayTimeout = window.setTimeout(() => {
      const startTime = Date.now(); // Record when polling actually begins
      let isRequestInFlight = false; // Prevent overlapping API calls

      // Begin polling using setInterval
      this.lenderPricingPollingInterval = window.setInterval(() => {
        const elapsedTime = Date.now() - startTime;

        // Check if we've exceeded the time limit
        if (elapsedTime >= limitTime) {
          console.log('Time limit reached.');
          clearInterval(this.lenderPricingPollingInterval);
          this.lenderPricingPollingInterval = undefined;
          return;
        }

        // Skip this interval tick if the previous API call hasn't completed
        if (isRequestInFlight) {
          return;
        }

        isRequestInFlight = true;
        firstValueFrom(
          this._pricingService.getPricingAssignmentCompleteInfoForLenderPrice(this.application?.applicationId)
        )
          .then(result => {
            console.log(`API call result: ${result}`);
            if (result && result.complete) {
              console.log('Success! Stopping polling.');
              clearInterval(this.lenderPricingPollingInterval);
              this.lenderPricingPollingInterval = undefined;
              this.pricingCompleted.emit(this.application);
            }
          })
          .catch(error => {
            console.log(error);
            // Optionally handle errors or retry logic here
          })
          .finally(() => {
            isRequestInFlight = false;
          });
      }, intervalTime);
    }, startDelay);
  }
}
