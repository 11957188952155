<form #textInputForm="ngForm" style="height: 100%">
  <div *ngIf="!inEditMode"
    [ngClass]="
      { 'ng-invalid-requested' : model && model.invalid && model.errors && model.errors.required && ((required && !fieldBeingEdited) || (fieldBeingEdited && urlaFieldsConfig[fieldBeingEdited]?.required == 'requested')),
        'ng-invalid-required' : model && model.invalid && model.errors && model.errors.required && ((required && !fieldBeingEdited) || (fieldBeingEdited && urlaFieldsConfig[fieldBeingEdited]?.required == 'required')),
        'ng-invalid-pattern' : model && model.invalid && model.errors && model.errors.pattern,
        'ng-invalid-mask' : model && model.invalid && model.errors && model.errors.mask,
        'form-input' : showBorder,
        'no-border' : !showBorder,
        'bg-transparent' : readonly
      } "
      [hidden]="fieldBeingEdited && urlaFieldsConfig[fieldBeingEdited]?.hidden">
    <span class="input-label" style="display: inline;" title="{{label}}" *ngIf="label && label.length > 0">
      <span class="input-label-overflow">{{label}}</span>
      <button (click)="editClickEvent.emit()" [hidden]="!hasEditButton || readonly || disabled" style="float: right; background: transparent; border: none;">
        <i class="fas fa-pencil-alt"></i>
      </button>
      <button *ngIf="copyButtonVisible" (click)="copyClicked.emit()" [hidden]="!hasEditButton || readonly || disabled" style="float: right; background: transparent; border: none;" title="{{copyTitleText}}">
        <i class="far fa-copy"></i>
      </button>
    </span>
    <input
      type="text"
      [(ngModel)]="value"
      #model="ngModel"
      name="{{name}}"
      (focus)="onFocus()"
      (blur)="onBlurred($event)"
      [pattern]="pattern"
      [required]="required"
      [placeholder]="placeholder ? placeholder : ''"
      [hidden]="model.invalid && !model.errors?.required && errorPreviewNotClickedYet && !isFocused"
      [ngClass]="
        { 'ng-invalid-requested' : model && model.invalid && model.errors && model.errors.required && fieldBeingEdited && urlaFieldsConfig[fieldBeingEdited]?.required == 'requested',
          'ng-invalid-required' : model && model.invalid && model.errors && model.errors.required && fieldBeingEdited && urlaFieldsConfig[fieldBeingEdited]?.required == 'required',
          'ng-invalid-pattern' : model && model.invalid && model.errors && model.errors.pattern,
          'ng-invalid-mask' : model && model.invalid && model.errors && model.errors.mask,
          'no-border' : !showBorder
        }"
      [mask]="mask"
      [showMaskTyped]="true"
      [readonly]="readonly || (hasEditButton && !isEditableEvenIfItHasEditButton)"
      [disabled]="disabled"
      [maxlength]="maxlength"
      class="input-element" />
      <span class="form-control ng-invalid-pattern" style="border: 0; padding:0;" (click)="onErrorPreviewClicked()"
        *ngIf="model.invalid && !model.errors?.required && errorPreviewNotClickedYet && !isFocused"
        [ngClass]="{'disabled bg-transparent': readonly}">{{value}}</span>
  </div>

  <edit-mode-input *ngIf="inEditMode" 
    [label]="label" 
    [name]="name" 
    [urlaFieldsConfig]="urlaFieldsConfig" 
    [fieldBeingEdited]="fieldBeingEdited" 
    [showBorder]="showBorder">
  </edit-mode-input>
</form>
