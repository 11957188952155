import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { FileDto } from 'src/app/models/borrower/file-dto.model';
import { MergeDocFilesRequest } from 'src/app/modules/loan-docs/models/merge-files/merge-doc-files-request.model';
import { LoanDocsService } from 'src/app/modules/loan-docs/services/loan-docs.service';
import { NotificationService } from 'src/app/services/notification.service';
import * as _ from 'lodash';
import { LoanDocService } from 'src/app/services/loan-doc.service';
import { FileService } from 'src/app/services/file.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { FileTrackingDialogComponent } from '../file-tracking-dialog/file-tracking-dialog.component';
import { Constants } from 'src/app/services/constants';
import { DocFilesEditDialogComponent } from 'src/app/modules/loan-docs/components/doc-files-edit-dialog/doc-files-edit-dialog.component';
import { GlobalConfig } from 'src/app/models/config/global-config.model';
import { Utils } from 'src/app/core/services/utils';

@Component({
  selector: 'view-file',
  templateUrl: './view-file.component.html',
  styleUrls: ['./view-file.component.scss']
})
export class ViewFileComponent implements OnInit {

  @Input()
  set file(file: FileDto) {
    this._file = _.cloneDeep(file);
  }

  get file(): FileDto {
    return this._file;
  }

  @Input()
  appId: number;

  @Input()
  globalConfig: GlobalConfig;

  @Input()
  allowDelete: boolean = true;

  @Input()
  allowMergeSelect: boolean = true;

  @Input()
  displaySingleRow: boolean = false;

  @Input()
  showFileTracking: boolean = true;

  @Input()
  showMergeFiles: boolean = true;

  @Input()
  editFileName: boolean = true;

  @Output()
  fileDeleted: EventEmitter<FileDto> = new EventEmitter();

  @Output()
  fileRenamed: EventEmitter<FileDto> = new EventEmitter();

  @Output()
  fileCheckStatusChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  viewDocFile: EventEmitter<any> = new EventEmitter();

  showConvertToPdf: boolean;

  tempFileName: string;

  shouldEdit: boolean;

  fileExtension: string;

  isMergeFile: boolean;

  activeDeletion: boolean = false;

  modalOptions: NgbModalOptions;

  private _file: FileDto;

  constructor(private readonly _loanDocsService: LoanDocsService,
    private readonly _notifyService: NotificationService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _loanDocService: LoanDocService,
    private readonly _fileService: FileService,
    private readonly _modalService: NgbModal) {
    this.modalOptions = {
      windowClass: 'modal-full-width',
      backdrop: 'static',
      centered: true,
    };
  }

  ngOnInit(): void {
    this.initialize();
  }

  onEditFileName = () => {
    this.shouldEdit = true;
  }

  cancelLoanDocEdit = () => {
    const { fileName } = Utils.getFileNameAndExtension(this.tempFileName);
    this.file.fileName = fileName;
    this.shouldEdit = false;
  }

  saveLoanDoc = () => {
    this._spinner.show();
    this.tempFileName = this.file.fileName + "." + this.fileExtension;

    let file = new MergeDocFilesRequest(null, null, this.file.guid, (this.file.fileName + "." + this.fileExtension), null, false)

    this._loanDocsService.mergeDocFiles(file, this.file.loanDocId).subscribe(result => {
      this._spinner.hide();
      this.shouldEdit = false;
      this.fileRenamed.emit(this.file);
      this._notifyService.showSuccess('Successfully renamed document!', 'Success');
    }, err => {
      this._spinner.hide();
      this._notifyService.showError(err.message || 'Rename Document', 'Fail!');
    })
  }

  private initialize = () => {
    this.shouldEdit = false;

    if (this.file && this.file.fileName) {
      this.showConvertToPdf = !this.file.fileName.toLowerCase().endsWith(".pdf");
      this.tempFileName = _.cloneDeep(this.file.fileName);

      const { fileName, extension } = Utils.getFileNameAndExtension(this.file.fileName);

      this.file.fileName = fileName;
      this.fileExtension = extension;
    } else {
      this.showConvertToPdf = false;
      this.tempFileName = '';
    }
  }

  toggleFileSelected = (file: FileDto) => {
    file.selectedForAction = file.selectedForAction == true ? false : true;
    this.fileCheckStatusChanged.emit(file.selectedForAction);
  }

  downloadFile = () => {
    this._spinner.show();
    this._loanDocService.getLoanDoc(this.file.loanDocId).subscribe(result => {
      if (result && result.docFiles.length > 0) {
        let file = result.docFiles.find(docfile => docfile.guid == this.file.guid);
        let fileGuid = file.guid;
        let mimeType = file.mimeType;
        this._loanDocService.downloadFile(fileGuid, mimeType).subscribe(data => {
          this._spinner.hide();
          const blob = new Blob([data], { type: 'application/pdf', });
          let downloadLink = document.createElement('a');
          downloadLink.href = URL.createObjectURL(blob);
          let fileName = file.fileName;
          downloadLink.setAttribute('download', fileName);
          document.body.appendChild(downloadLink);
          downloadLink.click();
        });
      } else {
        this._spinner.hide();
      }
    }, error => {
      this._notifyService.showError(error.message, 'Error');
      this._spinner.hide();
    });
  }

  showFileTrackingModal = () => {
    this._spinner.show();
    this._fileService.getFileTracking(this.file.guid).subscribe((response) => {
      this._spinner.hide();
      const modelRef = this._modalService.open(FileTrackingDialogComponent, Constants.modalOptions.large);
      modelRef.componentInstance.title = this.file.fileName;
      modelRef.componentInstance.fileTrackingInfo = response;
    }, (err) => {
      this._notifyService.showError(err.message, 'Error');
      this._spinner.hide();
    });
  }

  confirmDeletion = () => {
    this._spinner.show();
    this._fileService.removeFile(this.file.guid).subscribe((response) => {
      this._notifyService.showSuccess("Moved file to trash", "Success");
      this.activeDeletion = false;
      this.fileDeleted.emit(this.file);
      this._loanDocsService.sendEventDocumentUploaded();
    }, (err) => {
      this._notifyService.showError(err ? (err.data ? err.data.message : 'Unable to move file to trash') : "Unable to move file to trash", 'Error');
    }).add(() => this._spinner.hide());
  }

  onConvertToPdfClicked = (file: FileDto) => {
    this._spinner.show();
    this._fileService.convertToPdf(file.guid).subscribe(result => {
      this._spinner.hide();
      this._notifyService.showSuccess('Conversion was successful.', 'Success!');
      this.showConvertToPdf = false;
      this.tempFileName = _.cloneDeep(result);
      let splitFileName = result.split(".");
      this.file.fileName = splitFileName[0];
      this.fileExtension = splitFileName[1];
    }, err => {
      this._spinner.hide();
      let errorMessage = "An error occurred while converting document to pdf.";
      if (err.error) {
        errorMessage = err.error;
      }
      this._notifyService.showError(errorMessage, 'Error!');
    })
  }

  viewFile = () => {
    this._spinner.show();
    this._loanDocService.getLoanDoc(this.file.loanDocId).subscribe(result => {
      if (result && result.docFiles.length > 0) {
        let file = result.docFiles.find(docfile => docfile.guid == this.file.guid);
        const fileGuid = file.guid;
        this._loanDocService.viewFile(fileGuid).subscribe(data => {
          this._spinner.hide();
          let blob;
          if (file.fileName.split('.')[1] == 'fnm') {
            blob = new Blob([data], { type: 'fnm' });
          } else {
            blob = new Blob([data], { type: file.mimeType });
          }
          const url = window.URL.createObjectURL(blob);
          this.viewDocFile.emit(url);
        });
      } else {
        this._spinner.hide();
      }
    }, error => {
      this._notifyService.showError(error.message, 'Error');
      this._spinner.hide();
    })
  }

  onShowMergeFilesClicked = () => {
    this._fileService.getDocFile(this.file.guid).subscribe((docFile) => {
      this._loanDocsService.getLoanDocs(this.appId).subscribe((loanDocs) => {
        const modalRef = this._modalService.open(DocFilesEditDialogComponent, this.modalOptions)
        modalRef.componentInstance.title = 'Document File Editor';
        modalRef.componentInstance.file = docFile;
        modalRef.componentInstance.fileExtension = this.fileExtension;
        modalRef.componentInstance.appId = this.appId;
        modalRef.componentInstance.loanDocs = loanDocs;
        modalRef.componentInstance.globalConfig = this.globalConfig;
        modalRef.componentInstance.modalOptions = this.modalOptions;
        modalRef.result.then((result) => {

        }, (res) => {
        });
      }, (err) => {
        this._notifyService.showError("Error retrieving loan docs for app " + this.appId + '. ' + err ? err.message || err : '', 'Error');
      });
    }, (err) => {
      this._notifyService.showError(err ? (err.data ? err.data.message : "Error retrieving doc file") : "Error retrieving doc file", 'Error');
    });
  }
}
