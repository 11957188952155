import {
  HttpClient,
  HttpBackend,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Constants } from 'src/app/services/constants';
import { EnvironmentService } from './environment/environment.service';
import { mergeMap } from 'rxjs/operators';
import { Observable, of, Subscription } from 'rxjs';
import * as _ from 'lodash';

const headers = {
  'Content-Type': 'application/json',
  'auth-req': 'true',
  'X-Requested-With': 'XMLHttpRequest',
};

const fileUploadHeaders = {
  'Content-Type': undefined,
};

@Injectable()
export class DataService implements OnDestroy {

  private _companyGuid: string = '';

  // This one is the one to use to by-pass the auth interceptor
  private _httpClient: HttpClient;

  private readonly _activatedRouteSubscriotion: Subscription;

  constructor(
    private _handler: HttpBackend,
    private readonly _http: HttpClient,
    private readonly _environmentService: EnvironmentService,
    private readonly _activatedRoute: ActivatedRoute
  ) {
    this._activatedRoute.queryParams.subscribe((params) => {
      this._companyGuid = params['companyGuid'];
    });
    this._httpClient = new HttpClient(this._handler);
  }

  ngOnDestroy(): void {
    this._activatedRouteSubscriotion?.unsubscribe();
  }

  postFormData = (url: string, formData: FormData): Observable<any> => {
    const urlToUse = this._environmentService.apiInfo.apiBaseUrl + url;
    return this._http.post(urlToUse, formData, { headers: {} });
  };

  post = (url: string, data: any, customHeaders?: any, customOptions?: any): Observable<any> => {
    const urlToUse = this._environmentService.apiInfo.apiBaseUrl + url;
    return this._http.post(urlToUse, data, { headers: customHeaders ? customHeaders : headers, ...customOptions });
  };

  postWithoutAuth = (url: string, data: any): Observable<any> => {
    const urlToUse = this._environmentService.apiInfo.apiBaseUrl + url;
    return this._httpClient.post(urlToUse, data, { headers: headers });
  };

  put = (url: string, data: any): Observable<any> => {
    const urlToUse = this._environmentService.apiInfo.apiBaseUrl + url;
    return this._http.put(urlToUse, data, { headers: headers });
  };

  patch = (url: string, data: any): Observable<any> => {
    const urlToUse = this._environmentService.apiInfo.apiBaseUrl + url;
    return this._http.patch(urlToUse, data, { headers: headers });
  }

  getWithoutAuth = (url: string): Observable<any> => {
    const urlToUse = this._environmentService.apiInfo.apiBaseUrl + url;
    return this._httpClient.get(urlToUse, { headers });
  };

  get = (url: string, customOptions?: any): Observable<any> => {
    const urlToUse = this._environmentService.apiInfo.apiBaseUrl + url;
    return this._http.get(urlToUse, { headers: headers, ...customOptions });
  };

  delete = (url: string, data?: any): Observable<any> => {
    const urlToUse = this._environmentService.apiInfo.apiBaseUrl + url;
    return this._http.delete(urlToUse, { headers: headers, body: data });
  };

  getRelativePath = (url: string): Observable<any> => {
    const urlToUse = url;
    return this._http.get(urlToUse, { headers: headers });
  };

  downloadFile = (url: string, relativePath: boolean = true, bypassAuth: boolean = false, mimeType: string = null): Observable<BlobPart> => {
    const httpClient = bypassAuth ? this._httpClient : this._http;
    const options: any = {
      headers: {
        'Content-Type': Constants.mimeTypes.octetStream,
      },
      observe: 'response',
      responseType: mimeType ? ('arraybuffer' as 'arraybuffer') : ('blob' as 'blob')
    }
    const urlToUse = relativePath ? this._environmentService.apiInfo.apiBaseUrl + url : url;
    return httpClient.get(urlToUse,
      options).pipe(mergeMap((response: any) => {
        const blobUrl = response.headers.get('LE-B-Loc');
        const contentType = response.headers.get('Content-Type');
        let body = response.body;
        if (mimeType && mimeType !== contentType && !blobUrl) {
          body.type = mimeType
        }
        return blobUrl != null ? this.downloadFile(blobUrl, false, true, contentType) : of(body);
      }));
  }

  downloadBinaryFileByAbsolueUrl = (url: string, authHeader: string): Observable<BlobPart> => {
    const options: any = {
      headers: {
        'Content-Type': Constants.mimeTypes.octetStream,
        'Authorization': `Bearer ${authHeader}`
      },
      observe: 'response',
      responseType: 'arraybuffer' as 'arraybuffer'
    }
    const urlToUse = url;
    return this._httpClient.get(urlToUse, options);
  }

  downloadBinaryFile = (url: string): Observable<BlobPart> => {
    const options: any = {
      headers: {
        'Content-Type': Constants.mimeTypes.octetStream,
      },
      observe: 'response',
      responseType: 'arraybuffer' as 'arraybuffer'
    }
    const urlToUse = this._environmentService.apiInfo.apiBaseUrl + url;
    return this._http.get(urlToUse, options);
  }

  downloadBinaryFileWithPost = (url: string, data: any): Observable<any> => {
    const options: any = {
      observe: 'response',
      responseType: 'arraybuffer' as 'arraybuffer'
    }
    const urlToUse = this._environmentService.apiInfo.apiBaseUrl + url;
    return this._http.post(urlToUse, data, options);
  }

  getPublic = (url: string, customOptions?: any): Observable<any> => {
    const urlToUse = this._environmentService.apiInfo.publicApiBaseUrl + url;
    return this._http.get(urlToUse, { headers: headers, ...customOptions });
  };
}
