import { Component, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Call, Device } from '@twilio/voice-sdk';
import { DateTime } from 'luxon';
import { NgxSpinnerService } from 'ngx-spinner';
import { firstValueFrom } from 'rxjs/internal/firstValueFrom';
import { EnvironmentService } from 'src/app/core/services/environment/environment.service';
import { Utils } from 'src/app/core/services/utils';
import { ActivityLog, Borrower, LeadStatus, LoanStatus, Message, SendSms } from 'src/app/models';
import { Message as InternalMessage } from 'src/app/models/message/message.model';
import { User } from 'src/app/models/user/user.model';
import { AgentNote } from 'src/app/modules/agents/models/agent-note.model';
import { AgentsService } from 'src/app/modules/agents/services/agents.service';
import { Agent } from 'src/app/modules/app-details/models/agent.model';
import { BorrowerNote } from 'src/app/modules/borrower/models/borrower-note.model';
import { BorrowersService } from 'src/app/modules/borrower/services/borrowers.service';
import { LeadEvent } from 'src/app/modules/leads/models/lead-event.model';
import { LeadsService } from 'src/app/modules/leads/services/leads.service';
import { LoanActivityService } from 'src/app/modules/loan-activity/services/loan-activity.service';
import { ChatService } from 'src/app/services/chat.service';
import { LoanService } from 'src/app/services/loan/loan.service';
import { MessageService } from 'src/app/services/message.service';
import { NotificationService } from 'src/app/services/notification.service';
import { VoiceService } from 'src/app/services/voice.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components/application-context-bound.component';
import { CallStatus } from '../../models/conference-participant.model';
import { DialListBasic } from '../../models/dial-list-basic.model';
import { DialListRecordBasic, RecordType } from '../../models/dial-list-record-basic.model';
import { DialerEventType } from '../../models/dialer-event.model';
import { PredefinedNote } from '../../models/dialer-page-data.model';
import { HistoryCallRequest } from '../../models/history-call-request.model';
import { ManualDialParams } from '../../models/manual-dial-params.model';
import { PhoneType } from '../../models/phone-type.model';
import { WarmTransferStatus } from '../../models/warm-transfer-status.model';
import { DialerEvent, DialerService } from '../../services/dialer.service';
import { PhoneNumberToCall } from '../call-control-panel-action-bar/call-control-panel-action-bar.component';

@Component({
  selector: 'twilio-call-control-panel-action-bar',
  templateUrl: './twilio-call-control-panel-action-bar.component.html',
  styleUrls: ['./twilio-call-control-panel-action-bar.component.scss']
})
export class TwilioCallControlPanelActionBarComponent extends ApplicationContextBoundComponent implements OnInit, OnDestroy {

  @ViewChild('quickSmsForm')
  quickSmsForm: NgForm;

  protected device: Device;
  protected activeCall: Call;
  protected agentCall: Call;

  protected currentUserPhoneNumber: string;
  protected currentUserFullName: string;
  protected currentUserAvatarUrl: string;

  protected participantPhoneNumber: string;
  protected participantFullName: string;

  protected callingStartTime: string;

  protected calleeTimer: string = "00:00";
  protected calleeTotalSeconds: number = 0;

  protected activeParticipantCount: number = 0;

  protected incomingCallPhone: string = null;

  protected latestNote: string = "";
  protected latestNoteTime: string = "";
  protected latestNoteAuthor: string;
  protected note: string;

  protected sendSmsClicked: boolean = false;
  protected numberToSendSmsTo: string = null;
  protected possibleNumbersToSendSmsTo: any = {
    borrowerName: ""
  };
  protected textMessage: string = "";
  protected sendingSms: boolean = false;

  protected dialLists: DialListBasic[] = [];
  protected selectedDialList: DialListBasic;
  protected predefinedNotes: PredefinedNote[];
  protected manualDial: boolean = false;
  protected callImmediate: boolean;
  protected fetchImmediate: boolean;
  protected isAdmin: boolean = false;
  protected users: User[] = [];
  protected leadStatuses: LeadStatus[] = [];
  protected loanStatuses: LoanStatus[] = [];

  protected warmTransferStatus: WarmTransferStatus = null;
  protected voiceHistoryId: number;
  protected currentUserId: string;
  protected activeCallOnHold: boolean = false;

  private _participantTimer: number;
  private _dialerServiceSubscriber;
  private _inboundAudio = new Audio('assets/sound/inbound-ringing.mp3');
  private _incomingCall: Call = null;

  private _typeOfEntityBeingDialed: RecordType | null = null;
  private _idOfEntityBeingDialed: number | null = null;
  private _selectedRecordForCall: DialListRecordBasic;
  private _phoneTypeToDial: PhoneType;
  private _dialUsingTelephonyPoolId: number = null;

  set selectedRecordForCall(selectedRecordForCall: DialListRecordBasic) {
    selectedRecordForCall.selectedPhoneType = this.getPhoneTypeFromRecord(selectedRecordForCall, null);
    this._phoneTypeToDial = selectedRecordForCall.selectedPhoneType;
    this._selectedRecordForCall = selectedRecordForCall;
    this.setIdAndTypeOfEntityBeingDialedFromRecord(selectedRecordForCall);
  }

  get selectedRecordForCall(): DialListRecordBasic {
    return this._selectedRecordForCall;
  }

  get haveRecordId(): boolean {
    return this._idOfEntityBeingDialed && this._idOfEntityBeingDialed > 0;
  }

  constructor(
    private readonly _dialerService: DialerService,
    private readonly _voiceService: VoiceService,
    private readonly _environment: EnvironmentService,
    private readonly _notifyService: NotificationService,
    private readonly _chatService: ChatService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _leadsService: LeadsService,
    private readonly _borrowersService: BorrowersService,
    private readonly _agentsService: AgentsService,
    private readonly _loanService: LoanService,
    private readonly _messageService: MessageService,
    private readonly _loanActivityService: LoanActivityService,
    private readonly injector: Injector
  ) {
    super(injector);

    this._dialerServiceSubscriber = this._dialerService.events.subscribe((e: DialerEvent) => {
      if (!e) {
        return;
      }

      switch (e.eventType) {
        case DialerEventType.callNumber:
          this.listener_onManualDial(e.data);
          break;
        case DialerEventType.callDialListRecord:
          this.listener_onCallRecord(e.data);
          break;
        case DialerEventType.DialUsingChanged:
          this.listener_dialUsingChanged(e.data);
          break;
        case DialerEventType.WarmTransferAccepted:
          this.listener_warmTransferAccepted(e.data);
          break;

        default:
          break;
      }
    })
  }

  ngOnInit(): void {
    this.initialize();
  }

  ngOnDestroy(): void {
    this._dialerServiceSubscriber?.unsubscribe();

    this.device?.disconnectAll();
    this.device?.destroy();
    this.device?.removeAllListeners();
  }

  protected getWordsFirstLetters = (text: string): string => {
    if (!text) {
      return;
    }
    let matches = text.match(/\b(\w)/g);
    if (!matches) {
      return;
    }

    return matches.join('');
  }

  // outgoing call
  protected startCall = async () => {
    try {
      const callerPhone = "+19499970927";

      const params = {
        To: this.participantPhoneNumber, //callee
        From: callerPhone // caller
      };

      this.activeCall = await this.device?.connect({ params });
      this.addCallEventListeners(this.activeCall);

      const req = new HistoryCallRequest();
      req.phoneNumber = this.participantPhoneNumber;
      req.fromPhoneNumber = callerPhone;

      if (this.selectedRecordForCall) {

        switch (this._typeOfEntityBeingDialed) {
          case RecordType.Application:
            req.applicationId = this._idOfEntityBeingDialed;
            break;
          case RecordType.Lead:
            req.leadId = this._idOfEntityBeingDialed;
            break;
          case RecordType.Agent:
            req.agentId = this._idOfEntityBeingDialed;
            break;
          case RecordType.Borrower:
            req.borrowerId = this._idOfEntityBeingDialed;
            break;
        }

        req.dialListRecordId = this.selectedRecordForCall.dialListRecordId;
        req.dialListId = this.selectedRecordForCall.dialListId;
      }

      req.telephonyPoolId = this._dialUsingTelephonyPoolId;

      this.voiceHistoryId = await firstValueFrom(this._voiceService.insertCallToHistory(req));
    }
    catch (e) {
      console.log(e);
    }
  }


  // outgoing call
  protected startAgentCall = async (phoneNumber: string, clientid: string) => {
    try {
      const callerPhone = "+19499970927";

      const params = {
        To: phoneNumber, //callee
        From: callerPhone // caller
      };

      this.agentCall = await this.device?.connect({ params });
      this.addCallEventListeners(this.agentCall);

      const req = new HistoryCallRequest();
      req.phoneNumber = this.participantPhoneNumber;
      req.fromPhoneNumber = callerPhone;

      if (this.selectedRecordForCall) {

        switch (this._typeOfEntityBeingDialed) {
          case RecordType.Application:
            req.applicationId = this._idOfEntityBeingDialed;
            break;
          case RecordType.Lead:
            req.leadId = this._idOfEntityBeingDialed;
            break;
          case RecordType.Agent:
            req.agentId = this._idOfEntityBeingDialed;
            break;
          case RecordType.Borrower:
            req.borrowerId = this._idOfEntityBeingDialed;
            break;
        }

        req.dialListRecordId = this.selectedRecordForCall.dialListRecordId;
        req.dialListId = this.selectedRecordForCall.dialListId;
      }

      req.telephonyPoolId = this._dialUsingTelephonyPoolId;

      this.voiceHistoryId = await firstValueFrom(this._voiceService.insertCallToHistory(req));
    }
    catch (e) {
      console.log(e);
    }
  }


  protected endCall = () => {
    this.activeCall?.disconnect();
  }

  protected muteDialer = () => {
    this.activeCall?.mute(true);
  }

  protected unMuteDialer = () => {
    this.activeCall?.mute(false);
  }

  protected holdCall = async () => {
    await firstValueFrom(this._voiceService.placeCallOnHold(this.activeCall.parameters.CallSid));
    this.activeCallOnHold = true;
  }

  protected unHoldCall = async () => {
    await firstValueFrom(this._voiceService.resumeOnHoldCall(this.activeCall.parameters.CallSid));
    this.activeCallOnHold = false;
  }

  protected closePanel = (data?: any) => {
    console.log('endCurrentCall entered', data);

    this.endCall();
    this.closeCallPanel();
  }

  protected onAcceptedIncomigCall = () => {
    this.pauseInboundSound();

    this.activeCall?.disconnect();

    this.activeCall = this._incomingCall;
    this.addCallEventListeners(this.activeCall);

    this.participantPhoneNumber = this.preparePhoneNumber(this.incomingCallPhone);
    this.participantFullName = null;

    this.incomingCallPhone = null;

    this.activeCall.accept();
  }

  protected onRejectedIncomigCall = () => {
    this._incomingCall = null;
    this.incomingCallPhone = null;
    this.pauseInboundSound();
  }

  protected onSendSmsClicked = () => {
    this.sendSmsClicked = true;

    if (this.quickSmsForm) {
      this.quickSmsForm.form.markAllAsTouched();
      if (this.quickSmsForm.form.valid) {
        this.sendSms();
      }
    }
  }

  protected addNote = () => {
    if (this._typeOfEntityBeingDialed == RecordType.Lead) {
      this.addLeadNote();
    } else if (this._typeOfEntityBeingDialed == RecordType.Borrower) {
      this.addBorrowerNote();
    } else if (this._typeOfEntityBeingDialed == RecordType.Agent) {
      this.addAgentNote();
    } else if (this._typeOfEntityBeingDialed == RecordType.Application) {
      this.addApplicationNote();
    }
  }

  protected publishingWarmXfer = false;
  protected publishWarmTransfer = async () => {

    this.publishingWarmXfer = true;

    if (!this.participantPhoneNumber) {
      this._notifyService.showError('Unable to find the participant for the warm transfer.', 'Error');
      this.publishingWarmXfer = false;
      return;
    }

    try {
      const leadRoute = await firstValueFrom(this._voiceService.publishWarmTransferNew(this.voiceHistoryId))
      if (this.warmTransferStatus !== WarmTransferStatus.Started) {
        this.warmTransferStatus = WarmTransferStatus.Published;
      }

      // hold call
      this.holdCall();

      this.publishingWarmXfer = false;
      this._notifyService.showSuccess('Warm transfer published successfully.', 'Success');

    }
    catch (err) {
      console.log(err);
      this._notifyService.showError(err?.error?.message || err?.error, 'Error');

      this.publishingWarmXfer = false;
    }

  }
  protected cancelWarmTransfer = async () => {

    if (!this.participantPhoneNumber) {
      this._notifyService.showError('Unable to find the participant for the warm transfer.', 'Error');
      this.publishingWarmXfer = false;
      return;
    }

    try {
      const leadRoute = await firstValueFrom(this._voiceService.cancelWarmTransferNew(this.voiceHistoryId));
      this.warmTransferStatus = undefined;

      // hold call
      this.unHoldCall();

      this._notifyService.showSuccess('Warm transfer canceled successfully.', 'Success');
    }
    catch (err) {
      console.log(err);
      this._notifyService.showError(err?.error?.message || err?.error, 'Error');
    }
  }

  private initialize = () => {
    this.applicationContextService.context.subscribe(async context => {

      this.isAdmin = context.userPermissions.admin;
      this.currentUserId = context.userPermissions.userId;
      this.users = context.globalConfig.users;
      this.leadStatuses = context.globalConfig.leadStatus;
      this.loanStatuses = context.globalConfig.loanStatus;

      this.currentUserPhoneNumber = Utils.getActiveTelephonyService(context.currentlyLoggedInUserProfile.telephonyServices)?.fromPhoneNumber;
      this.currentUserFullName = Utils.getPersonsFullName(context.currentlyLoggedInUserProfile.userProfile);
      this.currentUserAvatarUrl = context.currentlyLoggedInUserProfile.userProfile.avatarId
        ? `${this._environment.apiInfo.apiBaseUrl}avatars/${context.currentlyLoggedInUserProfile.userProfile.avatarId}`
        : context.currentlyLoggedInUser.avatar;

      await this.setupTwilioDevice();
    })
  }

  private setupTwilioDevice = async () => {
    // Twilio.logLevel = 'debug';
    navigator.mediaDevices.getUserMedia({ audio: true })
      .then(stream => console.log('Microphone access granted'))
      .catch(err => console.error('Microphone access denied:', err));

    const token = await this.getTwilioToken(this.applicationContext.currentlyLoggedInUser.userCompanyGuid);
    console.log('Twilio Token:', token);

    this.device = new Device(token, { logLevel: "DEBUG", allowIncomingWhileBusy: true, closeProtection: true, });
    this.device.register();

    // const preflightTest = Device.runPreflight(this.device.token);
    // preflightTest.on('completed', (report) => {
    //   console.log("Preflight completed", report);
    // });

    // preflightTest.on('failed', (error) => {
    //   console.log("Preflight error", error);
    // });

    this.device.on('ready', () => console.log('Device is ready'));
    this.device.on('error', (error) => console.error('Device error:', error));
    this.device.on('offline', () => console.log('Device is offline'));
    this.device.on('registered', () => console.log('Device registered'));
    this.device.on('unregistered', () => console.log('Device unregistered'));
    this.device.on('registering', () => console.log('Device registering'));
    this.device.on('event', (event) => console.log(`Device event: ${event}`));

    this.device.on(Device.EventName.Incoming, (call) => {
      // Show incoming call notification in your UI
      console.log("Incoming call from:", call.parameters.From);
      this.onIncomingCallReceived(call);
    });

    this.device.on(Device.EventName.TokenWillExpire, async () => {
      const newToken = await this.getTwilioToken(this.applicationContext.currentlyLoggedInUser.userCompanyGuid);
      this.device.updateToken(newToken);
    });
  }

  private getTwilioToken = async (deviceId?: string): Promise<string> => {
    try {
      const token = await firstValueFrom(this._voiceService.getAccessToken(deviceId));

      // let jwtPayload = Utils.parseJwt(token);
      // console.log(Utils.getTokenExpireDate(jwtPayload.exp)) // only for a hour

      return token;
    }
    catch (e) {
      console.log(e);
    }
  }

  private listener_onManualDial = (data) => {

    this.applicationContextService.toggleCallControlPanel(true);

    this.manualDial = true;
    const manualdialParams = data.manualDialParams as ManualDialParams;

    this.participantFullName = Utils.getPersonsFullName(manualdialParams);
    this.participantPhoneNumber = this.preparePhoneNumber(manualdialParams.phoneNumber);
    this.numberToSendSmsTo = this.participantPhoneNumber;

    this.possibleNumbersToSendSmsTo.borrowerMobileNumber = this.participantPhoneNumber;

    this._idOfEntityBeingDialed = manualdialParams.recordId || null;
    this._typeOfEntityBeingDialed = manualdialParams.recordType || null;

    this.startCall();
  }

  private listener_onCallRecord = (data) => {

    this.applicationContextService.toggleCallControlPanel(true);

    this.manualDial = false;

    this.callImmediate = data.callImmediate;
    this.fetchImmediate = data.fetchImmediate;

    if (data.selectedDialList) {
      this.selectedDialList = data.selectedDialList;
    }

    if (data.predefinedNotes) {
      this.predefinedNotes = data.predefinedNotes;
    }

    if (data.dialLists) {
      this.dialLists = data.dialLists;
    }

    if (data.selectedRecord && !this.fetchImmediate) {
      this.selectedRecordForCall = data.selectedRecord;
      this.selectedRecordForCall.selectedPhoneType = data.phoneTypeToDial;
    }

    if (data.phoneTypeToDial) {
      this._phoneTypeToDial = data.phoneTypeToDial;
    }

    this.initializeFieldsForSelectedRecord();
  }

  private preparePhoneNumber = (phone: string): string => {
    return phone.startsWith("+1") ? phone : ("+1" + phone);
  }

  private closeCallPanel = () => {
    this.applicationContextService.toggleCallControlPanel(false);
    this._dialerService.publish({
      eventType: DialerEventType.closeCallPanel,
      data: {}
    });
  }

  private onTimeout = () => {
    this._participantTimer = window.setTimeout(this.onTimeout, 1000);

    if (this.activeCall?.status() != 'closed') {
      this.calleeTotalSeconds++;
      this.calleeTimer = this.padNumberTwoDigit(parseInt((this.calleeTotalSeconds / 60).toString())).toString() + ':' +
        this.padNumberTwoDigit(this.calleeTotalSeconds % 60);
    }
  }

  private padNumberTwoDigit = (val: number): string => {
    let valString = val + "";
    if (valString.length < 2) {
      return "0" + valString;
    } else {
      return valString;
    }
  }

  private formatDate = (date: Date): string => {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? 'PM' : 'AM';

    let lhours = hours % 12;
    lhours = lhours ? lhours : 12; // the hour '0' should be '12'
    let lminutes = minutes < 10 ? '0' + minutes : minutes;

    return lhours + ':' + lminutes + ' ' + ampm;
  }

  private participantStopTimer = () => {
    if (this._participantTimer) {
      clearTimeout(this._participantTimer);
    }

    this.calleeTotalSeconds = 0;
    this.calleeTimer = this.padNumberTwoDigit(parseInt((this.calleeTotalSeconds / 60).toString())).toString() + ':' +
      this.padNumberTwoDigit(this.calleeTotalSeconds % 60);
  }

  private particiantStartTimer = (): void => {
    this.participantStopTimer();
    this._participantTimer = window.setTimeout(this.onTimeout, 1000);
  }

  private onIncomingCallReceived = (call: Call) => {
    this.applicationContextService.toggleCallControlPanel(true);

    try {
      this._inboundAudio.play();
    }
    catch (err) { }

    this._incomingCall = call;
    this.incomingCallPhone = call.parameters.From;
  }

  private pauseInboundSound = () => {
    try {
      this._inboundAudio.pause();
    }
    catch (err) { }
  }

  private addCallEventListeners = (call: Call) => {
    call.on('accept', () => {
      // the'accept' event is emitted when the media session for the call has finished being set up.

      console.log('Call accepted');

      this.activeParticipantCount = 2;
      this.callingStartTime = this.formatDate(new Date());
      this.particiantStartTimer();

      if (this.selectedRecordForCall) {
        this.selectedRecordForCall.callStatus = CallStatus.InProgress;
        this.publishCallStatusChange(this.selectedRecordForCall.dialListRecordId, CallStatus.InProgress);
      }

    });

    call.on('disconnect', () => {
      console.log('Call ended')
      this.activeParticipantCount = 0;
      this.participantStopTimer();

      if (this.selectedRecordForCall) {
        this.selectedRecordForCall.callStatus = CallStatus.Disconnected;
        this.publishCallStatusChange(this.selectedRecordForCall.dialListRecordId, CallStatus.Disconnected);
      }
    });

    call.on('cancel', () => {
      // Emitted when the Call instance has been canceled by the remote end and the call.status() has transitioned to 'closed'
      console.log('Call canceled')

      this.activeParticipantCount = 0;
      this.participantStopTimer();

      if (this.selectedRecordForCall) {
        this.selectedRecordForCall.callStatus = CallStatus.Canceled;
        this.publishCallStatusChange(this.selectedRecordForCall.dialListRecordId, CallStatus.Canceled);
      }
    });

    call.on('audio ', () => {
      console.log('Audio event')
    })
  }

  private sendSms = () => {

    let smsPayload = new SendSms();

    const entityIdField = this.getAppropriateEntityIdFieldForSmsMessage()
    smsPayload[entityIdField] = this._idOfEntityBeingDialed;
    smsPayload.to = this.numberToSendSmsTo;
    smsPayload.body = this.textMessage;
    smsPayload.from = this.currentUserPhoneNumber ? Utils.cleanFormatedPhoneNumber(this.currentUserPhoneNumber) : "";

    this._spinner.show();

    let observable;
    if (this._typeOfEntityBeingDialed != RecordType.Lead && this._typeOfEntityBeingDialed != RecordType.Application &&
      this._typeOfEntityBeingDialed != RecordType.Borrower && this._typeOfEntityBeingDialed != RecordType.Agent) {
      smsPayload.from = this._typeOfEntityBeingDialed;
      observable = this._chatService.sendSms(smsPayload);
    } else {
      observable = this._chatService.queueSms(smsPayload);
    }

    this.sendingSms = true;
    observable.subscribe(() => {
      this.onAfterSmsSuccessfullySent();
      this._notifyService.showSuccess("SMS has been successfully sent.", "Success!");
    }, (err) => {
      this.sendingSms = false;
      this.sendSmsClicked = false;
      this._notifyService.showError(err.error.message || err.message, "Error!");
    }).add(() => { this._spinner.hide(); });
  }

  private getAppropriateEntityIdFieldForSmsMessage = (): string => {
    if (this._typeOfEntityBeingDialed === RecordType.Lead) {
      return "leadId";
    } else if (this._typeOfEntityBeingDialed === RecordType.Borrower) {
      return "borrowerId";
    } else if (this._typeOfEntityBeingDialed === RecordType.Agent) {
      return "agentId";
    } else if (this._typeOfEntityBeingDialed === RecordType.Application) {
      return "applicationId";
    }
  }

  private onAfterSmsSuccessfullySent = (message: Message = null) => {
    if (this._typeOfEntityBeingDialed === RecordType.Lead && message) {
      this.createSmsEvent(this._idOfEntityBeingDialed);
      this._dialerService.publish({
        eventType: DialerEventType.LeadQuickSMSSended,
        data: message
      });
    }
    this._notifyService.showSuccess("Sms successfully sent!", "Success");
    this.textMessage = "";
    this.sendingSms = false;
    this.sendSmsClicked = false;
    this.quickSmsForm.form.markAsPristine();
    this.quickSmsForm.form.markAsUntouched();
    this._dialerService.publish({ eventType: DialerEventType.quickSmsSent, data: {} });
  }

  private createSmsEvent = (leadId: number) => {
    if (leadId) {
      let params = {
        leadId: leadId,
        type: "sms",
        note: 'Sent SMS To ' + this.numberToSendSmsTo + '. Message: ' + this.textMessage,
        leadStatusId: this._selectedRecordForCall.statusId
      } as LeadEvent;

      this._leadsService.addLeadEvent(params)
        .subscribe();
    }
  }

  private setIdAndTypeOfEntityBeingDialedFromRecord = (record: DialListRecordBasic): void => {
    if (record.leadId) {
      this._idOfEntityBeingDialed = record.leadId;
      this._typeOfEntityBeingDialed = RecordType.Lead;
    } else if (record.borrowerId) {
      this._idOfEntityBeingDialed = record.borrowerId;
      this._typeOfEntityBeingDialed = RecordType.Borrower;
    } else if (record.agentId) {
      this._idOfEntityBeingDialed = record.agentId;
      this._typeOfEntityBeingDialed = RecordType.Agent;
    } else if (record.applicationId) {
      this._idOfEntityBeingDialed = record.applicationId;
      this._typeOfEntityBeingDialed = RecordType.Application;
    }
  }

  private getPhoneTypeFromRecord = (record: DialListRecordBasic, phoneType: string): PhoneType => {

    if (!phoneType && record) {
      if (record.mobilePhone && record.mobilePhone.trim().length > 0)
        return PhoneType.mobile;
      if (record.homePhone && record.homePhone.trim().length > 0)
        return PhoneType.home;
      if (record.workPhone && record.workPhone.trim().length > 0)
        return PhoneType.work;
    }

    if (phoneType === 'mobile' && record.mobilePhone && record.mobilePhone.trim().length > 0)
      return PhoneType.mobile;
    if (phoneType === 'home' && record.homePhone && record.homePhone.trim().length > 0)
      return PhoneType.home;
    if (phoneType === 'work' && record.workPhone && record.workPhone.trim().length > 0)
      return PhoneType.work;
    if (record && record.mobilePhone && record.mobilePhone.trim().length > 0) {
      return PhoneType.mobile;
    }
    return null;
  }

  private formatPhoneNumber = (phoneNum: string): string => {
    if (!phoneNum) {
      return null;
    }
    return phoneNum.replace("+1", "").replace("(", "").replace(")", "").replace("-", "").replace(" ", "");
  }

  private doPopulatePhoneNumberOptionsForSms = (record: DialListRecordBasic, recordDetails: any) => {
    this.possibleNumbersToSendSmsTo.borrowerName = (recordDetails.firstName || recordDetails.lastName) ? `${recordDetails.firstName || ''} ${recordDetails.lastName || ''}` : null;
    if (recordDetails.mobilePhone) {
      this.possibleNumbersToSendSmsTo["borrowerMobileNumber"] = this.formatPhoneNumber(recordDetails.mobilePhone);
    }
    if (recordDetails.phone) {
      this.possibleNumbersToSendSmsTo["borrowerHomeNumber"] = this.formatPhoneNumber(recordDetails.phone);
    } else if (recordDetails.homePhone) {
      this.possibleNumbersToSendSmsTo["borrowerHomeNumber"] = this.formatPhoneNumber(recordDetails.homePhone);
    }
    if (recordDetails.coFirstName || recordDetails.coLastName) {
      this.possibleNumbersToSendSmsTo["coBorrowerName"] = (recordDetails.coFirstName || recordDetails.coLastName) ? `${recordDetails.coFirstName || ''} ${recordDetails.coLastName || ''}` : null;
      if (recordDetails.coMobilePhone) {
        this.possibleNumbersToSendSmsTo["coBorrowerMobileNumber"] = this.formatPhoneNumber(recordDetails.coMobilePhone);
      }
      if (recordDetails.coPhone) {
        this.possibleNumbersToSendSmsTo["coBorrowerHomeNumber"] = this.formatPhoneNumber(recordDetails.coPhone);
      }
    }
    if (record.phoneNumberToCall) {
      this.numberToSendSmsTo = record.phoneNumberToCall;
    } else {
      this.numberToSendSmsTo = this.possibleNumbersToSendSmsTo["borrowerMobileNumber"];
    }
  }

  private initializeFieldsForSelectedRecord = () => {
    if (this.selectedRecordForCall) {
      if (this.selectedRecordForCall.recordType) {
        this._typeOfEntityBeingDialed = this.selectedRecordForCall.recordType;
        this.populatePhoneNumberOptionsForSms(this.selectedRecordForCall);

        if (this.selectedRecordForCall.recordType == RecordType.Lead && this.selectedRecordForCall.leadId) {
          this.loadLeadLatestNote(this.selectedRecordForCall.leadId);
        } else if (this.selectedRecordForCall.recordType == RecordType.Borrower && this.selectedRecordForCall.borrowerId) {
          this.loadBorrowerLatestNote(this.selectedRecordForCall.borrowerId);
        }
        else if (this.selectedRecordForCall.recordType == RecordType.Agent && this.selectedRecordForCall.agentId) {
          this.loadAgentLatestNote(this.selectedRecordForCall.agentId);
        } else if (this.selectedRecordForCall.recordType == RecordType.Application && this.selectedRecordForCall.applicationId) {
          this.loadApplicationLatestNote(this.selectedRecordForCall.applicationId);
        }
      }

      this.participantFullName = Utils.getPersonsFullName(this.selectedRecordForCall);
      this.participantPhoneNumber = this.preparePhoneNumber(this.getPhoneNumberToCall(this.selectedRecordForCall).phoneNumber);
      this.numberToSendSmsTo = this.participantPhoneNumber;

      this.possibleNumbersToSendSmsTo.borrowerMobileNumber = this.participantPhoneNumber;

      this.startCall()
    }
    else {
      this.resetNoteFields();
    }
  }

  private populatePhoneNumberOptionsForSms = (record: DialListRecordBasic) => {
    if (this.manualDial) {
      this.numberToSendSmsTo = this.getPhoneNumberToCall(this.selectedRecordForCall, PhoneType.mobile).phoneNumber;
      return;
    }
    this._dialerService.getRecordDetails(record, this.isAdmin)
      .subscribe(response => {
        let recordDetails = response as any;
        if (record.recordType == RecordType.Borrower) {
          recordDetails = response["borrower"] as Borrower;
        }
        else if (record.recordType == RecordType.Agent) {
          recordDetails = response["agent"] as Agent;
        } else if (record.recordType == RecordType.Application) {
          this._loanService.getBorrowers(record.applicationId).subscribe(borrowers => {
            if (borrowers.length) {
              let borrower = borrowers.find(b => b.isPrimary);
              if (!borrower) {
                borrower = borrowers[0];
              }
              this.doPopulatePhoneNumberOptionsForSms(record, borrower)
              return;
            }
          }, error => {
            this._notifyService.showError(error.message || error, 'Error');
          })
        }
        this.doPopulatePhoneNumberOptionsForSms(record, recordDetails);
      }, error => {
        this._notifyService.showError(error.message || error, 'Error');
        if (record.mobilePhone) {
          this.numberToSendSmsTo = record.mobilePhone.trim();
        }
      });
  }

  private getPhoneNumberToCall = (dialListRecord: DialListRecordBasic, phoneType?: PhoneType): PhoneNumberToCall => {

    let phoneNumberToCall = new PhoneNumberToCall();

    if (dialListRecord.phoneNumberToCall) {
      if (phoneType && phoneType === dialListRecord.selectedPhoneType) {
        return { phoneNumber: dialListRecord.phoneNumberToCall, phoneType: dialListRecord.selectedPhoneType };
      }
    }

    if (!phoneType) {
      if (dialListRecord.mobilePhone && dialListRecord.mobilePhone.trim().length > 0) {
        phoneNumberToCall.phoneType = PhoneType.mobile;
        phoneNumberToCall.phoneNumber = this.formatPhoneNumber(dialListRecord.mobilePhone.trim());
        return phoneNumberToCall;
      }
      if (dialListRecord.homePhone && dialListRecord.homePhone.trim().length > 0) {
        phoneNumberToCall.phoneType = PhoneType.home;
        phoneNumberToCall.phoneNumber = this.formatPhoneNumber(dialListRecord.homePhone.trim());
        return phoneNumberToCall;
      }
      if (dialListRecord.workPhone && dialListRecord.workPhone.trim().length > 0) {
        phoneNumberToCall.phoneType = PhoneType.work;
        phoneNumberToCall.phoneNumber = this.formatPhoneNumber(dialListRecord.workPhone.trim());
        return phoneNumberToCall;
      }
    } else {
      if (phoneType === PhoneType.mobile && dialListRecord.mobilePhone && dialListRecord.mobilePhone.trim().length > 0) {
        phoneNumberToCall.phoneType = PhoneType.mobile;
        phoneNumberToCall.phoneNumber = this.formatPhoneNumber(dialListRecord.mobilePhone.trim());
        return phoneNumberToCall;
      }
      if (phoneType === PhoneType.home && dialListRecord.homePhone && dialListRecord.homePhone.trim().length > 0) {
        phoneNumberToCall.phoneType = PhoneType.home;
        phoneNumberToCall.phoneNumber = this.formatPhoneNumber(dialListRecord.homePhone.trim());
        return phoneNumberToCall;
      }
      if (phoneType === PhoneType.work && dialListRecord.workPhone && dialListRecord.workPhone.trim().length > 0) {
        phoneNumberToCall.phoneType = PhoneType.work;
        phoneNumberToCall.phoneNumber = this.formatPhoneNumber(dialListRecord.workPhone.trim());
        return phoneNumberToCall;
      }
    }

    return phoneNumberToCall;
  }

  private loadLeadLatestNote = (leadId: number): void => {
    this.resetNoteFields();
    this._leadsService.getLeadEvents(leadId)
      .subscribe((events: LeadEvent[]) => {

        let result = [];
        let notes = events.filter(r => r.type === "note");
        notes.forEach(note => {
          let time = new Date(note.dateUpdated || note.dateInserted);
          result.push({ note: note.note, time: time, author: note.insertedBy });
        });
        result = result.sort((n1, n2) => {
          return n2.time - n1.time;
        });

        if (result && result.length > 0) {
          let author = this.users.find(u => u.userCompanyGuid === result[0].author);
          if (author) {
            this.latestNoteAuthor = author.firstName + " " + author.lastName;
          }
          this.latestNoteTime = DateTime.fromJSDate(new Date(result[0].time)).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS);
          this.latestNote = result[0].note;
        } else {
          this.resetNoteFields();
        }
      });

  }

  private loadBorrowerLatestNote = (borrowerId: number) => {
    this.resetNoteFields();
    this._borrowersService.getBorrowerNotes(borrowerId)
      .subscribe((notes: BorrowerNote[]) => {

        let result = [];
        notes.forEach(note => {
          let time = new Date(note.dateUpdated || note.dateInserted);
          result.push({ note: note, time: time, author: note.insertedBy });
        });
        result = result.sort((n1, n2) => {
          return n2.time - n1.time;
        });

        if (result && result.length > 0) {
          let author = this.users.find(u => u.userCompanyGuid === result[0].author);
          if (author) {
            this.latestNoteAuthor = author.firstName + " " + author.lastName;
          }
          this.latestNoteTime = DateTime.fromJSDate(new Date(result[0].time)).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS);
          this.latestNote = result[0].note.content;
        } else {
          this.resetNoteFields();
        }
      });
  }

  private loadAgentLatestNote = (agentId: number) => {
    this.resetNoteFields();
    this._agentsService.getAgentNotes(agentId)
      .subscribe((notes: AgentNote[]) => {

        let result = [];
        notes.forEach(note => {
          let time = new Date(note.dateUpdated || note.dateInserted);
          result.push({ note: note, time: time, author: note.insertedBy });
        });
        result = result.sort((n1, n2) => {
          return n2.time - n1.time;
        });

        if (result && result.length > 0) {
          let author = this.users.find(u => u.userCompanyGuid === result[0].author);
          if (author) {
            this.latestNoteAuthor = author.firstName + " " + author.lastName;
          }
          this.latestNoteTime = DateTime.fromJSDate(new Date(result[0].time)).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS);
          this.latestNote = result[0].note.content;
        } else {
          this.resetNoteFields();
        }
      });
  }

  private loadApplicationLatestNote = (applicationId: number) => {
    this.resetNoteFields();
    this._loanActivityService.getActivityLogs(applicationId, false)
      .subscribe((notes: ActivityLog[]) => {
        notes = notes.filter(n => n.activityType === 'InternalMessage');
        let result = [];
        notes.forEach(note => {
          let time = new Date(note.dateCreated);
          result.push({ note: note, time: time, author: note.userId });
        });
        result = result.sort((n1, n2) => {
          return n2.time - n1.time;
        });

        if (result && result.length > 0) {
          let author = this.users.find(u => u.userCompanyGuid === result[0].author);
          if (author) {
            this.latestNoteAuthor = author.firstName + " " + author.lastName;
          }
          this.latestNoteTime = DateTime.fromJSDate(new Date(result[0].time)).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS);
          this.latestNote = result[0].note.displayText;
        } else {
          this.resetNoteFields();
        }
      });
  }

  private resetNoteFields = (): void => {
    this.latestNote = "";
    this.latestNoteTime = "";
    this.latestNoteAuthor = "";
    this.note = "";
  }

  private publishCallStatusChange = (dialListRecordId: number, callStatus: CallStatus) => {
    const phoneTypeToDial = this.selectedRecordForCall?.selectedPhoneType || this._phoneTypeToDial;
    this._dialerService.publish({
      eventType: DialerEventType.changeRecordCallStatus,
      data: {
        dialListRecordId: dialListRecordId,
        callStatus: callStatus,
        phoneType: phoneTypeToDial
      }
    });
  }

  private addApplicationNote = () => {
    this._spinner.show();

    const internalMessage = new InternalMessage();
    internalMessage.content = this.note;
    internalMessage.applicationId = this._idOfEntityBeingDialed;
    this._messageService.postInternalMessage(internalMessage).subscribe((response) => {
      if (response !== null && response !== undefined) {
        this.onAfterNoteSuccessfullyAdded(new Date());
      }
      this._spinner.hide();
    }, (err) => {
      this._notifyService.showError(err.message || err, 'Error');
      this._spinner.hide();
    });
  }

  private addAgentNote = () => {
    this._spinner.show();

    let note = new AgentNote();
    note.content = this.note;
    note.agentId = this._idOfEntityBeingDialed;

    this._agentsService.postAgentNote(this._idOfEntityBeingDialed, note)
      .subscribe(result => { // notice: result does not return any data
        this.onAfterNoteSuccessfullyAdded(new Date());
      }, (err) => {
        this._notifyService.showError(err.message || err, 'Error');
        this._spinner.hide();
      });
  }

  private addBorrowerNote = () => {
    this._spinner.show();

    let note = new BorrowerNote();
    note.content = this.note;
    note.borrowerId = this._idOfEntityBeingDialed;

    this._borrowersService.postBorrowerNote(this._idOfEntityBeingDialed, note)
      .subscribe(result => { // notice: result does not return any data

        this.onAfterNoteSuccessfullyAdded(new Date());

      }, (err) => {
        this._notifyService.showError(err.message || err, 'Error');
        this._spinner.hide();
      });
  }

  private addLeadNote = () => {
    let params = {
      leadId: this._idOfEntityBeingDialed,
      type: 'note',
      note: this.note,
      leadStatusId: this.selectedRecordForCall?.recordTypeStatusId
    } as LeadEvent;

    this._spinner.show();

    this._leadsService.addLeadEvent(params)
      .subscribe(result => {

        this._dialerService.publish({
          eventType: DialerEventType.LeadQuickNoteAdded,
          data: params
        });

        this.onAfterNoteSuccessfullyAdded(new Date(result.dateInserted));

      }, (err) => {
        this._notifyService.showError(err.message || err, 'Error');
        this._spinner.hide();
      });
  }

  private onAfterNoteSuccessfullyAdded = (dateAdded: Date) => {
    this._spinner.hide();
    this._notifyService.showSuccess("Successfully saved message.", "Success!");
    this.latestNote = this.note;
    this.latestNoteTime = DateTime.fromJSDate(dateAdded).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS);
    this.note = "";
  }

  private listener_dialUsingChanged = (data) => {
    this._dialUsingTelephonyPoolId = data;
  }

  private listener_warmTransferAccepted = (data) => { //for supervisor
    console.log('warmTransferAccepted entered', data);
    this.startAgentCall(data.newAgentPhoneNumber, data.newAgentUserId);
  }

}
