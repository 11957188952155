import { Component, Input, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'spinner',
  templateUrl: 'spinner.component.html',
})
export class SpinnerComponent implements OnInit {

  @Input()
  name: string;

  @Input()
  message: string = 'Loading...';


  constructor(private readonly _spinnerService: NgxSpinnerService) { }

  public show = (message: string = null) => {
    if (!!message)
      this.message = message;
    this._spinnerService.show(this.name);
  }

  public updateMessage = (message: string) => {
    this.message = message;
  }

  public hide = () => {
    this._spinnerService.hide(this.name);
  }

  ngOnInit() { }
}
