<div cdkDropListGroup>
	<div class="container text-start" *ngFor="let application of applications; let appIndex = index;">
		<h5 class="mb-1">Application {{appIndex + 1}}</h5>
		<div cdkDropList [cdkDropListData]="application" id="application-drop-list-{{appIndex}}" class="list"
			(cdkDropListDropped)="drop($event)" [ngStyle]="{ 'border-style' : application.length == 0 ? 'dashed' : 'solid' }">
			<div class="box" *ngFor="let borrower of application; let borrowerIndex = index;" [cdkDragData]="borrower"
				cdkDrag>
				<div>
					<i class="fa fa-user fa-sm"></i>
					{{borrower.firstName}} {{borrower.lastName}}

					<div class="float-end ps-2 ms-2 text-center borrowerType">
						<span>Borrower Type</span>
						<select [(ngModel)]='borrower.mortgagePartyType' #partyType="ngModel" [disabled]="isCompanySmartfi && isTpoUser"
							[name]="'mortgagePartyType' + borrower.borrowerId" [required]="isCompanyDeepHaven"
							[ngClass]="{'is-invalid' : partyType && partyType.touched && partyType.invalid}" class="form-select">
							<option [ngValue]="null">-- Select One --</option>
							<option *ngFor="let item of mortgagePartyTypeOptions" [ngValue]="item.value">
								{{item.name}}
							</option>
						</select>
						<div class="invalid-feedback">Borrower Type is required.</div>
					</div>

					<button type="button" style="margin-top: -5px;"
						class="btn btn-outline-info btn-icon-circle btn-icon-circle-sm float-end"
						[ngClass]="{'btn-info': detailsVisible[borrower.borrowerId]}"
						(click)="detailsVisible[borrower.borrowerId] = !detailsVisible[borrower.borrowerId]">
						<i class="fa"
							[ngClass]="{'fa-info': !detailsVisible[borrower.borrowerId], 'fa-chevron-up color-white': detailsVisible[borrower.borrowerId]}"></i>
					</button>
				</div>

				<div *ngIf="detailsVisible[borrower.borrowerId]" style="margin-top: 17px;">

					<div class="row mt-2">
						<div class="col-sm-4 pe-0 text-end"><strong>Last 4 SSN: </strong></div>
						<div class="col-sm-8">{{maskSsnNumber(borrower.socialSecNum)}}</div>
					</div>

					<div class="row">
						<div class="col-sm-4 pe-0 text-end"><strong>Phone: </strong></div>
						<div class="col-sm-8">{{(borrower.mobilePhone | phone) || '—'}}</div>
					</div>

					<div class="row">
						<div class="col-sm-4 pe-0 text-end"><strong>DOB: </strong></div>
						<div class="col-sm-8">{{borrower.dateOfBirth | date: 'longDate' || '—'}}</div>
					</div>

					<div class="row">
						<div class="col-sm-4 pe-0 text-end"><strong>Email: </strong></div>
						<div class="col-sm-8">{{borrower.primaryEmail || '—'}}</div>
					</div>

					<div class="row">
						<div class="col-sm-4 pe-0 text-end"><strong>Mailing Address: </strong></div>
						<div class="col-sm-8">
							<span *ngIf="borrower.residencyAddresses[0]">
								{{borrower.residencyAddresses[0].address?.address1 || '—'}}
							</span>
							<span *ngIf="borrower.residencyAddresses[0]">
								{{borrower.residencyAddresses[0].address?.city || '—'}},
							</span>
							<span *ngIf="borrower.residencyAddresses[0]">
								{{formatMailingState(borrower.residencyAddresses[0].address?.state)}}
								{{borrower.residencyAddresses[0].address?.zipCode}}
							</span>
						</div>
					</div>

				</div>
			</div>
		</div>
	</div>
</div>