<ng-template #pageActionBarLeft>
  <bread-crum></bread-crum>
</ng-template>

<ng-template #pageActionBarRight>
  <button type="button" class="btn btn-primary" [disabled]="superAdmin" (click)="addNewStackingOrder()">
    <i class="fas fa-plus"></i> Add New
  </button>
</ng-template>

<ng-template #mainContent>
  <h5>{{ superAdmin ? companyName + ' - ' : '' }} </h5>

  <p-table
    #dt1
    [columns]="columns"
    [value]="stackingOrders"
    [paginator]="true"
    [rows]="25"
    responsiveLayout="scroll"
    name="stacking-order-list-table-v2"
    [rowsPerPageOptions]="[10, 25, 50]"
    [globalFilterFields]="globalFilterFields"
    [autoLayout]="true"
    [showCurrentPageReport]="true"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
    styleClass="p-datatable-gridlines"
    scrollable="true"
    [scrollHeight]="scrollHeight"
  >
    <ng-template pTemplate="caption">
      <div class="d-flex justify-content-between">
        <div></div>
        <div>
          <span class="p-input-icon-right ms-2 h-100">
            <i class="pi pi-search"></i>
            <input
              pInputText
              type="text"
              class="form-control h-100"
              (input)="dt1.filterGlobal($event.target.value, 'contains')"
              (ngModelChange)="dt1.first=0"
              placeholder="Search keyword"
            />
          </span>
        </div>
      </div>
    </ng-template>

    <ng-template pTemplate="header">
      <tr>
        <th style="width: 7rem; text-align: center">
          <span>Action</span>
        </th>
        <th *ngFor="let col of getSortedColumns()" [pSortableColumn]="col.field">
          <span>{{ col.header }}</span>
          <p-sortIcon [field]="col.field" class="pull-right"></p-sortIcon>
        </th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-rowData>
      <tr>
        <td>
          <span class="d-flex align-items-center justify-content-center w-100">
            <span>
              <i
                class="fa fa-pencil-alt cursor text-primary"
                (click)="onEditStackingOrderClicked(rowData)"
                title="Edit"
              ></i>
            </span>

            <span class="mx-2"> | </span>

            <span>
              <i
                *ngIf="!isDeleteClicked[rowData.stakingOrderId]"
                class="fa fa-trash-alt cursor text-danger"
                (click)="isDeleteClicked[rowData.stakingOrderId] = true"
                title="Delete"
              ></i>

              <i
                *ngIf="isDeleteClicked[rowData.stakingOrderId]"
                class="fa fa-times cursor text-danger me-2"
                (click)="isDeleteClicked[rowData.stakingOrderId] = false"
                title="Cancel"
              ></i>

              <i
                *ngIf="isDeleteClicked[rowData.stakingOrderId]"
                class="fa fa-check-circle cursor text-danger"
                (click)="onDeleteStackingOrderClicked(rowData.stakingOrderId)"
                title="Delete"
              ></i>
            </span>
          </span>
        </td>
        <td *ngFor="let col of getSortedColumns()" [ngSwitch]="col.field">
          <span *ngSwitchCase="'docType'">
            <a class="text-primary" (click)="onAssociationClicked(rowData)">{{
              rowData.docType
            }}</a>
          </span>

          <span *ngSwitchCase="'showOnTPOPortal'">
            <input type="checkbox" [checked]="rowData.showOnTPOPortal" readonly />
          </span>

          <span *ngSwitchCase="'associate'">
            {{ rowData.docTypes }}
          </span>

          <span *ngSwitchDefault>
            {{ rowData[col.field] }}
          </span>
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="11" class="text-center">
          {{ !isLoading ? 'There are no stacking orders.' : 'Loading...' }}
        </td>
      </tr>
    </ng-template>
  </p-table>
</ng-template>

<admin-page-template
  [useActionBarSections]="true"
  [pageMainContentTemplate]="mainContent"
  [pageActionBarLeftContentTemplate]="pageActionBarLeft"
  [pageActionBarRightContentTemplate]="pageActionBarRight">
</admin-page-template>
