import { Component, Injector, Input, OnInit } from '@angular/core';
import {
  ThirdPartyCredential,
  ThirdPartyCredentialArray,
  ThirdPartyCredentialType,
} from '../../../../../models';
import { Configuration } from '../../../../../models/configuration.model';
import { DocumentType } from 'src/app/models';
import { NotificationService } from '../../../../../services/notification.service';
import { Constants } from '../../../../../services/constants';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CreditModalComponent } from './credit-modal/credit-modal.component';
import { SystemLevelService } from 'src/app/services/system-level.service';
import { ActivatedRoute } from '@angular/router';
import { ApplicationContextBoundComponent } from '../../../../../shared/components';
import { NgxSpinnerService } from 'ngx-spinner';
import { ScopeType } from '../integrations.component';
import { CreditCredentialsConfigurationDialogComponent } from 'src/app/modules/credit-credentials-configuration/credit-credentials-configuration-dialog/credit-credentials-configuration-dialog.component';

@Component({
  selector: 'credit-credentials',
  templateUrl: './credit-credentials.component.html',
})
export class CreditCredentialsComponent
  extends ApplicationContextBoundComponent
  implements OnInit
{
  @Input() scope: ScopeType;
  @Input() creditReportingEnabled: Configuration;
  @Input() requireLosLoanLinkForCreditReporting: Configuration;
  @Input() requireSoftPullBeforeHardPull: Configuration;
  @Input() creditReportDocumentType: Configuration;

  @Input() documentTypes: Array<DocumentType>;
  @Input() creditCredentials: ThirdPartyCredentialArray;

  @Input() branchId?: number;

  constructor(
    private readonly injector: Injector,
    private readonly _modalService: NgbModal,
    private readonly _notificationService: NotificationService,
    private readonly _systemLevelService: SystemLevelService,
    private readonly route: ActivatedRoute,
    private readonly _spinner: NgxSpinnerService
  ) {
    super(injector);
  }

  ngOnInit(): void {
    if (!this.creditReportDocumentType) {
      this.creditReportDocumentType = new Configuration(
        'CreditReportDocumentType',
        null
      );
    }

    if (!this.requireSoftPullBeforeHardPull) {
      this.requireSoftPullBeforeHardPull = new Configuration(
        'RequireSoftPullBeforeHardPull',
        0
      );
    }
  }

  openCredentialModal(credential: ThirdPartyCredential): void {
    if (
      this.applicationContext.isTpo &&
      !this.applicationContext.isCompanyLfhs &&
      !this.applicationContext.isCompanyOpenWholesale &&
      !this.applicationContext.isCompanyMortgegProcessing &&
      !this.applicationContext.isCompanyPulseProcessing &&
      !this.applicationContext.isCompanyGenway &&
      !this.applicationContext.isCompanySmartfi
    ) {
      let modalRef = this._modalService.open(
        CreditCredentialsConfigurationDialogComponent,
        {
          ...Constants.modalOptions.large,
          windowClass: 'myCustomModalClass',
        }
      );
      modalRef.componentInstance.showYouDontHaveCreditCredentialsConfiguredMessage = false;
    } else {
      this.openClassicCredentialsEditorDialog(credential);
    }
  }

  protected openClassicCredentialsEditorDialog = (
    credential: ThirdPartyCredential
  ) => {
    const modalRef = this._modalService.open(
      CreditModalComponent,
      Constants.modalOptions.large
    );
    modalRef.componentInstance.scope = this.scope;
    modalRef.componentInstance.branchId = this.branchId;
    modalRef.componentInstance.credential = JSON.parse(
      JSON.stringify(credential)
    );
    modalRef.componentInstance.userCompanyGuid = this.route.snapshot.params.id;
    modalRef.result.then(
      (result) => {
        this.setCredentials(result);
      },
      () => {}
    );
  };

  onDocTypeChanged = () => {
    this._spinner.show();
    this._systemLevelService
      .saveConfiguration(this.creditReportDocumentType)
      .subscribe({
        next: () => {
          this._notificationService.showSuccess(
            'Document type saved successfully.',
            'Success'
          );
        },
        error: () => {
          this._notificationService.showError(
            'Unable to save document type.',
            'Error'
          );
        },
      })
      .add(() => this._spinner.hide());
  };

  setCredentials = (credential: ThirdPartyCredential): void => {
    const index = this.creditCredentials.findIndex(
      (el) => el.credentialId === credential.credentialId
    );
    if (index > -1) {
      this.creditCredentials[index] = credential;
    } else {
      this.creditCredentials.push(credential);
    }

    if (
      !this.route.snapshot.params.id &&
      credential.userId ===
        this.applicationContext.currentlyLoggedInUser.userCompanyGuid
    ) {
      // mean it's current logged in user
      this.applicationContextService.updateUserProfileThirdPartyCredentials(
        this.creditCredentials,
        ThirdPartyCredentialType.CreditVendor
      );
    }
  };
}
