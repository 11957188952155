import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ThirdPartyCredential } from 'src/app/models';

@Component({
  selector: 'credit-credentials-configuration-dialog',
  templateUrl: 'credit-credentials-configuration-dialog.component.html',
})
export class CreditCredentialsConfigurationDialogComponent implements OnInit {
	
  @Input()
  showYouDontHaveCreditCredentialsConfiguredMessage: boolean = true;

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {}

  onCredentialsSaved = (
    newlyCreatedCreditCredentials: ThirdPartyCredential
  ) => {
    this.activeModal.close(newlyCreatedCreditCredentials);
  };
}
