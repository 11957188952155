import { ExpressionTerm } from "./expression-term.model";

export interface Expression {
  conditionalOperator: string;
  expressionTerms: Array<ExpressionTerm>;
}

export enum ConditionalOperator {
  And = "And",
  Or = "Or",
  NotOr = "NotOr",
  NotAnd = "NotAnd",
}

export enum OperatorType {
  Equal = 'Equal',
  NotEqual = 'NotEqual',
  GreaterThan = 'GreaterThan',
  GreaterThanOrEqual = 'GreaterThanOrEqual',
  LessThan = 'LessThan',
  LessThanOrEqual = 'LessThanOrEqual',
  In = 'In',
  NotIn = 'NotIn',
  StartsWith = 'StartsWith',
  EndsWith = 'EndsWith',
  Contains = 'Contains',
  RegexMatch = 'RegexMatch',
  IsEmpty = 'IsEmpty',
  NotEmpty = 'NotEmpty',
  Between = 'Between',
  xPreviousDays = 'xPreviousDays',
  xNextDays = 'xNextDays',
  xPreviousHours = 'xPreviousHours',
  xNextHours = 'xNextHours',
  Today = 'Today',
  IsAnniversary = 'IsAnniversary',
  ContainsFlag = 'ContainsFlag',
  NotContainsFlag = 'NotContainsFlag',
  ArrayContainsAny = 'ArrayContainsAny',
  ArrayContainsAll = 'ArrayContainsAll'
}

export enum ExpressionDataType {
  String = "String",
  Integer = 'Integer',
  Decimal = 'Decimal',
  Boolean = 'Boolean',
  DateTime = 'DateTime',
  Date = 'Date',
  Text = 'Text',
  Time = 'Time',
  Enum = 'Enum',
  EnumFlag = 'EnumFlag',
  Array = 'Array'
}

export enum ExpressionFieldType {
  None = "None",
  Email = 'Email',
  MobilePhone = 'MobilePhone',
  LandLinePhone = 'LandLinePhone',
  FaxPhone = 'FaxPhone',
  LoanType = 'LoanType',
  LoanPurpose = 'LoanPurpose',
  LoanStatus = 'LoanStatus',
  LeadStatus = 'LeadStatus',
  User = 'User',
  Product = 'Product',
  Lender = 'Lender',
  Enum = 'Enum',
  Currency = 'Currency',
  Rate = 'Rate',
  LeadCampaign = 'LeadCampaign',
  Branch = 'Branch',
  LeadRoute = 'LeadRoute',
  LeadRouteGroup = 'LeadRouteGroup',
  RedistributionRoute = 'RedistributionRoute',
  RedistributionRouteGroup = 'RedistributionRouteGroup',
  Multiline = 'Multiline',
  SubStatus = 'SubStatus',
  TimeZone = 'TimeZone',
  Custom = 'Custom',
  Channel = 'Channel',
  ChannelFlags = 'ChannelFlags',
  CompanyStatus = 'CompanyStatus',
  State = 'State',
  AgentTags = 'AgentTags',
  Holiday = 'Holiday',
  CompensationType = 'CompensationType',
  DisclosurePath = 'DisclosurePath',
  TpoDisclosurePathPermissions = 'TpoDisclosurePathPermissions',
  AgentLoanStatus = 'AgentLoanStatus',
  DocumentationType = 'DocumentationType',
  IncomeVerificationMethod = 'IncomeVerificationMethod',
  IncomeVerificationType = 'IncomeVerificationType',
  ExternalCompany = 'ExternalCompany',
  MortgagePartyType = 'MortgagePartyType',
  MortgageEntityType = 'MortgageEntityType',
  ApplicationCopyReason = 'ApplicationCopyReason',
  ContactTrackingTag = 'ContactTrackingTag',
}

