<form id="doForm" #doForm="ngForm" name="doForm">
    <div class="modal-header">
        <h5 class="modal-title"> Desktop Originator (DO)</h5>
        <button type="button" class="btn-close" (click)="activeModal.dismiss()" aria-label="Close"></button>
    </div>
    <div class="modal-body">
        <div class="alert alert-danger border-0" role="alert" *ngIf="!doParams.isSuccessful && doParams.status">
            {{ doParams.status }}
        </div>
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-6 mb-2">
                        <label class="mb-2">
                            <b> Credit Provider </b>
                        </label>
                        <select class="form-select" id="creditVendor" name="creditVendor"
                            [(ngModel)]="doParams.creditVendor" (change)="creditProviderChanged()"
                            [ngClass]="{ 'is-invalid': creditVendor && creditVendor.touched && creditVendor.invalid }"
                            #creditVendor="ngModel" required>
                            <option value="">-- Select One --</option>
                            <optgroup *ngFor="let optionGrp of creditVendorList" label="{{optionGrp.grpName}}">
                                <option *ngFor="let itemOption of optionGrp.options" value="{{itemOption.value}}">
                                    {{itemOption.name}}</option>
                            </optgroup>
                        </select>
                        <div class="invalid-feedback"
                            *ngIf="creditVendor.touched && creditVendor.invalid && creditVendor.errors?.['required']">
                            Credit vendor is required.
                        </div>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-sm-6" *ngIf="creditVendorList.length && doParams.creditVendor">
                        <label for="doCreditOverride" class="mb-2 d-flex align-items-center">
                            <b> UserName </b>
                            <span *ngIf="selectedCreditProviderHasCreds" (click)="overrideCreditCredsClicked()"
                                class="text-primary text-decoration-underline cursor ms-3">
                                {{ !overrideCreditEnabled ? 'Override Credentials' : 'Use Default' }}</span>
                            <div *ngIf="overrideCreditEnabled" class="form-check ms-2">
                                <input class="form-check-input" type="checkbox" name="overrideCreditCreds" autocomplete="do-not-autofill"
                                    id="overrideCreditCreds" [(ngModel)]="doParams.writeCreditCredsToUserProfile">
                                <label class="form-check-label" for="overrideCreditCreds">
                                    Save to user profile
                                </label>
                            </div>
                        </label>
                        <input *ngIf="!overrideCreditEnabled" type="password" class="form-control" [value]="randomText"
                            readonly />
                        <input *ngIf="overrideCreditEnabled" type="text" class="form-control" autocomplete="new-username"
                            #doCreditUsernameField="ngModel"
                            [ngClass]="{ 'is-invalid': doCreditUsernameField && doCreditUsernameField.touched && doCreditUsernameField.invalid }"
                            name="doCreditOverrideUserName" [(ngModel)]="doParams.creditVendorUserName" required />
                    </div>
                    <div class="col-sm-6 position-relative" *ngIf="creditVendorList.length && doParams.creditVendor">
                        <label class="mb-2" for="doCreditOverridePassword"><b>Password</b></label>
                        <input *ngIf="!overrideCreditEnabled" type="password" class="form-control" [value]="randomText"
                            readonly />
                        <input *ngIf="overrideCreditEnabled" type="{{showPassword ? 'text' : 'password'}}" autocomplete="new-password"
                            class="form-control" name="doCreditOverridePassword" #doCreditPasswordField="ngModel"
                            [ngClass]="{ 'is-invalid': doCreditPasswordField && doCreditPasswordField.touched && doCreditPasswordField.invalid }"
                            [(ngModel)]="doParams.creditVendorPassword" required />
                        <i *ngIf="overrideCreditEnabled" (click)="showPassword = !showPassword"
                            class="fa fa-eye toggle-password mt-2"></i>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-md-12 mt-3" *ngFor="let creditRequest of doParams.creditRequests; let i = index">
                        <div class="border p-2 position-relative">
                            <span class="group-label panel-title">
                                {{ creditRequest.groupTitle }}
                            </span>
                            <div class="row pt-2">
                                <label for="borrower_{{i}}" class="mb-1">
                                    <b> {{ creditRequest.borrowerName }} </b>
                                </label>
                                <div class="row">
                                    <div class="col-sm-3">
                                        <select class="form-select" id="issueType_{{i}}" name="issueType_{{i}}"
                                            [(ngModel)]="creditRequest.issueType">
                                            <option value="New" *ngIf="!isTPOUser">New</option>
                                            <option value="Reissue">Reissue</option>
                                        </select>
                                    </div>
                                    <div class="col-sm-9" *ngIf="creditRequest.issueType === 'Reissue'">
                                        <input class="form-control borrower__form-input" type="text"
                                            placeholder="Credit Reference" id="borrower_{{i}}"
                                            [(ngModel)]="creditRequest.creditReferenceNumber" name="borrower_{{i}}"
                                            [ngClass]="{ 'is-invalid': refNumber && refNumber.touched && refNumber.invalid }"
                                            autocomplete="off" #refNumber="ngModel" required>
                                        <div class="invalid-feedback"
                                            *ngIf="refNumber.touched && refNumber.invalid && refNumber.errors?.['required']">
                                            This field is required.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-header">
                <h4 class="card-title"> Desktop Originator </h4>
            </div>
            <div class="card-body">
                <div class="row mb-3">
                    <div class="col-md-5">
                        <label class="mb-2">
                            <b> Sponsoring Lender </b>
                        </label>
                        <select [disabled]="(doParams.lockBrokerLenderField && !editBrokerLender) || (isCompanyGenway && isTPOUser && doParams.brokerLenderId)" class="form-select" id="brokerLenderId"
                            [ngClass]="{ 'is-invalid': brokerLenderField && brokerLenderField.touched && brokerLenderField.invalid }"
                            #brokerLenderField="ngModel" (change)="onLenderSelectionChange()" name="brokerLenderId"
                            [(ngModel)]="doParams.brokerLenderId" [required]="doParams.isGovtLoan" >
                            <option value="">-- Select One --</option>
                            <option *ngFor="let lender of ausLenders" [value]="lender.thirdPartyInstitutionId">
                                {{lender.name}}
                            </option>
                        </select>
                    </div>
                    <div class="col-md-5">
                        <label for="caseFile" class="mb-2">
                            <b> DO Casefile</b><i *ngIf="doParams.hasHistory && doParams.vendorFileId"
                                (click)="onForceEditChange()" class="fa fa-pencil-alt d-inline cursor ms-1"></i>
                        </label>
                        <input [disabled]="doParams.hasHistory && !!doParams.vendorFileId && !forceEdit" id="caseFile"
                            class="form-control" type="text" placeholder="DO Casefile" name="caseFile"
                            [(ngModel)]="doParams.vendorFileId" (ngModelChange)="onVendorFileIdChange()"
                            [ngClass]="{ 'is-invalid': caseIdField && caseIdField.touched && caseIdField.invalid }"
                            #caseIdField="ngModel" [required]="doParams.hasHistory && doParams.isSuccessful">
                    </div>
                    <div class="col-md-2">
                        <label class="mb-2">
                            <b> Submission Type </b>
                        </label>

                        <label class="d-block mt-2">
                            {{ (doParams.hasHistory && doParams.vendorFileId) || doParams.vendorFileId ? 'Resubmit' :
                            'New'}}
                        </label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-5">
                        <label for="doOverrideUsername" class="mb-2 d-flex align-items-center">
                            <b> UserName </b>
                            <span (click)="overrideClicked()"
                                class="text-primary text-decoration-underline cursor ms-3">
                                {{ !overrideDoCredsEnabled ? 'Override Credentials' : 'Use Default' }}</span>
                            <div *ngIf="overrideDoCredsEnabled" class="form-check ms-2">
                                <input class="form-check-input" type="checkbox" name="overrideCreds" id="overrideCreds"
                                    [(ngModel)]="doParams.writeAusCredsToUserProfile">
                                <label class="form-check-label" for="overrideCreds">
                                    Save to user profile
                                </label>
                            </div>
                        </label>
                        <input *ngIf="!overrideDoCredsEnabled" type="password" class="form-control" [value]="randomText"
                            readonly />
                        <input *ngIf="overrideDoCredsEnabled" class="form-control" type="text" name="doOverrideUsername"
                            [(ngModel)]="doParams.doUsernameOverride"
                            autocomplete="new-username"
                            [ngClass]="{ 'is-invalid': doUsernameField && doUsernameField.touched && doUsernameField.invalid }"
                            #doUsernameField="ngModel" required>
                    </div>
                    <div class="col-md-5 position-relative">
                        <label class="mb-2">
                            <b> Password </b>
                        </label>
                        <input *ngIf="!overrideDoCredsEnabled" type="password" class="form-control" [value]="randomText"
                            readonly />
                        <input *ngIf="overrideDoCredsEnabled" type="{{showDoPassword ? 'text' : 'password'}}"
                            class="form-control" name="doOverridePassword" [(ngModel)]="doParams.doPasswordOverride"
                            autocomplete="new-password"
                            [ngClass]="{ 'is-invalid': doPasswordField && doPasswordField.touched && doPasswordField.invalid }"
                            #doPasswordField="ngModel" required>
                        <i *ngIf="overrideDoCredsEnabled" (click)="showDoPassword = !showDoPassword"
                            class="fa fa-eye toggle-password mt-2"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <span *ngIf="doParams.submissionStatus === 'Final'" class="flex-grow-1">
            <i class="fas fa-exclamation-triangle text-primary"></i>
            Casefile is finalized; Lender must release to resubmit.
        </span>
        <div *ngIf="doParams.brokerLenderId !== '' && canFinalizeDO" class="form-check me-2">
            <input class="form-check-input" type="checkbox" name="finalizeDo" id="finalizeDo"
                [(ngModel)]="doParams.finalizeDo" [disabled]="doParams.submissionStatus === 'Final'">
            <label class="form-check-label" for="finalizeDo">
                finalize DO
            </label>
        </div>
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
            (click)="activeModal.dismiss()">Close</button>
        <button [disabled]="doParams.submissionStatus === 'Final'" class="btn btn-primary me-1"
            (click)="submitRequest()">
            {{ doParams.hasHistory ? 'Re-run DO' : 'Run DO' }}
        </button>
    </div>
</form>
