import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { IHaveContactInfo } from 'src/app/models/contact-info.interface';
import { RecordType } from 'src/app/modules/dialer/models/dial-list-record-basic.model';
import { ManualDialParams } from 'src/app/modules/dialer/models/manual-dial-params.model';
import { PhoneType } from 'src/app/modules/dialer/models/phone-type.model';
import { DialerService } from 'src/app/modules/dialer/services/dialer.service';
import { ApplicationContextBoundComponent } from '../application-context-bound.component';
import { ConversationService } from 'src/app/modules/conversations/services/conversation.service';
import { PopoverDirective } from 'ngx-bootstrap/popover';

@Component({
  selector: 'table-cell-contact-info',
  templateUrl: 'contact-info.component.html',
  styleUrls: ['contact-info.component.scss']
})
export class ContactInfoComponent extends ApplicationContextBoundComponent implements OnInit {

  @Input()
  contactInfo: IHaveContactInfo;

  @Input()
  emailEnabled: boolean = true;

  @Input()
  smsEnabled: boolean = true;

  @Input()
  dialerEnabled: boolean = false;

  @Input()
  isManualDial: boolean = true;

  @Input()
  ellipsisShowingEnabled: boolean = false;

  @Input()
  recordType: RecordType = RecordType.Borrower;

  @Input()
  recordId: number;

  @Input()
  popoverBorrowerInfo: PopoverDirective;

  @Input()
  applicationId: number;

  @Input()
  isLinksDisabled: boolean = false;

  @Input()
  showHomePhone: boolean = true;

  @Input()
  showFax: boolean = false;

  @Input()
  isLodaEmail: boolean = false;

  @Output()
  dialClicked: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  lodaEmailClicked: EventEmitter<any> = new EventEmitter<any>();

  smsAlertsEnabled: boolean = false;

  constructor(
    private readonly injector: Injector,
    private readonly _dialerService: DialerService,
    private readonly _conversationService: ConversationService
  ) {
    super(injector);
  }

  ngOnInit() {
    this.smsAlertsEnabled = this.applicationContext.userPermissions.smsAlertsEnabled;
    this.contactInfo.phone = this.contactInfo.phone || this.contactInfo.homePhone;
  }

  dial = (phoneNumber: string, phoneType: PhoneType): void => {
    if (this.isManualDial) {
      let data = {
        phoneNumber: phoneNumber,
        phoneType: phoneType,
        firstName: this.contactInfo.firstName,
        lastName: this.contactInfo.lastName,
        recordType: this.recordType,
        recordId: this.recordId,
        applicationId: this.applicationId,
        borrowerId: this.contactInfo.borrowerId
      } as ManualDialParams;

      this._dialerService.openCallControlPanel(data)
    } else {
      this.dialClicked.emit({
        phoneNumber: phoneNumber,
        phoneType: phoneType,
        firstName: this.contactInfo.firstName,
        lastName: this.contactInfo.lastName,
        recordType: this.recordType,
        recordId: this.recordId
      })
    }
  }

  onOpenSmsChat = (contact: IHaveContactInfo, phone: string) => {
    this._conversationService.openSmsChat({
      userPhone: phone,
      userName: `${contact.firstName} ${contact.lastName}`,
      openDrawer: true,
      openChat: true,
      conversationTargetPersonContext: {
        applicationId: this.applicationId,
        borrowerId: this.contactInfo.borrowerId,
        leadId: this.recordType === RecordType.Lead ? this.recordId : null,
        agentId: this.recordType === RecordType.Agent ? this.recordId : null
      }
    });
    this.popoverBorrowerInfo?.hide();
  }

  onEmailClicked = () => {
    this.lodaEmailClicked.emit(this.contactInfo.email);
  }
}
