<ng-template #pageActionBarLeft>
  <bread-crum></bread-crum>
</ng-template>

<ng-template #pageActionBarRight>
  <button type="button" class="btn btn-primary" [disabled]="superAdmin" (click)="addNewType()">
    <i class="fas fa-plus"></i> Add New
  </button>
</ng-template>

<ng-template #mainContent>
  <h5>{{ superAdmin ? companyName + ' - ' : '' }}</h5>

  <p-table
    #dt1
    [columns]="columns"
    [value]="types"
    [paginator]="true"
    [rows]="25"
    responsiveLayout="scroll"
    name="document-type-list-table-v2"
    [rowsPerPageOptions]="[10, 25, 50]"
    [globalFilterFields]="globalFilterFields"
    [autoLayout]="true"
    [showCurrentPageReport]="true"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
    styleClass="p-datatable-gridlines"
    [sortField]="'order'"
    scrollable="true"
    [scrollHeight]="scrollHeight"
  >
    <ng-template pTemplate="caption">
      <div class="d-flex justify-content-between">
        <div></div>
        <div>
          <p-multiSelect
            [options]="columns"
            [(ngModel)]="selectedColumns"
            optionLabel="header"
            selectedItemsLabel="{0} columns selected"
            name="selectedColumns"
            ngDefaultControl
            defaultLabel="Choose Columns"
          ></p-multiSelect>

          <span class="p-input-icon-right ms-2 h-100">
            <i class="pi pi-search"></i>
            <input
              pInputText
              type="text"
              class="form-control h-100"
              (input)="dt1.filterGlobal($event.target.value, 'contains')"
              (ngModelChange)="dt1.first=0"
              placeholder="Search keyword"
            />
          </span>
        </div>
      </div>
    </ng-template>

    <ng-template pTemplate="header">
      <tr>
        <th style="width: 7rem; text-align: center;">
          <span>Action</span>
        </th>
        <th
          *ngFor="let col of getSortedColumns()"
          [pSortableColumn]="col.field"
          [pSortableColumnDisabled]="col.field == 'order'"
        >
          <span>
            <a
              *ngIf="!superAdmin && col.field == 'order'; else colHeaderText"
              class="text-primary"
              (click)="openOrderDrawer()"
              >{{ col.header }}</a
            >
            <ng-template #colHeaderText>
              <span>{{ col.header }}</span>
            </ng-template>
          </span>
          <p-sortIcon [field]="col.field" class="pull-right"></p-sortIcon>
        </th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-rowData>
      <tr>
        <td>
          <span class="d-flex align-items-center justify-content-center w-100">
            <span>
              <i
                class="fa fa-pencil-alt cursor text-primary"
                (click)="onEditTypeClicked(rowData)"
                title="Edit"
              ></i>
            </span>

            <span class="mx-2"> | </span>

            <span>
              <i
                *ngIf="!isDeleteClicked[rowData.documentTypeId]"
                class="fa fa-trash-alt cursor text-danger"
                (click)="isDeleteClicked[rowData.documentTypeId] = true"
                title="Delete"
              ></i>

              <i
                *ngIf="isDeleteClicked[rowData.documentTypeId]"
                class="fa fa-times cursor text-danger me-2"
                (click)="isDeleteClicked[rowData.documentTypeId] = false"
                title="Cancel"
              ></i>

              <i
                *ngIf="isDeleteClicked[rowData.documentTypeId]"
                class="fa fa-check-circle cursor text-danger"
                (click)="onDeleteTypeClicked(rowData.documentTypeId)"
                title="Delete"
              ></i>
            </span>
          </span>
        </td>
        <td *ngFor="let col of getSortedColumns()" [ngSwitch]="col.field">

          <span *ngSwitchCase="'showOnBorrowerPortal'">
            <input type="checkbox" [checked]="rowData.showOnBorrowerPortal" readonly />
          </span>

          <span *ngSwitchCase="'showOnTPOPortal'">
            <input type="checkbox" [checked]="rowData.showOnTPOPortal" readonly />
          </span>

          <span *ngSwitchCase="'allowTpoUpload'">
            <input type="checkbox" [checked]="rowData.allowTpoUpload" readonly />
          </span>

          <span *ngSwitchCase="'showOnChannels'">
            {{ rowData.showOnChannels.replaceAll('NonDelegatedCorrespondent', 'Non Delegated Correspondent') }}
           </span>

           <span *ngSwitchCase="'allowUploadOnChannels'">
            {{ rowData.allowUploadOnChannels.replaceAll('NonDelegatedCorrespondent', 'Non Delegated Correspondent') }}
           </span>

          <span *ngSwitchCase="'permittedAgentTypes'">
           {{ getSelectedAgentTypeNames(rowData.permittedAgentTypes) }}
          </span>

          <span *ngSwitchCase="'restrictedToRoles'">
            {{ getSelectedRoleNames(rowData.restrictedToRoles) }}
           </span>

          <span *ngSwitchDefault>
            {{ rowData[col.field] }}
          </span>
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="11" class="text-center">
          {{ !isLoading ? 'There are no types.' : 'Loading...' }}
        </td>
      </tr>
    </ng-template>
  </p-table>
</ng-template>

<admin-page-template
  [useActionBarSections]="true"
  [pageMainContentTemplate]="mainContent"
  [pageActionBarLeftContentTemplate]="pageActionBarLeft"
  [pageActionBarRightContentTemplate]="pageActionBarRight">
</admin-page-template>

<drawer
  #docTypeOrderDrawer
  [title]="'Document Types Order'"
  [name]="'docTypeOrderDrawer'"
  [templateRef]="docTypeOrderRef"
  [options]="orderDrawerOptions">
</drawer>
<ng-template #docTypeOrderRef>
    <rearrange-order
        #docTypeOrder
        [itemList]="itemsToOrder"
        (close)="closeOrderDrawer($event)"
        (save)="onOrderDrawerSave($event)"
    ></rearrange-order>
</ng-template>
