import { CanDeactivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export interface PricingIFrameComponentCanDeactivate {
  canDeactivate: () => boolean | Observable<boolean>;
}

@Injectable()
export class PricingIFrameChangesGuard implements CanDeactivate<PricingIFrameComponentCanDeactivate> {
  
  canDeactivate(component: PricingIFrameComponentCanDeactivate): boolean | Observable<boolean> {
    // if there are no pending changes, just allow deactivation; else confirm first
    if (!component) {
      return true;
    }
    return component.canDeactivate();
  }
}
