import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { tap } from "rxjs/operators";
import { Constants } from "./constants";
import { openDB, IDBPDatabase } from "idb";
import { LocalStorageService } from "../core/services/local-storage.service";

interface CacheEntry<T> {
  value: T;
  expiration: Date;
}

@Injectable({
  providedIn: "root",
})
export class CacheService {
  private readonly localStorageKey = 'appCache';
  private cache: { [key: string]: CacheEntry<any> } = {};
  private tenantKeyPrefix: string;
  private dbPromise: Promise<IDBPDatabase>;

  constructor(private readonly _localStorageService: LocalStorageService) {
    this.tenantKeyPrefix = ''; // Initialize to empty, should be set based on tenant context.
    this.dbPromise = this.initializeDbAsync();
    this.loadCacheFromLocalStorage();
  }

  private async initializeDbAsync(): Promise<IDBPDatabase> {
    return await openDB('cache-db', 1, {
      upgrade(db) {
        if (!db.objectStoreNames.contains('cacheStore')) {
          db.createObjectStore('cacheStore');
        }
      },
    });
  }

  initializeTenantPrefix(): void {
    const authData = localStorage.getItem(Constants.authorization.authorizationDataKey);
    if (authData) {
      const parsedAuthData = JSON.parse(authData);
      if (parsedAuthData && parsedAuthData.userCompanyGuid) {
        this.setTenantPrefix(parsedAuthData.userCompanyGuid);
      }
    }
  }

  // Set the tenant prefix for cache keys
  setTenantPrefix(tenantId: string): void {
    this.tenantKeyPrefix = tenantId;
    this.loadCacheFromLocalStorage();
  }

  // Load cache from localStorage for the current tenant
  private loadCacheFromLocalStorage(): void {
    this.cache = {}; // Clear existing cache
    const storedCache = localStorage.getItem(this.localStorageKey);
    if (storedCache) {
      const allCache = JSON.parse(storedCache);

      // Only load entries for the current tenant
      Object.keys(allCache).forEach((key) => {
        if (key.startsWith(this.tenantKeyPrefix)) {
          this.cache[key] = allCache[key];
          // Convert string dates to Date objects
          this.cache[key].expiration = new Date(this.cache[key].expiration);
        }
      });
    }
  }

  // Save the current tenant's cache to localStorage
  private saveCacheToLocalStorage(): void {
    const allCache = JSON.parse(localStorage.getItem(this.localStorageKey) || '{}');

    // Merge current tenant's cache with the existing cache
    Object.keys(this.cache).forEach((key) => {
      allCache[key] = this.cache[key];
    });

    localStorage.setItem(this.localStorageKey, JSON.stringify(allCache));
  }

  private getCacheKey(key: string): string {
    return `${this.tenantKeyPrefix}_${key}`;
  }

  async getAsync<T>(key: string, useIndexedDb: boolean = false): Promise<T | null> {
    const cacheKey = this.getCacheKey(key);
    const entry = this.cache[cacheKey];

    if (entry) {
      const now = new Date();
      if (now < entry.expiration) {
        return entry.value;
      } else {
        // If the cache has expired, delete it
        await this.clearByKeyAsync(key);
      }
    }

    // Check IndexedDB if configured
    if (useIndexedDb) {
      const db = await this.dbPromise;
      const indexedDbEntry = await db.get('cacheStore', cacheKey);
      if (indexedDbEntry) {
        const now = new Date();
        if (now < indexedDbEntry.expiration) {
          return indexedDbEntry.value;
        } else {
          // If the cache has expired, delete it
          await this.clearByKeyAsync(key);
        }
      }
    }

    return null;
  }

  async setAsync<T>(key: string, value: T, durationInMinutes: number, useLocalStorage: boolean, useIndexedDb: boolean = false): Promise<void> {
    const expiration = new Date();
    expiration.setMinutes(expiration.getMinutes() + durationInMinutes);
    const cacheKey = this.getCacheKey(key);
    this.cache[cacheKey] = { value, expiration };

    try {
      if (useLocalStorage) {
        this.saveCacheToLocalStorage();
      }

      if (useIndexedDb) {
        const db = await this.dbPromise;
        await db.put('cacheStore', this.cache[cacheKey], cacheKey);
      }
    } catch (err) {
      console.warn('Error saving into cache - most likely you exceeded your quota', err);
    }
  }

  // Wrap method for easier cache usage
  wrap<T>(key: string, observable: Observable<T>, durationInMinutes: number, useLocalStorage: boolean = true, useIndexedDb: boolean = false): Observable<T> {
    return new Observable<T>((observer) => {
      const authData = this._localStorageService.authorizationData;
      if (authData && authData.userName.toLowerCase() === 'admin@admin.com') {
        observable.subscribe({
          next: (data) => observer.next(data),
          error: (err) => observer.error(err),
          complete: () => observer.complete()
        });
        return;
      }
      this.getAsync<T>(key, useIndexedDb).then((cachedData) => {
        if (cachedData) {
          observer.next(cachedData);
          observer.complete();
        } else {
          observable.pipe(
            tap(async (data) => {
              await this.setAsync(key, data, durationInMinutes, useLocalStorage, useIndexedDb);
            })
          ).subscribe({
            next: (data) => observer.next(data),
            error: (err) => observer.error(err),
            complete: () => observer.complete()
          });
        }
      });
    });
  }

  // Method to clear the cache for the current tenant prefix only
  async clearAsync(): Promise<void> {
    // Clear in-memory cache for the current tenant prefix
    Object.keys(this.cache).forEach((key) => {
      if (key.startsWith(this.tenantKeyPrefix)) {
        delete this.cache[key];
      }
    });

    // Clear tenant-specific cache entries from localStorage
    const storedCache = localStorage.getItem(this.localStorageKey);
    if (storedCache) {
      const allCache = JSON.parse(storedCache);
      Object.keys(allCache).forEach((key) => {
        if (key.startsWith(this.tenantKeyPrefix)) {
          delete allCache[key];
        }
      });
      localStorage.setItem(this.localStorageKey, JSON.stringify(allCache));
    }

    // Clear tenant-specific cache entries from IndexedDB
    const db = await this.dbPromise;
    const transaction = db.transaction('cacheStore', 'readwrite');
    const store = transaction.objectStore('cacheStore');
    const keys = await store.getAllKeys();
    keys.forEach(async (key) => {
      if (typeof key === 'string' && key.startsWith(this.tenantKeyPrefix)) {
        await store.delete(key);
      }
    });

    await transaction.done;
  }

  // Clear specific cache entry by key, including from localStorage and IndexedDB if present
  async clearByKeyAsync(key: string): Promise<void> {
    const cacheKey = this.getCacheKey(key);

    // Remove from in-memory cache
    if (this.cache[cacheKey]) {
      delete this.cache[cacheKey];
    }
    if (this.cache[key]) {
      delete this.cache[key];
    }

    // Remove from localStorage
    const storedCache = localStorage.getItem(this.localStorageKey);
    if (storedCache) {
      const allCache = JSON.parse(storedCache);
      if (allCache[cacheKey] || allCache[key]) {
        delete allCache[cacheKey];
        delete allCache[key];
        localStorage.setItem(this.localStorageKey, JSON.stringify(allCache));
      }
    }

    // Remove from IndexedDB
    const db = await this.dbPromise;
    await db.delete('cacheStore', cacheKey);
    await db.delete('cacheStore', key);
  }
}
