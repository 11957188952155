<ng-template #spinner>
  <loading-indicator [loadingMessage]="'Loading orders, please wait...'"></loading-indicator>
</ng-template>
<ng-container *ngIf="orders else spinner;">
  <div class="row">
    <div class="col-6">
      <esignature-orders-table [orders]="orders" (orderSelected)="onOrderSelected($event)"></esignature-orders-table>
    </div>
    <div class="col-6">
      <div class="card rounded-2" style="height: 100%; border: 1px solid #e9ecef !important;" *ngIf="!selectedOrder">
        <div class="card-body message-container">
          <h3 class="rounded-2">Please select a history item on the left to see details</h3>
        </div>
      </div>
      <ng-container *ngIf="selectedOrder">
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">Recipients ({{selectedOrder.recipients ? selectedOrder.recipients.length : 0}})</h4>
          </div>
          <div class="card-body">
            <esignature-recipients-table *ngIf="selectedOrder" [recipients]="selectedOrder.recipients">
            </esignature-recipients-table>
          </div>
        </div>
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">Tasks ({{selectedOrder.loanDocTasks ? selectedOrder.loanDocTasks.length : 0}})</h4>
          </div>
          <div class="card-body">
            <esignature-tasks-table *ngIf="selectedOrder" [tasks]="selectedOrder.loanDocTasks" (reloadTasks)="reloadTasks()"></esignature-tasks-table>
          </div>
        </div>
        <div class="card" *ngIf="adminFunctionSectionEnabled">
          <div class="card-header">
            <h4 class="card-title">Admin Functions</h4>
          </div>
          <div class="card-body">
            <button class="btn btn-primary" [disabled]="isRefreshLoading" (click)="refresh()">
              <span class="spinner-grow spinner-grow-sm" *ngIf="isRefreshLoading" role="status"
                aria-hidden="true"></span>
              {{ isRefreshLoading ? 'Please wait...' : 'Refresh Order from DocuSign'}}

            </button>
            <button *ngIf="allowCancelEsign" class="btn btn-danger mx-1" (click)="cancelOrder()"
              [disabled]="selectedOrder.status == 'Cancelled' || isCancelLoading">
              <span class="spinner-grow spinner-grow-sm" *ngIf="isCancelLoading" role="status"
                aria-hidden="true"></span>
              {{ isCancelLoading ? 'Please wait...' : ' Cancel Order '}}
            </button>
          </div>
        </div>
        <div class="card" *ngIf="originalDocumentsSectionEnabled">
          <div class="card-header">
            <h4 class="card-title">Original Documents</h4>
          </div>
          <div class="card-body">
            <esignature-original-docs-table [documents]="originalDocumentsForSelectedOrder"
              (viewDocumentClicked)="onViewOriginalDocumentClicked($event)"
              (viewFilePackageClicked)="onViewFilePackageClicked($event)">
            </esignature-original-docs-table>
            <button class="btn btn-primary mt-2" type="button" [disabled]="isLoadingOriginalDocuments"
              (click)="onloadOriginalDocsClicked()">
              <span *ngIf="isLoadingOriginalDocuments" class="spinner-border spinner-border-sm" role="status"
                aria-hidden="true">
              </span>
              {{ isLoadingOriginalDocuments ? 'Please wait...' : 'Load Original Documents'}}
            </button>
          </div>
        </div>
        <div class="card" *ngIf="docuSignDataSectionEnabled">
          <div class="card-header">
            <h4 class="card-title">DocuSign Data</h4>
          </div>
          <div class="card-body">
            <docusign-data [docuSignData]="docuSignDataForSelectedOrder"
              (viewDocuSignDocumentClicked)="viewDocuSignDocumentClicked($event)">
            </docusign-data>
            <button class="btn btn-primary mt-2" type="button" [disabled]="isLoadingDocuSignData"
              (click)="onloadDocuSignDataClicked()">
              <span *ngIf="isLoadingDocuSignData" class="spinner-border spinner-border-sm" role="status"
                aria-hidden="true">
              </span>
              {{ isLoadingDocuSignData ? 'Please wait...' : 'Load DocuSign Data'}}
            </button>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>