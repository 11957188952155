import { Injectable } from '@angular/core';
import { defaultIfEmpty, firstValueFrom, forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DataService } from 'src/app/core/services/data.service';
import { PricingVendor } from '../../../models/pricing/pricing-vendor';
import { EnumerationService } from '../../../services/enumeration-service';
import { PricingEngineVendor } from '../../admin/tpo-config/models/configuration-product.model';
import { PricingEngineVendorAccount } from '../../global-admin/company/models/pricing-vendor-account.model';
import { OBChannel, VendorProfile } from '../models/business-channel.model';
import { AssignedProductPrice } from '../models/pricing/assigned-product-price.model';
import { BaseRequest } from '../models/pricing/base-request.model';
import { CustomField } from '../models/pricing/custom-fields.model';
import { LoanProductPricing } from '../models/pricing/loan-product-pricing.model';
import { LockExtensionRequest } from '../models/pricing/lock-extension-request.model';
import { LockRepriceRequest } from '../models/pricing/lock-reprice-request.model';
import { LockRepriceResponse } from '../models/pricing/lock-reprice-response.model';
import { LockResponse } from '../models/pricing/lock-response.model';
import { PricingEvents } from '../models/pricing/pricing-events';
import { PricingImportProductsRequestModel } from '../models/pricing/pricing-import-products-request.model';
import { InvestorProductList } from '../models/pricing/pricing-investor-product.model';
import { Adjustment } from '../models/pricing/pricing-quote.model';
import { PricingScenario } from '../models/pricing/pricing-scenario.model';
import { ProductPriceValidation } from '../models/pricing/product-price-validation.model';
import { PricedProduct } from '../models/pricing/product-pricing-detail.model';
import {
  ForwardLockRequest,
  LoCompensationInPricing,
  ProductSearchRequest,
  ProductSearchRequestInfo,
} from '../models/pricing/product-search-request-info.model';
import { ProductSearchResult, VendorFeatureRequest } from '../models/pricing/product-search-result.model';
import { PricingRateCreditApplicationMethod } from '../models/pricing/rate.model';
import { LockExtensionResponse } from '../models/pricing/lock-extension-response.model';
import { QuickPriceHistoryItem } from '../models/pricing/quick-price-history-item.model';
import { OriginatorCompensation } from '../models/pricing/originator-compensation.model';
import { CustomFieldSection } from '../models/pricing/custom-field-section.model';
import { LoanApplication } from 'src/app/models';
import { ZipCodeService } from 'src/app/services/zipcode.service';
import { PricingCredentials } from '../models/pricing/pricing-credentials.model';

@Injectable()
export class PricingService {
  constructor(
    private readonly _dataService: DataService,
    private readonly _enumerationService: EnumerationService,
    private readonly _zipCodeService: ZipCodeService,
  ) { }

  getPricingCredentials = (loanId?: number): Observable<PricingCredentials> => {
    let url = `api/pricing/credentials`;
    if (loanId)
      url += `?applicationId=${loanId}`;
    return this._dataService.get(url);
  };

  searchProducts = (
    selectedChannelId: number,
    searchRequest: ProductSearchRequest
  ): Observable<ProductSearchResult> => {
    const url = `api/pricing/vendor/OptimalBlue/channel/${selectedChannelId}/full/search`;
    return this._dataService.post(url, searchRequest);
  };

  searchProductsByVendor = (
    vendor: string,
    searchRequest: ProductSearchRequest
  ): Observable<ProductSearchResult> => {
    const url = `api/pricing/vendor/${vendor}/search`;
    return this._dataService.post(url, searchRequest);
  };

  searchProductsWithDetailByVendor = (
    vendor: string,
    searchRequest: ProductSearchRequest
  ): Observable<ProductSearchResult> => {
    const url = `api/pricing/vendor/${vendor}/search-with-detail`;
    return this._dataService.post(url, searchRequest);
  };

  getFeaturesByVendor = (vendor: string, payload: VendorFeatureRequest) => {
    const url = `api/pricing/vendor/${vendor}/features`;
    return this._dataService.post(url, payload);
  }

  assignProductPriceToLoan = (applicationId: number,
    productPrice: AssignedProductPrice
  ): Observable<any> => {
    const url = `api/pricing/loan/${applicationId}/assign`;
    return this._dataService.post(url, productPrice);
  };

  applyScenarioToLoan = (applicationId: number,
    scenarioId: number,
    creditApplicationMethod: PricingRateCreditApplicationMethod
  ): Observable<any> => {
    const url = `api/pricing/loan/${applicationId}/assign/scenario/${scenarioId}?rateCreditApplicationMethod=${creditApplicationMethod}`;
    return this._dataService.post(url, {});
  };

  getLoanPricingRequest = (
    applicationId: number
  ): Observable<ProductSearchRequestInfo> => {
    const url = `api/pricing/request/assigned/${applicationId}`;
    return this._dataService.get(url);
  };

  getMortgagePricingRequest = (
    mortgageId: number
  ): Observable<ProductSearchRequestInfo> => {
    const url = `api/pricing/request/${mortgageId}`;
    return this._dataService.get(url);
  };

  getPricingRequestForApplication = (application: LoanApplication): Observable<ProductSearchRequestInfo> => {
    const url = `api/pricing/request/mortgage`;
    return this._dataService.post(url, application);
  };

  getProductPricingDetails = (
    vendor: string,
    searchRequest: ProductSearchRequest
  ): Observable<PricedProduct> => {
    const url = `api/pricing/vendor/${vendor}/search/products/detail`;
    return this._dataService.post(url, searchRequest);
  };

  getLoanProductPricing = (
    applicationId: number
  ): Observable<LoanProductPricing> => {
    const url = `api/loan/${applicationId}/productpricing`;
    return this._dataService.get(url);
  };

  getAdjustments = (applicationId: number): Observable<Adjustment[]> => {
    const url = `api/Loan/${applicationId}/productpricing/adjustments`;
    return this._dataService.get(url);
  };

  requestRateLock = (applicationId: number): Observable<LockResponse> => {
    const url = `api/pricing/loan/${applicationId}/request-lock`;
    return this._dataService.post(url, {});
  };

  repriceLock = (applicationId: number, request?: LockRepriceRequest): Observable<LockRepriceResponse> => {
    const url = `api/pricing/loan/${applicationId}/reprice-lock`;
    return this._dataService.post(url, request);
  };

  programChange = (applicationId: number, request?: LockRepriceRequest): Observable<LockRepriceResponse> => {
    const url = `api/pricing/loan/${applicationId}/program-change`;
    return this._dataService.post(url, request);
  };

  requestLockExtension = (applicationId: number, request: LockExtensionRequest): Observable<LockExtensionResponse> => {
    const url = `api/pricing/loan/${applicationId}/request-lock-extension`;
    return this._dataService.post(url, request);
  };

  requestForwardLock = (data: ForwardLockRequest, vendor: PricingEngineVendor) => {
    const url = `api/pricing/vendor/${vendor}/forward-lock`;
    return this._dataService.post(url, data);
  }

  getPollyPricerUrl = (channel:string, applicationId?: number, pricingCredentialId?:number): Observable<string> => {
    let url = `api/pricing/loan/polly-url`;

    let queryParams: string[] = [];

    queryParams.push("channel=" + channel);

    if (applicationId) {
      queryParams.push("applicationId=" + applicationId);
    }

    if (pricingCredentialId) {
      queryParams.push("pricingCredentialId=" + pricingCredentialId);
    }

    if (queryParams.length) {
      url = url + '?' + queryParams.join("&");
    }

    return this._dataService.get(url, { observe: 'body', responseType: 'text' });
  }

  getLenderPricePricerUrl = (applicationId?: number): Observable<string> => {
    let url = `api/pricing/loan/lender-price-url`;

    let queryParams: string[] = [];

    if (applicationId) {
      queryParams.push("applicationId=" + applicationId);
    }

    if (queryParams.length) {
      url = url + '?' + queryParams.join("&");
    }

    return this._dataService.get(url, { observe: 'body', responseType: 'text' });
  };

  getLoanPassPricerUrl = (applicationId: number): Observable<string> => {
    let url = `api/pricing/loan/loan-pass-url`;
    if (applicationId)
      url += `?applicationId=${applicationId}`;
    return this._dataService.get(url, { observe: 'body', responseType: 'text' });
  };

  getProductPricingHistory = (
    applicationId: number
  ): Observable<PricingEvents[]> => {
    const url = `api/Loan/${applicationId}/productpricing/events`;
    return this._dataService.get(url);
  };

  getQuickPriceHistory = (): Observable<QuickPriceHistoryItem[]> => {
    const url = `api/pricing/quick-pricer/history`;
    return this._dataService.get(url);
  };

  checkIfProductPricingIsValid = (
    applicationId: number
  ): Observable<ProductPriceValidation> => {
    const url = `api/pricing/loan/${applicationId}/validation`;
    return this._dataService.get(url);
  };

  getCustomFields = (vendorName: string, baseRequest: BaseRequest): Observable<CustomField[]> => {
    const url = `api/pricing/vendor/${vendorName}/custom-fields`;
    return this._dataService.post(url, baseRequest);
  };

  getCustomFieldsV2 = (vendorName: string, baseRequest: BaseRequest): Observable<CustomFieldSection[]> => {
    const url = `api/pricing/vendor/${vendorName}/custom-fields/v2`;
    return this._dataService.post(url, baseRequest);
  };

  setApproveLock = (appId: number): Observable<LockResponse> => {
    const url = `api/pricing/loan/${appId}/approve-lock`;
    return this._dataService.post(url, {});
  };

  setRejectLock = (appId: number): Observable<LockResponse> => {
    const url = `api/pricing/loan/${appId}/reject-lock`;
    return this._dataService.post(url, {});
  };

  setRequestCancellationLock = (appId: number): Observable<LockResponse> => {
    const url = `api/pricing/loan/${appId}/request-lock-cancelation`;
    return this._dataService.post(url, {});
  };

  setCancelLock = (appId: number): Observable<LockResponse> => {
    const url = `api/pricing/loan/${appId}/cancel-lock`;
    return this._dataService.post(url, {});
  };

  getPricingScenarios = (applicationId: number | null): Observable<PricingScenario[]> => {
    const url = `api/pricing/scenarios/for-app/${applicationId ?? ''}`;
    return this._dataService.get(url);
  };

  getPricingScenario = (scenarioId: number): Observable<PricingScenario> => {
    const url = `api/pricing/scenarios/${scenarioId}`;
    return this._dataService.get(url);
  };

  getPricingScenariosGlobal = (): Observable<PricingScenario[]> => {
    const url = "api/pricing/scenarios/global";
    return this._dataService.get(url);
  };

  createPricingScenario = (pricingScenario: PricingScenario): Observable<PricingScenario> => {
    const url = `api/pricing/scenarios`;
    return this._dataService.post(url, pricingScenario);
  };

  savePricingScenario = (scenario: PricingScenario): Observable<PricingScenario> => {
    const url = `api/pricing/scenarios/${scenario.pricingScenarioId}`;
    return this._dataService.post(url, scenario);
  };

  deletePricingScenarios = (id: number): Observable<PricingScenario> => {
    const url = `api/pricing/scenarios/${id}`;
    return this._dataService.delete(url);
  };

  importProducts(
    vendor: PricingEngineVendor,
    request: PricingImportProductsRequestModel,
  ): Observable<void> {
    const url = `api/pricing/vendor/${vendor}/import-products`;
    return this._dataService.post(url, request);
  }

  getPricingProductLists(
    vendor: PricingEngineVendor,
    nonImportedOnly: boolean = false,
  ): Observable<InvestorProductList[]> {
    let url = `api/pricing/vendor/${vendor}/products`;
    if (nonImportedOnly) {
      url += '?nonImportedOnly=true';
    }
    return this._dataService.get(url);
  }

  getPricingInvestors = (vendorName: PricingEngineVendor): Observable<InvestorProductList[]> => {
    const url = `api/pricing/vendor/${vendorName}/investors`;
    return this._dataService.get(url);
  };

  createLoan = (pricingScenario: PricingScenario): Observable<PricingScenario> => {
    const url = `api/pricing/scenarios/${pricingScenario.pricingScenarioId}/loan`;
    return this._dataService.post(url, pricingScenario);
  };

  refreshScenario = (scenarioId: number): Observable<PricingScenario> => {
    const url = `api/pricing/scenarios/${scenarioId}/refresh`;
    return this._dataService.post(url, {});
  }

  getActiveOriginatorCompensationForUser = (userId: string, branchId?: number): Observable<OriginatorCompensation> => {
    let url = `api/admin/originator-compensation/active?userId=${userId}`;
    if (branchId) {
      url += `&branchId=${branchId}`;
    }
    return this._dataService.get(url);
  }

  getDefaultPricingRequest = async (zip: string): Promise<ProductSearchRequestInfo> => {
    var state = undefined;
    var county = undefined;
    var countyFips = undefined;
    var city = undefined;
    if (!!zip) {
      var result = await firstValueFrom(this._zipCodeService.lookupZipCode(zip, true));
      if (result?.length > 0) {
        city = result[0].city;
        state = result[0].state?.toLowerCase();
        county = result[0].county;
        countyFips = result[0].countyFips;
      }
    }

    let requestInfo = new ProductSearchRequestInfo();
    requestInfo.request.borrowerInformation.address1 = "";
    requestInfo.request.borrowerInformation.address2 = "";
    requestInfo.request.borrowerInformation.applicationDate = "";
    requestInfo.request.borrowerInformation.assetDocumentation = "Verified";
    requestInfo.request.borrowerInformation.bankruptcy = "";
    requestInfo.request.borrowerInformation.bankruptcyChapter = null;
    requestInfo.request.borrowerInformation.bankruptcyDisposition = null;
    requestInfo.request.borrowerInformation.bankStatementsForIncome = "NotApplicable";
    requestInfo.request.borrowerInformation.citizenship = "UsCitizen";
    requestInfo.request.borrowerInformation.city = "";
    requestInfo.request.borrowerInformation.country = "USA";
    requestInfo.request.borrowerInformation.dateOfBirth = "";
    requestInfo.request.borrowerInformation.debtToIncomeRatio = 0.01;
    requestInfo.request.borrowerInformation.disclosureDate = "";
    requestInfo.request.borrowerInformation.email = "";
    requestInfo.request.borrowerInformation.employmentDocumentation = "Verified";
    requestInfo.request.borrowerInformation.fico = 720;
    requestInfo.request.borrowerInformation.firstName = "";
    requestInfo.request.borrowerInformation.firstTimeHomeBuyer = false;
    requestInfo.request.borrowerInformation.foreclosure = "";
    requestInfo.request.borrowerInformation.homePhone = "";
    requestInfo.request.borrowerInformation.incomeDocumentation = "Verified";
    requestInfo.request.borrowerInformation.lastName = "";
    requestInfo.request.borrowerInformation.middleName = "";
    requestInfo.request.borrowerInformation.monthlyIncome = 0;
    requestInfo.request.borrowerInformation.monthsReserves = 12;
    requestInfo.request.borrowerInformation.mortgageLatesRolling = 0;
    requestInfo.request.borrowerInformation.mortgageLatesX120 = 0;
    requestInfo.request.borrowerInformation.mortgageLatesX30 = 0;
    requestInfo.request.borrowerInformation.mortgageLatesX60 = 0;
    requestInfo.request.borrowerInformation.mortgageLatesX90 = 0;
    requestInfo.request.borrowerInformation.pledgedAssets = false;
    requestInfo.request.borrowerInformation.bankruptcy = null;
    requestInfo.request.borrowerInformation.selfEmployed = false;
    requestInfo.request.borrowerInformation.ssn = "";
    requestInfo.request.borrowerInformation.state = "";
    requestInfo.request.borrowerInformation.suffix = "";
    requestInfo.request.borrowerInformation.typeOfVeteran = null;
    requestInfo.request.borrowerInformation.vaFirstTimeUse = true;
    requestInfo.request.borrowerInformation.workPhone = "";
    requestInfo.request.borrowerInformation.zipCode = "";
    requestInfo.request.borrowerInformation.numberOfFinancedProperties = 1;
    requestInfo.request.loanInformation.customFieldValues = [];
    requestInfo.request.loanInformation.assetDepletion = false;
    requestInfo.request.loanInformation.autoDebit = false;
    requestInfo.request.loanInformation.automatedUnderwritingSystem = "Any";
    requestInfo.request.loanInformation.baseLoanAmount = 300000.00;
    requestInfo.request.loanInformation.borrowerPaidMi = true;
    requestInfo.request.loanInformation.calculateTotalLoanAmount = true;
    requestInfo.request.loanInformation.cashOutAmount = 0;
    requestInfo.request.loanInformation.communityAffordableSecond = false;
    requestInfo.request.loanInformation.currentServicer = "";
    requestInfo.request.loanInformation.employeeLoan = false;
    requestInfo.request.loanInformation.exemptFromVaFundingFee = false;
    requestInfo.request.loanInformation.prepaymentPenalty = 'None';
    requestInfo.request.loanInformation.expandedGuidelines.bankruptcyOutcome = "NotApplicable";
    requestInfo.request.loanInformation.expandedGuidelines.bankruptcySeasoning = "NotApplicable";
    requestInfo.request.loanInformation.expandedGuidelines.bankruptcyType = "None";
    requestInfo.request.loanInformation.expandedGuidelines.deedInLieuType = "None";
    requestInfo.request.loanInformation.expandedGuidelines.chareOffType = "None";
    requestInfo.request.loanInformation.expandedGuidelines.foreclosureType = "None";
    requestInfo.request.loanInformation.expandedGuidelines.shortSaleType = "None";
    requestInfo.request.loanInformation.expandedGuidelines.forbearanceType = "None";
    requestInfo.request.loanInformation.expandedGuidelines.tradelineType = "Limited";
    requestInfo.request.loanInformation.expandedGuidelines.debtConsolidation = false;
    requestInfo.request.loanInformation.expandedGuidelines.housingEventSeasoning = "NotApplicable";
    requestInfo.request.loanInformation.expandedGuidelines.housingEventType = "None";
    requestInfo.request.loanInformation.expandedGuidelines.incomeVerificationType = "FullDoc";
    requestInfo.request.loanInformation.expandedGuidelines.mortgageLatesx12012Mos = 0;
    requestInfo.request.loanInformation.expandedGuidelines.mortgageLatesx12013To24Mos = 0;
    requestInfo.request.loanInformation.expandedGuidelines.mortgageLatesx3012Mos = 0;
    requestInfo.request.loanInformation.expandedGuidelines.mortgageLatesx3013To24Mos = 0;
    requestInfo.request.loanInformation.expandedGuidelines.mortgageLatesx6012Mos = 0;
    requestInfo.request.loanInformation.expandedGuidelines.mortgageLatesx6013To24Mos = 0;
    requestInfo.request.loanInformation.expandedGuidelines.mortgageLatesx9012Mos = 0;
    requestInfo.request.loanInformation.expandedGuidelines.mortgageLatesx9013To24Mos = 0;
    requestInfo.request.loanInformation.expandedGuidelines.uniqueProperty = null;
    requestInfo.request.loanInformation.expandedGuidelines.bankruptcyMonths = 0;
    requestInfo.request.loanInformation.expandedGuidelines.chargeOffMonths = 0;
    requestInfo.request.loanInformation.expandedGuidelines.deedinLieuMonths = 0;
    requestInfo.request.loanInformation.expandedGuidelines.defaultNoticeMonths = 0;
    requestInfo.request.loanInformation.expandedGuidelines.foreclosureMonths = 0;
    requestInfo.request.loanInformation.expandedGuidelines.loanModificationMonths = 0;
    requestInfo.request.loanInformation.expandedGuidelines.shortSaleMonths = 0;
    requestInfo.request.loanInformation.expandedGuidelines.incomeVerificationMethod = "None";
    requestInfo.request.loanInformation.expandedGuidelines.bankStatementExpenseMethod = "None";
    requestInfo.request.loanInformation.expandedGuidelines.monthsFullDoc = 0;
    requestInfo.request.loanInformation.expandedGuidelines.months1099 = 0;
    requestInfo.request.loanInformation.expandedGuidelines.monthsCpaPandL = 0;
    requestInfo.request.loanInformation.expandedGuidelines.monthsPersonalBankStatements = 0;
    requestInfo.request.loanInformation.expandedGuidelines.monthsBusinessBankStatements = 0;
    requestInfo.request.loanInformation.expandedGuidelines.voeAmount = 0;
    requestInfo.request.loanInformation.expandedGuidelines.assetAmount = 0;
    requestInfo.request.loanInformation.expandedGuidelines.assetDepletionAmount = 0;
    requestInfo.request.loanInformation.expandedGuidelines.investorExperience = "NotApplicable";
    requestInfo.request.loanInformation.expandedGuidelines.propertiesOwned = 0;
    requestInfo.request.loanInformation.externalStatus = 0;
    requestInfo.request.loanInformation.fhaCaseAssigned = null;
    requestInfo.request.loanInformation.fhaCaseEndorsement = null;
    requestInfo.request.loanInformation.intentToOccupy = false;
    requestInfo.request.loanInformation.includeFeesInBasePrice = false;
    requestInfo.request.loanInformation.interestOnlyTerm = 0;
    requestInfo.request.loanInformation.leadSource = 0;
    requestInfo.request.loanInformation.nonOccupyingCoBorrower = false;
    requestInfo.request.loanInformation.lienType = "FirstLien";
    requestInfo.request.loanInformation.loanPurpose = "Refinance";
    requestInfo.request.loanInformation.refinancePurpose = "NoCashOutOther";
    requestInfo.request.loanInformation.loanType = "Conventional";
    requestInfo.request.loanInformation.secondLienAmount = 0;
    requestInfo.request.loanInformation.ltv = 0.6;
    requestInfo.request.loanInformation.cltv = 0.6;
    requestInfo.request.loanInformation.totalLoanAmount = 300000.00;
    requestInfo.request.loanInformation.upfrontPmiMipFfGfAmount = 0;
    requestInfo.request.loanInformation.upfrontPmiMipFfGfFinancedAmount = 0;
    requestInfo.request.loanInformation.upfrontPmiMipFfGfPaidinCash = 0;
    requestInfo.request.loanInformation.upfrontPmiMipFfGfPercent = 0;
    requestInfo.request.loanInformation.waiveEscrows = false;
    requestInfo.request.loanInformation.taxesAndInsuranceMonthly = 0;
    requestInfo.request.loanLevelDebtToIncomeRatio = 0.01;
    requestInfo.request.propertyInformation.appraisedValue = 500000.00;
    requestInfo.request.propertyInformation.isPlannedUnitDevelopment = false;
    requestInfo.request.propertyInformation.city = city || "";
    requestInfo.request.propertyInformation.construction = false;
    requestInfo.request.propertyInformation.corporateRelocation = false;
    requestInfo.request.propertyInformation.county = county || "ORANGE";
    requestInfo.request.propertyInformation.countyFips = countyFips || "06059";
    requestInfo.request.propertyInformation.numberOfStories = 1;
    requestInfo.request.propertyInformation.occupancy = "PrimaryResidence";
    requestInfo.request.propertyInformation.projectType = null;
    requestInfo.request.propertyInformation.propertyStreetAddress = "";
    requestInfo.request.propertyInformation.salesPrice = 500000.00;
    requestInfo.request.propertyInformation.state = state || "ca";
    requestInfo.request.propertyInformation.uniqueDwelling = null;
    requestInfo.request.propertyInformation.zipCode = zip || "92675";
    requestInfo.request.representativeCreditScore = 720;
    requestInfo.request.propertyInformation.constructionMethod = "SiteBuilt";
    requestInfo.request.propertyInformation.attachmentType = 'Detached';
    requestInfo.request.loanInformation.documentationType = 'FullDocumentation';
    requestInfo.request.propertyInformation.numberOfUnits = 'OneUnit';
    requestInfo.request.buydown = 'None';
    requestInfo.request.desiredLockPeriod = 30;
    requestInfo.request.desiredRate = null;
    requestInfo.request.interestOnly = false;
    requestInfo.request.amortizationTypes = ['FixedRate'];
    requestInfo.request.loanTerms = [360];
    requestInfo.request.productTypes = ['StandardProducts'];
    requestInfo.request.loanTypes = [];
    requestInfo.request.desiredPrice = 100;
    requestInfo.request.borrowerInformation.vaFirstTimeUse = true;
    requestInfo.request.borrowerInformation.typeOfVeteran = 'ActiveDuty';
    requestInfo.request.loanLevelDebtToIncomeRatio = 0.01;
    return requestInfo;
  }

  getBusinessChannels = (viewAllOriginators: boolean): Observable<OBChannel[]> => {
    const url = `api/pricing/vendor/OptimalBlue/business-channels?viewAllOriginators=${viewAllOriginators}`;
    return this._dataService.get(url);
  }

  getPricingVendorAccounts = (): Observable<PricingEngineVendorAccount[]> => {
    const url = `api/pricing/vendor/OptimalBlue/accounts`;
    return this._dataService.get(url);
  }

  getEnabledPricingVendor = (provider: string, skipCredentialCheck: boolean = false): Observable<boolean> => {
    let url = `api/integration/${provider}/Pricing/enabled`;
    if (skipCredentialCheck) {
      url += '?skipCredentialCheck=true';
    }
    return this._dataService.get(url);
  }

  getSupportedProviders = (service: string, checkForCredentials: boolean = false): Observable<string[]> => {
    let url = `api/integration/services/${service}/supported-providers`;
    if (checkForCredentials) {
      url += '?checkForCredentials=true';
    }
    return this._dataService.get(url);
  }

  getEnabledPricingVendors(skipCredentialCheck: boolean = false): Observable<PricingVendor[]> {
    const vendors = this._enumerationService.pricingVendors
      .filter(e => e.value != PricingVendor.Unknown && e.value != PricingVendor.Lodasoft)
      .map(e => e.value);

    // LoanPass should always be enabled
    const getEnabledCalls = vendors.filter(v => v !== PricingVendor.LoanPass)
      .map(v => this.getEnabledPricingVendor(v, skipCredentialCheck)
        .pipe(map(enabled => enabled ? v : null)));

    return forkJoin(getEnabledCalls).pipe(
      defaultIfEmpty([]),
      map(enabledVendors => [
        PricingVendor.LoanPass,
        ...enabledVendors.filter(v => v !== null)
      ]),
    );
  }

  getPricingAssignmentCompleteInfoForLenderPrice = (applicationId: number): Observable<{complete: boolean}> => {
    let url = `api/pricing/loan/lender-price-iframe/is-pricing-assignment-complete?applicationId=${applicationId}`;
    return this._dataService.get(url);
  };

  getPricingAssignmentCompleteInfoForPolly = (applicationId: number): Observable<{complete: boolean}> => {
    let url = `api/pricing/loan/polly-iframe/is-pricing-assignment-complete?applicationId=${applicationId}`;
    return this._dataService.get(url);
  };
}
