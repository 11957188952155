import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { DataService } from '../core/services/data.service';
import { CallHistory, CallHistoryFilterRequest } from '../models/voice/call-history.model';
import { CallRecordingDetails } from '../models/voice/call-recording-details.models';
import { ConferenceLine } from '../modules/dialer/models/conference-line.model';
import { ConferenceResponse } from '../modules/dialer/models/conference-response.model';
import { FetchLeadResponse } from '../modules/dialer/models/fetch-lead-response.model';
import { LeadRouterResponse } from '../modules/dialer/models/lead-router-response.model';
import { ManualCallRequest } from '../modules/dialer/models/manual-call-request.model';
import { StartWarmTransferResponse } from '../modules/dialer/models/start-warm-transfer-response.model';
import { StartConferenceResponse, VoiceResponse } from '../modules/dialer/models/voice-response.model';
import { PreviewDialResponse } from '../modules/dialer/models/preview-dial-response';
import { logWithStack } from 'src/utils/debugHelpers';
import { v4 as uuidv4 } from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class VoiceService {

  constructor(private readonly _dataService: DataService) { }

  getCallRecording = (voiceHistoryId: number): Observable<CallRecordingDetails> => {
    return this._dataService.get("api/voice/call/" + voiceHistoryId + "/recording");
  }

  startConference = (conferenceLineId?: number): Observable<StartConferenceResponse> => {
    logWithStack(`calling startConference via api for conf ${conferenceLineId}`);
    let url = "api/voice/conference";
    if (conferenceLineId) {
      url += '?' + _.compact([
        conferenceLineId ? `conferenceLineId=${conferenceLineId}` : ''
      ]).join('&');
    }

    return this._dataService.post(url, {}).pipe(
      tap(r => console.log('startConference resp', r))
    );
  }

  endConference = (conferenceId: number): Observable<VoiceResponse> => {
    logWithStack(`calling endConference via api for conf ${conferenceId}`);
    return this._dataService.post("api/voice/conference/" + conferenceId + "/end", {}).pipe(
      tap(r => console.log('endConference resp', r))
    );
  }

  addParticipantToConference = (conferenceId: number, targetNumber?: string, dialListRecordId?: number, telephonyPoolId?: number): Observable<VoiceResponse> => {
    logWithStack(`calling addParticipantToConference via api for conf ${conferenceId}, targetNumber ${targetNumber}, dialListRecordId ${dialListRecordId}, telephonyPoolId ${telephonyPoolId}`);
    let url = "api/voice/conference/" + conferenceId + "/participant";

    if (targetNumber || dialListRecordId || telephonyPoolId) {
      url += '?' + _.compact([
        targetNumber ? `targetNumber=${targetNumber}` : '',
        dialListRecordId ? `dialListRecordId=${dialListRecordId}` : '',
        telephonyPoolId ? `poolId=${telephonyPoolId}` : ''
      ]).join('&');
    }

    return this._dataService.post(url, {}).pipe(
      tap(r => console.log('addParticipantToConference resp', r))
    );
  }

  removeActiveParticipantFromConference = (conferenceId: number, participantId: number): Observable<any> => {
    logWithStack(`calling removeActiveParticipantFromConference via api for conf ${conferenceId}, participant ${participantId}`);
    return this._dataService.delete("api/voice/conference/" + conferenceId + "/participant/" + participantId)
      .pipe(
        catchError(err => {
          if (err.indexOf("Unable to find conference line that this conference belongs to.") > -1) {
            return of(true);
          }
          else {
            throw err;
          }
        }))
  }

  changeConferenceStatus = (activeConferenceId: number, activeParticipantId: number, status: 'hold' | 'unhold' | 'mute' | 'unmute'): Observable<any> => {
    logWithStack(`calling changeConferenceStatus via api for conf ${activeConferenceId}, participant ${activeParticipantId}, status ${status}`);
    return this._dataService.post("api/voice/conference/" + activeConferenceId + "/participantId/" + activeParticipantId + "/" + status, {}).pipe(
      tap(r => console.log('changeConferenceStatus resp', r))
    );
  }

  fetchConferenceLead = (activeConferenceId: number, telephonyPoolId?: number): Observable<FetchLeadResponse> => {
    let url = "api/voice/conference/" + activeConferenceId + "/fetch-queued";

    if (telephonyPoolId) {
      url += `?telephonyPoolId=${telephonyPoolId}`
    }

    return this._dataService.post(url, {})
      .pipe(
        map(response => {
          if (!response.dialRecord) {
            throw new Error("No Lead Found");
          }
          else {
            return response;
          }
        }))
  }

  cancelWarmTransfer = (activeConferenceId: number, participantId: number): Observable<any> => {
    logWithStack(`calling cancelWarmTransfer via api for ${activeConferenceId}, participant ${participantId}`);
    return this._dataService.post("api/voice/conference/" + activeConferenceId + "/warm-transfer/cancel", {}).pipe(
      tap(r => console.log('cancelWarmTransfer resp', r))
    );
  }

  publishWarmTransfer = (activeConferenceId: number): Observable<LeadRouterResponse> => {
    return this._dataService.post("api/voice/conference/" + activeConferenceId + "/warm-transfer/publish", {});
  }

  startWarmTransfer = (voiceHistoryId: number, leadRouteHistoryId: number): Observable<StartWarmTransferResponse> => {
    return this._dataService.post(`api/voice/${voiceHistoryId}/warm-transfer/start/${leadRouteHistoryId}`, {});
  }

  completeWarmTransfer = (activeConferenceId: number): Observable<ConferenceResponse> => {
    return this._dataService.post(`api/voice/conference/${activeConferenceId}/warm-transfer/complete`, {}).pipe(
      tap(r => console.log('completeWarmTransfer resp', r))
    );
  }

  publishWarmTransferNew = (voiceHistoryId: number): Observable<LeadRouterResponse> => {
    return this._dataService.post("api/voice/voip/warm-transfer/publish/" + voiceHistoryId, {});
  }

  cancelWarmTransferNew = (voiceHistoryId: number): Observable<StartWarmTransferResponse> => {
    return this._dataService.post(`api/voice/voip/warm-transfer/cancel/${voiceHistoryId}`, {});
  }

  startWarmTransferNew = (leadRouteHistoryId: number): Observable<StartWarmTransferResponse> => {
    return this._dataService.post(`api/voice/voip/warm-transfer/start/${leadRouteHistoryId}`, {});
  }

  getActiveConference = (): Observable<ConferenceLine> => {
    return this._dataService.get("api/voice/conference/active").pipe(
      tap(r => console.log('getActiveConference resp', r))
    );
  }

  insertCallToHistory = (manualCallRequest: ManualCallRequest): Observable<number> => {
    return this._dataService.post("api/voice/history/insert-from-call", manualCallRequest);
  }

  placeCallOnHold = (callSid: string): Observable<any> => {
    return this._dataService.post(`api/voice/voip/hold/${callSid}`, {});
  }

  resumeOnHoldCall = (callSid: string): Observable<any> => {
    return this._dataService.post(`api/voice/voip/unhold/${callSid}`, {});
  }

  // manually calls a number
  manualCallNumber = (manualCallRequest: ManualCallRequest): Observable<VoiceResponse> => {
    return this._dataService.post("api/voice/call", manualCallRequest);
  }

  getCallHistory = (requestFilter: CallHistoryFilterRequest): Observable<CallHistory[]> => {
    return this._dataService.post("api/voice/history/search", requestFilter);
  }

  previewFetchQueued = (params?: { leadsOnly?: boolean }): Observable<PreviewDialResponse> => {
    const url = "api/voice/conference/preview-fetch-queued";
    const options = params?.leadsOnly !== undefined ? { params: { leadsOnly: params.leadsOnly } } : {};
    return this._dataService.post(url,options);
  };

  getAccessToken = (deviceId?: string): Observable<string> => {
    if (!deviceId) {
      deviceId = uuidv4();
    }

    let url = "api/voice/access-token?deviceId=" + deviceId;
    return this._dataService.post(url, {}).pipe(map(data => data.token));
  }

}
