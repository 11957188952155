import { Component, OnInit, Input, Output, EventEmitter, QueryList, ViewChildren, ViewChild, OnDestroy } from '@angular/core';
import { PricingScenario } from '../../models/pricing/pricing-scenario.model';
import { ProductSearchRequest } from '../../models/pricing/product-search-request-info.model';
import { NotificationService } from 'src/app/services/notification.service';
import { FeeService } from 'src/app/services/fee.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NewApplicationDialogComponent } from 'src/app/modules/new-application/components/new-application-dialog/new-application-dialog.component';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { PricingService } from '../../services/pricing.service';
import { Constants } from 'src/app/services/constants';
import { Price, PricedProductRow } from '../../models/pricing/priced-product-row.model';
import { PriceCell } from '../../models/pricing/price-cell.model';
import { PricedProduct } from '../../models/pricing/product-pricing-detail.model';
import { ProductDetailDialogComponent } from '../product-detail-dialog/product-detail-dialog.component';
import { SelectedPriceCardViewModel } from '../../models/pricing/selected-price-card-view.model';
import { Quote } from '../../models/pricing/pricing-quote.model';
import { ScenarioFeesDialogComponent } from 'src/app/modules/fees/scenario-fees-dialog/scenario-fees-dialog.component';
import { PinnedScenarioCardComponent } from '../pinned-scenario-card/pinned-scenario-card.component';
import { InitialFeesWorksheetDialog } from '../initial-fees-worksheet-dialog/initial-fees-worksheet-dialog.component';
import { Carousel } from 'primeng/carousel';
import { LoanFee } from 'src/app/models/fee/fee.model';
import { ApplicationContextService } from 'src/app/services/application-context.service';
import { Subscription } from 'rxjs';
import { ProductPricingDetails, UpfrontCosts } from '../../models/pricing/product-pricing-details.model';

@Component({
  selector: 'pinned-scenarios',
  templateUrl: 'pinned-scenarios.component.html',
  styleUrls: ['./pinned-scenarios.component.scss']
})
export class PinnedScenariosComponent implements OnInit, OnDestroy {

  @ViewChild(Carousel)
  carousel: Carousel;

  @ViewChildren("scenarioCard")
  scenarioCards: QueryList<PinnedScenarioCardComponent>;

  @Output()
  refreshAllFinished = new EventEmitter<boolean>();

  @Output()
  scenarioDeleted = new EventEmitter<PricingScenario[]>();

  @Output()
  applyScenarioToLoanClicked = new EventEmitter<PricingScenario>();

  @Input()
  applicationId: number | null;

  @Input()
  isLoanReadOnly: boolean = false;

  @Input()
  set scenarios(scenarios: PricingScenario[]) {
    this._scenarios = scenarios;
    for (let i = 0; i <= this.scenarios.length - 1; i++) {
      this._scenarios[i]['index'] = i + 1;
    }
  }

  get scenarios(): PricingScenario[] {
    return this._scenarios;
  }

  responsiveOptions: any;

  isCarouselViewLoading: boolean = false;
  turnOffPrimeNgFeaturesDueToHtmlEditorPrimeNgConflict: boolean = false;

  private _scenarios: PricingScenario[] = [];

  private readonly _modalOptions: NgbModalOptions;

  private _numberOfCardsThatAreRefreshing: number = 0;

  private _sendEmailDialogOpenedEventSubscription: Subscription;
  private _sendEmailDialogClosedEventSubscription: Subscription;

  constructor(private readonly _feeService: FeeService,
    private readonly _notifyService: NotificationService,
    private readonly _spinnerService: NgxSpinnerService,
    private readonly _modalService: NgbModal,
    private readonly _applicationContextService: ApplicationContextService,
    private readonly _pricingService: PricingService) {

    this._sendEmailDialogOpenedEventSubscription = this._applicationContextService.sendEmailDialogOpenedEvent.subscribe(() => {
      this.turnOffPrimeNgFeaturesDueToHtmlEditorPrimeNgConflict = true;
    });

    this._sendEmailDialogClosedEventSubscription = this._applicationContextService.sendEmailDialogClosedEvent.subscribe(() => {
      this.turnOffPrimeNgFeaturesDueToHtmlEditorPrimeNgConflict = false;
      this.reRender();
    });

    this._modalOptions = {
      size: 'xl',
      backdrop: 'static',
      centered: true,
      scrollable: true,
      windowClass: 'modal-fullscreen',
      modalDialogClass: 'modal-fullscreen'
    };
    this.responsiveOptions = [
      {
        breakpoint: '1790px',
        numVisible: 5,
        numScroll: 5
      },
      {
        breakpoint: '1511px',
        numVisible: 4,
        numScroll: 4
      },
      {
        breakpoint: '1234px',
        numVisible: 3,
        numScroll: 3
      },
      {
        breakpoint: '1029px',
        numVisible: 2,
        numScroll: 2
      },
      {
        breakpoint: '684px',
        numVisible: 1,
        numScroll: 1
      }
    ];
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this._sendEmailDialogOpenedEventSubscription?.unsubscribe();
    this._sendEmailDialogClosedEventSubscription?.unsubscribe();
  }

  refreshAllScenarios = () => {
    this._numberOfCardsThatAreRefreshing = this.scenarioCards.length;
    const components: any[] = this.scenarioCards.toArray();
    components.forEach(c => {
      c.refresh();
    })
  }

  reRender = () => {
    this.isCarouselViewLoading = true;
    setTimeout(() => {
      this.isCarouselViewLoading = false;
    }, 100);
  }

  onCreateLoanClicked = (scenarioId: number) => {
    const modalRef = this._modalService.open(NewApplicationDialogComponent, this._modalOptions);
    modalRef.componentInstance.scenarioId = scenarioId;
    modalRef.result.then(() => {
    }, err => {
    })
  }

  onApplyScenarioToLoanClicked = (pricingScenario: PricingScenario) => {
    this.applyScenarioToLoanClicked.emit(pricingScenario);
  }

  onCardRefreshCompleted = (refreshedScenario: PricingScenario) => {
    const olderScenarioIndex = this._scenarios.findIndex(s => s.pricingScenarioId === refreshedScenario.pricingScenarioId);
    if (olderScenarioIndex >= 0) {
      this._scenarios[olderScenarioIndex] = refreshedScenario;
    }
    this._numberOfCardsThatAreRefreshing -= 1;
    if (this._numberOfCardsThatAreRefreshing === 0) {
      this.refreshAllFinished.emit();
    }
  }

  onDeleteScenarioClicked = (scenarioId: number) => {
    const self = this;
    Swal.fire({
      title: 'Are you sure?',
      text: 'Are you sure you\'d like to delete this pinned scenario?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes, continue!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true
    }).then(function (result: any) {
      if (result.value) {
        let index = self.scenarios.findIndex((item: PricingScenario) => item.pricingScenarioId === scenarioId);
        if (index > -1) {
          self._scenarios.splice(index, 1);
          self.scenarioDeleted.emit(self._scenarios);
          self._pricingService.deletePricingScenarios(scenarioId).subscribe(() => {
            self._notifyService.showSuccess("Scenario removed succesfully", "Success");
            self.reRender();
          }, (err: any) => {
            self._notifyService.showError(err.error.message || "Error encountered while deleting scenario", "Error!");
          });

        }
      }
    });
  }

  onScenarioEditClicked = (scenario: PricingScenario) => {
    this._spinnerService.show();
    this._pricingService.savePricingScenario(scenario).subscribe((result) => {
      if (result) {
        this._notifyService.showSuccess("Scenario name changed successfully", "Success");
      }
    }, err => {
      this._notifyService.showError(err.error.message || "An error occurred while renaming the Scenario", "Error!");
    }).add(() => this._spinnerService.hide());
  }

  onLoanQuoteClicked = (scenario: PricingScenario) => {
    this.turnOffPrimeNgFeaturesDueToHtmlEditorPrimeNgConflict = true;
    const modalRef = this._modalService.open(InitialFeesWorksheetDialog, Constants.modalOptions.xlarge);
    modalRef.componentInstance.scenario = scenario;
    modalRef.componentInstance.appId = this.applicationId;
    modalRef.componentInstance.fees = scenario['fees'];

    modalRef.result.then(() => {
      this.turnOffPrimeNgFeaturesDueToHtmlEditorPrimeNgConflict = false;
    }, err => {
      this.turnOffPrimeNgFeaturesDueToHtmlEditorPrimeNgConflict = false;
      this.reRender();
    });
  }

  onFeesEditClicked = (scenario: PricingScenario) => {
    this._feeService.getScenarioFees(scenario.pricingScenarioId).subscribe(scenarioFees => {
      if (scenario.applicationId && (!scenarioFees || scenarioFees.length === 0)) {
        this.showFeesEditor([], scenario);
      } else {
        this.showFeesEditor(scenarioFees, scenario);
      }
    });
  }

  onRateAprEditClicked = (scenario: PricingScenario) => {
    this._spinnerService.show();
    const pricingRequest: ProductSearchRequest = scenario.pricingRequestPayload;
    this._pricingService.searchProductsByVendor(scenario.pricingVendor, pricingRequest).subscribe((searchResultsForAllProducts) => {

      pricingRequest.thirdPartySearchId = searchResultsForAllProducts.searchId;;
      pricingRequest.productIds = [scenario.productId];
      pricingRequest.optimalBlueChannelId = pricingRequest.optimalBlueChannelId;

      const upfrontCosts = {
        totalLoanAmount: searchResultsForAllProducts.calculatedTotalLoanAmount,
        pmiMipFfGfAmount: searchResultsForAllProducts.calculatedUpfrontPmiMipFfGfAmount,
        pmiMipFfGfFinancedAmount: searchResultsForAllProducts.calculatedUpfrontPmiMipFfGfFinancedAmount,
        pmiMipFfGfPaidinCash: searchResultsForAllProducts.calculatedUpfrontPmiMipFfGfPaidinCash,
        pmiMipFfGfPercent: searchResultsForAllProducts.calculatedUpfrontPmiMipFfGfPercent
      };

      const totalLoanAmount = pricingRequest.loanInformation.baseLoanAmount +
        (pricingRequest.loanInformation.upfrontPmiMipFfGfFinancedAmount ? pricingRequest.loanInformation.upfrontPmiMipFfGfFinancedAmount : 0);
      this._pricingService.getProductPricingDetails(scenario.pricingVendor, pricingRequest).subscribe(refreshedPricingForProduct => {
        const selectedProduct = new PricedProductRow(
          refreshedPricingForProduct,
          [new Price(scenario.adjustedRate, scenario.adjustedPrice, scenario.lockTerm, scenario.principalAndInterest, 
            scenario.monthlyMi, scenario.monthlyTaxes + scenario.monthlyInsurance, scenario.discountPercent, 
            scenario.discountDollars, scenario.rebatePercent, scenario.rebateDollars)],
          true,
          totalLoanAmount,
          'Available'
        )

        const priceCells = refreshedPricingForProduct.quotes.map(
          (q) =>
            new PriceCell(
              q.adjustedRate,
              q.adjustedPrice,
              q.lockPeriod,
              q.principalAndInterest,
              q.monthlyMi,
              q.discountDollars,
              q.discountPercent,
              q.rebateDollars,
              q.rebatePercent,
              q.totalClosingCost,
              q.totalPayment,
              q.adjustments
            )
        );
        const pricesForProduct = new ProductPricingDetails(
          priceCells,
          refreshedPricingForProduct.notesAndAdvisories,
          refreshedPricingForProduct.quotes[0].adjustments,
          refreshedPricingForProduct.calculatedValues
        );
        this.showProductPricingDetailsDialog(
          scenario,
          refreshedPricingForProduct,
          selectedProduct,
          pricesForProduct,
          pricingRequest,
          upfrontCosts
        );
      }, err => {

      }).add(() => this._spinnerService.hide());
    }, err => {

    }).add(() => this._spinnerService.hide());
  }

  private showFeesEditor = (fees: LoanFee[], scenario: PricingScenario) => {
    const modalRef = this._modalService.open(ScenarioFeesDialogComponent, Constants.modalOptions.fullScreen);
    modalRef.componentInstance.scenarioId = scenario.pricingScenarioId;
    // Do not forget to give the modal templates as well, when they are available.
    modalRef.componentInstance.fees = fees;

    modalRef.result.then(() => {
      this._pricingService.getPricingScenario(scenario.pricingScenarioId).subscribe(updatedScenario => {
        const index = this._scenarios.findIndex(s => s.pricingScenarioId === scenario.pricingScenarioId);
        updatedScenario['index'] = index + 1;
        this._scenarios[index] = updatedScenario;
        this._scenarios = [...this._scenarios];
      });
    }, err => { });
  }

  private showProductPricingDetailsDialog = (
    scenarioToUpdate: PricingScenario,
    pricedProduct: PricedProduct,
    selectedProduct: PricedProductRow,
    productPricingDetails: ProductPricingDetails,
    searchRequest: ProductSearchRequest,
    upfrontCosts: UpfrontCosts
  ) => {
    const modalRef = this._modalService.open(ProductDetailDialogComponent, Constants.modalOptions.fullScreen);
    modalRef.componentInstance.isSingleSelection = true;
    modalRef.componentInstance.selectedProduct = selectedProduct;
    modalRef.componentInstance.taxesAndInsurance = searchRequest.loanInformation.taxesAndInsuranceMonthly || searchRequest.taxesAndInsurance;
    modalRef.componentInstance.notesAndAdvisories = productPricingDetails.notesAndAdvisories;
    modalRef.componentInstance.adjustments = productPricingDetails.adjustments;
    modalRef.componentInstance.prices = productPricingDetails.prices;

    upfrontCosts.totalLoanAmount = (productPricingDetails.calculatedValues && productPricingDetails.calculatedValues.totalLoanAmount) ?
      productPricingDetails.calculatedValues.totalLoanAmount : upfrontCosts.totalLoanAmount;
    upfrontCosts.pmiMipFfGfAmount = (productPricingDetails.calculatedValues && productPricingDetails.calculatedValues.upfrontPmiMipFfGfAmount) ?
      productPricingDetails.calculatedValues.upfrontPmiMipFfGfAmount : upfrontCosts.pmiMipFfGfAmount;
    upfrontCosts.pmiMipFfGfFinancedAmount = (productPricingDetails.calculatedValues && productPricingDetails.calculatedValues.upfrontPmiMipFfGfFinancedAmount) ?
      productPricingDetails.calculatedValues.upfrontPmiMipFfGfFinancedAmount : upfrontCosts.pmiMipFfGfFinancedAmount;
    upfrontCosts.pmiMipFfGfPaidinCash = (productPricingDetails.calculatedValues && productPricingDetails.calculatedValues.upfrontPmiMipFfGfPaidinCash) ?
      productPricingDetails.calculatedValues.upfrontPmiMipFfGfPaidinCash : upfrontCosts.pmiMipFfGfPaidinCash;
    upfrontCosts.pmiMipFfGfPercent = (productPricingDetails.calculatedValues && productPricingDetails.calculatedValues.upfrontPmiMipFfGfPercent) ?
      productPricingDetails.calculatedValues.upfrontPmiMipFfGfPercent : upfrontCosts.pmiMipFfGfPercent;

    modalRef.componentInstance.upfrontCosts = upfrontCosts;

    modalRef.result.then(
      (selectedPrices: SelectedPriceCardViewModel[]) => {
        if (selectedPrices) {

          const selectedPrice = selectedPrices[0];
          const selectedQuote = pricedProduct.quotes.find(q => q.adjustedRate === selectedPrice.rate && q.lockPeriod === selectedPrice.lockPeriod);

          const updatedScenario = this.updatePricingScenario(scenarioToUpdate, pricedProduct, selectedQuote);
          updatedScenario.pricingScenarioId = scenarioToUpdate.pricingScenarioId;

          this._spinnerService.show();
          this._pricingService.savePricingScenario(updatedScenario).subscribe(savedScenario => {
            const index = this.scenarios.findIndex(s => s.pricingScenarioId === scenarioToUpdate.pricingScenarioId);
            if (index >= 0) {
              savedScenario['index'] = index + 1;
              this.scenarios[index] = savedScenario;
              const scenarioCardToRefresh: PinnedScenarioCardComponent = this.scenarioCards.toArray()
                .find(c => c.scenario.pricingScenarioId === savedScenario.pricingScenarioId);
              if (scenarioCardToRefresh) {
                scenarioCardToRefresh.reloadScenario();
              }
            }
          }, error => {

          }).add(() => this._spinnerService.hide());
        };
      },
      (res) => { }
    );
  }

  private updatePricingScenario = (pricingScenarioToUpdate: PricingScenario, product: PricedProduct, price: Quote): PricingScenario => {
    pricingScenarioToUpdate.applicationId = this.applicationId ? this.applicationId : null;
    pricingScenarioToUpdate.rateSheetDate = product.rateSheetDate;
    pricingScenarioToUpdate.productId = product.productId;
    pricingScenarioToUpdate.productName = product.productName;
    pricingScenarioToUpdate.investorId = product.investorId;
    pricingScenarioToUpdate.lockTerm = price.lockPeriod;
    pricingScenarioToUpdate.adjustedRate = price.adjustedRate;
    pricingScenarioToUpdate.adjustedPrice = price.adjustedPrice;
    pricingScenarioToUpdate.margin = price.margin;
    pricingScenarioToUpdate.principalAndInterest = price.principalAndInterest;
    pricingScenarioToUpdate.monthlyMi = price.monthlyMi;
    pricingScenarioToUpdate.totalPayment = price.totalPayment;
    pricingScenarioToUpdate.totalClosingCost = price.totalClosingCost;
    pricingScenarioToUpdate.discountDollars = price.discountDollars;
    pricingScenarioToUpdate.discountPercent = price.discountPercent;
    pricingScenarioToUpdate.rebateDollars = price.rebateDollars;
    pricingScenarioToUpdate.rebatePercent = price.rebatePercent;
    pricingScenarioToUpdate.baseRate = price.baseRate;
    pricingScenarioToUpdate.basePrice = price.basePrice;
    pricingScenarioToUpdate.totalPriceAdjustment = price.totalPriceAdjustment;
    pricingScenarioToUpdate.totalRateAdjustment = price.totalRateAdjustment;
    pricingScenarioToUpdate.totalMarginAdjustment = price.totalMarginAdjustment;
    pricingScenarioToUpdate.totalSrpAdjustment = price.totalSrpAdjustment;
    pricingScenarioToUpdate.apr = price.apr;
    pricingScenarioToUpdate.monthlyInsurance = price.monthlyInsurance;
    pricingScenarioToUpdate.monthlyTaxes = price.monthlyTaxes;
    pricingScenarioToUpdate.loCompensationDollars = price.loCompensationDollars;
    pricingScenarioToUpdate.loCompensationPercent = price.loCompensationPercent;
    pricingScenarioToUpdate.borrowerPaidClosingCostDollars = price.borrowerPaidClosingCostDollars;
    pricingScenarioToUpdate.currentFeeCreditDollar = price.currentFeeCreditDollar;
    pricingScenarioToUpdate.totalFeesDollars = price.totalFeesDollars;
    pricingScenarioToUpdate.totalFeesPercent = price.totalFeesPercent;
    pricingScenarioToUpdate.originationDollars = price.originationDollars;
    pricingScenarioToUpdate.originationPercent = price.originationPercent;
    pricingScenarioToUpdate.lenderFeesDollars = price.lenderFeesDollars;
    pricingScenarioToUpdate.lenderFeesPercent = price.lenderFeesPercent;
    pricingScenarioToUpdate.thirdPartyFeesDollars = price.thirdPartyFeesDollars;
    pricingScenarioToUpdate.thirdPartyFeesPercent = price.thirdPartyFeesPercent;
    pricingScenarioToUpdate.qmStatus = price.qmStatus;
    pricingScenarioToUpdate.adjustments = price.adjustments;
    pricingScenarioToUpdate.pricingLastUpdated = price.pricingLastUpdated;

    return pricingScenarioToUpdate;
  }
}
