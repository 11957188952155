import { Component, ElementRef, EventEmitter, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm, NgModel, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription, firstValueFrom, forkJoin } from 'rxjs';
import { CreditVendor, CreditVendorEnum, LoanApplication, ThirdPartyCredential, UserType } from 'src/app/models';
import { CreditTechnicalAffiliate } from 'src/app/models/credit/credit-technical-affiliate.model';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { TpoCreditPermissions } from 'src/app/modules/admin/tpo-config/models/tpo-configuration.model';
import { TpoConfigService } from 'src/app/modules/admin/tpo-config/services/tpo-config.service';
import { CreditModalComponent } from 'src/app/modules/system-level/components/integrations/credit/credit-modal/credit-modal.component';
import { UrlaMortgage } from 'src/app/modules/urla/models/urla-mortgage.model';
import { Constants } from 'src/app/services/constants';
import { LoanServicesService } from 'src/app/services/loan';
import { LoanDocService } from 'src/app/services/loan-doc.service';
import { LosService } from 'src/app/services/los.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ThirdPartyCredentialsService } from 'src/app/services/third-party-credentials.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import Swal from 'sweetalert2';
import { CreditBorrower } from '../../../credit/models/credit-borrower.model';
import { CreditCardType, CreditPullType, CreditRequest, PaymentInfo } from '../../../credit/models/credit-request.model';
import { CreditThirdPartyCredential } from '../../../credit/models/credit-third-party-credential.model';
import { ServicesPermissions } from '../../../credit/models/services-permissions.model';
import { CreditService } from '../../../credit/services/credit.service';
import { CreditUpgradeRequest } from '../../models/credit-upgrade-request.model';
import { SpinnerComponent } from 'src/app/shared/components/spinner/spinner.component';
import { CreditCredentialsConfigurationDialogComponent } from 'src/app/modules/credit-credentials-configuration/credit-credentials-configuration-dialog/credit-credentials-configuration-dialog.component';

@Component({
  selector: 'loan-credit-run',
  templateUrl: './loan-credit-run.component.html',
  styleUrls: ['./loan-credit-run.component.scss'],
})
export class LoanCreditRunComponent
  extends ApplicationContextBoundComponent
  implements OnInit
{
  @ViewChild('spinner')
  spinner: SpinnerComponent;

  @Input()
  public set borrowers(borrowers: CreditBorrower[]) {
    this.groupBorrowersByApplicationIndexAndDetermineIfTheyAreJointOrIndividualBasedOnLatestCreditRefNumbers(
      borrowers
    );
  }

  @Input()
  set creditVendors(creditVendors: CreditThirdPartyCredential[]) {
    this._creditVendors = creditVendors;
    this.prepareCreditVendorsDropdown();
  }

  get creditVendors(): CreditThirdPartyCredential[] {
    return this._creditVendors;
  }

  @Input()
  application: LoanApplication;

  @Input()
  servicesPermissions: ServicesPermissions;

  @Input()
  mortgage?: UrlaMortgage;

  @Input()
  isLoanReadOnly: boolean = false;

  @Output()
  creditRunCompleted: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  reissueSucceeded: EventEmitter<number> = new EventEmitter<number>();

  @Output()
  importLiabilitiesClicked: EventEmitter<number> = new EventEmitter<number>();

  @Output()
  creditVendorChange = new EventEmitter<CreditVendorEnum>();

  @ViewChild('loanCreditRunForm')
  creditRunForm: NgForm | undefined;

  @ViewChild('creditVendorComponent')
  creditVendorComponent: ElementRef<HTMLSelectElement>;

  @ViewChild('ccExpirationDate')
  ccExpirationDate: NgModel;

  reissue: boolean = false;
  pullingCreditReport: boolean = false;
  isPullEnabled: boolean = true;
  isLinkedToLos: boolean = true;
  showRunCredit: boolean = false;
  isSubmitSuccess: boolean = false;
  isSubmitError: boolean = false;
  creditRunClicked: boolean = false;
  showSoftPullReportType: boolean = false;

  warnMessage: string;
  actionButtonLabel: string;

  errorMessages: string[] = [];
  validationErrors: string[] = [];

  request: CreditRequest = new CreditRequest();

  creditCardMask: string;
  companyId: number;

  isReissueOnly: boolean;

  runCreditLabel: string = '';

  enterPaymentInfo: boolean = false;

  issueType: string;

  reportTypes: EnumerationItem[] = [];

  technicalAffiliates: Array<CreditTechnicalAffiliate>;

  protected borrowersGroupedByAppIndex: Map<number, CreditBorrower[]> =
    new Map();
  protected borrowerIsJointMap: Map<number, boolean> = new Map();
  protected appIndexes: number[] = [];

  protected creditRefNumberPattern = {
    A: { pattern: new RegExp('^[a-zA-Z0-9]+$') },
  };

  private _creditVendors: CreditThirdPartyCredential[] = [];

  protected selectedBorrowersToPullCreditFor: CreditBorrower[] = [];

  protected pullCreditIndividualOrJointButtonLabelsPerApplicationIndex: Map<
    number,
    string
  > = new Map();

  protected needHardSoftPullSelection: boolean = false;

  protected isTPO: boolean = false;
  protected isCompanyDeephaven: boolean = false;

  private _applicationContextSubscription: Subscription;

  constructor(
    injector: Injector,
    private readonly _creditService: CreditService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _losService: LosService,
    private readonly _loanDocService: LoanDocService,
    private readonly _notifyService: NotificationService,
    private readonly _tpoConfigService: TpoConfigService,
    private readonly _modalService: NgbModal,
    private readonly _loanServicesService: LoanServicesService,
    private readonly _thirdPartyCredentialsService: ThirdPartyCredentialsService
  ) {
    super(injector);
  }

  ngOnInit() {
    this.loadTechnicalAffiliates();
    this.companyId = this.applicationContext.userPermissions.companyId;
    for (const borrowers of this.borrowersGroupedByAppIndex.values()) {
      borrowers.forEach((borrower) => {
        borrower['highlightScore'] =
          this.getCreditBureauToHighlightScoreFor(borrower);
      });
    }

    this.isReissueOnly =
      this.servicesPermissions.creditPermissions === 'ReissueOnly';

    if (this.isReissueOnly) {
      this.reissue = true;
      this.issueType = 'Reissue';
    }
    this.updateActionButtonLabel();

    this.isPullEnabled = this.servicesPermissions.isPullEnabled;
    this.isLinkedToLos = this.servicesPermissions.isLinkedToLos;

    const isUserProfileSet = this.creditVendors.some((cV) => cV.userId);
    if (isUserProfileSet) {
      this.creditVendors.forEach((el) => {
        const exist = this.creditVendors.filter(
          (cV) => cV.vendorName === el.vendorName
        );
        if (!exist) this.creditVendors.push(el);
      });
    }

    this.request = {
      ...this.request,
      borrowerIds: [],
      applicationId: this.application.applicationId,
      pullType: CreditPullType.Individual,
      experian: true,
      equifax: true,
      transUnion: true,
    };

    if (!this.isPullEnabled) {
      this.warnMessage =
        'To enable pull credit reports please link your loan application to LOS.';
    }

    this._applicationContextSubscription =
      this.applicationContextService.context.subscribe((ctx) => {
        this.isTPO = ctx.isTpo;
        this.isCompanyDeephaven = ctx.isCompanyDeepHaven;

        if (ctx.isTpo) {
          this.setReissueFieldForTpo();
        }
      });

    this.getMaskType(this.request.paymentInfo.ccType);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this._applicationContextSubscription?.unsubscribe();
  }

  protected onPullCreditForBorrowersClicked = (borrowers: CreditBorrower[]) => {
    this.selectedBorrowersToPullCreditFor = borrowers;
    if (borrowers.length > 1) {
      this.prepareUIForJointCreditPull(borrowers);
    } else {
      this.prepareUIForIndividualCreditPullForBorrower(borrowers[0]);
    }
  };

  protected onPullIndividualCreditForBorrowerClicked = (
    borrower: CreditBorrower
  ) => {
    this.selectedBorrowersToPullCreditFor = [borrower];
    this.prepareUIForIndividualCreditPullForBorrower(borrower);
  };

  protected onReissueToggleChanged = (borrowers: CreditBorrower[]) => {
    this.reissue = !this.reissue;
    this.updateActionButtonLabel();
  };

  protected onIssueTypeChanged = () => {
    this.reissue = this.issueType == 'Reissue';
    this.updateActionButtonLabel();
  };

  protected onOpenReportClicked = (integrationHistoryId: number) => {
    this.closeErrors();
    this._spinner.show();
    this._creditService.getReportContent(integrationHistoryId).subscribe(
      (data) => {
        this._spinner.hide();
        const blob = new Blob([data], { type: 'text/html' });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      },
      (error) => {
        this._spinner.hide();
        this.showError(error.message);
      }
    );
  };

  protected onReissueClicked = (integrationHistoryId: number) => {
    this.closeErrors();
    this._spinner.show();
    this._creditService
      .reissueCreditReport(integrationHistoryId)
      .subscribe(
        (result) => {
          this._spinner.hide();
          if (result.success) {
            this.isSubmitSuccess = true;
            this.request.creditRequestType = 'Reissue';
            this.reissueSucceeded.emit(integrationHistoryId);
          }
        },
        (error) => {
          this._spinner.hide();
          this.showError(error.message);
        }
      )
      .add(() => {
        this.pullingCreditReport = false;
      });
  };

  protected onPullCreditClicked = () => {
    this.creditRunClicked = true;
    if (!this.creditRunForm) return;
    this.creditRunForm.form.markAllAsTouched();
    if (!this.creditRunForm.form.valid) return;

    this.closeErrorsAndPullCredit();
  };

  protected closeErrors = () => {
    this.isSubmitSuccess = false;
    this.isSubmitError = false;
    this.validationErrors = [];
    this.errorMessages = [];
  };

  protected onOpenLoanDocClicked = (loanDocId: number) => {
    this.closeErrors();
    this._spinner.show();
    this._loanDocService.getLoanDoc(loanDocId).subscribe(
      (result) => {
        if (result && result.docFiles.length) {
          let fileGuid = result.docFiles[0].guid;
          let mimeType = result.docFiles[0].mimeType;
          this._loanDocService.viewFile(fileGuid).subscribe((data) => {
            this._spinner.hide();
            const blob = new Blob([data], {
              type: mimeType || 'application/pdf',
            });
            const url = window.URL.createObjectURL(blob);
            window.open(url);
          });
        } else {
          this.showError('No File exist.');
        }
      },
      (error) => {
        this.showError(error.errorMessage);
      }
    );
  };

  protected onSelectedCreditCredentialIdChanged = async (
    credentialId: number | null
  ): Promise<void> => {
    if (credentialId === -1) {
      this.request.creditVendor = '';
      this.request.credentialId = null;
      this.addCreditVendor();
      return;
    }

    // Allow selecting Encompass vendor only if the application is linked to
    // Encompass and has an LOS identifier.
    const creditVendor = this._creditVendors.find(
      (cv) => cv.credentialId === credentialId
    );

    const creditVendorName = creditVendor.vendorName as CreditVendorEnum;

    this.request.importLiabs = creditVendorName === CreditVendorEnum.EncompassCredit;

    this.needHardSoftPullSelection = false;
    if (
      creditVendorName === CreditVendorEnum.MeridianLinkHardPull ||
      creditVendorName === CreditVendorEnum.MeridianLinkSoftPull
    ) {
      if (
        creditVendor.thirdPartyKeyValuePairs.find(
          (kvp) => kvp.key === 'TechnicalAffiliate'
        )?.value === '5'
      ) {
        this.needHardSoftPullSelection = true;
      }
    }

    this.request.creditVendor = creditVendorName;

    if (creditVendorName === CreditVendorEnum.EncompassCredit) {
      const application = this.application;
      if (!application.losIdentifier) {
        let created = false;
        if (this.applicationContext.userPermissions.userType == UserType.Tpo) {
          created = await this.autoCreateLosLoan();
        }

        if (!created) {
          if (!this.applicationContext.isTpo)
            await this.showCantSelectEncompassVendorDialog().catch(
              console.error
            );
          else this.isSubmitError = true;
          return;
        }
      }
    }

    this.creditVendorChange.emit(creditVendorName);

    this.setDefaultBureaus();

    if (
      ((creditVendorName == CreditVendorEnum.CredCoHardPull ||
        creditVendorName == CreditVendorEnum.CredCoSoftPull ||
        creditVendorName == CreditVendorEnum.FactualDataHardPull ||
        creditVendorName == CreditVendorEnum.FactualDataSoftPull) &&
        !!this.selectedBorrowersToPullCreditFor[0].latestCreditReport
          ?.refNumber) ||
      this.isReissueOnly
    ) {
      this.issueType = 'Reissue';
    } else {
      this.issueType = null;
    }
  };

  protected onExpirationDateChanged = () => {
    if (this.request.paymentInfo.ccExpirationDate.length === 2) {
      this.request.paymentInfo.ccExpirationDate += '/';
    } else if (this.request.paymentInfo.ccExpirationDate.length > 7) {
      this.request.paymentInfo.ccExpirationDate =
        this.request.paymentInfo.ccExpirationDate.slice(0, -1);
      this.request = { ...this.request };
    }

    if (this.request.paymentInfo.ccExpirationDate?.length === 7) {
      const isValid = Constants.regexPatterns.monthYear.test(
        this.request.paymentInfo.ccExpirationDate
      );
      if (isValid) this.ccExpirationDate.control.setErrors(null);
    }
  };

  protected onExpirationDateBlurred = () => {
    if (
      !this.request.paymentInfo?.ccExpirationDate &&
      this.ccExpirationDate.control.hasValidator(Validators.required)
    ) {
      this.ccExpirationDate.control.setErrors({ required: true });
      return;
    }

    if (
      this.request.paymentInfo?.ccExpirationDate.split('/')[0] &&
      +this.request.paymentInfo.ccExpirationDate.split('/')[0] > 12
    ) {
      this.ccExpirationDate.control.setErrors({ invalid: true });
      return;
    }

    if (this.request.paymentInfo?.ccExpirationDate.split('/')[1]?.length < 4) {
      this.ccExpirationDate.control.setErrors({ invalid: true });
      return;
    }

    if (
      this.request.paymentInfo?.ccExpirationDate.split('/')[1]?.length === 4
    ) {
      const isValid = Constants.regexPatterns.monthYear.test(
        this.request.paymentInfo.ccExpirationDate
      );
      if (!isValid) {
        this.ccExpirationDate.control.setErrors({ invalid: true });
        return;
      }
    }

    this.ccExpirationDate.control.setErrors(null);
  };

  protected onCardNumberChanged = () => {
    this.getCreditCardType(this.request.paymentInfo.payerAccount);
  };

  protected onCardTypeChanged = () => {
    this.request.paymentInfo.payerAccount = null;
    this.getMaskType(this.request.paymentInfo.ccType);
  };

  protected onCancelPullCreditClicked = () => {
    this.showRunCredit = false;
  };

  protected onEnterPaymentInfoCheckChanged = () => {
    this.request.paymentInfo = new PaymentInfo();
  };

  private groupBorrowersByApplicationIndexAndDetermineIfTheyAreJointOrIndividualBasedOnLatestCreditRefNumbers =
    (borrowers: CreditBorrower[]) => {
      const mortgageBorrowers =
        this.applicationContext.currentMortgage.borrowers.filter((b) =>
          borrowers.map((br) => br.borrowerId).includes(b.contactId)
        );
      const mortgageBorrowersGroupedByApplicationIndex = _.groupBy(
        mortgageBorrowers,
        (b) => b.printApplicationIndex
      );

      // We have mortgage borrowers grouped by application index. These represent rows of borrowera in the UI (joint or individual borrowers)
      // Now, we need to determine if their latest pull was joint or individual. We can do this by checking if their latest credit report ref number is the same.
      // All the borrowers in the same application index should have the same ref number if they were pulled together.
      // Otherwise, we'll think they are separate.
      for (const [
        applicationIndex,
        mortgageBorrowersInApplication,
      ] of Object.entries(mortgageBorrowersGroupedByApplicationIndex)) {
        const borrowersThatWeCanRunCreditFor: CreditBorrower[] = [];
        mortgageBorrowersInApplication.forEach((mortgageBorrower) => {
          const borrowerThatWeCanRunCreditFor = borrowers.find(
            (b) => b.borrowerId === mortgageBorrower.contactId
          );
          if (
            borrowerThatWeCanRunCreditFor &&
            (mortgageBorrower.signingRole === 'Borrower' ||
              mortgageBorrower.signingRole === 'CoSigner')
          ) {
            borrowersThatWeCanRunCreditFor.push(borrowerThatWeCanRunCreditFor);
          }
        });
        if (borrowersThatWeCanRunCreditFor.length) {
          this.borrowersGroupedByAppIndex.set(
            Number(applicationIndex),
            borrowersThatWeCanRunCreditFor
          );

          const latestCreditRefNumbersInGroup = _.map(
            borrowersThatWeCanRunCreditFor,
            'latestCreditReport.refNumber'
          );

          // Check if all latest credit ref numbers are the same, if so these people are joint now
          const allBorrowersInTheSameApplicationHaveSameLatestCreditRefNumbers =
            _.uniq(latestCreditRefNumbersInGroup).length === 1;

          borrowersThatWeCanRunCreditFor.forEach((b) => {
            this.borrowerIsJointMap.set(
              b.borrowerId,
              allBorrowersInTheSameApplicationHaveSameLatestCreditRefNumbers
            );
          });
          this.pullCreditIndividualOrJointButtonLabelsPerApplicationIndex.set(
            Number(applicationIndex),
            this.getPullCreditIndividualOrJointButtonLabel(
              borrowersThatWeCanRunCreditFor
            )
          );
        }
      }

      this.appIndexes = Array.from(this.borrowersGroupedByAppIndex.keys()).map(
        (appIndex) => Number(appIndex)
      );
    };

  private prepareUIForJointCreditPull = (borrowers: CreditBorrower[]) => {
    this.request.pullType = CreditPullType.Joint;

    this.runCreditLabel = "Pulling Joint Credit for <b class='dark-color'>";
    borrowers.forEach((borrower) => {
      this.runCreditLabel += borrower.firstName || '';
      this.runCreditLabel += borrower.firstName
        ? ' ' + borrower.lastName
        : borrower.lastName;
      this.runCreditLabel +=
        borrower.borrowerId != borrowers[borrowers.length - 1].borrowerId
          ? ' & '
          : '</b>';
    });
    this.reissue = !!borrowers[0].latestCreditReport?.refNumber;
    this.issueType = this.reissue ? 'Reissue' : '';

    if (this.isReissueOnly) {
      this.reissue = true;
      this.issueType = 'Reissue';
    }
    this.request.creditReferenceNumber =
      borrowers[0].latestCreditReport?.refNumber;
    this.request.creditVendor = borrowers[0].latestCreditReport?.creditVendor;
    const creditVendorToRunWith = this.creditVendors.find(
      (x) => x.vendorName == this.request.creditVendor
    );
    if (creditVendorToRunWith) {
      this.request.credentialId = creditVendorToRunWith.credentialId;
      this.request.importLiabs = creditVendorToRunWith.vendorName === CreditVendorEnum.EncompassCredit;
    }
    this.showRunCredit = true;

    this.updateActionButtonLabel();

    setTimeout(() => {
      this.setDefaultBureaus();
    }, 100);
  };

  private prepareUIForIndividualCreditPullForBorrower = (
    borrower: CreditBorrower
  ) => {
    this.runCreditLabel =
      "Pulling Individual Credit for <b class='dark-color'>";
    this.runCreditLabel += borrower.firstName || '';
    this.runCreditLabel += borrower.firstName
      ? ' ' + borrower.lastName + '</b>'
      : borrower.lastName + '</b>';
    this.request.pullType = CreditPullType.Individual;
    if (!borrower.latestCreditReport?.refNumber) {
      borrower.latestCreditReport.refNumber = this.mortgage?.borrowers?.find(
        (b) => b.contactId == borrower.borrowerId
      )?.creditRefNumber;
    }
    this.request.creditReferenceNumber =
      borrower.latestCreditReport?.refNumber || '';
    this.request.creditVendor = borrower.latestCreditReport?.creditVendor;
    const creditVendorToRunWith = this.creditVendors.find(
      (x) => x.vendorName == this.request.creditVendor
    );
    if (creditVendorToRunWith) {
      this.request.credentialId = creditVendorToRunWith.credentialId;
      this.request.importLiabs = creditVendorToRunWith.vendorName === CreditVendorEnum.EncompassCredit;
    }
    this.reissue = !!borrower.latestCreditReport?.refNumber;
    this.issueType = this.reissue ? 'Reissue' : '';

    if (this.isReissueOnly) {
      this.reissue = true;
      this.issueType = 'Reissue';
    }

    this.updateActionButtonLabel();

    this.showRunCredit = true;
    setTimeout(() => {
      this.setDefaultBureaus();
    }, 100);
  };

  private pullCredit = () => {
    if (this.reissue) {
      this.pullReport('Reissue');
    } else {
      if (this.issueType == 'Upgrade') {
        this.upgradeReport();
      } else {
        this.request.creditReferenceNumber = null;
        this.pullReport('Submit');
      }
    }
  };

  private upgradeReport = () => {
    this._spinner.show();
    this.request.creditRequestType = 'Upgrade';

    let upgradeRequest: CreditUpgradeRequest = new CreditUpgradeRequest();
    upgradeRequest.integrationHistoryId =
      this.selectedBorrowersToPullCreditFor[0].latestCreditReport.integrationHistoryId;
    upgradeRequest.equifax = this.request.equifax || false;
    upgradeRequest.experian = this.request.experian || false;
    upgradeRequest.transUnion = this.request.transUnion || false;
    upgradeRequest.paymentInfo = this.request.paymentInfo;

    this._creditService
      .upgradeReport(upgradeRequest)
      .subscribe(
        async (response) => {
          if (response && response.success) {
            this.isSubmitSuccess = true;
            this.isSubmitError = false;
            this.creditRunCompleted.emit(true);
          } else {
            this.isSubmitSuccess = false;
            this.isSubmitError = true;
            if (response.hasError) {
              if (
                response.validationErrors &&
                response.validationErrors.length
              ) {
                this.validationErrors = this.validationErrors.concat(
                  response.validationErrors
                );
              }
              if (response.errorMessage) {
                this.errorMessages.push(response.errorMessage);
              }
            }
            this.creditRunCompleted.emit(false);
          }
        },
        (err) => {
          this.showError(
            err?.message || 'An error occurred while upgrade credit.'
          );
        }
      )
      .add(() => {
        this.pullingCreditReport = false;
        this._spinner.hide();
      });
  };

  private pullReport = (creditRequestType: string) => {
    this.request.creditRequestType = creditRequestType;

    if (!this.request.credentialId) {
      this.pullingCreditReport = false;
      this._notifyService.showError(
        'Credit Vendor Credential ID is not set. Cannot run credit without a credential Id. ',
        'Error'
      );
      return;
    }

    if (this.request.pullType == 'Individual') {
      this.pullIndividualCreditReport(this.selectedBorrowersToPullCreditFor[0]);
    } else {
      this.pullJointCreditReport(this.selectedBorrowersToPullCreditFor);
    }
  };

  private pullIndividualCreditReport = (borrower: CreditBorrower) => {
    this.spinner.show('Pulling credit...');
    const apiCallsToMake: any[] = [];
    const individualCreditPullRequest = { ...this.request };

    individualCreditPullRequest.borrowerIds = [borrower.borrowerId];
    if (individualCreditPullRequest.creditRequestType == 'Submit') {
      individualCreditPullRequest.creditReferenceNumber = null;
    }

    const pullReportForBorrower = this._creditService.pullReport(
      individualCreditPullRequest
    );
    apiCallsToMake.push(pullReportForBorrower);

    if (apiCallsToMake.length > 0) {
      forkJoin(apiCallsToMake)
        .subscribe({
          next: async (response) => {
            if (response && response.every((r) => r.success)) {
              this.isSubmitSuccess = true;
              this.isSubmitError = false;
              this.creditRunCompleted.emit(true);
            } else {
              this.isSubmitSuccess = false;
              this.isSubmitError = true;
              response.forEach((r) => {
                if (r.hasError) {
                  if (r.validationErrors && r.validationErrors.length) {
                    this.validationErrors = this.validationErrors.concat(
                      r.validationErrors
                    );
                  }
                  if (r.errorMessage) {
                    this.errorMessages.push(r.errorMessage);
                  }
                }
              });
              this.creditRunCompleted.emit(false);
            }
          },
          error: (error) => {
            this._notifyService.showError(
              error?.message || 'An error occurred while pulling credit.',
              'Error!'
            );
          },
        })
        .add(() => {
          this.pullingCreditReport = false;
          this.spinner.hide();
        });
    }
  };

  private pullJointCreditReport = (borrowers: CreditBorrower[]) => {
    this.spinner.show('Pulling credit...');
    const jointPullCreditRequest = {
      ...this.request,
      borrowerIds: borrowers.map((b) => b.borrowerId),
    };
    if (jointPullCreditRequest.creditRequestType == 'Submit') {
      jointPullCreditRequest.creditReferenceNumber = null;
    }

    this._creditService
      .pullReport(jointPullCreditRequest)
      .subscribe({
        next: async (result) => {
          if (result.success) {
            this.isSubmitSuccess = true;
            this.isSubmitError = false;
            this.creditRunCompleted.emit(true);
          } else {
            if (result.errorMessage) {
              this.isSubmitSuccess = false;
              this.showError(result.errorMessage);
            } else if (
              result.validationErrors &&
              result.validationErrors.length > 0
            ) {
              this.validationErrors = result.validationErrors;
            }
            this.creditRunCompleted.emit(false);
          }
        },
        error: (error) => {
          this.showError(
            error?.message || 'An error occurred while pulling credit.'
          );
        },
      })
      .add(() => {
        this.pullingCreditReport = false;
        this.spinner.hide();
      });
  };

  private async showCantSelectEncompassVendorDialog(): Promise<void> {
    await Swal.fire({
      title: 'Cannot Select Encompass Vendor',
      text: 'This application is currently not linked to an Encompass instance.  Please create a corresponding file in Encompass first.',
      icon: 'warning',
    });
  }

  private autoCreateLosLoan = async (): Promise<boolean> => {
    try {
      this.errorMessages = [];
      this._spinner.show();
      const losAppOpResult = await firstValueFrom(
        this._losService.autoCreateLosLoan(this.application.applicationId)
      );
      this.application = losAppOpResult.application;
      this.mortgage = losAppOpResult.application.mortgageLoan;
      this._spinner.hide();
      return !!losAppOpResult.application.losIdentifier;
    } catch (e) {
      console.error(e);
      let msg =
        e?.error?.message ||
        e?.message ||
        `There was an error attempting to prepare your loan for credit. Please contact your ${
          this.applicationContext.isTpo ? 'AE' : 'Administrator'
        }.`;
      this.errorMessages.push(
        msg.replace(
          '{' + this.application.losIdentifier + '}',
          this.application.refNumber
        )
      );
      this._spinner.hide();
      return false;
    }
  };

  private showError = (message: string) => {
    this.isSubmitError = true;
    this.errorMessages.push(message);
  };

  private prepareCreditVendorsDropdown = () => {
    this._creditVendors.forEach((vendor) => {
      for (let enumMember in CreditVendor) {
        let isValueProperty = parseInt(enumMember, 10) >= 0;
        if (isValueProperty) {
          let enumLiteral = Number(enumMember);

          if (
            vendor.vendorName == this.getVendorEnumNameByLiteral(enumLiteral)
          ) {
            vendor.vendorEnum = enumLiteral;
            vendor.vendorDisplayName = `${
              vendor.alias ? vendor.alias + ' -' : ''
            } ${this.splitCamelCaseString(vendor.vendorName)}`;
          }
        }
      }
    });
  };

  private setReissueFieldForTpo = () => {
    this._tpoConfigService.getTpoConfigs().subscribe({
      next: (tpoConfigs) => {
        if (tpoConfigs.creditPermissions == TpoCreditPermissions.ReissueOnly) {
          this.reissue = true;
          this.issueType = 'Reissue';
          this.isReissueOnly = true;
        }
      },
      error: (err) => {
        this.showError(err.message || err);
      },
    });
  };

  private getCreditCardType(creditCardNumber) {
    if (/^5[1-5]/.test(creditCardNumber)) {
      this.request.paymentInfo.ccType = CreditCardType.MasterCard;
    }
    if (/^4/.test(creditCardNumber)) {
      this.request.paymentInfo.ccType = CreditCardType.Visa;
    }
    if (/^3[47]/.test(creditCardNumber)) {
      this.request.paymentInfo.ccType = CreditCardType.AmericanExpress;
    }
    if (/6(?:011|5[0-9]{2})[0-9]{12}/.test(creditCardNumber)) {
      this.request.paymentInfo.ccType = CreditCardType.Discover;
      this.request = { ...this.request };
    }
  }

  private setDefaultBureaus = () => {
    if (!this.creditVendorComponent) return;

    const selectedCreditVendor = this._creditVendors.find(
      (cv) =>
        cv['vendorDisplayName'] ===
        this.creditVendorComponent.nativeElement?.selectedOptions[0]?.innerText.trim()
    );
    if (!selectedCreditVendor) {
      this.showSoftPullReportType = false;
      return;
    }

    this.setReportTypes(selectedCreditVendor);

    this.request.experian =
      selectedCreditVendor.thirdPartyKeyValuePairs?.find(
        (el) => el.key === 'ExperianDefault'
      )?.value === 'true';
    this.request.equifax =
      selectedCreditVendor.thirdPartyKeyValuePairs?.find(
        (el) => el.key === 'EquifaxDefault'
      )?.value === 'true';
    this.request.transUnion =
      selectedCreditVendor.thirdPartyKeyValuePairs?.find(
        (el) => el.key === 'TransunionDefault'
      )?.value === 'true';
  };

  private getVendorEnumNameByLiteral = (literal: number) => {
    return CreditVendor[literal];
  };

  private splitCamelCaseString = (str: string): string => {
    return str.replace(/([a-z0-9])([A-Z])/g, '$1 $2');
  };

  private getMaskType(cardType: CreditCardType) {
    const masks: { [key in CreditCardType]: string } = {
      MasterCard: '9999 9999 9999 9999',
      Visa: '9999 9999 9999 9999',
      AmericanExpress: '9999 999999 99999',
      Discover: '9999 9999 9999 9999',
    };

    this.creditCardMask = masks[cardType] || '9999 9999 9999 9999';
    this.request = { ...this.request };
  }

  private closeErrorsAndPullCredit = () => {
    //@Kaan What is this and why are we saving mortgage data from the services tab?
    this.closeErrors();
    this.pullingCreditReport = true;
    this.pullCredit();
  };

  private setReportTypes(selectedCreditVendor) {
    this.request.reportType = null;
    this.reportTypes = [];

    if (
      selectedCreditVendor.vendorName ===
        CreditVendorEnum.MeridianLinkSoftPull ||
      selectedCreditVendor.vendorName === CreditVendorEnum.MeridianLinkHardPull
    ) {
      this.request.reportType = 'Merge';
      this.showSoftPullReportType = false;
      return;
    }

    if (
      selectedCreditVendor.vendorName === CreditVendorEnum.CredCoSoftPull ||
      selectedCreditVendor.vendorName === CreditVendorEnum.CredCoHardPull
    ) {
      this.reportTypes = [
        {
          name: 'Merge (Instant Merge)',
          value: 'Merge',
        },
        {
          name: 'Prequal',
          value: 'Prequal',
        },
      ];
      this.showSoftPullReportType = true;
      return;
    }

    if (selectedCreditVendor.vendorName == CreditVendorEnum.Xactus) {
      this.reportTypes = [
        {
          name: 'Merge',
          value: 'Merge',
        },
        {
          name: 'Prequal',
          value: 'Prequal',
        },
        {
          name: 'RMCR (Mortgage Only)',
          value: 'RMCR',
        },
      ];
      this.showSoftPullReportType = true;
      return;
    }

    if (
      selectedCreditVendor.vendorName == CreditVendorEnum.FactualDataByCbc ||
      selectedCreditVendor.vendorName == CreditVendorEnum.FactualDataHardPull ||
      selectedCreditVendor.vendorName == CreditVendorEnum.FactualDataSoftPull
    ) {
      this.reportTypes = [
        {
          name: 'Merge',
          value: 'Merge',
        },
        {
          name: 'RMCR (Mortgage Only)',
          value: 'RMCR',
        },
      ];
      this.showSoftPullReportType = true;
      return;
    }

    this.showSoftPullReportType = false;
  }

  private getPullCreditIndividualOrJointButtonLabel = (
    borrowers: CreditBorrower[]
  ): string => {
    if (borrowers.length == 0) {
      return;
    } else if (borrowers.length == 1) {
      return 'Pull/Re-issue Individual';
    } else {
      const isJoint = this.borrowerIsJointMap.get(borrowers[0].borrowerId);
      if (!isJoint) {
        return 'Pull/Re-issue Individual';
      } else {
        return 'Pull/Re-issue Joint';
      }
    }
  };

  private getCreditBureauToHighlightScoreFor = (borrower: CreditBorrower) => {
    if (borrower.latestCreditReport) {
      const x = Number(borrower.latestCreditReport.equifax);
      const y = Number(borrower.latestCreditReport.experian);
      const z = Number(borrower.latestCreditReport.transUnion);

      if (x && y && z) {
        if ((y >= x && y < z) || (y >= z && y < x)) {
          return 'experian';
        }
        if ((x >= y && x < z) || (x >= z && x < y)) {
          return 'equifax';
        }
        if ((z >= x && z < y) || (z >= y && z < x)) {
          return 'transUnion';
        }
      } else if (x && y) {
        if (x >= y) {
          return 'equifax';
        } else {
          return 'experian';
        }
      } else if (x && z) {
        if (x >= z) {
          return 'equifax';
        } else {
          return 'transUnion';
        }
      } else if (y && z) {
        if (y >= z) {
          return 'experian';
        } else {
          return 'transUnion';
        }
      } else {
        return x ? 'equifax' : y ? 'experian' : z ? 'transUnion' : '';
      }
    }
    return '';
  };

  private updateActionButtonLabel = () => {
    if (this.reissue) {
      this.actionButtonLabel = 'Re-issue Credit';
    } else if (this.issueType == 'Upgrade') {
      this.actionButtonLabel = 'Upgrade Credit';
    } else {
      if (!this.isLoanReadOnly) {
        this.actionButtonLabel = 'Pull Credit';
      } else {
        this.actionButtonLabel = 'Pull Credit (Readonly)';
      }
    }
  };

  loadTechnicalAffiliates = () => {
    this._loanServicesService
      .getTechnicalAffiliates('MeridianLinkHardPull')
      .subscribe({
        next: (result) => {
          this.technicalAffiliates = result.filter((el) => el.mlcid && el.code);
        },
        error: (err) => {
          this._notifyService.showError(
            err?.message || 'Unable to load technical affiliates',
            'Credit Pull'
          );
        },
      });
  };

  private addCreditVendor = () => {
    if (
      this.applicationContext.isTpo &&
      !this.applicationContext.isCompanyLfhs &&
      !this.applicationContext.isCompanyOpenWholesale &&
      !this.applicationContext.isCompanyMortgegProcessing &&
      !this.applicationContext.isCompanyPulseProcessing &&
      !this.applicationContext.isCompanyGenway &&
      !this.applicationContext.isCompanySmartfi
    ) {
      this.openCreditCredentialsCreationWizardDialog();
    } else {
      this.openClassicCreditCredentialsCreationDialog();
    }
  };

  private openCreditCredentialsCreationWizardDialog = () => {
    let modalRef = this._modalService.open(
      CreditCredentialsConfigurationDialogComponent,
      {
        ...Constants.modalOptions.large,
        windowClass: 'myCustomModalClass',
      }
    );
    modalRef.componentInstance.showYouDontHaveCreditCredentialsConfiguredMessage = false;
    modalRef.result.then(
      (result: ThirdPartyCredential) => {
        this.onAfterCreditCredentialsAdded(result);
      },
      () => {}
    );
  };

  private openClassicCreditCredentialsCreationDialog = () => {
    const modalRef = this._modalService.open(
      CreditModalComponent,
      Constants.modalOptions.large
    );
    let credential =
      this._thirdPartyCredentialsService.getNewCredential('CreditVendor');
    credential.channels = this.application.channel;
    modalRef.componentInstance.scope = 'User';
    modalRef.componentInstance.credential = JSON.parse(
      JSON.stringify(credential)
    );
    modalRef.componentInstance.technicalAffiliates = this.technicalAffiliates;
    modalRef.componentInstance.disabledChannel = true;

    modalRef.result.then(
      (result: ThirdPartyCredential) => {
        this.onAfterCreditCredentialsAdded(result);
      },
      () => {}
    );
  };

  private onAfterCreditCredentialsAdded = (result: ThirdPartyCredential) => {
    this._creditVendors.unshift(result as CreditThirdPartyCredential);
    this.creditVendors = [...this.creditVendors];
    this.request.creditVendor = result.vendorName;
    this.request.credentialId = result.credentialId;
    this.request.importLiabs = result.vendorName === CreditVendorEnum.EncompassCredit;
    this.creditVendorChange.emit(result.vendorName as CreditVendorEnum);
  };
}
