// import { Injectable } from '@angular/core';

// @Injectable({
//   providedIn: 'root'
// })
// export class LoggerService {
//   log(message: string): void {
//     const stackTrace = this.getFilteredStackTrace();
//     console.log(`[LOG]: ${message}`);
//     console.log(`Stack Trace (filtered):`, stackTrace);
//   }

//   private getFilteredStackTrace(limit: number = 4): string[] {
//     const stack = new Error().stack?.split('\n') || [];

//     return stack
//       .map(line => line.trim())
//       .filter(line => 
//         !line.includes('node_modules') &&   // Exclude third-party libraries
//         !line.includes('webpack') &&        // Exclude Webpack internals
//         !line.includes('zone.js')           // Exclude Angular's Zone.js
//       )
//       .slice(1, limit + 1);  // Skip the first line (it's the `Error` constructor call)
//   }
// }

export function logWithStack(...messages: any[]): void {
    // Capture the error for stack trace
    const error = new Error();

    // Log messages and stack trace
    console.groupCollapsed("[LOG]", ...messages);
    console.log("Stack Trace (Click to Expand):");
    console.error(error); // Stack trace will map to the TypeScript source
    console.groupEnd();
}