import { AfterViewInit, Component, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { ControlContainer, NgForm, NgModelGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AusLender, Configuration } from 'src/app/models';
import { CreditVendorSelectOption } from 'src/app/models/aus/du-providers.model';
import { ApplicationContextBoundComponent } from "../../../../../shared/components";
import { LoanServicesService } from 'src/app/services/loan';

@Component({
  selector: 'desktop-originator',
  templateUrl: './aus-do.component.html',
  styleUrls: ['./aus-do.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgModelGroup }],
})
export class AusDoComponent extends ApplicationContextBoundComponent implements OnInit, AfterViewInit {

  @ViewChild('doForm') doForm: NgForm | undefined;
  @Input() doParams: any = {};
  @Input() creditVendorList: Array<CreditVendorSelectOption> = [];
  @Input() ausLenders: Array<AusLender> = [];
  @Input() allowedRolesToFinalizeDOFindingsConfig: Configuration;
  @Input() isTPOUser: boolean;
  @Input() isLoanReadOnly: boolean;
  @Input() isCompanyGenway: boolean = false;

  overrideCreditEnabled: boolean;
  overrideDoCredsEnabled: boolean;
  selectedCreditProviderHasCreds: boolean;
  forceEdit: boolean;
  randomText = Math.random().toString(30);
  showPassword: boolean = false;
  showDoPassword: boolean = false;
  canFinalizeDO: boolean;
  editBrokerLender: boolean;

  constructor(
    private readonly injector: Injector,
    public activeModal: NgbActiveModal,
    private readonly _loanServicesService: LoanServicesService
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.creditProviderChanged();
    this.doParams.doUsernameOverride = '';
    this.doParams.doPasswordOverride = '';

    if (this.allowedRolesToFinalizeDOFindingsConfig?.valueStr) {
      const roles = this.allowedRolesToFinalizeDOFindingsConfig.valueStr.split(',');
      this.canFinalizeDO = this.applicationContext.userPermissions.roleId ? roles.includes(this.applicationContext.userPermissions.roleId.toString()) : false;
    } else {
      this.canFinalizeDO = false;
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.doForm) {
        this.doForm.form.markAllAsTouched();
        const isValid = this.doForm.form.valid;
      }
    });
  }

  creditProviderChanged() {
    this.doParams.creditVendorUserName = '';
    this.doParams.creditVendorPassword = '';
    const matchingCreditVendor = this.creditVendorList.flatMap(vendor => vendor.options)
      .find(vendor => vendor.value == this.doParams.creditVendor);
    this.selectedCreditProviderHasCreds = !matchingCreditVendor
      ? false
      : matchingCreditVendor.userName != '' && matchingCreditVendor.password != '';
    this.overrideCreditEnabled = !this.selectedCreditProviderHasCreds;
  }

  overrideClicked() {
    this.overrideDoCredsEnabled = !this.overrideDoCredsEnabled
    if (!this.overrideDoCredsEnabled) {
      this.doParams.writeAusCredsToUserProfile = false;
      this.doParams.doUsernameOverride = '';
      this.doParams.doPasswordOverride = '';
    } else {
      this.doParams.writeAusCredsToUserProfile = true;
    }
  }

  overrideCreditCredsClicked() {
    this.overrideCreditEnabled = !this.overrideCreditEnabled
    if (!this.overrideCreditEnabled) {
      this.doParams.writeCreditCredsToUserProfile = false;
      this.doParams.creditVendorUserName = '';
      this.doParams.creditVendorPassword = '';
    } else {
      this.doParams.writeCreditCredsToUserProfile = true;
    }
  }

  onLenderSelectionChange() {
    if (!this.doParams.brokerLenderId) {
      this.doParams.finalizeDo = false;
    }
  }

  onForceEditChange = () => {
    if (!this.forceEdit) {
      this.forceEdit = true;
      this._loanServicesService.forceEditChange(this.forceEdit);
    }
  }

  onVendorFileIdChange = () => {
    if (!this.doParams.vendorFileId && !this.editBrokerLender) {
      this.editBrokerLender = true;
      this._loanServicesService.editBrokerLenderChange(this.editBrokerLender);
    }
  }

  submitRequest() {
    if (this.doForm) {
      this.doForm.form.markAllAsTouched();

      if (!this.doForm.form.valid) {
        return;
      }
      this.activeModal.close(this.doParams);
    }
  }
}
