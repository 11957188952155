import { AusData, Extension, MortgageTerm, OriginatorInformation, SubjectProperty, TransactionDetail } from "src/app/models";
import { NewAppBorrowerModel } from "./new-app-borrower.model";

export class NewAppMortgageModel {
    mortgageId = 0;
    channel: string;
    applicationId = 0;
    subjectProperty  = new SubjectProperty();
    mortgageTerm = new MortgageTerm();
    transactionDetail = new TransactionDetail();
    originatorInformation = new OriginatorInformation();
    extension = new Extension();
    ausData = new AusData();
    borrowers : Array<NewAppBorrowerModel> = [new NewAppBorrowerModel()];
}