import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { finalize } from 'rxjs/operators';
import { KeyDateConfig, KeyDateGroup } from 'src/app/models';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { KeyDatesService } from 'src/app/services/key-dates.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ConfirmModalComponent } from 'src/app/shared/components/confirm-modal/confirm-modal.component';

@Component({
  selector: 'key-date-box',
  templateUrl: 'key-date-box.component.html',
  styleUrls: ['key-date-box.component.scss'],
})
export class KeyDateBoxComponent implements OnInit {
  @Input() keyDate: KeyDateConfig;
  @Input() keyDateGroup?: KeyDateGroup;
  @Input() keyDateTypes?: EnumerationItem[];
  @Input() companyId: number;
  @Input() baseMessageTitle: string;
  @Output() onEdit: EventEmitter<KeyDateConfig> = new EventEmitter<KeyDateConfig>()

  @Output()
  keyDateDeleted: EventEmitter<KeyDateConfig> = new EventEmitter<KeyDateConfig>();

  deleting = false;

  constructor(
    private readonly _modalService: NgbModal,
    private readonly _keyDatesService: KeyDatesService,
    private readonly _notificationService: NotificationService
  ) { }

  ngOnInit(): void {
    if (this.keyDate) {
      this.keyDate["keyDateTypeDisplayName"] = this.getKeyDateDisplayName(this.keyDate.keyDateType);
    }
  }

  getKeyDateDisplayName = (value: string) => {
    if (!value) {
      return '';
    }
    const keyDateType = this.keyDateTypes?.find(x => x.value == value);
    if (!keyDateType) {
      return '';
    }
    return keyDateType?.name || '';
  }

  editKeyDate() {
    this.onEdit.emit(this.keyDate)
  }

  openDeleteConfirmDialog() {
    const modalRef = this._modalService.open(ConfirmModalComponent);

    modalRef.componentInstance.title = 'Key Date';
    modalRef.componentInstance.text =
      'Are you sure you want to delete this key date?';

    modalRef.result.then(
      (result) => {
        if (result === 'cancel') return;
        this.delete();
      },
      () => { }
    );
  }

  private delete() {
    this.deleting = true;
    this._keyDatesService
      .deleteKeyDate(this.companyId, this.keyDate.keyDateConfigurationId)
      .pipe(finalize(() => this.deleting = false))
      .subscribe({
        next: () => {
          this._notificationService.showSuccess(
            'Key date removed successfully',
            this.baseMessageTitle
          );

          this.keyDateDeleted.emit(this.keyDate);
        },
        error: ({ error }) => {
          this._notificationService.showError(
            error?.message || "Couldn't delete key date",
            this.baseMessageTitle
          );
        }
      });
  }
}
