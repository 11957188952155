import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { DocumentType } from 'src/app/models';
import { DocumentTypeService } from 'src/app/modules/admin/document-config/services/document-type.service';
import { ReggoraSyncDirection, RoggoraDocType } from 'src/app/modules/loan-services/reggora/models/reggora-doc-type';
import { ReggoraService } from 'src/app/modules/loan-services/reggora/services/reggora.service';
import { Constants } from 'src/app/services/constants';
import { NotificationService } from 'src/app/services/notification.service';
import { ReggoraEditDocTypeDialogComponent } from './reggora-edit-doc-type-dialog/reggora-edit-doc-type-dialog.component';

@Component({
  selector: 'app-reggora-doc-type-mappings-dialog',
  templateUrl: './reggora-doc-type-mappings-dialog.component.html',
  styleUrls: ['./reggora-doc-type-mappings-dialog.component.scss']
})
export class ReggoraDocTypeMappingsDialogComponent implements OnInit {

  @Input() credentialId: number;
  @Input() mappingType: ReggoraSyncDirection;

  docTypes: DocumentType[] = [];
  docTypeMappings: RoggoraDocType[] = [];
  isLoadingCompleted: boolean = false;

  activeDeletionIndex: number = -1;

  constructor(
    public activeModal: NgbActiveModal,
    private readonly _modalService: NgbModal,
    private readonly _spinnerService: NgxSpinnerService,
    private readonly _notificationService: NotificationService,
    private readonly _reggoraService: ReggoraService,
    private readonly _documentTypeService: DocumentTypeService

  ) { }

  ngOnInit(): void {
    this.isLoadingCompleted = false;
    this._spinnerService.show();

    forkJoin({
      mappings: this._reggoraService.getAllReggoraDocTypes(this.credentialId),
      docTypes: this._documentTypeService.getDocumentTypes(true)
    })
    .subscribe({
      next: (result) => {
        this._spinnerService.hide();

        this.docTypeMappings = (result.mappings || []).filter(m => m.syncDirection == this.mappingType);
        this.docTypes = result.docTypes || [];

        this.docTypeMappings.forEach(map => {
          const matchedType = this.docTypes.find(dt => dt.documentTypeId == map.documentTypeId);
          if(matchedType){
            map["documentTypeName"] = matchedType.documentTypeName;
          }
        })
        this.isLoadingCompleted = true;
      },
      error: (error) => {
        this._spinnerService.hide();
        this._notificationService.showError(error?.message || "An error occurred while getting doc type mappings.", "Error!");
        this.isLoadingCompleted = true;
      }
    });
  }

  addMapping = () => {
    const docType = new RoggoraDocType();
    docType.credentialId = this.credentialId;
    docType.syncDirection = this.mappingType;
    this.editMapping(docType, false);
  }

  editMapping = (docType: RoggoraDocType, isEdit: boolean = true) => {
    const modalRef = this._modalService.open(ReggoraEditDocTypeDialogComponent, {...Constants.modalOptions.xlarge, scrollable: false});
    modalRef.componentInstance.mapping = docType;
    modalRef.componentInstance.docTypes = this.docTypes;
    modalRef.result.then((res: RoggoraDocType) => {
      if(isEdit){
        this.docTypeMappings = [...this.docTypeMappings].map(dt => dt.reggoraDocTypeId !== res.reggoraDocTypeId ? dt : res);
      }
      else {
        this.docTypeMappings.push(res);
      }
    });
  }

  onDeleteCancelled = () => {
    this.activeDeletionIndex = -1;
  }

  onDeleteButtonClicked = (index: number) => {
    this.activeDeletionIndex = index;
  }

  onDeleteConfirmClicked = (docType: RoggoraDocType) => {
    this._spinnerService.show();
    this._reggoraService.deleteReggoraDocType(docType.credentialId, docType.reggoraDocTypeId).subscribe({
      next: () => {
        this._spinnerService.hide();
        this.docTypeMappings.splice(this.activeDeletionIndex, 1);
        this.activeDeletionIndex = null;
      },
      error: (error) => {
        this._spinnerService.hide();
        this._notificationService.showError(error?.message || "An error occurred while deleting doc type mapping.", "Error!");
      }
    });
  }

}
