import { Component, Input, OnInit } from '@angular/core';
import { formViewProvider } from 'src/app/core/services/form-view.provider';
import { Utils } from 'src/app/core/services/utils';
import { Address, CreditVendor, MortgageBorrower, ResidencyAddress, ResidencyType } from 'src/app/models';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { ZipCodeLookupResult } from 'src/app/models/zipcode-lookup-result.model';
import { BorrowerDto } from 'src/app/modules/contacts/models/borrower-dto.model';
import { RecordType } from 'src/app/modules/dialer/models/dial-list-record-basic.model';
import { ManualDialParams } from 'src/app/modules/dialer/models/manual-dial-params.model';
import { PhoneType } from 'src/app/modules/dialer/models/phone-type.model';
import { DialerService } from 'src/app/modules/dialer/services/dialer.service';
import { ApplicationContextService } from 'src/app/services/application-context.service';
import { Constants } from 'src/app/services/constants';
import { EnumerationService } from 'src/app/services/enumeration-service';

@Component({
  selector: 'loan-borrower-info',
  templateUrl: './loan-borrower-info.component.html',
  styleUrls: ['./loan-borrower-info.component.scss'],
  viewProviders: [formViewProvider]
})
export class LoanBorrowerInfoComponent implements OnInit {

  @Input()
  borrower: MortgageBorrower;

  @Input()
  loanBorrower: BorrowerDto;

  @Input()
  coBorr: boolean;

  mobilePhone: string;
  homePhone: string;
  workPhone: string;

  maxDate: Date = new Date();
  minDate: Date = new Date("1/1/1900");

  dialerEnabled: boolean;
  isSsnHidden: boolean = false;
  creditVendor: CreditVendor = null;

  presentAddress: ResidencyAddress = new ResidencyAddress();
  mailingAddress: ResidencyAddress = new ResidencyAddress();

  states: EnumerationItem[] = [];
  suffixes: EnumerationItem[] = [];
  signingRoleOptions: EnumerationItem[] = [];

  titleOnlyEnumValue: string = "";
  nonTitledSpouseEnumValue: string = "";

  constructor(
    private readonly _applicationContextService: ApplicationContextService,
    private readonly _enumerationService: EnumerationService,
    private readonly _dialerService: DialerService
  ) {
    this._enumerationService.getMortgageEnumerations().subscribe(enumerations => {
      this.suffixes = enumerations[Constants.enumerations.suffix];
      this.signingRoleOptions = enumerations[Constants.mortgageEnumerations.signingRole];
    });
    this._applicationContextService.context.subscribe(context => {
      Object.keys(context.globalConfig.states).forEach(key => {
        this.states.push(new EnumerationItem(context.globalConfig.states[key], key));
        this.dialerEnabled = context.userPermissions.dialerEnabled;
      });
    })
  }

  ngOnInit(): void {
    this.mobilePhone = Utils.cleanPhone(this.borrower.mobilePhone);
    this.homePhone = Utils.cleanPhone(this.borrower.homePhone);
    this.workPhone = Utils.cleanPhone(this.borrower.workPhone);

    this.presentAddress = this.borrower.residencyAddresses.find(ra => ra.residencyType === ResidencyType.PresentAddress);
    this.mailingAddress = this.borrower.residencyAddresses.find(ra => ra.residencyType === ResidencyType.MailingAddress);

    this.titleOnlyEnumValue = this._enumerationService.getEnumValue(Constants.enumerationValueNames.SigningRole.TitleOnly);
    this.nonTitledSpouseEnumValue = this._enumerationService.getEnumValue(Constants.enumerationValueNames.SigningRole.NonTitledSpouse);

    if (!this.presentAddress) {
      this.presentAddress = new ResidencyAddress();
      this.presentAddress.residencyType = ResidencyType.PresentAddress;
      this.borrower.residencyAddresses.push(this.presentAddress)
    }
    if (!this.mailingAddress) {
      this.mailingAddress = new ResidencyAddress();
      this.mailingAddress.residencyType = ResidencyType.MailingAddress;
      this.borrower.residencyAddresses.push(this.mailingAddress)
    }
  }

  copyFromMobile = () => {
    this.homePhone = this.mobilePhone;
  }

  static setBorrowerDefaults(borrower: MortgageBorrower) {
    LoanBorrowerInfoComponent.setAddressDefaults(borrower);

    borrower.firstName ??= "";
    borrower.middleName ??= "";
    borrower.lastName ??= "";
    borrower.mobilePhone ??= "";
    borrower.homePhone ??= "";
    borrower.workPhone ??= "";
    borrower.primaryEmail ??= "";
    borrower.creditScore ??= 0;
    borrower.socialSecNum ??= "";
  }

  private static setAddressDefaults(borrower: MortgageBorrower) {
    const setDefaultValues = (residencyAddress: ResidencyAddress) => {
      if (!residencyAddress.address) {
        residencyAddress.address = {};
      }
      const { address } = residencyAddress;
      address.address1 ??= "";
      address.address2 ??= "";
      address.city ??= "";
      // Because address.state is used by a select element the default value is
      // undefined, not empty string
      //
      // address.state ??= "";
      address.zipCode ??= "";
    }

    const { residencyAddresses } = borrower;

    const presentAddress = residencyAddresses.find(({ residencyType }) => residencyType === ResidencyType.PresentAddress);
    const mailingAddress = residencyAddresses.find(({ residencyType }) => residencyType === ResidencyType.MailingAddress);

    presentAddress && setDefaultValues(presentAddress);
    mailingAddress && setDefaultValues(mailingAddress);
  }

  dial = (phoneNumber: string, phoneType: PhoneType): void => {
    let data = {
      phoneNumber: phoneNumber,
      phoneType: phoneType,
      firstName: this.borrower.firstName,
      lastName: this.borrower.lastName,
      recordType: RecordType.Borrower,
      recordId: this.borrower.contactId
    } as ManualDialParams;

    this._dialerService.openCallControlPanel(data)

  }

  onPhoneChanged = (value: string, type: string) => {
    let prefix = ""

    if (this.borrower[type]) {
      if (this.borrower[type].indexOf("+1") > -1) {
        prefix = '+1';
      } else if (this.borrower[type].length > 10 && this.borrower[type][0] === "1") {
        prefix = '1';
      }
    }

    this.borrower[type] = prefix + value;
  }

  onSigningRoleChanged = () => {
    let isTitleOnlyOrNonTitledSpouse = this.borrower.signingRole == this.titleOnlyEnumValue ||
      this.borrower.signingRole == this.nonTitledSpouseEnumValue;

    if (isTitleOnlyOrNonTitledSpouse) {
      this.borrower.employments = [];
      this.borrower.nonEmploymentIncomes = [];
    }
  }

  emailFormatValidate = (email): boolean => {
    return !email ||
      (
        email
        &&
        email
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          )
      )
  }

  onZipCodeRelatedInfoChanged = (zipCode: ZipCodeLookupResult, resicendyAddress: ResidencyAddress) => {
    if (zipCode) {
      resicendyAddress.address.state = zipCode.state.toLowerCase();
      resicendyAddress.address.city = Utils.toTitleCase(zipCode.city);
      resicendyAddress.address.zipCode = zipCode.zipcode;
      resicendyAddress.address.country = 'us';
    }
  }

  handlePresentAddress1Change = (e: any) => {
    const presentAddress = this.presentAddress.address;
    presentAddress.address1 = ''; // to reset the last populated address.

    setTimeout(() => {
      presentAddress.address1 = e.address1;
      presentAddress.city = e.city;
      presentAddress.state = e.state;
      presentAddress.zipCode = e.zipCode;
    }, 200);
  }

  protected handleMailingAddress1Change(e: Partial<Address>) {
    const mailingAddress = this.mailingAddress.address;
    mailingAddress.address1 = ''; // to reset the last populated address.

    setTimeout(() => {
      mailingAddress.address1 = e.address1;
      mailingAddress.city = e.city;
      mailingAddress.state = e.state;
      mailingAddress.zipCode = e.zipCode;
    }, 200);
  }

  copyAddressFromTo = (from: string, to: string) => {
    this[to].address['address1'] = this[from].address['address1'];
    this[to].address['address2'] = this[from].address['address2'];
    this[to].address['city'] = this[from].address['city'];
    this[to].address['state'] = this[from].address['state'];
    this[to].address['zipCode'] = this[from].address['zipCode'];
  }
}
