export class ActivityLog {
  userId: string;
  category: ActivityTypeCategory;
  activityType: ActivityType;
  displayText: string;
  dateCreated: Date;
  loanDocTaskId: number;
  messageId: number;
  loanEventId: number;
  leadEventId: number;
  fileId: string;
  trackingId: number;
  mentionTrackingGuid: string;
  base64Img?: string; // optional base64 image
}

export enum ActivityType {
  LoanChangeStatus = "LoanChangeStatus",
  BorrowerMessage = "BorrowerMessage",
  InternalMessage = "InternalMessage",
  LeadNote = "LeadNote",
  LeadEmail = "LeadEmail",
  LeadSms = "LeadSms",
  LeadApi = "LeadApi",
  LeadPhoneAttempt = "LeadPhoneAttempt",
  LeadPhoneSuccess = "LeadPhoneSuccess",
  ChangeStatus = "ChangeStatus",
  Uploadfile = "Uploadfile",
  DeleteFile = "DeleteFile",
  DownloadFile = "DownloadFile",
  ExportFile = "ExportFile",
  FileNoteUpdated = "FileNoteUpdated",
  BorrowerNoteUpdated = "BorrowerNoteUpdated",
  DeleteTask = "DeleteTask",
  RemoveFileFromTrash = "RemoveFileFromTrash",
  ConditionStatusChange = "ConditionStatusChange",
  CreateTask = "CreateTask",
  RestoreTask = "RestoreTask",
  MergeFile = "MergeFile",
  PostponeTask = "PostponeTask",
  ChangeFollowUpDate = "ChangeFollowUpDate",
  ChangeDueDate = "ChangeDueDate",
  ViewFile = "ViewFile",
  FileNameChanged = "FileNameChanged",
  ChangeConditionFlag = "ChangeConditionFlag",
  ChangeDescription = "ChangeDescription",
  ChangeNotifyParty = "ChangeNotifyParty",
  ChangePriority = "ChangePriority",
  ChangeResponsibleParty = "ChangeResponsibleParty",
  ChangeReviewRequired = "ChangeReviewRequired",
  Email = "Email",
  Sms = "Sms",
  Voice = "Voice",
  Rvm = "Rvm",
  LoanPausedEmailSms = "LoanPausedEmailSms",
  LoanUnpausedEmailSm = "LoanUnpausedEmailSm"
}

export enum ActivityTypeCategory {
  LoanChangeStatus = "LoanChangeStatus",
  BorrowerMessage = "BorrowerMessage",
  InternalMessage = "InternalMessage",
  Lead = "Lead",
  DocumentFile = "DocumentFile",
  EmailSmsVoice = "EmailSmsVoice",
  LosMessage = "LosMessage",
  PauseEmailSms = "PauseEmailSms",
  Email = "Email",
  Sms = "Sms",
  Voice = "Voice"
}
