<div class="card tpo-report-card team-card">
  <div class="card-body" *ngIf="loanSummary">
    <div class="media align-items-center">
      <div class="media-body align-self-center">
        <h4 class="team-leader m-0 text-underline">{{loanSummary.borrowerName}}</h4>
      </div>
    </div>
    <hr class="my-2">
    <div class="media align-items-center">
      <div class="media-body align-self-center">
        <h5 class="team-leader-sm m-0">{{loanSummary.streetAddress}}</h5>
        <h5 class="team-leader-sm m-0">{{loanSummary.city}}</h5>
        <p class="text-muted mb-0">Address</p>
      </div>
    </div>
    <hr class="hr-dashed my-2">
    <div class="media align-items-center">
      <div class="media-body align-self-center">
        <h5 class="team-leader-sm m-0">{{loanSummary.loanStatusAlias ? loanSummary.loanStatusAlias : (loanSummary.loanStatus || "---")}}</h5>
        <p class="text-muted mb-0">Loan Status</p>
      </div>
    </div>
    <hr class="hr-dashed my-2">
    <div class="media align-items-center">
      <div class="media-body align-self-center">
        <h5 class="team-leader-sm mt-2 m-0">{{loanSummary.loanNumber ? loanSummary.loanNumber : "---"}}</h5>
        <p class="text-muted mb-0">Loan Number</p>
      </div>
    </div>
    <hr class="hr-dashed my-2">
    <div class="media align-items-center">
      <div class="media-body align-self-center">
        <h5 class="team-leader-sm mt-2 m-0">{{loanSummary.channel ? loanSummary.channel : "---"}}</h5>
        <p class="text-muted mb-0">Channel</p>
      </div>
    </div>
  </div>
  <loading-indicator *ngIf="!loanSummary" [loadingMessage]="''"></loading-indicator>
</div>