import { Component, Input, OnInit } from '@angular/core';
import { MortgageTerm, RegulationZDetail } from 'src/app/models';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { ProductPricing } from 'src/app/models/loan/product-pricing.model';

@Component({
  selector: 'apr-apor',
  templateUrl: 'apr-apor.component.html',
  styleUrls: ['./apr-apor.component.scss']
})

export class AprAporComponent implements OnInit {

  @Input()
  regulationZDetail: RegulationZDetail;

  @Input()
  qualifyingRateTypes: EnumerationItem[];

  @Input()
  qualifiedMortgageTemporaryGSETypes: EnumerationItem[];

  @Input()
  mortgageTerm: MortgageTerm
  
  @Input()
  productPricing: ProductPricing

  constructor() { }

  ngOnInit() {
   }

  onQualifyingRateTypeChanged = () => {
    if (this.regulationZDetail.qualifyingRateType !== 'Other') {
      this.regulationZDetail.qualifyingRateTypeOtherDescription = null;
    }
  }
}