import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocumentConfigRoutingModule } from './document-config-routing.module';
import { MultiSelectModule } from 'primeng/multiselect';
import { TableModule } from 'primeng/table';
import { SharedModule } from 'src/app/shared/shared.module';
import { DocumentTypesComponent } from './components/document-types/document-types.component';
import { StackingOrderComponent } from './components/stacking-order/stacking-order.component';
import { DocumentTypeService } from './services/document-type.service';
import { DocumentTypeDetailsDialogComponent } from './dialogs/document-type-details-dialog/document-type-details-dialog.component';
import { DocumentTypeDetailsComponent } from './dialogs/document-type-details-dialog/document-type-details/document-type-details.component';
import { StackingOrderService } from './services/stacking-order.service';
import { StackingOrderDetailsDialogComponent } from './dialogs/stacking-order-details-dialog/stacking-order-details-dialog.component';
import { StackingOrderDetailsComponent } from './dialogs/stacking-order-details-dialog/stacking-order-details/stacking-order-details.component';
import { StackingOrderDocumentAssociationDialogComponent } from './dialogs/stacking-order-document-association-dialog/stacking-order-document-association-dialog.component';
import { TreeModule } from 'primeng/tree';

@NgModule({
  declarations: [
    DocumentTypesComponent,
    StackingOrderComponent,
    DocumentTypeDetailsDialogComponent,
    DocumentTypeDetailsComponent,
    StackingOrderDetailsDialogComponent,
    StackingOrderDetailsComponent,
    StackingOrderDocumentAssociationDialogComponent
  ],
  imports: [
    CommonModule,
    DocumentConfigRoutingModule,
    SharedModule,
    TableModule,
    MultiSelectModule,
    TreeModule
  ],
  providers: [
    DocumentTypeService,
    StackingOrderService
  ]
})
export class DocumentConfigModule { }
