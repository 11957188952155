import { Component, Injector, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApplicationContext, DocumentType, LoanApplication, LoanDoc, Role, UserPermissions } from 'src/app/models';
import { GlobalConfig } from 'src/app/models/config/global-config.model';
import { StackingOrderDocTypes } from 'src/app/models/config/stacking-order-doc-types.model';
import { Constants } from 'src/app/services/constants';
import { ApplicationMode, NavigationService } from 'src/app/services/navigation.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { VendorsList } from '../models/vendors-list-model';
import { LoanDocsService } from '../services/loan-docs.service';
import { LoanDocsDialogComponent } from './loan-docs-dialog/loan-docs-dialog.component';
import { SyncLodaDocWithLosDialogComponent } from './sync-loda-doc-with-los-dialog/sync-loda-doc-with-los-dialog.component';
import * as _ from "lodash";
import { Subscription } from 'rxjs';
import { LosService } from 'src/app/services/los.service';

@Component({
  selector: 'loan-docs',
  templateUrl: 'loan-docs.component.html',
  styleUrls: ['loan-docs.component.scss'],
})

export class LoanDocsComponent extends ApplicationContextBoundComponent implements OnInit {

  loadingLoanDocs: boolean = true;

  filteredStackingOrders: StackingOrderDocTypes[];

  userPermissions: UserPermissions;

  roleId: number;

  uploadedFiles: Array<File> = [];

  filteredDocumentTypes: DocumentType[] = [];

  loanDocs: LoanDoc[] = [];

  isTpoUser: boolean;

  documentTypesPerRoles: DocumentType[] = [];

  roles: Role[];

  currentApplication: LoanApplication;

  docFilter: string = 'all';

  losEnabled: any;

  userType: string;

  globalConfig: GlobalConfig;

  selectedStackingOrderId: number;

  errorMessage: string = null;

  isCompanyDeepHaven: boolean;

  private vendorsList: VendorsList;

  private losVendorStr: string;

  private _uploadedFiles: Array<File> = [];

  private _loanDocsServiceEventSubscription: Subscription = null;
  private _loanInfoChangesSubscription: Subscription;

  constructor(private readonly injector: Injector,
    private readonly _loanDocsService: LoanDocsService,
    private readonly _losService: LosService,
    private readonly _modalService: NgbModal,
    private readonly _notifyService: NotificationService,
    private readonly _navigationService: NavigationService) {
    super(injector);
    this.applicationContextService.loanInfoLoadError.subscribe(error => {
      this.errorMessage = error;
      this.loadingLoanDocs = false;
    });
    this._loanDocsServiceEventSubscription = this._loanDocsService.documentGenerated.subscribe(() => {
      this.getLoanDocs(this.currentApplication.applicationId, true);
    });
    this._loanDocsService.documentUploaded.subscribe(() => {
      this.getLoanDocs(this.currentApplication.applicationId, true);
    });
    this.applicationContextService.loanTasksChanges.subscribe(() => {
      this.getLoanDocs(this.currentApplication.applicationId, true);
    });
  }

  ngOnInit(): void {
    if (!this.applicationContext?.application?.applicationId) {
      this._loanInfoChangesSubscription = this.applicationContextService.loanInfoChanges.subscribe((context) => {
        if (context.application) {
          this.initialize(context);
        }
      });
    } else {
      this.initialize(this.applicationContext);
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this._loanDocsServiceEventSubscription) {
      this._loanDocsServiceEventSubscription.unsubscribe();
    }
    if (this._loanInfoChangesSubscription) {
      this._loanInfoChangesSubscription.unsubscribe();
    }
  }


  onFileUploadChanged = (uploadedFiles: File[]) => {
    if (uploadedFiles.length > 0) {
      this._uploadedFiles = _.cloneDeep(uploadedFiles);
      this.uploadedFiles = [];
      this.onAddLoanDocClicked();
    }
  }


  onStackingOrderChanged = (selectedId: number) => {
    this.filteredDocumentTypes = this.filterDocTypesByStackingOrder(selectedId);
    this.setFilteredDocTypeCount(this.filteredDocumentTypes);
  }

  filteredDocTypeCount = {};
  setFilteredDocTypeCount = (filteredDocTypeList: DocumentType[]) => {
    this.filteredDocTypeCount = {};
    this.filteredDocumentTypes.forEach(x => {
      if (!this.filteredDocTypeCount[x.documentTypeId]) {
        this.filteredDocTypeCount[x.documentTypeId] = 1;
      }
    });
  }


  showDocType = (type: DocumentType) => {
    if (!Array.isArray(this.loanDocs) || this.loanDocs.length == 0) {
      return false;
    }

    const canShow = (this.loanDocTypeCount[type.documentTypeId] > 0) && (this.filteredDocTypeCount[type.documentTypeId] > 0);

    // const canShow = (this.filteredDocumentTypes.filter(dt => dt.documentTypeId == type.documentTypeId).length > 0) &&
    //   (this.loanDocs.filter(ld => ld.documentTypeId == type.documentTypeId).length > 0);
    return canShow;
  }

  onLoanDocUpdated = (loanDoc: LoanDoc) => {
    const existingOne = this.loanDocs.find(d => (d.loanDocId && loanDoc.loanDocId && d.loanDocId == loanDoc.loanDocId) || (d.losLoanDocId && loanDoc.losLoanDocId && d.losLoanDocId == loanDoc.losLoanDocId));
    if (existingOne) {
      const index = this.loanDocs.indexOf(existingOne);
      if (index >= 0) {
        this.loanDocs[index] = loanDoc;
        this.loanDocs = [...this.loanDocs];
        this.generateDocumentTypeMetaData();
      }
    }
  }

  onAddLoanDocClicked = () => {
    const modalRef = this._modalService.open(LoanDocsDialogComponent, Constants.modalOptions.xlarge)
    modalRef.componentInstance.title = 'New Loan Doc';
    modalRef.componentInstance.docTypes = this.documentTypesPerRoles.filter(x => !this.isTpoUser || x.allowUploadOnChannels?.includes('Wholesale'));
    modalRef.componentInstance.appId = this.currentApplication.applicationId;
    modalRef.componentInstance.roleId = this.roleId;
    modalRef.componentInstance.isTpoUser = this.isTpoUser;
    modalRef.componentInstance.autoConvertToPdf = this.userPermissions.autoConvertToPdf;
    modalRef.componentInstance.uploadedFiles = this._uploadedFiles;
    modalRef.result.then((result) => {
      this._uploadedFiles = [];
      if (!result.documentTypeId) {
        result.documentTypeId = 0;
      }
      this.loanDocs.push(result);
      this.loanDocs = [...this.loanDocs];
      this.generateDocumentTypeMetaData();
    }, (res) => {
      this._uploadedFiles = [];
    });
  }

  onOpenSyncLodaDocWithLosClicked = () => {
    const modalRef = this._modalService.open(SyncLodaDocWithLosDialogComponent, Constants.modalOptions.xlarge)
    modalRef.componentInstance.title = 'Synchronize Documents With LOS';
    modalRef.componentInstance.appId = this.currentApplication.applicationId;
    modalRef.componentInstance.userId = this.currentApplication.userId;
    modalRef.componentInstance.companyId = this.userPermissions.companyId;
    modalRef.componentInstance.docTypes = this.globalConfig.documentType.filter(doc => doc.documentTypeId > -1);
    modalRef.componentInstance.losVendor = this.filteredVendors(this.vendorsList, this.currentApplication.losVendor);
    modalRef.result.then((result) => {
      if (result === 'cancel') {
        return;
      }

    }, (res) => {
    });
  }

  private filterDocTypesByRoles = (type: DocumentType) => {
    if (type.restrictedToRoles && type.restrictedToRoles.length > 0) {
      this.roleId = this.applicationContext.userPermissions.roleId;
      let vals = type.restrictedToRoles.split(',');
      return vals.filter(x => Number(x) == this.roleId).length > 0 && (!this.isTpoUser || type.showOnChannels?.includes('Wholesale'));
    }
    return type.documentTypeId > (this.isTpoUser ? -1 : -2) && (!this.isTpoUser || type.showOnChannels?.includes('Wholesale'));
  }

  private filterDocTypesByStackingOrder = (selectedId: number): DocumentType[] => {
    if (selectedId == 0) {
      return this.documentTypesPerRoles;
    }
    if (this.applicationContext.globalConfig.stackingOrderDocTypes) {
      const filteredDocumentTypes = [];
      let stOrder = this.applicationContext.globalConfig.stackingOrderDocTypes.find(x => x.stakingOrderId == selectedId);
      if (stOrder && stOrder.associate) {
        let associatedDocTypes = stOrder.associate.split(',');
        if (associatedDocTypes) {
          associatedDocTypes.forEach(associatedDocTypeId => {
            let documentType = this.documentTypesPerRoles.find(x => x.documentTypeId == Number(associatedDocTypeId));
            if (documentType && !filteredDocumentTypes.find(x => x.documentTypeId == documentType.documentTypeId)) {
              filteredDocumentTypes.push(documentType);
            }
          });
        }
      }
      return filteredDocumentTypes;
    }
    return this.documentTypesPerRoles;
  }

  loanDocTypeCount = {};
  private getLoanDocs = (loanId: number, importLosDocs: boolean) => {
    this.loanDocTypeCount = {};
    this.loadingLoanDocs = true;
    this._loanDocsService.getLoanDocs(loanId, importLosDocs).subscribe(result => {
      this.loanDocs = result;
      this.generateDocumentTypeMetaData()
    }, error => {

    }).add(() => {
      this.loadingLoanDocs = false;
    })
  }

  private generateDocumentTypeMetaData = () => {
    this.loanDocs.forEach(d => {

      if (!this.loanDocTypeCount[d.documentTypeId]) {
        this.loanDocTypeCount[d.documentTypeId] = 0;
      }

      if (d.docFiles && d.docFiles.length) {
        const inactiveFiles = d.docFiles.filter(f => !f.active);
        if (inactiveFiles.length === d.docFiles.length) {
          d.documentTypeId = -1;
        }
      }

      if (d.documentTypeId == -1) {
        this.loanDocTypeCount[d.documentTypeId] += d.docFiles?.filter(f => !f.active).length ?? 0;
      } else {
        this.loanDocTypeCount[d.documentTypeId] += d.docFiles?.filter(f => f.active).length ?? 0;
      }
    })
  }

  private getVendors = () => {
    this._losService.getVendors().subscribe(result => {
      this.vendorsList = result;
    }, err => {
      this._notifyService.showError(err ? err.error : "Couldn't get vendor list!", 'Failure');
    })
  }

  private filteredVendors = (vendorsList: VendorsList, losVendor: string) => {
    if (vendorsList.values.length === 0) return "";
    const index = vendorsList.values.findIndex(value => value === losVendor);
    this.losVendorStr = vendorsList.names[index];
    return this.losVendorStr;
  }

  private initialize = (context: ApplicationContext) => {
    this.isTpoUser = context.isTpo;
    this.globalConfig = context.globalConfig;
    this.currentApplication = context.application;
    this.filteredStackingOrders = this.globalConfig.stackingOrderDocTypes.filter(docType => !this.isTpoUser || docType.showOnTPOPortal);
    this.userPermissions = context.userPermissions;
    this.selectedStackingOrderId = this.userPermissions.stackingOrderId ? this.userPermissions.stackingOrderId : 0;
    this.roles = this.globalConfig.roles;
    this.documentTypesPerRoles = this.globalConfig.documentType.filter(docType => this.filterDocTypesByRoles(docType));
    this.filteredDocumentTypes = this.filterDocTypesByStackingOrder(this.selectedStackingOrderId);
    this.setFilteredDocTypeCount(this.filteredDocumentTypes);
    this.getLoanDocs(this.currentApplication.applicationId, true);
    if (this.isTpoUser) {
      this.userType = 'tpo';
    } else {
      this.userType = this._navigationService.applicationMode == ApplicationMode.Classic ? 'admin' :
        this._navigationService.applicationMode == ApplicationMode.NextGen ? 'loda-nextgen' : 'admin';
    }
    this.isCompanyDeepHaven = context.isCompanyDeepHaven;
    this.getVendors();
  }
}
