import { Injectable } from '@angular/core';

@Injectable()
export class DocumentService {
  constructor() {}

  convertBase64IntoPdf(data: string): string {
    const byteCharacters = atob(data);
    const byteNumbers = new Array(byteCharacters.length);
    for (var i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const file = new Blob([byteArray], { type: 'application/pdf;base64' });
    const fileURL = URL.createObjectURL(file);
    return fileURL;
  }

  convertBase64ToBlob(data: string) : Blob {
    // convert base64 string to byte array
    const binaryString = atob(data);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++)
      bytes[i] = binaryString.charCodeAt(i);

    // Create a Blob from the byte array
    return new Blob([bytes], { type: "application/pdf" });
  }

  convertBase64IntoHtml(data: string): string {
    const byteCharacters = atob(data);
    return byteCharacters;
  }

  convertBlobIntoUrl(data: BlobPart, type: string): string {
    const blob = new Blob([data], { type: type })
    return URL.createObjectURL(blob);
  }
}
