import { Question } from "./question.model"

export class QuestionAnswer {
  questionAnswerId: number;
  questionId: number;
  answerText: string;
  sortValue: number;
  taskIds: number[];
  taskOptions: string[];
  loanCharacteristicIds: number[];
  loanCharacteristicOptions: string[];
  borrowerCharacteristicIds: number[];
  borrowerCharacteristicOptions: string[];
  require: boolean;
  exist: boolean;
}
