import { Component, EventEmitter, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { combineLatest, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Constants } from 'src/app/services/constants';
import { DataService } from '../../../../core/services/data.service';
import { EnumerationItem } from '../../../../models/simple-enum-item.model';
import { NotificationService } from '../../../../services/notification.service';
import { SystemLevelService } from '../../../../services/system-level.service';
import { MergeFieldContextMenuComponent } from '../../../../shared/components/merge-field-context-menu/merge-field-context-menu.component';
import { DefinedEmailTemplate, MapDirection, RoleBCC, RoleCC } from '../../models';
import { Configuration } from 'src/app/models/configuration.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApplicationContextBoundComponent } from '../../../../shared/components';
import { Utils } from 'src/app/core/services/utils';
import { EnvironmentService } from 'src/app/core/services/environment/environment.service';
import { StripoRichTextEditorComponent } from 'src/app/shared/components/stripo-rich-text-editor/stripo-rich-text-editor.component';
import { DrawerOptions, DrawerService, DrawerSize, DynamicComponentInfo } from 'src/app/shared/services/drawer.service';
import { StripoEmailEditorComponent, StripoEditorSaveClickedEvent } from 'src/app/shared/components/stripo-email-editor/stripo-email-editor.component';
import { DrawerComponent } from 'src/app/shared/components/drawer/drawer.component';
import { concat, isArray } from 'lodash';
import { BranchService } from 'src/app/modules/admin/company/services/branch.service';
import { BranchSettings } from 'src/app/modules/admin/company/models/branch.model';
import { ChannelService } from 'src/app/services/channel.service';

@Component({
  selector: 'email-templates',
  templateUrl: './email-templates.component.html',
})
export class EmailTemplatesComponent extends ApplicationContextBoundComponent implements OnInit {

  @ViewChild("editEmailTemplateDrawer")
  editEmailTemplateDrawer: DrawerComponent;

  @ViewChild("agentInviteEmailEditor")
  agentInviteEmailEditor: StripoRichTextEditorComponent;

  @ViewChild('appMergeFieldsMenu')
  appMergeFieldsMenu: MergeFieldContextMenuComponent;

  @ViewChild('leadMergeFieldsMenu')
  leadMergeFieldsMenu: MergeFieldContextMenuComponent;

  @Input()
  definedEmailTemplate: DefinedEmailTemplate;

  @Input()
  set branchSettings(settings: BranchSettings) {
    this._branchSettings = settings;
    if (settings) {
      this.definedEmailTemplate = settings.definedEmailTemplate;
    }
  }

  @Input()
  type: 'branch' | 'system-level';

  @Input()
  reminderEmailEnabled: Configuration;

  @Input()
  reminderEmailInterval: Configuration;

  @Output()
  settingsUpdated: EventEmitter<void> = new EventEmitter<void>();

  availableMergeFields: EnumerationItem[] = [];
  leadMergeFieldsWithCurrentNote: EnumerationItem[] = [];
  appMergeFieldsWithCurrentNote: EnumerationItem[] = [];

  roles: EnumerationItem[] = [];
  ccSelectedRoles = [];
  bccSelectedRoles = [];
  dropdownSettings: IDropdownSettings = {};
  isSaving: boolean;
  defaultEmailTemplates = [];

  ckEditorConfig = Constants.defaultCkEditorConfig;

  requestRolesCC: Array<RoleCC> = []
  requestRolesBCC: Array<RoleBCC> = []
  reminderRolesCC: Array<RoleCC> = []
  reminderRolesBCC: Array<RoleBCC> = []
  onlineAppStartedRolesCC: Array<RoleCC> = []
  onlineAppStartedRolesBCC: Array<RoleBCC> = []
  onlineAppSubmissionRolesCC: Array<RoleCC> = []
  onlineAppSubmissionRolesBCC: Array<RoleBCC> = []
  esignCompletedRolesCC: Array<RoleCC> = []
  esignCompletedRolesBCC: Array<RoleBCC> = []
  losEsignCompletedRolesCC: Array<RoleCC> = []
  losEsignCompletedRolesBCC: Array<RoleBCC> = []
  lockRequestRolesTo: Array<RoleCC> = []
  lockRequestRolesCC: Array<RoleCC> = []
  lockRequestRolesBCC: Array<RoleBCC> = []
  denialNotificationRolesTo: Array<RoleCC> = []
  denialNotificationRolesCC: Array<RoleCC> = []
  denialNotificationRolesBCC: Array<RoleBCC> = []
  qcuLeadReferralRolesTo: Array<RoleCC> = []
  qcuLeadReferralRolesCC: Array<RoleCC> = []
  qcuLeadReferralRolesBCC: Array<RoleBCC> = []
  qcuPreApprovedRolesTo: Array<RoleCC> = []
  qcuPreApprovedRolesCC: Array<RoleCC> = []
  qcuPreApprovedRolesBCC: Array<RoleBCC> = []
  qcuInProcessRolesTo: Array<RoleCC> = []
  qcuInProcessRolesCC: Array<RoleCC> = []
  qcuInProcessRolesBCC: Array<RoleBCC> = []
  borrowerVerificationCompletedRolesCC: Array<RoleCC> = []
  borrowerVerificationCompletedRolesBCC: Array<RoleCC> = []

  protected collapsibleSectionVisibilityStatuses: any = {};

  protected editEmailTemplateDrawerOptions: DrawerOptions = {
    size: DrawerSize.XXXXLarge,
    containerWrapperId: null
  };

  editTemplateDrawerOptions: DrawerOptions = {
    size: DrawerSize.XXXXLarge,
    containerWrapperId: null
  };

  private _currentNoteMergeField = {
    name: 'Current Note',
    value: 'CurrentNote',
    groupName: ''
  };
  companyGuid: string;
  baseApiUrl: string;

  corrNonDelChannelsEnabled: boolean;

  private _dynamicEventSubscriptions: Subscription[] = [];

  private _branchSettings: BranchSettings;

  constructor(
    injector: Injector,
    private readonly _environmentService: EnvironmentService,
    private readonly _systemLevelService: SystemLevelService,
    private readonly _branchService: BranchService,
    private readonly _notificationService: NotificationService,
    private readonly _dataService: DataService,
    private readonly _channelService: ChannelService,
    private readonly _drawerService: DrawerService,
    private readonly _spinnerService: NgxSpinnerService
  ) {
    super(injector);
    this.collapsibleSectionVisibilityStatuses.inviteEmail = false;
    this.collapsibleSectionVisibilityStatuses.videoMessage = false;
  }

  ngOnInit(): void {
    this.companyGuid = this.applicationContext.globalConfig.company[0].companyGUID;
    this.baseApiUrl = this._environmentService.apiInfo.apiBaseUrl;

    const enabledChannels = this._channelService.getChannelsFromCommaDelimitedString(this.applicationContext.userPermissions.enabledChannels);
    this.corrNonDelChannelsEnabled = enabledChannels.some(e => ["Correspondent","NonDelegatedCorrespondent"].includes(e.name));

    // add missing properties required by ckeditor
    // branch handles this functionality on its own
    // below code doesn't remove reference from branchSettings
    if (this.type !== 'branch') {
      this.definedEmailTemplate = Object.assign(this.definedEmailTemplate, new DefinedEmailTemplate(this.definedEmailTemplate));
    }

    this.dropdownSettings = {
      idField: 'value',
      textField: 'name',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
    this.roles = concat(
      this.applicationContext.globalConfig.roles.map(r => ({ id: r.roleId, value: `IC_${r.roleId}`, name: r.roleName, groupName: "Internal Contacts" })),
      this.applicationContext.globalConfig.agentType.map(a => ({ id: a.agentTypeId, value: `EC_${a.agentTypeId}`, name: a.agentTypeName, groupName: "External Contacts" }))
    );
    this.mapCCAndBCCRolesOnInit(this.definedEmailTemplate);

    this.loadMergeFields();
    this.loadEmailTemplates();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this._dynamicEventSubscriptions.forEach(s => s?.unsubscribe());
  }

  async save(): Promise<void> {
    if (this.type === 'branch') {
      await this.saveBranchLevel();
    } else {
      await this.saveSystemLevel();
    }
  }

  loadEmailTemplates(): void {
    this._spinnerService.show()
    this._dataService.get(`api/Admin/AllGlobalEmailTemplates`)
      .pipe(finalize(() => this._spinnerService.hide()))
      .subscribe({
        next: res => {
          this.defaultEmailTemplates = res;
        }
      });
  }

  protected populateDefaultEmailTemplate(
    isChecked: boolean,
    templateType: string,
    subject: string,
    email: string,
    forceReload: boolean = false,
  ): void {
    if (!isChecked) {
      return;
    }

    const matchingTemplate = this.defaultEmailTemplates.find(template => template.templateType === templateType);
    if (matchingTemplate) {
      if (forceReload || !this.definedEmailTemplate[subject]) {
        this.definedEmailTemplate[subject] = matchingTemplate.subject;
      }
      if (forceReload || !this.definedEmailTemplate[email]) {
        this.definedEmailTemplate[email] = matchingTemplate.email;
        this.replaceMergeFieldsForImages(this.definedEmailTemplate, email, MapDirection.load);
        this.loadEmailTemplatePreview(email);
      }

      // This should be done regardless of `forceReload` because iframe content is removed when the template is changed.
      this.loadEmailTemplatePreview(email);
    }
  }

  onRoleMultiselectChanged(type: string) {
    this.mapCCAndBCCRolesForDefinedTemplate(type);
  }

  onEmailTemplateEditorVisibilityChanged = (templateType: string) => {
    this.collapsibleSectionVisibilityStatuses[templateType] = !this.collapsibleSectionVisibilityStatuses[templateType];
    this.loadEmailTemplatePreview(templateType);
  }

  private loadMergeFields(): void {
    const combined = combineLatest([
      this._dataService.get('api/configuration/document-templates/app-global-merge-field-keys'),
      this._dataService.get('api/configuration/document-templates/lead-global-merge-field-keys'),
      this._dataService.get('api/configuration/document-templates/agent-global-merge-field-keys'),
      this._dataService.get('api/configuration/document-templates/borrower-global-merge-field-keys')
    ]);

    combined.subscribe({
      next: ([appMergeFields, leadMergeFields, agentMergeFields, borrowerMergeFields]) => {
        this.leadMergeFieldsWithCurrentNote = leadMergeFields.map(field => ({
          name: Utils.spinalTapCaseString(field ? field : null),
          value: field,
          groupName: ''
        })).concat(this._currentNoteMergeField);

        this.appMergeFieldsWithCurrentNote = appMergeFields.map(field => ({
          name: Utils.spinalTapCaseString(field ? field : null),
          value: field,
          groupName: ''
        })).concat(this._currentNoteMergeField);

        this.availableMergeFields = [
          ...appMergeFields,
          // TFS 17409: Commented out the below merge fields as they are not used in the email templates
          // ...agentMergeFields,
          // ...borrowerMergeFields
        ].map(field => ({
          name: Utils.spinalTapCaseString(field ? field : null),
          value: field,
          groupName: ''
        })
        );
      }
    });
  }

  private async saveSystemLevel(): Promise<void> {
    this.isSaving = true;

    this.replaceMergeFieldsForImagesBeforeSave(this.definedEmailTemplate);
    this._spinnerService.show();
    combineLatest([
      this._systemLevelService.saveDefinedEmailTemplate(this.definedEmailTemplate),
      this._systemLevelService.saveConfiguration(this.reminderEmailEnabled),
      this._systemLevelService.saveConfiguration(this.reminderEmailInterval),
    ]).pipe(finalize(() => {
      this.isSaving = false;
      this._spinnerService.hide();
    })).subscribe({
      next: ([definedTemplate, reminderEnabled, reminderInterval]) => {
        this.definedEmailTemplate = definedTemplate;
        this.reloadEmailTemplatePreviewsForVisibleSections();
        this.mapCCAndBCCRolesOnInit(this.definedEmailTemplate);
        this.settingsUpdated.emit();
        this._notificationService.showSuccess(`Settings saved successfully.`, 'System Level');
      },
      error: error => {
        this._notificationService.showError(`${error ? error.message : 'Unable to save.'}`, 'System Level');
      }
    });
  }

  private async saveBranchLevel(): Promise<void> {
    this.isSaving = true;

    this.replaceMergeFieldsForImagesBeforeSave(this.definedEmailTemplate);

    const observer = {
      next: (branchSettings) => {
        this.branchSettings = branchSettings;
        this.reloadEmailTemplatePreviewsForVisibleSections();
        this.mapCCAndBCCRolesOnInit(this.definedEmailTemplate);
        this.settingsUpdated.emit();
        this._notificationService.showSuccess(`Settings saved successfully.`, 'Branch Level');
      },
      error: error => {
        this._notificationService.showError(`${error ? error.message : 'Unable to save.'}`, 'Branch Level');
      }
    }

    this._spinnerService.show();
    this._branchSettings.definedEmailTemplate = this.definedEmailTemplate;
    this._branchService.upsertBranchSettings(this._branchSettings, this._branchSettings.branchId).subscribe(observer)
      .add(() => {
        this.isSaving = false;
        this._spinnerService.hide();
      });
  }


  private reloadEmailTemplatePreviewsForVisibleSections = () => {
    Object.keys(this.collapsibleSectionVisibilityStatuses).forEach(templateType => {
      if (this.collapsibleSectionVisibilityStatuses[templateType]) {
        this.loadEmailTemplatePreview(templateType);
      }
    })
  }

  private loadEmailTemplatePreview = (templateType: string) => {
    setTimeout(() => {
      let iframe = document.getElementById(`${templateType}Iframe`) as HTMLIFrameElement;
      if (!iframe) {
        return;
      }
      let doc = iframe.contentDocument;
      doc.open();
      doc.write(this.definedEmailTemplate[templateType]);
      doc.close();
    });
  }

  private mapRolesForCCAndBCC = (type: string) => {
    if (!this.definedEmailTemplate[type]) {
      this[type] = [];
    } else {
      this[type] = this.definedEmailTemplate[type]?.split(",");
    }
  }

  private replaceMergeFieldsForImages = (definedEmailTemplate: DefinedEmailTemplate, type: string, mode: MapDirection) => {
    if (!definedEmailTemplate[type]) {
      this[type] = [];
    } else {
      if (mode == MapDirection.load) {
        definedEmailTemplate[type] = definedEmailTemplate[type]?.replace("${CompanyGuid}", this.companyGuid);
        definedEmailTemplate[type] = definedEmailTemplate[type]?.replace("${BaseApiUrl}", this.baseApiUrl);
      }
      else {
        definedEmailTemplate[type] = definedEmailTemplate[type]?.replace(this.companyGuid, "${CompanyGuid}");
        definedEmailTemplate[type] = definedEmailTemplate[type]?.replace(this.baseApiUrl, "${BaseApiUrl}");
      }
    }
  }

  onContextMenu($event: MouseEvent, target: DefinedEmailTemplate, targetProperty: string, type: string = 'app'): void {
    if (type === 'app') {
      this.appMergeFieldsMenu.show($event, target, targetProperty, null);
    } else {
      this.leadMergeFieldsMenu.show($event, target, targetProperty, null);
    }
    $event.preventDefault();
    $event.stopPropagation();
  }

  onEditTemplateClicked = (html: string, documentId: string, openWithStripo: boolean) => {
    this._drawerService.hide("editEmailTemplateDrawer");
    let dynamicComponentInfo = new DynamicComponentInfo();
    dynamicComponentInfo.componentType = StripoEmailEditorComponent;
    dynamicComponentInfo.parameters.set('html', html);
    dynamicComponentInfo.parameters.set('documentId', documentId);
    const mergeFields = [...this.availableMergeFields];

    dynamicComponentInfo.parameters.set('mergeFields', mergeFields);
    dynamicComponentInfo.parameters.set('useStripoEditor', openWithStripo);
    this._drawerService.show("editEmailTemplateDrawer", 100, "Edit Email Template", dynamicComponentInfo).then(() => {
      const subscription = this.editEmailTemplateDrawer.componentInstance.saveClicked.subscribe((saveInfo: StripoEditorSaveClickedEvent) => {
        this.definedEmailTemplate[documentId] = saveInfo.html;
        this.definedEmailTemplate[documentId.replace("Email", "UsingStripoEditor")] = saveInfo.savingUsingStripo;
        this.save();
      });
      const subscription2 = this.editEmailTemplateDrawer.componentInstance.cancelClicked.subscribe(() => {
        this._drawerService.hide("editEmailTemplateDrawer", 100);
      });
      this._dynamicEventSubscriptions.push(subscription);
      this._dynamicEventSubscriptions.push(subscription2);
    });
  }

  onTemplateEditorDrawerClosed = () => {

  }

  private mapCCAndBCCRolesForDefinedTemplate = (type: string) => {
    this.definedEmailTemplate[type] = isArray(this[type]) ? this[type].join(',') : null;
  }

  private mapCCAndBCCRolesOnInit = (definedEmailTemplate: DefinedEmailTemplate) => {

    this.replaceMergeFieldsForImages(definedEmailTemplate, "requestEmail", MapDirection.load);
    this.replaceMergeFieldsForImages(definedEmailTemplate, "reminderEmail", MapDirection.load);
    this.replaceMergeFieldsForImages(definedEmailTemplate, "onlineAppRegistrationEmail", MapDirection.load);
    this.replaceMergeFieldsForImages(definedEmailTemplate, "onlineAppStartedEmail", MapDirection.load);
    this.replaceMergeFieldsForImages(definedEmailTemplate, "onlineAppSubmissionEmail", MapDirection.load);
    this.replaceMergeFieldsForImages(definedEmailTemplate, "esignCompletedEmail", MapDirection.load);
    this.replaceMergeFieldsForImages(definedEmailTemplate, "losEsignCompletedEmail", MapDirection.load);
    this.replaceMergeFieldsForImages(definedEmailTemplate, "lockRequestEmail", MapDirection.load);
    this.replaceMergeFieldsForImages(definedEmailTemplate, "inviteEmail", MapDirection.load);
    this.replaceMergeFieldsForImages(definedEmailTemplate, "inviteAgentEmail", MapDirection.load);
    this.replaceMergeFieldsForImages(definedEmailTemplate, "agentEsignEmail", MapDirection.load);
    this.replaceMergeFieldsForImages(definedEmailTemplate, "borrowerEsignEmail", MapDirection.load);
    this.replaceMergeFieldsForImages(definedEmailTemplate, "corrNonDelBorrowerEsign", MapDirection.load);
    this.replaceMergeFieldsForImages(definedEmailTemplate, "internalEsignEmail", MapDirection.load);
    this.replaceMergeFieldsForImages(definedEmailTemplate, "denialNotificationEmail", MapDirection.load);
    this.replaceMergeFieldsForImages(definedEmailTemplate, "qcuLeadReferralEmail", MapDirection.load);
    this.replaceMergeFieldsForImages(definedEmailTemplate, "qcuPreApprovedEmail", MapDirection.load);
    this.replaceMergeFieldsForImages(definedEmailTemplate, "qcuInProcessEmail", MapDirection.load);
    this.replaceMergeFieldsForImages(definedEmailTemplate, "borrowerVerificationCompletedEmail", MapDirection.load);

    this.mapRolesForCCAndBCC("requestRolesCC");
    this.mapRolesForCCAndBCC("requestRolesBCC");

    this.mapRolesForCCAndBCC("reminderRolesCC");
    this.mapRolesForCCAndBCC("reminderRolesBCC");

    this.mapRolesForCCAndBCC("onlineAppStartedRolesCC");
    this.mapRolesForCCAndBCC("onlineAppStartedRolesBCC");

    this.mapRolesForCCAndBCC("onlineAppSubmissionRolesCC");
    this.mapRolesForCCAndBCC("onlineAppSubmissionRolesBCC");

    this.mapRolesForCCAndBCC("esignCompletedRolesCC");
    this.mapRolesForCCAndBCC("esignCompletedRolesBCC");

    this.mapRolesForCCAndBCC("losEsignCompletedRolesCC");
    this.mapRolesForCCAndBCC("losEsignCompletedRolesBCC");

    this.mapRolesForCCAndBCC("lockRequestRolesTo");
    this.mapRolesForCCAndBCC("lockRequestRolesCC");
    this.mapRolesForCCAndBCC("lockRequestRolesBCC");

    this.mapRolesForCCAndBCC("denialNotificationRolesTo");
    this.mapRolesForCCAndBCC("denialNotificationRolesCC");
    this.mapRolesForCCAndBCC("denialNotificationRolesBCC");

    this.mapRolesForCCAndBCC("qcuLeadReferralRolesTo");
    this.mapRolesForCCAndBCC("qcuLeadReferralRolesCC");
    this.mapRolesForCCAndBCC("qcuLeadReferralRolesBCC");

    this.mapRolesForCCAndBCC("qcuPreApprovedRolesTo");
    this.mapRolesForCCAndBCC("qcuPreApprovedRolesCC");
    this.mapRolesForCCAndBCC("qcuPreApprovedRolesBCC");

    this.mapRolesForCCAndBCC("qcuInProcessRolesTo");
    this.mapRolesForCCAndBCC("qcuInProcessRolesCC");
    this.mapRolesForCCAndBCC("qcuInProcessRolesBCC");

    this.mapRolesForCCAndBCC("borrowerVerificationCompletedRolesCC");
    this.mapRolesForCCAndBCC("borrowerVerificationCompletedRolesBCC");
  }

  public replaceMergeFieldsForImagesBeforeSave = (definedEmailTemplate: DefinedEmailTemplate) => {
    this.replaceMergeFieldsForImages(definedEmailTemplate, "inviteEmail", MapDirection.save);
    this.replaceMergeFieldsForImages(definedEmailTemplate, "inviteAgentEmail", MapDirection.save);
    this.replaceMergeFieldsForImages(definedEmailTemplate, "requestEmail", MapDirection.save);
    this.replaceMergeFieldsForImages(definedEmailTemplate, "reminderEmail", MapDirection.save);
    this.replaceMergeFieldsForImages(definedEmailTemplate, "onlineAppRegistrationEmail", MapDirection.save);
    this.replaceMergeFieldsForImages(definedEmailTemplate, "onlineAppStartedEmail", MapDirection.save);
    this.replaceMergeFieldsForImages(definedEmailTemplate, "onlineAppSubmissionEmail", MapDirection.save);
    this.replaceMergeFieldsForImages(definedEmailTemplate, "esignCompletedEmail", MapDirection.save);
    this.replaceMergeFieldsForImages(definedEmailTemplate, "losEsignCompletedEmail", MapDirection.save);
    this.replaceMergeFieldsForImages(definedEmailTemplate, "lockRequestEmail", MapDirection.save);
    this.replaceMergeFieldsForImages(definedEmailTemplate, "agentEsignEmail", MapDirection.save);
    this.replaceMergeFieldsForImages(definedEmailTemplate, "borrowerEsignEmail", MapDirection.save);
    this.replaceMergeFieldsForImages(definedEmailTemplate, "corrNonDelBorrowerEsign", MapDirection.save);
    this.replaceMergeFieldsForImages(definedEmailTemplate, "internalEsignEmail", MapDirection.save);
    this.replaceMergeFieldsForImages(definedEmailTemplate, "denialNotificationEmail", MapDirection.save);
    this.replaceMergeFieldsForImages(definedEmailTemplate, "qcuLeadReferralEmail", MapDirection.save);
    this.replaceMergeFieldsForImages(definedEmailTemplate, "qcuPreApprovedEmail", MapDirection.save);
    this.replaceMergeFieldsForImages(definedEmailTemplate, "qcuInProcessEmail", MapDirection.save);
    this.replaceMergeFieldsForImages(definedEmailTemplate, "borrowerVerificationCompletedEmail", MapDirection.save);
  }
}
