<form id="telephony" #telephonyForm="ngForm" name="telephonyForm">
  <div class="row pt-3" *ngIf="telephony">
    <div class="row">
      <div class="col-lg-6 form-group">
        <label class="form-label" for="incomingCallTextGreeting"> Incoming Call Text Greeting </label>
        <textarea id="incomingCallTextGreeting" name="incomingCallTextGreeting" class="form-control" style="height: 115px"
          [(ngModel)]="telephony.incomingCallInitialTextGreeting" rows="2"></textarea>
      </div>

      <div class="col-lg-6 form-group">
        <div class="w-100 m-auto" style="border: 2px dashed #889988">
          <file-upload [(ngModel)]="uploadedFile" multiple="false" name="file" (change)="test(uploadedFile)">
          </file-upload>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-6">
        <div class="col-12 ms-1 mb-2">
          <div class="form-check form-switch mb-1">
            <input class="form-check-input" [(ngModel)]="isForwardCallToUser" name="forwardCallToUser"
              id="forwardCallToUser" type="checkbox" (change)="onIsForwardCallToUserChanged()">
            <label class="form-check-label text-muted" for="forwardCallToUser">
              Forward to User
            </label>
          </div>
        </div>

        <ng-container *ngIf="isForwardCallToUser">
          <div class="row">
            <div class="form-group">
              <label for="users"> Users </label>
              <select id="users" name="users" class="form-select"
                [(ngModel)]="telephony.forwardCallToUserId" (change)="onForwardToUserChange()" #forwardCallToUserId="ngModel"
                [ngClass]="{ 'is-invalid': forwardCallToUserId && forwardCallToUserId.touched && forwardCallToUserId.invalid }" [disabled]="isCallForward" required>
                <option [ngValue]="null"> -- Select One -- </option>
                <option *ngFor="let user of users" [ngValue]="user.userCompanyGuid">
                  {{ user.lastName + ', ' + user.firstName }}
                </option>
              </select>
              <div class="invalid-feedback"> User or Call Forward Phone Number is required. </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group">
              <label for="forwardToUserPhone"> User Forward Phone Number </label>
              <div class="d-flex justify-content-between flex-wrap">
                <input id="forwardToUserPhone" class="form-control" name="forwardToUserPhone" style="width: 55%; min-width: 150px;" placeholder="Phone Number" type="text"
                  [mask]="'(000) 000-0000'" [showMaskTyped]="true" [(ngModel)]="!telephony.forwardCallToUserId ? null : telephony.forwardCallToPhoneNumber" #forwardToUserPhone="ngModel"
                  [ngClass]="{ 'is-invalid': forwardToUserPhone && forwardToUserPhone.touched && forwardToUserPhone.invalid }" [disabled] = "isCallForward" />
                <select name="userPhoneType" id="userPhoneType" class="form-select" style="width: 40%; min-width: 150px;" [(ngModel)]="userPhoneType" (change)="onUserPhoneTypeChanged()" [disabled] = "isCallForward">
                  <option value="customPhone">Custom</option>
                  <option value="workPhone">Work</option>
                  <option value="cellPhone">Cell</option>
                  <option value="homePhone">Home</option>
                </select>
              </div>
              <div *ngIf="forwardToUserPhone?.touched && forwardToUserPhone?.errors?.['required']" class="invalid-feedback d-block">
                User Forward Phone Number or Call Forward Phone Number is required.
              </div>
            </div>
          </div>
          <div class="text-center">- OR -</div>
          <div class="row">
            <div class="form-group">
              <label class="form-label" for="callForwardPhoneNumber"> Call Forward Phone Number </label>
              <input id="callForwardPhoneNumber" class="form-control" name="callForwardPhoneNumber" placeholder="Phone Number"  type="text" [mask]="'(000) 000-0000'" [showMaskTyped]="true"
                [(ngModel)]="telephony.forwardCallToUserId ? null : telephony.forwardCallToPhoneNumber" [disabled] = "!!telephony.forwardCallToUserId" (change)="onCallForwardChange()"
                [ngClass]="{ 'is-invalid': callForwardPhoneNumber && callForwardPhoneNumber.touched && callForwardPhoneNumber.invalid }" #callForwardPhoneNumber="ngModel" required/>
                <div *ngIf="callForwardPhoneNumber?.touched && callForwardPhoneNumber?.errors?.['required']" class="invalid-feedback d-block">
                  Call Forward Phone Number or User Forward Phone Number is required.
                </div>
              </div>
          </div>
        </ng-container>

        <ng-container *ngIf="!isForwardCallToUser && telephony.userId == null" class="row">
          <div class="row">
            <div class="form-group">
              <label for="users"> Voice Lead Routes </label>
              <select id="voiceLeadRoute" name="voiceLeadRoute" class="form-select"
                [(ngModel)]="telephony.voiceLeadRouteGroupId" (change)="onForwardToUserChange()">
                <option [ngValue]="null">-- Select One --</option>
                <option *ngFor="let leadRoute of voiceRoutes" [ngValue]="leadRoute.leadRouteGroupId">
                  {{ leadRoute.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="form-group">
              <div class="form-check form-switch mb-1">
                <input class="form-check-input" [(ngModel)]="telephony.voicemailEnabled" name="enableVoicemail"
                       id="enableVoicemail" type="checkbox">
                <label class="form-check-label text-muted" for="enableVoicemail">
                  Enable Voicemail
                </label>
              </div>
            </div>
          </div>
          <ng-container *ngIf="telephony.voiceLeadRouteGroupId != null || telephony.voicemailEnabled == true">
            <div class="row">
              <div class="form-group">
                <label class="form-label" for="voicemailMessage"> Voicemail Greeting </label>
                <textarea id="voicemailMessage" name="voicemailMessage" class="form-control" style="height: 50px"
                          [(ngModel)]="telephony.voicemailMessage" rows="1"></textarea>
                <div class="text-center text-muted"> -- OR -- </div>
                <div class="w-100 m-auto" style="border: 2px dashed #889988">
                  <file-upload [(ngModel)]="voicemailAudio" multiple="false" name="file" (change)="test(voicemailAudio)">
                  </file-upload>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="form-group">
                <label for="voicemailNotifyUsers">Users To Notify When Voicemail Received</label>
                <ng-select name="voicemailNotifyUsers" bindLabel="formattedName" bindValue="userCompanyGuid" placeholder="Select Users To Notify"
                 class="custom-ng-select" [closeOnSelect]="false" [multiple]="true" [(ngModel)]="telephony.voicemailNotifyUserIds"
                 [items]="users">
                </ng-select>
              </div>
            </div>
            <div class="row">
              <div class="form-group">
                <label for="routingTime">Routing Time Before Sent To Voicemail</label>
                <input id="routingTime" class="form-control" name="routingTime" style="width: 55%; min-width: 150px;" placeholder="# Seconds" type="text"
                       [(ngModel)]="telephony.routingTimeInSecondsBeforeVoicemail" />
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>

      <div class="col-lg-6">
        <ng-container *ngIf="telephony.userId == null">
          <div class="row mt-lg-4">
            <div class="form-group">
              <label for="users"> SMS Lead Routes
              </label>
              <select id="smsLeadRoute" name="smsLeadRoute" class="form-select"
                [(ngModel)]="telephony.incomingSmsLeadRouteGroupId">
                <option [ngValue]="null">-- Select One --</option>
                <option *ngFor="let leadRoute of smsRoutes" [ngValue]="leadRoute.leadRouteGroupId">
                  {{ leadRoute.name }}
                </option>
              </select>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</form>
