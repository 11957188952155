<ng-container *ngIf="!loadingSystemData; else loadingContent">
  <div class="fieldset-title">General Settings</div>
  <div class="pt-3">
    <div class="row mb-3">
      <div class="col-md-2 text-end">
        <label class="custom-control-label">Restrict "Create New" option on import when a duplicate has been
          found</label>
      </div>
      <div class="col-md-1">
        <input id="restrictCreateNewOption" class="form-check-input mt-3" type="checkbox" ToggleValue [true]="1"
          [false]="0" [(ngModel)]="restrictCreateNewOption.value">
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-2 text-end">
        <label class="custom-control-label">Disable New Registration Profile Wizard</label>
      </div>
      <div class="col-md-1">
        <input id="newUserProfileWizardDisabled" class="form-check-input mt-2" type="checkbox" ToggleValue [true]="1"
          [false]="0" [(ngModel)]="newUserProfileWizardDisabled.value">
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-2 text-end">
        <label class="custom-control-label">Disable Borrower Invite By Channel</label>
      </div>
      <div class="col-md-4">
        <ng-multiselect-dropdown [placeholder]="'-- Select --'" [settings]="dropdownSettings" [data]="companyEnabledChannels"
                                 [(ngModel)]="disabledBorrowerInviteChannels">
        </ng-multiselect-dropdown>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-2 text-end">
        <label class="custom-control-label">Hidden Fields</label>
      </div>
      <div class="col-md-4">
        <ng-multiselect-dropdown [placeholder]="'-- Select --'" [settings]="dropdownSettings" [data]="hiddenFields"
          [(ngModel)]="hiddenFieldsModel">
        </ng-multiselect-dropdown>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-2 text-end">
        <label class="custom-control-label">FHA Lender ID</label>
      </div>
      <div class="col-md-4">
        <input class="form-control" [(ngModel)]="company.fhaLenderId">
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-2 text-end">
        <label class="custom-control-label">FHA Sponsor ID</label>
      </div>
      <div class="col-md-4">
        <input class="form-control" [(ngModel)]="company.fhaSponsorId">
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-2 text-end">
        <label class="custom-control-label">VA Lender ID</label>
      </div>
      <div class="col-md-4">
        <input class="form-control" [(ngModel)]="company.vaLenderId">
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-2 text-end">
        <label class="custom-control-label">VA Sponsor ID</label>
      </div>
      <div class="col-md-4">
        <input class="form-control" [(ngModel)]="company.vaSponsorId">
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-2 text-end">
        <label class="custom-control-label">Default "To" in Loan Emails:</label>
      </div>
      <div class="col-md-4">
        <input class="form-control" [(ngModel)]="company.defaultToInLoanEmails">
      </div>
    </div>
  </div>

  <div class="fieldset-title">Session Settings</div>
  <div class="pt-3">
    <div class="row mb-3">
      <div class="col-md-2 text-end">
        <label class="custom-control-label">Session Timeout</label>
      </div>
      <div class="col-md-4">
        <input type="number" min="0" class="form-control" placeholder="0" [(ngModel)]="sessionTimeout.value">
      </div>
      <div class="col-md-2 pt-2">
        {{sessionTimeout.value > 0 ? "minutes" : "disabled"}}
      </div>
    </div>
  </div>

  <!-- Broadcast Manager Access -->
  <div class='fieldset-title'>Broadcast Manager Access</div>
  <div class='row pt-3 mb-3'>
    <div class='col'>
      <!-- Roles with Access -->
      <div class='form-group mb-3 row'>
        <label class='col-md-2 form-label align-self-center mb-lg-0 text-end' for='roles-with-access'>Roles with
          Access</label>
        <div class='col-md-4'>
          <ng-multiselect-dropdown
            [(ngModel)]='rolesWithAccessToBroadcastManager'
            [settings]='accessSelectionSettings'
            [data]='rolesWithAccessOptions'
            id='roles-with-access' name='rolesWithAccessToBroadcastManager'
            placeholder='-- Select --'></ng-multiselect-dropdown>
        </div>
      </div>

      <!-- Users with Access -->
      <div class='form-group mb-3 row'>
        <label class='col-md-2 form-label align-self-center mb-lg-0 text-end' for='users-with-access'>Users with
          Access</label>
        <div class='col-md-4'>
          <ng-multiselect-dropdown
            [(ngModel)]='usersWithAccessToBroadcastManager'
            [settings]='accessSelectionSettings'
            [data]='usersWithAccessOptions'
            id='users-with-access' name='usersWithAccessToBroadcastManager'
            placeholder='-- Select --'></ng-multiselect-dropdown>
        </div>
      </div>
    </div>
  </div>

    <!-- MFA Setup -->
    <div class='fieldset-title'>MFA Setup</div>
    <div class='row pt-3 mb-3'>
      <div class='col'>
        <!-- Roles with Access -->
        <div class='form-group mb-3 row'>
          <label class='col-md-2 form-label align-self-center mb-lg-0 text-end' for='roles-with-access'>User Types For Which MFA is Required</label>
          <div class='col-md-4'>
            <ng-multiselect-dropdown
              [(ngModel)]='userTypesForWhichMfaSetupIsRequired'
              [settings]='accessSelectionSettings'
              [data]='userTypeOptions'
              id='roles-with-mfa' name='userTypesForWhichMfaSetupIsRequired'
              placeholder='-- Select --'>
            </ng-multiselect-dropdown>
          </div>
        </div>
      </div>
    </div>

  <div class="fieldset-title">Document Settings</div>
  <div class="pt-3">
    <div class="row mb-3">
      <div class="col-md-2 text-end">
        <label class="custom-control-label">Enable Automatic Conversion to PDF</label>
      </div>
      <div class="col-md-1">
        <input id="autoConvertToPdf" class="form-check-input mt-3" type="checkbox" ToggleValue [true]="1" [false]="0"
          [(ngModel)]="autoConvertToPdf.value">
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-2 text-end">
        <label class="custom-control-label">Enable Dynamic Compression of PDF Documents</label>
      </div>
      <div class="col-md-1">
        <input id="useDynamicCompression" class="form-check-input mt-3" type="checkbox" ToggleValue [true]="1"
          [false]="0" [(ngModel)]="useDynamicCompression.value">
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-2 text-end">
        <label class="custom-control-label">Exclude Accepted Borrower Documents From Email Templates</label>
      </div>
      <div class="col-md-1">
        <input id="taskUpdateExcludeAccepted" class="form-check-input mt-3" type="checkbox" ToggleValue [true]="1"
          [false]="0" [(ngModel)]="taskUpdateExcludeAccepted.value">
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-2 text-end">
        <label class="custom-control-label">Use Scriban Template formatting when rendering HTML Doc Templates</label>
      </div>
      <div class="col-md-1">
        <input id="useScribanForHtmlDocGenRendering" class="form-check-input mt-3" type="checkbox" ToggleValue [true]="1" [false]="0"
          [(ngModel)]="useScribanForHtmlDocGenRendering.value">
      </div>
    </div>
  </div>

  <div class="fieldset-title">E-Sign Settings</div>
  <div class="pt-3">
    <div class="row mb-3">
      <div class="col-md-2 text-end">
        <label class="custom-control-label">Non-LOS Loan Officer Sender</label>
      </div>
      <div class="col-md-1">
        <input id="documentSigningNonLosLoanOfficerSender" class="form-check-input mt-2" type="checkbox" ToggleValue [true]="1" [false]="0"
          [(ngModel)]="documentSigningNonLosLoanOfficerSender.value">
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-2 text-end">
        <label class="custom-control-label">LOS Loan Officer Sender</label>
      </div>
      <div class="col-md-1">
        <input id="documentSigningLosLoanOfficerSender" class="form-check-input mt-2" type="checkbox" ToggleValue [true]="1"
          [false]="0" [(ngModel)]="documentSigningLosLoanOfficerSender.value">
      </div>
    </div>
  </div>

  <div class="fieldset-title">Credit Reporting Script</div>
  <div class="pt-3">
    <div class="row mb-3 mx-1">
      <rich-text-editor [name]="'creditReportingHtml'" [(ngModel)]="creditReportingScript.valueStr">
      </rich-text-editor>
    </div>
  </div>

  <div class="fieldset-title">Custom Data Fields 
    <button class="pull-right btn btn-sm btn-soft-primary me-1" (click)="saveCustomDataFields()" [disabled]="isSaving || isCustomDataFieldsSaving">
      <span *ngIf="!isCustomDataFieldsSaving"><i class="fa fa-save me-2"></i> Save</span>
      <span *ngIf="isCustomDataFieldsSaving">
        <span class="spinner-border spinner-border-sm"></span> Saving
      </span>
    </button>
    <a class="pull-right btn btn-sm btn-soft-primary me-1" (click)="addCustomField()"> <i class="fas fa-plus font-15"></i> Add Custom Field </a>
    <a class="pull-right btn btn-sm btn-soft-primary me-1" (click)="customFieldLayoutOpen()"> Custom Field Layout </a>
  </div>
  <div class="pt-3">
    <div class="row">
      <div class="col-md-3 text-center">
        <label class="custom-control-label">Field Name</label>
      </div>
      <div class="col-md-3 text-center">
        <label class="custom-control-label">Label</label>
      </div>
      <div class="col-md-2 text-center">
        <label class="custom-control-label">Field Type</label>
      </div>
      <div class="col-md-2 text-center">
        <label class="custom-control-label">Editor Type</label>
      </div>
      <div class="col-md-2 text-center">
        <label class="custom-control-label">Action</label>
      </div>
    </div>
    <hr />
    <ng-container *ngFor="let cdfItem of customDataFieldConfigModels; index as i">
      <custom-data-setting
        [label]="'Field ' + (i + 1)"
        [customDataConfigModel]="cdfItem"
        [number]="i"
        [fieldTypeOptions]="fieldTypeOptions"
        [editorTypeOptions]="editorTypeOptions"
        [listTypes]="listTypes"
        (customDataConfigRemoved)="customDataConfigRemoved($event)"
        (customDataConfigSaved)="customDataConfigSaved($event, i)"
      >
      </custom-data-setting>
    </ng-container>
  </div>

  <div class="text-end">
    <div class="col-md-12 my-1">
      <hr />
    </div>
    <button type="submit" class="btn btn-primary right" [disabled]="isSaving" (click)="save()">
      <span *ngIf="!isSaving"><i class="fa fa-save me-2"></i> Save</span>
      <span *ngIf="isSaving">
        <span class="spinner-border spinner-border-sm"></span> Saving
      </span>
    </button>
  </div>
</ng-container>
<ng-template #loadingContent>
  <loading-indicator loadingMessage="Loading Application Settings..."
    [customClass]="'init-loading-indicator'"></loading-indicator>
</ng-template>

<drawer
  #customDataFieldsLayoutDrawer
  [title]="'Custom Data Fields Layout'"
  [name]="'customDataFieldsLayoutDrawer'"
  [templateRef]="customDataFieldsLayoutContentRef"
  [options]="customDataFieldsLayoutDrawerOptions"
></drawer>
<ng-template #customDataFieldsLayoutContentRef>
  <custom-data-fields-layout
      [customDataFieldConfigModels]="originalCustomDataFieldConfigModels"
      (update)="onCustomDataFieldConfigModelsUpdated($event)"
      (closed)="closeLayoutDrawer()"
  >
  </custom-data-fields-layout>
</ng-template>