import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { DocumentType } from 'src/app/models';
import { NotificationService } from 'src/app/services/notification.service';
import { DocumentTypeService } from '../../services/document-type.service';

@Component({
  selector: 'app-document-type-details-dialog',
  templateUrl: './document-type-details-dialog.component.html',
  styleUrls: ['./document-type-details-dialog.component.scss']
})
export class DocumentTypeDetailsDialogComponent implements OnInit {

  @ViewChild("documentTypeForm") documentTypeForm: NgForm | undefined;

  get documentType(): DocumentType {
    return this._documentType;
  }

  @Input()
  set documentType(value: DocumentType) {
    this._documentType = value;
    if (value) {
      this.documentTypeData = _.cloneDeep(value);
    }
  }

  documentTypeData: DocumentType = new DocumentType();
  isSaving: boolean = false;

  private _documentType: DocumentType;

  constructor(
    public activeModal: NgbActiveModal,
    private readonly _documentTypeService: DocumentTypeService,
    private readonly _notifyService: NotificationService
  ) {
  }

  ngOnInit(): void {
    if (!this.documentTypeData.documentTypeId) {
      this.documentTypeData.showOnChannels = 'Retail';
      this.documentTypeData.allowUploadOnChannels = 'Retail';
    }
  }

  save = () => {

    if (this.documentTypeForm) {

      this.documentTypeForm.form.markAllAsTouched();

      if (this.documentTypeForm.form.valid) {
        this.isSaving = true;

        if (this.documentTypeData.documentTypeId) {
          this._documentTypeService.updateDocumentType(this.documentTypeData)
            .subscribe(() => {
              this.isSaving = false;
              this._notifyService.showSuccess("Update done successfully", "Success");
              this.activeModal.close(this.documentTypeData);

            }, (err) => {
              this._notifyService.showError(err.error.message, "Error");
              this.isSaving = false;
            })
        }
        else {
          this._documentTypeService.insertDocumentType(this.documentTypeData)
            .subscribe(() => {
              this.isSaving = false;
              this._notifyService.showSuccess("Insert done successfully", "Success");
              this.activeModal.close(this.documentTypeData);

            }, (err) => {
              this._notifyService.showError(err.error.message, "Error");
              this.isSaving = false;
            })
        }


      }

    }

  }
}

