<div class="modal-header" *ngIf="!inEmail">
  <h5 class="modal-title"> </h5>
  <button type="button" class="btn-close" (click)="activeModal.dismiss()" aria-label="Close"></button>
</div>
<div id="sectionConvertedToPDF" class="modal-body style-0">
  <div class="container-fluid loan-quote style-1">
    <div class="row style-2">
      <div class="worksheet-header d-flex justify-content-between style-3">
        <div class="col-md-7 d-flex mb-2 style-4">
          <div class="col-md-2 ps-4 style-5">
            <img [src]="imageProfile" alt="Profile Image" class="thumb-xxl style-6"
              (error)="onProfileImageDownloadFailed()">
          </div>
          <div class="col-md-10 style-7">
            <div class="personal-info style-8">
              <span class="fs-5 style-9">
                <strong *ngIf="appId" class="style-10"> {{user ? (user.firstName + ' ' + user.lastName) : '-'}}
                </strong>
                <strong *ngIf="!appId" class="style-10"> {{loggedInUser ? (loggedInUser.userProfile.firstName + ' ' +
                  loggedInUser.userProfile.lastName) : '-'}}
                </strong>
              </span>
              <div class="style-11">
                <span *ngIf="appId" class="style-12"> NMLS {{user ? user.nmls : ' - '}}, License Number: {{licenseNo ?
                  licenseNo : '-'}}
                </span>
                <span *ngIf="!appId" class="style-12"> NMLS {{loggedInUser ? loggedInUser.userProfile.nmls : ' - '}},
                  License Number: - </span>
              </div>
              <div class='mt-1 style-13'>
                <i class='fas fa-phone-alt style-14'></i>
                <conditional-link *ngIf='appId' [href]="userCellPhone ? 'tel:' + userCellPhone : null">
                  <span class='ms-1 style-15'>
                    {{userCellPhone ? (userCellPhone | phone) : '-'}}
                  </span>
                </conditional-link>

                <conditional-link *ngIf='!appId' [href]="loggedInUserCellPhone ? 'tel:' + loggedInUserCellPhone : null">
                  <span class='ms-1 style-15'>
                    {{loggedInUserCellPhone ? (loggedInUserCellPhone | phone) : '-'}}
                  </span>
                </conditional-link>

                <i class='far fa-envelope ms-4 style-16'></i>
                <conditional-link *ngIf='appId' [href]="userEmail ? 'mailto:' + userEmail : null">
                  <span class='ms-1 style-17'>
                    {{userEmail ?? '-'}}
                  </span>
                </conditional-link>

                <conditional-link *ngIf='!appId' [href]="loggedInUserEmail ? 'mailto:' + loggedInUserEmail : null">
                  <span class='ms-1 style-17'>
                    {{loggedInUserEmail ?? '-'}}
                  </span>
                </conditional-link>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex flex-column style-18">
          <span class="text-primary fs-3 style-19">
            <strong style="vertical-align: -webkit-baseline-middle;" class="style-20">
              INITIAL FEES WORKSHEET
            </strong>
          </span>

          <div *ngIf='forBorrowers'>
            for
            <ul class='style-232'>
              <li *ngFor='let borrower of forBorrowers'>
                <b>{{borrower}}</b>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="text-center mt-2 d-flex style-21">
        <span class="fs-5 flex-grow-1 style-22">
          <strong class="style-23">
            Your actual rate, payment and costs could be higher. Get an official Loan Estimate before choosing a loan.
          </strong>
        </span>
        <div style="margin-right: 2rem;" class="style-24" *ngIf="!inEmail && !!appId">
          <button type="button" id="buttonMenu" class="btn btn-outline-primary style-25" style="padding: 4px 6px 0;"
            data-bs-container="body" data-bs-toggle="popover" placement="bottom" [popover]="templateMenu"
            data-trigger="click" [outsideClick]="false" [containerClass]="'templateMenu'"><i
              class="ti-more-alt style-26"></i></button>
          <ng-template #templateMenu>
            <div class="popover-menu">
              <a class="dropdown-item" (click)="onSendAsHtmlInEmailClickedV2()"> Send Email </a>
            </div>
          </ng-template>
        </div>
      </div>
      <div class="card-body-header mt-2 style-27">
        <span class="style-28"> Quote Number: <span class="text-danger style-29"> TBD </span> </span>
        <span class="style-30"> Preparation Date: {{currentDate | date: 'MMM d, y h:mm:ss a'}} </span>
      </div>
      <div class="card-body border mt-2 style-31">
        <div class="row style-32">
          <div class="col-md-4 style-33">
            <div class="style-34">
              <span class="style-35"> Loan Purpose: </span>
              <span style="margin-left: 29px;" class="style-36">
                <strong class="style-37"> {{loanPurpose ? loanPurpose : '-'}} </strong>
              </span>
            </div>
            <div class="mt-1 style-38">
              <span class="style-39"> Property Type: </span>
              <span style="margin-left: 29px;" class="style-40">
                <strong class="style-41"> {{propertyType ? propertyType : '-'}} </strong>
              </span>
            </div>
            <div class="mt-1 style-42">
              <span class="style-43"> Estimated FICO: </span>
              <span style="margin-left: 19px" class="style-44">
                <strong class="style-45"> {{fico ? fico : '-'}} </strong>
              </span>
            </div>
            <div class="mt-1 style-46">
              <span class="style-47"> Product: </span>
              <span style="margin-left: 4rem;word-break: break-all;" class="style-48">
                <strong class="style-49"> {{productName ? productName : '-'}} </strong>
              </span>
            </div>
          </div>
          <div class="col-md-4 style-50">
            <div *ngIf="loanPurpose === 'Purchase'" class="style-51">
              <span class="style-52"> Purchase Price: </span>
              <span style="margin-left: 29px;" class="style-53">
                <strong class="style-54"> {{purchasePrice ? (purchasePrice | currency) : '-'}} </strong>
              </span>
            </div>
            <div *ngIf="loanPurpose === 'Refinance'" class="style-51">
              <span class="style-52"> Appraised Value: </span>
              <span style="margin-left: 22px;" class="style-53">
                <strong class="style-54"> {{appraisedValue ? (appraisedValue | currency) : '-'}} </strong>
              </span>
            </div>
            <div class="mt-1 style-55">
              <span class="style-56"> Occupancy: </span>
              <span style="margin-left: 52px;" class="style-57">
                <strong class="style-58"> {{occupancy ? occupancy : '-'}} </strong>
              </span>
            </div>
            <div class="mt-1 style-59">
              <span class="style-60"> ZIP / State: </span>
              <span style="margin-left: 55px;" class="style-61">
                <strong class="style-62"> {{zipCode ? zipCode : '-'}} / {{state ? state : '-'}} </strong>
              </span>
            </div>
            <div class="mt-1 style-63">
              <span class="style-64"> Rate / APR: </span>
              <span style="margin-left: 54px;" class="style-65">
                <strong class="style-66"> {{rate ? ((rate / 100) | percent:'1.3-3'): '-'}} / {{apr ? ((apr / 100) |
                  percent:'1.3-3') :
                  '-'}} </strong>
              </span>
            </div>
          </div>
          <div class="col-md-4 style-67">
            <div class="style-68">
              <span class="style-69"> Loan Amount: </span>
              <span style="margin-left: 29px;" class="style-70">
                <strong class="style-71"> {{loanAmount ? (loanAmount | currency) : '-'}} </strong>
                <strong *ngIf="totalLoanAmount && totalLoanAmount != loanAmount" class="style-71"> (Total: {{totalLoanAmount | currency}})</strong>
              </span>
            </div>
            <div class="mt-1 style-72">
              <span class="style-73"> No. of Units: </span>
              <span style="margin-left: 38px;" class="style-74">
                <strong class="style-75"> {{noOfUnits ? noOfUnits : '-'}}</strong>
              </span>
            </div>
            <div class="mt-1 style-76">
              <span class="style-77"> Escrow: </span>
              <span style="margin-left: 64px;" class="style-78">
                <strong class="style-79"> {{waiveEscrows ? 'Waived' : 'Not Waived'}} </strong>
              </span>
            </div>
            <div class="mt-1 style-80">
              <span class="style-81"> Lock Period: </span>
              <span style="margin-left: 37px;" class="style-82">
                <strong class="style-83"> {{lockTerm ? lockTerm + ' Days' : '-'}} </strong>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="row cards-section mt-4 style-84">
        <div class="col-md-6 style-85">
          <div class="card style-86" id="originationFeesCard" [style.height.px]="originationFeesHeight">
            <div class="card-header style-87">
              <h4 class="card-title style-88">
                <span class="style-89"> Origination Fees </span>
                <span class="float-end text-primary style-90">
                  {{signedCurrencyToString(totalOriginationFees)}}
                </span>
              </h4>
            </div>
            <div class="card-body style-91">
              <div *ngFor="let originationFee of originationFees" class="mt-1 style-92">
                <span class="style-93"> {{originationFee.name ? getFeeNameWithFeePercent(originationFee) : '-'}} </span>
                <span class="float-end style-127">
                  {{ getBorrowerTotalAsString(originationFee) }} </span>
              </div>
              <div *ngIf="originationFees.length == 0" class="style-129">
                <span class="mt-1 style-130"> - </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 style-94">
          <div class="card style-95" id="taxesAndOtherGovernmentCard"
            [style.height.px]="taxesAndOtherGovernmentFeesFeesHeight">
            <div class="card-header style-96">
              <h4 class="card-title style-97">
                <div class="style-98">
                  <span class="style-99"> Taxes and Other Government Fees </span>
                  <span class="float-end text-primary style-100"> {{totalOtherAndGovernmentFees | currency}} </span>
                </div>
              </h4>
            </div>
            <div class="card-body style-101">
              <div *ngFor="let item of governmentTaxesAndFees" class="mt-1 style-102">
                <span class="style-93"> {{item.name ? getFeeNameWithFeePercent(item) : '-'}} </span>
                <span class="float-end style-127">
                  {{ item.calculatedValues.borrowerTotal | currency }}
                </span>
              </div>
              <div *ngIf="governmentTaxesAndFees.length == 0" class="style-129">
                <span class="style-130"> - </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 style-104">
          <div class="card style-105" id="thirdPartyFeesCard" [style.height.px]="thirdPartyFeesHeight">
            <div class="card-header style-106">
              <h4 class="card-title style-107">
                <div class="style-108">
                  <span class="style-109"> Other Fees </span>
                  <span class="float-end text-primary style-110"> {{totalOtherFees | currency}} </span>
                </div>
              </h4>
            </div>
            <div class="card-body third-party-fees style-111" style="overflow: auto;">
              <div class="style-145" *ngFor='let item of otherFees'>
                <span class="style-146"> {{item.name ? getFeeNameWithFeePercent(item) : '-'}} </span>
                <span class="float-end style-147">
                  {{ item.calculatedValues.borrowerTotal | currency }}
                </span>
              </div>
              <div *ngIf="otherFees.length == 0" class="style-145">
                <span class="style-146"> - </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 style-121">
          <div class="card style-122" id="prepaidAndInitialCard" [style.height.px]="prepaidAndInitialHeight">
            <div class="card-header style-123">
              <h4 class="card-title style-124">
                <div class="style-125">
                  <span class="style-126"> Prepaids and Initial Escrow Payment at Closing </span>
                  <span class="float-end text-primary style-127"> {{totalPrepaidsAndEscrow | currency}} </span>
                </div>
              </h4>
            </div>
            <div class="card-body prepaids-and-escrows style-128" style="overflow: auto;">
              <div class="style-129">
                <span class="style-130"> Prepaids </span>
              </div>
              <div *ngFor="let prepaid of prepaids" class="mt-1 style-131">
                <span class="ms-3 style-93"> {{prepaid.name ? getFeeNameWithFeePercent(prepaid) : '-'}} </span>
                <span class="float-end style-127">
                  {{ prepaid.calculatedValues.borrowerTotal | currency }}
                </span>
              </div>
              <div *ngIf="prepaids.length == 0" class="mt-1 style-131">
                <span class="ms-3 style-132"> - </span>
              </div>
              <hr class="style-133">
              <div class="style-134">
                <span class="style-135"> Initial Escrow Payment at Closing </span>
              </div>
              <div *ngFor="let esc of escrow" class="mt-1 style-131">
                <span class="ms-3 style-93"> {{esc.name ? getFeeNameWithFeePercent(esc) : '-'}} </span>
                <span class="float-end style-127">
                  {{ esc.calculatedValues.borrowerTotal | currency }}
                </span>
              </div>
              <div *ngIf="escrow.length == 0" class="mt-1 style-119">
                <span class="ms-3 style-120"> - </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 style-138">
          <div class="card style-139" id="estimatedProposedMonthlyHousingExpanseCard" style="height: 388px;">
            <div class="card-header style-140">
              <h4 class="card-title style-141">
                <div class="style-142">
                  <span class="style-143"> Estimated Proposed Monthly Housing Expense </span>
                </div>
              </h4>
            </div>
            <div class="card-body style-144" style="overflow: auto;">
              <div *ngIf="appId" class="style-145">
                <span class="style-146"> First Mortgage P&I </span>
                <span class="float-end style-147"> {{scenario ? (scenario.principalAndInterest | currency) :
                  mortgage && mortgage.proposedHousingExpense ?
                  (mortgage.proposedHousingExpense.firstMortgagePrincipalAndInterest | currency) : '-'}} </span>
              </div>
              <div *ngIf="!appId" class="style-145">
                <span class="style-146"> Monthly P&I </span>
                <span class="float-end style-147"> {{scenario.principalAndInterest ? (scenario.principalAndInterest |
                  currency) :
                  '-'}} </span>
              </div>
              <div *ngIf="appId" class="mt-1 style-119">
                <span class="style-146"> Other Financing P&I </span>
                <span class="float-end style-147"> {{mortgage && mortgage.proposedHousingExpense ?
                  (mortgage.proposedHousingExpense.otherMortgageLoanPrincipalAndInterest | currency) : '-'}} </span>
              </div>
              <div *ngIf="!appId" class="mt-1 style-119">
                <span class="style-146"> Taxes and Insurance </span>
                <span class="float-end style-147"> {{scenario.productSearchRequest &&
                  scenario.productSearchRequest.loanInformation ?
                  (scenario.productSearchRequest.loanInformation.taxesAndInsuranceMonthly | currency) : '-'}} </span>
              </div>
              <div class="mt-1 style-119">
                <span class="style-146"> Homeowner’s Insurance </span>
                <span *ngIf="appId" class="float-end style-147"> {{mortgage && mortgage.proposedHousingExpense ?
                  (mortgage.proposedHousingExpense.homeownersInsurance | currency) : '-'}} </span>
                <span *ngIf="!appId" class="float-end style-147"> {{scenario.monthlyInsurance ?
                  (scenario.monthlyInsurance |
                  currency) :
                  '-'}} </span>
              </div>
              <div class="mt-1 style-119">
                <span class="style-146"> Property Taxes </span>
                <span *ngIf="appId" class="float-end style-147"> {{mortgage && mortgage.proposedHousingExpense ?
                  (mortgage.proposedHousingExpense.realEstateTax | currency) : '-'}} </span>
                <span *ngIf="!appId" class="float-end style-147"> {{scenario.monthlyTaxes ? (scenario.monthlyTaxes |
                  currency) :
                  '-'}} </span>
              </div>
              <div class="mt-1 style-119">
                <span class="style-146"> Mortgage Insurance </span>
                <span *ngIf="appId" class="float-end style-147"> {{mortgage && mortgage.proposedHousingExpense ?
                  (mortgage.proposedHousingExpense.mortgageInsurance | currency) : '-'}} </span>
                <span *ngIf="!appId" class="float-end style-147"> {{scenario.monthlyMi ?
                  (scenario.monthlyMi | currency) : '-'}} </span>
              </div>
              <div class="mt-1 style-119">
                <span class="style-146"> HOA Dues </span>
                <span *ngIf="appId" class="float-end style-147"> {{mortgage && mortgage.proposedHousingExpense ?
                  (mortgage.proposedHousingExpense.homeownersAssociationDues | currency) : '-'}} </span>
                <span *ngIf="!appId" class="float-end style-147"> {{scenario.monthlyHoa ?
                  (scenario.monthlyHoa | currency) : '-'}} </span>
              </div>
              <div class="mt-1 style-119">
                <span class="style-146"> Supplemental Insurance</span>
                <span *ngIf="appId" class="float-end style-147"> {{mortgage && mortgage.proposedHousingExpense ?
                  (((mortgage.proposedHousingExpense.supplementalPropertyInsurance || 0) + 
                   (mortgage.proposedHousingExpense.otherSupplementalPropertyInsurance || 0)) | currency) : '-'}} </span>
                <span *ngIf="!appId" class="float-end style-147"> {{'-'}} </span>
              </div>
            </div>
            <div class="card-footer style-160">
              <h4 class="card-title text-primary style-161">
                <div class="style-162">
                  <span class="text-uppercase style-163"> TOTAL APPROXIMATED MONTHLY PAYMENT </span>
                  <span class="float-end style-164"> {{(totalApproxMonthlyPayment ? totalApproxMonthlyPayment : 0 )|
                    currency}}
                  </span>
                </div>
              </h4>
            </div>
          </div>
        </div>
        <div class="col-md-6 style-165">
          <div class="card style-166" id="estimatedFundsToCloseCard" style="height: 388px;">
            <div class="card-header style-167">
              <h4 class="card-title style-168">
                <div class="style-169">
                  <span class="style-170"> Estimated Funds to Close: </span>
                </div>
              </h4>
            </div>
            <div class="card-body style-171" style="overflow: auto;">
              <div class="style-172">
                <span class="style-173"> {{ isRefinance ? 'Loan Amount' : 'Downpayment/Funds from Borrower' }} </span>
                <span class="float-end style-174"> {{downpaymentFunds | currency}} </span>
              </div>
              <div class="style-172" *ngIf="totalSubordinateLiens">
                <span class="style-173"> Other New Mortgage Loans on Subject Property </span>
                <span class="float-end style-174"> {{-totalSubordinateLiens | currency}} </span>
              </div>
              <div class="mt-1 style-175" *ngIf="improvementsRenovationsAndRepairs">
                <span class="style-176"> Improvements, Renovations and Repairs </span>
                <span class="float-end style-177"> {{improvementsRenovationsAndRepairs | currency}} </span>
              </div>
              <div class="mt-1 style-175">
                <span class="style-176"> Origination Fees </span>
                <span class="float-end style-177"> {{signedCurrencyToString(totalOriginationFees)}} </span>
              </div>
              <div class="mt-1 style-178">
                <span class="style-179"> Third Party Fees </span>
                <span class="float-end style-180"> {{totalOtherFees | currency}} </span>
              </div>
              <div class="mt-1 style-181">
                <span class="style-182"> Taxes and Other Government Fees </span>
                <span class="float-end style-183"> {{totalOtherAndGovernmentFees | currency}} </span>
              </div>
              <div class="mt-1 style-184">
                <span class="style-185"> Prepaids and Initial Escrow </span>
                <span class="float-end style-186"> {{totalPrepaidsAndEscrow | currency}} </span>
              </div>

              <div class="mt-1 style-184" *ngIf="nonFinancedPmiMipFundingFee">
                <span class="style-185"> UFMIP / Funding Fee (Excluding Financed Amount) </span>
                <span class="float-end style-186"> {{nonFinancedPmiMipFundingFee | currency}} </span>
              </div>

              <div class="mt-1 style-187" *ngIf="estimatedTotalPayOffs">
                <span class="style-188"> Estimated Total Payoffs </span>
                <span class="float-end style-189"> {{(estimatedTotalPayOffs ? estimatedTotalPayOffs : 0) | currency}}
                </span>
              </div>

              <div class="mt-1 style-187" *ngIf="isRefinance">
                <span class="style-188"> For Refinance: Balance of Mtg. Loans on the Prop. to be paid off </span>
                <span class="float-end style-189"> {{(refiMortgagePayoffs ? refiMortgagePayoffs : 0) | currency}}
                </span>
              </div>

              <div class="col-md-3 float-end style-190" style="border-bottom: 1px dashed #d3ddeb;"></div>
              <div class="mt-1 style-191">
                <span class="style-192"> <strong class="style-193"> Funds Due from Borrower (A) </strong> </span>
                <span class="float-end style-194"> <strong class="style-195"> {{totalFundsDueFromBorr | currency}}
                  </strong> </span>
              </div>
              <hr class="style-196">
              <div class="mt-1 style-197">
                <span class="style-198"> Lender Credits </span>
                <span class="float-end style-199"> {{(lenderCredits ? lenderCredits : 0) | currency}} </span>
              </div>
              <div class="mt-1 style-200">
                <span class="style-201"> Seller Credits </span>
                <span class="float-end style-202"> {{(sellerCredits ? sellerCredits : 0) | currency}} </span>
              </div>
              <div class="mt-1 style-200">
                <span class="style-201"> Other Credits </span>
                <span class="float-end style-202"> {{(otherCredits ? otherCredits : 0) | currency}} </span>
              </div>
              <div class="col-md-3 float-end style-203" style="border-bottom: 1px dashed #d3ddeb;"></div>
              <div class="mt-1 style-204">
                <span class="style-205"> <strong class="style-206"> Total Credits Applied (B) </strong> </span>
                <span class="float-end style-207"> <strong class="style-208"> {{(totalCreditsApplied ?
                    totalCreditsApplied : 0) | currency}}
                  </strong>
                </span>
              </div>
            </div>
            <div class="card-footer style-209">
              <h4 class="card-title text-primary style-210">
                <div class="style-211">
                  <span class="text-uppercase style-212"> ESTIMATED CASH FROM BORROWER (A-B) </span>
                  <span class="float-end style-213"> {{(estimatedCashFromBorr ? estimatedCashFromBorr : 0) | currency}}
                  </span>
                </div>
              </h4>
            </div>
          </div>
        </div>
      </div>
      <div class="cards-section mt-2 style-214" style="font-size: smaller;">
        <span class="style-215">
          This estimate is provided for illustrative and informational purposes only based on the initial basic loan
          scenario provided. This is not a loan approval or commitment to lend.
          <span *ngIf="updatedDate" class="style-216">
            Rates effective as of {{updatedDate | date: 'MM/dd/yyyy'}} and are subject to change.
          </span>
          <span *ngIf="!updatedDate" class="style-216">
            Currently, there is no pricing information for the loan.
          </span>
          Annual Percentage Rate APR is an estimate based on criteria provided. Until you
          lock your rate, APR and terms are subject to change or may not be available at commitment or closing.
        </span>
      </div>
      <div class="mt-3 d-flex style-217" style="border-top: 1px dashed #d3ddeb;">
        <div class="col-md-8 d-flex style-218">
          <div class="col-md-3 ps-4 mt-4 style-219">
            <img [src]="imageLogo" (error)="onCompanyLogoDownloadFailed()" alt="Company Logo"
              [ngClass]="{'img-size': !isFailed, 'default-img-size': isFailed}" style="width: 105px;" class="style-220">
          </div>
          <div class="col-md-9 mt-3 style-221">
            <div class="personal-info style-222">
              <span class="fs-5 style-223">
                {{entityName ? entityName : '-'}}
              </span>
              <div class="style-224">
                <span class="style-225"> Company NMLS {{entityNmls ? entityNmls : '-'}} </span>
              </div>
              <div class="style-226">
                <a class="style-227"> <span class="style-228"> {{companyUrl}} </span> </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer" *ngIf="!inEmail">
  <button [disabled]="!isPDFButtonEnabled" class="btn btn-secondary mx-2" [print]="printConfig" style="min-width: 13ch;">
  <span *ngIf="!isPDFButtonEnabled" class="spinner-border spinner-border-sm"> </span>
  <span *ngIf="isPDFButtonEnabled">Export to PDF</span>
  </button>
  <button type="button" class="btn btn-primary" (click)="activeModal.dismiss()" data-bs-dismiss="modal"> Close
  </button>
</div>

<initial-fees-worksheet-email
  #email
  [hidden]="true"
  [scenario]="scenario"
  [fees]="fees"
  [appId]="appId">
</initial-fees-worksheet-email>
