import { Component, Injector, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { DocumentType } from 'src/app/models';
import { StackingOrder } from 'src/app/models/config/stacking-order.model';
import { Constants } from 'src/app/services/constants';
import { NotificationService } from 'src/app/services/notification.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { TableColumn } from 'src/app/shared/models/tale-column.model';
import { StackingOrderDetailsDialogComponent } from '../../dialogs/stacking-order-details-dialog/stacking-order-details-dialog.component';
import { StackingOrderDocumentAssociationDialogComponent } from '../../dialogs/stacking-order-document-association-dialog/stacking-order-document-association-dialog.component';
import { DocumentTypeService } from '../../services/document-type.service';
import { StackingOrderService } from '../../services/stacking-order.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-stacking-order',
  templateUrl: './stacking-order.component.html',
  styleUrls: ['./stacking-order.component.scss']
})
export class StackingOrderComponent extends ApplicationContextBoundComponent implements OnInit {

  stackingOrders: StackingOrder[] = [];
  selectedColumns: TableColumn[] = [];
  columns: TableColumn[] = [];
  globalFilterFields: string[] = [];
  documentTypes: DocumentType[] = [];

  isLoading: boolean = false;
  superAdmin: boolean = false;

  isDeleteClicked = {};

  companyName: string;

  companyId: number;

  private _applicationContextSubscription: Subscription;

  constructor(
    private readonly _stackingOrderService: StackingOrderService,
    private readonly _documentTypeService: DocumentTypeService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _notifyService: NotificationService,
    private readonly _modalService: NgbModal,
    private readonly injector: Injector
  ) {
    super(injector);
    super.scrollOffset = 265;
  }

  ngOnInit(): void {
    this.getScreenSize();
    this._applicationContextSubscription = this.applicationContextService.context.subscribe(context => {
      this.superAdmin = context.userPermissions.superAdmin;

      let matchedCompany = context.globalConfig.company.find(c => c.companyGUID == context.currentlyLoggedInUser.userCompanyGuid)
      this.companyName = matchedCompany ? matchedCompany.companyName : null;

      this.companyId = context.userPermissions.companyId;

      this.columns = [
        { field: 'docType', header: 'Stacking Order', order: 1, visible: true },
        { field: 'showOnChannels', header: 'Show On Channels', order: 2, visible: true },
        { field: 'associate', header: 'Document Types', order: 3, visible: true }
      ];

      this.columns.forEach(column => {
        this.globalFilterFields.push(column.field);
        if (column.visible) {
          this.selectedColumns.push(column);
        }
      });

      this.getStackingOrders();

    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._applicationContextSubscription?.unsubscribe();
  }

  getSortedColumns = () => {
    return this.selectedColumns.sort((a, b) => a.order - b.order);
  }

  addNewStackingOrder = (): void => {
    let modalRef = this._modalService.open(StackingOrderDetailsDialogComponent, Constants.modalOptions.medium);
    modalRef.result.then(() => {
      this.getStackingOrders();
    }, () => {
    });
  }

  getStackingOrders = (): void => {
    this.isLoading = true;

    this._spinner.show();

    this._documentTypeService.getDocumentTypes().subscribe(result => {
      this.documentTypes = result;

      this._stackingOrderService.getStackingOrders().subscribe(result2 => {
        this.stackingOrders = result2.map(r => {
          r["docTypes"] = this.getSelectedDocumentTypes(r.associate);
          return r;
        });
      }, (err) => {
        this._notifyService.showError(err.error.message || err.error, "Error!");
      }).add(() => {
        this.isLoading = false;
        this._spinner.hide();
      });

    }, (err) => {
      this._notifyService.showError(err.error.message || err.error, "Error!");
      this._spinner.hide();
      this.isLoading = false;
    });


  }

  onEditStackingOrderClicked = (stackingOrder: StackingOrder) => {
    let modalRef = this._modalService.open(StackingOrderDetailsDialogComponent, Constants.modalOptions.medium);
    modalRef.componentInstance.stackingOrder = stackingOrder;

    modalRef.result.then(() => {
      this.getStackingOrders();
    }, () => {
    });
  }

  onDeleteStackingOrderClicked = (stackingOrderId: number) => {
    this._stackingOrderService.deletestackingOrder(stackingOrderId)
      .subscribe(() => {
        this._notifyService.showSuccess("Stacking Order removed succesfully", "Success");
        this.stackingOrders = this.stackingOrders.filter(t => t.stakingOrderId != stackingOrderId);
      }, (err) => {
        this._notifyService.showError(err.error.message || "Error encountered while deleting stacking order", "Error!");
      });
  }

  getSelectedDocumentTypes = (types: string) => {
    if (!types || !types.trim()) {
      return;
    }

    let selectedTypes: DocumentType[] = [];
    let parts = types.includes(",") ? types.trim().split(",") : [types.trim()];
    parts.forEach(value => {
      value = value.trim();
      let matched = this.documentTypes.find(s => s.documentTypeId == Number(value));
      if (matched) {
        selectedTypes.push(matched);
      }
    });
    return selectedTypes.map(r => ' ' + r.documentTypeName + ' ').join();
  }

  onAssociationClicked = (stackingOrder: StackingOrder) => {
    let modalRef = this._modalService.open(StackingOrderDocumentAssociationDialogComponent, Constants.modalOptions.ninetyPercentOfScreenWidth);
    modalRef.componentInstance.stackingOrder = stackingOrder;
    modalRef.componentInstance.documentTypes = this.documentTypes;

    modalRef.result.then(() => {
      this.getStackingOrders();
    }, () => {
    });
  }
}
