import { CreditVendor } from "src/app/models";

export class CreditRequest {
  leadId: number;
  applicationId: number;
  borrowerIds: number[];
  pullType: string;
  credentialId?: number;
  creditVendor: CreditVendor | string;
  creditRequestType?: CreditRequestType | string;
  experian?: boolean;
  equifax?: boolean;
  transUnion?: boolean;
  creditReferenceNumber?: string;
  reportType?: "Merge" | "SoftPull" | "Streamline";
  paymentInfo: PaymentInfo;
  importLiabs: boolean = false;
  deleteExistingUnmatchedLiabilitiesFromLoan: boolean = false;

  constructor() {
    this.paymentInfo = new PaymentInfo();
    this.credentialId = null;
  }
}

export enum CreditPullType {
  Individual = "Individual",
  Joint = "Joint"
}

export enum CreditRequestType {
  Submit = "Submit",
  Reissue = "Reissue",
  Upgrade = "Upgrade"
}

export class PaymentInfo {
  payerName: string
  payerAccount: string
  paymentMethod: PaymentType = PaymentType.CreditCard
  ccType: CreditCardType = null
  ccExpirationDate: string
  ccVerificationCode: string
  payerAddress: string
  payerAddress2: string
  payerCity: string
  payerState: string
  payerZipCode: string
  amount: number = 0
}

enum PaymentType {
  CreditCard = "CreditCard"
}

export enum CreditCardType {
  AmericanExpress = "AmericanExpress",
  Discover = "Discover",
  MasterCard = "MasterCard",
  Visa = "Visa",
}
