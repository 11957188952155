import { AfterViewInit, Component, EventEmitter, Injector, Input, OnInit, Output, ViewChild } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NgxSpinnerService } from "ngx-spinner";
import { finalize, Observable, Subscription } from "rxjs";
import { saveAs } from 'file-saver';
import Swal, { SweetAlertResult } from 'sweetalert2';
import { Table } from "primeng/table";
import { Workbook } from "exceljs";
import { LocalStorageService } from "src/app/core/services/local-storage.service";
import { Utils } from "src/app/core/services/utils";
import { ContactListType, LeadStatus } from "src/app/models";
import { LeadCampaign } from "src/app/models/config/global-config.model";
import { LoanPurpose } from "src/app/models/config/loan-purpose.model";
import { LoanType } from "src/app/models/config/loan-type.model";
import { ReferralSource } from "src/app/models/referral-source.model";
import { User } from "src/app/models/user/user.model";
import { AgentService } from "src/app/services/agent.service";
import { Constants } from "src/app/services/constants";
import { NotificationService } from "src/app/services/notification.service";
import { SystemLevelService } from "src/app/services/system-level.service";
import { ApplicationContextBoundComponent } from "src/app/shared/components";
import { Column } from "src/app/shared/models/table-config.model";
import * as _ from "lodash";
import { IDateRange } from "src/app/shared/components/date-range-filter/date-range-filter.component";
import { DateTime } from "luxon";
import { Lead } from "../../models/lead.model";
import { LeadFilters } from "../../models/lead-filters.model";
import { LeadsService } from "../../services/leads.service";
import { ViewLeadDrawerComponent } from "../dialogs/view-lead-drawer/view-lead-drawer.component";
import { ReassignLeadUserConfirmationDialogComponent } from "../dialogs/reassign-lead-user-confirmation-dialog/reassign-lead-user-confirmation-dialog.component";
import { FilterService } from 'primeng/api';
import { MultiSelect } from "primeng/multiselect";
import { CommonService } from "src/app/services/common.service";
import { WebPreferences } from "src/app/models/web-preferences.model";
import { ConfigurationService } from "src/app/services/configuration.service";
import { Tag } from "src/app/modules/admin/tag-management/models/tag.model";

@Component({
  selector: 'power-user-lead-table',
  templateUrl: './power-user-lead-table.component.html',
  styleUrls: ['./power-user-lead-table.component.scss']
})
export class PowerUserLeadTableComponent extends ApplicationContextBoundComponent implements OnInit, AfterViewInit {

  @Input()
  set filters(filters: LeadFilters) {
    this._filters = filters;
    this.getLeads();
  }

  @Input()
  set leads(leads: Lead[]) {
    if (!leads) {
      return;
    }
    this._leads = leads;
    this.populateOtherLeadPropertiesToDisplayOnTable(this._leads);
    this.filteredLeads = [...this._leads];
  }

  set selectedColumns(selectedColumns: any) {
    this._selectedColumns = selectedColumns;
    if (selectedColumns) {
      this._selectedColumns.sort((a, b) => a.order - b.order);
      this.webPreferences.leadsPreferences.leadsPowerUserTableSelectedColumns = this._selectedColumns;
    }
  }

  @Input() tagLists: Tag[] = [];

  @Input() showUnassignedLeads: boolean = false;

  @Input()
  webPreferences: WebPreferences;

  @Output()
  editRequestedForLead: EventEmitter<Lead> = new EventEmitter<Lead>();

  @ViewChild("dt2")
  leadTable: Table

  @ViewChild('mobileColumnSelector')
  mobileColumnSelector: MultiSelect;

  get filters(): LeadFilters { return this._filters; }
  get selectedColumns(): any { return this._selectedColumns; }

  filteredLeads: Lead[] = [];

  selectedRows: any[] = [];
  globalFilterFields: string[] = [];
  contactListType: ContactListType = ContactListType.Lead;
  users: User[] = [];
  leadLists: Tag[] = [];
  leadCampaigns: LeadCampaign[] = [];

  isLoading: boolean = false;

  error: any;
  dialerEnabled: boolean = false;
  isTpoUser: boolean;

  globalSearchString: string = null;

  tableState: any;

  leadStatuses: LeadStatus[] = [];
  loanPurposes: LoanPurpose[] = [];
  loanTypes: LoanType[] = [];
  referralSources: ReferralSource[] = [];
  leadListIds: number[] = [];

  columns: Column[] = [
    { field: 'firstName', header: 'First Name', visible: true, order: 1, dataType: 'text' },
    { field: 'lastName', header: 'Last Name', visible: true, order: 2, dataType: 'text' },
    { field: 'leadStatusName', header: 'Status', visible: true, order: 3, dataType: 'leadStatus' },
    { field: 'loanTypeName', header: 'Loan Type', visible: true, order: 4, dataType: 'loanType' },
    { field: 'loanPurposeName', header: 'Loan Purpose', visible: true, order: 5, dataType: 'loanPurpose' },
    { field: 'mostRecentNote', header: 'Last Note', visible: true, order: 6, dataType: 'text' },
    { field: 'subjectPropertyAddress1', header: 'Street Address', visible: true, order: 7, dataType: 'text' },
    { field: 'subjectPropertyCity', header: 'City', visible: true, order: 8, dataType: 'text' },
    { field: 'subjectPropertyState', header: 'State', visible: true, order: 9, dataType: 'text' },
    { field: 'subjectPropertyZip', header: 'Zip Code', visible: true, order: 10, dataType: 'text' },
    { field: 'mobilePhone', header: 'Mobile Phone', visible: true, order: 11, dataType: 'phone' },
    { field: 'phone', header: 'Home Phone', visible: true, order: 12, dataType: 'phone' },
    { field: 'email', header: 'Email', visible: true, order: 13, dataType: 'text' },
    { field: 'interestRate', header: 'Rate', visible: true, order: 14, dataType: 'percentage' },
    { field: 'loanAmount', header: 'Loan Amount', visible: true, order: 15, dataType: 'currency' },
    { field: 'leadCampaignName', header: 'Lead Campaign', visible: true, order: 16, dataType: 'leadCampaign' },
    { field: 'leadSource', header: 'Lead Source', visible: true, order: 17, dataType: 'text' },
    { field: 'leadRefId', header: 'Lead Ref Id', visible: true, order: 18, dataType: 'text' },
    { field: 'leadContactName', header: 'Lead Contact', visible: true, order: 19, dataType: 'text' },
    { field: 'leadContactAssignmentDate', header: 'Contact Assign. Date', visible: true, order: 20, dataType: 'date' },
    { field: 'dateInserted', header: 'Created On', order: 21, visible: true, dataType: 'date' },
    { field: 'contactAttempts', header: 'Contact Attempts', order: 22, visible: true, dataType: 'text' },
    { field: 'referralSourceName', header: 'Referral Source', order: 23, visible: true, dataType: 'text' },
    { field: 'leadListIds', header: 'Tags', order: 24, visible: true, dataType: 'leadListIds' },
    { field: 'lastAttemptedContact', header: 'Last Attempted Contact', order: 25, visible: true, dataType: 'date' },
    { field: 'lastSuccessfulContact', header: 'Last Successful Contact', order: 26, visible: true, dataType: 'date' },
    { field: 'creditScore', header: 'FICO', visible: true, order: 2, dataType: 'number' },
    { field: 'appraisedValue', header: 'Appraised Value', visible: true, order: 27, dataType: 'currency' },
    { field: 'cashOutAmount', header: 'Cash-out amount', visible: true, order: 28, dataType: 'currency' },
  ]

  private _selectedColumns: any;
  private _filters: LeadFilters;
  private _isSubjectPropertyAddressHidden: boolean = false;
  private _isAdmin: boolean = false;
  private _selectedColumnsLocalStorageKeyName: string = "power-user-leads-selectedColumns";

  private _leads: Lead[] = [];

  private _lastNote: string = "";

  private _applicationContextSubscription: Subscription;
  private _systemLevelSubscription: Subscription;
  private _leadUpdatesSubscription: Subscription;

  constructor(
    private readonly _filterService: FilterService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _leadService: LeadsService,
    private readonly _agentService: AgentService,
    private readonly _configurationService: ConfigurationService,
    private readonly _notifyService: NotificationService,
    private readonly _modalService: NgbModal,
    private readonly injector: Injector,
    private readonly _localStorageService: LocalStorageService,
    private readonly _commonService: CommonService
  ) {
    super(injector);
    this.getAllLeadLists();

    this._agentService.getAllReferralSources().subscribe(referralSources => {
      this.referralSources = referralSources;
    });

    this._configurationService.getCompanyConfiguration('LoanHiddenFields').subscribe(loanHiddenFields => {
      let hiddenFields = loanHiddenFields ? loanHiddenFields['valueStr'] : '';
      this._isSubjectPropertyAddressHidden = hiddenFields.indexOf('Subject Property') > -1;
    });

    this._leadUpdatesSubscription = this._leadService.updatedLead.subscribe((res: { leadId: number, note: string }) => {
      this._lastNote = res.note;
      this.updateRow(res.leadId);
    })
  }

  ngOnInit(): void {
    this.scrollOffset = 280;
    this.getScreenSize();
    this.dialerEnabled = this.applicationContext.userPermissions.dialerEnabled;
    this._isAdmin = this.applicationContext.userPermissions.admin;
    this.leadStatuses = this.applicationContext.globalConfig.leadStatus || [];
    this.loanPurposes = this.applicationContext.globalConfig.loanPurpose || [];
    this.loanTypes = this.applicationContext.globalConfig.loanType || [];
    this.users = this.applicationContext.globalConfig.users || [];
    this.leadCampaigns = this.applicationContext.globalConfig.leadCampaigns || [];
    this.isTpoUser = this.applicationContext.userPermissions.userType === 'TPO';

    this.loadColumnsToDisplayOnTable();

    this.globalFilterFields = this.columns.map(c => c.field);

    const customFilterName = 'tags-filter';
    this._filterService.register(customFilterName, (value, filter): boolean => {
      if (filter == null || filter.length == 0) {
        return true;
      }
      return value.some((r) => filter.includes(r));
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      const localTableState = this._localStorageService.getItem('leads-power-user-table-state');
      this._localStorageService.removeItem('leads-power-user-table-state')
      this.tableState = this.webPreferences.leadsPreferences?.leadsTableState;

      if (localTableState && localTableState != this.tableState) {
        this.tableState = localTableState;
        this.webPreferences.leadsPreferences.leadsTableState = localTableState;
        this._commonService.saveWebPreferences(this.webPreferences).subscribe(response => { }, (err) => {
          this._localStorageService.setItem('leads-power-user-table-state', this.tableState);
        });
      }

      if (!this.tableState) {
        this.tableState = {
          multiSortMeta: [{
            field: 'dateInserted',
            order: 1
          }]
        }
        this.webPreferences.leadsPreferences.leadsTableState = this.tableState;
      }
      this.selectedRows = [];
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this._applicationContextSubscription) {
      this._applicationContextSubscription.unsubscribe();
    }
    if (this._systemLevelSubscription) {
      this._systemLevelSubscription.unsubscribe();
    }
    if (this._leadUpdatesSubscription) {
      this._leadUpdatesSubscription.unsubscribe();
    }
  }

  updateAfterAddOrEdit = (leadId: number) => {
    const existingLead = this._leads.find(l => l.leadId == leadId);
    if (!existingLead) { // Added a new one
      this.getLeads();
    }
    else {
      this.updateRow(leadId);
    }
  }

  viewLead = (leadId: number): void => {
    let modalRef = this._modalService.open(ViewLeadDrawerComponent, Constants.modalOptions.fullScreen);
    modalRef.componentInstance.leadId = leadId;

    modalRef.result.then(() => {
      this.getAllLeadLists();
    }, () => {
      this.updateRow(leadId);
      this.getAllLeadLists();
    });
  }

  updateRow = (leadId: number) => {
    this._leadService.getLead(leadId)
      .pipe(
        finalize(() => {
          this._lastNote = "";
        })
      )
      .subscribe(lead => {
        lead.mostRecentNote = lead.mostRecentNote ? lead.mostRecentNote : this._lastNote;
        this._leads = this._leads.map(l => l.leadId == leadId ? lead : l);
        this.filteredLeads = this.filteredLeads.map(l => l.leadId == leadId ? lead : l);
        this.populateOtherLeadPropertiesToDisplayOnTable(this._leads);
        this.populateOtherLeadPropertiesToDisplayOnTable(this.filteredLeads);
      });

  }

  openReassignModal = () => {
    let modalRef = this._modalService.open(ReassignLeadUserConfirmationDialogComponent, Constants.modalOptions.medium);

    modalRef.componentInstance.leadIds = this.selectedRows.map(r => r.leadId);
    modalRef.componentInstance.users = this.users;

    modalRef.result.then((selectedReassignUserGUID: string) => {
      this._leads.forEach(lead => {
        const index = this.selectedRows.findIndex(l => l.leadId === lead.leadId);
        if (index > -1) {
          this.populateLeadContactName(lead, selectedReassignUserGUID);
        }
      })

      this._leads = [...this._leads];
      this.selectedRows = [];
    }, () => {
    });

  }

  getLeads = (): void => {
    if (this.filters.dateRange && this.isFilterDateRangeInValid(this.filters.dateRange)) {
      return;
    }
    this.isLoading = true;

    let observable: Observable<Lead[]>;

    if (this.showUnassignedLeads) {
      observable = this._leadService.getUnassignedLeads(this.filters);
    }
    else {
      observable = this._leadService.getLeads(this.filters);
    }

    this._spinner.show();
    observable.pipe(finalize(() => {
      this.isLoading = false;
      this._spinner.hide();
    })).subscribe({
      next: (result: Lead[]) => {
        this._leads = [...result];
        this.populateOtherLeadPropertiesToDisplayOnTable(result);
        let filteredLeads = [...result];
        this.filteredLeads = Utils.filter(this.globalFilterFields, this.globalSearchString, filteredLeads);
      },
      error: (error) => {
        this.error = error?.message || "Error encountered while loading leads";
        this._notifyService.showError(this.error, "Error!");
      },
    })
  }

  getTodayLeads = (): void => {
    if (this.isFilterDateRangeInValid(this.filters.dateRange)) {
      return;
    }

    const nowDate = DateTime.now();
    let _filters = {
      dateRange: {
        displayText: "Today",
        name: "Today",
        id: 1,
        startDate: nowDate.startOf('day').toJSDate(),
        endDate: nowDate.endOf('day').toJSDate()
      },
      branchId: this.filters?.branchId,
      campaignId: this.filters?.campaignId,
      leadListId: this.filters?.leadListId,
      showArchived: this.filters?.showArchived,
      showUserId: this.filters?.showUserId,
      leadStatusIds: this.filters?.leadStatusIds,
      leadTagIds: this.filters?.leadTagIds,
      state: this.filters?.state,
      firstName: this.filters?.firstName,
      lastName: this.filters?.lastName,
      excludeLeadTagIds: this.filters?.excludeLeadTagIds
    };

    if (!_filters.dateRange || _filters.dateRange.displayText !== "Today") {
      _filters.dateRange.displayText = "Today";
      _filters.dateRange.name = "Today";
      _filters.dateRange.id = 1;
      _filters.dateRange.startDate = nowDate.startOf('day').toJSDate();
      _filters.dateRange.endDate = nowDate.endOf('day').toJSDate();
    }

    this.isLoading = true;

    let observable: Observable<Lead[]>;

    if (this.showUnassignedLeads) {
      observable = this._leadService.getUnassignedLeads(_filters);
    }
    else {
      observable = this._leadService.getLeads(_filters);
    }

    this._spinner.show();
    observable.pipe(finalize(() => {
      this.isLoading = false;
      this._spinner.hide();
    })).subscribe({
      next: (result: Lead[]) => {
        this.populateOtherLeadPropertiesToDisplayOnTable(result);
        this._leads.forEach(l => {
          const existingLead = result.find(rl => rl.leadId === l.leadId)
          if (existingLead) l = existingLead; // udapte lead
        })
        const _result = result.filter(rl => !this._leads.some(ll => ll.leadId === rl.leadId)); // filter not to have
        let filteredLeads = [...this._leads, ..._result];
        this.filteredLeads = Utils.filter(this.globalFilterFields, this.globalSearchString, filteredLeads);
      },
      error: (error) => {
        this.error = error?.message || "Error encountered while loading leads";
        this._notifyService.showError(this.error, "Error!");
      },
    })
  }

  private isFilterDateRangeInValid = (dateRange: IDateRange) => {
    if (!dateRange.startDate || !dateRange.endDate) return false;
    const startDate = Date.parse(dateRange.startDate.toString());
    const endDate = Date.parse(dateRange.endDate.toString());
    return isNaN(startDate) || isNaN(endDate);
  }
  onViewLeadDetailsClicked = (leadId: number) => {
    this.viewLead(leadId);
  }

  onEditLeadDetailsClicked = (lead: Lead) => {
    this.editRequestedForLead.emit(lead);
  }

  onDeleteLeadClicked = (leadId: number) => {
    if (!this._isAdmin) {
      this._notifyService.showError("You do not have permission to do that!", "No permission");
      return;
    }
    const self = this;
    Swal.fire({
      title: 'Are you sure?',
      text: 'Are you sure you want to delete this Lead?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      reverseButtons: true
    }).then(function (result: SweetAlertResult) {
      if (!result.value) {
        return;
      }
      self._leadService.deleteLead(leadId)
        .subscribe(() => {
          self._notifyService.showSuccess("Lead deleted succesfully.", "Success!");
          self.getLeads();
        }, (err) => {
          self._notifyService.showError(err?.message || "Error encountered while deleting lead.", "Error!");
        });
    });
  }

  getSubjectPropertyText = (rowData: Lead): string => {

    let addr = '';
    if (
      typeof rowData.subjectPropertyAddress1 === 'string'
      &&
      !this._isSubjectPropertyAddressHidden
    ) {
      addr = rowData.subjectPropertyAddress1;
    }
    let city = '';
    if (typeof rowData.subjectPropertyCity === 'string') {
      city = rowData.subjectPropertyCity;
    }
    let state = '';
    if (typeof rowData.subjectPropertyState === 'string') {
      state = rowData.subjectPropertyState.toUpperCase();
    }

    let zip = '';
    if (typeof rowData.subjectPropertyZip === 'string') {
      zip = rowData.subjectPropertyZip.toUpperCase();
    }
    if (addr.length > 0)
      addr += "<br>";

    if (city.length > 0)
      city += ", ";

    return addr + city + state + " " + zip;
  }

  getInterestRateText = (rate: number): string => {
    return (rate * 100).toFixed(3) + "%";
  }

  getLeadListNameById = (tagId: number): string => {
    if (!this.leadLists) {
      return "";
    }
    let matched = this.leadLists.filter(tag => tag.tagId == tagId)[0];
    return matched ? matched.name : "";
  }

  onGlobalSearchStringChanged = () => {
    const globalSearchCache: any = {};
    globalSearchCache.globalSearchString = this.globalSearchString;
    this.filteredLeads = Utils.filter(this.globalFilterFields, this.globalSearchString, this._leads);
    this.leadTable.first = 0;
  }

  addedToDialList() {
    this.selectedRows = [];
  }

  exportToCSV() {
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Leads');

    const columnsToExport = [];
    this.columns.forEach(column => {
      if (this.selectedColumns.some(col => col.field === column.field)) {
        columnsToExport.push(column);
      }
    })

    const orderedColumnsToExport = columnsToExport.map(col => ({ ...col, key: col.field }));
    worksheet.columns = orderedColumnsToExport;

    this.filteredLeads.forEach(row => {
      const excelRow = {};
      orderedColumnsToExport.forEach(c => {
        excelRow[c.key] = row[c.key] || '';
      });
      worksheet.addRow(excelRow);
    })

    workbook.csv.writeBuffer().then(buffer => {
      saveAs(new Blob([buffer], { type: "application/octet-stream" }), "Leads.csv");
    });
  }

  openColumnSelectorForMobile = () => {
    setTimeout(() => {
      this.mobileColumnSelector?.containerViewChild?.nativeElement?.click();
    })
  }

  onStateSave = (event) => {
    const colOrder: string[] = event.columnOrder || [];
    if (colOrder.length > 0) {
      const columnsWithOrder = this.columns.filter(col => colOrder.indexOf(col.field) > -1).sort((a, b) => colOrder.indexOf(a.field) - colOrder.indexOf(b.field));
      const columnsWithoutOrder = this.columns.filter(col => colOrder.indexOf(col.field) === -1);
      this.columns = [...columnsWithOrder, ...columnsWithoutOrder].map((col, index) => ({
        ...col,
        order: col.order = index + 1
      }));
    }

    this.selectedColumns.forEach((col, index) => col.order = index + 1);

    let localTableState = this._localStorageService.getItem('leads-power-user-table-state') as any;
    this._localStorageService.removeItem('leads-power-user-table-state');
    if (this.webPreferences) {
      this.webPreferences.leadsPreferences.leadsPowerUserTableSelectedColumns = this._selectedColumns;
      this.webPreferences.leadsPreferences.leadsTableState = localTableState;
      this._commonService.saveWebPreferences(this.webPreferences).subscribe(response => { }, (err) => {
        this._localStorageService.setItem('leads-power-user-table-state', localTableState);
        this._localStorageService.setItem(this._selectedColumnsLocalStorageKeyName, this.selectedColumns);
      });
    }
  }

  private loadColumnsToDisplayOnTable = () => {
    const localSelectedColumns = <[]>(
      this._localStorageService.getItem(this._selectedColumnsLocalStorageKeyName)
    );

    if (localSelectedColumns) {
      this.webPreferences.leadsPreferences.leadsPowerUserTableSelectedColumns = localSelectedColumns;
      this._localStorageService.removeItem(this._selectedColumnsLocalStorageKeyName);
    }

    let selectedColumns: Column[] = this.webPreferences.leadsPreferences.leadsPowerUserTableSelectedColumns;

    if (!selectedColumns?.length) {
      this.selectedColumns = [];
      this.columns.forEach(column => {
        if (column.visible) {
          this.selectedColumns.push(column);
        }
      });

      this.webPreferences.leadsPreferences.leadsPowerUserTableSelectedColumns = this.selectedColumns;
      this._commonService.saveWebPreferences(this.webPreferences).subscribe(response => { }, (err) => {
        this._localStorageService.setItem(this._selectedColumnsLocalStorageKeyName, this._selectedColumns);
      });
    } else {
      this.selectedColumns = [];
      selectedColumns.forEach(column => {
        this.columns.forEach(c => {
          if (column['field'] === c.field) {
            this.selectedColumns.push(column);
          }
        })
      });
      this.webPreferences.leadsPreferences.leadsPowerUserTableSelectedColumns = this.selectedColumns;
      this._commonService.saveWebPreferences(this.webPreferences).subscribe(response => { }, (err) => {
        this._localStorageService.setItem(this._selectedColumnsLocalStorageKeyName, this._selectedColumns);
      });
    }
  }

  private getAllLeadLists = () => {
    this._leadService.getLeadLists().subscribe(lists => {
      this.leadLists = lists.filter(l => l.name != null && l.name.length > 0).sort((a, b) => a.name.localeCompare(b.name)); // name asc
    });
  }

  private populateOtherLeadPropertiesToDisplayOnTable = (leads: Lead[]): void => {
    const dateFields = this.columns.filter(c => c.dataType === 'date');
    leads.map(lead => {
      dateFields.forEach(dateField => {
        if (lead[dateField.field]) {
          lead[dateField.field] = new Date(lead[dateField.field]);
        }
      })
      lead['displayName'] = Utils.getPersonsDisplayName(lead);
      // populate matched lead status
      const matchedLeadStatus = this.leadStatuses.find(status => status.loanStatusId == lead.leadStatusId);
      if (matchedLeadStatus) {
        lead["leadStatusName"] = matchedLeadStatus.loanStatusName;
      }

      // populate matched lead source
      const matchedLeadCampaign = this.leadCampaigns.find(campaign => campaign.leadCampaignId == lead.leadCampaignId);
      if (matchedLeadCampaign) {
        lead["leadCampaignName"] = matchedLeadCampaign.name;
      }

      // populate matched loan purpose
      const matchedLoanPurpose = this.loanPurposes.find(loanPurpose => loanPurpose.loanPurposeId == lead.loanPurposeId);
      if (matchedLoanPurpose) {
        lead["loanPurposeName"] = matchedLoanPurpose.loanPurposeName;
      }

      // populate matched loan type
      const matchedLoanType = this.loanTypes.find(loanType => loanType.loanTypeId == lead.loanTypeId);
      if (matchedLoanType) {
        lead["loanTypeName"] = matchedLoanType.loanTypeName;
      }

      // populate matched lead contact
      this.populateLeadContactName(lead, lead.leadContactUserId);

      // populate matched referral source
      var referralSourceItem = this.referralSources.find(agent => agent.agentId == lead.referralSource);
      lead["referralSourceName"] = referralSourceItem ? referralSourceItem.lastName + " " + referralSourceItem.firstName : "";

      lead['tagNames'] = [];
      if (lead.tags && lead.tags.length > 0) {
        lead.tags.forEach(tag => {
          const matchedTag = this.tagLists.find(t => t.tagId === tag.tagId);
          if (matchedTag) {
            lead['tagNames'].push(matchedTag.name);
          }
      });
    }

      return lead;
    });

    let distinctLeadListIds = [...new Set(leads.map(l => l.leadListIds).flat())];

    let haveNewLeadLists = distinctLeadListIds.filter(id => !this.leadLists.map(l => l.leadListId).includes(id));

    if (haveNewLeadLists.length) {
      this.getAllLeadLists();
    }
  }

  private populateLeadContactName = (lead: Lead, leadContactUserId: string) => {
    const matchedLeadContact = this.users.find(user => user.userCompanyGuid == leadContactUserId);
    if (matchedLeadContact) {
      lead["leadContactName"] = Utils.getPersonsDisplayName(matchedLeadContact);
    }
  }
}
