import { Company } from "../company-model";

export class StackingOrder {
  stakingOrderId:	number;
  company:	Company;
  docType:	string;
  associate:	string;
  showOnTPOPortal:	boolean;
  companyId: number;
  insertedBy:	string;
  dateInserted:	Date;
  updatedBy:	string;
  dateUpdated:	Date;
}
