<div class="row form-group">
  <label class="col-sm-4 col-form-label" for="docType"
    >Stacking Order</label
  >
  <div class="col-sm-8">
    <input
      id="docType"
      name="docType"
      class="form-control"
      [(ngModel)]="stackingOrder.docType"
      #docTypeInput="ngModel"
      [ngClass]="{
        'is-invalid':
        docTypeInput &&
        docTypeInput.touched &&
        docTypeInput.invalid
      }"
      required
    />
    <div class="invalid-feedback">This field is required</div>
  </div>
</div>

<div class="row form-group">
  <label class="col-sm-4 col-form-label" for="showOnTPOPortal"
    >Show On TPO Portal</label
  >
  <div class="col-sm-8" style="padding-top: 0.4rem;">
    <input
      class="form-check-input"
      type="checkbox"
      name="showOnTPOPortal"
      id="showOnTPOPortal"
      [(ngModel)]="stackingOrder.showOnTPOPortal"
    />
  </div>
</div>
