<div class="modal-header">
  <h5 class="modal-title">Doc Type Mappings ({{mappingType == 'ToReggora' ? 'To Reggora' : 'From Reggora'}})</h5>
  <button type="button" class="btn-close" (click)="activeModal.dismiss()" aria-label="Close"></button>
</div>
<div class="modal-body">
  <div class="card">
    <div class="card-body">
      <p-table *ngIf="isLoadingCompleted" [value]="docTypeMappings" [paginator]="false" responsiveLayout="scroll" [autoLayout]="true">
        <ng-template pTemplate="caption">
          <div class="d-flex justify-content-start">
            <button class="btn btn-primary" (click)="addMapping()">
              Add Mapping
            </button>
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th style="width:12rem;text-align:center;">Actions</th>
            <th>Title</th>
            <th>Document Type </th>
            <th *ngIf="mappingType == 'ToLodasoft'">Doc Name Contains Csv</th>
            <th *ngIf="mappingType == 'ToLodasoft'">Use For Submission Pdf </th>
            <th *ngIf="mappingType == 'ToLodasoft'">Use For Submission Xml </th>
            <th *ngIf="mappingType == 'ToLodasoft'">Use For Submission Invoice </th>
            <th *ngIf="mappingType == 'ToReggora'">Use For P&S </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-mapping let-index="rowIndex">
          <tr>
            <td style="width:12rem;">
              <div class="d-flex justify-content-center justify-content-center">
                <a href="javascript:void(0);" (click)="editMapping(mapping)">
                    <i class="fas fa-pencil-alt"></i>
                </a>
                <span class="text-muted mx-1">|</span>
                <a href="javascript:void(0);"
                    *ngIf="(!(activeDeletionIndex === index) && mapping.reggoraDocTypeId > -1)"
                    (click)="onDeleteButtonClicked(index)">
                    <i class="fas fa-trash-alt text-danger"></i>
                </a>
                <a *ngIf="activeDeletionIndex === index" class="table-icon-button mx-2"
                    href="javascript:void(0);" (click)="onDeleteCancelled()">
                    <i class="fa fa-times text-danger"></i>
                </a>
                <a *ngIf="activeDeletionIndex === index" class="table-icon-button"
                    href="javascript:void(0);" (click)="onDeleteConfirmClicked(mapping)">
                    <i class="fa fa-check-circle"></i>
                </a>
              </div>
            </td>
            <td>{{mapping.title}}</td>
            <td>{{mapping['documentTypeName']}}</td>
            <td *ngIf="mappingType == 'ToLodasoft'">{{mapping.reggoraDocNameContainsCsv}}</td>
            <td *ngIf="mappingType == 'ToLodasoft'">{{mapping.useForSubmissionPdf ? 'Yes' : 'No'}}</td>
            <td *ngIf="mappingType == 'ToLodasoft'">{{mapping.useForSubmissionXml ? 'Yes' : 'No'}}</td>
            <td *ngIf="mappingType == 'ToLodasoft'">{{mapping.useForSubmissionInvoice ? 'Yes' : 'No'}}</td>
            <td *ngIf="mappingType == 'ToReggora'">{{mapping.useForPAndS ? 'Yes' : 'No'}}</td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="7" class="text-center">
              There are no items.
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary right" (click)="activeModal.dismiss()">
    Cancel
  </button>
</div>