import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataService } from 'src/app/core/services/data.service';
import { DocumentType } from 'src/app/models';
import { StackingOrder } from 'src/app/models/config/stacking-order.model';

@Injectable()
export class StackingOrderService {
  constructor(private readonly _dataService: DataService) {}

  getStackingOrders = (): Observable<StackingOrder[]> => {
    return this._dataService
      .get(`api/Admin/AllStackingOrder`);
  }


  getStackingOrder = (stackingOrderId: number): Observable<StackingOrder> => {
    return this._dataService
      .get(`api/Admin/StackingOrderInfo/${stackingOrderId}`);
  }

  insertStackingOrder = (stackingOrder: StackingOrder): Observable<StackingOrder> =>{
    let url = `api/Admin/InsertStackingOrder`;

    return this._dataService.post(url, stackingOrder);
  }

  updateStackingOrder = (stackingOrder: StackingOrder): Observable<any> =>{
    let url = `api/Admin/UpdateStackingOrder`;

    return this._dataService.post(url, stackingOrder);
  }

  deletestackingOrder = (id: number): Observable<any> => {
    let url = `api/Admin/DeleteStackingOrder/${id}`;

    return this._dataService.delete(url);
  }

}
