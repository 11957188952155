import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize, Subscription } from 'rxjs';
import { DocumentType, Role } from 'src/app/models';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { Constants } from 'src/app/services/constants';
import { NotificationService } from 'src/app/services/notification.service';
import { ApplicationContextBoundComponent, RearrangeOrderComponent } from 'src/app/shared/components';
import { DrawerComponent } from 'src/app/shared/components/drawer/drawer.component';
import { TableColumn } from 'src/app/shared/models/tale-column.model';
import { DrawerOptions, DrawerSize, DrawerService } from 'src/app/shared/services/drawer.service';
import { DeleteWithReplacementConfirmationDialogComponent } from '../../../company/components/shared/delete-with-replacement-confirmation-dialog/delete-with-replacement-confirmation-dialog.component';
import { AgentType } from '../../../company/models/agent-type.model';
import { DocumentTypeDetailsDialogComponent } from '../../dialogs/document-type-details-dialog/document-type-details-dialog.component';
import { DocumentTypeService } from '../../services/document-type.service';

@Component({
  selector: 'app-document-types',
  templateUrl: './document-types.component.html',
  styleUrls: ['./document-types.component.scss']
})
export class DocumentTypesComponent extends ApplicationContextBoundComponent implements OnInit {

  @ViewChild('docTypeOrderDrawer')
  docTypeOrderDrawer: DrawerComponent;

  @ViewChild('docTypeOrder')
  docTypeOrder: RearrangeOrderComponent

  types: DocumentType[] = [];
  selectedColumns: TableColumn[] = [];
  columns: TableColumn[] = [];
  globalFilterFields: string[] = [];
  agentTypes: AgentType[] = [];
  roles: Role[] = [];
  itemsToOrder: Array<{ name: string, value: number }> = [];

  isLoading: boolean = false;
  superAdmin: boolean = false;

  isDeleteClicked = {};
  orderDrawerOptions: DrawerOptions = {
    size: DrawerSize.Large,
    containerWrapperId: null
  }

  companyName: string;

  companyId: number;

  private _applicationContextSubscription: Subscription;

  constructor(
    private readonly _documentTypeService: DocumentTypeService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _notifyService: NotificationService,
    private readonly _modalService: NgbModal,
    private readonly _drawerService: DrawerService,
    private readonly injector: Injector
  ) {
    super(injector);
    this.scrollOffset = 265;
  }

  ngOnInit(): void {
    this.getScreenSize();

    this._applicationContextSubscription = this.applicationContextService.context.subscribe(context => {
      this.superAdmin = context.userPermissions.superAdmin;

      let matchedCompany = context.globalConfig.company.find(c => c.companyGUID == context.currentlyLoggedInUser.userCompanyGuid)
      this.companyName = matchedCompany ? matchedCompany.companyName : null;

      this.companyId = context.userPermissions.companyId;
      this.agentTypes = context.globalConfig.agentType;
      this.roles = context.globalConfig.roles;

      this.columns = [
        { field: 'documentTypeName', header: 'Document Type', order: 1, visible: true },
        { field: 'showOnBorrowerPortal', header: 'Show On Borrower Portal', order: 2, visible: true },
        { field: 'showOnChannels', header: 'Show On Channels', order: 3, visible: true },
        { field: 'allowUploadOnChannels', header: 'Allow Upload On Channels', order: 4, visible: true },
        { field: 'permittedAgentTypes', header: 'Permitted Agent Types', order: 5, visible: true },
        { field: 'restrictedToRoles', header: 'Exclusive Role Access', order: 6, visible: true },
        { field: 'order', header: 'Sort', order: 7, visible: true },
      ];

      this.columns.forEach(column => {
        this.globalFilterFields.push(column.field);
        if (column.visible) {
          this.selectedColumns.push(column);
        }
      });

      this.getTypes();

    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._applicationContextSubscription?.unsubscribe();
  }

  getSortedColumns = () => {
    return this.selectedColumns.sort((a, b) => a.order - b.order);
  }

  addNewType = (): void => {
    let modalRef = this._modalService.open(DocumentTypeDetailsDialogComponent, Constants.modalOptions.ninetyPercentOfScreenWidth);
    modalRef.result.then(() => {
      this.getTypes();
    }, () => {
    });
  }

  getTypes = (): void => {
    this.isLoading = true;

    this._spinner.show();

    this._documentTypeService.getDocumentTypes().subscribe(result => {
      this.types = result;
    }, (err) => {
      this._notifyService.showError(err.error.message || err.error, "Error!");
    }).add(() => {
      this.isLoading = false;
      this._spinner.hide();
    });
  }

  onEditTypeClicked = (documentType: DocumentType) => {
    let modalRef = this._modalService.open(DocumentTypeDetailsDialogComponent, Constants.modalOptions.ninetyPercentOfScreenWidth);
    modalRef.componentInstance.documentType = documentType;

    modalRef.result.then(() => {
      this.getTypes();
    }, () => {
    });
  }

  openOrderDrawer = () => {
    if (_.isEmpty(this.types)) {
      return;
    }

    this.itemsToOrder = _.chain(this.types)
      .orderBy(a => a.order)
      .map(a => ({
        name: a.documentTypeName,
        value: a.documentTypeId
      }))
      .value();

    this._drawerService.show("docTypeOrderDrawer", 100);
  }

  onOrderDrawerSave(sortedTemplates: Array<EnumerationItem>) {
    this.docTypeOrder.saveInProgress = true;

    const sortedIds = sortedTemplates.map(r => r.value);

    this._documentTypeService.saveDocumentTypeOrder(sortedIds)
      .pipe(finalize(() => {
        this.docTypeOrder.saveInProgress = false;
        this.closeOrderDrawer();
      }))
      .subscribe(() => {
        this.getTypes();
        this._notifyService.showSuccess('Document Type order updated Successfully', 'Update Document Type Order')
      }, (error) => {
        this._notifyService.showError(error.message || 'Unable to update Document Type order ', 'Update Document Type Order')
      });
  }

  closeOrderDrawer() {
    this._drawerService.hide("docTypeOrderDrawer", 100);
  }

  onDeleteTypeClicked = (typeId: number) => {

    const modalRef = this._modalService.open(DeleteWithReplacementConfirmationDialogComponent, Constants.modalOptions.medium);
    modalRef.componentInstance.ItemsList = this.types
      .filter(a => a.documentTypeId !== typeId)
      .map(a => ({
        value: a.documentTypeId,
        name: a.documentTypeName
      }));
    modalRef.componentInstance.itemType = 'Document Type';
    modalRef.result.then((replacementAgentTypeId: number) => {
      this._documentTypeService.deleteDocumentType(typeId, replacementAgentTypeId)
        .subscribe(() => {
          this._notifyService.showSuccess("Document Type removed succesfully", "Success");
          this.types = this.types.filter(t => t.documentTypeId != typeId);
        }, (err) => {
          this._notifyService.showError(err.error.message || "Error encountered while deleting type", "Error!");
        });
    });

  }

  getSelectedRoleNames = (roles: string) => {
    if (!roles || !roles.trim()) {
      return;
    }

    let selectedRoles: Role[] = [];
    let parts = roles.trim().split(",");
    parts.forEach(value => {
      value = value.trim();
      let matched = this.roles.find(s => s.roleId == Number(value));
      if (matched) {
        selectedRoles.push(matched);
      }
    });
    return selectedRoles.map(r => ' ' + r.roleName + ' ').join();
  }

  getSelectedAgentTypeNames = (agentTypes: string) => {
    if (!agentTypes || !agentTypes.trim()) {
      return;
    }

    let selectedTypes: AgentType[] = [];
    let parts = agentTypes.trim().split(",");
    parts.forEach(value => {
      value = value.trim();
      let matched = this.agentTypes.find(s => s.agentTypeId == Number(value));
      if (matched) {
        selectedTypes.push(matched);
      }
    })

    return selectedTypes.map(r => ' ' + r.agentTypeName + ' ').join();
  }
}
