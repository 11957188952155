import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { NgControl, NgModel } from '@angular/forms';
import { AbstractValueAccessor } from 'src/app/core/abstract-value-accessor';
import { MortgageFieldConfig, MortgageFieldRequiredState } from 'src/app/shared/services/mortgage-field-validation-utils';

@Component({
  template: '',
  host: { '(keydown)': 'onTouchedByUser($event)', '(mousedown)': 'onTouchedByUser($event)' },
})
export abstract class BaseUrlaInputComponent
  extends AbstractValueAccessor
  implements OnInit {

  @ViewChild('model', { static: true }) model: NgModel;

  @Output()
  blur: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  label: string;

  @Input()
  placeholder: string;

  @Input()
  required: boolean = false;

  @Input()
  disabled: boolean = false;

  @Input()
  name: string;

  @Input()
  id: string;

  @Input()
  showBorder: boolean = true;

  @Input()
  readonly: boolean;

  @Input()
  inEditMode: boolean = false;

  @Input()
  fieldBeingEdited: string = '';

  @Input()
  urlaFieldsConfig: {};

  @Input()
  hasTBDCheckbox: boolean = false;

  protected ngControl: NgControl;

  ngOnInit(): void {
    this.id = this.name + Date.now();
    if (this.urlaFieldsConfig && this.fieldBeingEdited && !this.urlaFieldsConfig[this.fieldBeingEdited]) {
      this.urlaFieldsConfig[this.fieldBeingEdited] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
    }
    if (this.urlaFieldsConfig && this.fieldBeingEdited && this.urlaFieldsConfig[this.fieldBeingEdited]?.readonly) {
      this.readonly = true;
    }
  }

  public isFocused = false;

  // Called when the input gains focus
  public onFocus(): void {
    this.isFocused = true;
  }

  onBlurred(e: any) {
    this.isFocused = false;
    this.blur.emit(e);
  }

  onTouchedByUser = (e: any) => {
    this.onTouched();
  }
}
