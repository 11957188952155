import { DocumentType } from "src/app/models"
import { ThirdPartyCredential } from "src/app/models/third-party/third-party-creds.model"

export class RoggoraDocType {
    reggoraDocTypeId: number;
    title: string;
    reggoraDocNameContainsCsv: string;
    syncDirection: ReggoraSyncDirection;
    useForPAndS: boolean;
    useForSubmissionPdf: boolean;
    useForSubmissionXml: boolean;
    useForSubmissionInvoice: boolean;
    useForUnmappedDocuments: boolean;
    documentTypeId: number;
    credentialId: number;
    documentType: DocumentType;
    credentials: ThirdPartyCredential;
    companyId: number;
    modelGuid: string;
    insertedBy: string
    dateInserted: Date;
    updatedBy: string;
    dateUpdated: Date;
}

export enum ReggoraSyncDirection {
    ToReggora = "ToReggora",
    ToLodasoft = "ToLodasoft"
}