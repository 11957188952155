import { Component, ElementRef, OnInit, ViewChild, inject, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbCarousel, NgbSlideEvent } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize, Subscription } from 'rxjs';
import { EnvironmentService } from 'src/app/core/services/environment/environment.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { Utils } from 'src/app/core/services/utils';
import { AuthData } from 'src/app/models/auth/auth-data.model';
import { AuthenticationResponse, AuthenticationUserCompany } from 'src/app/models/auth/authentication-response.model';
import { LoginStatus } from 'src/app/models/auth/login-status.enum';
import { AdminService } from 'src/app/services/admin.service';
import { ErrorMessage } from 'src/app/shared/models/error.model';
import Swal from 'sweetalert2';

import {
  UserType,
  AuthenticationRequest,
  ApplicationContext
} from '../../models';

import { ApplicationContextService } from '../../services/application-context.service';
import { AuthService } from '../../services/auth.service';
import { Constants } from '../../services/constants';
import { NavigationService } from '../../services/navigation.service';
import { Select2OptionData } from 'ng-select2';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { CacheService } from 'src/app/services/cache.service';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {

  @ViewChild('loginForm') loginForm: NgForm | undefined;
  @ViewChild('slider') slider: NgbCarousel;

  @ViewChild("twoFactorCodeInput")
  private _twoFactorCodeInput: ElementRef;

  @ViewChild("passwordInput")
  private _passwordInput: ElementRef;

  loginInfo: AuthenticationRequest;
  error: ErrorMessage | undefined;

  loggingIn: boolean = false;
  alreadyLoggedIn: boolean;

  companyName: string = null;
  backgroundImageUrl: string = null;
  tpoRegistrationLink: string = null;
  selectedCompanyName: string;

  availableCompanies: AuthenticationUserCompany[] = [];
  possibleLogoUrls: string[] = [];

  currentYear: number = new Date().getFullYear();

  protected countriesOptions = {
    width: '100%',
    multiple: false,
    closeOnSelect: true,
  };

  protected setting = {
    phone: null,
    areaCode: null,
    isTwoFactor: null
  }

  countries: Array<Select2OptionData> = [];

  protected needToConfirmPhone: boolean = false;

  protected verificationCode: string;

  protected passwordRequired: boolean = false;

  private _returnUrl: string | null = null;

  private readonly _adminService = inject(AdminService);
  private readonly _authService = inject(AuthService);
  private readonly _activatedRoute = inject(ActivatedRoute);
  private readonly _navigationService = inject(NavigationService);
  private readonly _applicationContext = inject(ApplicationContextService);
  private readonly _router = inject(Router);
  private readonly _spinner = inject(NgxSpinnerService);
  private readonly _environment = inject(EnvironmentService);
  private readonly _localStorageService = inject(LocalStorageService);
  private readonly _enumsService = inject(EnumerationService);
  private readonly _cacheService = inject(CacheService);

  private readonly _activatedRouteSubscription: Subscription;
  private _applicationContextSubscription: Subscription;

  private _queryParams: string = "";

  private _companySelectionRequired: boolean = false;

  constructor() {
    this.possibleLogoUrls = this._navigationService.possibleLogoUrls;
    this.backgroundImageUrl = this._environment.apiInfo.apiBaseUrl + 'company/co/login';
    this.loginInfo = new AuthenticationRequest();
    this.loginInfo.usernameValidationOnly = true;
    this.loginInfo.scope = Constants.authorization.adminScope;

    this._activatedRouteSubscription = this._activatedRoute.queryParams.subscribe((params) => {
      const companyGuid = params[Constants.authorization.companyGuid];
      this.loginInfo.companyGuid = companyGuid;
      this._returnUrl = params['returnUrl'];

      const userName = params['username'];
      if (userName) {
        this.loginInfo.username = userName;
      }
      Object.keys(params).forEach(key => {
        if (key !== 'returnUrl') {
          this._queryParams += `${key}=${params[key]}&`;
        } else {
          this._queryParams += `r_url=${params[key]}&`;
        }
      });
      if (this._queryParams && this._queryParams.endsWith('&')) {
        this._queryParams = this._queryParams.slice(0, -1);
      }
    });
  }

  ngOnInit() {
    const authData = this._localStorageService.authorizationData;
    if (authData && authData.token) {
      this._spinner.show();
      this.alreadyLoggedIn = true;
      console.log(`User ${authData.userName} is already logged in`);
      this._applicationContextSubscription?.unsubscribe();
      this._applicationContextSubscription = this._applicationContext.context.subscribe(context => {
        console.log("Context retrieved current user info.", context.currentlyLoggedInUser);
        this.onAfterLogin(context);
      }, error => {
        console.error(error);
        this._spinner.hide();
        this.alreadyLoggedIn = false;
      });
    }

    this._adminService.getTpoRegistrationLink().subscribe({
      next: (res) => {
        this.tpoRegistrationLink = res?.tpoUserRegistrationURL || null;
      }
    });

    this.countries = this._enumsService.countries.map((val: any) => ({
      id: val.areaCode,
      text: `(${val.areaCode}) ${val.name}`,
      code: val.areaCode
    }))
  }

  ngOnDestroy() {
    this._activatedRouteSubscription?.unsubscribe();
    this._applicationContextSubscription?.unsubscribe();
  }

  onNewPhoneNumberChanged = () => {

  }

  onChangeNumberClicked = () => {
    this.needToConfirmPhone = false;
  }

  onConfirmPhoneClicked = () => {
    this.loggingIn = true;
    this._authService.confirmUpdatePhoneAnonymous(this.setting.phone, this.setting.areaCode, this.verificationCode, this.loginInfo)
      .pipe(finalize(() => {
      }))
      .subscribe({
        next: () => {
          this.onLoginClicked();
        },
        error: (error) => {
          this.loggingIn = false;
          if (error?.code === "InvalidToken") {
            this.setLoginError(error.description);
          } else {
            this.setLoginError(`Couldn't process the confirmation code.`);
          }
        }
      })
  }

  onSaveNumberClicked() {
    this.loggingIn = true;
    this._authService.updatePhoneAnonymous(this.setting.phone, this.setting.areaCode, this.loginInfo)
      .pipe(finalize(() => this.loggingIn = false))
      .subscribe({
        next: (res) => {
          this.needToConfirmPhone = true;
        },
        error: (error) => {
          this.setLoginError(error?.message || 'Unable to update phone number');
        }
      });
  }

  onLoginClicked = () => {
    if (this.passwordRequired && this.loginInfo.password) {
      this.passwordRequired = false;
    }
    this.error = undefined;
    this.doLogin();
  };

  onLetsGoClickedOnCompanySelectionPage = () => {
    this.onAvailableCompanyPickedForLogin();
  }

  onBackClickedOnPasswordPage = () => {
    if (this._companySelectionRequired) {
      this.loginForm.form.markAsUntouched();
      this.loginInfo.password = null;
      this.passwordRequired = false;
      this.slider.select('companySelection');
      return;
    }
    this.onCancelLoginClicked();
  }

  onCancelLoginClicked = () => {
    this.error = undefined;
    this.slider.select('userNameEntry');
    this.loginInfo.companyGuid = null;
    this.loginInfo.userCompanyGuid = null;
    this.loginInfo.twoFactorCode = null;
    this.loginInfo.scope = null;
    this.loginInfo.username = null;
    this.loginInfo.password = null;
    this.selectedCompanyName = "";
    this.passwordRequired = false;
    this._companySelectionRequired = false;
    this.loginInfo.usernameValidationOnly = true;

    this.loginForm.form.markAsUntouched();

    this.needToConfirmPhone = false;
    this.setting.phone = null;
    this.setting.areaCode = null;
    this.verificationCode = null;
  }

  onForgotPasswordClicked = () => {
    this.error = undefined;
    this._router.navigate(['forgot-password'], {
      queryParams: {
        scope: this.loginInfo.scope,
        email: this.loginInfo.username
      },
    });
  };

  onAvailableCompanyPickedForLogin = () => {
    if (!this.loginInfo.userCompanyGuid) {
      this.loginInfo.scope = null;
      this.loginInfo.companyGuid = null;
      this.selectedCompanyName = "";
      return;
    }
    const companyThatIsPicked = this.availableCompanies.find(company => company.userCompanyGuid == this.loginInfo.userCompanyGuid);
    if (companyThatIsPicked) {
      this.loginInfo.scope = companyThatIsPicked.userType;
      this.loginInfo.companyGuid = companyThatIsPicked.companyGuid;
      this.loginInfo.userCompanyGuid = companyThatIsPicked.userCompanyGuid;
      this.selectedCompanyName = companyThatIsPicked.companyName;

      if (companyThatIsPicked.isExternalAuth) {
        this.error = undefined;
        this.redirectToExtternalAuthProvider();
        return;
      }

      this.passwordRequired = true;
      this.slider.select('passwordEntry');
    }
  }

  onTestBackgroundImageErrored = () => {
    this.backgroundImageUrl = "./assets/images/background-temp.png";
  }

  onCarouselTransitionComplete = (e: NgbSlideEvent) => {
    if (e.current === 'twoFactorCodeEntry') {
      this._twoFactorCodeInput.nativeElement.focus();
    }
    if (e.current === 'passwordEntry') {
      this._passwordInput.nativeElement.focus();
    }
  }

  onSendCodeClicked = () => {
    this._authService.sendTwoFactorPhoneCode(this.loginInfo.userCompanyGuid).subscribe(response => {
      Swal.fire(
        'Sent Code',
        'We sent another code to your phone. Please check your phone and use that code to login.',
        'success'
      )
    }, err => {

    })
  }

  private doLogin = () => {
    if (this.loginForm) {
      this.loginForm.form.markAllAsTouched();
      if (this.loginForm.form.valid) {
        this.loggingIn = true;
        this._authService.signIn(this.loginInfo).subscribe(
          (response: AuthenticationResponse) => {

            if (response.userCompanyGuid) {
              this.loginInfo.userCompanyGuid = response.userCompanyGuid;
            }

            if (response.loginStatus === LoginStatus.Error) {
              this.onLoginErrored(response);
            } else if (response.loginStatus === LoginStatus.UsernameValidationComplete) {
              this.onUserNameValidationComplete(response.availableCompanies);
            } else if (response.loginStatus === LoginStatus.TwoFactorAuthenticationSetupRequired) {
              if (!this.loginInfo.userCompanyGuid && response.availableCompanies.length > 0) {
                this.loginInfo.userCompanyGuid = response.availableCompanies[0].userCompanyGuid;
              }
              this.onMfaSetupRequired();
            } else if (response.loginStatus === LoginStatus.CompanySelectionRequired) {
              this.onCompanySelectionRequired(response.availableCompanies);
            } else if (response.loginStatus === LoginStatus.TwoFactorAuthenticationRequired) {
              if (!this.loginInfo.userCompanyGuid && response.availableCompanies.length > 0) {
                this.loginInfo.userCompanyGuid = response.availableCompanies[0].userCompanyGuid;
              }
              this.onTwoFactorCodeRequired();
            } else if (response.loginStatus === LoginStatus.Success) {
              this.persistAuthData(response);
              this._applicationContextSubscription = this._applicationContext.context.subscribe(this.onAfterLogin);
            }
          },
          (error) => {
            this.companyName = null;
            this.loginInfo.companyGuid = null;
            this.loginInfo.userCompanyGuid = null;
            this.setLoginError('Unable to login at the moment, please try again later.');
            this.loggingIn = false;
          }
        );
      }
    }
  }

  private redirectToExtternalAuthProvider = () => {
    const scope = this.loginInfo.scope || 'Admin';
    if (this._queryParams) {
      this._queryParams += `&scope=${scope}`;
    }
    const returnUrl = `${window.location.protocol}//${window.location.host}/ext-auth-callback?${this._queryParams}`;

    const challengeUrl = this._environment.apiInfo.apiBaseUrl + `/api/auth/connect/challenge/${this.loginInfo.companyGuid}/${scope}?returnUrl=${encodeURIComponent(returnUrl)}&userName=${this.loginInfo.username}`;
    window.location.href = challengeUrl;
  }

  private onAfterLogin = (context: ApplicationContext) => {
    if (this._returnUrl) {
      let returnUrl = this._returnUrl;
      if (!this._returnUrl.includes('admin') && !this._returnUrl.includes('tpo')) {
        returnUrl = '/admin/' + this._returnUrl;
      }
      this._navigationService.navigateToPath(returnUrl);
      return;
    }
    // TODO: Kaan - investigate what the below is for??
    if (this._activatedRoute.snapshot.queryParams.redirect) {
      this._navigationService.navigateToPath(this._activatedRoute.snapshot.queryParams.redirect);
      return;
    }
    if (context.currentlyLoggedInUser.userType === UserType.Tpo) {
      this._navigationService.navigateToPath('tpo');
    } else {
      this._navigationService.navigateToPath('admin');
    }
  };

  private onLoginErrored = (response: AuthenticationResponse) => {
    if (response.errorMessage === 'Bad username or password') {
      this.loginInfo.password = null;
      this.passwordRequired = true;
      this.loginForm.form.markAsUntouched();
    }
    this.setLoginError(response.errorMessage);
    this.loggingIn = false;
  }

  private onTwoFactorCodeRequired = () => {
    this.loginInfo.usernameValidationOnly = false;
    this.slider.select('twoFactorCodeEntry');
    this.loggingIn = false;
  }

  private onCompanySelectionRequired = (availableCompanies: AuthenticationUserCompany[]) => {
    this.loggingIn = false;
    this.loginInfo.usernameValidationOnly = false;
    this.availableCompanies = availableCompanies;
    if (availableCompanies.length == 1) {
      this.loginInfo.userCompanyGuid = availableCompanies[0].userCompanyGuid;
      this.loginInfo.companyGuid = availableCompanies[0].companyGuid;
      this.onAvailableCompanyPickedForLogin();
      return;
    }
    this._companySelectionRequired = true;
    if (this.slider) {
      this.slider.select('companySelection');
    }
  }

  private onUserNameValidationComplete = (availableCompanies: AuthenticationUserCompany[]) => {
    this.loggingIn = false;
    this.loginInfo.usernameValidationOnly = false;
    this.availableCompanies = availableCompanies;
    this.loginInfo.userCompanyGuid = availableCompanies[0].userCompanyGuid;
    this.onAvailableCompanyPickedForLogin();
  }

  private onMfaSetupRequired = () => {
    this.passwordRequired = false;
    this.loginInfo.usernameValidationOnly = false;
    this.slider.select('mfaSetup');
    this.loggingIn = false;
  }

  private setLoginError = (msg: string) => {
    this.error = new ErrorMessage('Unable to login!', msg);
  };

  private persistAuthData = (authResponse: AuthenticationResponse): void => {

    let jwtPayload = Utils.parseJwt(authResponse.jwtToken);

    const authData: AuthData = {
      companyGuid: this.loginInfo.companyGuid,
      userCompanyGuid: this.loginInfo.userCompanyGuid,
      token: authResponse.jwtToken,
      role: jwtPayload.AspNetRole,
      userName: authResponse.userName,
      refreshToken: authResponse.refreshToken,
      companies: this.availableCompanies,
      rememberMe: this.loginInfo.rememberMe,
      expiresAt: Utils.getTokenExpireDate(jwtPayload.exp)
    };
    this._cacheService.setTenantPrefix(authData.userCompanyGuid);

    this._localStorageService.authorizationData = authData;
  }
}

export enum LoginPage {
  UserNameEntry = 'UserNameEntry',
  PasswordEntry = 'PasswordEntry',
  TwoFactorCodeEntry = 'TwoFactorCodeEntry',
  CompanySelection = 'CompanySelection',
  MfaSetup = 'MfaSetup'
}
