import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { DocumentType, LoanDoc } from 'src/app/models';
import { NgForm } from '@angular/forms';
import { LoanDocsService } from '../../services/loan-docs.service';
import { forkJoin, Observable } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationService } from 'src/app/services/notification.service';
import { DocFile } from '../../models/doc-file.model';
import { LoanDocService } from 'src/app/services/loan-doc.service';
import * as _ from 'lodash';
import {DocumentTemplateService} from '../../../../services/document-template.service';
import {Constants} from 'src/app/services/constants';
import {PdfPasswordModalComponent} from '../pdf-password-modal/pdf-password-modal.component';
import {FileService} from 'src/app/services/file.service';

@Component({
  selector: 'loan-docs-dialog',
  templateUrl: 'loan-docs-dialog.component.html',
  styleUrls: ['loan-docs-dialog.component.scss']
})

export class LoanDocsDialogComponent implements OnInit {

  @ViewChild('loanDocForm') loanDocForm: NgForm | undefined;

  @Input()
  title: string;

  @Input()
  appId: number;

  @Input()
  roleId: number;

  @Input()
  isTpoUser: boolean;

  @Input()
  isEdit: boolean;

  @Input()
  docTypes: DocumentType[];

  @Input()
  loanDoc: LoanDoc;

  @Input()
  autoConvertToPdf: boolean;

  @Input()
  uploadedFiles: File[];

  uploadedNewFiles: File[] = [];

  filteredDocumentTypesForEdit: DocumentType[];

  loanDocBeingEdited: LoanDoc;

  saveLoandocLoading: boolean = false;

  activeDeletionIndex: number = -1;

  private _useDynamicCompression: boolean = true;

  currentDate: Date;

  dateFormat = 'yyyy-MM-dd';

  private _originalDocFileCount: number = 0;

  optionsSelect = {
    width: '100%',
    multiple: false,
    closeOnSelect: true,
    dropdownParent: "#editLoanDoc",
    placeholder: "Select Doc Type"
  };

  constructor(public activeModal: NgbActiveModal,
    private readonly _loanDocsService: LoanDocsService,
    private readonly _fileService: FileService,
    private _modalService: NgbModal,
    private readonly _spinner: NgxSpinnerService,
    private readonly _notifyService: NotificationService,
    private readonly _loanDocService: LoanDocService) {
  }

  ngOnInit() {

    this.filteredDocumentTypesForEdit = this.docTypes.filter(docType => this.filterDocType(docType));

    if (!this.isEdit) {
      this.loanDocBeingEdited = new LoanDoc(this.appId);

      if(this.filteredDocumentTypesForEdit.length == 1 && this.isTpoUser)
        this.loanDocBeingEdited.documentTypeId = this.filteredDocumentTypesForEdit[0].documentTypeId;

      if (this.uploadedFiles.length > 0) {
        this.uploadedFiles.forEach((file: File) => {
          if (file.type === 'application/pdf') {
            this.handlePdfPasswordValidation(file);
          } else {
            this.addFileToUploadList(file);
          }
        });
      }
    } else {
      this.loanDocBeingEdited = _.cloneDeep(this.loanDoc);
      this._originalDocFileCount = this.loanDocBeingEdited.docFiles ? this.loanDocBeingEdited.docFiles.length : 0;
    }
    this.currentDate = new Date();
  }

  onSaveClicked = () => {
    if (this.loanDocForm) {
      this.loanDocForm.form.markAllAsTouched();
      if (this.loanDocForm.form.valid) {
        this.saveLoanDoc();
      }
    }
  }

  onOpenLoanDocClicked = (docFile: DocFile) => {
    if (!docFile.guid) {
      const reader = new FileReader();
      reader.readAsArrayBuffer(docFile.file);
      reader.onload = (event) => {
        const blob = new Blob([reader.result], { type: docFile.file.type });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      };
      return;
    }

    this._spinner.show();
    this._loanDocsService.viewLoanDocContent(docFile.guid).subscribe({
      next: data => {
        const blob = new Blob([data], { type: data['type'] });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      },
      error: (error) => {}
    }).add(() => this._spinner.hide());
  }

  onDownloadDocumentClicked = (file: DocFile) => {
    this._spinner.show();
    this._loanDocsService.getLoanDocContent(file.guid).subscribe(data => {
      this._spinner.hide();
      const mimeType = file.mimeType;
      const blob = new Blob([data], { type: mimeType });
      let downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(blob);
      let fileName = file.fileName;
      downloadLink.setAttribute('download', fileName);
      document.body.appendChild(downloadLink);
      downloadLink.click();
    }, error => {
      this._spinner.hide();
    })
  }

  onDeleteFileClicked = (index: number) => {
    this.activeDeletionIndex = index;
  }

  onDeleteFileCancelClicked = () => {
    this.activeDeletionIndex = -1;
  }

  onDeleteFileConfirmClicked = (docFile: DocFile) => {
    this._spinner.show();
    this.activeDeletionIndex = -1;
    this._loanDocsService.deleteDocFile(this.loanDoc, docFile.guid).subscribe(result => {
      this._spinner.hide();
      const index = this.loanDocBeingEdited.docFiles.indexOf(docFile);
      if (index >= 0) {
        this.loanDocBeingEdited.docFiles.splice(index, 1);
      }
    }, error => {
      this._spinner.hide();
      this._notifyService.showError(error.message || 'Unable to move file to trash!', 'Failure');
    })

  }

  removeFileFromLoanDoc = (docFiles: DocFile[], index: number) => {
    let file = docFiles[index];
    if (file != null) {
      if (file.guid == null) {
        docFiles.splice(index, 1);
      } else {
        file.active = false;
      }
    }
  }

  filterDocType = (type: DocumentType) => {
    if (type.restrictedToRoles && type.restrictedToRoles.length > 0) {
      var vals = type.restrictedToRoles.split(',');
      return vals.filter(x => Number(x) == this.roleId).length > 0 && (!this.isTpoUser || type.showOnChannels?.includes('Wholesale'));
    }
    return type.documentTypeId > 0 && (!this.isTpoUser || type.showOnChannels?.includes('Wholesale'));
  }

  addFileToUploadList = (file: File): void => {
    let docFile = new DocFile();
    docFile.fileName = file.name;
    docFile.file = file;
    docFile.guid = null;
    docFile.active = true;
    this.loanDocBeingEdited.docFiles.push(docFile);
  }

  onDocFilesChanged = (event: any) => {
    if (event.target?.files.length) {
      event.target.files.forEach((file: File) => {
        if (file.type === 'application/pdf') {
          this.handlePdfPasswordValidation(file);
        } else {
          this.addFileToUploadList(file);
        }
      });
    }
  }

  onFileUploadChanged = (uploadedFiles: File[]): void => {
    if (uploadedFiles.length > 0) {
      uploadedFiles.forEach((file: File) => {
        if (file.type === 'application/pdf') {
          this.handlePdfPasswordValidation(file);
        } else {
          this.addFileToUploadList(file);
        }
      });
    }

    if (this.uploadedNewFiles.length > 0) {
      this.uploadedNewFiles = [];
    }
  }

  private handlePdfPasswordValidation(file: File) {
    this._fileService.checkPdfPasswordProtection(file).subscribe({
      next: (response) => {
        if (response.isPasswordProtected) {
          // Show password prompt modal
          this.openPasswordModal(file);
        } else {
          // Add to upload list directly
          this.addFileToUploadList(file);
        }
      },
      error: (e) => this._notifyService.showError(e.error || "Unable to validate PDF. Check the file and please try again.","Error!")
    });
  }

  private openPasswordModal(file: File) {
    let modalRef = this._modalService.open(PdfPasswordModalComponent, Constants.modalOptions.medium);
    modalRef.componentInstance.file = file;

    modalRef.result
      .then((file: File) => {
        this.addFileToUploadList(file);
      })
  }

  private saveLoanDoc = () => {
    this._spinner.show();

    let payload = _.cloneDeep(this.loanDocBeingEdited);

    if (payload.documentTypeId == 0) {
      payload.documentTypeId = null;
    }

    if (this._originalDocFileCount > 0) {
      if (!payload.docFiles.length) {
        payload.documentTypeId = -1;
      }
    }

    if(payload.documentTypeId && !payload.description){
      let selectedDocType = this.filteredDocumentTypesForEdit.find(type => type.documentTypeId == payload.documentTypeId);
      payload.description = selectedDocType?.documentTypeName;
    }

    this._loanDocsService.upsertLoanDoc(payload).subscribe(result => {
      let calls: Observable<any>[] = [];

      const savedLoanDoc: LoanDoc = result;

      Array.from(this.loanDocBeingEdited.docFiles).forEach((docFile) => {
        docFile.status = 0;
        if (docFile.file) {
          calls.push(this._loanDocsService.uploadFileForLoanDoc(docFile.file, docFile, result.loanDocId, false, this.autoConvertToPdf,
            this._useDynamicCompression));
        }
      });

      if (calls.length === 0) {
        this.onAfterSuccessfulSave(savedLoanDoc.loanDocId);
        return;
      }

      forkJoin(calls).subscribe((result) => {
        this.loanDocBeingEdited.docFiles.forEach((f, index) => {
          const fileResult = result[index];
          Object.assign(f, fileResult);
          if (!f.active) {
            let index = this.loanDocBeingEdited.docFiles.indexOf(f);
            this.loanDocBeingEdited.docFiles.splice(index, 1);
          } else {
            f.status = 1;
          }
        });
        this.onAfterSuccessfulSave(savedLoanDoc.loanDocId);

      }, (error) => {
        this._spinner.hide();
        this._notifyService.showError(error.error || 'Errors have been detected!', 'Failure');
      });
    }, (error) => {
      this._spinner.hide();
      this._notifyService.showError(error.error || 'Failed to save the loan doc!', 'Failure');
    })
  }

  private onAfterSuccessfulSave = (loanDocId: number) => {
    this._loanDocService.getLoanDoc(loanDocId).subscribe(result => {
      this._spinner.hide();
      this._notifyService.showSuccess('The loan doc has been successfully saved!', 'Success');
      if (!result.documentTypeId) {
        result.documentTypeId = 0;
      }
      if (result.docFiles && result.docFiles.length) {
        const inactiveFiles = result.docFiles.filter(f => !f.active);
        if (inactiveFiles.length === result.docFiles.length) {
          result.documentTypeId = -1;
        }
      }
      this.activeModal.close(result);
    }, error => {
      this._spinner.hide();
      this._notifyService.showError(error.message || 'Failed to refresh the loan doc!', 'Failure');
    });
  }
}
