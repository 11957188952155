<form class="form-horizontal auth-form" #startNewAppForm="ngForm" novalidate id="startNewAppForm"
  name="startNewAppForm">
  <div class="card">
    <div class="card-header">
      <h4 class="card-title">Information</h4>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-6" *ngIf="enabledChannels.length > 0">
          <div class="form-group mb-3 row">
            <label class="col-sm-3 form-label align-self-center mb-lg-0 text-end">Channel</label>
            <div class="col-sm-9">
              <select class="form-select" required [(ngModel)]="selectedChannel" name="selectedChannel"
                (change)="handleChannelChange(selectedChannel)" id="new-app-info-channel" #channel="ngModel" [ngClass]="{
                  'is-invalid': channel && channel.touched && channel.invalid
                }">
                <option value="">-- Select One --</option>
                <option *ngFor="let channel of enabledChannels" value="{{ channel.name }}"
                  [selected]="selectedChannel == channel.name">
                  {{
                  channel.name == 'Wholesale' && isTpoUser
                  ? 'Brokered'
                  : channel.name
                  }}
                </option>
              </select>
              <div class="invalid-feedback">Please select a channel</div>
            </div>
          </div>
        </div>
      </div>
      <div class="row"
        [hidden]="selectedChannel !== 'Wholesale' && selectedChannel !== 'Correspondent' && selectedChannel !== 'NonDelegatedCorrespondent'">
        <div class="col-md-6">
          <div class="form-group mb-3 row">
            <label class="col-sm-3 form-label align-self-center mb-lg-0 text-end">Company</label>
            <div class="col-sm-9">
              <select class="form-select" [(ngModel)]="selectedCompanyId"
                (change)="loadExternalCompanyUsers(selectedCompanyId)" name="selectedCompanyId">
                <option value=""> -- Select One -- </option>
                <option *ngFor="let company of filteredExternalCompanies" value="{{company.externalCompanyId}}">
                  {{company.name}}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <ng-container *ngIf="!isTpoUser">
        <div class="row" [hidden]="
        (!selectedChannel && enabledChannels.length !== 0) || !firstRole
      ">
          <div class="col-md-6">
            <role-based-internal-contacts [selectedCompanyId]="selectedCompanyId" [selectedChannel]="selectedChannel"
              [roleUsers]="availableRoleUsers" [firstRole]="firstRole" [secondaryRoles]="avaliableSecondaryRoles"
              [isTpoUser]="isTpoUser" [loadingUsers]="loadingUsers" [primaryRoleUserId]="primaryRoleUserId"
              (primaryRoleContactChanged)="primaryRoleUserId = $event; populateLoanOptions()"
              (secondaryRoleContactChanged)="tpoAlignmentRoleUsers = $event;">
            </role-based-internal-contacts>
          </div>
        </div>
        <div class="row" *ngIf="selectedChannel">
          <div class="col-md-6">
            <div class="mb-3 row">
              <label class="col-sm-3 form-label align-self-center mb-lg-0 text-end">Loan Purpose</label>
              <div class="col-sm-9 position-relative">
                <span *ngIf="loadingLoanOptions"
                  class="spinner-border spinner-border-sm position-absolute spinner-inside-dd" role="status"
                  aria-hidden="true"></span>
                <select class="form-select" required [(ngModel)]="loanPurposeId"
                  (change)="fillLoanStatus(loanPurposeId)" #loanPurpose="ngModel" name="loanPurposeId"
                  id="new-app-info-loan-purpose" [ngClass]="{
                'is-invalid':
                  loanPurpose && loanPurpose.touched && loanPurpose.invalid
              }">
                  <option value="">-- Select One --</option>
                  <option *ngFor="let loan of availableLoanPurpose" value="{{ loan.loanPurposeId }}">
                    {{ loan.loanPurposeName }}
                  </option>
                </select>
                <div class="invalid-feedback">Please select a loan purpose</div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-3 row">
              <label class="col-sm-3 form-label align-self-center mb-lg-0 text-end">Loan Type</label>
              <div class="col-sm-9 position-relative">
                <span *ngIf="loadingLoanOptions"
                  class="spinner-border spinner-border-sm position-absolute spinner-inside-dd" role="status"
                  aria-hidden="true"></span>
                <select class="form-select" required [(ngModel)]="loanTypeId" #loanType="ngModel" name="loanTypeId"
                  [ngClass]="{
                'is-invalid': loanType && loanType.touched && loanType.invalid
              }" id="new-app-info-loan-type">
                  <option value="">-- Select One --</option>
                  <option *ngFor="let loan of availableLoanTypes" value="{{ loan.loanTypeId }}">
                    {{ loan.loanTypeName }}
                  </option>
                </select>
                <div class="invalid-feedback">Please select a loan type</div>
              </div>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="selectedChannel && selectedChannel != 'Wholesale'">
          <div class="col-md-6">
            <div class="form-group row">
              <label class="col-sm-3 form-label align-self-center mb-lg-0 text-end"
                for="new-app-info-referral-source">Referral Source</label>
              <div class="col-sm-9">
                <div class="d-flex">
                  <div class="flex-grow-1">
                    <ng-select width="100%" name="referralSource" bindLabel="displayName" bindValue="agentId"
                      placeholder="Select Referral Source" [closeOnSelect]="true" [(ngModel)]="referralSource"
                      [items]="referralSources" id="new-app-info-referral-source">
                    </ng-select>
                  </div>
                  <a class="btn btn-outline-primary" style="margin-left: 3px"
                    (click)="onAddNewReferralSourceClicked('referralSource')" id="new-app-info-add-referral-source-btn">
                    <i class="float-end fas fa-plus" style="margin-top: 4px"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group row" *ngIf="referralSource">
              <label class="col-sm-3 form-label align-self-center mb-lg-0 text-end"
                for="new-app-info-referral-source-type">Referral
                Source Type</label>
              <div class="col-sm-9">
                <div class="d-flex">
                  <div class="flex-grow-1">
                    <ng-select width="100%" name="referralSourceType" bindLabel="agentTypeName" bindValue="agentTypeId"
                      placeholder="Select Referral Source" [closeOnSelect]="true" [(ngModel)]="referralSourceType"
                      [items]="globalConfig.agentType | orderBy:'agentTypeName'" id="new-app-info-referral-source-type">
                    </ng-select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6" *ngIf="referralSource">
            <div class="form-group row">
              <label class="col-sm-3 form-label align-self-center mb-lg-0 text-end"
                for="new-app-info-secondary-referral-source">Secondary Referral Source</label>
              <div class="col-sm-9">
                <div class="d-flex">
                  <div class="flex-grow-1">
                    <ng-select width="100%" name="secondaryReferralSource" bindLabel="displayName" bindValue="agentId"
                      placeholder="Select Referral Source" [closeOnSelect]="true" [(ngModel)]="secondaryReferralSource"
                      [items]="referralSources" id="new-app-info-secondary-referral-source">
                    </ng-select>
                  </div>
                  <a class="btn btn-outline-primary" style="margin-left: 3px"
                    (click)="onAddNewReferralSourceClicked('secondaryReferralSource')"
                    id="new-app-info-secondary-referral-source-btn">
                    <i class="float-end fas fa-plus" style="margin-top: 4px"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6" *ngIf="referralSource">
            <div class="form-group row" *ngIf="secondaryReferralSource">
              <label class="col-sm-3 form-label align-self-center mb-lg-0 text-end"
                for="new-app-info-secondary-referral-source-type">Secondary Referral Source Type</label>
              <div class="col-sm-9">
                <div class="d-flex">
                  <div class="flex-grow-1">
                    <ng-select width="100%" name="secondaryReferralSourceType" bindLabel="agentTypeName"
                      bindValue="agentTypeId" placeholder="Select Referral Source" [closeOnSelect]="true"
                      [(ngModel)]="secondaryReferralSourceType"
                      [items]="globalConfig.agentType | orderBy:'agentTypeName'"
                      id="new-app-info-secondary-referral-source-type">
                    </ng-select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group row">
              <label class="col-sm-3 form-label align-self-center mb-lg-0 text-end"
                for="new-app-info-inside-referral-source">Inside Referral Source</label>
              <div class="col-sm-9">
                <ng-select width="100%" name="insideReferralSource" bindLabel="userFullName" bindValue="userCompanyGuid"
                  placeholder="Select User" [closeOnSelect]="true" [(ngModel)]="insideReferralSource"
                  [items]="availableUsers" id="new-app-info-inside-referral-source">
                </ng-select>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group row">
              <label class="col-sm-3 form-label align-self-center mb-lg-0 text-end"
                for="new-app-info-referred-to">Referred To</label>
              <div class="col-sm-9">
                <div class="d-flex">
                  <div class="flex-grow-1">
                    <ng-select width="100%" name="referredTo" bindLabel="displayName" bindValue="agentId"
                      placeholder="Select Referral Source" [closeOnSelect]="true" [(ngModel)]="referredTo"
                      [items]="referralSources" id="new-app-info-referred-to">
                    </ng-select>
                  </div>
                  <a class="btn btn-outline-primary" style="margin-left: 3px"
                    (click)="onAddNewReferralSourceClicked('referredTo')" id="new-app-info-add-referred-to-btn">
                    <i class="float-end fas fa-plus" style="margin-top: 4px"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="isTpoUser">
        <div class="row">
          <div class="col-md-6">
            <role-based-internal-contacts [selectedCompanyId]="selectedCompanyId" [selectedChannel]="selectedChannel"
              [roleUsers]="availableRoleUsers" [firstRole]="firstRole" [secondaryRoles]="avaliableSecondaryRoles"
              [isTpoUser]="isTpoUser" [loadingUsers]="loadingUsers" [primaryRoleUserId]="primaryRoleUserId"
              (primaryRoleContactChanged)="primaryRoleUserId = $event; populateLoanOptions()"
              (secondaryRoleContactChanged)="tpoAlignmentRoleUsers = $event;">
            </role-based-internal-contacts>
          </div>

          <div class="col-md-6" *ngIf="!isCompanySmartfi">
            <div class="mb-3 row">
              <label class="col-sm-3 form-label align-self-center mb-lg-0 text-end">Loan Purpose</label>
              <div class="col-sm-9 position-relative">
                <span *ngIf="loadingLoanOptions"
                  class="spinner-border spinner-border-sm position-absolute spinner-inside-dd" role="status"
                  aria-hidden="true"></span>
                <select class="form-select" required [(ngModel)]="loanPurposeId"
                  (change)="fillLoanStatus(loanPurposeId)" #loanPurpose="ngModel" name="loanPurposeId"
                  id="new-app-info-loan-purpose" [ngClass]="{
                'is-invalid':
                  loanPurpose && loanPurpose.touched && loanPurpose.invalid
              }">
                  <option value="">-- Select One --</option>
                  <option *ngFor="let loan of availableLoanPurpose" value="{{ loan.loanPurposeId }}">
                    {{ loan.loanPurposeName }}
                  </option>
                </select>
                <div class="invalid-feedback">Please select a loan purpose</div>
              </div>
            </div>
            <div class="mb-3 row">
              <label class="col-sm-3 form-label align-self-center mb-lg-0 text-end">Loan Type</label>
              <div class="col-sm-9 position-relative">
                <span *ngIf="loadingLoanOptions"
                  class="spinner-border spinner-border-sm position-absolute spinner-inside-dd" role="status"
                  aria-hidden="true"></span>
                <select class="form-select" required [(ngModel)]="loanTypeId" #loanType="ngModel" name="loanTypeId"
                  [ngClass]="{
                'is-invalid': loanType && loanType.touched && loanType.invalid
              }" id="new-app-info-loan-type">
                  <option value="">-- Select One --</option>
                  <option *ngFor="let loan of availableLoanTypes" value="{{ loan.loanTypeId }}">
                    {{ loan.loanTypeName }}
                  </option>
                </select>
                <div class="invalid-feedback">Please select a loan type</div>
              </div>
            </div>
          </div>
        </div>

      </ng-container>
    </div>
  </div>
  <div class="card">
    <div class="card-header">
      <h4 class="card-title">Borrower Information</h4>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-6">
          <div class="form-group mb-3 row">
            <label class="col-sm-3 form-label align-self-center mb-lg-0 text-end">First Name</label>
            <div class="col-sm-9">
              <input class="form-control" placeholder="First Name" id="new-app-borrower-info-first-name"
                name="borrowerfirstname" type="text" [(ngModel)]="mortgageModel.borrowers[0].firstName" required
                maxlength="40" [ngClass]="{
                  'is-invalid': first && first.touched && first.invalid
                }" #first="ngModel" />
              <div class="invalid-feedback">Please enter a first name</div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group mb-3 row">
            <label class="col-sm-3 form-label align-self-center mb-lg-0 text-end">Last Name</label>
            <div class="col-sm-9">
              <input class="form-control" placeholder="Last Name" id="new-app-borrower-info-last-name"
                name="borrowerlastname" type="text" [(ngModel)]="mortgageModel.borrowers[0].lastName" required
                maxlength="40" [ngClass]="{
                  'is-invalid': last && last.touched && last.invalid
                }" #last="ngModel" />
              <div class="invalid-feedback">Please enter a last name</div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group mb-3 row">
            <label class="col-sm-3 form-label align-self-center mb-lg-0 text-end">Email</label>
            <div class="col-sm-9">
              <input class="form-control" placeholder="Email" id="new-app-borrower-info-email" name="borrowerEmail"
                type="text" [(ngModel)]="mortgageModel.borrowers[0].primaryEmail" trim
                [email]="!!mortgageModel.borrowers[0].primaryEmail" maxlength="60" [ngClass]="{
                  'is-invalid':
                    borrowerEmail &&
                    borrowerEmail.touched &&
                    borrowerEmail.invalid
                }" #borrowerEmail="ngModel" />
              <div class="invalid-feedback">Please enter a valid email</div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="mb-3 row">
            <label class="col-sm-3 form-label align-self-center mb-lg-0 text-end">Birth Date</label>
            <div class="col-sm-9">
              <date-input name="borrowerbirthdate" [id]="'new-app-borrower-info-birth-date'" [max]="maxDate"
                [min]="minDate" [(ngModel)]="mortgageModel.borrowers[0].dateOfBirth">
              </date-input>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="mb-3 row">
            <label class="col-sm-3 form-label align-self-center mb-lg-0 text-end">Mobile Phone</label>
            <div class="col-sm-9">
              <input class="form-control" name="borrowerphone" id="new-app-borrower-info-phone" type="text"
                mask="(000) 000-0000" [(ngModel)]="mortgageModel.borrowers[0].mobilePhone" [showMaskTyped]="true" />
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="mb-3 row">
            <label class="col-sm-3 form-label align-self-center mb-lg-0 text-end">Home Phone</label>
            <div class="col-sm-9">
              <input class="form-control" name="borrowerworkphone" id="new-app-borrower-info-work-phone" type="text"
                mask="(000) 000-0000" [(ngModel)]="mortgageModel.borrowers[0].homePhone" [showMaskTyped]="true" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-header">
      <h4 class="card-title">Present Address</h4>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-6">
          <div class="mb-3 row">
            <label class="col-sm-3 form-label align-self-center mb-lg-0 text-end">Address</label>
            <div class="col-sm-9">
              <address-autocomplete-input (addressChange)='handleAddressChange($event, 0)'
                [(ngModel)]='mortgageModel.borrowers[0].residencyAddresses[0].address.address1'
                id="new-app-borrower-address-address" name='address'
                placeholder='Number and Street'></address-autocomplete-input>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="mb-3 row">
            <label class="col-sm-3 form-label align-self-center mb-lg-0 text-end">Address 2</label>
            <div class="col-sm-9">
              <input class="form-control" id="new-app-borrower-address-address2" name="address2" type="text"
                [(ngModel)]="
                  mortgageModel.borrowers[0].residencyAddresses[0].address
                    .address2
                " />
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="mb-3 row">
            <label class="col-sm-3 form-label align-self-center mb-lg-0 text-end">City</label>
            <div class="col-sm-9">
              <input class="form-control" placeholder="City" id="new-app-borrower-address-city" name="city" type="text"
                [(ngModel)]="
                  mortgageModel.borrowers[0].residencyAddresses[0].address.city
                " />
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="mb-3 row">
            <label class="col-sm-3 form-label align-self-center mb-lg-0 text-end">State</label>
            <div class="col-sm-9">
              <select class="form-select" name="state" [(ngModel)]="
                  mortgageModel.borrowers[0].residencyAddresses[0].address.state
                " id="new-app-borrower-address-state">
                <option value="">-- Select One --</option>
                <option *ngFor="let item of globalConfig.states | keyvalue" value="{{ item.key }}">
                  {{ item.value }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="mb-3 row">
            <label class="col-sm-3 form-label align-self-center mb-lg-0 text-end">Zip Code</label>
            <div class="col-sm-9">
              <input class="form-control" placeholder="XXXXX-XXXX" [validation]="false" [mask]="'XXXXX-XXXX'"
                id="zipCode" name="zipCode" type="text" [(ngModel)]="
                  mortgageModel.borrowers[0].residencyAddresses[0].address
                    .zipCode
                " id="new-app-borrower-address-zipcode" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-header">
      <h4 class="card-title d-flex align-items-center justify-content-between">Subject Property Address
        <span style="font-size: 0.8em; text-transform: initial">
          <a class="link" id="new-app-copy-present-address" (click)="copyFromPresentAddress()">Copy from Present</a>
        </span>
      </h4>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-6">
          <div class="mb-3 row">
            <label class="col-sm-3 form-label align-self-center mb-lg-0 text-end">Address</label>
            <div class="col-sm-9">
              <address-autocomplete-input (addressChange)='handleSubjectPropertyAddressChange($event)'
                [(ngModel)]='mortgageModel.subjectProperty.address1'
                id="new-app-subject-property-address-address" name='subject-property-address-address'
                placeholder='Number and Street'></address-autocomplete-input>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="mb-3 row">
            <label class="col-sm-3 form-label align-self-center mb-lg-0 text-end">Address 2</label>
            <div class="col-sm-9">
              <input class="form-control" id="new-app-subject-property-address-address2" name="subject-property-address-address2" type="text"
                [(ngModel)]="mortgageModel.subjectProperty.address2" />
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="mb-3 row">
            <label class="col-sm-3 form-label align-self-center mb-lg-0 text-end">City</label>
            <div class="col-sm-9">
              <input class="form-control" placeholder="City" id="new-app-subject-property-address-city" name="subject-property-address-city" type="text"
                [(ngModel)]="mortgageModel.subjectProperty.city" />
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="mb-3 row">
            <label class="col-sm-3 form-label align-self-center mb-lg-0 text-end">State</label>
            <div class="col-sm-9">
              <select class="form-select" name="subject-property-address-state" [(ngModel)]="mortgageModel.subjectProperty.state"
                 id="new-app-subject-property-address-state">
                <option value="">-- Select One --</option>
                <option *ngFor="let item of globalConfig.states | keyvalue" value="{{ item.key }}">
                  {{ item.value }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="mb-3 row">
            <label class="col-sm-3 form-label align-self-center mb-lg-0 text-end">Zip Code</label>
            <div class="col-sm-9">
              <input class="form-control" placeholder="XXXXX-XXXX" [validation]="false" [mask]="'XXXXX-XXXX'"
                name="subject-property-address-zipCode" type="text" [(ngModel)]="mortgageModel.subjectProperty.zipCode"
                id="new-app-subject-property-address-zipcode" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!enabledCoBorrower" class="mb-3">
    <a id="new-app-add-co-borrower-btn" href="javascript: void(0);" type="button" class="btn btn-outline-primary"
      (click)="addCoBorr()">+ Add a
      CoBorrower</a>
  </div>
  <div *ngIf="enabledCoBorrower" class="mb-3" (click)="removeCoBorr()">
    <a id="new-app-remove-co-borrower-btn" href="javascript: void(0);" type="button" class="btn btn-outline-danger">-
      Remove CoBorrower</a>
  </div>
  <div class="card" *ngIf="
      enabledCoBorrower &&
      mortgageModel.borrowers &&
      mortgageModel.borrowers.length > 1
    ">
    <div class="card-header">
      <h4 class="card-title">CoBorrower Information</h4>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-6">
          <div class="form-group mb-3 row">
            <label class="col-sm-3 form-label align-self-center mb-lg-0 text-end">First Name</label>
            <div class="col-sm-9">
              <input class="form-control" placeholder="First Name" id="new-app-coborrower-info-first-name"
                name="coborrowerfirstname" type="text" [(ngModel)]="mortgageModel.borrowers[1].firstName" required
                maxlength="40" [ngClass]="{
                  'is-invalid': coFirst && coFirst.touched && coFirst.invalid
                }" #coFirst="ngModel" />
              <div class="invalid-feedback">Please enter a first name</div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group mb-3 row">
            <label class="col-sm-3 form-label align-self-center mb-lg-0 text-end">Last Name</label>
            <div class="col-sm-9">
              <input class="form-control" placeholder="Last Name" id="new-app-coborrower-info-last-name"
                name="coborrowerlastname" type="text" [(ngModel)]="mortgageModel.borrowers[1].lastName" required
                maxlength="40" [ngClass]="{
                  'is-invalid': coLast && coLast.touched && coLast.invalid
                }" #coLast="ngModel" />
              <div class="invalid-feedback">Please enter a last name</div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group mb-3 row">
            <label class="col-sm-3 form-label align-self-center mb-lg-0 text-end">Email</label>
            <div class="col-sm-9">
              <input class="form-control" placeholder="Email" id="new-app-coborrower-info-email" name="coBorrowerEmail"
                type="text" [(ngModel)]="mortgageModel.borrowers[1].primaryEmail" trim
                [email]="!!mortgageModel.borrowers[0].primaryEmail" maxlength="60" [ngClass]="{
                  'is-invalid':
                    coBorrowerEmail &&
                    coBorrowerEmail.touched &&
                    coBorrowerEmail.invalid
                }" #coBorrowerEmail="ngModel" />
              <div class="invalid-feedback">Please enter a valid email</div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="mb-3 row">
            <label class="col-sm-3 form-label align-self-center mb-lg-0 text-end">Birth Date</label>
            <div class="col-sm-9">
              <date-input name="coborrowerbirthdate" [id]="'new-app-coborrower-info-birth-date'" [max]="maxDate"
                [min]="minDate" [(ngModel)]="mortgageModel.borrowers[1].dateOfBirth">
              </date-input>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="mb-3 row">
            <label class="col-sm-3 form-label align-self-center mb-lg-0 text-end">Phone</label>
            <div class="col-sm-9">
              <input class="form-control" name="coborrowerphone" id="new-app-coborrower-info-phone"
                [showMaskTyped]="true" type="text" mask="(000) 000-0000"
                [(ngModel)]="mortgageModel.borrowers[1].mobilePhone" />
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="mb-3 row">
            <label class="col-sm-3 form-label align-self-center mb-lg-0 text-end">Work Phone</label>
            <div class="col-sm-9">
              <input class="form-control" name="coborrowerworkphone" id="new-app-coborrower-info-work-phone"
                [showMaskTyped]="true" type="text" mask="(000) 000-0000"
                [(ngModel)]="mortgageModel.borrowers[1].workPhone" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card" *ngIf="enabledCoBorrower">
    <div class="card-header">
      <h4 class="card-title">
        CoBorrower Address
        <span class="ms-1" style="font-size: 0.8em; text-transform: initial">
          <a class="link" id="new-app-copy-borrower-address" (click)="copyBorrowerAddress()">Copy from Borrower</a>
        </span>
      </h4>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-6">
          <div class="mb-3 row">
            <label class="col-sm-3 form-label align-self-center mb-lg-0 text-end">Address</label>
            <div class="col-sm-9">
              <address-autocomplete-input (addressChange)='handleAddressChange($event, 1)'
                [(ngModel)]='mortgageModel.borrowers[1].residencyAddresses[0].address.address1'
                id='new-app-coborrower-address-address' name='coaddress'
                placeholder='Number and Street'></address-autocomplete-input>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="mb-3 row">
            <label class="col-sm-3 form-label align-self-center mb-lg-0 text-end">Address 2</label>
            <div class="col-sm-9">
              <input class="form-control" id="new-app-coborrower-address-address2" name="coaddress2" type="text"
                [(ngModel)]="
                  mortgageModel.borrowers[1].residencyAddresses[0].address
                    .address2
                " />
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="mb-3 row">
            <label class="col-sm-3 form-label align-self-center mb-lg-0 text-end">City</label>
            <div class="col-sm-9">
              <input class="form-control" placeholder="City" id="new-app-coborrower-address-city" name="cocity"
                type="text" [(ngModel)]="
                  mortgageModel.borrowers[1].residencyAddresses[0].address.city
                " />
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="mb-3 row">
            <label class="col-sm-3 form-label align-self-center mb-lg-0 text-end">State</label>
            <div class="col-sm-9">
              <select class="form-select" name="coState" id="new-app-coborrower-address-state"
                [(ngModel)]="mortgageModel.borrowers[1].residencyAddresses[0].address.state">
                <option value="">-- Select One --</option>
                <option *ngFor="let item of globalConfig.states | keyvalue" value="{{ item.key }}">
                  {{ item.value }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="mb-3 row">
            <label class="col-sm-3 form-label align-self-center mb-lg-0 text-end">Zip Code</label>
            <div class="col-sm-9">
              <input class="form-control" [mask]="'XXXXX-XXXX'" [validation]="false" placeholder="XXXXX-XXXX"
                id="new-app-coborrower-address-zipcode" name="cozipCode" type="text" [(ngModel)]="
                  mortgageModel.borrowers[1].residencyAddresses[0].address
                    .zipCode
                " />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
<div class="text-center">
  <button id="new-app-upload-file-proceed-btn" class="btn btn-primary" (click)="proceed()">
    Proceed To Review Data
  </button>
</div>