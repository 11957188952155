import { Component, ElementRef, Injectable, Input, ViewChild } from '@angular/core';
import { NgModel } from '@angular/forms';
import { NgbDateAdapter, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { MakeProvider } from 'src/app/core/abstract-value-accessor';
import { BaseUrlaInputComponent } from '../base-urla-input.component';
import { DateTime } from 'luxon';
import {getDaysForMonth} from 'src/utils';
import { Constants } from 'src/app/services/constants';
import { formViewProvider } from 'src/app/core/services/form-view.provider';
import { v4 as uuidv4 } from 'uuid';

Injectable()
export class CustomAdapter extends NgbDateAdapter<string> {
  readonly DELIMITER = '/';

  fromModel(value: string | null): NgbDateStruct | null {
    if (value) {
      let date = value.split(this.DELIMITER);
      return {
        month: parseInt(date[0], 10),
        day: parseInt(date[1], 10),
        year: parseInt(date[2], 10),
      };
    }
    return null;
  }

  toModel(date: NgbDateStruct | null): string | null {
    return date
      ? ((date.month < 10) ? "0" + date.month : date.month) + this.DELIMITER + ((date.day < 10) ? "0" + date.day : date.day) + this.DELIMITER + date.year
      : null;
  }
}

/**
 * This Service handles how the date is rendered and parsed from keyboard i.e. in the bound input field.
 */
@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
  readonly DELIMITER = '/';

  parse(value: string): NgbDateStruct | null {
    if (value) {
      let date = value.split(this.DELIMITER);
      return {
        month: parseInt(date[0], 10),
        day: parseInt(date[1], 10),
        year: parseInt(date[2], 10),
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date
      ? ((date.month < 10) ? "0" + date.month : date.month) + this.DELIMITER + ((date.day < 10) ? "0" + date.day : date.day) + this.DELIMITER + date.year
      : '';
  }
}

@Component({
  selector: 'urla-date-input',
  templateUrl: 'urla-date-input.component.html',
  providers: [MakeProvider(UrlaDateInputComponent),
  { provide: NgbDateAdapter, useClass: CustomAdapter },
  { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }],
  viewProviders: [formViewProvider]
})
export class UrlaDateInputComponent extends BaseUrlaInputComponent {
  @Input() maxDate;
  @Input() minDate;
  @ViewChild("model") model: NgModel;
  @ViewChild("dateInput") dateInput: ElementRef;

  date: NgbDateStruct;

  errorPreviewNotClickedYet: boolean = true;
  initialLoad: boolean = true;

  datePattern = Constants.regexPatterns.date;

  nameSuffix: string;

  constructor(private dateAdapter: NgbDateAdapter<string>) {
    super();
    this.nameSuffix = uuidv4();
  }

  writeValue(value: any) {
    this._value = value;
    if (this._value) {
      let splitDate = this._value.split('/');// Convert it into the datstruct
      this.date = {
        month: parseInt(splitDate[0], 10),
        day: parseInt(splitDate[1], 10),
        year: parseInt(splitDate[2], 10),
      };
    }
    // warning: comment below if only want to emit on user intervention
    this.onChange(value);
  }

  onDateChange = () => {
    this.initialLoad = false;
    if (typeof this.date === 'string' || this.date instanceof String) {
      // Not able to parse properly, must be incomplete
      return;
    }
    const value = this.date ? ((this.date.month < 10) ? "0" + this.date.month : this.date.month) + '/' +
      ((this.date.day < 10) ? "0" + this.date.day : this.date.day) + '/' + this.date.year : '';
    this.writeValue(value);
  }

  onErrorPreviewClicked = () => {
    this.errorPreviewNotClickedYet = false;
    if (this.value != undefined) {
      this.dateInput.nativeElement.value = this.value;
    }
    
    setTimeout(() => {
      this.dateInput.nativeElement.focus();
    });
  }

  onBlurred = (event: any) => {
    this.checkIsInvalid(event.target?.value, true, true);
    this.blur.emit(event);
  }

  applyDateMask = (event) => {
    this.initialLoad = false;
    if (event.key === '/') {
      return;
    }
    // var dateString = event.target.value.replace(/\D/g, '').slice(0, 10);
    // if (dateString.length >= 5) {
    //   const newVal = `${dateString.slice(0, 2)}/${dateString.slice(2, 4)}/${dateString.slice(4)}`;
    //   $('#' + this.id).val(newVal);
    //   return;
    // }
    // if (dateString.length >= 3) {
    //   const newVal = `${dateString.slice(0, 2)}/${dateString.slice(2)}`;
    //   $('#' + this.id).val(newVal);
    //   return;
    // }

    
    let invalidDate = false;
    const dateStr = event.target.value?.replace(/\D/g, '').slice(0, 10) || '';

    let result = '';

    if (dateStr.length >= 5) {
      result = `${dateStr.slice(0, 2)}/${dateStr.slice(2, 4)}/${dateStr.slice(
        4,
        8
      )}`;

      this.checkIsInvalid(result);

      return this.changeValue(result);
    }
    if (dateStr.length >= 3) {
      result = `${dateStr.slice(0, 2)}/${dateStr.slice(2, 4)}`;
      invalidDate = this.checkIsInvalid(result);
      return this.changeValue(result);
    }
    if (dateStr.length > 0) {
      result = `${dateStr.slice(0, 2)}`;
      invalidDate = this.checkIsInvalid(result);
      return this.changeValue(result);
    }
    this.checkIsInvalid(result);
    return this.changeValue(result);
  };

  private checkIsInvalid = (value, markPartialAsInvalid: boolean = true, setFormErrors: boolean = true): boolean => {
    let invalidDate = false;
    if (!value) {
      value = "";

      if (this.required) {
        this.model.control.setErrors(null);
        this.model.control.setErrors({'required': true});
        return true;
      }

      if (setFormErrors) {
        this.model.control.setErrors(null);
      }
      return false;
    }

    if (value.length === 10) {
      invalidDate = this.validateFullDate(value);
      if (invalidDate) {
        if (setFormErrors) {
          this.model.control.setErrors({'invalid': true});
        }
        return true;
      }
      invalidDate = this.validateDay(value);
      if (invalidDate) {
        if (setFormErrors) {
          this.model.control.setErrors({'invalid': true});
        }
        return true;
      }
      invalidDate = this.validateMonth(value);
      if (invalidDate) {
        if (setFormErrors) {
          this.model.control.setErrors({'invalid': true});
        }
        return true;
      }
      // validate min and max only if date is full
      invalidDate = this.validateMax(value);
      if (invalidDate) {
        if (setFormErrors) {
          this.model.control.setErrors({ 'max': true });
        }
        return true;
      }
      invalidDate = this.validateMin(value);
      if (invalidDate) {
        if (setFormErrors) {
          this.model.control.setErrors({ 'min': true });
        }
        return true;
      }
    } else {
      if (markPartialAsInvalid) {
        if (setFormErrors) {
          this.model.control.setErrors({ 'invalid': true});
        }
        return true;
      }
    }

    if (setFormErrors) {
      this.model.control.setErrors(null);
    }
    return false;
  }

  private changeValue(value) {
    const input = document.querySelector(`#${this.id}`) as HTMLInputElement;
    input.value = value;
    this.onChange(value);
  }

  private validateDay = (value): boolean => {
    const invalidDate = value.split('/')[1] > 31;
    return invalidDate;
  }

  private validateMonth = (value): boolean => {
    const invalidDate = value.split('/')[0] > 12;
    return invalidDate;
  }

  private validateMax(value) {
    const date = DateTime.fromJSDate(new Date(value));
    const maxDate = this.datePattern.test(this.maxDate as any) ? DateTime.fromJSDate(new Date(this.maxDate)) : DateTime.fromJSDate(this.maxDate);
    const invalidDate = date > maxDate;
    return invalidDate;
  }

  private validateMin(value) {
    const date = DateTime.fromJSDate(new Date(value));
    const minDate = this.datePattern.test(this.minDate as any) ? DateTime.fromJSDate(new Date(this.minDate)) : DateTime.fromJSDate(this.minDate);
    const invalidDate = date < minDate;
    return invalidDate;
  }

  private validateFullDate = (value): boolean => {
    const invalidDate = this.isValidAsFullDate(value);
    return invalidDate;
  }

  private isValidAsFullDate(value) {
    const maxDays = getDaysForMonth(value.split('/')[0], value.split('/')[2]);
    const areDaysValid = value.split('/')[1] <= maxDays;
    return !this.datePattern.test(value) || !areDaysValid;
  }

}
