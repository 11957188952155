import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { EnvironmentService } from 'src/app/core/services/environment/environment.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { ApplicationContext, Borrower, ContactListType, LoanStatus, SmsChatRequestedModel, UserProfile } from 'src/app/models';
import { AlertModel } from 'src/app/models/alert.model';
import { LoanDocTask } from 'src/app/models/loan/loan-doc-task.model';
import { MentionMessageScope } from 'src/app/models/mention.model';
import { ApplicationContextService } from 'src/app/services/application-context.service';
import { FirebaseService } from 'src/app/services/firebase';
import { NotificationService } from 'src/app/services/notification.service';
import { SignalRService } from 'src/app/services/signalr.service';
import { TaskService } from 'src/app/services/task.service';
import { MentionViewModel } from 'src/app/shared/components/mentions/mention-item/mention-item.component';
import { DrawerOptions, DrawerService, DrawerSize, DynamicComponentInfo } from 'src/app/shared/services/drawer.service';
import { Notifications } from './header/header-actions/header-actions.component';
import { phoneToPlainString, removeUsPhonePrefix, urlify } from 'src/utils';
import { Utils } from 'src/app/core/services/utils';
import { Lead } from 'src/app/modules/leads/models/lead.model';
import { DrawerComponent } from 'src/app/shared/components/drawer/drawer.component';
import { Agent } from 'src/app/modules/app-details/models/agent.model';
import { Subscription } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { DashboardV2Service } from 'src/app/modules/dashboardv2/services/dashboardv2.service';
import { ConversationService } from 'src/app/modules/conversations/services/conversation.service';
import { ChatMedium, Conversation, ConversationParticipant, MessageDirection, ParticipantType } from 'src/app/modules/conversations/models/conversation.model';
import { GlobalConfig } from 'src/app/models/config/global-config.model';
import { ViewLeadDrawerComponent } from 'src/app/modules/leads/components/dialogs/view-lead-drawer/view-lead-drawer.component';
import * as _ from 'lodash';
import { ColorUtils } from 'src/app/shared/utils/color/utils';
import { v4 as uuidv4 } from 'uuid';
import Swal from 'sweetalert2';

@Component({
  selector: 'layout-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
})
export class LayoutAdminComponent implements OnInit, OnDestroy {
  @ViewChild('editLeadDrawer')
  editLeadDrawer: DrawerComponent;

  @ViewChild('editAgentDrawer')
  editAgentDrawer: DrawerComponent;

  @ViewChild('editContactDrawer')
  editContactDrawer: DrawerComponent;

  @ViewChild('mentionsDrawer')
  mentionsDrawer: DrawerComponent;

  user = {};
  userId: string = '';
  conversationsUserId: string = '';
  loanStatus: LoanStatus[] = [];
  avatarUrl: string;
  userProfile: UserProfile;

  unreadMentionCount: number = 0;
  mentionApplicationId: number | null = null;
  mentionLoanDocTask: LoanDocTask | null = null;

  notifications: Notifications = null;

  numberOfLoadedEmails: number = 0;

  mentionMessageScope: MentionMessageScope;

  messageId: number | null = null;

  insideLoanContext: boolean = false;

  taskMentionTrackingUrl: string;

  currentUserPhoneNumber: string;

  showLoanSummary: boolean;

  allConversations: Conversation[] = [];

  updatingConversation: boolean;

  refreshMentions: boolean;

  globalConfig: GlobalConfig;

  mentionTrackingGuid: string;

  agentIdToEdit: number | null = null;
  borrowerIdToEdit: number | null = null;
  niceInContactVisible: boolean = false;
  dialpadVisible: boolean = false;
  currentTab: string = 'ring-central';

  conversationTargetPersonContext: ConversationTargetPersonContext;

  protected faviconUrl = '/assets/images/favicon_loda.ico';

  private _mentionsDrawerOpened: boolean = false;
  private _notificationsDrawerOpened: boolean = false;
  private _conversationsDrawerOpened: boolean = false;
  private _newLeadDrawerOpened: boolean = false;
  private _editLeadDrawerOpened: boolean = false;
  private _editAgentDrawerOpened: boolean = false;
  private _editContactDrawerOpened: boolean = false;
  private _newAgentDrawerOpened: boolean = false;
  private _newContactDrawerOpened: boolean = false;
  private _newApplicationDrawerOpened: boolean = false;

  private _contextChangesForLoaninfoSubscription: Subscription;
  private _contextChangesSubscription: Subscription;
  private _contextSubscription: Subscription;
  private _conversationUpdatedSubscription: Subscription;
  private _borrowerChatRequestedSubscription: Subscription;
  private _internalChatRequestedSubscription: Subscription;
  private _routerEventsSubscription: Subscription;
  private _smsChatRequestedSubscription: Subscription;
  private _conversationDeletedSubscription: Subscription;
  private _openDrawersSubscription: Subscription;

  private _currentConversationPageNumber = 1;

  private _conversationAutoRefreshInterval: any = null;

  mentionsDrawerOptions: DrawerOptions = {
    size: DrawerSize.Medium,
    containerWrapperId: 'drawer-wrapper',
  };

  alertsDrawerOptions: DrawerOptions = {
    size: DrawerSize.Medium,
    containerWrapperId: 'drawer-wrapper',
  };

  loanActivityDrawerOptions: DrawerOptions = {
    size: DrawerSize.Medium,
    containerWrapperId: 'drawer-wrapper',
  };

  taskEditorDrawerOptions: DrawerOptions = {
    size: DrawerSize.XXLarge,
    containerWrapperId: null,
  };

  conversationsDrawerOptions: DrawerOptions = {
    size: DrawerSize.Custom,
    containerWrapperId: null,
    width: 1400,
  };

  newLeadDrawerOptions: DrawerOptions = {
    size: DrawerSize.XXLarge,
    containerWrapperId: null,
  };

  editLeadDrawerOptions: DrawerOptions = {
    size: DrawerSize.XXXLarge,
    containerWrapperId: null,
  };

  newAgentDrawerOptions: DrawerOptions = {
    size: DrawerSize.XXLarge,
    containerWrapperId: null,
  };

  editAgentDrawerOptions: DrawerOptions = {
    size: DrawerSize.XXXLarge,
    containerWrapperId: null,
  };

  newContactDrawerOptions: DrawerOptions = {
    size: DrawerSize.XXLarge,
    containerWrapperId: null,
  };

  editContactDrawerOptions: DrawerOptions = {
    size: DrawerSize.XXXLarge,
    containerWrapperId: null,
  };

  newApplicationDrawerOptions: DrawerOptions = {
    size: DrawerSize.XXLarge,
    containerWrapperId: null,
  };

  genericDrawerOptions: DrawerOptions = {
    size: DrawerSize.Medium,
    containerWrapperId: 'drawer-wrapper',
  };

  protected alertBorrowerId: number | undefined;
  protected alertAgentId: number | undefined;

  constructor(
    private readonly _applicationContextService: ApplicationContextService,
    private readonly _signalRService: SignalRService,
    private readonly _firebaseService: FirebaseService,
    private readonly _taskService: TaskService,
    private readonly _drawerService: DrawerService,
    private readonly _router: Router,
    private readonly _environment: EnvironmentService,
    private readonly _conversationService: ConversationService,
    private readonly _localStorageService: LocalStorageService,
    private readonly _notifyService: NotificationService,
    private readonly _spinnerService: NgxSpinnerService,
    private readonly _dashboardService: DashboardV2Service,
  ) {
    this._openDrawersSubscription = this._drawerService.openDrawer$.subscribe(
      (info: { contactListType: ContactListType; recordId: number }) => {
        if (info.contactListType === ContactListType.Lead) {
          var lead = new Lead();
          lead.leadId = info.recordId;
          this.onEditRequestedForLeadId(lead);
        }
      }
    );

    this.faviconUrl = this._environment.faviconUrl;
  }

  ngOnInit() {
    this.subscribeToEvents();

    this.getConversations();

    this.showLoanSummary = true;
  }

  ngOnDestroy(): void {
    if (this._routerEventsSubscription) {
      this._routerEventsSubscription.unsubscribe();
    }
    if (this._contextSubscription) {
      this._contextSubscription.unsubscribe();
    }
    if (this._contextChangesForLoaninfoSubscription) {
      this._contextChangesForLoaninfoSubscription.unsubscribe();
    }
    if (this._contextChangesSubscription) {
      this._contextChangesSubscription.unsubscribe();
    }
    if (this._borrowerChatRequestedSubscription) {
      this._borrowerChatRequestedSubscription.unsubscribe();
    }
    if (this._internalChatRequestedSubscription) {
      this._internalChatRequestedSubscription.unsubscribe();
    }
    if (this._smsChatRequestedSubscription) {
      this._smsChatRequestedSubscription.unsubscribe();
    }
    if (this._conversationDeletedSubscription) {
      this._conversationDeletedSubscription.unsubscribe();
    }
    if (this._openDrawersSubscription) {
      this._openDrawersSubscription.unsubscribe();
    }
    if (this._conversationAutoRefreshInterval) {
      clearInterval(this._conversationAutoRefreshInterval);
    }
    this._conversationUpdatedSubscription?.unsubscribe();
  }

  onMentionClicked = (mention: MentionViewModel) => {
    this.mentionMessageScope = mention.messageScope;
    this.messageId = mention.messageId;
    this.refreshMentions = !mention.openDate; // reload mentions if unread

    if (this.mentionMessageScope === MentionMessageScope.InternalMessage) {
      this.mentionApplicationId = mention.entityId;
      if (mention.trackingGuid) {
        this.mentionTrackingGuid = mention.trackingGuid;
      }
      this._drawerService.hide('taskEditorDrawer', 10);
      this._drawerService.show('loanActivityInternalMessageDrawer', 10);
    } else if (this.mentionMessageScope === MentionMessageScope.TaskNote) {
      this._taskService.getTaskDashboardViewById(mention.entityId).subscribe(
        (task) => {
          this.mentionLoanDocTask = task;
          if (mention.trackingGuid) {
            this.taskMentionTrackingUrl = `${this._environment.apiInfo.apiBaseUrl}mentions/pixel/${this.userId}/${mention.trackingGuid}`;
          }
          this._drawerService.hide('loanActivityInternalMessageDrawer', 10);
          this._drawerService.show('taskEditorDrawer', 10);
        },
        (error) => {
          this._notifyService.showError(error.error, 'Error!');
        }
      );
    }
  };

  onAlertsCreated = (alerts: AlertModel[]) => {
    alerts.forEach((a) => {
      const bucketThatAlertNeedsToGoTo = this.notifications?.alertBuckets?.find(
        (b) => b.type == a.alertType
      );
      if (bucketThatAlertNeedsToGoTo) {
        bucketThatAlertNeedsToGoTo.alerts.splice(0, 0, a);
      }
      const updatedNotifications = { ...this.notifications };
      this.notifications = updatedNotifications as Notifications;
    });
  };

  onNotificationsToggled = (notifications: Notifications) => {
    this.notifications = notifications;
    if (this._mentionsDrawerOpened) {
      this._drawerService.hide('mentionsDrawer', 10);
    }
    if (this._conversationsDrawerOpened) {
      this._drawerService.hide('conversationsDrawer', 10);
    }
    if (notifications && !this._notificationsDrawerOpened) {
      this._drawerService.show('alertsDrawer', 10);
      this._notificationsDrawerOpened = true;
    } else {
      this._drawerService.hide('alertsDrawer', 10);
    }
  };

  onMentionsStreamToggled = (open: boolean) => {
    if (this._notificationsDrawerOpened) {
      this._drawerService.hide('alertsDrawer', 10);
    }
    if (this._conversationsDrawerOpened) {
      this._drawerService.hide('conversationsDrawer', 10);
    }
    if (open && !this._mentionsDrawerOpened) {
      this._drawerService.show('mentionsDrawer', 10);
      this._mentionsDrawerOpened = true;
    } else {
      this._drawerService.hide('mentionsDrawer', 10);
    }
  };

  onDialpadToggled = () => {
    this.dialpadVisible = !this.dialpadVisible;
    this._applicationContextService.niceInContactVisibilityChanged(
      this.dialpadVisible
    );
    if (this.dialpadVisible) {
      this.niceInContactVisible = false;
    }
  };

  onNiceInContactToggled = (activeTab: string) => {
    if (this.currentTab == activeTab) {
      this.niceInContactVisible = !this.niceInContactVisible;
    } else {
      this.currentTab = activeTab;
      if (!this.niceInContactVisible) {
        this.niceInContactVisible = !this.niceInContactVisible;
      }
    }
    this._applicationContextService.niceInContactVisibilityChanged(
      this.niceInContactVisible
    );
    if (this.niceInContactVisible) {
      this.dialpadVisible = false;
    }
  };

  onConversationsToggled = (open: boolean) => {
    open = true;
    if (this._notificationsDrawerOpened) {
      this._drawerService.hide('alertsDrawer', 10);
    }
    if (this._mentionsDrawerOpened) {
      this._drawerService.hide('mentionsDrawer', 10);
    }
    if (open && !this._conversationsDrawerOpened) {
      this._openConversationDrawer();
    } else {
      this._drawerService.hide('conversationsDrawer', 10);
      this._conversationsDrawerOpened = false;
    }
  };

  onNewLeadToggled = () => {
    if (this._newContactDrawerOpened) {
      this._drawerService.hide('newContactDrawer', 10);
      this._newContactDrawerOpened = false;
    }
    if (this._newAgentDrawerOpened) {
      this._drawerService.hide('newAgentDrawer', 10);
      this._newAgentDrawerOpened = false;
    }
    if (this._newApplicationDrawerOpened) {
      this._drawerService.hide('newApplicationDrawer', 10);
      this._newApplicationDrawerOpened = false;
    }
    this._newLeadDrawerOpened = true;
    this._drawerService.show('newLeadDrawer', 10);
  };

  onNewAgentToggled = () => {
    if (this._newLeadDrawerOpened) {
      this._drawerService.hide('newLeadDrawer', 10);
      this._newLeadDrawerOpened = false;
    }
    if (this._newContactDrawerOpened) {
      this._drawerService.hide('newContactDrawer', 10);
      this._newContactDrawerOpened = false;
    }
    if (this._newApplicationDrawerOpened) {
      this._drawerService.hide('newApplicationDrawer', 10);
      this._newApplicationDrawerOpened = false;
    }
    this._newAgentDrawerOpened = true;
    this._drawerService.show('newAgentDrawer', 10);
  };

  onNewContactToggled = () => {
    if (this._newLeadDrawerOpened) {
      this._drawerService.hide('newLeadDrawer', 10);
      this._newLeadDrawerOpened = false;
    }
    if (this._newAgentDrawerOpened) {
      this._drawerService.hide('newAgentDrawer', 10);
      this._newAgentDrawerOpened = false;
    }
    if (this._newApplicationDrawerOpened) {
      this._drawerService.hide('newApplicationDrawer', 10);
      this._newApplicationDrawerOpened = false;
    }
    this._newContactDrawerOpened = true;
    this._drawerService.show('newContactDrawer', 10);
  };

  onNewApplicationToggled = () => {
    if (this._newLeadDrawerOpened) {
      this._drawerService.hide('newLeadDrawer', 10);
      this._newLeadDrawerOpened = false;
    }
    if (this._newAgentDrawerOpened) {
      this._drawerService.hide('newAgentDrawer', 10);
      this._newAgentDrawerOpened = false;
    }
    if (this._newContactDrawerOpened) {
      this._drawerService.hide('newContactDrawer', 10);
      this._newContactDrawerOpened = false;
    }
    this._newApplicationDrawerOpened = true;
    this._drawerService.show('newApplicationDrawer', 10);
  };

  checkUnreadConversations = () => {
    var unread = this.allConversations.filter(
      (c) => c.isUnread == true && !c.isHidden
    );
    return unread.length > 0;
  };

  onNotificationsDrawerClosed = () => {
    this._notificationsDrawerOpened = false;
  };

  onMentionsDrawerClosed = () => {
    this._mentionsDrawerOpened = false;
  };

  onMentionDetailsDrawerClosed = () => {};

  onConversationsDrawerClosed = () => {
    this.alertBorrowerId = undefined;
    this.alertAgentId = undefined;
    this._conversationsDrawerOpened = false;
    //load the current user conversations if impersonate user conversation are loaded.
    if (this.conversationsUserId !== this.userProfile.userCompanyGuid) {
      this.onImpersonatedUserChanged(this.userId);
    }
  };

  onNewLeadDrawerClosed = () => {
    this._newLeadDrawerOpened = false;
  };

  onNewLeadDrawerClose = (lead?: Lead) => {
    this._drawerService.hide('newLeadDrawer', 10);
    this._newLeadDrawerOpened = false;
    if (lead?.leadId > 0) {
      this.onEditRequestedForLead(lead);
    }
  };

  onNewLeadCancelled = () => {
    this._drawerService.hide('newLeadDrawer', 10);
  };

  onEditLeadDrawerClosed = () => {
    this._editLeadDrawerOpened = false;
    this._dashboardService.leadEditingEnded();
  };

  onEditLeadDrawerOpened = () => {
    this._dashboardService.leadOpenedForEdit();
  };

  onEditRequestedForLead = (lead: Lead) => {
    const dynamicComponentInfo = new DynamicComponentInfo();
    dynamicComponentInfo.componentType = ViewLeadDrawerComponent;
    dynamicComponentInfo.parameters.set('lead', lead);

    this._drawerService.show(
      'editLeadDrawer',
      10,
      'Editing Lead - ' + Utils.getPersonsDisplayName(lead),
      dynamicComponentInfo
    );
    this._spinnerService.hide();
  };

  onEditRequestedForLeadId = (lead: Lead) => {
    const dynamicComponentInfo = new DynamicComponentInfo();
    dynamicComponentInfo.componentType = ViewLeadDrawerComponent;
    dynamicComponentInfo.parameters.set('leadId', lead.leadId);

    this._drawerService.show(
      'editLeadDrawer',
      10,
      'Editing Lead',
      dynamicComponentInfo
    );
    this._spinnerService.hide();
  };

  onNewAgentDrawerClosed = () => {
    this._newAgentDrawerOpened = false;
  };

  onNewAgentDrawerClose = (agent?: Agent) => {
    this._drawerService.hide('newAgentDrawer', 10);
    this._newAgentDrawerOpened = false;
    if (agent?.agentId > 0) {
      this.onEditRequestedForAgent(agent);
    }
  };

  onEditAgentDrawerClosed = () => {
    this._editAgentDrawerOpened = false;
  };

  onEditAgentDrawerClose = () => {
    this._drawerService.hide('editAgentDrawer', 10);
    this._editAgentDrawerOpened = false;
  };

  onEditRequestedForAgent = (agent: Agent) => {
    this.agentIdToEdit = agent.agentId;
    this.editAgentDrawer.title =
      'Editing Agent - ' + Utils.getPersonsDisplayName(agent);

    setTimeout(() => {
      this._drawerService.show('editAgentDrawer', 10);
    }, 100);
  };

  onNewContactDrawerClosed = () => {
    this._newContactDrawerOpened = false;
  };

  onNewContactCreated = (borr?: Borrower) => {
    this._drawerService.hide('newContactDrawer', 10);
    this._newContactDrawerOpened = false;
    if (borr?.borrowerId > 0) {
      this.onEditRequestedForContact(borr);
    }
  };

  onEditContactDrawerClosed = () => {
    this._editContactDrawerOpened = false;
  };

  onEditContactDrawerClose = () => {
    this._drawerService.hide('editContactDrawer', 10);
    this._editContactDrawerOpened = false;
  };

  onNewContactDrawerClose = () => {
    this._drawerService.hide('newContactDrawer', 10);
    this._newContactDrawerOpened = false;
  };

  onEditRequestedForContact = (borr: Borrower) => {
    this.borrowerIdToEdit = borr.borrowerId;
    this.editContactDrawer.title =
      'Editing Contact - ' + Utils.getPersonsDisplayName(borr);

    setTimeout(() => {
      this._drawerService.show('editContactDrawer', 10);
    }, 100);
  };

  onImpersonatedUserChanged = (userId: string) => {
    //reload chats
    this.getConversations(userId);
  };

  onNewApplicationDrawerClosed = () => {
    this._newApplicationDrawerOpened = false;
  };

  onNewApplicationDrawerClose = () => {
    this._drawerService.hide('newApplicationDrawer', 10);
    this._newApplicationDrawerOpened = false;
  };

  onEmailsLoaded = (numberOfRecordsLoaded: number) => {
    // this.numberOfLoadedMentions += numberOfRecordsLoaded;
  };

  notificationDetails = (alert: AlertModel) => {
    // TODO: There is no agentId on the alert model - how to identify the agent conversation then?
    // if (alert.agentId) {
    //   this.alertAgentId = alert.agentId;
    // }
    if (alert && alert.alertType == 'BorrowerMessageAlert') {
      //open the message conversation in the chats panel
      this.alertBorrowerId = alert.borrowerId;
      this._drawerService.show('conversationsDrawer', 10);
      this._conversationsDrawerOpened = true;
      setTimeout(() => this.alertMessage(alert), 500);
    } else if (alert && alert.applicationId && alert.taskId) {
      //Open the task in the side panel
      this._taskService.getTaskDashboardViewById(alert.taskId).subscribe(
        (task) => {
          this.mentionLoanDocTask = task;
          this.mentionMessageScope = MentionMessageScope.TaskNote;
          this._drawerService.show(
            'taskEditorDrawer',
            10,
            undefined,
            undefined,
            {
              size: DrawerSize.XXLarge,
              containerWrapperId: null,
            }
          );
        },
        (error) => {
          this._notifyService.showError(error.error, 'Error!');
        }
      );
    } else if (alert && alert.applicationId && alert.borrowerId) {
      this._router.navigate([`admin/app-details/${alert.applicationId}`]);
    } else if (alert && alert.leadId) {
      this.viewViewLeadModal(alert.leadId);
    }
  };

  alertMessage = (alert) => {
    // let index = this.conversationList.allChats.findIndex(chat => chat.applicationId == alert.applicationId);
    // this.conversationList.allChats[index].isChatWindowOpened = true;
    // this.conversationList.loadChatHistory(this.conversationList.allChats[index]);
  };

  viewViewLeadModal = (leadId: number) => {
    if (!leadId) {
      console.error('invalid leadId.');
      return;
    }
    this._drawerService.openDrawer({
      contactListType: ContactListType.Lead,
      recordId: leadId,
    });
  };

  private onSmsChatRequested = (smsChat: SmsChatRequestedModel) => {
    const internalPhone = smsChat.betweenPhone || this.currentUserPhoneNumber;

    let convo: Conversation;

    if (smsChat.smsMessage?.conversationId) {
      convo = this.allConversations.find(
        (c) =>
          c.conversationId ==
          (smsChat.conversationId ?? smsChat.smsMessage?.conversationId)
      );

      if (convo) {
        var index = this.allConversations.indexOf(convo);
        this.allConversations.splice(index, 1);
        this.allConversations.unshift(convo);

        this.updateConversationStateAfterNewMessage(convo, smsChat);

        if (convo.conversations && convo.conversations.length > 0) {
          const convoItself = convo.conversations.find(
            (c) => c.conversationId == smsChat.smsMessage?.conversationId
          );
          if (convoItself) {
            this.updateConversationStateAfterNewMessage(convoItself, smsChat);
          }
        }

        if (!this._conversationsDrawerOpened) {
          convo.isActive = true;
          this.allConversations
            .filter((x) => x.conversationId !== convo.conversationId)
            .forEach((x) => (x.isActive = false));

          if (smsChat.openDrawer) {
            this.conversationTargetPersonContext =
              smsChat.conversationTargetPersonContext;
            this._openConversationDrawer();
          }
        }

        this._conversationService.addSmsMessageToChat(smsChat.smsMessage);
        this.allConversations = [...this.allConversations];
        return;
      }
    }

    const convos = this.allConversations.filter((chat) => {
      const internalParticipants = [];
      const externalParticipants = chat.participants.filter(p => p.participantType === ParticipantType.External);

      if (chat.conversations && chat.conversations.length > 0) {
        chat.conversations.forEach((convo) => {
          internalParticipants.push(
            convo.participants.find(
              (p) => p.participantType === ParticipantType.Internal
            )
          );
        });
      }

      let internalParticipant = internalParticipants.find(
        (p) =>
          phoneToPlainString(removeUsPhonePrefix(p.phoneNumber)) ===
          phoneToPlainString(removeUsPhonePrefix(internalPhone))
      );

      let externalParticipant = externalParticipants.find(
        (p) =>
          phoneToPlainString(removeUsPhonePrefix(p.phoneNumber)) ===
          phoneToPlainString(removeUsPhonePrefix(smsChat.userPhone))
      );
      if (!externalParticipant || !internalParticipant) {
        return false;
      }

      return (
        internalParticipant &&
        externalParticipant &&
        (phoneToPlainString(removeUsPhonePrefix(externalParticipant.phoneNumber)) ===
        phoneToPlainString(removeUsPhonePrefix(smsChat.userPhone)) || !smsChat.userName)
      ); // internal bubble || external message
    });

    if (convos?.length == 1) {
      convo = convos[0];

      if (convo.isHidden) {
        this._updateConversationHiddenStatus(convo, false);
      }
      convo.mostRecentMessageDate = new Date();
      convo.mostRecentMessagePreview = smsChat.smsMessage?.body || ' ';
      if (convo.isActive) {
        smsChat.smsMessage.conversationId = convo.conversationId;
        this._conversationService.addSmsMessageToChat(smsChat.smsMessage);
      } else {
        convo.isUnread = true;
      }

      if (!convo.isActive) {
        convo.isActive = smsChat.openDrawer;
      }
      if (convo.isActive) {
        this.allConversations
          .filter((x) => x.conversationId !== convo.conversationId)
          .forEach((x) => (x.isActive = false));
      }
      const index = this.allConversations.indexOf(convo);
      this.allConversations.splice(index, 1);
      this.allConversations.unshift(convo);
    } else if (convos.length > 1) {
      convos.forEach((convo) => {
        if (convo.isHidden) {
          this._updateConversationHiddenStatus(convo, false);
        }
        convo.isUnread = true;
        const index = this.allConversations.indexOf(convo);
        if (index > -1) {
          this.allConversations.splice(index, 1);
        }
      });

      this.allConversations.forEach((x) => (x.isActive = false));
      this.allConversations.unshift(...convos);

      Swal.fire({
        title: 'Reminder',
        text: `${smsChat.userName || smsChat.userPhone} is a participant in ${
          convos.length
        } conversations. Please pick the one you'd like to proceed with from the conversations list.`,
        icon: 'warning',
        confirmButtonText: 'OK',
      });
    } else {
      //todo: call api to register conversation
      var newConversation = new Conversation();
      newConversation.assignedTo = this.userId;
      var externalParticipant = new ConversationParticipant();
      var internalParticipant = new ConversationParticipant();

      externalParticipant.participantType = ParticipantType.External;
      internalParticipant.participantType = ParticipantType.Internal;
      externalParticipant.phoneNumber = smsChat.userPhone.startsWith('+1')
        ? smsChat.userPhone
        : `+1${smsChat.userPhone}`;
      internalParticipant.phoneNumber = internalPhone.startsWith('+1')
        ? internalPhone
        : `+1${internalPhone}`;
      newConversation.chatMedium = ChatMedium.Sms;
      newConversation.isUnread = true;
      newConversation.mostRecentIncomingMessageDate = new Date();
      newConversation.lastReadDate =
        newConversation.mostRecentIncomingMessageDate;
      newConversation.externalName = smsChat.userName;
      newConversation.conversationId = smsChat.smsMessage?.conversationId;
      newConversation.mostRecentMessagePreview = ' ';
      newConversation.participants = [];
      newConversation.contactAlias = smsChat.userName
        ? smsChat.userName
            .match(/(\b\S)?/g)
            .join('')
            .match(/(^\S|\S$)?/g)
            .join('')
            .toUpperCase()
        : 'N/A';

      newConversation.participants.push(externalParticipant);
      newConversation.participants.push(internalParticipant);

      // Call API to create conversation in DB
      this._spinnerService.show();
      this._conversationService
        .saveConversation(newConversation)
        .subscribe({
          next: (result) => {
            convo = result;
            convo.conversations = [result];
            convo.isActive = smsChat.openDrawer;
            this.allConversations
              .filter((x) => x.conversationId !== convo.conversationId)
              .forEach((x) => (x.isActive = false));
            this.allConversations.unshift(convo);
            this.allConversations = [...this.allConversations];
          },
          error: (err) => {
            this._notifyService.showError(
              err ? (err.data ? err.data.message : '') : '',
              'Error'
            );
          },
        })
        .add(() => this._spinnerService.hide());
    }

    if (!this._conversationsDrawerOpened && smsChat.openDrawer) {
      this.conversationTargetPersonContext =
        smsChat.conversationTargetPersonContext;
      this._openConversationDrawer();
    }

    this.allConversations = [...this.allConversations];
  };

  private updateConversationStateAfterNewMessage = (
    convo: Conversation,
    smsChat: SmsChatRequestedModel
  ) => {
    // If the message coming in is older than the most recent message in the conversation, don't update the conversation
    // if (
    //   convo.mostRecentMessageDate &&
    //   smsChat.smsMessage?.dateUpdated &&
    //   convo.mostRecentMessageDate >= new Date(smsChat.smsMessage?.dateUpdated)
    // ) {
    //   return;
    // }
    convo.mostRecentMessagePreview = smsChat.smsMessage?.body;
    convo.mostRecentMessageDate =
      typeof smsChat.smsMessage?.dateInserted == 'string'
        ? new Date(smsChat.smsMessage?.dateInserted)
        : smsChat.smsMessage?.dateInserted;
    convo.lastReadDate = convo.mostRecentMessageDate;
    convo.isUnread = smsChat.smsMessage?.direction == MessageDirection.Inbound;
  };

  private initializeFromContext = (context: ApplicationContext) => {
    this.avatarUrl = context.currentlyLoggedInUserProfile.userProfile.avatarId
      ? `${this._environment.apiInfo.apiBaseUrl}avatars/${context.currentlyLoggedInUserProfile.userProfile.avatarId}`
      : context.currentlyLoggedInUser.avatar;

    this.userId = context.userPermissions.userId;
    this.userProfile = context.currentlyLoggedInUserProfile.userProfile;
    this.loanStatus = context.globalConfig.loanStatus;
    this.user = {
      firstName: context.currentlyLoggedInUserProfile.userProfile.firstName,
      lastName: context.currentlyLoggedInUserProfile.userProfile.lastName,
      lastTimeLogin: context.currentlyLoggedInUser.lastTimeLogin,
      userImage: this.avatarUrl,
    };
    this.globalConfig = context.globalConfig;
  };

  private initializeConversationAutoRefreshTimer = () => {
    if (this._conversationAutoRefreshInterval) {
      clearInterval(this._conversationAutoRefreshInterval);
    }

    const self = this;
    this._conversationAutoRefreshInterval = setInterval(async function () {
      self.refreshConversations();
    }, 300000);
  };

  private subscribeToEvents = () => {
    this._conversationUpdatedSubscription =
      this._conversationService.conversationUpdated.subscribe(
        (conversation) => {
          let convo = this.allConversations.find(
            (c) => c.conversationId == conversation.conversationId
          );

          if (!convo) {
            this.populateConversationColors(conversation);
            this.allConversations.unshift(conversation);
          } else {
            conversation.contactAlias = convo.contactAlias;
            conversation.contactAvatarColor = convo.contactAvatarColor;
            conversation.isActive = convo.isActive;

            conversation.conversations = convo.conversations;
            var index = this.allConversations.indexOf(convo);
            this.allConversations.splice(index, 1);
            this.allConversations.unshift(conversation);
            // convo = _.clone(conversation);
          }

          this.allConversations = [...this.allConversations];
        }
      );

    this._borrowerChatRequestedSubscription =
      this._conversationService.borrowerChatRequested.subscribe(
        (borrowerChat) => {
          let convo = this.allConversations.find(
            (chat) =>
              chat.borrowerId == borrowerChat.borrowerId &&
              chat.applicationId === borrowerChat.appId
          );

          if (!convo) {
            //todo: call api to register conversation
            var newConversation = new Conversation();
            newConversation.assignedTo = this.userId;
            newConversation.applicationId = borrowerChat.appId;
            newConversation.conversationId =
              borrowerChat.borrMessage?.conversationId;
            newConversation.borrowerId = borrowerChat.borrowerId;
            newConversation.chatMedium = ChatMedium.BorrowerMessage;
            newConversation.mostRecentIncomingMessageDate = new Date();
            newConversation.mostRecentMessageDate = new Date();
            newConversation.lastReadDate =
              newConversation.mostRecentIncomingMessageDate;
            newConversation.isUnread = true;
            newConversation.externalName =
              borrowerChat.borrMessage?.senderDisplayName ||
              `Borrower (${borrowerChat.borrowerId})`;

            convo = newConversation;

            this.allConversations.unshift(convo);
          } else {
            if (convo.isHidden) {
              this._updateConversationHiddenStatus(convo, false);
            }
            convo.mostRecentMessageDate = new Date();
            convo.mostRecentMessagePreview =
              borrowerChat.borrMessage.plainTextContent ||
              convo.mostRecentMessagePreview;
            if (convo.isActive) {
              this._conversationService.addBorrowerMessageToChat(
                borrowerChat.borrMessage
              );
            } else {
              convo.isUnread = true;
            }
          }

          if (!borrowerChat.external) {
            this.allConversations.forEach((x) => (x.isActive = false));
          }

          convo.isActive = borrowerChat.openDrawer;

          if (!this._conversationsDrawerOpened && borrowerChat.openDrawer) {
            this._openConversationDrawer();
          }

          this.allConversations = [...this.allConversations];
        }
      );

    this._internalChatRequestedSubscription =
      this._conversationService.internalChatRequested.subscribe(
        (internalChat) => {
          let convo = this.allConversations.find(
            (chat) =>
              chat.chatMedium == 'InternalMessage' &&
              chat.applicationId == internalChat.appId
          );

          if (!convo) {
            //todo: call api to register conversation
            var newConversation = new Conversation();
            newConversation.assignedTo = this.userId;
            newConversation.applicationId = internalChat.appId;
            newConversation.chatMedium = ChatMedium.InternalMessage;
            newConversation.mostRecentIncomingMessageDate = new Date();
            newConversation.mostRecentMessageDate = new Date();
            newConversation.isUnread = true;
            newConversation.lastReadDate =
              newConversation.mostRecentIncomingMessageDate;
            newConversation.externalName =
              internalChat.borrMessage?.senderDisplayName;

            convo = newConversation;

            this.allConversations.unshift(convo);
          } else {
            if (convo.isHidden) {
              this._updateConversationHiddenStatus(convo, false);
            }
            convo.mostRecentMessageDate = new Date();
            convo.mostRecentMessagePreview =
              internalChat.borrMessage.plainTextContent ||
              convo.mostRecentMessagePreview;
            if (convo.isActive) {
              this._conversationService.addBorrowerMessageToChat(
                internalChat.borrMessage
              );
            } else {
              convo.isUnread = true;
            }
          }

          if (!internalChat.external) {
            this.allConversations.forEach((x) => (x.isActive = false));
          }

          convo.isActive = internalChat.openDrawer;

          if (!this._conversationsDrawerOpened && internalChat.openDrawer) {
            this._openConversationDrawer();
          }

          this.allConversations = [...this.allConversations];
        }
      );

    this._smsChatRequestedSubscription =
      this._conversationService.smsChatRequested.subscribe((smsChat) => {
        this.onSmsChatRequested(smsChat);
      });

    this._contextChangesForLoaninfoSubscription =
      this._applicationContextService.loanInfoChanges.subscribe((context) => {
        this.insideLoanContext = false;
        if (context.application) {
          this.insideLoanContext = true;
        }
      });

    this._contextChangesSubscription =
      this._applicationContextService.changes.subscribe((context) => {
        if (context) {
          this.initializeFromContext(context);
        }
      });

    this._contextSubscription =
      this._applicationContextService.context.subscribe((context) => {
        this.initializeFromContext(context);
        this.checkUserFromNumber(context);

        this._signalRService.run(context);
        this._firebaseService.run(context);
      });
  };

  private checkUserFromNumber = (context: ApplicationContext) => {
    this.currentUserPhoneNumber = Utils.getActiveTelephonyService(
      context.currentlyLoggedInUserProfile.telephonyServices
    )?.fromPhoneNumber;
    const isShownPhoneMessage = this._localStorageService.getItem(
      'isShownPhoneMessage'
    );
    if (!this.currentUserPhoneNumber && !isShownPhoneMessage) {
      this._notifyService.showWarning(
        'User phone number was not defined',
        'Warning'
      );
      this._localStorageService.setItem('isShownPhoneMessage', true);
    }
  };

  private _openConversationDrawer = () => {
    this._drawerService.show('conversationsDrawer', 10);
    this._conversationsDrawerOpened = true;
  };

  private getConversations = (userId?: string) => {
    this.conversationsUserId = userId || this.userProfile.userCompanyGuid;
    this.allConversations = [];
    this._conversationService
      .getConversationsForUser(this.conversationsUserId, 1, 1000)
      .subscribe({
        next: (chats) => {
          if (chats?.records?.length > 0) {
            this.allConversations = this.prepareConversationItemsForList(
              chats?.records
            );
          }
          this.initializeConversationAutoRefreshTimer();
        },
        error: (error) => {
          this._notifyService.showError(
            error?.message || "Couldn't load conversations",
            'Error'
          );
        },
      });
  };

  private refreshConversations = (userId?: string) => {
    this.conversationsUserId = userId || this.userProfile.userCompanyGuid;
    this._conversationService
      .getConversationsForUser(this.conversationsUserId, 1, 1000, 6)
      .subscribe({
        next: (newChats) => {
          if (newChats?.records?.length > 0) {
            const newConversationsToAppend = [];
            newChats.records.forEach((chat) => {
              const existing = this.allConversations.find(c => c.conversationId == chat.conversationId);
              if (!existing) {
                newConversationsToAppend.push(chat);
              }
            });
            if (newConversationsToAppend.length > 0) {
              const newConversationListItems = this.prepareConversationItemsForList(newConversationsToAppend);
              const existingConversations = [...this.allConversations];
              let allConversationsAfterRefresh = existingConversations.concat(newConversationListItems);
              // Order them by the most recent message date
              allConversationsAfterRefresh = _.orderBy(allConversationsAfterRefresh, ['mostRecentMessageDate'], ['desc']);
              this.allConversations = allConversationsAfterRefresh;
            }
          }
        },
        error: (error) => {
          this._notifyService.showError(
            error?.message || "Couldn't refresh conversations",
            'Error'
          );
        },
      });
  };

  private prepareConversationItemsForList = (conversations: Array<Conversation>): Array<Conversation> => {
    const conversationItems: Conversation[] = [];

    const smsConversations = conversations.filter(
      (c) =>
        c.chatMedium === ChatMedium.Sms &&
        c.participants &&
        c.participants.some(
          (p) => p.participantType === ParticipantType.External && p.phoneNumber
        )
    );

    const smsConversationGroupedByExternalParticipants = _.groupBy(
      smsConversations,
      (c) => {
        const externalParticipants = _.orderBy(
          c.participants.filter(
            (p) => p.participantType === ParticipantType.External
          ),
          ['phoneNumber'],
          ['asc']
        );
        const groupIdenfier = externalParticipants
          .map((p) => removeUsPhonePrefix(p.phoneNumber))
          .join(' ');
        return groupIdenfier;
      }
    );

    const smsChatConversationsAccountedFor: string[] = [];

    conversations.forEach((conversation) => {
      this.populateConversationColors(conversation);
      const externalParticipants = _.orderBy(
        conversation.participants.filter(
          (p) => p.participantType === ParticipantType.External
        ),
        ['phoneNumber'],
        ['asc']
      );
      const groupIdenfier = externalParticipants
        .map((p) => removeUsPhonePrefix(p.phoneNumber))
        .join(' ');

      // Handle SMS conversations differently, since they can be grouped by external participants and done with the same user using different numbers
      if (conversation.chatMedium == ChatMedium.Sms) {
        if (!smsChatConversationsAccountedFor.includes(groupIdenfier)) {
          if (
            smsConversationGroupedByExternalParticipants.hasOwnProperty(
              groupIdenfier
            ) &&
            smsConversationGroupedByExternalParticipants[groupIdenfier]
          ) {
            conversation.conversations =
              smsConversationGroupedByExternalParticipants[groupIdenfier];
            smsChatConversationsAccountedFor.push(groupIdenfier);
            conversationItems.push(conversation);
          }
        }
      } else {
        conversationItems.push(conversation);
      }
    });
    return conversationItems;
  };

  private populateConversationColors = (conversation: Conversation) => {
    if (!conversation.contactAvatarColor) {
      conversation.contactAvatarColor = ColorUtils.normalizedColorFromString(
        conversation.externalName || uuidv4()
      );
    }

    if (conversation.chatMedium == 'InternalMessage') {
      conversation.contactAlias = '--';
    } else if (
      conversation.externalName &&
      conversation.externalName.length > 3
    ) {
      conversation.contactAlias = conversation.externalName
        .match(/(\b\S)?/g)
        .join('')
        .match(/(^\S|\S$)?/g)
        .join('')
        .toUpperCase();
    } else if (
      conversation.participants.some(
        (p) => p.participantType === ParticipantType.External && p.name
      )
    ) {
      const externalParticipant = conversation.participants.find(
        (p) => p.participantType === ParticipantType.External
      );
      conversation.contactAlias = externalParticipant.name
        .match(/(\b\S)?/g)
        .join('')
        .match(/(^\S|\S$)?/g)
        .join('')
        .toUpperCase();
    } else {
      conversation.contactAlias = '??';
    }
  };

  private _updateConversationHiddenStatus(conversation: Conversation, status: boolean) {
    conversation.isHidden = status;
    if (!conversation.conversationId) {
      return;
    }
    this._conversationService
      .changeHiddenStatus(conversation.conversationId, status)
      .subscribe();
  }
}

export class ConversationTargetPersonContext {
  applicationId?: number;
  borrowerId?: number;
  leadId?: number;
  agentId?: number;
}
