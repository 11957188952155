import { ChatRelatedEntities } from "../../conversations/models/chat-related-entities.model";
import { PhoneType } from "./phone-type.model";
import { VoiceHistory } from "./voice-history.model";

export class ConferenceParticipant {
  conferenceParticipantId: number;
  conferenceLineId: number;
  thirdPartyCallId: string;
  voiceHistoryId: number;
  phoneNumber: string;
  onHold: boolean;
  muted: boolean;
  coaching: boolean;
  isAgent: boolean;
  callStatus: CallStatus;
  voiceHistory: VoiceHistory;
  companyId: number;
  insertedBy: string;
  dateInserted: Date;
  updatedBy: string;
  dateUpdated: Date;

  totalSeconds?: number;
  timer?: string;
  participantName?: string;
  participantFirstLetters?: string;
  agentUserId?: string;
  conferenceId?: number
  phoneType?: PhoneType;

  //front end only
  relatedEntities: ChatRelatedEntities;
  isSpeaking: boolean;
}

export enum CallStatus {
  Queued = "Queued",
  Initiated = "Initiated",
  Ringing = "Ringing",
  InProgress = "InProgress",
  Completed = "Completed",
  Failed = "Failed",
  Busy = "Busy",
  NoAnswer = "NoAnswer",
  Canceled = "Canceled",
  Disconnected = "Disconnected"
}

export enum ConferenceParticipantEvent {
  Join = "Join",
  Leave = "Leave",
  OnHold = "OnHold",
  UnHold = "UnHold",
  OnMute = "OnMute",
  UnMute = "UnMute",
  SpeechStart = "SpeechStart",
  SpeechStop = "SpeechStop",
  Modify = "Modify"
}
