import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Borrower } from 'src/app/models';
import { LoanDocTask } from 'src/app/models/loan/loan-doc-task.model';

@Component({
  selector: 'items-required-for-submission',
  templateUrl: './items-required-for-submission.component.html',
  styleUrls: ['./items-required-for-submission.component.scss']
})
export class ItemsRequiredForSubmissionComponent implements OnInit {

  @Input()
  data: Array<LoanDocTask>;

  @Input()
  optionalConditions: Array<LoanDocTask>;

  @Input()
  borrowers: Borrower[] = [];

  @Output()
  onTaskUpdated: EventEmitter<void> = new EventEmitter<void>();

  constructor() { }

  ngOnInit(): void {
  }

  taskUpdated = () => {
    this.onTaskUpdated.emit();
  }
}
