<div class="modal-header">
  <h5 class="modal-title w-100">
    {{ !stackingOrderData.stakingOrderId ? 'New' : 'Edit' }}
    Stacking Order Document Association
  </h5>

  <button
    type="button"
    class="btn-close"
    (click)="activeModal.dismiss()"
    aria-label="Close"
  ></button>
</div>

<div class="modal-body">
  <div class="row">
    <div class="col example-container">
      <h4>Associated Document Types</h4>
      <div
        cdkDropList
        #todoList="cdkDropList"
        [cdkDropListData]="selectedDocumentTypes"
        [cdkDropListConnectedTo]="[doneList]"
        class="example-list"
        (cdkDropListDropped)="drop($event)"
      >
        <div *ngIf="selectedDocumentTypes.length == 0" class="empty-box">
            Drag items here
        </div>

        <div
          class="example-box"
          *ngFor="let item of selectedDocumentTypes"
          cdkDrag
        >
          {{ item.documentTypeName }}
        </div>
      </div>
    </div>

    <div class="col example-container">
      <h4>Remaining Document Types</h4>

      <div
        cdkDropList
        #doneList="cdkDropList"
        [cdkDropListData]="remainigDocumentTypes"
        [cdkDropListConnectedTo]="[todoList]"
        class="example-list"
        (cdkDropListDropped)="drop($event)"
      >
        <div
          class="example-box"
          *ngFor="let item of remainigDocumentTypes"
          cdkDrag
        >
          {{ item.documentTypeName }}
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal-footer justify-content-end">
  <button
    type="button"
    class="btn btn-secondary"
    (click)="activeModal.dismiss()"
  >
    <i class="fa fa-close"></i> Cancel
  </button>

  <button
    type="button"
    class="btn btn-primary"
    [disabled]="isSaving"
    (click)="save()"
  >
    <span *ngIf="!isSaving">Save</span>
    <span *ngIf="isSaving">
      <span
        class="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
      ></span>
      Saving
    </span>
  </button>
</div>
