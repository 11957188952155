import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { DocumentType } from 'src/app/models';
import { StackingOrder } from 'src/app/models/config/stacking-order.model';
import { NotificationService } from 'src/app/services/notification.service';
import { StackingOrderService } from '../../services/stacking-order.service';

@Component({
  selector: 'app-stacking-order-document-association-dialog',
  templateUrl: './stacking-order-document-association-dialog.component.html',
  styleUrls: ['./stacking-order-document-association-dialog.component.scss'],
})
export class StackingOrderDocumentAssociationDialogComponent implements OnInit {

  get stackingOrder(): StackingOrder {
    return this._stackingOrder;
  }

  @Input()
  set stackingOrder(value: StackingOrder) {
    this._stackingOrder = value;
    if (value) {
      this.stackingOrderData = _.cloneDeep(value);
    }
  }

  @Input() documentTypes: DocumentType[] = [];

  stackingOrderData: StackingOrder = new StackingOrder();
  isSaving: boolean = false;

  selectedDocumentTypes: DocumentType[] = [];
  remainigDocumentTypes: DocumentType[] = [];

  private _stackingOrder: StackingOrder;

  constructor(
    public activeModal: NgbActiveModal,
    private readonly _stackingOrderService: StackingOrderService,
    private readonly _notifyService: NotificationService
  ) {
  }

  ngOnInit(): void {
    this.populateLoadedDocumentTypes(this.stackingOrderData.associate);
  }

  save = () => {

    this.stackingOrderData.associate = this.selectedDocumentTypes ? this.selectedDocumentTypes.map(t => t.documentTypeId).join() : null;
    this._stackingOrderService.updateStackingOrder(this.stackingOrderData)
      .subscribe(() => {
        this.isSaving = false;
        this._notifyService.showSuccess("Update done successfully", "Success");
        this.activeModal.close(this.stackingOrderData);

      }, (err) => {
        this._notifyService.showError(err.error.message, "Error");
        this.isSaving = false;
      })

  }

  drop = (event: CdkDragDrop<any[]>) =>  {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
  }

  private populateLoadedDocumentTypes = (types: string): void => {
    if (!types || !types.trim()) {
      this.remainigDocumentTypes = this.documentTypes;
      return;
    }

    let selectedTypes: DocumentType[] = [];
    let parts = types.trim().split(",");
    parts.forEach(value => {
      value = value.trim();
      let matched = this.documentTypes.find(s => s.documentTypeId == Number(value));
      if (matched) {
        selectedTypes.push(matched);
      }
    });

    this.selectedDocumentTypes = selectedTypes;
    this.remainigDocumentTypes = selectedTypes.length > 0 ? this.documentTypes.filter(t => this.selectedDocumentTypes.length && !this.selectedDocumentTypes.includes(t)) : this.documentTypes;
  }

}
