<div class="row border-list" *ngIf="conversation">
  <div class="col-sm-12">
    <div class="d-flex mobile-padding" style="margin-bottom: 7px;">
      <div class="activity-info-icon">
        <i *ngIf="conversation.contactAlias" class="contact-icon align-self-center fw-bold"
          [ngStyle]="{'backgroundColor': conversation.contactAvatarColor ? conversation.contactAvatarColor : defaultColor}">
          <span class="text-white"> {{ conversation.contactAlias }} </span>
        </i>
      </div>
      <div class="ms-2" style="flex-grow: 2">
        <ul class="list-unstyled mb-0 text-muted">
          <li class="list-item">
            <span class="activity-info-text"
              *ngIf="conversation.chatMedium == 'InternalMessage' && !conversation['onEdit']">Int Msg - Loan: 1{{
              conversation.applicationId | padLeft:'0': 9 }} - Borrower: {{ conversation.externalName }}
            </span>

            <div class="d-flex justify-content-between align-items-center">
              <span>
                <span class="activity-info-text me-1 mt-1"
                  *ngIf="conversation.chatMedium !== 'InternalMessage' && !conversation['onEdit']"
                  (click)="onEditExternalNameClicked()">
                  {{ conversation.externalName | phone }}

                </span>
                <div *ngFor="let p of conversation.participants | filter: {participantType: 'External'}" class="mb-1">
                  <a (click)="dial(p.phoneNumber)" class="text-muted">
                    {{ p.phoneNumber | phone }}
                  </a>
                </div>
                <!-- <a *ngFor="let p of conversation.participants | filter: {participantType: 'External'}"
                  class="mb-1 text-muted" (click)="dial(p.phoneNumber)">{{p.phoneNumber | phone}}</a> -->
              </span>
              <ng-container *ngIf="!conversation['onEdit'] && fromPhoneNumbersAll?.length > 1">
                <div class="d-flex align-items-center">
                  <i *ngIf="thereAreUnreadConversationsForPhoneNumber"
                    class="fas fa-circle unread-conversation mt-1 me-1"></i>
                  <label for="phoneNumberToUse" class="mt-1 ms-1 me-2">From:</label>

                  <select class="form-select mt-1" name="fromPhoneNumber" id="fromPhoneNumber"
                    style="min-width: 119px; width: 183px;" [(ngModel)]="fromPhoneNumberGuid"
                    (ngModelChange)="onFromPhoneNumberChanged()">
                    <ng-container *ngIf="existingConversationPhoneNumbers && existingConversationPhoneNumbers.length">
                      <optgroup label="Existing Conversations">
                        <option class="phone-number-under-group"
                          *ngFor="let number of existingConversationPhoneNumbers; let i = index"
                          value="{{number.guid}}">
                          {{number.phoneNumber | phone}} {{number.isUnread ? '(Unread)' : ''}}
                        </option>
                      </optgroup>
                    </ng-container>
                    <ng-container
                      *ngIf="(myPhoneNumbers && myPhoneNumbers.length) || (otherPhoneNumbers && otherPhoneNumbers.length)">
                      <optgroup label="Start a New One Using">
                        <ng-container *ngIf="myPhoneNumbers && myPhoneNumbers.length">
                          <option class="sub-phone-number-group" disabled>
                            My Numbers:
                          </option>
                          <option class="phone-number-under-group" style="margin-left: 100px"
                            *ngFor="let number of myPhoneNumbers; let i = index" value="{{number.guid}}">
                            &nbsp;&nbsp;
                            {{ number.phoneNumber | phone}}
                          </option>
                        </ng-container>
                        <ng-container *ngIf="otherPhoneNumbers && otherPhoneNumbers.length">
                          <option class="sub-phone-number-group" disabled>
                            Other Numbers:
                          </option>
                          <option class="phone-number-under-group"
                            *ngFor="let number of otherPhoneNumbers; let i = index" value="{{number.guid}}">
                            &nbsp;&nbsp;
                            {{ number.phoneNumber | phone}}
                          </option>
                        </ng-container>
                      </optgroup>
                    </ng-container>
                  </select>
                </div>
              </ng-container>
            </div>

            <div *ngIf="conversation['onEdit']" class="d-flex">
              <input style="width: 10rem;" type="text" class="form-control w-100 mt-1 me-2"
                name="convoExternalName{{conversation.conversationId}}"
                id="convoExternalName{{conversation.conversationId}}"
                [(ngModel)]="conversation['tempConvoGroupExternalName']" />
              <div class="d-flex align-items-center mt-1">
                <a (click)="onExternalNameRenameConfirmed()">
                  <span> <i class="fa fa-check-circle text-success" style="font-size: small;"></i>
                  </span>
                </a>
                <a (click)="onExternalNameRenameCancelled()">
                  <span class="ms-2"> <i class="fa fa-times text-danger" style="font-size: small;"></i> </span>
                </a>
              </div>
            </div>
          </li>
        </ul>
      </div>

      <div class="activity-info-icon">
        <button type="button" class="btn btn-outline-secondary convo-group-btn me-2" placement="bottom"
          [popover]="convoGroupPopoverTemplate" containerClass="convo-group-btn" #convoGroupPopover="bs-popover"
          [outsideClick]="false">
          <i class="fas fa-user-plus text-success"></i>
        </button>
        <ng-template #convoGroupPopoverTemplate>
          <form id="convoGroupForm" #convoGroupForm="ngForm" name="convoGroupForm">
            <div class="row">
              <div class="col-md-10 form-group me-0 pe-0">
                <label for="convoGroupPhoneNumber"> Cell Phone </label>
                <input #borrowerCellPhone="ngModel" class="form-control" id="convoGroupPhoneNumber"
                  name="convoGroupPhoneNumber" type="text" [(ngModel)]="convoGroupPhoneNumber" mask="(000) 000-0000"
                  [showMaskTyped]="true" required
                  [ngClass]="{'is-invalid': isSubmitted && cellPhoneField && cellPhoneField.touched && cellPhoneField.invalid}"
                  #cellPhoneField="ngModel" />
                <div class="invalid-feedback"> Please enter a valid phone number </div>
              </div>
              <div class="col-md-2 form-group">
                <button (click)="onAttemptedToAddNumber()" class="btn btn-outline-success"
                  style="margin-top: 1.25rem; float: right;" title="Add Number">
                  <i class="fas fa-plus"></i>
                </button>
              </div>
              <div class="col-md-12">
                <h5 *ngIf="convoGroupPhoneNumbers.length > 0">Numbers in Conversation</h5>
                <ul class="list-group">
                  <li class="list-group-item" *ngFor="let number of convoGroupPhoneNumbers">
                    <ng-container
                      *ngIf="!checkNumberParticipantType(number) && isNumberNewlyAdded(number); else elseBlock">
                      <span class="cursor me-2" (click)="onAttemptedToDeleteNumber(number)">
                        <i class="fas fa-trash-alt text-danger" title="Delete Number"></i>
                      </span>
                    </ng-container>
                    <ng-template #elseBlock>
                      <span class="me-2" title="Existing participants cannot be deleted">
                        <i class="fas fa-trash-alt" style="color: #ffc6d2;"></i>
                      </span>
                    </ng-template>
                    <span style="color: #1d2c48;">{{number | phone}}</span>
                    <span *ngIf="checkNumberParticipantType(number) && currentUserName" class="text-muted mx-2">|</span>
                    <span *ngIf="checkNumberParticipantType(number)" class="text-muted">{{currentUserName}}</span>
                  </li>
                </ul>
              </div>

              <div class="text-end">
                <button type="button" class="btn btn-secondary mt-2 me-2" (click)="onConvoGroupCancelClicked()">
                  Cancel
                </button>
                <button type="button" class="btn btn-primary mt-2" (click)="onConvoGroupSaveClicked()"
                  [disabled]="isSaving || !isSaveEnabled">
                  <span class="spinner-border spinner-border-sm" role="status" *ngIf="isSaving"></span>
                  Save
                </button>
              </div>
            </div>
          </form>
        </ng-template>
      </div>

      <button class="btn hamburger-menu-btn mt-1" (click)="toggleMobileMenu()" data-bs-toggle="dropdown">
        <i class="fa fa-bars"></i>
      </button>

      <div class="dropdown-menu mobile-menu me-xs-4" [class.hide]="!isMobileView">
        <a class="dropdown-item" [ngClass]="{'active': tabIndex == 1}" (click)="onCommunicationClicked()"> Communication
        </a>
        <a *ngIf="conversation.chatMedium !== 'InternalMessage'" class="dropdown-item"
          [ngClass]="{'active': tabIndex == 2}" (click)="onDetailsClicked()"> Details </a>
        <a *ngIf="conversation.chatMedium == 'InternalMessage'" class="dropdown-item"
          [routerLink]="['/' + applicationMode + '/app-details/' + conversation.applicationId]"> Open Application </a>
        <a (click)="archiveConversation()" class="dropdown-item"> <i class="fa fa-archive"></i> {{conversation.isHidden
          ? 'Unarchive' : 'Archive'}} </a>
        <a class="dropdown-item" *ngIf="conversation.chatMedium == 'Sms'" (click)="recordVideo()"> <i
            class="fa fa-video"></i> Record Video
        </a>
      </div>

      <div class="button-items me-xs-4">
        <button type="button" class="btn" [ngClass]="{'active': tabIndex == 1}" (click)="onCommunicationClicked()">
          Communication
        </button>
        <button *ngIf="conversation.chatMedium !== 'InternalMessage'" type="button" class="btn"
          [ngClass]="{'active': tabIndex == 2}" (click)="onDetailsClicked()"> Details
        </button>
        <a *ngIf="conversation.chatMedium == 'InternalMessage'" type="button" class="btn"
          [routerLink]="['/' + applicationMode + '/app-details/' + conversation.applicationId]"> Open Application
        </a>

        <button type="button" class="btn" (click)="archiveConversation()">
          <i class="fa fa-archive"></i> {{conversation.isHidden ? 'Unarchive' : 'Archive'}}
        </button>
        <button *ngIf="conversation.chatMedium == 'Sms'" type="button" class="btn" (click)="recordVideo()">
          <i class="fa fa-video"></i> Record Video
        </button>
      </div>

    </div>
  </div>
</div>

<div *ngIf="tabIndex === 1" class="chat-box-right" id="chat-box-right">
  <conversation-chat-window #chatWindow *ngIf="conversation" [usersThatCanBeMentioned]="usersThatCanBeMentioned"
    [userId]="userId" [conversation]="conversation" [messages]="messages"
    (newMessageWasSeen)="onNewMessageWasSeen($event)" (conversationUpdated)="onConversationUpdated($event)"
    (newMessageWasSeen)="onNewMessageWasSeen($event)">
  </conversation-chat-window>
</div>
<div *ngIf="tabIndex === 2">
  <related-entities *ngIf="!conversation.applicationId && relatedEntities" [relatedEntities]="relatedEntities"
    (editBorrower)="onEditBorrowerClicked($event)" (editLead)="onEditLeadClicked($event)">
  </related-entities>
  <div *ngIf="conversation.applicationId" style="width:60%; margin: 0 auto"
    class="justify-content-center align-items-center mt-4">
    <loading-indicator *ngIf="!application || !borrowers" [customClass]="'init-loading-indicator'"></loading-indicator>
    <loan-summary-card-admin *ngIf="application && borrowers" [application]="application" [borrowers]="borrowers">
    </loan-summary-card-admin>
  </div>
</div>