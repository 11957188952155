import { Component, ElementRef, EventEmitter, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm, NgModel, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { forkJoin, map } from 'rxjs';
import { CreditReport, CreditVendor, CreditVendorEnum } from 'src/app/models';
import { TpoCreditPermissions } from 'src/app/modules/admin/tpo-config/models/tpo-configuration.model';
import { TpoConfigService } from 'src/app/modules/admin/tpo-config/services/tpo-config.service';
import { BorrowerType } from 'src/app/modules/leads/models/lead-credit.model';
import { LoanDocService } from 'src/app/services/loan-doc.service';
import { NotificationService } from 'src/app/services/notification.service';
import { isNullOrUndefined } from 'src/utils';
import { MortgageService } from "../../../../../services/mortgage.service";
import { ApplicationContextBoundComponent } from "../../../../../shared/components";
import { UrlaMortgage } from "../../../../urla/models/urla-mortgage.model";
import { CreditBorrower } from '../../models/credit-borrower.model';
import { CreditCardType, CreditPullType, CreditRequest, PaymentInfo } from '../../models/credit-request.model';
import { CreditThirdPartyCredential } from '../../models/credit-third-party-credential.model';
import { ServicesPermissions } from '../../models/services-permissions.model';
import { CreditService } from '../../services/credit.service';
import { Constants } from 'src/app/services/constants';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoanServicesService } from 'src/app/services/loan';
import { ThirdPartyCredentialsService } from 'src/app/services/third-party-credentials.service';
import { CreditModalComponent } from 'src/app/modules/system-level/components/integrations/credit/credit-modal/credit-modal.component';
import { CreditTechnicalAffiliate } from 'src/app/models/credit/credit-technical-affiliate.model';

@Component({
  selector: 'credit-run',
  templateUrl: 'credit-run.component.html',
  styleUrls: ['./credit-run.component.scss'],
})
export class CreditRunComponent extends ApplicationContextBoundComponent implements OnInit {

  @Input()
  borrowers: CreditBorrower[];

  @Input()
  creditHistory: CreditReport[];

  @Input()
  set creditVendors(creditVendors: CreditThirdPartyCredential[]) {
    this._creditVendors = creditVendors;
    this.prepareCreditVendorsDropdown();
    this.onPullCreditToggleChanged();
  }

  get creditVendors(): CreditThirdPartyCredential[] {
    return this._creditVendors;
  }

  @Input()
  applicationId: number;

  @Input()
  leadId: number;

  @Input()
  servicesPermissions: ServicesPermissions;

  @Input()
  creditVendor: CreditVendor = null;

  @Input()
  creditInfoId: number;

  @Input()
  latestPullCredit: CreditReport;

  @Input()
  mortgage?: UrlaMortgage;

  @Output()
  creditRunCompleted: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  creditRunSuccessfullyCompleted: EventEmitter<never> = new EventEmitter<never>();

  @Output()
  reissueSucceeded: EventEmitter<number> = new EventEmitter<number>();

  @Output()
  importLiabilitiesClicked: EventEmitter<number> = new EventEmitter<number>();

  @ViewChild('creditRunForm')
  creditRunForm: NgForm | undefined;

  @ViewChild("creditVendorComponent")
  creditVendorComponent: ElementRef<HTMLSelectElement>

  @ViewChild("ccExpirationDate")
  ccExpirationDate: NgModel

  reissue: boolean = false;
  pullingCreditReport: boolean = false;
  isPullEnabled: boolean = true;
  isLinkedToLos: boolean = true;
  showRunCredit: boolean = false;
  isSubmitSuccess: boolean = false;
  isSubmitError: boolean = false;
  creditRunClicked: boolean = false;
  showSoftPullReportType: boolean = false;

  warnMessage: string;
  actionButtonLabel: string;

  errorMessages: string[] = [];
  validationErrors: string[] = [];
  filteredBorrowers: CreditBorrower[] = [];
  requestTypes: CreditPullType[] = [CreditPullType.Individual];

  borrowerCreditRefNumbers: any = {};
  request: CreditRequest = new CreditRequest();

  creditCardMask: string;
  companyId: number;

  technicalAffiliates: Array<CreditTechnicalAffiliate>;

  private isReissueOnly: boolean;

  private _creditVendors: CreditThirdPartyCredential[] = [];

  constructor(
    private readonly injector: Injector,
    private readonly _creditService: CreditService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _loanDocService: LoanDocService,
    private readonly _notifyService: NotificationService,
    private readonly _mortgageService: MortgageService,
    private readonly _tpoConfigService: TpoConfigService,
    private readonly _modalService: NgbModal,
    private readonly _loanServicesService: LoanServicesService,
    private readonly _thirdPartyCredentialsService: ThirdPartyCredentialsService
  ) {
    super(injector);
  }

  ngOnInit() {
    this.loadTechnicalAffiliates();
    this.companyId = this.applicationContext.userPermissions.companyId;
    this.borrowers.forEach(borrower => {
      if (borrower.latestCreditReport) {
        borrower.latestCreditReport.experian = borrower.latestCreditReport.experianScore || borrower.latestCreditReport.experian;
        borrower.latestCreditReport.equifax = borrower.latestCreditReport.equifaxScore || borrower.latestCreditReport.equifax;
        borrower.latestCreditReport.transUnion = borrower.latestCreditReport.transUnionScore || borrower.latestCreditReport.transUnion;
        borrower.latestCreditReport.refNumber = borrower.latestCreditReport.referenceNumber || borrower.latestCreditReport.refNumber;
        borrower.latestCreditReport.date = borrower.latestCreditReport.creditRunDate || borrower.latestCreditReport.date;
      }
      borrower.willPullCredit = false;
      this.borrowerCreditRefNumbers[borrower.borrowerId] = borrower.latestCreditReport.refNumber;
    });
    this.filteredBorrowers = this.borrowers.filter(borrower => borrower.creditScore);
    this.isReissueOnly = this.servicesPermissions.creditPermissions === "1";

    if (this.isReissueOnly) {
      this.actionButtonLabel = "Re-issue Credit";
      this.reissue = true;
    } else {
      this.actionButtonLabel = "Run Credit";
    }

    this.isPullEnabled = this.servicesPermissions.isPullEnabled;
    this.isLinkedToLos = this.servicesPermissions.isLinkedToLos;

    const isUserProfileSet = this.creditVendors.some(cV => cV.userId);
    if (isUserProfileSet) {
      this.creditVendors.forEach(el => {
        const exist = this.creditVendors.filter(cV => cV.vendorName === el.vendorName)
        if (!exist) this.creditVendors.push(el)
      })
    }

    this.request = {
      applicationId: this.applicationId,
      leadId: this.leadId,
      borrowerIds: [],
      pullType: CreditPullType.Individual,
      creditVendor: this._creditVendors?.length === 1 ? this._creditVendors[0].vendorEnum : '',
      credentialId: null,
      experian: true,
      equifax: true,
      transUnion: true,
      paymentInfo: new PaymentInfo(),
      importLiabs: false,
      deleteExistingUnmatchedLiabilitiesFromLoan : false
    };
    this.request.creditVendor = isNullOrUndefined(this.creditVendor) ? this.request.creditVendor : this.creditVendor;
    const creditVendorToRunWith = this.creditVendors.find(x => x.vendorName == this.request.creditVendor);
    if (creditVendorToRunWith) {
      this.request.credentialId = creditVendorToRunWith.credentialId;
    }

    if (this.borrowers.length == 1) {
      this.request.pullType = CreditPullType.Individual;
    }
    else {
      this.requestTypes.push(CreditPullType.Joint);
    }

    if (this.filteredBorrowers.length > 0 && !this.isReissueOnly) {
      this.actionButtonLabel = "Re-run Credit";
    }

    if (!this.isPullEnabled) {
      this.warnMessage = "To enable pull credit reports please link your loan application to LOS.";
    }

    this.onPullCreditToggleChanged();

    this.applicationContextService.context.subscribe(ctx => {
      if (ctx.isTpo) {
        this.setReissueFieldForTpo();
      }
    })

    this.getMaskType(this.request.paymentInfo.ccType);
  }

  onReissueToggleChanged = () => {
    this.reissue = !this.reissue;
    if (this.latestPullCredit && this.latestPullCredit.successful) {
      this.borrowers.forEach(borrower => {
        this.borrowerCreditRefNumbers[borrower.borrowerId] = borrower.latestCreditReport.refNumber;
      });
    }
    if (this.reissue) {
      this.actionButtonLabel = "Re-issue Credit";
    } else {
      if (this.filteredBorrowers.length > 0) {
        this.actionButtonLabel = "Re-run Credit";
      }
      else {
        this.actionButtonLabel = "Run Credit";
      }
    }
  }

  openReport = (integrationHistoryId: number) => {
    this.closeErrors();
    this._spinner.show();
    this._creditService.getReportContent(integrationHistoryId).subscribe(data => {
      this._spinner.hide();
      const blob = new Blob([data], { type: 'text/html' });
      const url = window.URL.createObjectURL(blob);
      window.open(url);
    }, error => {
      this._spinner.hide();
      this.showError(error.error.error.message);
    });
  }

  onReissueClicked = (integrationHistoryId: number) => {
    this.closeErrors();
    this._spinner.show();
    this._creditService.reissueCreditReport(integrationHistoryId).subscribe(result => {
      this._spinner.hide();
      if (result.success) {
        this.isSubmitSuccess = true;
        this.request.creditRequestType = "Reissue";
        this.reissueSucceeded.emit(integrationHistoryId);
      }

    }, error => {
      this._spinner.hide();
      this.showError(error.message);
    })
    this.pullingCreditReport = false;
  }

  openImportLiabilitiesTab = (creditInfoId: number) => {
    this.closeErrors();
    this.importLiabilitiesClicked.emit(creditInfoId);
  }

  numberOfReportsJointWith = (creditReport: CreditReport) => {
    if (!creditReport.joint) {
      return 1;
    }
    if (creditReport.joint) {
      const reportsThatAreJointwithThisOne = this.creditHistory.filter(r => r.refNumber === creditReport.refNumber && r.joint == creditReport.joint);
      return reportsThatAreJointwithThisOne.length;
    }
  }

  onPullCreditToggleChanged = () => {
    this.showRunCredit = false;
    if (!this.isPullEnabled) return;
    if (this.borrowers) {
      var borrowersToPullCredit = this.borrowers.filter(borrower => borrower.willPullCredit);
      this.borrowers.forEach(borrower => {
        if (borrower.willPullCredit) {
          this.showRunCredit = true;
        }
        // else {
        //   if (borrower.jointWithMortgageBorrowerId && borrowersToPullCredit.filter(x => x.mortgageBorrowerId == borrower.jointWithMortgageBorrowerId).length > 0)
        //     borrower.willPullCredit = true;
        //   else if(borrowersToPullCredit.filter(x => x.jointWithMortgageBorrowerId == borrower.mortgageBorrowerId).length > 0)
        //     borrower.willPullCredit = true;
        // }
      });
    }

    setTimeout(() => {
      this.setDefaultBureaus();
    }, 100)
  }

  onPullCreditClicked = () => {
    this.creditRunClicked = true;
    if (!this.creditRunForm) return;
    this.creditRunForm.form.markAllAsTouched();
    if (!this.creditRunForm.form.valid) return;

    this.saveMortgageAndPullCredit();
  }

  onPullTypeChanged = (pullType: string) => {
    if (pullType != 'Indiviual') {
      const primaryBorrower = this.borrowers.find(borrower => this.leadId ? (borrower.borrowerType == BorrowerType.PrimaryBorrower) : borrower.isPrimary);
      if (primaryBorrower) {
        this.request.creditReferenceNumber = primaryBorrower.latestCreditReport.refNumber;
      } else {
        this.request.creditReferenceNumber = this.borrowers[0].latestCreditReport.refNumber;
      }
    }
  }

  closeErrors = () => {
    this.isSubmitSuccess = false;
    this.isSubmitError = false;
    this.validationErrors = [];
    this.errorMessages = [];
  }

  openLoanDoc = (loanDocId: number) => {
    this.closeErrors();
    this._spinner.show();
    this._loanDocService.getLoanDoc(loanDocId).subscribe(result => {
      if (result && result.docFiles.length) {
        let fileGuid = result.docFiles[0].guid;
        this._loanDocService.viewPdfFile(fileGuid).subscribe(data => {
          this._spinner.hide();
          const blob = new Blob([data], { type: 'application/pdf' });
          const url = window.URL.createObjectURL(blob);
          window.open(url);
        });
      } else {
        this.showError("No File exist.");
      }
    }, error => {
      this.showError(error.errorMessage);
    })
  }

  onCreditVendorChanged = () => {
    if (this.request.credentialId === -1) {
      this.request.creditVendor = "";
      this.request.credentialId = null;
      this.addCreditVendor();
      return;
    }

    const creditVendor = this._creditVendors.find(cv => cv.credentialId === this.request.credentialId);
    const creditVendorName = creditVendor.vendorName as CreditVendorEnum;

    this.request.creditVendor = creditVendorName;

    this.setDefaultBureaus();
  }

  onExpirationDateChanged = () => {
    if (this.request.paymentInfo.ccExpirationDate.length === 2) {
      this.request.paymentInfo.ccExpirationDate += "/";
    } else if (this.request.paymentInfo.ccExpirationDate.length > 7) {
      this.request.paymentInfo.ccExpirationDate = this.request.paymentInfo.ccExpirationDate.slice(0, -1);
      this.request = { ...this.request };
    }

    if (this.request.paymentInfo.ccExpirationDate?.length === 7) {
      const isValid = Constants.regexPatterns.monthYear.test(this.request.paymentInfo.ccExpirationDate);
      if (isValid) this.ccExpirationDate.control.setErrors(null);
    }
  }

  onExpirationDateBlurred = () => {
    if (!this.request.paymentInfo?.ccExpirationDate && this.ccExpirationDate.control.hasValidator(Validators.required)) {
      this.ccExpirationDate.control.setErrors({ 'required': true });
      return;
    }

    if (this.request.paymentInfo?.ccExpirationDate.split("/")[0] && +this.request.paymentInfo.ccExpirationDate.split("/")[0] > 12) {
      this.ccExpirationDate.control.setErrors({ 'invalid': true });
      return;
    }

    if (this.request.paymentInfo?.ccExpirationDate.split("/")[1]?.length < 4) {
      this.ccExpirationDate.control.setErrors({ 'invalid': true });
      return;
    }

    if (this.request.paymentInfo?.ccExpirationDate.split("/")[1]?.length === 4) {
      const isValid = Constants.regexPatterns.monthYear.test(this.request.paymentInfo.ccExpirationDate);
      if (!isValid) {
        this.ccExpirationDate.control.setErrors({ 'invalid': true });
        return;
      };
    }

    this.ccExpirationDate.control.setErrors(null);
  }

  onCardNumberChanged = () => {
    this.getCreditCardType(this.request.paymentInfo.payerAccount);
  }

  onCardTypeChanged = () => {
    this.request.paymentInfo.payerAccount = null;
    this.getMaskType(this.request.paymentInfo.ccType);
  }

  private pullCredit = () => {
    if (this.reissue) {
      this.pullReport("Reissue");
    } else {
      this.request.creditReferenceNumber = null;
      this.pullReport("Submit");
    }
  };

  private pullReport = (creditRequestType: string) => {
    this._spinner.show();
    this.request.creditRequestType = creditRequestType;

    if (!this.request.credentialId) {
      this.pullingCreditReport = false;
      this._spinner.hide();
      this._notifyService.showError('Credit Vendor Credential ID is not set. Cannot run credit without a credential Id. ', 'Error');
      return;
    }

    if (this.request.pullType == 'Individual') {
      const apiCallsToMake: any[] = [];

      this.borrowers.filter(b => b.willPullCredit).forEach(borrower => {
        const borrowerRequest = { ...this.request };
        borrowerRequest.borrowerIds = [borrower.borrowerId];

        if (
          borrowerRequest.creditVendor == CreditVendor.MeridianLinkHardPull
          && this.latestPullCredit?.creditVendor === CreditVendorEnum.MeridianLinkSoftPull
          && this.latestPullCredit?.refNumber === borrowerRequest.creditReferenceNumber
        ) {
          borrowerRequest.creditReferenceNumber = null;
        } else if (borrowerRequest.pullType == 'Individual') {
          borrowerRequest.creditReferenceNumber = (creditRequestType === 'Reissue' ? this.borrowerCreditRefNumbers[borrower.borrowerId] : null);
        }

        const pullReportForBorrower = this._creditService.pullReport(borrowerRequest);
        apiCallsToMake.push(pullReportForBorrower);
      });

      if (apiCallsToMake.length > 0) {
        forkJoin(apiCallsToMake).subscribe({
          next: (response) => {
            if (response && response.every(r => r.success)) {
              this.isSubmitSuccess = true;
              this.isSubmitError = false;

              if (!this.leadId) {
                this.applicationContextService.reloadApplicationAndMortgagePostAction(this.applicationId).subscribe();
              }

              this.creditRunSuccessfullyCompleted.emit();
            } else {
              this.isSubmitSuccess = false;
              this.isSubmitError = true;
              response.forEach(r => {
                if (r.hasError) {
                  if (r.validationErrors && r.validationErrors.length) {
                    this.validationErrors = this.validationErrors.concat(r.validationErrors);
                  }
                  if (r.errorMessage) {
                    this.errorMessages.push(r.errorMessage);
                  }
                }
              })
            }
            this.creditRunCompleted.emit(this.isSubmitSuccess);
          },
          error: (error) => {
            const errorToShow = error?.message || 'An error occurred while running credit.';
            this.isSubmitSuccess = false;
            this.showError(errorToShow);
            this._notifyService.showError(errorToShow, "Error!");
          }
        }).add(() => {
          this.pullingCreditReport = false;
          this._spinner.hide()
        })
      }
    } else {
      const jointRequest = { ...this.request, borrowerIds: [] };

      this.borrowers.filter(b => b.willPullCredit).forEach(borrower => {
        jointRequest.borrowerIds.push(borrower.borrowerId);
      });

      if (
        jointRequest.creditVendor == CreditVendor.MeridianLinkHardPull
        && this.latestPullCredit?.creditVendor === CreditVendorEnum.MeridianLinkSoftPull
        && this.latestPullCredit?.refNumber === jointRequest.creditReferenceNumber
      ) {
        jointRequest.creditReferenceNumber = null;
      }

      this._creditService.pullReport(jointRequest).subscribe({
        next: (result) => {
          if (result.success) {
            this.isSubmitSuccess = true;
            this.isSubmitError = false;
            this.applicationContextService.reloadApplicationAndMortgagePostAction(this.applicationId).subscribe();
            this.creditRunSuccessfullyCompleted.emit();
          } else {
            if (result.errorMessage) {
              this.isSubmitSuccess = false;
              this.showError(result.errorMessage);
            } else if (result.validationErrors && result.validationErrors.length > 0) {
              this.validationErrors = result.validationErrors;
            }
          }
          this.creditRunCompleted.emit(this.isSubmitSuccess);
        },
        error: (error) => {
          const errorToShow = error?.message || 'An error occurred while running credit.';
          this.isSubmitSuccess = false;
          this.showError(errorToShow);
          this._notifyService.showError(errorToShow, "Error!");
        }
      }).add(() => {
        this.pullingCreditReport = false;
        this._spinner.hide();
      });
    }
  }

  private showError = (message: string) => {
    this.isSubmitError = true;
    this.errorMessages.push(message);
  }

  private prepareCreditVendorsDropdown = () => {
    this._creditVendors.forEach(vendor => {
      for (let enumMember in CreditVendor) {
        let isValueProperty = parseInt(enumMember, 10) >= 0;
        if (isValueProperty) {

          let enumLiteral = Number(enumMember);

          if (vendor.vendorName == this.getVendorEnumNameByLiteral(enumLiteral)) {
            vendor.vendorEnum = enumLiteral;
            vendor.vendorDisplayName = `${vendor.alias ? vendor.alias + ' -' : ''} ${this.splitCamelCaseString(vendor.vendorName)}`;
          }
        }
      }
    })
  }

  private setReissueFieldForTpo = () => {
    this._tpoConfigService.getTpoConfigs()
      .subscribe({
        next: (tpoConfigs) => {
          if (tpoConfigs.creditPermissions == TpoCreditPermissions.ReissueOnly) {
            this.reissue = true;
          }
        },
        error: (err) => {
          this.showError(err.message || err);
        }
      })
  }

  private getCreditCardType(creditCardNumber) {
    if (/^5[1-5]/.test(creditCardNumber)) {
      this.request.paymentInfo.ccType = CreditCardType.MasterCard;
    }
    if (/^4/.test(creditCardNumber)) {
      this.request.paymentInfo.ccType = CreditCardType.Visa;
    }
    if (/^3[47]/.test(creditCardNumber)) {
      this.request.paymentInfo.ccType = CreditCardType.AmericanExpress;
    }
    if (/6(?:011|5[0-9]{2})[0-9]{12}/.test(creditCardNumber)) {
      this.request.paymentInfo.ccType = CreditCardType.Discover;
      this.request = { ...this.request };
    }
  }

  private setDefaultBureaus = () => {
    if (!this.creditVendorComponent) return;

    const selectedCreditVendor = this._creditVendors.find(cv => cv['vendorDisplayName'] === this.creditVendorComponent.nativeElement?.selectedOptions[0]?.innerText.trim());
    if (!selectedCreditVendor) {
      this.showSoftPullReportType = false;
      return;
    }

    this.request.reportType = null;

    if (selectedCreditVendor.vendorName === CreditVendorEnum.MeridianLinkSoftPull) {
      this.request.reportType = "SoftPull";
    }
    if (selectedCreditVendor.vendorName === CreditVendorEnum.MeridianLinkHardPull) {
      this.request.reportType = "Merge";
    }

    this.showSoftPullReportType = selectedCreditVendor.vendorName == CreditVendorEnum.Xactus;

    this.request.experian = selectedCreditVendor.thirdPartyKeyValuePairs?.find(el => el.key === 'ExperianDefault')?.value === "true";
    this.request.equifax = selectedCreditVendor.thirdPartyKeyValuePairs?.find(el => el.key === 'EquifaxDefault')?.value === "true";
    this.request.transUnion = selectedCreditVendor.thirdPartyKeyValuePairs?.find(el => el.key === 'TransunionDefault')?.value === "true";
  }

  private getVendorEnumNameByLiteral = (literal: number) => {
    return CreditVendor[literal];
  }

  private splitCamelCaseString = (str: string): string => {
    return str.replace(/([a-z0-9])([A-Z])/g, '$1 $2');
  }

  private getMaskType(cardType: CreditCardType) {
    const masks: { [key in CreditCardType]: string } = {
      'MasterCard': '9999 9999 9999 9999',
      'Visa': '9999 9999 9999 9999',
      'AmericanExpress': '9999 999999 99999',
      'Discover': '9999 9999 9999 9999',
    };

    this.creditCardMask = masks[cardType] || '9999 9999 9999 9999';
    this.request = { ...this.request };
  }

  private saveMortgageAndPullCredit = () => {
    //@Kaan What is this and why are we saving mortgage data from the services tab?
    this.closeErrors();
    this.pullingCreditReport = true;
    this._spinner.show();

    if (this.mortgage) {
      this._mortgageService.saveMortgage(this.mortgage).pipe(
        map(response => {
          this.mortgage = response;
          this.applicationContextService.updateMortgage(this.mortgage);
        }))
        .subscribe(() => {
          this.pullCredit();
        }, error => {
          this._spinner.hide();
          this._notifyService.showError(error ? error.message : "An error occurred while saving mortgage.", "Error!");
        });
    } else {
      this.pullCredit();
    }
  }

  loadTechnicalAffiliates = () => {
    this._loanServicesService
      .getTechnicalAffiliates('MeridianLinkHardPull')
      .subscribe({
        next: (result) => {
          this.technicalAffiliates = result
            .filter(el => el.mlcid && el.code);
        }, error: (err) => {
          this._notifyService.showError(err?.message || 'Unable to load technical affiliates', 'Credit Pull'
          );
        }
      });
  }

  protected addCreditVendor = () => {
    const modalRef = this._modalService.open(CreditModalComponent, Constants.modalOptions.large);
    let credential = this._thirdPartyCredentialsService.getNewCredential('CreditVendor');
    credential.channels = this.applicationContext.application.channel;
    modalRef.componentInstance.scope = "User";
    modalRef.componentInstance.credential = JSON.parse(JSON.stringify(credential));
    modalRef.componentInstance.technicalAffiliates = this.technicalAffiliates;
    modalRef.componentInstance.disabledChannel = true;

    modalRef.result.then(
      (result) => {
        this.creditVendors.unshift(result);
        this.creditVendors = [...this.creditVendors];
        this.request.creditVendor = result.vendorName;
        this.request.credentialId = result.credentialId;
      },
      () => { }
    );
  }
}

