import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { StackingOrder } from 'src/app/models/config/stacking-order.model';
import { NotificationService } from 'src/app/services/notification.service';
import { StackingOrderService } from '../../services/stacking-order.service';

@Component({
  selector: 'app-stacking-order-details-dialog',
  templateUrl: './stacking-order-details-dialog.component.html',
  styleUrls: ['./stacking-order-details-dialog.component.scss']
})
export class StackingOrderDetailsDialogComponent implements OnInit {

  @ViewChild("stackingOrderForm") stackingOrderForm: NgForm | undefined;

  get stackingOrder(): StackingOrder {
    return this._stackingOrder;
  }

  @Input()
  set stackingOrder(value: StackingOrder) {
    this._stackingOrder = value;
    if (value) {
      this.stackingOrderData = _.cloneDeep(value);
    }
  }

  stackingOrderData: StackingOrder = new StackingOrder();
  isSaving: boolean = false;

  private _stackingOrder: StackingOrder;

  constructor(
    public activeModal: NgbActiveModal,
    private readonly _stackingOrderService: StackingOrderService,
    private readonly _notifyService: NotificationService
  ) {
  }

  ngOnInit(): void {
  }

  save = () => {

    if (this.stackingOrderForm) {

      this.stackingOrderForm.form.markAllAsTouched();

      if (this.stackingOrderForm.form.valid) {
        this.isSaving = true;

        if (this.stackingOrderData.stakingOrderId) {
          this._stackingOrderService.updateStackingOrder(this.stackingOrderData)
            .subscribe(() => {
              this.isSaving = false;
              this._notifyService.showSuccess("Update done successfully", "Success");
              this.activeModal.close(this.stackingOrderData);

            }, (err) => {
              this._notifyService.showError(err.error.message, "Error");
              this.isSaving = false;
            })
        }
        else {
          this._stackingOrderService.insertStackingOrder(this.stackingOrderData)
            .subscribe(() => {
              this.isSaving = false;
              this._notifyService.showSuccess("Insert done successfully", "Success");
              this.activeModal.close(this.stackingOrderData);

            }, (err) => {
              this._notifyService.showError(err.error.message, "Error");
              this.isSaving = false;
            })
        }


      }

    }

  }
}

