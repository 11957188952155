import { Component, OnDestroy, OnInit } from '@angular/core';
import { filter, firstValueFrom, fromEvent, Observable, Subscription } from 'rxjs';
import * as _ from 'lodash';
import Swal from 'sweetalert2';
import { NavigationEnd, Router } from '@angular/router';
import { SystemLevelService } from '../../services/system-level.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from '../../services/auth.service';
import { ITimer, TimerResult } from '../../../utils/timer';
import { distinctUntilChanged, map, tap } from 'rxjs/operators';
import {
  CloseMessage,
  InitMessage,
  PauseMessage,
  PlayMessage,
  Tick$Message,
  Timeout$Message,
  TimerWorkerMessage,
  TimerWorkerMessageType,
} from '../../shared/workers/timer.worker';

// How many seconds before the timeout should the warning be displayed.
// This value must be less than the timeout. Otherwise, the warning
// might not be displayed.
const _secondsBeforeTimeoutWarning = 20;
const _actionEvents = Object.freeze([
  'mousemove',
  'click',
  'mouseup',
  'mousedown',
  'keydown',
  'keypress',
  'keyup',
  'submit',
  'change',
  'mouseenter',
  'scroll',
  'resize',
  'dblclick',
]);
const _excludedRoutes = Object.freeze([
  'login',
  'register',
  'reset-password',
  'forgot-password',
  'email-preference-center',
  'feewise-wizard-complete',
  'lenderprice-mock',
  'sl',
  'ext-auth-callback',
  'tpo/esign-via-token',
  'admin/esign-via-token',
  'admin/esign-confirmation',
  'tpo/esign-confirmation',
]);
const _swalIdentifierClass = 'session-timeout-swal';

@Component({
  selector: 'session-timeout',
  template: `
    <session-timeout-sync
      *ngIf='isSynced'
      [timer]='timer'
    >
    </session-timeout-sync>
  `,
})
export class SessionTimeoutComponent implements OnInit, OnDestroy {
  private _timer: ITimer;
  protected get timer(): ITimer {
    return this._timer;
  }

  private _warningDuration: number | undefined;

  private _warningSubscription: Subscription | undefined;
  private _timeoutSubscription: Subscription | undefined;
  private _play$Subscription: Subscription | undefined;
  private _handleActionEvent: () => void;
  private _teardownTimer: (() => void) | undefined;

  private _isRouteExcluded: boolean = true;
  private _routerSubscription: Subscription;
  private _configurationSaveSubscription: Subscription;
  private _recentTabTimeoutSubscription: Subscription;

  protected get isSynced(): boolean {
    return this._timer != null && !this._isRouteExcluded;
  }

  constructor(
    private readonly _router: Router,
    private readonly _systemLevelService: SystemLevelService,
    private readonly _spinnerService: NgxSpinnerService,
    private readonly _authService: AuthService,
  ) {
  }

  ngOnInit(): Promise<void> {
    this._routerSubscription = this.subscribeToRouter();
    this._configurationSaveSubscription = this.subscribeToConfigurationSave();

    if (!this._isRouteExcluded) {
      return this.initTimer();
    }
  }

  ngOnDestroy(): void {
    this._configurationSaveSubscription?.unsubscribe();
    this._routerSubscription?.unsubscribe();
    this._recentTabTimeoutSubscription?.unsubscribe();
    this.destroyTimer();
  }

  private get _isDisplayingWarning(): boolean {
    if (!Swal.isVisible()) {
      return false;
    }

    return document.querySelector(
      `.swal2-container.${_swalIdentifierClass}`,
    ) != null;
  }

  private async logout(): Promise<void> {
    const spinnerService = this._spinnerService;
    const authService = this._authService;

    await spinnerService.show();

    await firstValueFrom(authService.logout());

    await spinnerService.hide();
  }

  private async initTimer(): Promise<void> {
    const sessionTimeout = await firstValueFrom(
      this._systemLevelService.getSystemLevelSessionTimeout(),
    );
    const timeout = sessionTimeout.value;
    if (timeout == null || timeout <= 0) {
      return;
    }

    console.assert(
      _.isInteger(timeout),
      '[sessionTimeout] must be an integer value in minutes.',
    );

    const timeoutSecs = timeout * 60;

    /**
     * Using a variable in the Worker constructor is not supported by webpack.
     * For example, the following code will not work:
     * @example
     * const url = new URL('./path/to/worker.ts', import.meta.url);
     * const worker = new Worker(url);
     *
     * @see https://webpack.js.org/guides/web-workers/
     */
    const worker = new Worker(new URL(
      '../../shared/workers/timer.worker',
      import.meta.url,
    ));
    this._timer = new TimerWorkerAdapter(worker, timeoutSecs);
    this._warningDuration = timeoutSecs - _secondsBeforeTimeoutWarning;

    this._warningSubscription = this.subscribeToWarning();
    this._timeoutSubscription = this.subscribeToTimeout();
    this._play$Subscription = this.subscribeToPlay$();
    this._teardownTimer = () => worker.terminate();

    this.addHandleActionEventListeners();

    if (!this._isRouteExcluded) {
      this._timer.play();
    }
  }

  private subscribeToWarning(): Subscription {
    return this._timer.tick$.pipe(
      map((value) => value >= this._warningDuration),
      distinctUntilChanged(),
      filter((shouldDisplay) => shouldDisplay),
    ).subscribe(() => {
      let timeoutSubscription: Subscription;
      let tickSubscription: Subscription;
      Swal.fire({
        html: `
          Your session will expire in <b>${this._timer.remaining}</b> seconds.
          Would you like to continue your session?`,
        customClass: {
          container: _swalIdentifierClass,
        },
        timerProgressBar: true,
        didOpen: () => {
          const b = Swal.getHtmlContainer().querySelector('b');

          const timer = this._timer;
          timeoutSubscription = timer.subscribe(() => {
            this.dismissWarning(Swal.DismissReason.timer);
          });

          tickSubscription = timer.tick$.pipe(
            map(() => timer.remaining.toString()),
          ).subscribe((value) => b.textContent = value);

          // Swal's timer is not used to avoid conflicts between Swal's timer
          // and the timer. Therefore, the progress bar is simulated here.
          const progressBar = Swal.getTimerProgressBar();
          progressBar.style.cssText =
            `display: flex;
             transition: width ${timer.remaining}s linear 0s;
             width: 100%;`;
          setTimeout(() => progressBar.style.width = '0');
        },
        icon: 'warning',
        confirmButtonText: 'Yes',
      }).then((result) => {
        timeoutSubscription.unsubscribe();
        tickSubscription.unsubscribe();

        if (result.dismiss !== Swal.DismissReason.timer) {
          this._timer.play(0);
        }
      });
    });
  }

  private subscribeToTimeout(): Subscription {
    return this._timer.pipe(
      filter(({ isCancelled }) => !isCancelled),
    ).subscribe(() => this.logout());
  }

  private subscribeToPlay$(): Subscription {
    return this._timer.play$.pipe(
      filter((from) => from < this._warningDuration),
    ).subscribe(() => this.dismissWarning());
  }

  private addHandleActionEventListeners(): void {
    const handle = this._handleActionEvent = () => {
      // Do not reset the timer if the warning is open, ignore the events.
      if (this._isDisplayingWarning) {
        return;
      }

      this._timer?.play(0);
    };
    _actionEvents.forEach((e) => document.addEventListener(e, handle));
  }

  private dismissWarning(reason?: Swal.DismissReason) {
    if (this._isDisplayingWarning) {
      Swal.close({
        isDismissed: true,
        dismiss: reason ?? Swal.DismissReason.cancel,
      });
    }
  }

  private subscribeToRouter(): Subscription {
    return this._router.events.pipe(
      filter((e) => e instanceof NavigationEnd),
    ).subscribe((e: NavigationEnd) => {
      const isRouteExcluded = _excludedRoutes.some((p) => e.url.startsWith(`/${p}`));
      this._isRouteExcluded = isRouteExcluded;

      if (isRouteExcluded) {
        this.destroyTimer();
      } else if (this._timer == null) {
        return this.initTimer();
      }
    });
  }

  private subscribeToConfigurationSave(): Subscription {
    return this._systemLevelService
      .configurationSave.pipe(
        filter(({ key }) => key === 'GetSystemTimeout'),
      ).subscribe(() => {
        this.dismissWarning();

        return this.resetTimer();
      });
  }

  private destroyTimer(): void {
    this._warningSubscription?.unsubscribe();
    this._timeoutSubscription?.unsubscribe();
    this._play$Subscription?.unsubscribe();
    this._teardownTimer?.();
    this._timer?.close();
    this._timer = null;

    const handle = this._handleActionEvent;
    if (handle != null) {
      _actionEvents.forEach((e) => document.removeEventListener(e, handle));
    }

    // This might be necessary if the user logs out programmatically from
    // outside of this component, after the warning has been displayed.
    this.dismissWarning();
  }

  private resetTimer(): Promise<void> {
    this.destroyTimer();
    return this.initTimer();
  }
}

class TimerWorkerAdapter extends Observable<TimerResult> implements ITimer {
  public readonly tick$: Observable<number>;
  public readonly play$: Observable<number>;

  private _result: TimerResult | undefined;

  get isExpired(): boolean {
    return this._result != null;
  }

  private _elapsed: number = 0;
  get elapsed(): number {
    return this._elapsed;
  }

  get remaining(): number {
    return this.duration - this._elapsed;
  }

  constructor(
    private readonly _worker: Worker,
    readonly duration: number,
  ) {
    super((subscriber) => {
      const subscription = message$.pipe(
        filter(({ type }) => type === TimerWorkerMessageType.timeout$),
        map((message: Timeout$Message) => message.result),
        tap((value) => this._result = value),
      ).subscribe(subscriber);

      return () => {
        this.pause();
        subscription.unsubscribe();
      };
    });

    this.postMessage(new InitMessage(duration));

    const message$ = fromEvent<MessageEvent<TimerWorkerMessage>>(
      _worker,
      'message',
    ).pipe(map(({ data }) => data));

    this.tick$ = message$.pipe(
      filter(({ type }) => type === TimerWorkerMessageType.tick$),
      map((message: Tick$Message) => message.elapsed),
      tap((value) => this._elapsed = value),
    );

    this.play$ = message$.pipe(
      filter(({ type }) => type === TimerWorkerMessageType.play$),
      map((message: PlayMessage) => message.from),
      tap((value) => this._elapsed = value),
    );
  }

  private postMessage(message: TimerWorkerMessage) {
    this._worker.postMessage(message);
  }

  play(from?: number): void {
    this.postMessage(new PlayMessage(from));
  }

  pause(): void {
    this.postMessage(new PauseMessage());
  }

  close(): void {
    this.postMessage(new CloseMessage());
  }
}
