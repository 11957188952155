<div class="modal-header">
    <h2 class="modal-title">Expression Builder</h2>
    <button type="button" class="btn-close" (click)="activeModal.close()" aria-label="Close"></button>
</div>
<div class="modal-body">
    <div>
        <div class="row">
            <div class="col-md-6">
                <div class="condition-btn">
                    <button class="btn" [ngClass]="{'btn-primary': expressionGroup.conditionalOperator === 'Or'}"
                        (click)="expressionGroup.conditionalOperator = 'Or'">OR</button>
                    <button class="btn" [ngClass]="{'btn-primary': expressionGroup.conditionalOperator === 'And'}"
                        (click)="expressionGroup.conditionalOperator = 'And'">AND</button>
                    <button class="btn" [ngClass]="{'btn-primary': expressionGroup.conditionalOperator === 'NotOr'}"
                        (click)="expressionGroup.conditionalOperator = 'NotOr'">NOT OR</button>
                    <button class="btn" [ngClass]="{'btn-primary': expressionGroup.conditionalOperator === 'NotAnd'}"
                        (click)="expressionGroup.conditionalOperator = 'NotAnd'">NOT AND</button>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="add-comparsion">
                    <button class="btn btn-primary" (click)="addExpression()">
                        <i class="fa fa-plus" aria-hidden="true"></i> Add Group
                    </button>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="main-groups-wrap">
                <div class="group-listing" *ngFor="let expression of expressionGroup.expressions; let index = index;">
                    <div class="group-inner">
                        <div class="inner-experssion-action">
                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="condition-btn">
                                        <button class="btn"
                                            [ngClass]="{'btn-primary': expression.conditionalOperator === 'Or'}"
                                            (click)="expression.conditionalOperator = 'Or'">OR</button>
                                        <button class="btn"
                                            [ngClass]="{'btn-primary': expression.conditionalOperator === 'And'}"
                                            (click)="expression.conditionalOperator = 'And'">AND</button>
                                        <button class="btn"
                                            [ngClass]="{'btn-primary': expression.conditionalOperator === 'NotOr'}"
                                            (click)="expression.conditionalOperator = 'NotOr'">NOT OR</button>
                                        <button class="btn"
                                            [ngClass]="{'btn-primary': expression.conditionalOperator === 'NotAnd'}"
                                            (click)="expression.conditionalOperator = 'NotAnd'">NOT AND</button>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="group-action">
                                        <button class="btn btn-primary" (click)="addExpressionTerm(expression)"><i
                                                class="fa fa-plus"></i> Add Rule</button>
                                        <a class="danger action delete ms-2"
                                            (click)="removeExpression(expressionGroup.expressions, index)"><i
                                                class="fa fa-trash-alt text-danger align-middle"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="group-wrap">
                            <div class="row expression-lising"
                                *ngFor="let expressionTerm of expression.expressionTerms; let termIndex = index;">
                                <div class="col-sm-11">
                                    <div class="row expression-input">
                                        <div class="col-sm-4">
                                            <ng-select width="100%" bindLabel="nameToShow" appendTo="body"
                                                bindValue="columnDefinition.name" placeholder="Select One"
                                                name="fieldPath_{{termIndex}}" [closeOnSelect]="true"
                                                (change)="handleExpressionTermChange(expressionTerm)"
                                                [(ngModel)]="expressionTerm.field.fieldPath" [items]="fields">
                                                <ng-template ng-option-tmp let-item="item">
                                                    <span [title]="item.nameToShow">{{item.nameToShow}}</span>
                                                </ng-template>
                                            </ng-select>
                                        </div>
                                        <div class="col-sm-3">
                                            <ng-template [ngIf]="expressionTerm.loadingOperators"
                                                [ngIfElse]="expressionOperator">
                                                <span class="spinner-border spinner-border-sm" role="status"
                                                    aria-hidden="true"></span>
                                            </ng-template>
                                            <ng-template #expressionOperator>
                                                <select class="form-select" title="{{expressionTerm.operatorType}}"
                                                    [hidden]="!expressionTerm.field.fieldPath"
                                                    [(ngModel)]="expressionTerm.operatorType"
                                                    [ngModelOptions]="{ standalone: true }"
                                                    (change)="operatorChanged(expressionTerm)">
                                                    <option [value]="null">-- Select One --</option>
                                                    <option
                                                        *ngFor="let operator of expressionTerm.operators | sort : 'name'"
                                                        value={{operator.value}}>{{operator.name}}
                                                    </option>
                                                </select>
                                            </ng-template>
                                        </div>

                                        <div class="col-sm-5" [hidden]="!expressionTerm.operatorType"
                                            *ngIf="['IsEmpty', 'NotEmpty'].indexOf(expressionTerm.operatorType) === -1">
                                            <ng-template [ngIf]="expressionTerm.operatorType === 'Between'"
                                                [ngIfElse]="notBetween">
                                                <div class="row">
                                                    <span class="col-md-6">
                                                        <custom-data-field [selectFieldTypes]='selectFieldTypes'
                                                            [dataType]="expressionTerm.field.fieldType === 'None' ? expressionTerm.field.dataType : expressionTerm.field.fieldType"
                                                            [model]="expressionTerm.values[0]"
                                                            (valueChanged)="expressionTerm.values[0] = $event?.toString()"></custom-data-field>
                                                    </span>
                                                    <span class="col-md-6">
                                                        <custom-data-field [selectFieldTypes]='selectFieldTypes'
                                                            [dataType]="expressionTerm.field.fieldType === 'None' ? expressionTerm.field.dataType : expressionTerm.field.fieldType"
                                                            [model]="expressionTerm.values[1]"
                                                            (valueChanged)="expressionTerm.values[1] = $event?.toString()"></custom-data-field>
                                                    </span>
                                                </div>
                                                <!-- dataType = 'Time' -->
                                                <div class="row m-t-1"
                                                    *ngIf="expressionTerm.field.dataType === 'TimeZone'">
                                                    <span class="col-md-6">
                                                        <custom-data-field [selectFieldTypes]='selectFieldTypes'
                                                            [dataType]="'TimeZone'" [model]="expressionTerm.values[0]"
                                                            (valueChanged)="expressionTerm.values[0] = $event?.toString()">
                                                        </custom-data-field>
                                                    </span>
                                                    <span class="col-md-6">
                                                        <custom-data-field [selectFieldTypes]='selectFieldTypes'
                                                            [dataType]="'TimeZone'" [model]="expressionTerm.values[1]"
                                                            (valueChanged)="expressionTerm.values[1] = $event?.toString()">
                                                        </custom-data-field>
                                                    </span>
                                                </div>
                                            </ng-template>

                                            <ng-template #notBetween>
                                                <div class="row">
                                                    <ng-container
                                                        *ngIf="expressionTerm.field.dataType === 'TimeZone'; else notTime">
                                                        <span class="col-md-12">
                                                            <custom-data-field [selectFieldTypes]='selectFieldTypes'
                                                                [dataType]="'TimeZone'"
                                                                [model]="expressionTerm.values[0]"
                                                                (valueChanged)="expressionTerm.values[0] = $event?.toString()">
                                                            </custom-data-field>
                                                        </span>
                                                    </ng-container>
                                                    <ng-template #notTime>
                                                        <div
                                                            *ngIf="['Today', 'IsAnniversary'].indexOf(expressionTerm.operatorType) === -1">
                                                            <ng-template
                                                                [ngIf]='CustomDataUtils.isMultiSelectOperatorType(expressionTerm.operatorType)'
                                                                [ngIfElse]="expressionNotInNot">
                                                                <ng-template
                                                                    [ngIf]="!selectFieldTypes.has(expressionTerm.field.fieldType) &&
                                                                    !(expressionTerm.field.dataType == 'Enum' && expressionTerm.field.fieldType == 'Enum' && expressionTerm.field.lodasoftFullName && isLOSConfig)"
                                                                    [ngIfElse]="inMultiSelect">
                                                                    <tag-input theme='bootstrap'
                                                                        [inputClass]="'input-tags-overrider form-control'"
                                                                        [ngModel]="expressionTerm.tags"
                                                                        [ngModelOptions]="{standalone: true}"
                                                                        [placeholder]="'Enter tag and hit enter'"
                                                                        [secondaryPlaceholder]="'Enter tag and hit enter'"
                                                                        (onAdd)="addTag($event, expressionTerm)"
                                                                        (onRemove)="removeTag($event, expressionTerm)">
                                                                    </tag-input>
                                                                </ng-template>
                                                                <ng-template #inMultiSelect>
                                                                    <custom-data-field
                                                                        [selectFieldTypes]='selectFieldTypes'
                                                                        [dataType]="expressionTerm.field.fieldType === 'None' ? expressionTerm.field.dataType : expressionTerm.field.fieldType"
                                                                        [model]="expressionTerm"
                                                                        [operatorType]="expressionTerm.operatorType"
                                                                        [lodasoftFullName]="expressionTerm.field.lodasoftFullName">
                                                                    </custom-data-field>
                                                                </ng-template>
                                                            </ng-template>
                                                            <ng-template #expressionNotInNot>
                                                                <number-input
                                                                    *ngIf="['xPreviousDays', 'xNextDays', 'xPreviousHours', 'xNextHours'].includes(expressionTerm.operatorType); else nonNumber"
                                                                    [(ngModel)]='expressionTerm.values[0]'
                                                                    [ngModelOptions]='{ standalone: true }'></number-input>
                                                                <ng-template #nonNumber>
                                                                    <custom-data-field
                                                                        (valueChanged)='expressionTerm.values[0] = $event?.toString()'
                                                                        [dataType]="expressionTerm.field.fieldType === 'None' ? expressionTerm.field.dataType : expressionTerm.field.fieldType"
                                                                        [model]='expressionTerm.values[0]'
                                                                        [selectFieldTypes]='selectFieldTypes'
                                                                        [lodasoftFullName]="expressionTerm.field.lodasoftFullName"></custom-data-field>
                                                                </ng-template>
                                                            </ng-template>
                                                        </div>
                                                    </ng-template>
                                                </div>
                                            </ng-template>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-1">
                                    <div class="expression-actions">
                                        <a class="danger action delete"
                                            (click)="removeExpressionTerm(expression, termIndex)">
                                            <i class="fa fa-trash-alt text-danger"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <div class="button-items">
        <button type="button" class="btn btn-secondary" (click)="activeModal.close('cancel')">Cancel</button>
        <button type="button" class="btn btn-danger"
            *ngIf="showDeleteBtn && expressionBuilderData?.expressionGroup?.expressionGroupId" (click)="delete()"
            [disabled]="saving || deleting">
            <span *ngIf="!saving && !deleting"> Delete </span>
            <span *ngIf="saving || deleting" class="spinner-border spinner-border-sm" role="status"></span>
        </button>
        <button type="button" class="btn btn-primary" (click)="save()" [disabled]="saving || deleting">
            <span *ngIf="!saving && !deleting"> Accept Changes </span>
            <span *ngIf="saving || deleting" class="spinner-border spinner-border-sm" role="status"></span>
        </button>
    </div>
</div>