<ng-container *ngIf="!loadingSystemData; else loadingContent">
    <div class='fieldset-title mb-3'>Styling</div>
    <custom-css-controls [config]='customCssControlsConfig'>
    </custom-css-controls>

    <div class="fieldset-title mt-5">About Us</div>
    <div class="pt-3">
        <div class="row mb-3">
            <div class="col-md-8 offset-2">
                <rich-text-editor [name]="'aboutUsContent'" [(ngModel)]="borrowerPortalContent.aboutUsContent">
                </rich-text-editor>
            </div>
        </div>
    </div>

    <div class="fieldset-title mt-5">Referral</div>
    <div class="pt-3">
        <div class="row mb-3">
            <div class="col-md-8 offset-2">
                <rich-text-editor [name]="'referralContent'" [(ngModel)]="borrowerPortalContent.referralContent">
                </rich-text-editor>
            </div>
        </div>
    </div>

    <div class="fieldset-title mt-5">Referral Terms and Conditions</div>
    <div class="pt-3">
        <div class="row mb-3 form-group">
            <div class="col-md-2 text-end">
                <label class="form-check-label" for="showReferralTerms">
                    Show Referral Terms and Conditions Link
                </label>
            </div>
            <div class="col-md-10">
                <div class="form-check form-check-inline">
                    <input class="form-check-input" id="showReferralTerms" type="checkbox"
                        [(ngModel)]="borrowerPortalContent.showReferralTerms">
                </div>
            </div>
            <div class="col-md-8 offset-2" *ngIf="borrowerPortalContent.showReferralTerms">
                <rich-text-editor [name]="'referralTermsContent'"
                    [(ngModel)]="borrowerPortalContent.referralTermsContent">
                </rich-text-editor>
            </div>
        </div>
    </div>

    <div class="fieldset-title mt-5">Others</div>
    <div class="pt-3">
        <div class="row mb-3 form-group">
            <div class="col-md-2 text-end">
                <label class="form-check-label" for="isGoogleAddressesIntegrationDisabled">
                    Disable Google Addresses Integration
                </label>
            </div>
            <div class="col-md-1">
                <div class="form-check form-check-inline">
                    <input class="form-check-input" id="isGoogleAddressesIntegrationDisabled" type="checkbox"
                        [(ngModel)]="borrowerPortalContent.isGoogleAddressesIntegrationDisabled">
                </div>
            </div>
        </div>
        <div class="row mb-3 form-group">
            <div class="col-md-2 text-end">
                <label class="form-check-label" for="showTitleInPreferenceToRoleName">
                    Show Title Preferred to Role Name
                </label>
            </div>
            <div class="col-md-1">
                <div class="form-check form-check-inline">
                    <input class="form-check-input" id="showTitleInPreferenceToRoleName" type="checkbox"
                        [(ngModel)]="borrowerPortalContent.showTitleInPreferenceToRoleName">
                </div>
            </div>
        </div>
    </div>

    <div class="text-end">
        <div class="col-md-12 my-1">
            <hr />
        </div>
        <button type="submit" class="btn btn-primary pull-right" [disabled]="isSaving" (click)="save()">
            <span *ngIf="!isSaving"><i class="fa fa-save me-2"></i> Save</span>
            <span *ngIf="isSaving">
                <span class="spinner-border spinner-border-sm"></span> Saving
            </span>
        </button>
    </div>
</ng-container>
<ng-template #loadingContent>
    <loading-indicator loadingMessage="Loading Borrower Portal Settings..."
        [customClass]="'init-loading-indicator'"></loading-indicator>
</ng-template>