import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataService } from 'src/app/core/services/data.service';
import { RoggoraDocType } from '../models/reggora-doc-type';

@Injectable({
  providedIn: 'root'
})
export class ReggoraService {

  constructor(private readonly _dataService: DataService) { }

  public getReggoraToken = (applicationId: number): Observable<string> => {
    return this._dataService.post(`api/reggora/sso-token?applicationId=${applicationId}`, null, null, {
      responseType: "text"
    });
  }

  public getReggoraIframeURL = (applicationId: number): Observable<string> => {
    return this._dataService.post(`api/reggora/iframe-url?applicationId=${applicationId ? applicationId : ''}`,null, null, {
      responseType: "text"
    });
  }

  getAllReggoraDocTypes = (credentialId: number): Observable<RoggoraDocType[]> => {
    let url = `api/reggora/doc-type/${credentialId}`;
    return this._dataService.get(url);
  }

  upsertReggoraDocType = (credentialId: number, data: RoggoraDocType): Observable<RoggoraDocType> => {
    let url = `api/reggora/doc-type/${credentialId}`;
    return this._dataService.post(url,data);
  }

  deleteReggoraDocType = (credentialId: number, reggoraDocTypeId: number): Observable<any> => {
    let url = `api/reggora/doc-type/${credentialId}/${reggoraDocTypeId}`;
    return this._dataService.delete(url);
  }
}
