import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ContactsService } from '../../services/contacts.service';
import { Borrower } from "src/app/models/loan/borrower.model";
import { BorrowerFull } from 'src/app/modules/app-details/models/full-borrower.model';

@Component({
  selector: 'new-contact',
  templateUrl: './new-contact.component.html',
  styleUrls: ['./new-contact.component.scss']
})
export class NewContactComponent implements OnInit {

  @Input()
  isDrawer: boolean = false;

  @Output()
  closeDrawer: EventEmitter<never> = new EventEmitter<never>();

  @Output()
  contactCreated: EventEmitter<Borrower> = new EventEmitter<Borrower>();

  constructor(
    private readonly _contactsService: ContactsService,
  ) { }

  ngOnInit(): void {
  }

  onCloseDrawer = () => {
    this.closeDrawer.emit();
  }

  onBorrowerSaved = (fullBorrower: BorrowerFull) => {
    this._contactsService.updateContactsTable(fullBorrower.borrower);
    this.contactCreated.emit(fullBorrower.borrower);
  }
}
