import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Injector,
  Input,
  NgZone,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ContextMenuComponent, ContextMenuService } from 'ngx-contextmenu';
import { Table } from 'primeng/table';
import { EnvironmentService } from 'src/app/core/services/environment/environment.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { Utils } from 'src/app/core/services/utils';
import { Borrower, ContactList, Role } from 'src/app/models';
import { PipelineItem } from 'src/app/models/pipeline/pipeline-item.model';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { Column } from 'src/app/shared/models/table-config.model';
import { DrawerOptions, DrawerSize } from 'src/app/shared/services/drawer.service';
import { ApplicationService } from '../services/applications.service';
import { ApplicationTableConfig } from './application-table-config.model';
import { MultiSelect } from 'primeng/multiselect';
import Swal, { SweetAlertResult } from "sweetalert2";
import { NotificationService } from "../../../services/notification.service";
import { NgxSpinnerService } from 'ngx-spinner';
import { ApplicationMode, NavigationService } from 'src/app/services/navigation.service';

@Component({
  selector: 'application-table',
  templateUrl: './application-table.component.html',
  styleUrls: ['./application-table.component.scss'],
})
export class ApplicationTableComponent extends ApplicationContextBoundComponent implements OnInit, AfterViewInit {

  @Input()
  set applications(applications: PipelineItem[]) {
    applications.forEach(app => {
      app["applicationIdWithPadding"] = 1 + Utils.padLeft(app.applicationId + '', '0', 9);
      app["displayName"] = Utils.getPersonsDisplayName(app);

      if (!app.appCreateDate) {
        app.appCreateDate = app.dateInserted;
      }
      app.appCreateDate = new Date(app.appCreateDate);

      if (app.interestRate != null || app.interestRate != undefined) {
        app.interestRate = app.interestRate / 100;
      }
    })
    this._allApplications = applications;
    let filteredApplications = [...this._allApplications];
    this.filteredApplications = Utils.filter(this.globalFilterFields, this.globalSearchString, filteredApplications);
    setTimeout(() => {
      this.applicationTable.first = 0;
      this.applicationTable.firstChange.emit(this.applicationTable.first);
    }, 0);
  }

  @Input()
  applicationTableConfig: ApplicationTableConfig;

  @Output()
  addNewClicked: EventEmitter<PipelineItem> = new EventEmitter<PipelineItem>();

  @Output()
  loanDetailsClicked: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('dt1')
  applicationTable: Table;

  @ViewChild('contextMenuContacts', { static: true })
  public contextMenuContacts: ContextMenuComponent;

  @ViewChild('mobileColumnSelector')
  mobileColumnSelector: MultiSelect;

  selectedRows: PipelineItem[] = [];

  selectedColumns: Column[] = [];

  globalFilterFields: string[] = [];

  globalFilterFieldsX: string[] = [];

  globalSearchString: string = null;

  tableState: any;

  contactListType: string = 'Application';

  dialerEnabled: boolean = false;

  borrowers: Borrower[] = [];
  currentLoanContact;
  baseAvatarUrl: string;
  loanTeam: ContactList[] = null;

  addContactAppDrawerOptions: DrawerOptions = {
    size: DrawerSize.XXLarge,
    containerWrapperId: null
  }

  filteredApplications: PipelineItem[] = [];

  url: string = "";

  private _allApplications: PipelineItem[] = [];
  private _selectedColumnsLocalStorageKeyName: string = "applications-selectedColumns";

  constructor(
    private readonly injector: Injector,
    private readonly _environmentService: EnvironmentService,
    private readonly _service: ApplicationService,
    private readonly _navigationService: NavigationService,
    private readonly _contextMenuService: ContextMenuService,
    private readonly _cdr: ChangeDetectorRef,
    private zone: NgZone,
    private readonly _spinner: NgxSpinnerService,
    private readonly _localStorageService: LocalStorageService,
    private _notify: NotificationService
  ) {
    super(injector);
  }

  ngOnInit(): void {
    if (this.applicationTableConfig?.localStorageKey) {
      this._selectedColumnsLocalStorageKeyName = this.applicationTableConfig.localStorageKey;
    }
    this.scrollOffset = this.applicationTableConfig.scrollOffset;
    this.getScreenSize();
    this.baseAvatarUrl = this._environmentService.apiInfo.apiBaseUrl;
    if (this._navigationService.applicationMode == ApplicationMode.Classic) {
      this.url = "/admin/app-details/";
    } else {
      this.url = "/loda-nextgen/app-details/";
    }

    this.applicationTableConfig.columns.forEach((column) => {
      this.globalFilterFields.push(column.field);
      this.selectedColumns.push(column);
    });

    this.loadColumnsToDisplayOnTable();

    if (this.applicationTableConfig.extraGlobalFilterFields) {
      this.applicationTableConfig.extraGlobalFilterFields.forEach((field) => {
        this.globalFilterFields.push(field);
      });
    }

    this.applicationContextService.context.subscribe((context) => {
      this.dialerEnabled = context.userPermissions.dialerEnabled;
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.tableState = this._localStorageService.getItem('applications-table-state');
      this.selectedRows = [];
    });
  }

  onSelectedColumnsChanged = () => {
    if (this.selectedColumns) {
      this.selectedColumns.sort((a, b) => a.order - b.order);
      this._localStorageService.setItem(this._selectedColumnsLocalStorageKeyName, this.selectedColumns);
    }
  }

  onGlobalSearchStringChanged = (e: any) => {
    const globalSearchCache: any = {};
    globalSearchCache.globalSearchString = this.globalSearchString;
    this.filteredApplications = Utils.filter(this.globalFilterFields, this.globalSearchString, this._allApplications);
    this.applicationTable.first = 0;
  };

  onViewLoanDetailsClicked = (application: PipelineItem) => {
    this.loanDetailsClicked.emit(application);
  };

  onAddNewClicked = () => {
    this.addNewClicked.emit();
  };

  onShowLoanTeamClicked = ($event: MouseEvent, rowData: any) => {
    this.showLoanTeam($event, rowData);
  }

  setCurrentLoanContact = (player) => {
    this.currentLoanContact = player;
  }

  showLoanTeam($event: MouseEvent, rowData: any) {
    const self = this;
    this.loanTeam = null;
    this.updateLoanTeamOnContextMenu($event);

    $event.preventDefault();
    $event.stopPropagation();
    this._service.getInternalContacts(rowData.applicationId).subscribe({
      next: (result) => {
        result = result.filter((contact) => contact.userId);
        const contactRoles = this.applicationContext.globalConfig.roles.filter(x => x.isLoanContact == true);

        this.loanTeam = [];
        this.zone.run(() => {
          this.loanTeam = result
            .map((contact) => {
              let user = this.applicationContext.globalConfig.users.find(
                (x) => x.userCompanyGuid === contact.userId
              );
              let role: Role = contactRoles.find(
                (x) => x.roleId === contact.roleId
              );
              const name = `${user && user.firstName} ${user && user.lastName
                }`;
              const order = role && role.order;
              return {
                name: name,
                label: name,
                user: user,
                order: order,
                role: `${role && role.roleName}`
              };
            })

            .filter((user) => user.user && user.name);
          this.loanTeam.sort((a: any, b: any) => (a.order > b.order ? 1 : -1));
          this.updateLoanTeamOnContextMenu($event);
        });
      },
    });
  }

  getInternalContactsCount(internalContacts) {
    if (!internalContacts) return 0;
    return parseInt(internalContacts) - 1;
  }

  addedToDialList() {
    this.selectedRows = [];
  }

  openColumnSelectorForMobile = () => {
    setTimeout(() => {
      this.mobileColumnSelector?.containerViewChild?.nativeElement?.click();
    })
  }

  showDeleteDialog(rowData) {
    Swal.fire({
      title: 'Deleting Application',
      text: 'Would you like to delete the Lead associated with this Application?',
      icon: 'question',
      input: 'radio',
      inputOptions: {
        true: 'Yes',
        false: 'No'
      },
      showCancelButton: true,
      inputValidator: (value) => {
        if (!value) {
          return 'Please choose your answer.'
        }
      }
    }).then((res: SweetAlertResult) => {
      if (res.value) {
        Swal.fire({
          title: 'Deleting Application',
          text: 'Are you sure you want to delete this application. All data associated with this application will be removed. This action cannot be undone!',
          icon: 'question',
          input: 'text',
          html: `Please confirm by typing the <b>application number</b> as shown here: <b>${rowData.applicationIdWithPadding}</b>`,
          inputValue: '',
          showCancelButton: true,
          inputValidator: (value) => {
            if (!value) {
              return 'Please enter contact name.'
            }
            if (value !== rowData.applicationIdWithPadding) {
              return "Application number doesn't match, please try again."
            }
          }
        }).then((result: SweetAlertResult) => {
          if (!result.value) {
            return;
          }
          this.deleteRow(rowData, res.value === 'true');
        });
      }
    });

  }

  private deleteRow(application, deleteLeads) {
    this._spinner.show();
    this._service
      .delete(application.applicationId, deleteLeads)
      .subscribe(
        () => {
          this._allApplications = this._allApplications.filter(el => el.applicationId !== application.applicationId);
          this.filteredApplications = this.filteredApplications.filter(el => el.applicationId !== application.applicationId);
          this._spinner.hide();
          this._notify.showSuccess(
            "Application deleted successfully.",
            'Application'
          );
        },
        (error) => {
          this._spinner.hide();
          this._notify.showError(
            error && error.message ? error.message : (error.error ? error.error : "Couldn't delete application"),
            'Application'
          )
        }
      );
  }

  private updateLoanTeamOnContextMenu = ($event: any) => {
    this._cdr.detectChanges();
    this._contextMenuService.show.next({
      anchorElement: $event.target,
      contextMenu: this.contextMenuContacts,
      event: <any>$event,
      item: this.loanTeam,
    });
  }

  private loadColumnsToDisplayOnTable = () => {
    const selectedColumns = <[]>(
      this._localStorageService.getItem(this._selectedColumnsLocalStorageKeyName)
    );

    this.selectedColumns = [];
    if (!(selectedColumns && selectedColumns.length)) {
      this.applicationTableConfig.columns.forEach(column => {
        if (column.visible) {
          this.selectedColumns.push(column);
        }
      });
    } else {
      selectedColumns.forEach((column: any) => {
        this.applicationTableConfig.columns.forEach((c) => {
          if (column['field'] === c.field) {
            this.selectedColumns.push(c);
          }
        })
      });
    }

    this._localStorageService.setItem(this._selectedColumnsLocalStorageKeyName, this.selectedColumns);
    this.selectedColumns = [...this.selectedColumns];
  }
}
