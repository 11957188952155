import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { DataService } from 'src/app/core/services/data.service';
import { DocumentType } from 'src/app/models';
import { GlobalConfigService } from 'src/app/services/global-config.service';

@Injectable()
export class DocumentTypeService {
  constructor(
    private readonly _dataService: DataService,
    private readonly _globalConfigService: GlobalConfigService,
  ) {}

  // document type
  getDocumentTypes = (dbOnly: boolean = true): Observable<DocumentType[]> => {
    return this._dataService
      .get(`api/Admin/AllDocumentModel/${dbOnly}`);
  }

  insertDocumentType = (type: DocumentType): Observable<DocumentType> => {
    let url = `api/Admin/InsertDocumentModel`;

    return this._dataService.post(url, type).pipe(
      tap(() => this._globalConfigService.refreshConfigAsync())
    );
  }

  updateDocumentType = (type: DocumentType): Observable<any> =>{
    let url = `api/Admin/UpdateDocumentModel`;

    return this._dataService.post(url, type).pipe(
      tap(() => this._globalConfigService.refreshConfigAsync())
    );
  }

  deleteDocumentType = (id: number, replaceWithDocumentTypeId?: number): Observable<any> => {
    let url = `api/Admin/DeleteDocumentModel/${id}${replaceWithDocumentTypeId ? '?=replaceWithDocumentTypeId=' + replaceWithDocumentTypeId : ''}`;

    // I would clear cache here too but no company id in context and i'm not gonna go break all callers.
    return this._dataService.delete(url);
  }

  saveDocumentTypeOrder = (orders: string[]): Observable<any> => {
    let url = `api/Admin/UpdateDocumentOrder`;

    // Need a cache update? hope not, no companyId in scope.
    return this._dataService.post(url, orders.map(o => ({id: o})));
  }

}
