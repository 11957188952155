<div class="modal-header">
    <h5 class="modal-title">Edit Doc Type Mapping ({{mapping.syncDirection == 'ToReggora' ? 'To Reggora' : 'From
        Reggora'}})</h5>
    <button type="button" class="btn-close" (click)="activeModal.dismiss()" aria-label="Close"></button>
</div>
<div class="modal-body">
    <form #mappingForm="ngForm">
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label class="control-label">Title</label>
                    <div>
                        <input class="form-control" name="title" [(ngModel)]="editedDocType.title" #titleInput="ngModel"
                            [ngClass]="{'is-invalid' : titleInput && titleInput.touched && titleInput.invalid}" required />
                        <div class="invalid-feedback">Title is required.</div>
                    </div>
                </div>
                <div class="form-group">
                    <label class="form-label">Document Type</label>
                    <ng-select name="documentType" class="custom-ng-select" bindLabel="documentTypeName"
                        bindValue="documentTypeId" placeholder="Document Type" #docTypeControl="ngModel"
                        [(ngModel)]="editedDocType.documentTypeId"
                        [ngClass]="{'is-invalid' : docTypeControl && docTypeControl.touched && docTypeControl.invalid}"
                        [multiple]="false" [closeOnSelect]="false" [items]="docTypes" required></ng-select>
                    <div class="invalid-feedback">Document Type is required.</div>
                </div>
                <div class="form-group" *ngIf="mapping.syncDirection == 'ToLodasoft'">
                    <label class="control-label">Doc Name Contains Csv</label>
                    <div>
                        <tag-input theme='bootstrap' [placeholder]="'Enter new name'" [inputClass]="'form-control'"
                            [(ngModel)]="tags" name="reggoraDocNameContainsCsv">
                        </tag-input>
                        <em>Press 'enter' to add new tag.</em>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-check form-switch form-switch-success" *ngIf="mapping.syncDirection == 'ToLodasoft'">
                    <input class="form-check-input" type="checkbox" name="useForSubmissionPdf" id="useForSubmissionPdf"
                        [(ngModel)]="editedDocType.useForSubmissionPdf">
                    <label class="form-check-label" for="useForSubmissionPdf">Use For Submission Pdf </label>
                </div>
                <div class="form-check form-switch form-switch-success" *ngIf="mapping.syncDirection == 'ToLodasoft'">
                    <input class="form-check-input" type="checkbox" name="useForSubmissionXml" id="useForSubmissionXml"
                        [(ngModel)]="editedDocType.useForSubmissionXml">
                    <label class="form-check-label" for="useForSubmissionXml">Use For Submission Xml </label>
                </div>
                <div class="form-check form-switch form-switch-success" *ngIf="mapping.syncDirection == 'ToLodasoft'">
                    <input class="form-check-input" type="checkbox" name="useForSubmissionInvoice"
                        id="useForSubmissionInvoice" [(ngModel)]="editedDocType.useForSubmissionInvoice">
                    <label class="form-check-label" for="useForSubmissionInvoice">Use For Submission Invoice </label>
                </div>
                <div class="form-check form-switch form-switch-success" *ngIf="mapping.syncDirection == 'ToReggora'">
                    <input class="form-check-input" type="checkbox" name="useForPAndS" id="useForPAndS"
                        [(ngModel)]="editedDocType.useForPAndS">
                    <label class="form-check-label" for="useForPAndS">Use For P&S </label>
                </div>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer">
    <div class="button-items">
        <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss()">
            Close
        </button>
        <button type="button" class="btn btn-primary" (click)="save()">
            <span><i class="fa fa-save"></i> Save</span>
        </button>
    </div>
</div>