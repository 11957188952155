import { Component, HostListener, Injector, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { cloneDeep } from 'lodash';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { catchError, EMPTY, finalize, firstValueFrom, from, Observable, Subscription } from 'rxjs';
import { FeeSources } from 'src/app/models/fee/fee.model';
import { Constants } from 'src/app/services/constants';
import { LoanService } from 'src/app/services/loan';
import { ApplicationContextBoundComponent } from 'src/app/shared/components/application-context-bound.component';
import { DrawerComponent } from 'src/app/shared/components/drawer/drawer.component';
import { DrawerOptions, DrawerService, DrawerSize } from 'src/app/shared/services/drawer.service';
import Swal, { SweetAlertResult } from 'sweetalert2';
import {
  ApplicationContext,
  BaseKeyDate,
  LoanApplication,
  LosVendor,
  SubjectProperty,
  UserType,
} from '../../models';
import { FeeTemplate } from '../../models/fee/fee-template.model';
import { FeeService } from '../../services/fee.service';
import { MortgageService } from '../../services/mortgage.service';
import { NotificationService } from '../../services/notification.service';
import { UrlaMortgage } from '../urla/models/urla-mortgage.model';
import { MortgageCalculationService } from '../urla/services/mortgage-calculation.service';
import { EscrowFeesScheduleComponent } from './escrow-fees-schedule/escrow-fees-schedule.component';
import { FeesEditorComponent } from './fees-editor/fees-editor.component';
import { AddressUpdateModalComponent } from './modals/address-update-modal/address-update-modal.component';
import { PreCheckFeeWizardDialogComponent } from './pre-check-fee-wizard-dialog/pre-check-fee-wizard-dialog.component';
import { MortgageCalculationDetails } from '../../models/mortgage-calculation-details.model';
import { ActivatedRoute, Router } from '@angular/router';
import { LosService } from 'src/app/services/los.service';
import { AddFeeModalComponent } from '../fees-v2/add-fee-modal/add-fee-modal.component';
import { handleNonErrorDismissals, Utils } from '../../core/services/utils';
import { FeeContextService, SetFeesOptions } from '../fees-v2/services/fee-context.service';
import { FeeDisplayService } from '../fees-v2/services/fee-display.service';
import { FeeSectionType, LoanFee } from '../fees-v2/fees.model';
import { FeeUtils as FeeUtilsService } from './services/fee-utils.service';
import { FeeUtils } from '../fees-v2/utils/utils';
import { map, tap } from 'rxjs/operators';
import { AppDetailsService } from '../app-details/services/app-details.service';
import { ComponentCanDeactivate } from '../../core/route-guards/pending-changes.guard';
import { getErrorMessageOrDefault } from '../../shared/utils/error-utils';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import areFeesEqual = FeeUtils.areFeesEqual;

@Component({
  templateUrl: 'loan-fees.component.html',
  styleUrls: ['loan-fees.component.scss'],
  providers: [FeeContextService, FeeDisplayService],
})
export class LoanFeesComponent
  extends ApplicationContextBoundComponent
  implements OnInit, ComponentCanDeactivate {
  @ViewChild('feeForm')
  feeForm: NgForm | undefined;

  @ViewChild('feesEditor')
  feesEditor: FeesEditorComponent;

  @ViewChild('feesEditorDrawer')
  feesEditorDrawer: DrawerComponent;

  @ViewChild('escrowSchedule')
  feeScheduleEditor: EscrowFeesScheduleComponent;

  templates: FeeTemplate[];
  supportedVendorNames: string[] = [];

  protected fees: readonly LoanFee[] = [];

  /**
   * Reference to the original application object.
   * @protected
   */
  protected originalApplication: LoanApplication | null = null;
  /**
   * The application object that is being edited.
   * @protected
   */
  protected application: LoanApplication | null = null;
  /**
   * Reference to the original mortgage object.
   * @protected
   */
  protected originalMortgage: UrlaMortgage;
  /**
   * The mortgage object that is being edited.
   * @protected
   */
  protected mortgage: UrlaMortgage;
  protected calculationDetails: MortgageCalculationDetails;

  protected viewMode: ViewMode = ViewMode.FeeEdit;

  isSaving: boolean = false;
  //isRunningFeeWizard: boolean = false;
  isInitializing = false;
  allowRunFeeWizard: boolean = false;
  disableFeeWizard: boolean = false;
  showProformaLeGeneration: boolean = false;
  generatingLe: boolean = false;

  feeWizardRunDate: any;
  feeEditorDrawerTitle: string;
  selectedFeeForEditingDetails: LoanFee;

  escrowFees: LoanFee[] = [];

  feeEditorDrawerOptions: DrawerOptions = {
    size: DrawerSize.XXLarge,
    containerWrapperId: null,
  };

  escrowScheduleDrawerOptions: DrawerOptions = {
    size: DrawerSize.XLarge,
    containerWrapperId: 'drawer-wrapper',
  };

  protected showBasicInfoOnFeeEditor: boolean = false;
  protected isFeesV2Editable: boolean = true;

  protected hasChangedFees: boolean = false;

  protected leDocumentUrlToView: SafeResourceUrl | null = null;

  private _loanInfoChangesSubscription: Subscription;
  private _contextSubscription: Subscription;

  protected feeWizardTypeToUse: FeeWizardTypes = FeeWizardTypes.None;
  private _addFeeModalSubscription?: Subscription;
  private _feeChangesSubscription?: Subscription;

  private _generateProformaLeSubscription?: Subscription;
  private _routeSubscription?: Subscription;
  protected areZeroFeesHidden: boolean = false;

  protected isCheckingPricingStatus: boolean = false;

  private _hasInitializedAlready: boolean = false;

  constructor(
    injector: Injector,
    private readonly _feeService: FeeService,
    private readonly _notificationService: NotificationService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _losService: LosService,
    private readonly _modalService: NgbModal,
    private readonly _router: Router,
    private readonly _appDetailsService: AppDetailsService,
    private readonly _mortgageService: MortgageService,
    private readonly _loanService: LoanService,
    private readonly _mortgageCalculationsService: MortgageCalculationService,
    private readonly _drawerService: DrawerService,
    private readonly _feeContextService: FeeContextService,
    private readonly _feeDisplayService: FeeDisplayService,
    private readonly _feeUtilsService: FeeUtilsService,
    private readonly _sanitizer: DomSanitizer,
    private readonly _route: ActivatedRoute
  ) {
    super(injector);

    this._routeSubscription = this._route.queryParams.subscribe(params => {
      this.viewMode = ViewMode.FeeEdit;
      // If component has already initialized and we're here, it means we're coming from the fee wizard
      // and we need to re-initialize the component
      if (this._hasInitializedAlready) {
        this.ngOnInit();
      }
    });

    this._loanInfoChangesSubscription = this.applicationContextService.loanInfoChanges.subscribe(
      async context => {
        if (context.application && this.viewMode === ViewMode.FeeEdit || this.viewMode === ViewMode.RefreshFromLos) {
          await this.initialize(context);
        }
      }
    );

    this._contextSubscription = this.applicationContextService.context.subscribe(context => {
      const companyId = context.userPermissions.companyId;
      this.isFeesV2Editable = !context.applicationIsReadOnly && ![222, 229].includes(companyId);
      this.disableFeeWizard =
        context.application?.losVendor == 'MeridianLink' || [220].includes(companyId);
    });

    this._feeChangesSubscription = this._feeContextService.fees$.subscribe(fees => {
      this.fees = fees;
    });
    this._feeChangesSubscription.add(
      this._feeContextService.hasChanges$.subscribe(hasChanges => {
        this.hasChangedFees = hasChanges;
      })
    );
  }

  async ngOnInit() {
    this._hasInitializedAlready = true;
    if (this.applicationContext.application) {
      const queryParams = this._router.parseUrl(this._router.url).queryParams;
      if (queryParams && queryParams['pull'] === 'true') {
        const request = {
          FeeWizardRun: new Date().toISOString(),
        };
        try {
          await this._spinner.show();
          await firstValueFrom(
            this._loanService.saveKeyDatesByType(
              request,
              this.applicationContext.application.applicationId
            )
          );
          await this.autoSyncLosLoan();
        } catch (e) {
        } finally {
          await this._spinner.hide();
        }
      } else {
        await this.initialize(this.applicationContext);
        this.applicationContextService.updateApplicationTrackingStatuses();
      }
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this._loanInfoChangesSubscription?.unsubscribe();
    this._contextSubscription?.unsubscribe();
    this._addFeeModalSubscription?.unsubscribe();
    this._updateFeeValuesSubscription?.unsubscribe();
    this._feeChangesSubscription?.unsubscribe();
    this._generateProformaLeSubscription?.unsubscribe();
    this._routeSubscription?.unsubscribe();
  }

  private autoSyncLosLoan = async () => {
    this.isInitializing = true;
    try {
      const losAppOpResult = await firstValueFrom(
        this._losService.pullFromLos(this.applicationContext.application.applicationId)
      );
      this.applicationContextService.updateMortgageAndApplication(
        losAppOpResult.application?.mortgageLoan,
        losAppOpResult.application,
        losAppOpResult.customData
      );
    } catch (e) {
      console.error(e);
      await this.initialize(this.applicationContext);
    } finally {
      this.applicationContextService.updateApplicationTrackingStatuses();
      this.isInitializing = false;
    }
  };

  // @HostListener allows us to also guard against browser refresh, close, etc.
  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    // don't check when session expires
    if (!this.applicationContext) {
      return true;
    }

    return this.confirm();
  }

  confirm(): boolean | Observable<boolean> {
    return from(this.showNotSavedDialog());
  }

  private showNotSavedDialog = async (): Promise<boolean> => {
    if (!this.hasChangedFees) {
      return true;
    }

    const answer = await Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to save your changes before continuing?',
      icon: 'warning',
      reverseButtons: true,
      showCancelButton: true,
      showDenyButton: true,
      cancelButtonColor: '#A4ABC5',
      denyButtonColor: '#DC3741',
      cancelButtonText: 'Cancel',
      denyButtonText: 'Discard',
      confirmButtonText: 'Save',
    });

    if (answer.isDismissed) {
      return false;
    }

    if (answer.isConfirmed) {
      await this.saveFees();
    }

    return true;
  };

  /**
   * Sets the fee array to the given array of fees and updates the related properties.
   * @param {LoanFee[]} fees - The array of fees to set.
   * @param {Object} [options] - The options to use when setting the fees.
   * @param {boolean} [options.populateEscrowFees=true] - Whether to populate the escrow fees.
   * @param {boolean} [options.sortFees=false] - Whether to sort the fees.
   * Has no effect if {@link options.setContextFees} is set to false.
   * @param {boolean} [options.preserveOriginalFees=false] - Whether to preserve the original fees.
   * Has no effect if {@link options.setContextFees} is set to false.
   * @param {boolean} [options.setContextFees=true] - Whether to set the fees in the fee context
   * service.
   */
  private setFees(
    fees: LoanFee[],
    options?: {
      populateEscrowFees?: boolean;
      sortFees?: boolean;
      preserveOriginalFees?: boolean;
      setContextFees?: boolean;
    }
  ): void {
    const effectiveOptions = {
      populateEscrowFees: options?.populateEscrowFees ?? true,
      sortFees: options?.sortFees ?? false,
      preserveOriginalFees: options?.preserveOriginalFees ?? false,
      setContextFees: options?.setContextFees ?? true,
    };

    if (effectiveOptions.setContextFees) {
      const setFeesOptions: SetFeesOptions = {
        sortFees: effectiveOptions.sortFees,
        resetOriginalFees: !effectiveOptions.preserveOriginalFees,
        setMissingFeeProperties: true,
      };

      this._feeContextService.setFees(fees, setFeesOptions);
    }
    // this.fixPercentagesOnLoad();

    if (effectiveOptions.populateEscrowFees) {
      this.populateEscrowFees(this._feeContextService.fees);
    }
  }

  onFeeEditorDrawerOpened = (fee: LoanFee) => {
    this.showBasicInfoOnFeeEditor =
      fee.feeSection === FeeSectionType.Prepaids || fee.feeSection === FeeSectionType.Escrow;
    // noinspection JSIgnoredPromiseFromCall
    this._drawerService.hide('feesEditorDrawer', 10);

    if (this.selectedFeeForEditingDetails) {
      setTimeout(() => {
        this.feeEditorDrawerTitle = `${fee?.hudNumber ? fee.hudNumber + ':' : ''} ${fee.name}`;
        this.selectedFeeForEditingDetails = cloneDeep(fee);
        // noinspection JSIgnoredPromiseFromCall
        this._drawerService.show('feesEditorDrawer', 10);
      }, 500);
      return;
    } else {
      this.feeEditorDrawerTitle = `${fee?.hudNumber ? fee.hudNumber + ':' : ''} ${fee.name}`;
      this.selectedFeeForEditingDetails = cloneDeep(fee);
      // noinspection JSIgnoredPromiseFromCall
      this._drawerService.show('feesEditorDrawer', 10);
    }
  };

  onFeeEditorDrawerSaved = (fee: LoanFee) => {
    // TODO: Handle this subscription
    this._feeContextService.updateFee(fee).subscribe();
    this.onFeeEditorDrawerClosed();

    // @deprecated
    // const feesEditor = this.feesEditor;
    // if (feesEditor != null) {
    //   let existingFee = this._feeContextService.fees.find(f => f.modelGuid === fee.modelGuid);
    //   if (!existingFee) {
    //     return;
    //   }
    //
    //   existingFee = {...existingFee, ...fee};
    //
    //   // @ts-ignore TODO: Use fees-v2 models
    //   feesEditor.updateFeeValue(existingFee);
    // } else {
    //   // FeesV2
    //   this._feeContextService.updateFee(fee);
    // }
    // this.onFeeEditorDrawerClosed();
  };

  onFeeEditorDrawerClosed = () => {
    // noinspection JSIgnoredPromiseFromCall
    this._drawerService.hide('feesEditorDrawer', 10);
    this.feeEditorDrawerTitle = null;
    this.selectedFeeForEditingDetails = null;
  };

  protected onClickAddFee(): void {
    const modalRef = this._modalService.open(AddFeeModalComponent, Constants.modalOptions.medium);
    const instance = modalRef.componentInstance as AddFeeModalComponent;
    instance.feeContextService = this._feeContextService;

    this._addFeeModalSubscription?.unsubscribe();
    this._addFeeModalSubscription = from(modalRef.result)
      .pipe(
        catchError(error => {
          if (error !== 'cancel') {
            handleNonErrorDismissals(error);
          }
          return EMPTY;
        })
      )
      .subscribe(() => {
        this.setFees(
          // @ts-ignore
          this._feeContextService.fees,
          {
            setContextFees: false,
          }
        );
      });
  }

  private static showUnsavedChangesDialog(): Promise<SweetAlertResult> {
    return Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to save your changes before continuing?',
      icon: 'warning',
      reverseButtons: true,
      showCancelButton: true,
      showDenyButton: true,
      cancelButtonColor: '#A4ABC5',
      denyButtonColor: '#DC3741',
      cancelButtonText: 'Cancel',
      denyButtonText: 'Discard',
      confirmButtonText: 'Save',
    });
  }

  private async invokeWithUnsavedChangesDialog<T>(callback: () => Promise<T>): Promise<T> {
    if (this.hasChangedFees) {
      const answer = await LoanFeesComponent.showUnsavedChangesDialog();

      if (answer.isDismissed) {
        return;
      }

      if (answer.isConfirmed) {
        await this.saveFees();
      } else {
        await this.discardFeeChanges({ confirm: false });
      }
    }

    return callback();
  }

  protected onAreZeroFeesHiddenChange(value: boolean): void {
    this.areZeroFeesHidden = value;

    if (value) {
      const zeroFees = FeeUtils.getZeroFees(this._feeContextService.fees);
      this._feeDisplayService.hideFees(zeroFees);
    } else {
      this._feeDisplayService.showAllFees();
    }
  }

  protected async onRunFeeWizardClicked(): Promise<void> {
    return this.invokeWithUnsavedChangesDialog(async () => {
      this.decideOnWhatFeeWizardToUse();
    });
  }

  protected async onGenerateProformaLeClicked(): Promise<void> {
    return this.invokeWithUnsavedChangesDialog(async () => {
      this.generateProformaLe();
    });
  }

  private generateProformaLe(): void {
    this.generatingLe = true;

    this._generateProformaLeSubscription?.unsubscribe();
    this._generateProformaLeSubscription = this._feeService
      .generateProformaLe(this.application.applicationId)
      .pipe(
        tap(dto => {
          const doc = dto?.documents?.[0];
          if (doc == null) {
            throw new Error('No document found in the response.');
          }

          const pdf = doc?.pdf;
          if (!pdf) {
            throw new Error('PDF is missing in the response.');
          }
          if (!pdf.content) {
            throw new Error('PDF content is missing in the response.');
          }

          const urlToViewFile = Utils.generateFileUrlFromBase64(pdf.content);
          this.leDocumentUrlToView = this._sanitizer.bypassSecurityTrustResourceUrl(urlToViewFile);
          this.viewMode = ViewMode.LeDocView;
        }),
        finalize(() => {
          this.generatingLe = false;
        })
      )
      .subscribe({
        error: err => {
          this._notificationService.showError(
            getErrorMessageOrDefault(err, {
              defaultMessage: 'Error Generating Loan Estimate.',
            }),
            'Error!'
          );
        },
      });
  }

  /**
   * @deprecated
   * @param {LoanFee} fee
   */
  onFeeRemoved = (fee: LoanFee) => {
    // const index = this.fees.findIndex(f => f.modelGuid === fee.modelGuid);
    // if (index === -1) return;
    //
    // this.fees.splice(index, 1);
    //
    // if (fee.feeSection == FeeSectionType.Escrow) {
    //   this.escrowFees = [...this.escrowFees.filter(f => f.modelGuid != fee.modelGuid)];
    //   this.populateEscrowFees(this.escrowFees);
    // }
  };

  private _updateFeeValuesSubscription?: Subscription;
  onUpdatedFeeValues = (newFee: LoanFee) => {
    this._updateFeeValuesSubscription?.unsubscribe();
    this._updateFeeValuesSubscription = this._feeContextService.updateFee(newFee).subscribe();

    // const observer = {
    //   next: (updatedLoanFees => {
    //     let updatedFee = updatedLoanFees.find(f => f.modelGuid == newFee.modelGuid);
    //     if (updatedFee) {
    //       this.selectedFeeForEditingDetails = { ...updatedFee };
    //     }
    //   }),
    //   error: (error => {
    //     this._notificationService.showError(
    //       error.error && error.error.message
    //         ? error.error.message
    //         : 'Error updating calculated fee totals.',
    //       'Error!'
    //     );
    //   })
    // }
    //
    // this._spinner.show();
    //
    // const request = new FeesToUpdateRequest();
    // request.feesToUpdate = [newFee];
    // request.existingLoanFees = this.fees.filter(x => x.feeSection == newFee.feeSection);
    // request.updateCharges = false;
    // this._feeService.updateLoanFeeFromLoanValues(this.application.applicationId, request)
    //   .subscribe(observer)
    //   .add(() => this._spinner.hide());
  };

  onEscrowScheduleDrawerOpen = () => {
    this._drawerService.show('escrowScheduleDrawer', 10);
  };

  onEscrowScheduleUpdated = (fees: LoanFee[]) => {
    const newFees = [...this._feeContextService.fees];
    fees.forEach(updatedFee => {
      const originalFeeIndex = newFees.findIndex(f => f.modelGuid === updatedFee.modelGuid);
      if (originalFeeIndex >= 0) {
        newFees[originalFeeIndex] = updatedFee;
      }
    });

    this._feeContextService.setFees(newFees);
    this.populateEscrowFees(this._feeContextService.fees, false);
  };

  onEscrowScheduleDrawerClosed = () => {
    // noinspection JSIgnoredPromiseFromCall
    this._drawerService.hide('escrowScheduleDrawer', 10);
  };

  /**
   * @deprecated
   * @param {LoanFee[]} fees
   */
  onFeesUpdated = (fees: LoanFee[]) => {
    this.populateEscrowFees(fees);
  };

  private checkKeyDates = (feeWizardTypeToUse: FeeWizardTypes) => {
    this._spinner.show();
    this._loanService
      .getKeyDatesByType(this.application.applicationId)
      .subscribe({
        next: keyDates => {
          const estimatedClosingKeyDate = keyDates ? keyDates['estimatedClosing'] : null;
          const closingKeyDate = keyDates ? keyDates['closing'] : null;

          if (!estimatedClosingKeyDate?.eventDate && !closingKeyDate?.eventDate) {
            this.openPreCheckFeeWizardDialog(estimatedClosingKeyDate, feeWizardTypeToUse);
          } else {
            this.viewMode = ViewMode.FeeWizard;
          }
        },
        error: error => {
          this._notificationService.showError(
            getErrorMessageOrDefault(error, {
              defaultMessage: 'Unable to pre-check fee wizard.',
            }),
            'Error!'
          );
        },
      })
      .add(() => this._spinner.hide());
  };

  private decideOnWhatFeeWizardToUse = () => {
    const isSupportedVendorMcto =
      this.supportedVendorNames.length === 1 &&
      this.supportedVendorNames[0].toLowerCase() === 'mortgagecto';
    if (isSupportedVendorMcto) {
      this.feeWizardTypeToUse = FeeWizardTypes.MortgageCTO;
    } else {
      this.feeWizardTypeToUse = FeeWizardTypes.Loda;
    }
    if (this.applicationContext.isCompanyPRMG || this.applicationContext.isCompanyDeepHaven) {
      this.viewMode = ViewMode.FeeWizard;
    } else {
      this.checkKeyDates(this.feeWizardTypeToUse);
    }
  };

  isAddressInfoMissing = mortgage => {
    return (
      !mortgage.subjectProperty.city ||
      mortgage.subjectProperty.city.length === 0 ||
      !mortgage.subjectProperty.state ||
      mortgage.subjectProperty.state.length === 0 ||
      !mortgage.subjectProperty.county ||
      mortgage.subjectProperty.county.length === 0 ||
      !mortgage.subjectProperty.zipCode ||
      mortgage.subjectProperty.city.zipCode === 0
    );
  };

  openUpdateAddressInfoDialog = () => {
    const modalRef = this._modalService.open(AddressUpdateModalComponent, { centered: true });
    modalRef.componentInstance.subjectProperty = this.mortgage.subjectProperty;

    this._spinner.hide();
    modalRef.result.then((subjectProperty: SubjectProperty) => {
      this.mortgage.subjectProperty = subjectProperty;

      this._spinner.show();
      this._mortgageService.saveMortgage(this.mortgage).subscribe(
        response => {
          if (response) {
            //this.openErnstQuestionnaireDialog();
          }
        },
        error => {
          this._spinner.hide();
          this._notificationService.showError(
            getErrorMessageOrDefault(error, {
              defaultMessage: 'An error occurred while saving subject property.',
            }),
            'Error!'
          );
        }
      );
    });
  };

  onMortgageCtoWizardCompleted = () => { };

  onWizardCompleted = async (appliedFees: LoanFee[]) => {
    this.setFees(appliedFees, { populateEscrowFees: false });

    await this.saveFeeWizardRunKeyDate();

    const self = this;
    Swal.fire({
      title: 'Fee Wizard Completed',
      text: 'You have successfully completed the fee wizard.',
      icon: 'success',
      confirmButtonText: 'OK',
      reverseButtons: true,
    }).then(function (result: SweetAlertResult) {
      self.viewMode = ViewMode.FeeEdit;
      self.populateEscrowFees(self._feeContextService.fees);

      if (this.applicationContext.isCompanyDeepHaven) {
        this.areZeroFeesHidden = true;
        this.onAreZeroFeesHiddenChange(this.areZeroFeesHidden);
      }
    });
  };

  /**
   * Discards the changes in the fees.
   * @param {object} [options]
   * @param {boolean} [options.confirm=true] - If set to true, it shows a
   * confirmation dialog before discarding the changes.
   */
  protected async discardFeeChanges(options?: { confirm?: boolean }): Promise<void> {
    options = {
      confirm: options?.confirm ?? true,
    };

    if (options.confirm) {
      const answer = await Swal.fire({
        title: 'Are you sure?',
        text: 'You will lose all unsaved changes.',
        icon: 'warning',
        showCancelButton: true,
        focusCancel: true,
        reverseButtons: true,
        cancelButtonColor: '#A4ABC5',
        confirmButtonColor: '#DC3741',
        cancelButtonText: 'Cancel',
        confirmButtonText: 'Discard',
      });

      if (!answer.isConfirmed) {
        return;
      }
    }

    this._feeContextService.discardChanges();
  }

  saveFees(): Promise<void> {
    if (!this.hasChangedFees) {
      this._notificationService.showInfo('There are no changes to save.', 'No Changes');
      return Promise.resolve();
    }

    const feesEditor = this.feesEditor;
    const isInvalid = feesEditor != null ? !feesEditor.isAllValid : false; // TODO: Check if the fees is valid also in fees-v2
    if (isInvalid) {
      this.showInvalidFeesWarning();
      this.setIsSaving(false);
      return Promise.reject(new Error('Invalid fees.'));
    }

    let resolve: (value: void) => void;
    let reject: (reason?: any) => void;
    const result = new Promise<void>((res, rej) => {
      resolve = res;
      reject = rej;
    });

    this.setIsSaving(true);

    this.setSourceOfChangedFeesToManual();

    // We need to save the mortgage first, then the fees.
    this._feeService
      .saveFees(
        this.application.applicationId,
        // @ts-ignore TODO: Use fees-v2 models
        this._feeContextService.fees
      )
      .pipe(
        finalize(() => {
          this.setIsSaving(false);
        })
      )
      .subscribe({
        next: fees => {
          // @ts-ignore TODO: Use fees-v2 models
          this.setFees(fees, { sortFees: true });
          this._notificationService.showSuccess(
            'Your changes have been successfully saved.',
            'Success!'
          );

          this.applicationContextService.reloadLoan().subscribe();

          resolve();
        },
        error: (err: any) => {
          this._notificationService.showError(
            getErrorMessageOrDefault(err, {
              defaultMessage: 'An error occurred while saving the fees.',
            }),
            'Error!'
          );

          reject(err);
        },
      });

    return result;
  }

  protected onCheckPricingStatusClicked = async () => {
    this.isCheckingPricingStatus = true;
    try {
      const losLoan = await firstValueFrom(this._losService.pullFromLos(this.application.applicationId));
      this.applicationContextService.updateMortgageAndApplication(losLoan.application?.mortgageLoan, 
        losLoan.application, losLoan.customData, undefined, true);
    } catch (error) {
      this._notificationService.showError(
        getErrorMessageOrDefault(error, {
          defaultMessage: 'An error occurred while checking pricing status.',
        }),
        'Error!'
      );
    } finally {
      this.isCheckingPricingStatus = false;
    }
  }

  protected saveMortgage(): Promise<void> {
    let resolve: (value: void) => void;
    let reject: (reason?: any) => void;
    const result = new Promise<void>((res, rej) => {
      resolve = res;
      reject = rej;
    });

    // This is the original mortgage loan that we want to restore if the save
    // fails.
    const application = this.application;
    const mortgageFallback = application.mortgageLoan;

    application.mortgageLoan = this.mortgage;

    const loanInfo = {
      application,
      customData: null,
    };

    this.setIsSaving(true);

    this._appDetailsService
      .saveLoanInfo(application.applicationId, loanInfo)
      .pipe(
        map(response => response.application),
        finalize(() => {
          this.setIsSaving(false);
        })
      )
      .subscribe({
        next: applicationResponse => {
          this.applicationContextService.updateMortgageAndApplication(
            applicationResponse.mortgageLoan,
            applicationResponse
          );

          this._notificationService.showSuccess(
            'The mortgage has been saved successfully.',
            'Success'
          );

          resolve();
        },
        error: (err: any) => {
          // Restore the original mortgage loan if the save fails.
          this.application.mortgageLoan = mortgageFallback;

          this._notificationService.showError(
            getErrorMessageOrDefault(err, {
              defaultMessage: 'An error occurred while saving the mortgage.',
            }),
            'Error!'
          );

          reject(err);
        },
      });

    return result;
  }

  private setSourceOfChangedFeesToManual() {
    const originalFeeById = this._feeContextService.originalFees.reduce((acc, fee) => {
      acc.set(fee.modelGuid, fee);
      return acc;
    }, new Map<string, Readonly<LoanFee>>());

    this._feeContextService.fees.forEach(fee => {
      const originalFee = originalFeeById.get(fee.modelGuid);
      if (originalFee) {
        if (!areFeesEqual(fee, originalFee)) {
          // @ts-ignore
          fee.latestFeeValueSource = FeeSources.Manual;
        }
      } else {
        // @ts-ignore
        fee.latestFeeValueSource = FeeSources.Manual;
      }
    });

    this._feeContextService.setFees(this._feeContextService.fees, {
      sortFees: false,
      resetOriginalFees: false,
      setMissingFeeProperties: false,
    });
  }

  private fixPercentagesOnLoad = () => {
    // this.fees.forEach(fee => {
    //   if (fee.borrowerFeePercent) {
    //     fee.borrowerFeePercent = fee.borrowerFeePercent;
    //   }
    //   if (fee.lenderFeePercent) {
    //     fee.lenderFeePercent = fee.lenderFeePercent;
    //   }
    //   if (fee.sellerFeePercent) {
    //     fee.sellerFeePercent = fee.sellerFeePercent;
    //   }
    //   if (fee.thirdPartyFeePercent) {
    //     fee.thirdPartyFeePercent = fee.thirdPartyFeePercent;
    //   }
    // })
  };

  private showInvalidFeesWarning = () => {
    const self = this;
    Swal.fire({
      title: 'Invalid Fees',
      text: 'There are invalid fees - please fix them and then try saving again.',
      icon: 'warning',
      confirmButtonText: 'OK',
      reverseButtons: true,
    }).then(function (result: SweetAlertResult) {
      self.feesEditor.scrollToFirstInvalidControl();
      if (!result.value) {
        return;
      }
    });
  };

  private initialize = async (context: ApplicationContext) => {
    if (context.application) {
      const application = context.application;

      this.originalApplication = application;
      this.application = _.cloneDeep(application);

      if (this.applicationContext.isTpo && (this.applicationContext.isCompanyPRMG || this.applicationContext.isCompanyDeepHaven)) {
        if ((!application.productPricing || !application.productPricing.assignDate)) {
          this.viewMode = ViewMode.RefreshFromLos;
          return;
        }
      }

      this.showProformaLeGeneration =
        !!application.losIdentifier && application.losVendor == LosVendor.EncompassApi;

      if (this.applicationContext.userPermissions.userType != UserType.Tpo) {
        this.allowRunFeeWizard = true;
      } else {
        try {
          var keyDates = await firstValueFrom(
            this._loanService.getKeyDatesByType(this.application.applicationId)
          );
          let leIssueDate = keyDates.initialDisclosureSent?.eventDate || keyDates.leIssueDate?.eventDate;
          this.feeWizardRunDate = keyDates.feeWizardRun?.eventDate;
          if (!leIssueDate) {
            this.allowRunFeeWizard = true;
          } else {
            this.isFeesV2Editable = false;
          }
        } catch (error) {
          this._notificationService.showError(
            getErrorMessageOrDefault(error, {
              defaultMessage:
                'An error occurred while checking key dates. Disabling Fee Wizard.',
            }),
            'Error!'
          );
        }
      }

      const mortgage = context.currentMortgage;
      this.originalMortgage = mortgage;
      this.mortgage = _.cloneDeep(mortgage);
      this.calculationDetails = context.currentMortgageCalculationDetails;

      this.loadVendors();
      this.getFees();
    }
  };

  private loadVendors = () => {
    const observer = {
      next: vendorNames => {
        this._spinner.hide();
        this.supportedVendorNames = vendorNames;
      },
      error: error => {
        this._spinner.hide();
        this._notificationService.showError(
          getErrorMessageOrDefault(error, {
            defaultMessage: 'An error occurred while getting fee providers.',
          }),
          'Error!'
        );
      },
    };
    this._spinner.show();
    this._feeService
      .getConfiguredFeeProvidersForApp(this.application.applicationId)
      .subscribe(observer);
  };

  private openPreCheckFeeWizardDialog = (estimatedClosingCostKeyDate: BaseKeyDate, feeWizardTypeToUse: FeeWizardTypes) => {
    const modalRef = this._modalService.open(
      PreCheckFeeWizardDialogComponent,
      { ...Constants.modalOptions.medium, scrollable: false }
    );
    modalRef.componentInstance.feeWizardTypeToUse = feeWizardTypeToUse;
    modalRef.componentInstance.estimatedClosingKeyDate = estimatedClosingCostKeyDate;
    modalRef.componentInstance.applicationReceivedKeyDate =
      this.applicationContext.applicationKeyDatesByType['applicationReceived'];
    modalRef.componentInstance.isPurchase =
      this._mortgageCalculationsService.isPurposeOfLoanPurchase(this.mortgage);
    modalRef.componentInstance.applicationId = this.application.applicationId;

    modalRef.result.then(
      () => {
        this.viewMode = ViewMode.FeeWizard;
      },
      () => { }
    );
  };

  private getFees = () => {
    const feesObserver = {
      next: fees => {
        this.setFees(fees, { sortFees: true });

        const templatesObserver = {
          next: templates => {
            this.templates = templates;
            this.isInitializing = false;

            if (this.applicationContext.isCompanyDeepHaven) {
              this.showProformaLeGeneration = false;
              this.areZeroFeesHidden = true;

              // At the very end of the initialization, if the company is deephaven, we need to check if the fee wizard
              // has been run, if not run we run it automatically
              if (this.allowRunFeeWizard && this.applicationContext.isCompanyDeepHaven && !this.feeWizardRunDate) {
                this.decideOnWhatFeeWizardToUse();
              }
              this.onAreZeroFeesHiddenChange(this.areZeroFeesHidden);
            }
          },
          error: error => {
            this.isInitializing = false;
            this._notificationService.showError(
              getErrorMessageOrDefault(error, {
                defaultMessage: 'Unable to get fee templates.',
              }),
              'Error!'
            );
          },
        };
        this._feeService
          .getFeeTemplates(this.application.applicationId)
          .subscribe(templatesObserver);
      },
      error: error => {
        this.isInitializing = false;
        this._notificationService.showError(
          getErrorMessageOrDefault(error, {
            defaultMessage: 'Unable to get fees for the loan.',
          }),
          'Error!'
        );
      },
    };
    this.isInitializing = true;
    this._feeService.getFees(this.application.applicationId).subscribe(feesObserver);
  };

  private populateEscrowFees = (fees: readonly LoanFee[], isReloadRequired: boolean = true) => {
    // @ts-ignore TODO: Use fees-v2 models
    this.escrowFees = [
      ...fees
        // @ts-ignore
        .filter(fee => fee.feeSection === FeeSectionType.Escrow)
        // @ts-ignore
        .map(fee => this._feeUtilsService.initMissingFeeValues(fee)),
    ];

    if (this.feeScheduleEditor && isReloadRequired) {
      this.feeScheduleEditor.reloadData();
    }
  };

  private saveFeeWizardRunKeyDate = async () => {
    const request = {
      FeeWizardRun: new Date().toISOString(),
    };
    try {
      await this._spinner.show();
      await firstValueFrom(
        this._loanService.saveKeyDatesByType(
          request,
          this.applicationContext.application.applicationId
        )
      );
    } catch (e) {
    } finally {
      this._spinner.hide();
    }
  }

  private setIsSaving(value: boolean): void {
    this.isSaving = value;

    if (value) {
      // noinspection JSIgnoredPromiseFromCall
      this._spinner.show();
    } else {
      // noinspection JSIgnoredPromiseFromCall
      this._spinner.hide();
    }
  }
}

export enum FeeWizardTypes {
  None = "None",
  Loda = "Loda",
  MortgageCTO = "MortgageCTO"
}

export enum ViewMode {
  FeeEdit = "FeeEdit",
  FeeWizard = "FeeWizard",
  LeDocView = "LeDocView",
  RefreshFromLos = "RefreshFromLos"
}
