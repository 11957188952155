<form class="card" #formElement="ngForm">
  <div class="card-header">
    <h4 class="card-title">Email Templates</h4>
  </div>
  <div class="card-body">
    <ng-template [ngIf]="loadingEmailTemplates" [ngIfElse]="emailTemplatesContent">
      <loading-indicator></loading-indicator>
    </ng-template>
    <ng-template #emailTemplatesContent>
      <div class="accordion">
        <!-- Borrower Invite Template -->
        <div [hidden]="restrictedEmailTemplates?.indexOf('BorrowerInvite') > -1" *ngLet='collapsibleSectionVisibilityStatuses["inviteEmail"] as expanded' class='accordion-item'>
          <h5 class='accordion-header m-0'>
            <button (click)='onEmailTemplateEditorVisibilityChanged("inviteEmail")' [attr.aria-expanded]='expanded'
              aria-controls='collapse1' class='accordion-button collapsed fw-semibold' data-bs-target='#bit'
              data-bs-toggle='collapse' type='button'>
              Borrower Invite Template

              <!-- Override Status Badge -->
              <span *ngIf='effectiveEmailTemplate.inviteOverride' class='text-success ms-1'>
                - (Overridden)
              </span>
            </button>
          </h5>
          <div id='bit' class='accordion-collapse collapse' [class.show]='expanded'>
            <div class='accordion-body'>
              <div class='form-group mb-3 row'>
                <div class='col-sm-2'></div>
                <div class='col-sm-10'>
                  <div class='form-check form-check-inline'>
                    <input (ngModelChange)='populateDefaultEmailTemplate(
                        $event,
                        "BorrowerInvite",
                        "inviteSubject",
                        "inviteEmail"
                      )' [(ngModel)]='effectiveEmailTemplate.inviteOverride' class='form-check-input'
                      id='inviteOverride' name='inviteOverride' type='checkbox'>

                    <label class='form-check-label' for='inviteOverride'>
                      Override Company Borrower Invite Template
                    </label>
                  </div>
                </div>
              </div>

              <ng-container *ngIf='effectiveEmailTemplate.inviteOverride'>
                <div class='form-group mb-3 row'>
                  <div class='col-sm-2'></div>
                  <div class='col-sm-10'>
                    <div class='form-check form-check-inline'>
                      <input [(ngModel)]='effectiveEmailTemplate.inviteIncludeEmailSignature' class='form-check-input'
                        id='inviteIncludeEmailSignature' name='inviteIncludeEmailSignature' type='checkbox'>

                      <label class='form-check-label' for='inviteIncludeEmailSignature'>
                        Include Email Signature
                      </label>
                    </div>
                  </div>
                </div>

                <div class='form-group mb-3 row'>
                  <label class='col-sm-2 form-label align-self-center mb-lg-0 text-end'>
                    CC
                  </label>

                  <div class='col-sm-10'>
                    <input [(ngModel)]='effectiveEmailTemplate.inviteCC' class='form-control' placeholder='CC'
                      type='text' name='inviteCC'>
                  </div>
                </div>

                <div class='form-group mb-3 row'>
                  <label class='col-sm-2 form-label align-self-center mb-lg-0 text-end'>BCC</label>
                  <div class='col-sm-10'>
                    <input [(ngModel)]='effectiveEmailTemplate.inviteBCC' class='form-control' placeholder='BCC'
                      type='text' name='inviteBCC'>
                  </div>
                </div>

                <div class='form-group mb-3 row'>
                  <label class='col-sm-2 form-label align-self-center mb-lg-0 text-end'>CC</label>
                  <div class='col-sm-10'>
                    <ng-select width='100%' bindLabel='name' bindValue='value'
                      placeholder='-- Select --' [closeOnSelect]='false' [multiple]='true' groupBy='groupName'
                      [(ngModel)]='inviteRolesCC' [items]='roles' appendTo='body'
                      [ngModelOptions]="{standalone: true}"
                      (ngModelChange)="onRoleMultiselectChanged('inviteRolesCC')">
                    </ng-select>
                  </div>
                </div>
                <div class='form-group mb-3 row'>
                  <label class='col-sm-2 form-label align-self-center mb-lg-0 text-end'>BCC</label>
                  <div class='col-sm-10'>
                    <ng-select width='100%' bindLabel='name' bindValue='value'
                      placeholder='-- Select --' [closeOnSelect]='false' [multiple]='true' groupBy='groupName'
                      [(ngModel)]='inviteRolesBCC' [items]='roles' appendTo='body'
                      [ngModelOptions]="{standalone: true}"
                      (ngModelChange)="onRoleMultiselectChanged('inviteRolesBCC')">
                    </ng-select>
                  </div>
                </div>
                <div class='form-group mb-3 row'>
                  <label class='col-sm-2 form-label align-self-center mb-lg-0 text-end'>Subject</label>
                  <div class='col-sm-10'>
                    <input class='form-control' placeholder='Subject' type='text'
                      [(ngModel)]='effectiveEmailTemplate.inviteSubject' name='inviteSubject'>
                  </div>
                </div>

                <hr>

                <!-- Action Buttons & Email Preview -->
                <div class='form-group mb-3 row'>
                  <div class='col-sm-12 form-label align-self-center mb-1 d-flex justify-content-between'>
                    <div class='d-flex'>
                      <div class='special-radio-btn-group flex-grow-1'>
                        <div class='btn-group'>
                          <button (click)="onEditTemplateClicked(effectiveEmailTemplate.inviteEmail, 'inviteEmail')"
                            class='btn btn-outline-primary tippy-btn mb-xs-1 me-1' type='button'>
                            <div class='d-flex'>

                              <span class='fw-bolder button-item-text flex-grow-1'>
                                <i class='fa fa-pencil-alt'></i>
                              </span>

                              <span class='fw-bolder button-item-text filter-label flex-grow-1 ms-1'>
                                Edit
                              </span>
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class='col-sm-12'>
                    <iframe id='inviteEmailIframe'
                      style='width:100%; height: 700px; border:1px solid #e3ebf6; border-radius: 0.25rem;'></iframe>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>

        <!-- Agent Invite Template -->
        <div [hidden]="restrictedEmailTemplates?.indexOf('AgentInvite') > -1" *ngLet='collapsibleSectionVisibilityStatuses["inviteAgent"] as expanded' class='accordion-item'>
          <h5 class="accordion-header m-0">
            <button (click)='onEmailTemplateEditorVisibilityChanged("inviteAgentEmail")' [attr.aria-expanded]='expanded'
              aria-controls='collapse1' class='accordion-button collapsed fw-semibold' data-bs-target='#ait'
              data-bs-toggle='collapse' type='button'>
              Agent Invite Template

              <!-- Override Status Badge -->
              <span *ngIf='effectiveEmailTemplate.inviteAgentOverride' class='text-success ms-1'>
                - (Overridden)
              </span>
            </button>
          </h5>
          <div id='ait' class='accordion-collapse collapse' [class.show]='expanded'>
            <div class="accordion-body">
              <div class="form-group mb-3 row">
                <div class="col-sm-2"></div>
                <div class="col-sm-10">
                  <div class="form-check form-check-inline">
                    <input (ngModelChange)='populateDefaultEmailTemplate(
                        $event,
                        "AgentInvite",
                        "inviteAgentSubject",
                        "inviteAgentEmail"
                      )' [(ngModel)]='effectiveEmailTemplate.inviteAgentOverride' class='form-check-input'
                      id='inviteAgentOverride' type='checkbox' name='inviteAgentOverride'>

                    <label class='form-check-label' for='inviteAgentOverride'>
                      Override Company Agent Invite Template
                    </label>
                  </div>
                </div>
              </div>


              <ng-container *ngIf='effectiveEmailTemplate.inviteAgentOverride'>
                <div class='form-group mb-3 row'>
                  <label class='col-sm-2 form-label align-self-center mb-lg-0 text-end'>Subject</label>
                  <div class='col-sm-10'>
                    <input class='form-control' placeholder='Subject' type='text'
                      [(ngModel)]='effectiveEmailTemplate.inviteAgentSubject' name='inviteAgentSubject'>
                  </div>
                </div>

                <hr>

                <!-- Action Buttons & Email Preview -->
                <div class='form-group mb-3 row'>
                  <div class='col-sm-12 form-label align-self-center mb-1 d-flex justify-content-between'>
                    <div class='d-flex'>
                      <div class='special-radio-btn-group flex-grow-1'>
                        <div class='btn-group'>
                          <button
                            (click)="onEditTemplateClicked(effectiveEmailTemplate.inviteAgentEmail, 'inviteAgentEmail')"
                            class='btn btn-outline-primary tippy-btn mb-xs-1 me-1' type='button'>
                            <div class='d-flex'>

                              <span class='fw-bolder button-item-text flex-grow-1'>
                                <i class='fa fa-pencil-alt'></i>
                              </span>

                              <span class='fw-bolder button-item-text filter-label flex-grow-1 ms-1'>
                                Edit
                              </span>
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class='col-sm-12'>
                    <iframe id='inviteAgentEmailIframe'
                      style='width:100%; height: 700px; border:1px solid #e3ebf6; border-radius: 0.25rem;'></iframe>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>

        <!-- Task Request Template -->
        <div [hidden]="restrictedEmailTemplates?.indexOf('TaskRequestStatusUpdate') > -1"
             *ngLet='collapsibleSectionVisibilityStatuses["request"] as expanded' class="accordion-item">
          <h5 class="accordion-header m-0">
            <button (click)='onEmailTemplateEditorVisibilityChanged("requestEmail")' [attr.aria-expanded]='expanded'
              aria-controls='collapse1' class='accordion-button collapsed fw-semibold' data-bs-target='#trt'
              data-bs-toggle='collapse' type='button'>
              Task Request / Status Update Template

              <span *ngIf='effectiveEmailTemplate.requestOverride' class='text-success ms-1'>
                - (Overridden)
              </span>
            </button>
          </h5>
          <div id="trt" class="accordion-collapse collapse" [class.show]='expanded'>
            <div class="accordion-body">
              <div class="accordion-body">
                <div class="form-group mb-3 row">
                  <div class="col-sm-2"></div>
                  <div class="col-sm-10">
                    <div class="form-check form-check-inline">
                      <input (ngModelChange)='populateDefaultEmailTemplate(
                          $event,
                          "TaskRequestStatusUpdate",
                          "requestSubject",
                          "requestEmail"
                        )' [(ngModel)]='effectiveEmailTemplate.requestOverride' class='form-check-input'
                        id='requestOverride' type='checkbox' name='requestOverride'>

                      <label class='form-check-label' for='requestOverride'>
                        Override Company Request Template
                      </label>
                    </div>
                  </div>
                </div>

                <ng-container *ngIf='effectiveEmailTemplate.requestOverride'>
                  <div class='form-group mb-3 row'>
                    <label class='col-sm-2 form-label align-self-center mb-lg-0 text-end'>CC</label>
                    <div class='col-sm-10'>
                      <input class='form-control' placeholder='CC' type='text'
                        [(ngModel)]='effectiveEmailTemplate.requestCC' name='requestCC'>
                    </div>
                  </div>
                  <div class='form-group mb-3 row'>
                    <label class='col-sm-2 form-label align-self-center mb-lg-0 text-end'>BCC</label>
                    <div class='col-sm-10'>
                      <input class='form-control' placeholder='BCC' type='text'
                        [(ngModel)]='effectiveEmailTemplate.requestBCC' name='requestBCC'>
                    </div>
                  </div>
                  <div class='form-group mb-3 row'>
                    <label class='col-sm-2 form-label align-self-center mb-lg-0 text-end'>CC</label>
                    <div class='col-sm-10'>
                      <ng-select width='100%' bindLabel='name' bindValue='value'
                        placeholder='-- Select --' [closeOnSelect]='false' [multiple]='true' groupBy='groupName'
                        [(ngModel)]='requestRolesCC' [items]='roles' appendTo='body'
                        [ngModelOptions]="{standalone: true}"
                        (ngModelChange)="onRoleMultiselectChanged('requestRolesCC')">
                      </ng-select>
                    </div>
                  </div>
                  <div class='form-group mb-3 row'>
                    <label class='col-sm-2 form-label align-self-center mb-lg-0 text-end'>BCC</label>
                    <div class='col-sm-10'>
                      <ng-select width='100%' bindLabel='name' bindValue='value'
                        placeholder='-- Select --' [closeOnSelect]='false' [multiple]='true' groupBy='groupName'
                        [(ngModel)]='requestRolesBCC' [items]='roles' appendTo='body'
                        [ngModelOptions]="{standalone: true}"
                        (ngModelChange)="onRoleMultiselectChanged('requestRolesBCC')">
                      </ng-select>
                    </div>
                  </div>
                  <div class='form-group mb-3 row'>
                    <label class='col-sm-2 form-label align-self-center mb-lg-0 text-end'>Subject</label>
                    <div class='col-sm-10'>
                      <input class='form-control' placeholder='Subject' type='text'
                        [(ngModel)]='effectiveEmailTemplate.requestSubject' name='requestSubject'>
                    </div>
                  </div>

                  <hr>

                  <!-- Action Buttons & Email Preview -->
                  <div class='form-group mb-3 row'>
                    <div class='col-sm-12 form-label align-self-center mb-1 d-flex justify-content-between'>
                      <div class='d-flex'>
                        <div class='special-radio-btn-group flex-grow-1'>
                          <div class='btn-group'>
                            <button (click)="onEditTemplateClicked(effectiveEmailTemplate.requestEmail, 'requestEmail')"
                              class='btn btn-outline-primary tippy-btn mb-xs-1 me-1' type='button'>
                              <div class='d-flex'>

                                <span class='fw-bolder button-item-text flex-grow-1'>
                                  <i class='fa fa-pencil-alt'></i>
                                </span>

                                <span class='fw-bolder button-item-text filter-label flex-grow-1 ms-1'>
                                  Edit
                                </span>
                              </div>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class='col-sm-12'>
                      <iframe id='requestEmailIframe'
                        style='width:100%; height: 700px; border:1px solid #e3ebf6; border-radius: 0.25rem;'></iframe>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>

        <!-- Task Reminder Template -->
        <div [hidden]="restrictedEmailTemplates?.indexOf('TaskReminder') > -1" *ngLet='collapsibleSectionVisibilityStatuses["reminder"] as expanded' class='accordion-item'>
          <h5 class='accordion-header m-0'>
            <button (click)='onEmailTemplateEditorVisibilityChanged("reminderEmail")' [attr.aria-expanded]='expanded'
              aria-controls='collapse1' class='accordion-button collapsed fw-semibold' data-bs-target='#tmt'
              data-bs-toggle='collapse' type='button'>
              Task Reminder Template

              <span *ngIf='effectiveEmailTemplate.reminderOverride' class='text-success ms-1'>
                - (Overridden)
              </span>
            </button>
          </h5>
          <div id='tmt' class='accordion-collapse collapse' [class.show]='expanded'>
            <div class='accordion-body'>
              <div class='form-group mb-3 row'>
                <div class='col-sm-2'></div>
                <div class='col-sm-10'>
                  <div class='form-check form-check-inline'>
                    <input (ngModelChange)='populateDefaultEmailTemplate(
                        $event,
                        "TaskReminder",
                        "reminderSubject",
                        "reminderEmail"
                      )' [(ngModel)]='effectiveEmailTemplate.reminderOverride' class='form-check-input'
                      id='reminderOverride' type='checkbox' name='reminderOverride'>

                    <label class='form-check-label' for='reminderOverride'>
                      Override Task Reminder Template
                    </label>
                  </div>
                </div>
              </div>

              <ng-container *ngIf='effectiveEmailTemplate.reminderOverride'>
                <div class='form-group mb-3 row'>
                  <label class='col-sm-2 form-label align-self-center mb-lg-0 text-end'>CC</label>
                  <div class='col-sm-10'>
                    <input class='form-control' placeholder='CC' type='text'
                      [(ngModel)]='effectiveEmailTemplate.reminderCC' name='reminderCC'>
                  </div>
                </div>
                <div class='form-group mb-3 row'>
                  <label class='col-sm-2 form-label align-self-center mb-lg-0 text-end'>BCC</label>
                  <div class='col-sm-10'>
                    <input class='form-control' placeholder='BCC' type='text'
                      [(ngModel)]='effectiveEmailTemplate.reminderBCC' name='reminderBCC'>
                  </div>
                </div>
                <div class='form-group mb-3 row'>
                  <label class='col-sm-2 form-label align-self-center mb-lg-0 text-end'>CC</label>
                  <div class='col-sm-10'>
                    <ng-select width='100%' bindLabel='name' bindValue='value'
                      placeholder='-- Select --' [closeOnSelect]='false' [multiple]='true' groupBy='groupName'
                      [(ngModel)]='reminderRolesCC' [items]='roles' appendTo='body'
                      [ngModelOptions]="{standalone: true}"
                      (ngModelChange)="onRoleMultiselectChanged('reminderRolesCC')">
                    </ng-select>
                  </div>
                </div>
                <div class='form-group mb-3 row'>
                  <label class='col-sm-2 form-label align-self-center mb-lg-0 text-end'>BCC</label>
                  <div class='col-sm-10'>
                    <ng-select width='100%' bindLabel='name' bindValue='value'
                      placeholder='-- Select --' [closeOnSelect]='false' [multiple]='true' groupBy='groupName'
                      [(ngModel)]='reminderRolesBCC' [items]='roles' appendTo='body'
                      [ngModelOptions]="{standalone: true}"
                      (ngModelChange)="onRoleMultiselectChanged('reminderRolesBCC')">
                    </ng-select>
                  </div>
                </div>
                <div class='form-group mb-3 row'>
                  <label class='col-sm-2 form-label align-self-center mb-lg-0 text-end'>Subject</label>
                  <div class='col-sm-10'>
                    <input class='form-control' placeholder='Subject' type='text'
                      [(ngModel)]='effectiveEmailTemplate.reminderSubject' name='reminderSubject'>
                  </div>
                </div>

                <hr>

                <!-- Action Buttons & Email Preview -->
                <div class='form-group mb-3 row'>
                  <div class='col-sm-12 form-label align-self-center mb-1 d-flex justify-content-between'>
                    <div class='d-flex'>
                      <div class='special-radio-btn-group flex-grow-1'>
                        <div class='btn-group'>
                          <button (click)="onEditTemplateClicked(effectiveEmailTemplate.reminderEmail, 'reminderEmail')"
                            class='btn btn-outline-primary tippy-btn mb-xs-1 me-1' type='button'>
                            <div class='d-flex'>

                              <span class='fw-bolder button-item-text flex-grow-1'>
                                <i class='fa fa-pencil-alt'></i>
                              </span>

                              <span class='fw-bolder button-item-text filter-label flex-grow-1 ms-1'>
                                Edit
                              </span>
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class='col-sm-12'>
                    <iframe id='reminderEmailIframe'
                      style='width:100%; height: 700px; border:1px solid #e3ebf6; border-radius: 0.25rem;'></iframe>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>

        <!-- Online App Registration Template -->
        <div class="accordion-item" *ngIf="applicationContext.userPermissions.onlineApplicationEnabled">
          <ng-container [hidden]="restrictedEmailTemplates?.indexOf('OnlineAppRegistration') > -1" *ngLet='collapsibleSectionVisibilityStatuses["onlineAppRegistration"] as expanded'>
            <h5 class='accordion-header m-0'>
              <button (click)='onEmailTemplateEditorVisibilityChanged("onlineAppRegistrationEmail")'
                [attr.aria-expanded]='expanded' aria-controls='collapse1' class='accordion-button collapsed fw-semibold'
                data-bs-target='#ort' data-bs-toggle='collapse' type='button'>
                Online App Registration Template

                <span *ngIf='effectiveEmailTemplate.onlineAppRegistrationOverride' class='text-success ms-1'>
                  - (Overridden)
                </span>
              </button>
            </h5>
            <div id='ort' class='accordion-collapse collapse' [class.show]='expanded'>
              <div class='accordion-body'>
                <div class='form-group mb-3 row'>
                  <div class='col-sm-2'></div>
                  <div class='col-sm-10'>
                    <div class='form-check form-check-inline'>
                      <input (ngModelChange)='populateDefaultEmailTemplate(
                          $event,
                          "OnlineAppRegistration",
                          "onlineAppRegistrationSubject",
                          "onlineAppRegistrationEmail"
                        )' [(ngModel)]='effectiveEmailTemplate.onlineAppRegistrationOverride' class='form-check-input'
                        id='onlineAppRegistrationOverride' type='checkbox' name='onlineAppRegistrationOverride'>

                      <label class='form-check-label' for='onlineAppRegistrationOverride'>
                        Override Company Online App Registration Template
                      </label>
                    </div>
                  </div>
                </div>

                <ng-container *ngIf='effectiveEmailTemplate.onlineAppRegistrationOverride'>
                  <div class='form-group mb-3 row'>
                    <label class='col-sm-2 form-label align-self-center mb-lg-0 text-end'>Subject</label>
                    <div class='col-sm-10'>
                      <input class='form-control' placeholder='Subject' type='text'
                        [(ngModel)]='effectiveEmailTemplate.onlineAppRegistrationSubject'
                        name='onlineAppRegistrationSubject'>
                    </div>
                  </div>

                  <hr>

                  <!-- Action Buttons & Email Preview -->
                  <div class='form-group mb-3 row'>
                    <div class='col-sm-12 form-label align-self-center mb-1 d-flex justify-content-between'>
                      <div class='d-flex'>
                        <div class='special-radio-btn-group flex-grow-1'>
                          <div class='btn-group'>
                            <button
                              (click)="onEditTemplateClicked(effectiveEmailTemplate.onlineAppRegistrationEmail, 'onlineAppRegistrationEmail')"
                              class='btn btn-outline-primary tippy-btn mb-xs-1 me-1' type='button'>
                              <div class='d-flex'>

                                <span class='fw-bolder button-item-text flex-grow-1'>
                                  <i class='fa fa-pencil-alt'></i>
                                </span>

                                <span class='fw-bolder button-item-text filter-label flex-grow-1 ms-1'>
                                  Edit
                                </span>
                              </div>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class='col-sm-12'>
                      <iframe id='onlineAppRegistrationEmailIframe'
                        style='width:100%; height: 700px; border:1px solid #e3ebf6; border-radius: 0.25rem;'></iframe>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </ng-container>
        </div>

        <!-- Online App Started Template -->
        <div class="accordion-item" *ngIf="applicationContext.userPermissions.onlineApplicationEnabled">
          <ng-container [hidden]="restrictedEmailTemplates?.indexOf('OnlineAppStarted') > -1" *ngLet='collapsibleSectionVisibilityStatuses["onlineAppStarted"] as expanded'>
            <h5 class='accordion-header m-0'>
              <button (click)='onEmailTemplateEditorVisibilityChanged("onlineAppStartedEmail")'
                [attr.aria-expanded]='expanded' aria-controls='collapse1' class='accordion-button collapsed fw-semibold'
                data-bs-target='#oast' data-bs-toggle='collapse' type='button'>
                Online App Started Template

                <span *ngIf='effectiveEmailTemplate.onlineAppStartedOverride' class='text-success ms-1'>
                  - (Overridden)
                </span>
              </button>
            </h5>
            <div id='oast' class='accordion-collapse collapse' [class.show]='expanded'>
              <div class='accordion-body'>
                <div class='form-group mb-3 row'>
                  <div class='col-sm-2'></div>
                  <div class='col-sm-10'>
                    <div class='form-check form-check-inline'>
                      <input (ngModelChange)='populateDefaultEmailTemplate(
                          $event,
                          "OnlineAppStarted",
                          "onlineAppStartedSubject",
                          "onlineAppStartedEmail"
                        )' [(ngModel)]='effectiveEmailTemplate.onlineAppStartedOverride' class='form-check-input'
                        id='onlineAppStartedOverride' type='checkbox' name='onlineAppStartedOverride'>

                      <label class='form-check-label' for='onlineAppStartedOverride'>
                        Override Company Online App Started Template
                      </label>
                    </div>
                  </div>
                </div>

                <ng-container *ngIf='effectiveEmailTemplate.onlineAppStartedOverride'>
                  <div class='form-group mb-3 row'>
                    <label class='col-sm-2 form-label align-self-center mb-lg-0 text-end'>Subject</label>
                    <div class='col-sm-10'>
                      <input class='form-control' placeholder='Subject' type='text'
                        [(ngModel)]='effectiveEmailTemplate.onlineAppStartedSubject'
                        name='onlineAppStartedSubject'>
                    </div>
                  </div>

                  <hr>

                  <!-- Action Buttons & Email Preview -->
                  <div class='form-group mb-3 row'>
                    <div class='col-sm-12 form-label align-self-center mb-1 d-flex justify-content-between'>
                      <div class='d-flex'>
                        <div class='special-radio-btn-group flex-grow-1'>
                          <div class='btn-group'>
                            <button
                              (click)="onEditTemplateClicked(effectiveEmailTemplate.onlineAppStartedEmail, 'onlineAppStartedEmail')"
                              class='btn btn-outline-primary tippy-btn mb-xs-1 me-1' type='button'>
                              <div class='d-flex'>

                                <span class='fw-bolder button-item-text flex-grow-1'>
                                  <i class='fa fa-pencil-alt'></i>
                                </span>

                                <span class='fw-bolder button-item-text filter-label flex-grow-1 ms-1'>
                                  Edit
                                </span>
                              </div>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class='col-sm-12'>
                      <iframe id='onlineAppStartedEmailIframe'
                        style='width:100%; height: 700px; border:1px solid #e3ebf6; border-radius: 0.25rem;'></iframe>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </ng-container>
        </div>

        <!-- Online App Submission Template -->
        <div class="accordion-item" *ngIf="applicationContext.userPermissions.onlineApplicationEnabled">
          <ng-container [hidden]="restrictedEmailTemplates?.indexOf('OnlineAppSubmission') > -1" *ngLet='collapsibleSectionVisibilityStatuses["onlineAppSubmission"] as expanded'>
            <h5 class='accordion-header m-0'>
              <button (click)='onEmailTemplateEditorVisibilityChanged("onlineAppSubmissionEmail")'
                [attr.aria-expanded]='expanded' aria-controls='collapse1' class='accordion-button collapsed fw-semibold'
                data-bs-target='#opst' data-bs-toggle='collapse' type='button'>
                Online App Submission Template

                <span *ngIf='effectiveEmailTemplate.onlineAppSubmissionOverride' class='text-success ms-1'>
                  - (Overridden)
                </span>
              </button>
            </h5>
            <div id='opst' class='accordion-collapse collapse' [class.show]='expanded'>
              <div class='accordion-body'>
                <div class='form-group mb-3 row'>
                  <div class='col-sm-2'></div>
                  <div class='col-sm-10'>
                    <div class='form-check form-check-inline'>
                      <input (ngModelChange)='populateDefaultEmailTemplate(
                          $event,
                          "OnlineAppSubmission",
                          "onlineAppSubmissionSubject",
                          "onlineAppSubmissionEmail"
                        )' [(ngModel)]='effectiveEmailTemplate.onlineAppSubmissionOverride' class='form-check-input'
                        id='onlineAppSubmissionOverride' type='checkbox' name='onlineAppSubmissionOverride'>

                      <label class='form-check-label' for='onlineAppSubmissionOverride'>
                        Override Company Online App Submission Template
                      </label>
                    </div>
                  </div>
                </div>
                <ng-container *ngIf='effectiveEmailTemplate.onlineAppSubmissionOverride'>
                  <div class='form-group mb-3 row'>
                    <label class='col-sm-2 form-label align-self-center mb-lg-0 text-end'>Subject</label>
                    <div class='col-sm-10'>
                      <input class='form-control' placeholder='Subject' type='text'
                        [(ngModel)]='effectiveEmailTemplate.onlineAppSubmissionSubject'
                        name='onlineAppSubmissionSubject'>
                    </div>
                  </div>

                  <hr>

                  <!-- Action Buttons & Email Preview -->
                  <div class='form-group mb-3 row'>
                    <div class='col-sm-12 form-label align-self-center mb-1 d-flex justify-content-between'>
                      <div class='d-flex'>
                        <div class='special-radio-btn-group flex-grow-1'>
                          <div class='btn-group'>
                            <button
                              (click)="onEditTemplateClicked(effectiveEmailTemplate.onlineAppSubmissionEmail, 'onlineAppSubmissionEmail')"
                              class='btn btn-outline-primary tippy-btn mb-xs-1 me-1' type='button'>
                              <div class='d-flex'>

                                <span class='fw-bolder button-item-text flex-grow-1'>
                                  <i class='fa fa-pencil-alt'></i>
                                </span>

                                <span class='fw-bolder button-item-text filter-label flex-grow-1 ms-1'>
                                  Edit
                                </span>
                              </div>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class='col-sm-12'>
                      <iframe id='onlineAppSubmissionEmailIframe'
                        style='width:100%; height: 700px; border:1px solid #e3ebf6; border-radius: 0.25rem;'></iframe>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </ng-container>
        </div>

        <!-- Video Message Template -->
        <div [hidden]="restrictedEmailTemplates?.indexOf('VideoMessage') > -1" *ngLet='collapsibleSectionVisibilityStatuses["videoMessage"] as expanded' class='accordion-item'>
          <h5 class='accordion-header m-0'>
            <button (click)='onEmailTemplateEditorVisibilityChanged("videoMessage")' [attr.aria-expanded]='expanded'
              aria-controls='collapse1' class='accordion-button collapsed fw-semibold' data-bs-target='#vid'
              data-bs-toggle='collapse' type='button'>
              Video Message Template
              <!-- Override Status Badge -->
              <span *ngIf='effectiveEmailTemplate.videoMessageOverride' class='text-success ms-1'>
                - (Overridden)
              </span>
            </button>
          </h5>
          <div id='vid' class='accordion-collapse collapse' [class.show]='expanded'>
            <div class='accordion-body'>
              <div class='form-group mb-3 row'>
                <div class='col-sm-2'></div>
                <div class='col-sm-10'>
                  <div class='form-check form-check-inline'>
                    <input (ngModelChange)='populateDefaultEmailTemplate(
                        $event,
                        "VideoMessage",
                        "videoMessageSubject",
                        "videoMessageEmail"
                      )' [(ngModel)]='effectiveEmailTemplate.videoMessageOverride' class='form-check-input'
                      id='videoMessageOverride' name='videoMessageOverride' type='checkbox'>

                    <label class='form-check-label' for='videoMessageOverride'>
                      Override Company Video Message Template
                    </label>
                  </div>
                </div>
              </div>

              <ng-container *ngIf='effectiveEmailTemplate.videoMessageOverride'>
                <div class='form-group mb-3 row'>
                  <div class='col-sm-2'></div>
                  <div class='col-sm-10'>
                    <div class='form-check form-check-inline'>
                      <input [(ngModel)]='effectiveEmailTemplate.videoMessageIncludeEmailSignature' class='form-check-input'
                        id='videoMessageIncludeEmailSignature' name='videoMessageIncludeEmailSignature' type='checkbox'>

                      <label class='form-check-label' for='videoMessageIncludeEmailSignature'>
                        Include Email Signature
                      </label>
                    </div>
                  </div>
                </div>

                <div class='form-group mb-3 row'>
                  <label class='col-sm-2 form-label align-self-center mb-lg-0 text-end'>
                    CC
                  </label>

                  <div class='col-sm-10'>
                    <input [(ngModel)]='effectiveEmailTemplate.videoMessageCC' class='form-control' placeholder='CC'
                      type='text' name='videoMessageCC'>
                  </div>
                </div>

                <div class='form-group mb-3 row'>
                  <label class='col-sm-2 form-label align-self-center mb-lg-0 text-end'>BCC</label>
                  <div class='col-sm-10'>
                    <input [(ngModel)]='effectiveEmailTemplate.videoMessageBCC' class='form-control' placeholder='BCC'
                      type='text' name='videoMessageBCC'>
                  </div>
                </div>

                <div class='form-group mb-3 row'>
                  <label class='col-sm-2 form-label align-self-center mb-lg-0 text-end'>CC</label>
                  <div class='col-sm-10'>
                    <ng-select width='100%' bindLabel='name' bindValue='value'
                      placeholder='-- Select --' [closeOnSelect]='false' [multiple]='true' groupBy='groupName'
                      [(ngModel)]='videoMessageRolesCC' [items]='roles' appendTo='body'
                      [ngModelOptions]="{standalone: true}"
                      (ngModelChange)="onRoleMultiselectChanged('videoMessageRolesCC')">
                    </ng-select>
                  </div>
                </div>
                <div class='form-group mb-3 row'>
                  <label class='col-sm-2 form-label align-self-center mb-lg-0 text-end'>BCC</label>
                  <div class='col-sm-10'>
                    <ng-select width='100%' bindLabel='name' bindValue='value'
                      placeholder='-- Select --' [closeOnSelect]='false' [multiple]='true' groupBy='groupName'
                      [(ngModel)]='videoMessageRolesBCC' [items]='roles' appendTo='body'
                      [ngModelOptions]="{standalone: true}"
                      (ngModelChange)="onRoleMultiselectChanged('videoMessageRolesBCC')">
                    </ng-select>
                  </div>
                </div>
                <div class='form-group mb-3 row'>
                  <label class='col-sm-2 form-label align-self-center mb-lg-0 text-end'>Subject</label>
                  <div class='col-sm-10'>
                    <input class='form-control' placeholder='Subject' type='text'
                      [(ngModel)]='effectiveEmailTemplate.videoMessageSubject' name='videoMessageSubject'>
                  </div>
                </div>

                <hr>

                <!-- Action Buttons & Email Preview -->
                <div class='form-group mb-3 row'>
                  <div class='col-sm-12 form-label align-self-center mb-1 d-flex justify-content-between'>
                    <div class='d-flex'>
                      <div class='special-radio-btn-group flex-grow-1'>
                        <div class='btn-group'>
                          <button (click)="onEditTemplateClicked(effectiveEmailTemplate.videoMessageEmail, 'videoMessageEmail')"
                            class='btn btn-outline-primary tippy-btn mb-xs-1 me-1' type='button'>
                            <div class='d-flex'>

                              <span class='fw-bolder button-item-text flex-grow-1'>
                                <i class='fa fa-pencil-alt'></i>
                              </span>

                              <span class='fw-bolder button-item-text filter-label flex-grow-1 ms-1'>
                                Edit
                              </span>
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class='col-sm-12'>
                    <iframe id='videoMessageEmailIframe'
                      style='width:100%; height: 700px; border:1px solid #e3ebf6; border-radius: 0.25rem;'></iframe>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</form>

<drawer #editEmailTemplateDrawer [name]="'editEmailTemplateDrawer'" [options]='editEmailTemplateDrawerOptions'>
</drawer>
