<div class="message-container" *ngIf="showYouDontHaveCreditCredentialsConfiguredMessage">
	<span class="message-content text-dark">You don't have your credit credentials configured in the system.</span>
</div>
<div class="message-container mb-2">
	<span class="message-content text-dark fw-semibold">Please select your credit vendor from the choices below.</span>
</div>

<div *ngIf="loading" class="cred-vendor-picker-container align-items-center justify-content-center">
	<loading-indicator></loading-indicator>
</div>

<div *ngIf="errorLoadingProviders"
		class="cred-vendor-picker-container align-items-center justify-content-center">
		<div class="alert alert-danger border-0 fs-6 mt-3 mb-3" role="alert">
			<strong><i class="la la-exclamation-circle text-danger"></i></strong> Unable to load credit providers.
		</div>
</div>

<div *ngIf="showAllAvailableVendorsInDropDown && !loading && !errorLoadingProviders"
	class="cred-vendor-picker-container align-items-center justify-content-center">
	<div class="card-body">
		<form class="form-horizontal auth-form" #createCreditCredentialsForm="ngForm" novalidate id="createCreditCredentialsForm" name="createCreditCredentialsForm">
			<div class="mb-2">
				<div><label for="providerIdentifier" class="form-label text-dark">Select Your Credit Vendor</label></div>
				<p-dropdown id="providerIdentifier" name="providerIdentifier" [options]="allDuCreditProviders"
					[required]="true"
					[disabled]="saving"
					[ngClass]="{'is-invalid': providerIdentifierField && providerIdentifierField.touched && providerIdentifierField.invalid}"
					#providerIdentifierField="ngModel"
					[(ngModel)]="selectedProviderIdentifier" [showClear]="true" [filter]="true" optionLabel="displayName"
					(ngModelChange)="onAfterProviderIdentifierSelected($event)"
					optionValue="providerIdentifier" placeholder="-- Select One --">
				</p-dropdown>
				<div class="invalid-feedback">Please select a credit vendor.</div>
			</div>
			<div class="mb-2">
				<label for="alias" class="form-label text-dark">Alias</label>
				<input name="alias" 
					[required]="true"
					[(ngModel)]="alias" 
					[disabled]="saving"
					class="form-control" 
					[ngClass]="{'is-invalid': aliasField && aliasField.touched && aliasField.invalid}"
					#aliasField="ngModel"/>
				<div class="invalid-feedback">Please enter an alias.</div>
			</div>
			<div class="mb-2">
				<label for="userName" class="form-label text-dark">Username</label>
				<input name="userName" 
					[required]="true"
					[(ngModel)]="userName" 
					[disabled]="saving"
					class="form-control" 
					[ngClass]="{'is-invalid': userNameField && userNameField.touched && userNameField.invalid}"
					#userNameField="ngModel"/>
				<div class="invalid-feedback">Please enter your username.</div>
			</div>
			<div class="mb-2">
				<label for="password" class="form-label text-dark">Password</label>
				<input name="password" 
					[required]="true"
					[(ngModel)]="password" 
					[disabled]="saving"
					class="form-control" 
					[ngClass]="{'is-invalid': passwordField && passwordField.touched && passwordField.invalid}"
					#passwordField="ngModel"/>
				<div class="invalid-feedback">Please enter your password.</div>
			</div>

			<div class="mb-2" *ngIf="needsClientId">
				<label for="clientId" class="form-label text-dark">Client ID</label>
				<input name="clientId" 
					[(ngModel)]="clientId" 
					[disabled]="saving"
					class="form-control" 
					[ngClass]="{'is-invalid': clientIdField && clientIdField.touched && clientIdField.invalid}"
					#clientIdField="ngModel"/>
			</div>
			<div class="mb-2" *ngIf="needsProviderId">
				<label for="providerId" class="form-label text-dark">Provider ID</label>
				<input name="providerId" 
					[(ngModel)]="providerId" 
					[disabled]="saving"
					class="form-control" 
					[ngClass]="{'is-invalid': providerIdField && providerIdField.touched && providerIdField.invalid}"
					#providerIdField="ngModel"/>
			</div>
			<div class="mb-2" *ngIf="needsSubscriberId">
				<label for="subscriberId" class="form-label text-dark">Subscriber ID</label>
				<input name="subscriberId" 
					[(ngModel)]="subscriberId" 
					[disabled]="saving"
					class="form-control" 
					[ngClass]="{'is-invalid': subscriberIdField && subscriberIdField.touched && subscriberIdField.invalid}"
					#subscriberIdField="ngModel"/>
			</div>

			<div class="mb-2" style="text-align: center; display: grid;">
				<button *ngIf="!saving" (click)="onSaveClicked()"
					class="btn btn-primary" name="saveButton"
					id="saveButton">Save
				</button>
				<button class="btn btn-primary waves-effect waves-ligh" type="button" disabled=""
					*ngIf="saving">
					<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
					Please wait...
				</button>
			</div>
		</form>
	</div>
</div>

<div *ngIf="!showAllAvailableVendorsInDropDown && !loading && !errorLoadingProviders"
	class="cred-vendor-grid-container align-items-center justify-content-center">
	<div class="search-container">
		<span class="p-input-icon-right" style="width: 300px;">
			<i class="pi pi-search"></i>
			<input pInputText type="text" class="form-control h-100" [(ngModel)]="searchString"
				(ngModelChange)="onSearchStringChanged()" placeholder="Search" />
		</span>
	</div>
	<button (click)="onProviderSelected(provider)" title="{{provider.displayName}}" *ngFor="let provider of filteredDuCreditProviders"
		class="col-sm-3 cred-button-grid-item">{{provider.displayName}}</button>
</div>

<div class="message-container">
	<a *ngIf="!showAllAvailableVendorsInDropDown" class="message-content" href="javascript:void(0)"
		(click)="onIDontSeeMyVendorClicked()">Don't see your credit vendor? Click here...</a>
	<a *ngIf="showAllAvailableVendorsInDropDown" class="message-content" href="javascript:void(0)"
		(click)="onBackToVendorButtonsViewClicked()">&lt; Back</a>
</div>