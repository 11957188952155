<loading-indicator *ngIf="loadingContacts" [loadingMessage]="'Loading internal contacts, please wait...'">
</loading-indicator>
<div class="card" *ngIf="!loadingContacts && contactRoles">
  <div class="card-header">
    <h4 class="card-title"> Internal Contacts </h4>
  </div>
  <div class="card-body">
    <div class="d-flex align-items-center justify-content-end mb-2">
      <button class="btn btn-sm btn-outline-primary me-1" (click)="onChangeViewType()"
        title="{{templateType == 'list' ? 'switch to card view' : 'switch to list view'}}"><i
          class="fas {{templateType == 'list' ? 'fa-address-card' : 'fa-list'}}"></i></button>
      <button class="btn btn-sm btn-outline-primary" (click)="onHistoryIconClicked()" title="Show/Hide History"><i
          class="far fa-clock"></i></button>
    </div>
    <form #internalContactsForm="ngForm" id="internalContactsForm" name="internalContactsForm">
      <div class="row">
        <ng-container *ngFor="let role of contactRoles; let index = index">
          <ng-container *ngIf="role['isTpoInternalContactsDropdownEnabled']">
            <div class="col-md-3 d-flex align-items-stretch" *ngIf="templateType == 'card'">
              <div class="card w-100">
                <div class="card-header">
                  <h4 class="card-title"> {{role.roleName}} </h4>
                </div>
                <div class="card-body">
                  <div *ngIf="contactUserIdsByRole[role.roleId]; else addContactTemplate" class="w-100">
                    <div class="row align-items-center">
                      <div class="col-3">
                        <div class="img-group">
                          <a class="user-avatar user-avatar-group">
                            <img src="{{baseAvatarUrl}}avatars/user-profile/{{contactUserIdsByRole[role.roleId]}}"
                              onerror="this.src='assets/images/male.png'" alt="user" class="rounded-circle thumb-md">
                          </a>
                        </div>
                      </div>
                      <div class="col-9">
                        <button type="button" class="btn nav nav-link d-inline p-0 w-100 text-start"
                          data-bs-container="body" data-bs-toggle="popover" data-trigger="click" placement="bottom"
                          [popover]="editContactPopover" [outsideClick]="true"
                          [containerClass]="'edit-contact-popover'">
                          {{ contactUsersByRole.get(role.roleId)?.displayName }}
                        </button>

                        <div class="mt-1">
                          <a class="action" *ngIf="index == 0" (click)="onShowAvailableAlignmentsClicked()">
                            Alignments
                          </a>
                        </div>

                        <ng-template #editContactPopover>
                          <ng-container [ngTemplateOutlet]="editContactTemplate"
                            [ngTemplateOutletContext]="{role: role, index: index}">
                          </ng-container>
                        </ng-template>
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-12">
                        <table-cell-contact-info [contactInfo]="{
                          mobilePhone: contactUsersByRole.get(role.roleId)?.usingPhone || null,
                          email: contactUsersByRole.get(role.roleId)?.usingEmail || null,
                          firstName: contactUsersByRole.get(role.roleId)?.firstName,
                          lastName: contactUsersByRole.get(role.roleId)?.lastName
                        }" [isLodaEmail]="true" [showHomePhone]="false" [dialerEnabled]="true" [recordType]="'Agent'"
                          [applicationId]="applicationId" [ellipsisShowingEnabled]="true"
                          (lodaEmailClicked)="onLodaEmailClicked($event)">
                        </table-cell-contact-info>
                      </div>
                    </div>
                  </div>
                  <ng-template #addContactTemplate>
                    <button type="button" class="btn nav nav-link d-inline" data-bs-container="body"
                      data-bs-toggle="popover" data-trigger="click" placement="bottom" [popover]="addContactPopover"
                      [outsideClick]="true" [containerClass]="'add-contact-popover'">
                      <i class="fas fa-plus"></i> New Contact
                    </button>

                    <ng-template #addContactPopover>
                      <ng-container [ngTemplateOutlet]="editContactTemplate"
                        [ngTemplateOutletContext]="{role: role, index: index}">
                      </ng-container>
                    </ng-template>

                  </ng-template>
                </div>

              </div>

            </div>
            <div class="row mb-1" *ngIf="templateType == 'list'">
              <label for="{{role.roleName}}" class="col-md-4 form-label align-self-center mb-lg-0 text-end">
                {{role.roleName}}
              </label>
              <div [ngClass]="index === 0 ? 'col-md-5' : 'col-md-8'">
                <ng-container [ngTemplateOutlet]="editContactTemplate"
                  [ngTemplateOutletContext]="{role:role, index: index}">
                </ng-container>
              </div>
              <div *ngIf="index === 0" class="col-md-3 mt-1">
                <a class="action" (click)="onShowAvailableAlignmentsClicked()">
                  Alignments
                </a>
              </div>
            </div>
          </ng-container>
        </ng-container>

        <div class="alert alert-primary border-0 text-center" role="alert" *ngIf="!contactRoles.length">
          There are no visible internal contacts.
        </div>
      </div>

      <div class="row mt-2">
        <div class="modal-footer" style="display: unset">
          <button type="button" class="btn btn-primary float-end" (click)="onSaveInternalContactClicked()"
            [disabled]="isSaving" *ngIf="contactRoles.length">
            <span *ngIf="!isSaving">
              <i class="fa fa-save"></i>
              Save
            </span>
            <span *ngIf="isSaving">
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              Saving
            </span>
          </button>
        </div>
      </div>
    </form>
  </div>
</div>

<ng-template #editContactTemplate let-role="role" let-index="index">
  <select class="form-select" [(ngModel)]="contactUserIdsByRole[role.roleId]" (change)="onRoleUserChanged(role)"
    id="{{role.roleId}}" name="{{role.roleId}}" [disabled]="isTpoUser && (role.order == 1 || !role.tpoEnabled)">
    <option disabled [ngValue]="undefined"> -- Select One -- </option>
    
    <ng-container *ngIf="role.order != 1">
      <ng-container *ngFor="let users of usersAll | groupBy: 'roleId'">
        <optgroup label="{{contactRoles[index].roleName}}"
          [hidden]="(((users.value | filter : { 'userCompanyGuid' : contactUserIdsByRole[role.roleId] }).length == 0) && ((users.value).length == 0)) || users.key != role.roleId">
          <ng-container *ngFor="let user of users.value">
            <option class="user-select"
              [ngValue]="user.userCompanyGuid">
              {{user.lastName + ", " + user.firstName}} <span *ngIf="!user.active"> (Inactive)</span>
            </option>
          </ng-container>
        </optgroup>
      </ng-container>
    </ng-container>

    <optgroup label="Branch Users"
      *ngIf="role.tpoEnabled && (branchUsers | filter : { 'roleId' : role.roleId}).length > 0">
      <ng-container *ngFor="let user of branchUsers | filter : { 'roleId' : role.roleId}">
        <option [ngValue]="user.userCompanyGuid">
          {{user.lastName + ", " + user.firstName}} <span *ngIf="!user.active"> (Inactive)</span>
        </option>
      </ng-container>
    </optgroup>
    <optgroup label="TPO All Users" *ngIf="role.tpoEnabled && externalCompanyUsers?.length > 0">
      <option class="user-select" *ngFor="let user of externalCompanyUsers" [ngValue]="user.userCompanyGuid">
        {{user.lastName + ", " + user.firstName}} <span *ngIf="!user.active"> (Inactive)</span>
      </option>
    </optgroup>

    <ng-container *ngIf="role.order == 1 && (users | filter :  { 'roleId': role.roleId }).length > 0">
      <optgroup label="All Users" [hidden]="externalCompanyId">
        <ng-container *ngFor="let user of users | filter : { 'roleId': role.roleId }">
          <option [ngValue]="user.userCompanyGuid">
            {{user.lastName + ", " + user.firstName}} <span *ngIf="!user.active"> (Inactive)</span>
          </option>
        </ng-container>
      </optgroup>
    </ng-container>
    <ng-container *ngIf="role.order != 1 && users.length > 0 && !isTpoUser">
      <optgroup label="All Users">
        <ng-container *ngFor="let user of users">
          <option [ngValue]="user.userCompanyGuid">
            {{user.lastName + ", " + user.firstName}} <span *ngIf="!user.active"> (Inactive)</span>
          </option>
        </ng-container>
      </optgroup>
    </ng-container>
  </select>
</ng-template>

<drawer [title]="'Send E-mail'" [name]="'sendEmailDrawerForInternalContacts'" [templateRef]="sendEmailContentRef"
  [options]="drawerOptionsXl">
</drawer>
<ng-template #sendEmailContentRef>
  <div style="padding: 20px">
    <send-email-sms-dialog [isModal]="false" [to]="emailRecipients" [isShowOnlyEmail]="true"
      [showBorrowerPicker]="false" (sentSuccessfully)="onEmailSentSuccessfully()" (cancelled)="onEmailSendCancelled()">
    </send-email-sms-dialog>
  </div>
</ng-template>

<drawer [title]="'Internal Contact History'" [name]="'internalContactsHistoryDrawer'" [templateRef]="historyContentRef"
  [options]="drawerOptionsXl" (closed)="onHistoryDrawerClosed()">
</drawer>
<ng-template #historyContentRef>
  <div style="padding: 20px">
    <div *ngIf="internalContactsHistory">
      <internal-contacts-history [internalContactsHistory]="internalContactsHistory"></internal-contacts-history>
    </div>
  </div>
</ng-template>
