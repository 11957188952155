<loading-indicator
  *ngIf="isLoading"
  [loadingMessage]="'Loading submission, please wait...'"
></loading-indicator>
<div class="row" *ngIf="!isLoading">
  <div class="col-lg-12">
    <div class="card">
      <div class="card-body">
        <items-required-for-submission
          [data]="itemsRequiredForSubmissionData"
          [optionalConditions]="itemsRequestedForSubmission"
          [borrowers]="borrowers"
          (onTaskUpdated)="fetchValidateTasks()"
        ></items-required-for-submission>
        <submission-data-validation
          *ngIf="!isCompanyPulseProcessing"
          [isUrlaInvalid]="isUrlaInvalid"
          [application]="_application"
          [validationResults]="otherValidations"
        ></submission-data-validation>
        <license-info
          *ngIf='licenseValidationData'
          [data]='licenseValidationData'
        ></license-info>
        <disclosure-path
          *ngIf="!isCompanyPulseProcessing && !(isTpoUser && isGenway) && !(isPRMG && ['Correspondent'].includes(_application?.channel))"
          [disclosurePath]="_application.disclosurePath"
          [disclosurePathPermissions]="disclosurePathPermissions"
          [tridAppDate]="tridAppDate"
        ></disclosure-path>
        <program
          *ngIf="!isCompanyPulseProcessing"
          [productPricing]="productPricing" 
          [isCompTypeHidden]="isPRMG && ['Correspondent', 'NonDelegatedCorrespondent'].includes(_application?.channel)">
        </program>
        <final-submission-info
          [data]="finalSubmissionInfoData"
        ></final-submission-info>
        <external-contacts-v2 *ngIf="showExternalContacts"></external-contacts-v2>
        <div class="row">
          <div class="col-lg-12">                            
              <div class="card">
                  <div class="card-header">
                      <div class="row align-items-center">
                          <div class="col">                      
                              <h4 class="card-title" style="text-transform: none;">{{finalNotesSectionHeader}}</h4>                      
                          </div>
                      </div>                                 
                  </div>
                  <div class="card-body">
                      <div class="row">
                          <textarea class="form-control" id="finalNotes" cols="12" rows="7" style="resize: none;" [(ngModel)]="internalMessage.content"></textarea>
                      </div> 
                  </div>
              </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 text-end mb-3">
            <button
              [disabled]="isUrlaInvalid && (isDeephaven || isCompanyPulseProcessing || (isPRMG && ['Correspondent', 'NonDelegatedCorrespondent'].includes(_application?.channel)))"
              type="submit"
              class="btn btn-primary px-4"
              (click)="onSubmitLoanClicked()"
            >
              Submit Loan
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
