import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { DataService } from 'src/app/core/services/data.service';
import { User } from 'src/app/models/user/user.model';
import { OrgChartSaveRequest } from '../models/org-chart-user.model';
import { CacheService } from 'src/app/services/cache.service';

@Injectable()
export class CompanyUsersService {
  constructor(
    private readonly _dataService: DataService,
    private readonly _cacheService: CacheService
  ) {}

  getCompanyUsers = (companyId: number): Observable<Array<User>> => {
    const url = `api/Admin/AllUserModel?companyId=${companyId}`;
    return this._dataService.get(url);
  };

  updateCompanyUser = (user: User, companyId: number): Observable<void> => {
    const url = `api/Admin/UpdateUserModel?companyId=${companyId}`;
    return this._dataService.post(url, user);
  };

  activateUser = (userCompanyGuid: string): Observable<void> => {
    const url = `api/Admin/ActivateUser/${userCompanyGuid}`;
    return this._dataService.post(url, {});
  };

  deactivateUser = (userCompanyGuid: string): Observable<void> => {
    const url = `api/Admin/DeactivateUser/${userCompanyGuid}`;
    return this._dataService.post(url, {});
  };

  deleteCompanyUser = (
    userGuid: string,
    companyId: number
  ): Observable<void> => {
    const url = `api/Admin/DeleteUserModel/${userGuid}?companyId=${companyId}`;
    return this._dataService.delete(url);
  };

  saveOrgChart = (
    companyId: number,
    request: Array<OrgChartSaveRequest>
  ): Observable<void> => {
    const cacheKey =`api/Admin/PageResource/ALL?companyId=${companyId}`;
    const url = `api/Admin/SaveOrgChart?companyId=${companyId}`;
    this._cacheService.clearByKeyAsync(cacheKey);
    return this._dataService.post(url, request);
  };
}


