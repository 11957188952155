<spinner #spinner [name]="'pricingSpinner'"></spinner>
<div class="row" *ngIf="!isLoading">
  <div class="col-md-12">
    <div class="card" *ngIf="showIframePricer === false">
      <div class="card-header">
        <h4 class="card-title col-5" style="display: inline-block;"><i class="fas fa-usd me-2"></i> Pricing Details
        </h4>
        <div class="float-end button-items">
          <button class="btn btn-primary btn-sm" *ngIf="tab === 'pricingScenarios' && pricingScenarios.length"
            (click)="onLoanComparisonClicked()">Loan Comparison</button>
        </div>
      </div>
      <div class="card-body">
        <pricing-details [currentMortgage]="mortgage" [application]="applicationContext?.application"
          [enableChannelSelection]="!isTpo" (setAmortizationTabVisibility)="setAmortizationTabVisibility($event)"
          (amortizationTypeAssigned)="setAmortizationType($event)" (scenariosChanged)="onScenariosChanged($event)">
        </pricing-details>
      </div>
    </div>
    <ng-container *ngIf="showIframePricer === true">
      <lenderprice-pricer *ngIf="showIframeForLenderPrice"
        (pricingCompleted)="onPricingCompleted($event)"></lenderprice-pricer>
      <polly-pricer #pollyPricer *ngIf="showIframeForPolly && enabledChannels" [enabledChannels]="enabledChannels"
        (pricingCompleted)="onPricingCompleted($event)"></polly-pricer>
    </ng-container>
  </div>
</div>