import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import _ from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { RoggoraDocType } from 'src/app/modules/loan-services/reggora/models/reggora-doc-type';
import { ReggoraService } from 'src/app/modules/loan-services/reggora/services/reggora.service';
import { NotificationService } from 'src/app/services/notification.service';
import { DocumentType } from 'src/app/models';

@Component({
  selector: 'app-reggora-edit-doc-type-dialog',
  templateUrl: './reggora-edit-doc-type-dialog.component.html',
  styleUrls: ['./reggora-edit-doc-type-dialog.component.scss']
})
export class ReggoraEditDocTypeDialogComponent implements OnInit {

  @Input() mapping: RoggoraDocType;
  @Input() docTypes: DocumentType[] = [];

  @ViewChild("mappingForm") mappingForm: NgForm;

  editedDocType: RoggoraDocType;
  tags: string[] = [];

  constructor(public activeModal: NgbActiveModal,
    private readonly _spinnerService: NgxSpinnerService,
    private readonly _notificationService: NotificationService,
    private readonly _reggoraService: ReggoraService) { }

  ngOnInit(): void {
    this.editedDocType = _.cloneDeep(this.mapping);
    this.tags = this.editedDocType.reggoraDocNameContainsCsv ? this.editedDocType.reggoraDocNameContainsCsv.split(",") : [];
  }

  save() {
    if (!this.mappingForm){
      return;
    }

    this.mappingForm.form.markAllAsTouched();

    if (!this.mappingForm.form.valid){
      return;
    }

    this.editedDocType.reggoraDocNameContainsCsv = this.tags.map(to => to['value']).join(",");

    this._spinnerService.show();
    this._reggoraService.upsertReggoraDocType(this.mapping.credentialId, this.editedDocType)
      .subscribe({
        next: (res) => {
          this._spinnerService.hide();

          this._notificationService.showSuccess(
            "Successfully updated document type mapping.",
            'Success'
          );

          this.editedDocType = res;
          this.activeModal.close(this.editedDocType);

        },
        error: (error) => {
          this._spinnerService.hide();

          this._notificationService.showError(
            error?.message || "Couldn't update document type mapping.",
            'Error'
          );
        }
      });
  }
}
