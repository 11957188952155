import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild, ElementRef, AfterViewInit, HostListener } from '@angular/core';
import { AbstractValueAccessor, MakeProvider } from 'src/app/core/abstract-value-accessor';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { SimpleMergeFieldContextMenuComponent } from '../simple-merge-field-context-menu/simple-merge-field-context-menu.component';
import { MentionDirective } from 'angular-mentions';

@Component({
  selector: 'rich-text-editor',
  templateUrl: 'rich-text-editor.component.html',
  styleUrls: ['rich-text-editor.component.scss'],
  providers: [MakeProvider(RichTextEditorComponent)],
})
export class RichTextEditorComponent extends AbstractValueAccessor implements OnInit, OnChanges, AfterViewInit {

  @ViewChild('contextMenu')
  contextMenu: SimpleMergeFieldContextMenuComponent;

  @ViewChild("editor")
  editor: any;

  @Input()
  name: string;

  @Input()
  showToolbar: boolean = true;

  @Input()
  showUnsubscribeToolbarButton: boolean = false;

  @Input()
  height: string = '15rem';

  @Input()
  singleLine: boolean = false;

  @Input()
  mergeFields: EnumerationItem[] = null;

  selectedMergeField: string;

  private _anchorNode: any;
  private _anchorOffset: number;
  private _initialDrawerTopValue: number;
  private _drawer: HTMLElement;

  unsubLinkAnchorTag: string = 'To unsubscribe from receiving these notifications, click <a href="%Campaign_UnsubscribeLink%">here</a>';

  mergeFieldsMentionConfig = {};

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: this.height,
    minHeight: '5rem',
    sanitize: false,
    placeholder: '',
    translate: 'no',
    defaultFontName: 'Arial',
  };

  constructor(private readonly _elementRef: ElementRef,) {
    super();
  }

  ngOnInit() {
    this.config.showToolbar = this.showToolbar;
    this.config.height = this.height;
    this.config.minHeight = this.height;
    this.setMergeFieldConfig();
  }

  ngAfterViewInit(): void {
    this._drawer = this._elementRef.nativeElement.parentElement.closest("drawer");
    if (this._drawer) {
      this._initialDrawerTopValue = this._drawer.getBoundingClientRect().top;
    }
  }

  setMergeFieldConfig() {
    this.mergeFieldsMentionConfig = {
      items: this.mergeFields,
      triggerChar: '%',
      labelKey: 'value',
      mentionSelect: (el) => {
        this.selectedMergeField = null;
        //band aid fix the select mention value not persisting. need proper solution.
        setTimeout(() => {
          this.editor.executeCommandFn('insertHtml', '');
        }, 10);
        return `%${el.value}%`;
      },
    }
  }

  //this ngOnChanges is required because in case the rich text editor is initialized before the merge fields are
  //loaded then the merge fields will never display.
  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.mergeFields?.firstChange &&
      changes.mergeFields?.currentValue &&
      changes.mergeFields?.currentValue != changes.mergeFields?.previousValue
    ) {
      this.setMergeFieldConfig();
    }
  }

  onMergeFieldSelected = (mergeField: string, setCaret: boolean = true) => {
    setTimeout(() => {
      if (setCaret) {
        this.setCaret();
      }
      this.editor.executeCommandFn('insertHtml', mergeField);
    });
  }

  onMentionsListClosed() {
    if (this.selectedMergeField) {
      this.onMergeFieldSelected(this.selectedMergeField + "%", false);
      this.selectedMergeField = null;
    }
  }

  onMentionsListOpened() {
    setTimeout(() => {
      const mentionMenu = document.getElementsByClassName('mention-menu');
      const mentionList = mentionMenu[0].parentElement;
      if (mentionList && this._drawer) {
        const newTopValue = this._drawer.getBoundingClientRect().top;
        const diff = newTopValue - this._initialDrawerTopValue;
        const top = mentionList.offsetTop;
        mentionList.style.top = top - diff + 'px';
        mentionList.style.zIndex = '99999';
      }
    });
  }

  onRightClick = (e: any) => {
    const selection = window.getSelection();
    this._anchorNode = selection.anchorNode;
    this._anchorOffset = selection.anchorOffset;
    if (!this.mergeFields) {
      return;
    }
    if (!e.target.classList.contains('angular-editor-toolbar') && e.currentTarget.id === 'html-editor') {
      e.preventDefault();
      this.contextMenu.show(e);
    }
  }

  private setCaret() {
    let range = document.createRange();
    let selection = window.getSelection();

    range.setStart(this._anchorNode, this._anchorOffset);
    range.collapse(true);

    selection.removeAllRanges();
    selection.addRange(range);
  }

  protected onPaste(event: ClipboardEvent): void {
    const clipboardData = event.clipboardData;
    const hasRtf = Array.from(clipboardData.items)
      .some(({ type }) => type === 'text/rtf');
    if (!hasRtf) {
      return;
    }
    // The Rich Text Format (RTF) corrupts data when pasted. Therefore, only the
    // plain text value is used.

    event.preventDefault();

    // Preserve line breaks by converting text to HTML.
    const textToHtml = (text: string): string => {
      return text.replace(/\r?\n/g, '<br>');
    };

    // Discard the special formatting by getting the plain text only.
    const plainText = clipboardData.getData('text/plain');
    const html = textToHtml(plainText);

    this.editor.executeCommandFn('insertHTML', html);
  }
}