import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { DialerEventType } from 'src/app/modules/dialer/models/dialer-event.model';
import { StartWarmTransferResponse } from 'src/app/modules/dialer/models/start-warm-transfer-response.model';
import { DialerService } from 'src/app/modules/dialer/services/dialer.service';
import { ApplicationMode, NavigationService } from 'src/app/services/navigation.service';
import { NotificationService } from 'src/app/services/notification.service';
import { VoiceService } from 'src/app/services/voice.service';

@Component({
  selector: 'app-warm-transfer-lead-available-dialog',
  templateUrl: './warm-transfer-lead-available-dialog.component.html',
  styleUrls: ['./warm-transfer-lead-available-dialog.component.scss']
})
export class WarmTransferLeadAvailableDialogComponent implements OnInit {

  @Input() model: any;
  @Input() currentDialListId: number;

  acceptClicked: boolean;
  warmTransferAudio = new Audio('assets/sound/WarmTransferAlert.mp3');

  constructor(
    public activeModal: NgbActiveModal,
    private readonly _notifyService: NotificationService,
    private readonly _dialerService: DialerService,
    private readonly _voiceService: VoiceService,
    private readonly _localStorageService: LocalStorageService,
    private readonly _router: Router,
    private readonly _navigationService: NavigationService
  ) {
  }

  ngOnInit(): void {
  }

  acceptWarmTransferLead() {
    this.acceptClicked = true;
    this.warmTransferAudio.pause();

    console.log('_acceptWarmTransferLead', this.model);
    if (this.model.isVoipCall) {
      this._voiceService.startWarmTransferNew(this.model.leadRouteHistoryId).subscribe((result: StartWarmTransferResponse) => {
        this.onWarmTransferStartedSuccessfully(result);
        this.activeModal.close();
      }, error => {
        this.activeModal.close();
        this._notifyService.showError(error.message, "Warm Transfer");
      });
    }
    else {
      this._voiceService.startWarmTransfer(this.model.voiceHistoryId, this.model.leadRouteHistoryId).subscribe((result: StartWarmTransferResponse) => {
        this.onWarmTransferStartedSuccessfully(result);
        this.activeModal.close();
      }, error => {
        this.activeModal.close();
        this._notifyService.showError(error.message, "Warm Transfer");
      });
    }
  }

  private onWarmTransferStartedSuccessfully = (result: StartWarmTransferResponse) => {
    if (this._router.url.includes("/dialer")) {
      this._dialerService.publish({
        eventType: DialerEventType.WarmTransferAccepted,
        data: {
          leadId: this.model.leadId,
          conferenceId: result.conferenceId,
          leadRouteHistoryId: this.model.leadRouteHistoryId,
          participantId: result.participantId,
          transferingAgentParticipantId: result.transferingAgentParticipantId,
          transferingAgentUserId: result.transferingAgentUserId,
          voiceHistory: result.voiceHistory
        }
      });
    } else {
      this._localStorageService.setItem("warmTransferLeadInfo", {
        leadId: this.model.leadId,
        conferenceId: result.conferenceId,
        leadRouteHistoryId: this.model.leadRouteHistoryId,
        participantId: result.participantId,
        transferingAgentParticipantId: result.transferingAgentParticipantId,
        transferingAgentUserId: result.transferingAgentUserId,
      });

      if (this._navigationService.applicationMode == ApplicationMode.Classic) {
        this._router.navigate(["/admin/dialer"]);
      }
      if (this._navigationService.applicationMode == ApplicationMode.NextGen) {
        this._router.navigate(["/loda-nextgen/dialer"]);
      }
    }
  }
}

